import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import * as dropdownStyles from "./styles";
import { Searchbar2 } from "../Searchbar";
import { ReceiptCheckBox } from "../Receipt";

import { FaCaretDown } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import styles from "../../assets/styles/MimaDropdown.module.scss";

const propTypes = {
  labelTitle: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["medium", "form", "error", "fullWidthError"]),
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.any,
  data: PropTypes.array,
  styleClass: PropTypes.any,
  pt: PropTypes.number,
  pb: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.any,
  mb: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  fontSize: PropTypes.number,
  //for edit
  currentValue: PropTypes.array,
};

const MimaMultiDropdown = ({
  labelTitle,
  placeholder,
  data = [
    { key: "item 1", value: "item 1" },
    { key: "item 2", value: "item 2" },
  ],
  onBlur,
  onChange,
  value,
  variant = "medium",
  width,
  id,
  name,
  touched,
  error,
  mb,
  mt,
  mr,
  ml,
  height,
  disabled = false,
  currentValue,
  ...props
}) => {
  useEffect(() => {
    if (currentValue && currentValue?.length > 0) {
      const filterDataArray = data.filter((item) =>
        currentValue.includes(item.key)
      );
      setTags([...filterDataArray]);
    }
  }, [currentValue, data]);
  const [openDropdown, setOpenDropdown] = useState(false);

  const openDropdownHandler = () => {
    setOpenDropdown(!openDropdown);
  };

  const dropDownSetHandler = (dropDownOption) => {
    addTags(dropDownOption);
  };

  const dropDownOptions = data;
  const [filteredDropdown, setFilteredDropdown] = useState([]);

  const [tags, setTags] = useState([]);

  //Remove tags
  const removeTags = (index) => {
    const updateTags = [...tags.filter((tag) => tags.indexOf(tag) !== index)];
    const keysArray = updateTags.map((item) => item.key);
    // const valuesArray = updateTags.map((item) => item.value);

    setTags(updateTags);
    onChange(keysArray);
  };

  //Add tags
  const addTags = (dropDownOption) => {
    const existingIndex = tags.findIndex(
      (item) => item.key === dropDownOption.key
    );

    if (existingIndex !== -1) {
      const refinedTags = [...tags];
      refinedTags.splice(existingIndex, 1);
      setTags(refinedTags);
      const keysArray = refinedTags.map((item) => item.key);
      // const valuesArray = refinedTags.map((item) => item.value);
      onChange([...keysArray]);
    } else {
      setTags((prevTags) => [...prevTags, dropDownOption]);
      const tagKeysArray = tags.map((item) => item.key);
      // const tagValuesArray = tags.map((item) => item.value);
      const keysArray = [...tagKeysArray, dropDownOption.key];
      // const valuesArray = [...tagValuesArray, dropDownOption.value];
      onChange([...keysArray]);
    }
  };

  const errorVariant = useMemo(() => {
    if (variant === "form") {
      return "fullWidthError";
    } else return "error";
  }, [variant]);

  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
      <label className={styles.label}>{labelTitle}</label>
      <>
        <DropdownSkeleton
          width={width}
          height={height}
          placeholder={placeholder}
          onClick={openDropdownHandler}
          variant={error && touched ? errorVariant : variant}
          onBlur={onBlur}
        >
          <div className={styles.tags}>
            {tags.length > 0 ? (
              <>
                {tags.map((tag, index) => (
                  <div className={styles.singleTag} key={index}>
                    <span>{tag.value}</span>
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTags(index);
                      }}
                    >
                      <MdClose />
                    </i>
                  </div>
                ))}
              </>
            ) : (
              <p>{placeholder}</p>
            )}
          </div>
          <DropIcon>
            <FaCaretDown
              className={openDropdown ? styles.arr__open : styles.arr__close}
              onClick={() => {
                setOpenDropdown(!openDropdown);
              }}
            />
          </DropIcon>
        </DropdownSkeleton>
        {openDropdown && (
          <DropdownBody
            width={width}
            onMouseLeave={() => {
              setOpenDropdown(!openDropdown);
            }}
          >
            <div className="searchInMiddle">
              <Searchbar2
                placeholder="Search list"
                input={dropDownOptions}
                loading={false}
                output={setFilteredDropdown}
                handleFilter={(item, searchQuery) => {
                  return item?.value?.toLowerCase()?.includes(searchQuery);
                }}
                width={Math.round(width * 0.85)}
                showIcon={false}
                mb={1}
              />
            </div>

            {filteredDropdown.map((item, i) => (
              <div key={item.key} className={styles.select__options}>
                <ReceiptCheckBox
                  title={item.value}
                  name={item.key}
                  id={item.key}
                  value={item.key}
                  onChange={(e) => {
                    e.stopPropagation();
                    dropDownSetHandler(item);
                  }}
                  checked={tags.find((x) => x.key === item.key)}
                />
              </div>
            ))}
          </DropdownBody>
        )}
      </>

      {error ? (
        <div className={styles.error}>{touched ? error : ""}</div>
      ) : null}
    </DivSkeleton>
  );
};

const DropdownSkeleton = styled.div`
  ${dropdownStyles.multiDivBase};
  ${(props) => props.variant && dropdownStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(touched) => (touched.id || touched.name ? `color: var(--color-dark)` : "")}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
`;

const DropdownBody = styled.div`
  ${dropdownStyles.multiBodyBase};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const DivSkeleton = styled.div`
  ${dropdownStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const DropIcon = styled.div`
  ${dropdownStyles.iconBase};
`;

MimaMultiDropdown.propTypes = propTypes;

export default MimaMultiDropdown;
