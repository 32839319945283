import { MimaText, Modal, MimaButton } from '../../components';
import React from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { walletStore } from '../../stores';


const BankAccountReq = ({
    closeModal,
    goBack,
    loading,
    createBankAccount
}) => {

    return (
        <Modal closeModal={closeModal} goBack={goBack}>
            <MimaText variant="subtitleBold" mb={3}>Create Business Bank Account</MimaText>

            {walletStore.creatingAccNo ? (
                <>
                    <MimaText mb={3} width={38} align="center" color="var(--color-primary)" >
                        You have previously created a request and verification is in progress
                    </MimaText>
                    <MimaButton title="Continue" onClick={closeModal} />
                </>) : (
                <>
                    <AiFillWarning style={{
                        color: "var(--color-error)",
                        fontSize: "10rem"
                    }} />
                    <MimaText mt={2} mb={3} width={38} align="center" color="var(--color-error)">You will be charged a non refundable fee of 2,000 Naira for business verification.</MimaText>
                    <MimaText variant="small" mb={3} width={38} align="center">Clicking the continue button below means you accept our terms and conditions</MimaText>

                    <MimaButton title="Continue" onClick={createBankAccount} loading={loading} disabled={walletStore.creatingAccNo} />
                </>)
            }


        </Modal >
    );
};

export default BankAccountReq;
