import React, { useMemo, useState } from "react";
import {
  AppShell,
  MimaButton,
  MimaDropZone,
  MimaInput,
  MimaText,
  MimaTextArea,
  ReceiptCheckBox as RCB,
  ReactQuillInput,
} from "../../components";
import styles from "../../assets/styles/websiteConfig/websiteConfig.module.scss";
import useWebsiteConfig from "./useLogic/useWebsiteConfig";
import { Field, FieldArray, Form, Formik } from "formik";
import { businessStore, websiteStore } from "../../stores";
import { amountFormatter } from "../../utils/utils";
import DOMPurify from "dompurify";

const FieldTextArea = ({ field, ...props }) => {
  return <MimaTextArea {...field} {...props} />;
};

const WebsiteConfig = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const DangerousContent = ({ content }) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  };

  const { webConfig } = websiteStore;

  const configData = useMemo(() => {
    return webConfig?.website;
  }, [webConfig]);
  const tawkData = useMemo(() => {
    return webConfig?.tawk;
  }, [webConfig]);

  const sanitizedAboutUs = useMemo(() => {
    return DOMPurify.sanitize(configData?.aboutUs?.text || "");
  }, [configData?.aboutUs?.text]);
  const sanitizedRefundPolicy = useMemo(() => {
    return DOMPurify.sanitize(configData?.refundPolicy || "");
  }, [configData?.refundPolicy]);

  const sanitizedTermsOfService = useMemo(() => {
    return DOMPurify.sanitize(configData?.termsOfService || "");
  }, [configData?.termsOfService]);

  const initialValues = {
    tawk: {
      propertyId: tawkData?.propertyId || "",
      widgetId: tawkData?.widgetId || "",
    },
    website: {
      homeBanner: {
        imageUrl: [],
        title: configData?.homeBanner?.title || "",
      },
      collectionsMenuStyle:
        configData?.collectionsMenuStyle || "DISPLAY_ON_MENU_BAR",
      aboutUs: {
        imageUrl: [],
        text: configData?.aboutUs?.text || "",
      },

      faqs: configData?.faqs.map((x) => ({
        question: x.question,
        text: x.text,
      })) || [
        {
          question: "",
          text: "",
        },
      ],
      sizeGuides: configData?.sizeGuides.map((x) => x) || [],
      shipping: {
        local: {
          shippingFee: configData?.shipping.local.shippingFee || 0,
          freeShippingForOrderAbove:
            configData?.shipping.local.freeShippingForOrderAbove || 0,
        },
        international: {
          shippingFee: configData?.shipping.international.shippingFee || 0,
          freeShippingForOrderAbove:
            configData?.shipping.international.freeShippingForOrderAbove || 0,
        },
      },
      maintenanceMode: configData?.maintenanceMode || false,
      refundPolicy: configData?.refundPolicy || "",
      refundPolicyDays: 5,
      useDefaultRefundPolicy: configData?.refundPolicy ? false : true,
      termsOfService: configData?.termsOfService || "",
      useDefaulTermsOfService: configData?.termsOfService ? false : true,
      privacyPolicy: "",
    },
    homeBannerImageUrl: configData?.homeBanner?.imageUrl
      ? [
          {
            name: configData?.homeBanner?.imageUrl,
            preview: configData?.homeBanner?.imageUrl,
          },
        ]
      : [],
    aboutUsImageUrl: configData?.aboutUs?.imageUrl
      ? [
          {
            name: configData?.aboutUs.imageUrl,
            preview: configData?.aboutUs.imageUrl,
          },
        ]
      : [],
  };
  const {
    navData,
    menuBar,
    homePageBanner,
    aboutPage,
    localShipping,
    internationalShipping,
    refundPolicy,
    terms,
    faqs,
    sizeGuide,
    maintenanceMode,
    validationSchema,
    onSubmit,
    loading,
    setEditMenuStyle,
    editMenuStyle,
    setEditHomePage,
    editHomePage,
    setEditAboutPage,
    editAboutPage,
    setEditLocalShipping,
    setEditInternationalShipping,
    editInternationalShipping,
    setEditRefundPolicy,
    editRefundPolicy,
    setEditTerms,
    editTerms,
    setEditFaq,
    editFaq,
    setEditSizeGuide,
    editSizeGuide,
    setEditMaintenance,
    editMaintenance,
    editLocalShipping,
    liveChat,
    editLiveChat,
    setEditLiveChat,
  } = useWebsiteConfig();
  const business = businessStore.business;
  return (
    <AppShell pageTitle="Website Configuration">
      <div className={styles.container}>
        <div className={styles.container_nav}>
          {navData.map((nav, i) => (
            <div
              onClick={() => {
                const link = nav.link;
                setActiveIndex(i);
                link();
              }}
              className={
                activeIndex === i
                  ? styles.container_nav_item_active
                  : styles.container_nav_item
              }
            >
              {nav.title}
            </div>
          ))}
        </div>
        <div className={styles.container_form}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <Form>
                {menuBar && (
                  <>
                    {menuBar && editMenuStyle ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Menu Bar Style
                        </MimaText>
                        <div className={styles.checks}>
                          <RCB
                            title="Collection displayed on menu bar"
                            name="website.collectionsMenuStyle"
                            onChange={() => {
                              setFieldValue(
                                "website.collectionsMenuStyle",
                                "DISPLAY_ON_MENU_BAR"
                              );
                            }}
                            checked={
                              values.website.collectionsMenuStyle ===
                              "DISPLAY_ON_MENU_BAR"
                            }
                            value="DISPLAY_ON_MENU_BAR"
                          />
                          <RCB
                            title="Collection displayed under shop menu"
                            onChange={() => {
                              setFieldValue(
                                "website.collectionsMenuStyle",
                                "DISPLAY_UNDER_SHOP_MENU"
                              );
                            }}
                            checked={
                              values.website.collectionsMenuStyle ===
                              "DISPLAY_UNDER_SHOP_MENU"
                            }
                            name="website.collectionsMenuStyle"
                            value="DISPLAY_UNDER_SHOP_MENU"
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <MimaText
                          labelTitle="Current Menu Bar Style:"
                          variant="bodyBold"
                          mb={2}
                        >
                          {configData?.collectionsMenuStyle}
                        </MimaText>
                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditMenuStyle(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </div>
                    )}
                  </>
                )}
                {homePageBanner && (
                  <>
                    {editHomePage ? (
                      <>
                        <MimaText variant="bodyBold" mb={3}>
                          Change Home Page Banner
                        </MimaText>
                        <div className={styles.dropZone}>
                          <MimaDropZone
                            title="Upload Homepage Banner"
                            info="Click to upload banner"
                            maxNumOfImages={1}
                            files={values.homeBannerImageUrl}
                            setFiles={(newFiles) => {
                              setFieldValue("homeBannerImageUrl", newFiles);
                            }}
                            labelTitle="Upload Image"
                            name="imageUrls"
                          />
                        </div>
                        <MimaInput
                          type="text"
                          labelTitle="Banner Overlay Text"
                          placeholder="Enter Overlay Text"
                          name="website.homeBanner.title"
                          mb={2}
                          value={values.website.homeBanner.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </>
                    ) : (
                      <div>
                        <MimaText variant="body" mb={3}>
                          Current Home Page Banner
                        </MimaText>
                        <MimaText labelTitle="Title" variant="bodyBold" mb={3}>
                          {configData?.homeBanner?.title}
                        </MimaText>
                        <div>
                          <div>Banner</div>
                          <img
                            src={configData?.homeBanner?.imageUrl}
                            alt=""
                            sizes="auto"
                          />
                        </div>

                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditHomePage(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </div>
                    )}
                  </>
                )}

                {aboutPage && (
                  <>
                    {editAboutPage ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Change About Us Page
                        </MimaText>
                        <div className={styles.dropZone}>
                          <MimaDropZone
                            title="Upload About Us Picture"
                            info="Click to upload picture"
                            maxNumOfImages={1}
                            files={values.aboutUsImageUrl}
                            setFiles={(newFiles) => {
                              setFieldValue("aboutUsImageUrl", newFiles);
                            }}
                            labelTitle="Upload Image"
                            name="imageUrls"
                          />
                        </div>
                        <ReactQuillInput
                          name={`website.aboutUs.text`}
                          labelTitle="About Us Content"
                          error={errors?.website?.aboutUs?.text}
                          touched={touched?.website?.aboutUs?.text}
                          placeholder="Enter Content"
                          width={50}
                          mt={2}
                          mb={5}
                        />
                      </div>
                    ) : (
                      <>
                        <MimaText variant="body" mb={3}>
                          Current About Us Page
                        </MimaText>
                        <DangerousContent content={sanitizedAboutUs} />

                        <div>
                          <div>Banner</div>
                          <img
                            src={configData?.aboutUs?.imageUrl}
                            alt=""
                            sizes="auto"
                          />
                        </div>
                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditAboutPage(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}
                {localShipping && (
                  <>
                    {editLocalShipping ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          {`Set Local Shipping: ${business.localCurrency}`}
                        </MimaText>
                        <div className={styles.checks}>
                          <MimaInput
                            type="number"
                            labelTitle="Local Shipping Fee"
                            name="website.shipping.local.shippingFee"
                            id="website.shipping.local.shippingFee"
                            value={values.website.shipping.local.shippingFee}
                            onChange={handleChange}
                            error={
                              errors?.website?.shipping?.local?.shippingFee
                            }
                            touched={
                              touched?.website?.shipping?.local?.shippingFee
                            }
                            onBlur={handleBlur}
                            width={29}
                          />

                          <MimaInput
                            type="number"
                            labelTitle="Free Shipping for Orders Above"
                            name="website.shipping.local.freeShippingForOrderAbove"
                            id="website.shipping.local.freeShippingForOrderAbove"
                            value={
                              values.website.shipping.local
                                .freeShippingForOrderAbove
                            }
                            onChange={handleChange}
                            error={
                              errors?.website?.shipping?.local
                                ?.freeShippingForOrderAbove
                            }
                            touched={
                              touched?.website?.shipping?.local
                                ?.freeShippingForOrderAbove
                            }
                            onBlur={handleBlur}
                            width={29}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <MimaText variant="bodyBold" mb={3}>
                          {`Current Local Shipping state: ${business.localCurrency}`}
                        </MimaText>
                        {configData?.shipping.local.shippingFee && (
                          <MimaText
                            labelTitle="Shipping Fee:"
                            variant="bodyBold"
                            mb={1}
                          >
                            {amountFormatter(business.localCurrency).format(
                              configData?.shipping.local.shippingFee
                            )}
                          </MimaText>
                        )}
                        {configData?.shipping.local
                          .freeShippingForOrderAbove && (
                          <MimaText
                            labelTitle="Free Shipping For Order Above:"
                            variant="bodyBold"
                          >
                            {amountFormatter(business.localCurrency).format(
                              configData?.shipping.local
                                .freeShippingForOrderAbove
                            )}
                          </MimaText>
                        )}

                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditLocalShipping(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}
                {internationalShipping && (
                  <>
                    {editInternationalShipping ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Set International Shipping
                        </MimaText>
                        <div className={styles.checks}>
                          <MimaInput
                            type="number"
                            labelTitle="Shipping Fee (USD)"
                            name="website.shipping.international.shippingFee"
                            id="website.shipping.international.shippingFee"
                            value={
                              values.website.shipping.international.shippingFee
                            }
                            onChange={handleChange}
                            error={
                              errors?.website?.shipping?.international
                                ?.shippingFee
                            }
                            touched={
                              touched?.website?.shipping?.international
                                ?.shippingFee
                            }
                            onBlur={handleBlur}
                            width={29}
                          />

                          <MimaInput
                            type="number"
                            labelTitle="Free Shipping for Orders Above (USD)"
                            name="website.shipping.international.freeShippingForOrderAbove"
                            id="website.shipping.international.freeShippingForOrderAbove"
                            value={
                              values.website.shipping.international
                                .freeShippingForOrderAbove
                            }
                            onChange={handleChange}
                            error={
                              errors?.website?.shipping?.international
                                ?.freeShippingForOrderAbove
                            }
                            touched={
                              touched?.website?.shipping?.international
                                ?.freeShippingForOrderAbove
                            }
                            onBlur={handleBlur}
                            width={29}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <MimaText variant="bodyBold" mb={3}>
                          Current International Shipping Fee
                        </MimaText>
                        {configData?.shipping.international.shippingFee && (
                          <MimaText
                            labelTitle="Shipping Fee:"
                            variant="bodyBold"
                            mb={1}
                          >
                            {amountFormatter("USD").format(
                              configData?.shipping.international.shippingFee
                            )}
                          </MimaText>
                        )}
                        {configData?.shipping.international
                          .freeShippingForOrderAbove && (
                          <MimaText
                            labelTitle="Free Shipping For Order Above:"
                            variant="bodyBold"
                          >
                            {amountFormatter("USD").format(
                              configData?.shipping.international
                                .freeShippingForOrderAbove
                            )}
                          </MimaText>
                        )}
                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditInternationalShipping(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}
                {refundPolicy && (
                  <>
                    {editRefundPolicy ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Set Default Standard Refund Policy
                        </MimaText>
                        <div className={styles.checks}>
                          <RCB
                            title="Use default Standard Refund Policy"
                            onChange={() => {
                              setFieldValue(
                                "website.useDefaultRefundPolicy",
                                true
                              );
                            }}
                            checked={values.website.useDefaultRefundPolicy}
                            value={values.website.useDefaultRefundPolicy}
                            name="website.useDefaultRefundPolicy"
                          />
                          <RCB
                            title="State your Custom Policy"
                            onChange={() => {
                              setFieldValue(
                                "website.useDefaultRefundPolicy",
                                false
                              );
                            }}
                            checked={!values.website.useDefaultRefundPolicy}
                            value={!values.website.useDefaultRefundPolicy}
                            name="website.useDefaultRefundPolicy"
                          />
                        </div>
                        {values.website.useDefaultRefundPolicy === true && (
                          <MimaInput
                            type="number"
                            labelTitle="In How Many Days Is Return Valid"
                            name="website.refundPolicyDays"
                            id="website.refundPolicyDays"
                            value={values.website.refundPolicyDays}
                            onChange={handleChange}
                            error={errors.website?.refundPolicyDays}
                            touched={touched.website?.refundPolicyDays}
                            onBlur={handleBlur}
                            width={18}
                          />
                        )}
                        {values.website.useDefaultRefundPolicy === false && (
                          <ReactQuillInput
                            name={`website.refundPolicy`}
                            labelTitle="Refund Policy"
                            error={errors?.website?.refundPolicy}
                            touched={touched?.website?.refundPolicy}
                            placeholder="State policy"
                            width={50}
                            mt={2}
                            mb={5}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <MimaText variant="bodyBold" mb={3}>
                          Current Standard Refund Policy
                        </MimaText>

                        {configData?.refundPolicy && (
                          <>
                            <div>Refund Policy:</div>
                            <DangerousContent content={sanitizedRefundPolicy} />
                          </>
                        )}

                        {configData?.refundPolicyDays && (
                          <MimaText
                            labelTitle="Refund Policy Days:"
                            variant="body"
                            mb={3}
                            mt={2}
                          >
                            {configData?.refundPolicyDays}
                          </MimaText>
                        )}

                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditRefundPolicy(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}
                {terms && (
                  <>
                    {editTerms ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Set Terms of Service
                        </MimaText>
                        <div className={styles.checks}>
                          <RCB
                            title="Use default Standard Terms of Service"
                            onChange={() => {
                              setFieldValue(
                                "website.useDefaulTermsOfService",
                                true
                              );
                            }}
                            checked={values.website.useDefaulTermsOfService}
                            value={values.website.useDefaulTermsOfService}
                            name="website.useDefaulTermsOfService"
                          />
                          <RCB
                            title="State your Custom Term"
                            onChange={() => {
                              setFieldValue(
                                "website.useDefaulTermsOfService",
                                false
                              );
                            }}
                            checked={!values.website.useDefaulTermsOfService}
                            value={!values.website.useDefaulTermsOfService}
                            name="website.useDefaulTermsOfService"
                          />
                        </div>
                        {values.website.useDefaulTermsOfService === false && (
                          <ReactQuillInput
                            name={`website.termsOfService`}
                            labelTitle="Terms of Service"
                            error={errors?.website?.termsOfService}
                            touched={touched?.website?.termsOfService}
                            placeholder="Enter Terms of Service"
                            width={50}
                            mt={2}
                            mb={5}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <MimaText variant="bodyBold" mb={3}>
                          Terms of Service
                        </MimaText>

                        <DangerousContent content={sanitizedTermsOfService} />

                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditTerms(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}
                {faqs && (
                  <>
                    {editFaq ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Add FAQs
                        </MimaText>
                        <FieldArray name="website.faqs">
                          {({ push, remove }) => (
                            <>
                              {values.website.faqs.map((_, i) => (
                                <>
                                  <div key={i} className={styles.checks}>
                                    <Field
                                      name={`website.faqs.${i}.question`}
                                      id={`website.faqs.${i}.question`}
                                      component={FieldTextArea}
                                      type="text"
                                      placeholder="Enter Question"
                                      labelTitle="FAQ Question"
                                      variant="form"
                                      width={28}
                                    />
                                    <Field
                                      name={`website.faqs.${i}.text`}
                                      id={`website.faqs.${i}.text`}
                                      component={FieldTextArea}
                                      type="text"
                                      placeholder="Enter Answer"
                                      labelTitle="FAQ Answer"
                                      variant="form"
                                      width={28}
                                    />
                                  </div>
                                  {i !== 0 && (
                                    <div>
                                      <MimaButton
                                        title="Remove"
                                        variant="filter"
                                        type="button"
                                        buttonColor="var(--color-error)"
                                        width={10}
                                        mt={0.5}
                                        onClick={() => remove(i)}
                                      />
                                    </div>
                                  )}
                                </>
                              ))}
                              <MimaButton
                                title="Add Another FAQ"
                                variant="outlined"
                                type="button"
                                height={3}
                                mt={1}
                                onClick={() =>
                                  push({
                                    question: "",
                                    text: "",
                                  })
                                }
                              />
                            </>
                          )}
                        </FieldArray>
                      </div>
                    ) : (
                      <>
                        <MimaText variant="bodyBold" mb={3}>
                          FAQs
                        </MimaText>

                        {configData?.faqs.map((x, i) => (
                          <div key={i}>
                            <MimaText
                              labelTitle="Question:"
                              variant="body"
                              mb={1}
                            >
                              {x.question}
                            </MimaText>
                            <MimaText labelTitle="Answer:" variant="body">
                              {x.text}
                            </MimaText>
                          </div>
                        ))}
                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditFaq(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}
                {liveChat && (
                  <>
                    {editLiveChat ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Edit Live chat info
                        </MimaText>
                        <div className={styles.checks}>
                          <MimaInput
                            type="text"
                            labelTitle="Property Id"
                            name="tawk.propertyId"
                            id="tawk.propertyId"
                            value={values.tawk.propertyId}
                            onChange={handleChange}
                            error={errors?.tawk?.propertyId}
                            touched={touched?.tawk?.propertyId}
                            onBlur={handleBlur}
                            width={29}
                          />
                          <MimaInput
                            type="text"
                            labelTitle="Widget Id"
                            name="tawk.widgetId"
                            id="tawk.widgetId"
                            value={values.tawk.widgetId}
                            onChange={handleChange}
                            error={errors?.tawk?.widgetId}
                            touched={touched?.tawk?.widgetId}
                            onBlur={handleBlur}
                            width={29}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <MimaText variant="bodyBold" mb={3}>
                          Live Chats Info
                        </MimaText>
                        <MimaText
                          labelTitle="Property Id:"
                          variant="bodyBold"
                          mb={3}
                        >
                          {tawkData?.propertyId}
                        </MimaText>
                        <MimaText
                          labelTitle="Widget Id:"
                          variant="bodyBold"
                          mb={3}
                        >
                          {tawkData?.widgetId}
                        </MimaText>

                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditLiveChat(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}
                {sizeGuide && (
                  <>
                    {editSizeGuide ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Add Size Guides
                        </MimaText>
                        <div className={styles.checks}>
                          <RCB
                            title="Male"
                            id="sizeGuidesMale"
                            name="sizeGuides"
                            onChange={() => {
                              const newSizeGuides =
                                values?.website?.sizeGuides?.includes("MALE")
                                  ? values.website.sizeGuides.filter(
                                      (item) => item !== "MALE"
                                    )
                                  : [...values.website.sizeGuides, "MALE"];
                              setFieldValue(
                                "website.sizeGuides",
                                newSizeGuides
                              );
                            }}
                            checked={values?.website?.sizeGuides?.includes(
                              "MALE"
                            )}
                            value="MALE"
                          />
                          <RCB
                            title="Female"
                            id="sizeGuidesFemale"
                            name="sizeGuides"
                            onChange={() => {
                              const newSizeGuides =
                                values?.website?.sizeGuides?.includes("FEMALE")
                                  ? values.website.sizeGuides.filter(
                                      (item) => item !== "FEMALE"
                                    )
                                  : [...values.website.sizeGuides, "FEMALE"];
                              setFieldValue(
                                "website.sizeGuides",
                                newSizeGuides
                              );
                            }}
                            checked={values?.website?.sizeGuides?.includes(
                              "FEMALE"
                            )}
                            value="FEMALE"
                          />
                          <RCB
                            title="Children"
                            id="sizeGuidesChildren"
                            name="sizeGuides"
                            onChange={() => {
                              const newSizeGuides =
                                values?.website?.sizeGuides?.includes("CHILD")
                                  ? values.website.sizeGuides.filter(
                                      (item) => item !== "CHILD"
                                    )
                                  : [...values.website.sizeGuides, "CHILD"];
                              setFieldValue(
                                "website.sizeGuides",
                                newSizeGuides
                              );
                            }}
                            checked={values?.website?.sizeGuides?.includes(
                              "CHILD"
                            )}
                            value="CHILD"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <MimaText variant="bodyBold" mb={3}>
                          Size Guides
                        </MimaText>
                        <MimaText variant="body" mb={3}>
                          {configData?.sizeGuides.map((x) => {
                            return <MimaText>{x}</MimaText>;
                          })}
                        </MimaText>

                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditSizeGuide(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}
                {maintenanceMode && (
                  <>
                    {editMaintenance ? (
                      <div>
                        <MimaText variant="bodyBold" mb={3}>
                          Set Website to maintenance Mode
                        </MimaText>
                        <div className={styles.checks}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("website.maintenanceMode", true);
                            }}
                            checked={values.website.maintenanceMode}
                            value={values.website.maintenanceMode}
                            name="website.maintenanceMode"
                          />
                          <RCB
                            title="No"
                            onChange={() => {
                              setFieldValue("website.maintenanceMode", false);
                            }}
                            checked={!values.website.maintenanceMode}
                            value={!values.website.maintenanceMode}
                            name="website.maintenanceMode"
                          />
                        </div>{" "}
                      </div>
                    ) : (
                      <>
                        <MimaText
                          labelTitle="maintenance Mode:"
                          variant="bodyBold"
                          mb={3}
                        >
                          {configData?.maintenanceMode ? "True" : "False"}
                        </MimaText>
                        <MimaButton
                          title="Change"
                          variant="form"
                          onClick={() => {
                            setEditMaintenance(true);
                          }}
                          loading={loading}
                          width={10}
                          mt={2}
                        />
                      </>
                    )}
                  </>
                )}

                {(editMenuStyle ||
                  editHomePage ||
                  editAboutPage ||
                  editFaq ||
                  editInternationalShipping ||
                  editLocalShipping ||
                  editTerms ||
                  editRefundPolicy ||
                  editMaintenance ||
                  editSizeGuide ||
                  editLiveChat) && (
                  <MimaButton
                    title="Save"
                    variant="form"
                    onClick={handleSubmit}
                    type="submit"
                    loading={loading}
                    width={10}
                    mt={2}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </AppShell>
  );
};

export default WebsiteConfig;
