import { SettingsController, UserController } from "../../../controllers";
import { appStore, useStores } from "../../../stores";
import { MimaToastUtil } from "../../../components";
import { number, object, string } from "yup";
import constant from "../../../utils/constant";
import React, { useState } from "react";
import { useEffect } from "react";

const useSecurityLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [pinChange, setPinChange] = useState(false);
  const [pinSet, setPin] = useState(false);

  useEffect(() => {
    const getSecurityQuestions = async () => {
      const { status, data, errorMessage } =
        await SettingsController.securityQuestions();
    };
    getSecurityQuestions();
  }, []);

  let { appStore, userStore } = useStores();

  const goBack = () => {
    if (pinChange) {
      setPinChange(false);
    } else if (passwordChange) {
      setPasswordChange(false);
    } else if (pinSet) {
      setPin(false);
    } else {
      closeModal();
    }
  };

  const hasPin = userStore.user.hasPin;
  const changePasswordValidationSchema = () =>
    object({
      newPassword: string().required("Enter the new password value"),
      password: string().required("Enter the password value"),
      confirmNewPassword: string()
        .required("Enter the new confirm password value")
        .test(function () {
          const { newPassword, confirmNewPassword } = this.parent;
          return newPassword !== confirmNewPassword
            ? this.createError({
                message:
                  "New password and confirm new password should be the same",
                path: "confirmNewPassword",
              })
            : true;
        }),
    });
  const changePinValidationSchema = () =>
    object({
      password: string().required("Enter the password value"),
      secretAnswer: string().required(
        "Enter your answer to the secret question"
      ),
      newPin: string()
        .matches(
          /^\d{4}$/,
          "PIN must be exactly 4 digits and cannot contain spaces"
        )
        .required("Enter the new pin value"),
      // newPin: number().required("Enter the new pin value").max(9999).min(1000),
    });
  const setPinValidationSchema = () =>
    object({
      password: string().required("Enter the password value"),
      secretQuestion: string().required("Select a secret question"),
      secretAnswer: string().required(
        "Enter your answer to the secret question"
      ),
      pin: string()
        .matches(
          /^\d{4}$/,
          "PIN must be exactly 4 digits and cannot contain spaces"
        )
        .required("Enter the new pin value"),
      // pin: number().required("Enter the new pin value").max(9999).min(1000),
    });
  const onSubmitChangePin = async (payload) => {
    setLoading(true);
    const { status, errorMessage } = await UserController.changePin({
      password: payload.password,
      newPin: payload.newPin,
      secretAnswer: payload.secretAnswer,
    });

    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
    } else {
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
    closeModal();
  };
  const onSubmitSetPin = async (payload) => {
    setLoading(true);
    const { status, errorMessage } = await UserController.setPin({
      password: payload.password,
      pin: payload.pin,
      secretQuestion: payload.secretQuestion,
      secretAnswer: payload.secretAnswer,
    });
    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return userStore.setLoggedIn(false);
    } else {
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
    closeModal();
  };

  const onSubmitChangePassword = async (payload) => {
    setLoading(true);
    const { newPassword, password } = payload;
    const { status, errorMessage } = await UserController.changePassword({
      password,
      newPassword,
    });
    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return goToPasswordChangeSuccess();
    }

    MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const goToPasswordChange = () => {
    setPinChange(false);
    setPasswordChange(true);
    setPin(false);
  };

  const goToPasswordChangeSuccess = () => {
    setPasswordChange(false);
    setPinChange(false);
  };

  const goToPinChange = () => {
    setPinChange(true);
    setPasswordChange(false);
    setPin(false);
  };
  const goToSetPin = () => {
    setPin(true);
    setPasswordChange(false);
    setPinChange(false);
  };

  const securityQuestions = appStore.securityQuestions;

  return {
    securityQuestions,
    onSubmitChangePin,
    onSubmitSetPin,
    hasPin,
    closeModal,
    changePinValidationSchema,
    loading,
    goToPasswordChange,
    onSubmitChangePassword,
    goToPinChange,
    setPinValidationSchema,
    pinSet,
    goToSetPin,
    passwordChange,
    changePasswordValidationSchema,
    pinChange,
    goBack,
  };
};

export default useSecurityLogic;
