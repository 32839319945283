import {
  IconButton,
  Sidebar,
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  Modal,
} from "../../components";
import styles from "../../assets/styles/Wallet/TransferFunds.module.scss";
import TransferFailedImage from "../../assets/img/MimaActionFailed.png";
import React from "react";

const TransferFailed = ({ closeModal }) => {
  return (
    <div className={styles.centerContent}>
      <img src={TransferFailedImage} className={styles.transferFailedImg} />
      <MimaText align="center" variant="bodyBold" mb={3}>
        Unfortunately, your transfer <br /> was Unsuccessful! Kindly try again
        later.
      </MimaText>

      <div className={styles.buttonBeside}>
        <MimaButton
          title="Exit"
          onClick={closeModal}
          type="submit"
          variant="regular"
        />
      </div>
    </div>
  );
};

export default TransferFailed;
