import TransactionsController from "./TransactionsController";
import { apiRequest } from "../utils/useAPIRequest";
import { appStore, walletStore } from "../stores";
import constant from "../utils/constant";

const walletDetails = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/wallets/new/business",
    method: "get",
  });
  if (status === constant.Success) {
    walletStore.setWallets(data);
    // walletStore.setBalance(data.balance);
    // walletStore.setTransferLimit(data.transferlimit);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const subWalletDetails = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/wallets/subwallets/business/",
    method: "get",
  });
  if (status === constant.Success) {
    walletStore.setSubWallets(data);

    return { status: constant.Success };
  }
  return { errorMessage, status };
};
const createSubWallet = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/wallets/sub-wallet/business",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    await subWalletDetails();
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const updateSubWallet = async (payload, id) => {
  const { status, errorMessage } = await apiRequest({
    url: `/wallets/sub-wallet/${id}`,
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    await subWalletDetails();
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const assignSubWallet = async (payload, id) => {
  const { status, errorMessage } = await apiRequest({
    url: `/wallets/sub-wallet/update-user-access/${id}`,
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    await subWalletDetails();
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const beneficiaries = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/banking/beneficiaries/business",
    method: "get",
  });

  if (status === constant.Success) {
    const beneficiaries = data.beneficiaries.map((beneficiary) => {
      return {
        key: beneficiary._id,
        value: {
          accountName: beneficiary.accountName,
          accountNumber: beneficiary.accountNumber,
          bankName: beneficiary.bankName,
          bankCode: beneficiary.bankCode,
        },
      };
    });
    appStore.setBeneficiaries(beneficiaries);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const banks = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/banking/banks",
    method: "get",
  });
  if (status === constant.Success) {
    const banks = data.banks.map((bank) => {
      return { key: bank.bankCode, value: bank.bankName };
    });
    appStore.setBanks(banks);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const verifyAccountNumber = async (payload) => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/banking/bank/verify-account-details",
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    await beneficiaries();
    return { status: constant.Success, data };
  }
  return { errorMessage, status };
};
const verifyPaymentMethods = async (payload) => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/bank-accounts/payment-methods/${payload.currencyCode}/${payload.countryCode}`,
    method: "get",
    payload,
  });

  if (status === constant.Success) {
    await beneficiaries();
    return { status: constant.Success, data };
  }
  return { errorMessage, status };
};

const transferFund = async (payload) => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/banking/bank/fund-transfer",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    await TransactionsController.transactions();
    await walletDetails();
    await beneficiaries();
    return { status: constant.Success };
  }
  return { errorMessage, status, data };
};

const transferSubWalletFund = async (payload) => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/banking/bank/sub-wallet-fund-transfer",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    await TransactionsController.transactions();
    await Promise.all([
      walletDetails(),
      subWalletDetails(),
      TransactionsController.subWalletTransactions(),
    ]);
    return { status: constant.Success };
  }
  return { errorMessage, status, data };
};

const deactivateSubWalletFund = async (id) => {
  const { status, errorMessage } = await apiRequest({
    url: `/wallets/sub-wallet/deactivate/${id}`,
    method: "patch",
  });
  if (status === constant.Success) {
    await TransactionsController.transactions();
    await Promise.all([walletDetails(), subWalletDetails()]);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const fundForeignWallet = async (payload) => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/banking/create-funding-payment-link",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    await TransactionsController.transactions();
    await walletDetails();
    return { status: constant.Success, data };
  }

  return { errorMessage, status, data };
};

const deleteBeneficiary = async (id) => {
  const { status, errorMessage } = await apiRequest({
    url: `/banking/beneficiary/${id}`,
    method: "delete",
  });

  if (status === constant.Success) {
    await beneficiaries();
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const cardFundWallet = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/banking/fund-wallet-with-card",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    await TransactionsController.transactions();
    await walletDetails();
    return { status: constant.Success, data };
  }
  return { errorMessage, status, data };
};

const downloadAccountStatement = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}&currencyCode=${payload.currencyCode}`;

  const { status, errorMessage } = await apiRequest({
    url: `/transactions/accountStatement?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const getDynamicWalletFundingAccount = async (query) => {
  if (!query) {
    query = "";
  }

  const { status, errorMessage, data } = await apiRequest({
    url: `/bank-accounts/dynamic-wallet-funding?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    walletStore.setDynamicAccountNumbers(data);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const getWalletReceipt = async (transferId) => {
  const { status, errorMessage, data } = await apiRequest({
    url: `/banking/banking/transfer-receipts/${transferId}`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { errorMessage, status };
};

const WalletController = {
  beneficiaries,
  assignSubWallet,
  transferFund,
  createSubWallet,
  verifyAccountNumber,
  transferSubWalletFund,
  banks,
  walletDetails,
  subWalletDetails,
  deactivateSubWalletFund,
  updateSubWallet,
  fundForeignWallet,
  deleteBeneficiary,
  cardFundWallet,
  downloadAccountStatement,
  getDynamicWalletFundingAccount,
  getWalletReceipt,
  verifyPaymentMethods,
};

export default WalletController;
