import { useState, useEffect, useMemo } from "react";
import OrderController from "../../controllers/OrderController";
import { ordersStore } from "../../stores";
import { theYear } from "../../utils/utils";
import { date, object, string } from "yup";

import { getDate } from "../../utils/utils";
import { filterPeriods } from "../../utils/utils";
import moment from "moment";

const useOrdersLogic = () => {
  const [loading, setLoading] = useState(false);
  const [filterPeriod, setFilterPeriod] = useState(`${theYear}`);
  const [updateModal, setUpdateModal] = useState(false);
  const [viewOrdersModal, setViewOrdersModal] = useState(false);
  const [onReset, setOnReset] = useState(false);
  const [limit, setLimit] = useState(50);
  const [filterQuery, setFilterQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const ordersTableQuery = useMemo(() => {
    return `offset=${currentPage + 1}&limit=${limit}`;
  }, [currentPage, limit]);

  const { isLoading, data, isFetching } = OrderController.useGetOrders(
    ordersTableQuery,
    filterQuery,
    searchQuery
  );

  const onLimitChange = (limit) => {
    setLimit(limit);
    setCurrentPage(0);
  };

  const { getOrdersAnalysis } = OrderController;

  useEffect(() => {
    const setOrders = async () => {
      setLoading(true);
      await getOrdersAnalysis();
      setLoading(false);
    };
    setOrders();
  }, []);

  const validationSchema = () => {
    return object({
      trackingStatus: string().when("startDate", {
        is: (startDate) => !startDate,
        then: (schema) => schema.required("Select a tracking Status"),
        otherwise: (schema) => schema.optional(),
      }),
      startDate: date().optional(),
      endDate: date().when("startDate", {
        is: (startDate) => startDate,
        then: (schema) => schema.required("End Date is required"),
        otherwise: (schema) => schema.optional(),
      }),
    });
  };

  const onSubmit = async (payload) => {
    let query;
    let analysisQuery;

    if (payload && Object.keys(payload).length > 0) {
      if (payload.startDate && payload.endDate && payload.trackingStatus) {
        query = `startDate=${payload.startDate}&endDate=${payload.endDate}&trackingStatus=${payload.trackingStatus}`;
        analysisQuery = `startDate=${payload.startDate}&endDate=${payload.endDate}`;
      } else if (payload.startDate && payload.endDate) {
        query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;
        analysisQuery = `startDate=${payload.startDate}&endDate=${payload.endDate}`;
      } else {
        query = `trackingStatus=${payload.trackingStatus}`;
      }

      if (payload.period === "custom") {
        setFilterPeriod(
          `${moment(payload?.startDate).format("DD-MM-YYYY")} - ${moment(payload.endDate).format("DD-MM-YYYY")}`
        );
      } else {
        setFilterPeriod(`${payload?.periodText}`);
      }
    }

    setLoading(true);
    await Promise.all([
      setFilterQuery(query),
      OrderController.getOrdersAnalysis(analysisQuery),
    ]);

    setLoading(false);
  };

  const resetFilter = () => {
    onSubmit();
    setFilterPeriod(`${theYear}`);
  };
  //   const refresh = async () => {
  //     setLoading(true);
  //     await Promise.all([refetch(), OrderController.getOrdersAnalysis()]);
  //     setLoading(false);
  //   };

  const closeModal = () => {
    setUpdateModal(false);
    setViewOrdersModal(false);
  };
  const ordersAnalysisList = ordersStore.ordersAnalysis;
  const orderData = data;

  return {
    loading,
    validationSchema,
    onSubmit,
    setOnReset,
    resetFilter,
    onReset,
    ordersAnalysisList,
    filterPeriod,
    updateModal,
    setUpdateModal,
    closeModal,
    viewOrdersModal,
    setViewOrdersModal,
    getDate,
    filterPeriods,
    isLoading,
    orderData,
    isFetching,
    limit,
    onLimitChange,
    currentPage,
    setCurrentPage,
    setSearchQuery,
  };
};

export default useOrdersLogic;
