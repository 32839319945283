import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { MdMoreVert } from 'react-icons/md';
import Popover from '@mui/material/Popover';
import MimaMenuItem from '../MimaMenuItem';

const propTypes = {
  options: PropTypes.array,
  selectedOption: PropTypes.string,
  onOptionSelected: PropTypes.func
}

const defaultProps = {
  options: [],
  selectedOption: '',
  onOptionSelected: () => {}
};

const handleLabel = option =>
  option
    .split('_')
    .map(arr => arr.charAt(0).toUpperCase() + arr.slice(1).toLowerCase())
    .join(' ');

const MimaOptionMenu = ({ options, selectedOption, onOptionSelected }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const optionMenuClick = event => {
    event.stopPropagation();
    setOpenMenu(event.currentTarget);
  };

  const optionMenuClose = event => {
    event.stopPropagation();
    setOpenMenu(null);
  };

  return (
    <>
      <div onClick={optionMenuClick}><MdMoreVert  style={{fontSize: '3rem'}}/></div>
      
      <Popover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={optionMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <>
          {options
            .filter(x => x && x.value !== selectedOption)
            .map(option => (
              <MimaMenuItem
                id={option.label}
                item={{ title: option.label }}
                key={option.label}
                onItemClick={event => {
                  optionMenuClose(event);
                  onOptionSelected(option);
                }}
              />
            ))}
        </>
      </Popover>    
    </>
  );
};

MimaOptionMenu.propTypes = propTypes;

MimaOptionMenu.defaultProps = defaultProps;

export default memo(MimaOptionMenu);
