import TransactionsController from "./TransactionsController";
import UserController from "./UserController";
import PayrollController from "./PayrollController";
import BusinessController from "./BusinessController";
import WalletController from "./WalletController";
import { apiRequest } from "../utils/useAPIRequest";
import BillController from "./BillController";
import constant from "../utils/constant";
import { appStore, payrollStore, userStore } from "../stores";

const { getNetworks, getBills } = BillController;
const { banks } = WalletController;
const { businessDetails } = BusinessController;

const {
  transactions,
  transactionSummary,
  subWalletTransactions,
  expenseAnalysis,
} = TransactionsController;
const { getPayrollList, getTags } = PayrollController;
const { getPermissions, getUser } = UserController;

const businessCategories = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/business-categories",
    method: "get",
  });
  if (status === constant.Success) {
    data.bizCategories = data.bizCategories.map((category) => {
      return {
        key: category._id,
        value: category.name,
      };
    });
    appStore.setBusinessCategories(data.bizCategories);
  }
  return { status, errorMessage };
};

const securityQuestions = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/securityQuestions",
    method: "get",
  });
  if (status === constant.Success) {
    data.securityQuestions = data.securityQuestions.map((data) => {
      return {
        key: data._id,
        value: data.question,
      };
    });
    appStore.setSecurityQuestions(data.securityQuestions);
  }
  return { status, errorMessage };
};

const setWithdrawalAccount = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/bank-accounts/business/withdrawal",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    await getUser(userStore.user._id);
    return { status };
  }
  return { status, errorMessage };
};
const setWithdrawalAccountInternational = async (payload, currencyCode) => {
  const { status, errorMessage } = await apiRequest({
    url: `/bank-accounts/foreign/withdrawal?currencyCode=${currencyCode}`,
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    await getUser(userStore.user._id);
    return { status };
  }
  return { status, errorMessage };
};

const calculateCharges = async (params) =>
  await apiRequest({
    url: "/charges/calculate-charge",
    method: "get",
    params,
  });

const expenseCategories = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/banking/expense-categories",
    method: "get",
  });
  data.expenseCategories = data?.expenseCategories.map((category) => {
    return {
      key: category._id,
      value: category.name,
    };
  });
  if (status === constant.Success) {
    appStore.setExpenseCategories(data.expenseCategories);
  }
  return { status, errorMessage };
};

const dashboardData = async () => {
  // await getTags();
  // const tag = payrollStore.tags[payrollStore.tags?.length - 1] || "";
  await Promise.all([
    expenseAnalysis(),
    banks(),
    // getPayrollList(`tag=${tag}`),
    transactionSummary(),
    businessDetails(),
    // getPermissions(),
    expenseCategories(),
  ]);
  appStore.setDashboardSetup(true);
};
const runTransactionSetup = async () => {
  await Promise.all([
    transactions(),
    subWalletTransactions(),
    transactionSummary(),
    expenseAnalysis(),
  ]);
};

const runPayBillsSetup = async () => {
  await Promise.all([
    // getNetworks(),
    getBills(),
  ]);
};

const runNoAuthSetup = async () => {
  await Promise.all([businessCategories()]);
};

const getLiveUpdate = async () => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/businesses/all-business-logo",
    method: "get",
  });

  if (status === constant.Success) {
    appStore.setLiveData(data.businesses);
  }
  return { status, errorMessage };
};

const getGeneralConfig = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/settings/business",
    method: "get",
  });

  if (status === constant.Success) {
    appStore.setGeneralSettings(data);

    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const updateGeneralConfig = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/settings/update-setting",
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    await getGeneralConfig();
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const getStoredCards = async () => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/banking/get-stored-card-details",
    method: "get",
  });

  if (status === constant.Success) {
    userStore.setAvailableCards(data);
  }
  return { status, errorMessage };
};

const addNewCard = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/banking/add-card-paystack",
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    await BusinessController.businessDetails();
    return { status: constant.Success };
  }

  return { status, errorMessage };
};

const deleteCard = async (query) => {
  const { status, errorMessage } = await apiRequest({
    url: `/banking/delete-stored-card?cardID=${query}`,
    method: "delete",
  });

  if (status === constant.Success) {
    await BusinessController.businessDetails();
    return { status: constant.Success };
  }

  return { status, errorMessage };
};

const getWithdrawalAccounts = async () => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/bank-accounts/business-withdrawal-accounts",
    method: "get",
  });

  if (status === constant.Success) {
    userStore.setWithdrawalAccounts(data);
  }
  return { status, errorMessage };
};

export default {
  runTransactionSetup,
  runNoAuthSetup,
  dashboardData,
  runPayBillsSetup,
  securityQuestions,
  calculateCharges,
  setWithdrawalAccount,
  expenseCategories,
  getLiveUpdate,
  getGeneralConfig,
  updateGeneralConfig,
  getStoredCards,
  addNewCard,
  deleteCard,
  setWithdrawalAccountInternational,
  getWithdrawalAccounts,
};
