import {
  MimaButton,
  MimaInput,
  MimaDropdown,
  MimaText,
  PageLoader,
} from "../../components";
import styles from "../../assets/styles/PayBills/PayBills.module.scss";
import { Formik } from "formik";
import React, { useMemo } from "react";
import { useGetPowerVendors } from "../../controllers/BillController";
import EnterPin from "./EnterPin";
import useElectricityLogic from "./useLogic/useElectricityLogic";

function Electricity({ pinContent, closeModal, setPinContent }) {
  const {
    bill,
    loading,
    validationSchema,
    accountName,
    validateMeterNumber,
    onSubmit,
    vendors,
  } = useElectricityLogic({ closeModal, pinContent, setPinContent });
  const isCopied = Object.keys(bill).length > 0;

  const { powerVendors } = useGetPowerVendors();

  const powerVendorsList = useMemo(() => {
    const mappedTvVendors = powerVendors.map((x) => {
      return {
        id: x.categoryId,
        value: x.shortname,
      };
    });
    return mappedTvVendors;
  }, [powerVendors]);

  return (
    <div>
      {!pinContent && (
        <Formik
          initialValues={{
            meterNumber: isCopied ? bill?.meterNumber : "",
            categoryId: isCopied
              ? powerVendorsList?.find((x) => x.value === bill.categoryName)?.id
              : "",
            categoryName: isCopied ? bill?.categoryName : "",
            amount: isCopied ? bill.amount : "",
            phone: isCopied ? bill.phone : "",
            accountName: isCopied ? bill.accountName : "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <>
              <MimaText variant="subtitle" mb={4} align="center">
                Electricity
              </MimaText>

              <MimaInput
                type="text"
                placeholder="Enter Meter No"
                labelTitle="Meter Number"
                name="meterNumber"
                mb={2}
                value={values.meterNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.meterNumber}
                touched={touched.meterNumber}
              />
              <MimaDropdown
                labelTitle="Select Vendors"
                placeholder="Select Vendors"
                data={powerVendorsList}
                id="categoryId"
                name="categoryId"
                value={values.categoryName}
                onChange={async (data) => {
                  setFieldValue("categoryId", data.id.toString());
                  setFieldValue("categoryName", data.value);
                  validateMeterNumber(values.meterNumber, data.id.toString());
                }}
                error={errors.categoryId}
                touched={touched.categoryId}
              />

              {loading ? (
                <PageLoader title="In progess ..." loading={loading} />
              ) : (
                <>
                  <MimaInput
                    type="text"
                    placeholder={accountName}
                    labelTitle="Account Name"
                    disabled={true}
                    name="accountName"
                    mb={2}
                    value={values.accountName}
                    onChange={handleChange}
                    error={errors.accountName}
                    touched={touched.accountName}
                  />
                </>
              )}
              <MimaInput
                type="text"
                labelTitle="Amount"
                placeholder="Amount"
                name="amount"
                min="100"
                mb={2}
                value={values.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.amount}
                touched={touched.amount}
              />
              <MimaInput
                type="text"
                labelTitle="Phone Number"
                placeholder="Phone number"
                name="phone"
                mb={2}
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.phone}
                error={errors.phone}
              />

              <MimaButton
                title="Continue"
                onClick={handleSubmit}
                type="submit"
                variant="form"
                mt={2}
              />
            </>
          )}
        </Formik>
      )}
      {pinContent && <EnterPin closeModal={closeModal} />}
    </div>
  );
}

export default Electricity;
