import {
  MimaText,
  MimaButton,
  MimaTable,
  AppShell,
  MimaOptionMenu,
} from "../../components";

import styles from "../../assets/styles/Customer/Customer.module.scss";
import { AnimatePresence } from "framer-motion";
import React, { useMemo } from "react";
import Survey from "../../components/Survey/Survey";
import { userStore } from "../../stores";
import constant from "../../utils/constant";
import useCustomersLogic from "./useCustomersLogic";
import CreateGroup from "./CreateGroup";
import AddCustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";
import ViewCustomer from "./ViewCustomer";
import DeleteCustomer from "./DeleteCustomer";

const Customer = () => {
  const {
    loading,
    addCustomerModal,
    goToAddCustomer,
    goToCreateGroup,
    createGroupModal,
    closeModal,
    editCustomerModal,
    goToEditCustomer,
    viewCustomerModal,
    deleteCustomerModal,
    goBack,
    refresh,
    handleOptionSelect,
    isLoading,
    data,
    isFetching,
    limit,
    onLimitChange,
    currentPage,
    setCurrentPage,
    setSearchQuery,
    setShowSurvey,
    customerDetailsValidation,
    onSubmitCreateCustomer,
    onSubmitEditCustomer,
    onDelete,
  } = useCustomersLogic();

  const tableData = useMemo(() => {
    if (data?.status === constant.Success) {
      return data?.data?.customers || [];
    }
    return [];
  }, [data]);

  const tableColumns = [
    {
      Header: "Name",
      accessor: "fullname",
    },
    {
      Header: "Phone Number",
      accessor: "mobile",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Entity",
      accessor: "gender",
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        const status = cell.row.original.status;
        const customerId = cell.row.original._id;
        const customerDetail = cell.row.original;
        return (
          status !== constant.TRANSACTION_STATUS.VOID && (
            <MimaOptionMenu
              options={[
                {
                  value: "edit customer",
                  label: "Edit customer",
                },
                {
                  value: "delete customer",
                  label: "Delete customer",
                },
                {
                  value: "view more",
                  label: "View more",
                },
              ]}
              onOptionSelected={(option) => {
                handleOptionSelect(customerId, customerDetail, option);
              }}
            />
          )
        );
      },
    },
  ];

  return (
    <AppShell pageTitle="Customers" refresh={refresh}>
      <div className={styles.upper}>
        <div className={styles.customer__num}>
          <MimaText variant="smallBold">
            {data?.data?.totalCounts} Customers
          </MimaText>
          <MimaText variant="xsmall"> Updated 1 min ago </MimaText>
        </div>

        <div className={styles.upper__btns}>
          <MimaButton
            title="Add Customer"
            onClick={goToAddCustomer}
            variant="form"
            mb={1}
          />
        </div>
      </div>

      <MimaTable
        tableData={tableData}
        tableColumns={tableColumns}
        searchPlaceholder="Search Customer"
        searchVariant="wide"
        totalItems={data?.data?.totalCounts}
        onLimitChange={onLimitChange}
        isLoading={isLoading}
        isFetching={isFetching}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchQuery={setSearchQuery}
      />

      {(addCustomerModal ||
        editCustomerModal ||
        viewCustomerModal ||
        deleteCustomerModal) && (
        <div className="modal">
          <AnimatePresence>
            {addCustomerModal && (
              <AddCustomer
                closeModal={closeModal}
                goBack={goBack}
                setShowSurvey={setShowSurvey}
                customerDetailsValidation={customerDetailsValidation}
                onSubmitCreateCustomer={onSubmitCreateCustomer}
                loading={loading}
              />
            )}

            {editCustomerModal && (
              <EditCustomer
                loading={loading}
                closeModal={closeModal}
                goBack={goBack}
                setShowSurvey={setShowSurvey}
                onSubmitEditCustomer={onSubmitEditCustomer}
                customerDetailsValidation={customerDetailsValidation}
              />
            )}
            {viewCustomerModal && (
              <ViewCustomer
                closeModal={closeModal}
                goToEditCustomer={goToEditCustomer}
                goBack={goBack}
              />
            )}
            {deleteCustomerModal && (
              <DeleteCustomer
                closeModal={closeModal}
                goBack={goBack}
                loading={loading}
                onDelete={onDelete}
              />
            )}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default Customer;
