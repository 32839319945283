import {
  MimaText,
  MimaButton,
  PageLoader,
  MimaOptionMenu,
  AppShell,
} from "../../components";
import styles from "../../assets/styles/PaymentLink/PaymentLink.module.scss";
import { paymentLinkStore } from "../../stores";
import React from "react";
import { AnimatePresence } from "framer-motion";
import moment from "moment";
import DeletePaymentLink from "./DeletePaymentLink";
import CreatePaymentLink from "./CreatePaymentLink";
import EditPaymentLink from "./EditPaymentLink";
import usePaymentLink from "./useLogic/usePaymentLink";

const PaymentLink = () => {
  const {
    viewLinks,
    createLinks,
    paymentLinkNav,
    loading,
    editLink,
    refresh,
    handleOptionSelect,
    closeModal,
    deleteLink,
    cancelEdit,
    goToCreateLinks,
    goToViewLinks,
  } = usePaymentLink();

  const paymentLinks = paymentLinkStore.allPaymentLinks.value;

  return (
    <AppShell pageTitle="Payment Link(s)" refresh={refresh}>
      <div className={styles.main}>
        <div className={styles.main_nav}>
          {paymentLinkNav.map((navItem, index) => (
            <div
              key={index}
              onClick={navItem.clickAction}
              className={
                navItem.activeState
                  ? styles.main_nav_item_active
                  : styles.main_nav_item
              }
            >
              {navItem.title}
            </div>
          ))}
        </div>

        <div className={styles.main__content}>
          {viewLinks && (
            <>
              {loading ? (
                <PageLoader title="while we load your links" />
              ) : (
                <div>
                  {paymentLinks && paymentLinks.length === 0 ? (
                    <div className={styles.main__center}>
                      <MimaText
                        mb={2}
                        variant="subtitle"
                        align="center"
                        color="var(--color-primary)"
                      >
                        You are yet to create a Payment Link.
                      </MimaText>
                      <MimaButton
                        title="Create Link"
                        onClick={goToCreateLinks}
                        loading={loading}
                        variant="filter"
                        mb={2}
                        width={18}
                      />
                    </div>
                  ) : (
                    <>
                      <MimaText variant="subtitleBold" mb={2} align="center">
                        Available Payment Links
                      </MimaText>
                      <div className="whiteTable">
                        <table>
                          <thead>
                            <tr>
                              <th>Created Date </th>
                              <th> Link Name</th>
                              <th>Description</th>
                              <th> Currency </th>
                              <th> Amount </th>
                              {/* <th> Link Address </th> */}
                              <th> Status </th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentLinks &&
                              paymentLinks.map((link, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(link.createdAt).format(
                                      "DD-MM-YYYY hh:mm"
                                    )}{" "}
                                  </td>
                                  <td>{link.title}</td>
                                  <td>{link.description}</td>
                                  <td>{link.currencyCode}</td>
                                  <td>{link.amount || "No amount added"}</td>
                                  {/* <td>{link.paymentUrl}</td> */}
                                  <td
                                    className={
                                      link.status === "ACTIVE"
                                        ? styles.active
                                        : styles.deactivated
                                    }
                                  >
                                    {link.status}
                                  </td>

                                  <td>
                                    {link.status === "ACTIVE" ? (
                                      <MimaOptionMenu
                                        options={[
                                          {
                                            value: "Edit Link",
                                            label: "Edit Link",
                                          },
                                          {
                                            value: "Deactivate Link",
                                            label: "Deactivate Link",
                                          },
                                          {
                                            value: "Copy Link Url",
                                            label: "Copy Link Url",
                                          },
                                        ]}
                                        onOptionSelected={(option) => {
                                          handleOptionSelect(
                                            link._id,
                                            link,
                                            option
                                          );
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}

          {createLinks && (
            <CreatePaymentLink
              createLinks={createLinks}
              goToViewLinks={goToViewLinks}
            />
          )}
          {editLink && (
            <EditPaymentLink
              goToViewLinks={goToViewLinks}
              editLink={editLink}
              cancelEdit={cancelEdit}
            />
          )}
        </div>
      </div>

      {deleteLink && (
        <div className="modal">
          <AnimatePresence>
            {deleteLink && <DeletePaymentLink closeModal={closeModal} />}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default PaymentLink;
