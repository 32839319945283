import { MimaButton, MimaText, InvoiceSummaryItem } from "../../components";
import styles from "../../assets/styles/Invoice/Invoice.module.scss";
import { GiCloudDownload, GiUpgrade } from "react-icons/gi";
import { amountFormatter } from "../../utils/utils";
import { MdModeEditOutline } from "react-icons/md";
import { invoiceStore, userStore } from "../../stores";
import moment from "moment";
import React, { useState } from "react";

import EditInvoice from "./EditInvoice";
import UpdateInvoice from "./UpdateInvoice";
import useInvoiceSummaryLogic from "./useLogic/useInvoiceSummaryLogic";

const InvoiceSummary = ({ closeModal }) => {
  const {
    editInvoice,
    goToEditInvoice,
    sendInvoice,
    onDownload,
    editInvoiceMain,
    updateInvoice,
    goToUpdateInvoice,
    invoiceItem,
    setShowSurvey,
  } = useInvoiceSummaryLogic({ closeModal });
  let vat = {};
  let wht = {};
  const invoice = invoiceStore.selectedInvoice;

  const business = invoiceStore.businessDetails;

  const bankAccount = Array.isArray(business?.bankAccount)
    ? business?.bankAccount[0]
    : business?.bankAccount;

  const customer = Array.isArray(invoice.customer)
    ? invoice.customer[0]
    : invoice.customer;
  if (invoice.vat.amount) {
    if (Array.isArray(invoice.vat.tax)) {
      vat = invoice.vat.tax[0];
    } else {
      vat = invoice.vat.tax;
    }
  }
  if (invoice.wht.amount) {
    if (Array.isArray(invoice.wht.tax)) {
      wht = invoice.wht.tax[0];
    } else {
      wht = invoice.wht.tax;
    }
  }
  const type =
    invoice.status === "FULLY-PAID" ||
    invoice.status === "PARTIALLY-PAID" ||
    invoice.status === "OVER-PAID"
      ? "Receipt"
      : "Invoice";

  return (
    <>
      {!editInvoice && !editInvoiceMain && !updateInvoice && (
        <div>
          {
            <div className={styles.summary}>
              <div className={styles.invoice__beside}>
                {invoiceItem && (
                  <div
                    className={styles.summary__edit}
                    onClick={goToEditInvoice}
                  >
                    <MdModeEditOutline /> Edit
                  </div>
                )}
                <div
                  className={styles.summary__edit}
                  onClick={goToUpdateInvoice}
                >
                  <GiUpgrade /> Update
                </div>
                {/* <div>
									<a
										href={invoice.invoiceUrl || ''}
										target="_blank"
										rel="noreferrer">
										<GiCloudDownload /> Download
									</a>
								</div> */}
              </div>

              <div className={styles.summary__title}>
                <MimaText variant="bodyBold">#{invoice.number}</MimaText>
                <div className={styles.summary__initials}>
                  {business?.tradingName?.charAt(0).toUpperCase()}
                </div>
              </div>
              <div className={styles.summary__from}>
                <MimaText variant="small">From</MimaText>
                <MimaText
                  variant="bodyBold"
                  color="var(--color-fash-primary-3)"
                >
                  {business?.tradingName}
                </MimaText>
                <MimaText>{business?.businessAddress}</MimaText>
                <MimaText>{business?.mobile}</MimaText>
                <MimaText>{business?.email}</MimaText>
                <div className={styles.summary__line}></div>
              </div>
              <div className={styles.summary__to}>
                <MimaText variant="small">To</MimaText>
                <MimaText
                  variant="bodyBold"
                  color="var(--color-fash-primary-3)"
                >
                  {customer.fullname}
                </MimaText>
                <MimaText>{customer.mobile}</MimaText>
                <MimaText>{customer.address}</MimaText>
                <MimaText>{customer.email}</MimaText>
                <div className={styles.summary__line}></div>
              </div>
              <div className={styles.summary__dates}>
                <div>
                  <MimaText
                    labelTitle={
                      invoice.paidDate
                        ? "Receipt Created on"
                        : "Invoice Created on"
                    }
                  >
                    {moment(invoice.createdAt).format("DD/MM/YYYY")}
                  </MimaText>
                </div>
                <div>
                  <MimaText
                    labelTitle={
                      invoice.paidDate
                        ? "Receipt Paid Date"
                        : "Invoice Due Date"
                    }
                  >
                    {moment(
                      invoice.dueDate ? invoice.dueDate : invoice.paidDate
                    ).format("DD/MM/YYYY")}
                  </MimaText>
                </div>
              </div>
              <div className={styles.summary__services}>
                <MimaText
                  variant="bodyBold"
                  color="var(--color-fash-primary-3)"
                  mb={1}
                >
                  Service
                </MimaText>
                {invoice.orders.map((order, id) => (
                  <InvoiceSummaryItem
                    key={id}
                    service={order.item}
                    quantity={order.quantity}
                    amount={amountFormatter(invoice.currencyCode).format(
                      order.unitPrice
                    )}
                    totalAmount={amountFormatter(invoice.currencyCode).format(
                      order.itemPrice
                    )}
                  />
                ))}

                <div className={styles.summary__line}></div>
                {invoice.vat.amount !== 0 && (
                  <div className={styles.summary__total}>
                    <MimaText>
                      {`${invoice.vat.percentage || "7.5"}% - ${invoice.vat.type || "VAT"} `}
                    </MimaText>

                    <MimaText variant="bodyBold" color="var(--color-error)">
                      {amountFormatter(invoice.currencyCode).format(
                        invoice.vat.amount || 0
                      )}
                    </MimaText>
                  </div>
                )}
                {invoice.wht.amount !== 0 && (
                  <div className={styles.summary__total}>
                    <MimaText>
                      {`${invoice.wht.percentage || 5}% - ${invoice.wht.type || "WHT"} `}
                    </MimaText>

                    <MimaText variant="bodyBold" color="var(--color-error)">
                      {amountFormatter(invoice.currencyCode).format(
                        invoice.wht.amount || 0
                      )}
                    </MimaText>
                  </div>
                )}

                <div className={styles.summary__total}>
                  <MimaText>Discounted Amount</MimaText>
                  <MimaText variant="bodyBold" color="var(--color-error)">
                    {amountFormatter(invoice.currencyCode).format(
                      invoice.discountAmount
                    )}
                  </MimaText>
                </div>
                <div className={styles.summary__total}>
                  <MimaText>Shipping Fee</MimaText>
                  <MimaText
                    variant="bodyBold"
                    color="var(--color-fash-primary-3)"
                  >
                    {amountFormatter(invoice.currencyCode).format(
                      invoice.shipping || 0
                    )}
                  </MimaText>
                </div>
                <div className={styles.summary__total}>
                  <MimaText>Total</MimaText>
                  <MimaText
                    variant="bodyBold"
                    color="var(--color-fash-primary-3)"
                  >
                    {amountFormatter(invoice.currencyCode).format(
                      invoice.transactionAmount
                    )}
                  </MimaText>
                </div>
                {invoice.balanceAmount > 0 ? (
                  <div className={styles.summary__total}>
                    <MimaText>Part Payment</MimaText>
                    <MimaText
                      variant="bodyBold"
                      color="var(--color-fash-primary-3)"
                    >
                      {amountFormatter(invoice.currencyCode).format(
                        invoice.deposit
                      )}
                    </MimaText>
                  </div>
                ) : (
                  ""
                )}

                {type === "Receipt" && (
                  <div className={styles.summary__total}>
                    <MimaText>Amount Paid</MimaText>
                    <MimaText
                      variant="bodyBold"
                      color="var(--color-fash-primary-3)"
                    >
                      {amountFormatter(invoice.currencyCode).format(
                        invoice.amountPaid
                      )}
                    </MimaText>
                  </div>
                )}

                {invoice.balanceAmount > 0 ? (
                  <div className={styles.summary__total}>
                    <MimaText>Balance</MimaText>
                    <MimaText
                      variant="bodyBold"
                      color="var(--color-fash-primary-3)"
                    >
                      {amountFormatter(invoice.currencyCode).format(
                        invoice.balanceAmount
                      )}
                    </MimaText>
                  </div>
                ) : (
                  ""
                )}

                <div>
                  <MimaText
                    variant="bodyBold"
                    color="var(--color-fash-primary-3)"
                  >
                    Terms & Conditions
                  </MimaText>
                  <MimaText>{invoice.note}</MimaText>
                  <div className={styles.summary__button}>
                    <MimaButton
                      title="Done"
                      variant="form"
                      buttonColor="var(--color-dark-3)"
                      width={20}
                      onClick={closeModal}
                    />
                    <MimaButton
                      title={`Send ${type}`}
                      onClick={() => sendInvoice(invoice._id)}
                      variant="form"
                      width={20}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      )}
      {(editInvoice || editInvoiceMain) && (
        <EditInvoice closeModal={closeModal} />
      )}

      {updateInvoice && <UpdateInvoice closeModal={closeModal} />}
    </>
  );
};

export default InvoiceSummary;
