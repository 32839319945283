import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../assets/styles/AppHome/BusinessInsightCard.module.scss';

const propTypes = {
	title: PropTypes.string,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	amount: PropTypes.string,
	styleClass: PropTypes.any,
};


const BusinessInsightComponent = ({ title, amount, styleClass = styles.bi__card__outline, onClick, ...props }) => {
	return (
		<div onClick={onClick} className={`${styles.bi__card} ${styleClass}`}>
			<div className={styles.bi__card__title}>{title}</div>
			<div className={styles.bi__card__value}>{amount}</div>
		</div>
	);
};

export default BusinessInsightComponent;
