import {
  MimaText,
  MimaButton,
  PageLoader,
  MimaOptionMenu,
  AppShell,
} from "../../components";
import { amountFormatter } from "../../utils/utils";
import styles from "../../assets/styles/Booking/Booking.module.scss";
import { bookingStore } from "../../stores";
import { AnimatePresence } from "framer-motion";
import moment from "moment";
import React from "react";
import CreateBooking from "./CreateBooking";
import EditBooking from "./EditBooking";
import DeleteBookingLink from "./DeleteBookingLink";
import useBookinLogic from "./useBookingLogic";

const Booking = () => {
  const {
    viewBookings,
    viewLinks,
    createLinks,
    bookingNav,
    validationSchema,
    loading,
    editLink,
    refresh,
    handleOptionSelect,
    closeModal,
    deleteLink,
    cancelEdit,
    goToCreateLinks,
    showSurvey,
    setEditLink,
    setViewBookings,
    setViewLinks,
    setCreateLinks,
    confirmDelete,
    validationSchemaWithFreeBooking,
    validationSchemaWithoutFreeBooking,
    onSubmitEditBooking,
    onSubmitCreateBooking,
    validationSchemaEditBooking,
  } = useBookinLogic();
  const bookings = bookingStore.allBookings;
  const bookingLinks = bookingStore.allBookingLinks;
  const selectedLink = bookingStore.selectedBookingLink;

  return (
    <AppShell pageTitle="Bookings" refresh={refresh}>
      <div className={styles.main}>
        <div className={styles.main_nav}>
          {bookingNav.map((navItem, index) => (
            <div
              key={index}
              onClick={navItem.clickAction}
              className={
                navItem.activeState
                  ? styles.main_nav_item_active
                  : styles.main_nav_item
              }
            >
              {navItem.title}
            </div>
          ))}
        </div>

        <div className={styles.main__content}>
          {viewBookings && (
            <>
              {loading ? (
                <PageLoader title="while we load your bookings" />
              ) : (
                <div>
                  {bookings && bookings.length === 0 ? (
                    <div className={styles.main__center}>
                      <MimaText
                        mb={2}
                        variant="subtitleBold"
                        align="center"
                        color="var(--color-dark)"
                      >
                        You are yet to have a booking.
                      </MimaText>
                    </div>
                  ) : (
                    <>
                      <MimaText variant="subtitleBold" mb={2} align="center">
                        Available Bookings
                      </MimaText>
                      <div className="whiteTable">
                        <table>
                          <thead>
                            <tr>
                              <th>Created Date </th>
                              <th> Title</th>
                              <th> Customer </th>
                              <th> Session DateTime </th>
                              <th> Amount </th>
                              <th> Note </th>
                              <th> status </th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookings &&
                              bookings.map((booking, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(booking.createdAt).format(
                                      "DD-MM-YYYY hh:mm"
                                    )}{" "}
                                  </td>
                                  <td>{booking.bookingLink.title}</td>
                                  <td>{booking.customer.fullname}</td>
                                  <td>
                                    {moment(booking.sessionDateTime).format(
                                      "DD-MM-YYYY hh:mm"
                                    )}{" "}
                                  </td>
                                  <td>
                                    {amountFormatter(
                                      booking.currencyCode
                                    ).format(booking.amount)}
                                  </td>
                                  <td>{booking.note}</td>
                                  <td
                                    className={
                                      booking.status !== "UNPAID"
                                        ? styles.active
                                        : styles.deactivated
                                    }
                                  >
                                    {booking.status}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {viewLinks && (
            <>
              {loading ? (
                <PageLoader title="while we load your links" />
              ) : (
                <div>
                  {bookingLinks && bookingLinks.length === 0 ? (
                    <div className={styles.main__center}>
                      <MimaText
                        mb={2}
                        variant="subtitle"
                        align="center"
                        color="var(--color-dark)"
                      >
                        You are yet to create a booking Link.
                      </MimaText>
                      <MimaButton
                        title="Create Link"
                        onClick={goToCreateLinks}
                        loading={loading}
                        variant="filter"
                        mb={2}
                        width={18}
                      />
                    </div>
                  ) : (
                    <>
                      <MimaText
                        variant="subtitleBold"
                        mb={2}
                        align="center"
                        color="var(--color-dark)"
                      >
                        Available Booking Links
                      </MimaText>
                      <div className="whiteTable">
                        <table>
                          <thead>
                            <tr>
                              <th>Created Date </th>
                              <th> Link Name</th>
                              <th>Description</th>
                              <th> Amount </th>
                              <th> Link Address </th>
                              <th> Status </th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookingLinks &&
                              bookingLinks.map((link, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(link.createdAt).format(
                                      "DD-MM-YYYY hh:mm"
                                    )}{" "}
                                  </td>
                                  <td>{link.title}</td>
                                  <td>{link.description}</td>
                                  <td>
                                    {amountFormatter(link.currencyCode).format(
                                      link.amount
                                    )}
                                  </td>
                                  <td>{link.bookingUrl}</td>
                                  <td
                                    className={
                                      link.status === "ACTIVE"
                                        ? styles.active
                                        : styles.deactivated
                                    }
                                  >
                                    {link.status}
                                  </td>

                                  <td>
                                    {link.status === "ACTIVE" ? (
                                      <MimaOptionMenu
                                        options={[
                                          {
                                            value: "Edit Link",
                                            label: "Edit Link",
                                          },
                                          {
                                            value: "Deactivate Link",
                                            label: "Deactivate Link",
                                          },
                                          {
                                            value: "Copy Link Url",
                                            label: "Copy Link Url",
                                          },
                                        ]}
                                        onOptionSelected={(option) => {
                                          handleOptionSelect(
                                            link._id,
                                            link,
                                            option
                                          );
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}

          {createLinks && (
            <CreateBooking
              validationSchemaWithFreeBooking={validationSchemaWithFreeBooking}
              validationSchemaWithoutFreeBooking={
                validationSchemaWithoutFreeBooking
              }
              onSubmitCreateBooking={onSubmitCreateBooking}
              loading={loading}
              createLinks={createLinks}
            />
          )}
          {editLink && (
            <EditBooking
              validationSchema={validationSchema}
              editLink={editLink}
              cancelEdit={cancelEdit}
              selectedLink={selectedLink}
              setEditLink={setEditLink}
              setViewBookings={setViewBookings}
              setViewLinks={setViewLinks}
              setCreateLinks={setCreateLinks}
              onSubmitEditBooking={onSubmitEditBooking}
              validationSchemaEditBooking={validationSchemaEditBooking}
            />
          )}
        </div>
      </div>

      {(deleteLink || showSurvey) && (
        <div className="modal">
          <AnimatePresence>
            {deleteLink && (
              <DeleteBookingLink
                loading={loading}
                closeModal={closeModal}
                confirmDelete={confirmDelete}
                selectedLink={selectedLink}
              />
            )}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default Booking;
