import axios from "axios";
import { userStore } from "../stores";
const client = axios.create();

const exceptionEndpoint = [];

client.interceptors.request.use(
  async (config) => {
    if (!exceptionEndpoint.includes(config.url)) {
      config.headers.Authorization = `Bearer ${userStore.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error?.response || {};

    if (status === 401) {
      // Log user out
      userStore.setLoggedIn(false);
      delete client.defaults.headers.common.Authorization;
    }

    return Promise.reject(error);
  }
);

export default client;
