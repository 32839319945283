import { MimaText } from "../../../components";
import styles from "../../../assets/styles/Wallet/TransferFunds.module.scss";
import BeneficiaryList from "./BeneficiaryList";
import { userStore } from "../../../stores";
import React from "react";
import BankList from "./BankList";
import NewBeneficiary from "./NewBeneficiary";
import SavedBeneficiary from "./SavedBeneficiary";
import useTransferFundOptionLogic from "../useLogics/useTransferFundOptionLogic";

const TransferFundOptions = ({
  closeTransferModal,
  transferSwitchState,
  bankListContent,
  setBankListContent,
  beneficiaryContent,
  setBeneficiaryContent,
  stage2,
  setStage2,
}) => {
  const {
    goToSaved,
    beneficiaryList,
    onSelectBeneficiary,
    goToBankList,
    onSelectBank,
    goToNew,
    savedActive,
    newActive,
    newBeneficiarySelectedState,
    savedBeneficiarySelectedState,
    beneficiaries,
    banks,

    // New Beneficiary logic
    validationSchemaNewBeneficiary,
    onSubmitNewBeneficiary,
    verifyAccountNumber,
    loading,
    setLoading,
    // saved beneficiary logic
    validationSchemaSavedBeneficiary,
    onSubmitSavedBeneficiary,
  } = useTransferFundOptionLogic({
    closeTransferModal,
    transferSwitchState,
    bankListContent,
    setBankListContent,
    beneficiaryContent,
    setBeneficiaryContent,
    stage2,
    setStage2,
  });
  return (
    <div>
      {bankListContent ? (
        <BankList onSelectBank={onSelectBank} banks={banks} />
      ) : beneficiaryContent ? (
        <BeneficiaryList
          onSelectBeneficiary={onSelectBeneficiary}
          beneficiaries={beneficiaries}
        />
      ) : (
        <>
          <MimaText variant="subtitle" mb={1.5} align="center">
            Make A Payment
          </MimaText>
          <MimaText variant="small" mb={3} align="center">
            {!userStore.user.hasPin
              ? "Kindly set your Pin on the settings page to use this feature"
              : ""}
          </MimaText>
          <div className={styles.beneficiary}>
            <div
              className={`${
                savedActive
                  ? styles.beneficiary__saved
                  : styles.beneficiary__saved_b
              }`}
              onClick={goToSaved}
            >
              Saved Beneficiary
            </div>
            <div
              className={`${
                !newActive ? styles.beneficiary__new : styles.beneficiary__new_b
              }`}
              onClick={goToNew}
            >
              New Beneficiary
            </div>
          </div>
          {savedActive && (
            <SavedBeneficiary
              beneficiaryList={beneficiaryList}
              transferSwitchState={transferSwitchState}
              selectedState={savedBeneficiarySelectedState}
              onSubmitSavedBeneficiary={onSubmitSavedBeneficiary}
              validationSchemaSavedBeneficiary={
                validationSchemaSavedBeneficiary
              }
            />
          )}
          {newActive && (
            <NewBeneficiary
              transferSwitchState={transferSwitchState}
              goToBankList={goToBankList}
              selectedState={newBeneficiarySelectedState}
              stage2={stage2}
              setStage2={setStage2}
              validationSchemaNewBeneficiary={validationSchemaNewBeneficiary}
              onSubmitNewBeneficiary={onSubmitNewBeneficiary}
              verifyAccountNumber={verifyAccountNumber}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TransferFundOptions;
