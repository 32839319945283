import React, { useMemo, useState } from "react";
import {
  MimaButton,
  MimaDateFilter,
  MimaDropdownFilter,
  MimaFilter,
  MimaTable,
  MimaText,
  MimaOptionMenu,
  AppShell,
} from "../../components";
import { Formik } from "formik";
import { amountFormatter, filterPeriods, getDate } from "../../utils/utils";
import styles from "../../assets/styles/Transactions/Transactions.module.scss";
import TransactionInsightsCard from "../Transactions/TransactionInsightsCard";
import TransactionQuickLinksCard from "../Transactions/TransactionQuickLinksCard";
import AddExpenses from "./AddExpenses";
import AddIncome from "./AddIncome";
import UpdateDebt from "./UpdateDebt";
import UpdateTransactions from "./UpdateTransactions";
import EditExpense from "./EditExpenses";
import EditIncome from "./EditIncome";
import { toJS } from "mobx";
import { transactionStore, userStore } from "../../stores";
import { MdKeyboardArrowDown } from "react-icons/md";
import constant from "../../utils/constant";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import ExpenseViewMore from "../Transactions/ExpenseViewMore";
import IncomeViewMore from "../Transactions/IncomeViewMore";
import { downloadXlsx2 } from "../../utils/download";
import useTransactionLogic from "./useTransactionLogic";

const TransactionsNew = () => {
  const [onReset, setOnReset] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);

  const {
    loading,
    validationSchema,
    onSubmit,
    filterPeriod,
    resetFilter,
    tableType,
    refresh,
    tableTypeNavHandler,
    tableTypeData,
    tagContentHandler,
    tagTitle,
    showContent,
    tableTagData,
    tagSetHandler,
    tableTypeConstant,
    transactionDataInfo,
    onTransactionLimitChange,
    transactionLimit,
    transactionCurrentPage,
    setTransactionCurrentPage,
    setTransactionSearchQuery,
    modalType,
    modalConstant,
    openModalHandler,
    closeModal,
    handleExpenseOptionSelect,
    onSubmitUpdateTransaction,
    validationSchemaUpdateTransactions,
    balanceAmount,
    onCloseModal,
    balanceAmountUpdateDebt,
    onSubmitUpdateDebt,
    validationSchemaUpdateDebt,
    transaction,
    openIncomeModal,
    incomeSuccess,
    onSubmitAddIncome,
    validationSchemaAddIncome,
    getToggle,
    addAnotherIncome,
    openExpenseModal,
    expenseSuccess,
    addNewExpense,
    validationSchemaAddExpense,
    onSubmitAddExpense,
    type,
  } = useTransactionLogic();

  const permissions = new Set(
    toJS(userStore.user?.permissions?.map((perm) => perm.name))
  );

  const tableData = useMemo(() => {
    if (transactionDataInfo?.data?.status === constant.Success) {
      return transactionDataInfo?.data?.data?.transactions || [];
    }
    return [];
  }, [transactionDataInfo]);

  const inflowTableCOlumns = [
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ cell }) => {
        const createdDate = cell.value;
        return <span>{moment(createdDate).format("DD-MM-YYYY hh:mm")}</span>;
      },
    },
    {
      Header: "Narration",
      accessor: "narration",
      Cell: ({ cell }) => {
        const narrationValue = cell.value;
        const customerName = cell.row.original?.customer?.fullname;
        const orders = cell.row.original.orders;

        return (
          <span>
            {narrationValue
              ? narrationValue
              : customerName
                ? customerName
                : orders.length > 1
                  ? `Payment for ${orders[0].item} and ${orders.length - 1} item(s)`
                  : `Payment for ${orders[0]?.item}`}
          </span>
        );
      },
    },
    {
      Header: "Amount Charged",
      accessor: "transactionAmount",
      Cell: ({ cell }) => {
        const transactionAmount = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedAmount = amountFormatter(currencyCode).format(
          transactionAmount || 0
        );
        return <span>{formattedAmount}</span>;
      },
    },
    {
      Header: "Amount Received",
      accessor: "amountPaid",
      Cell: ({ cell }) => {
        const amountPaid = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedAmount = amountFormatter(currencyCode).format(
          amountPaid || 0
        );
        return <span>{formattedAmount}</span>;
      },
    },
    {
      Header: "Paid Date",
      accessor: "paidDate",
      Cell: ({ cell }) => {
        const paidDate = cell.value;
        return <span>{moment(paidDate).format("DD-MM-YYYY hh:mm")}</span>;
      },
    },
    {
      Header: "Balance",
      accessor: "balanceAmount",
      Cell: ({ cell }) => {
        const balanceAmount = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedAmount = amountFormatter(currencyCode).format(
          balanceAmount || 0
        );
        return <span>{formattedAmount}</span>;
      },
    },
    {
      Header: "Tag Type",
      accessor: "inflowType",
      Cell: ({ cell }) => {
        const inflowType = cell.value;
        const status = cell.row.original.status;
        return (
          <span
            style={{
              color:
                inflowType === constant.FLOW_TYPE.Untagged &&
                status !== "VOID" &&
                "var(--color-error)",
            }}
          >
            {inflowType}
          </span>
        );
      },
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // const id = cell.row.original._id;
        const transaction = cell.row.original;
        const status = cell.row.original.status;
        const inflowType = cell.row.original.inflowType;

        return (
          <MimaOptionMenu
            options={[
              status !== "VOID"
                ? {
                    value: "Update Status",
                    label: "Update Status",
                  }
                : null,
              {
                value: "View More",
                label: "View More",
              },
            ]}
            onOptionSelected={(option) => {
              if (option.value === "Update Status") {
                if (status !== "VOID") {
                  transactionStore.setSelectedTransaction(transaction);
                  openModalHandler(modalConstant.updateTransaction);
                }
              } else if (option.value === "View More") {
                transactionStore.setSelectedIncome(transaction);
                openModalHandler(modalConstant.viewIncome);
              }
            }}
          />
        );
      },
    },
  ];

  const outflowTableColumns = [
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ cell }) => {
        const createdDate = cell.value;
        return <span>{moment(createdDate).format("DD-MM-YYYY hh:mm")}</span>;
      },
    },
    {
      Header: "Category",
      accessor: "expenseCategory",
      Cell: ({ cell }) => {
        const expenseCategory = cell.value;
        return <span>{expenseCategory?.name || ""}</span>;
      },
    },
    {
      Header: "Narration",
      accessor: "narration",
      Cell: ({ cell }) => {
        const narrationValue = cell.value;
        const customerName = cell.row.original?.customer?.fullname;
        const orders = cell.row.original.orders;

        return (
          <span>
            {narrationValue
              ? narrationValue
              : customerName
                ? customerName
                : orders.length > 1
                  ? `Payment for ${orders[0].item} and ${orders.length - 1} item(s)`
                  : `Payment for ${orders[0]?.item}`}
          </span>
        );
      },
    },
    {
      Header: "Amount Charged",
      accessor: "transactionAmount",
      Cell: ({ cell }) => {
        const transactionAmount = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedAmount = amountFormatter(currencyCode).format(
          transactionAmount || 0
        );
        return <span>{formattedAmount}</span>;
      },
    },
    {
      Header: "Amount Paid",
      accessor: "amountPaid",
      Cell: ({ cell }) => {
        const amountPaid = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedAmount = amountFormatter(currencyCode).format(
          amountPaid || 0
        );
        return <span>{formattedAmount}</span>;
      },
    },
    {
      Header: "Balance",
      accessor: "balanceAmount",
      Cell: ({ cell }) => {
        const balanceAmount = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedAmount = amountFormatter(currencyCode).format(
          balanceAmount || 0
        );
        return <span>{formattedAmount}</span>;
      },
    },
    {
      Header: "Tag Type",
      accessor: "inflowType",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => {
        const status = cell.value;
        return (
          <span
            className={`${
              status === "UNPAID"
                ? styles.notPaid
                : status === "PAID"
                  ? styles.paid
                  : styles.partPaid
            }`}
          >
            {status}
          </span>
        );
      },
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        const id = cell.row.original._id;
        const transaction = cell.row.original;

        return (
          <MimaOptionMenu
            options={[
              {
                value: "View More",
                label: "View More",
              },
              transaction.category === "OFFLINE" &&
                transaction.status !== "VOID" && {
                  value: "Update Expense",
                  label: "Update Expense",
                },
            ]}
            onOptionSelected={(option) => {
              handleExpenseOptionSelect(id, transaction, option);
            }}
          />
        );
      },
    },
  ];

  const debtTableColumns = [
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ cell }) => {
        const createdDate = cell.value;
        return <span>{moment(createdDate).format("DD-MM-YYYY hh:mm")}</span>;
      },
    },
    {
      Header: "Narration",
      accessor: "narration",
      Cell: ({ cell }) => {
        const narrationValue = cell.value;
        const expenseCategoryName = cell.row.original?.expenseCategory?.name;

        return (
          <span>{narrationValue ? narrationValue : expenseCategoryName}</span>
        );
      },
    },
    {
      Header: "Amount Owed",
      accessor: "deposit",
      Cell: ({ cell }) => {
        const deposit = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedAmount = amountFormatter(currencyCode).format(
          parseInt(deposit) || 0
        );
        return <span>{formattedAmount}</span>;
      },
    },
    {
      Header: "Due Date",
      accessor: "balanceDueDate",
      Cell: ({ cell }) => {
        const balanceDueDate = cell.value;
        const dueDate = cell.row.original.dueDate;
        return (
          <span>
            {moment(balanceDueDate || dueDate).format("DD-MM-YYYY hh:mm")}
          </span>
        );
      },
    },
    {
      Header: "Tag Type",
      accessor: "inflowType",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => {
        const status = cell.value;
        return (
          <span
            className={`${
              status === "UNPAID"
                ? styles.notPaid
                : status === "PAID"
                  ? styles.paid
                  : styles.partPaid
            }`}
          >
            {status}
          </span>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ cell }) => {
        const status = cell.row.original.status;
        const transaction = cell.row.original;
        return status === "UNPAID" ? (
          <MimaButton
            title="Update Status"
            variant="filter"
            width={15}
            onClick={() => {
              transactionStore.setSelectedTransaction(transaction);
              openModalHandler(modalConstant.updateDebt);
            }}
          />
        ) : (
          ""
        );
      },
    },
  ];

  const tableColumns = useMemo(() => {
    if (tableType === tableTypeConstant.INFLOW) {
      return inflowTableCOlumns;
    } else if (tableType === tableTypeConstant.OUTFLOW) {
      return outflowTableColumns;
    } else if (tableType === tableTypeConstant.DEBT) {
      return debtTableColumns;
    }
  }, [
    debtTableColumns,
    inflowTableCOlumns,
    outflowTableColumns,
    tableType,
    tableTypeConstant.DEBT,
    tableTypeConstant.INFLOW,
    tableTypeConstant.OUTFLOW,
  ]);

  const printData = tableData.map((transaction) => ({
    Date: moment(transaction.createdAt).format("DD-MM-YYYY hh:mm"),
    Type: transaction.type,
    Customer: transaction.customer ? transaction.customer.fullname : "",
    Amount:
      transaction.type === constant.TRANSACTION_TYPE.EXPENSE ||
      transaction.type === constant.TRANSACTION_TYPE.EXPENSE_DEBT
        ? `-${transaction.transactionAmount}`
        : transaction.transactionAmount || 0,
    Bank: transaction.bankName || "",
    currencyCode: transaction.currencyCode,
    category: transaction.category,
    walletBalance: transaction.walletBalance,
    AccountNumber:
      transaction.type === constant.TRANSACTION_TYPE.EXPENSE
        ? transaction.receiverAccountNumber || ""
        : transaction.sourceAccountNumber || "",
    AccountName:
      transaction.type === constant.TRANSACTION_TYPE.EXPENSE
        ? transaction.receiverAccountName || ""
        : transaction.sourceAccountName || "",
    Deposit:
      transaction.type === constant.TRANSACTION_TYPE.EXPENSE
        ? `-${transaction.deposit}`
        : transaction.deposit || 0,
    ExpenseCategory: transaction.expenseCategory
      ? transaction.expenseCategory.name
      : "",
    Narration: transaction.narration || "",

    Balance: transaction.balanceAmount,
    Status: transaction.status,
    "Paid Date": moment(transaction.paidDate).format("DD-MM-YYYY hh:mm"),
    "Due Date": moment(transaction.dueDate).format("DD-MM-YYYY hh:mm"),
    "Balance Due Date": moment(transaction.balanceDueDate).format(
      "DD-MM-YYYY hh:mm"
    ),
  }));

  return (
    <AppShell pageTitle="Transaction">
      <MimaText variant="subtitle" mb={2} align="center">
        You are viewing Transactions for {filterPeriod}
      </MimaText>
      <Formik
        initialValues={{
          startDate: "",
          endDate: "",
          period: "",
          periodText: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <MimaFilter
            filterClick={() => {
              setOnReset(false);
              handleSubmit();
            }}
            loading={loading}
            resetClick={() => {
              setOnReset(true);
              resetFilter();
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MimaDropdownFilter
                labelTitle="Filter period"
                placeholder="Select Period"
                variant="medium"
                name="period"
                data={filterPeriods}
                onChange={(data) => {
                  const dates = getDate(data.key);
                  setFieldValue("period", data.key);
                  setFieldValue("startDate", dates.startDate);
                  setFieldValue("endDate", dates.endDate);
                  setFieldValue("periodText", data.value);
                }}
                error={errors.period}
                touched={touched.period}
              />
              {values.period === "custom" && (
                <div
                  style={{ display: "flex", gap: "2rem", marginTop: "2rem" }}
                >
                  <MimaDateFilter
                    labelTitle="start date"
                    name="startDate"
                    value={!onReset ? values.startDate : ""}
                    onChange={(text) => {
                      setOnReset(false);
                      setFieldValue("startDate", text);
                    }}
                    onBlur={handleBlur}
                    width={18}
                    touched={touched.startDate}
                    error={errors.startDate}
                  />
                  <MimaDateFilter
                    labelTitle="End date"
                    name="endDate"
                    value={!onReset ? values.endDate : ""}
                    onChange={(text) => {
                      setOnReset(false);
                      setFieldValue("endDate", text);
                    }}
                    onBlur={handleBlur}
                    width={18}
                    touched={touched.endDate}
                    error={errors.endDate}
                  />
                </div>
              )}
            </div>
          </MimaFilter>
        )}
      </Formik>
      <div className={styles.main}>
        {permissions.has("CAN VIEW BUSINESS TRANSACTIONS SUMMARY") && (
          <TransactionInsightsCard filterPeriod={filterPeriod} />
        )}

        {/* <TransactionQuickLinksCard
          modalConstant={modalConstant}
          openModalHandler={openModalHandler}
        /> */}
      </div>
      <MimaText mt={4} variant="bodyBold" align="center">
        You are viewing {tableType} Transactions
      </MimaText>
      <div className={styles.main_nav}>
        {tableTypeData.map((type, i) => (
          <div
            className={
              tableType === type
                ? styles.main_nav_item_active
                : styles.main_nav_item
            }
            onClick={() => {
              tableTypeNavHandler(type);
            }}
            key={i}
          >
            {type}
          </div>
        ))}
        <div
          className={styles.main_nav_item}
          onClick={() => {
            downloadXlsx2(printData, `Transactions`);
          }}
        >
          Download Report
        </div>
      </div>
      <div className={styles.txFilterSection}>
        <MimaButton
          loading={loading}
          variant="outlined"
          title="Refresh"
          onClick={refresh}
          width={10}
        />

        {(tableType === tableTypeConstant.INFLOW ||
          tableType === tableTypeConstant.OUTFLOW) && (
          <div className={styles.txSelect}>
            <div className={styles.txSelect__title} onClick={tagContentHandler}>
              <span>{tagTitle}</span>
              <span className={styles.txSelect__title__icon}>
                <MdKeyboardArrowDown />
              </span>
            </div>
            {showContent && (
              <div className={styles.txSelect__content}>
                {tableTagData.map((x, i) => (
                  <span
                    onClick={() => {
                      tagSetHandler(x);
                    }}
                    key={i}
                  >
                    {x.value}
                  </span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <MimaTable
        tableData={tableData}
        tableColumns={tableColumns}
        searchPlaceholder="Search Transactions"
        searchVariant="wide"
        totalItems={transactionDataInfo?.data?.data?.totalCounts}
        onLimitChange={onTransactionLimitChange}
        isLoading={transactionDataInfo?.isLoading}
        isFetching={transactionDataInfo?.isFetching}
        limit={transactionLimit}
        currentPage={transactionCurrentPage}
        setCurrentPage={setTransactionCurrentPage}
        setSearchQuery={setTransactionSearchQuery}
      />

      {(modalType !== modalConstant.close || showSurvey) && (
        <div className="modal">
          <AnimatePresence>
            {modalType === modalConstant.addExpense && (
              <AddExpenses
                closeModal={onCloseModal}
                type={type}
                loading={loading}
                openExpenseModal={openExpenseModal}
                expenseSuccess={expenseSuccess}
                addNewExpense={addNewExpense}
                validationSchemaAddExpense={validationSchemaAddExpense}
                onSubmitAddExpense={onSubmitAddExpense}
              />
            )}
            {modalType === modalConstant.addDebt && (
              <AddExpenses
                closeModal={onCloseModal}
                type={type}
                loading={loading}
                openExpenseModal={openExpenseModal}
                expenseSuccess={expenseSuccess}
                addNewExpense={addNewExpense}
                validationSchemaAddExpense={validationSchemaAddExpense}
                onSubmitAddExpense={onSubmitAddExpense}
              />
            )}
            {modalType === modalConstant.addIncome && (
              <AddIncome
                closeModal={onCloseModal}
                openIncomeModal={openIncomeModal}
                incomeSuccess={incomeSuccess}
                onSubmitAddIncome={onSubmitAddIncome}
                validationSchemaAddIncome={validationSchemaAddIncome}
                getToggle={getToggle}
                addAnotherIncome={addAnotherIncome}
              />
            )}
            {modalType === modalConstant.updateDebt && (
              <UpdateDebt
                closeModal={onCloseModal}
                balanceAmountUpdateDebt={balanceAmountUpdateDebt}
                onSubmitUpdateDebt={onSubmitUpdateDebt}
                validationSchemaUpdateDebt={validationSchemaUpdateDebt}
                transaction={transaction}
              />
            )}
            {modalType === modalConstant.updateTransaction && (
              <UpdateTransactions
                closeModal={onCloseModal}
                onSubmitUpdateTransaction={onSubmitUpdateTransaction}
                validationSchemaUpdateTransactions={
                  validationSchemaUpdateTransactions
                }
                balanceAmount={balanceAmount}
                transaction={transaction}
              />
            )}
            {modalType === modalConstant.editExpense && (
              <EditExpense closeModal={closeModal} />
            )}
            {modalType === modalConstant.editIncome && (
              <EditIncome closeModal={closeModal} />
            )}
            {modalType === modalConstant.viewExpense && (
              <ExpenseViewMore closeModal={closeModal} />
            )}
            {modalType === modalConstant.viewIncome && (
              <IncomeViewMore closeModal={closeModal} />
            )}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default TransactionsNew;
