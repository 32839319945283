import React, { useState, useRef, Fragment, useMemo } from "react";
import styles from "../../assets/styles/MimaDropdown.module.scss";
import styled from "styled-components";
import * as dropdownStyles from "./styles";
import PropTypes from "prop-types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { apiRequest } from "../../utils/useAPIRequest";
import { encodeQueryData } from "../../utils/utils";
import { useDebounce, useIntersectionObserver } from "../../hooks";
import PageLoader from "../PageLoader";

const propTypes = {
  value: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  labelTitle: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.any,
  touched: PropTypes.any,
  variant: PropTypes.oneOf(["medium", "wide", "small", "filterForm", "error"]),
  queryKey: PropTypes.array.isRequired,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  optionKey: PropTypes.string,
  bodyVariant: PropTypes.oneOf(["bodyBase", "bodyBase2"]),
  pt: PropTypes.any,
  pb: PropTypes.any,
  mb: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  styleClass: PropTypes.any,
  icon: PropTypes.element,
  url: PropTypes.string.isRequired,
  getPageValue: PropTypes.func.isRequired,
  getPageTotalCount: PropTypes.func.isRequired,
};

const MimaPaginatedDropdown = ({
  url = "",
  value = "",
  id = "",
  name = "",
  labelTitle = "",
  placeholder = "",
  variant = "medium",
  touched,
  error,
  queryKey = [],
  optionLabel = "label",
  optionValue = "value",
  optionKey = "_id",
  bodyVariant = "bodyBase",
  mb,
  mt,
  mr,
  ml,
  width,
  height,
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  getPageValue = (page) => {},
  getPageTotalCount = (lastPage) => {},
}) => {
  const loadMoreRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selected, setSelected] = useState({
    [optionLabel]: value || "",
  });
  const debouncedSearch = useDebounce(searchValue, 1000);

  const query = useMemo(() => {
    return {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      limit: 50,
      offset: 1,
    };
  }, [debouncedSearch]);

  const openDropdownHandler = (e) => {
    setOpenDropdown(!openDropdown);
  };

  const dropDownSetHandler = (dropDownOption) => {
    setSelected(dropDownOption);
    onChange(dropDownOption);
    setOpenDropdown(false);
  };

  const queryFn = async ({ pageParam = 1, queryKey }) => {
    const encodeQuery = encodeQueryData({
      ...query,
      offset: pageParam || 1,
    });

    return apiRequest({
      url: `${url}?${encodeQuery}`,
      method: "get",
    });
  };

  const getNextPageParam = (lastPage, allPages) => {
    const allPagesCount =
      allPages.flatMap((page) => getPageValue(page) || []).length || 0;
    const totalCount = getPageTotalCount(lastPage) || 0;
    const limit = query.limit || 50;

    if (totalCount > allPagesCount) {
      const page = Math.floor(allPagesCount / limit);
      return page + 1;
    }

    return undefined;
  };

  const {
    data: result = [],
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery([...queryKey, query], queryFn, { getNextPageParam });

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <>
      <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
        <label
          className={`${
            variant !== "wide"
              ? styles.label
              : `${styles.label} ${styles.label__wide}`
          }`}
        >
          {labelTitle}
        </label>
        <DropdownSkeleton
          width={width}
          height={height}
          placeholder={placeholder}
          onClick={openDropdownHandler}
          variant={error && touched ? "error" : variant}
        >
          {selected[optionLabel] || placeholder || ""}
        </DropdownSkeleton>
        {openDropdown && (
          <DropdownBody width={width} bodyVariant={bodyVariant}>
            <div className="searchInMiddle">
              <SearchDivSkeleton mb={1}>
                <SearchSkeleton
                  type="text"
                  value={searchValue}
                  placeholder="Search"
                  onChange={(e) => {
                    const text = e.target.value;
                    setSearchValue(text);
                  }}
                  variant="dropdown"
                />
              </SearchDivSkeleton>
            </div>

            <div>
              {isSuccess
                ? result?.pages?.map((page) => (
                    <Fragment key={String(page)}>
                      {page.status === "Success"
                        ? getPageValue(page).map((item) => (
                            <div
                              key={`${page}-${item[optionKey]}`}
                              onClick={() => dropDownSetHandler(item)}
                              className={styles.select__options}
                            >
                              {item[optionLabel]}
                            </div>
                          ))
                        : null}
                    </Fragment>
                  ))
                : null}

              <div
                ref={loadMoreRef}
                className={`${!hasNextPage ? "hidden" : ""}`}
              >
                <PageLoader loading={isFetchingNextPage} type="bar" />
              </div>

              {isLoading ? (
                <div className="text-center bg-gray-50 p-8 rounded-md text-gray-400 text-xl mt-14">
                  Loading...
                </div>
              ) : null}
            </div>
          </DropdownBody>
        )}

        {error ? <div className={styles.error}>{touched && error}</div> : null}
      </DivSkeleton>
    </>
  );
};

const DivSkeleton = styled.div`
  ${dropdownStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const SearchDivSkeleton = styled.div`
  ${dropdownStyles.searchDivBase};
  ${(props) => dropdownStyles[props.variant]};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const SearchSkeleton = styled.input`
  ${dropdownStyles.searchBase};
  ${(props) => dropdownStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
  ${(touched) => (touched ? `color: var(--color-dark)` : "")}
`;

const DropdownBody = styled.div`
  ${dropdownStyles.bodyBase};
  ${(props) => dropdownStyles[props.bodyVariant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  padding-bottom: 4px;
`;

const DropdownSkeleton = styled.div`
  ${dropdownStyles.base};
  ${(props) => dropdownStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(touched) => (touched.id || touched.name ? `color: var(--color-dark)` : "")}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
`;

MimaPaginatedDropdown.propTypes = propTypes;

export default MimaPaginatedDropdown;
