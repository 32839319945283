import constant from "../../../utils/constant";
import {
  CustomerController,
  InvoiceController,
  QuotationController,
} from "../../../controllers";
import { theYear } from "../../../utils/utils";
import {
  inventoryStore,
  invoiceStore,
  userStore,
  quotationStore,
} from "../../../stores";
import React, { useEffect, useState, useMemo } from "react";
import Invoice from "../../../pages";
import { MimaToastUtil } from "../../../components";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { date, object, string } from "yup";
import { toJS } from "mobx";
import fileDownload from "js-file-download";
import { getDate } from "../../../utils/utils";
import { filterPeriods } from "../../../utils/utils";
import moment from "moment";

const useInvoiceLogic = () => {
  const [addNewInvoice, setAddNewInvoice] = useState(false);
  const [viewInvoiceModal, setViewInvoiceModal] = useState(false);
  const [addNewReceipt, setAddNewReceipt] = useState(false);
  const [invoiceItem, setInvoiceItem] = useState(true);
  const [editInvoiceMain, setEditInvoiceMain] = useState(false);
  const [updateInvoiceModal, setUpdateInvoiceModal] = useState(false);
  const [updateQuoteModal, setUpdateQuoteModal] = useState(false);
  const [dupQuoteModal, setDupQuoteModal] = useState(false);
  const [invoiceList, setInvoiceList] = useState(invoiceStore.invoices);
  const [filterPeriod, setFilterPeriod] = useState(`${theYear}`);
  const [editInvoice, setEditInvoice] = useState(false);
  const [updateInvoice, setUpdateInvoice] = useState(false);
  const [addBulkInvoice, setAddBulkInvoice] = useState(false);
  const [addNewQuotation, setAddNewQuotation] = useState(false);
  const [quoteSummaryModal, setQuoteSummaryModal] = useState(false);
  const [editQuoteModal, setEditQuoteModal] = useState(false);
  const [tableType, setTableType] = useState("Invoice");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  //for invoice table and pagination
  const [limit, setLimit] = useState(50);
  const [filterQuery, setFilterQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const statusesAnalysis = invoiceStore?.invoicesAnalysis?.statuses || [];

  useEffect(() => {
    const setInvoices = async () => {
      setLoading(true);
      await Promise.all([
        InvoiceController.getAnalysis(),
        CustomerController.getCustomers(),
        QuotationController.getQuotations(),
      ]);

      setLoading(false);
    };
    setInvoices();
  }, [inventoryStore]);

  const goToNewInvoice = (item = {}) => {
    // isOnboardingDone();
    setAddNewInvoice(true);
    setAddNewReceipt(false);
    setInvoiceItem(true);
    invoiceStore.setCopiedInvoice(item);
  };

  const goToNewQuotation = () => {
    setAddNewQuotation(true);
  };

  const goToQuoteSummary = () => {
    setQuoteSummaryModal(true);
  };
  const goToDuplicateQuoteModal = () => {
    setDupQuoteModal(true);
  };
  const goToEditQuote = () => {
    setEditQuoteModal(true);
  };

  const goToUpdateQuote = () => {
    setUpdateQuoteModal(true);
  };

  const validationSchema = () => {
    return object({
      period: string().required("Please select a filter period"),
      startDate: date().when("period", {
        is: "custom",
        then: date().required("Start date is required"),
      }),
      endDate: date().when("period", {
        is: "custom",
        then: date().required("End date is required"),
      }),
    });
  };

  const onSubmit = async (payload) => {
    let query;
    if (payload && Object.keys(payload).length > 0) {
      query = `startDate=${payload.startDate}&endDate=${payload.endDate}&dateField=${payload.dateField}`;
      if (payload.period === "custom") {
        setFilterPeriod(
          `${moment(payload?.startDate).format("DD-MM-YYYY")} - ${moment(payload.endDate).format("DD-MM-YYYY")}`
        );
      } else {
        setFilterPeriod(`${payload?.periodText}`);
      }
    }
    setCurrentPage(0);
    setFilterQuery(query);
    setLoading(true);
    await Promise.all([InvoiceController.getAnalysis(query)]);
    setLoading(false);
  };
  const onQuotSubmit = async (payload) => {
    let query;

    if (payload && Object.keys(payload).length > 0) {
      query = `startDate=${payload.startDate}&endDate=${payload.endDate}&dateField=createdAt`;
      if (payload.period === "custom") {
        setFilterPeriod(
          `${moment(payload?.startDate).format("DD-MM-YYYY")} - ${moment(payload.endDate).format("DD-MM-YYYY")}`
        );
      } else {
        setFilterPeriod(`${payload?.periodText}`);
      }
    }
    setLoading(true);
    await Promise.all([QuotationController.getQuotations(query)]);
    setLoading(false);
  };
  const onDownload = async (invoiceId) => {
    const { status, data, errorMessage } =
      await InvoiceController.download(invoiceId);
    if (status === constant.Success) {
      await fileDownload(data, "invoice-download.pdf");
      MimaToastUtil.success({
        message: constant.Success,
      });
    }
    if (errorMessage) {
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  const refresh = async () => {
    setLoading(true);
    await Promise.all([
      InvoiceController.getInvoices(),
      InvoiceController.getAnalysis(),
      CustomerController.getCustomers(),
    ]);
    setLoading(false);
  };
  const isOnboardingDone = () => {
    if (userStore.isOnBoardingDone === "false") {
      MimaToastUtil.error({
        message: constant.OnboardingRequired,
      });
      localStorage.setItem(constant.RoutePath, constant.Routes.Invoice);
      return navigate(constant.Routes.OnboardingStageOne);
    }
  };

  const viewFullyPaidInvoices = () => {
    setInvoiceList(invoiceStore.fullyPaidInvoices);
  };

  const viewPartiallyPaidInvoices = () => {
    setInvoiceList(invoiceStore.partiallyPaidInvoices);
  };

  const viewNotPaidInvoices = () => {
    setInvoiceList(invoiceStore.notPaidInvoices);
  };
  const viewInvoicesDueIn30Days = () => {
    setInvoiceList(invoiceStore.dueIn30days);
  };
  const viewOverDueInvoices = () => {
    setInvoiceList(invoiceStore.overDue);
  };

  const goToUpdateInvoice = (value) => {
    // isOnboardingDone();
    invoiceStore.setSelectedInvoice(value);
    setUpdateInvoiceModal(true);
  };

  const invoiceFilteredText =
    invoiceList === invoiceStore.fullyPaidInvoices
      ? "Fully Paid Invoices"
      : invoiceList === invoiceStore.partiallyPaidInvoices
        ? "Partially Paid Invoices"
        : invoiceList === invoiceStore.notPaidInvoices
          ? "Unpaid Invoices"
          : invoiceList === invoiceStore.dueIn30days
            ? "Invoices Due in 30 days"
            : invoiceList === invoiceStore.overDue
              ? "Overdue Invoices"
              : "All Recorded Invoices";

  const invoiceStatus = (status) => {
    return (
      status === constant.TRANSACTION_STATUS.UNPAID ||
      status === constant.TRANSACTION_STATUS.VOID
    );
  };
  let discountedAmount = 0;

  const goToNewReceipt = () => {
    // isOnboardingDone();
    setAddNewReceipt(true);
    setAddNewInvoice(false);
    setInvoiceItem(false);
    setAddBulkInvoice(false);
  };

  const goToBulkInvoice = () => {
    isOnboardingDone();
    setAddBulkInvoice(true);
    setAddNewReceipt(false);
    setAddNewInvoice(false);
    setInvoiceItem(false);
  };

  const goToViewInvoice = (value) => {
    invoiceStore.setSelectedInvoice(value);
    setViewInvoiceModal(true);
  };

  const goToEditInvoiceMain = (value) => {
    invoiceStore.setSelectedInvoice(value);
    setViewInvoiceModal(true);
    setEditInvoiceMain(true);
  };

  const closeModal = () => {
    setAddNewInvoice(false);
    setViewInvoiceModal(false);
    setAddNewReceipt(false);
    setEditInvoiceMain(false);
    setUpdateInvoiceModal(false);
    setAddBulkInvoice(false);
    setAddNewQuotation(false);
    setQuoteSummaryModal(false);
    setEditQuoteModal(false);
    setUpdateQuoteModal(false);
    setDupQuoteModal(false);
  };

  const sendInvoice = async (id) => {
    const { status, errorMessage } = await InvoiceController.sendInvoice(id);
    if (status === constant.Success) {
      return MimaToastUtil.success({
        message: constant.Sent,
      });
    }
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };
  const sendQuotation = async (id) => {
    const { status, errorMessage } =
      await QuotationController.sendQuotation(id);
    if (status === constant.Success) {
      return MimaToastUtil.success({
        message: constant.Sent,
      });
    }
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const resetFilter = () => {
    setLoading(true);
    setFilterPeriod(`${theYear}`);
    setFilterQuery("");
    setCurrentPage(0);
    setLoading(false);
  };
  const resetQuoteFilter = () => {
    onQuotSubmit();
    setFilterPeriod(`${theYear}`);
  };

  const downloadInvoice = (item) => {
    window.location.href = item.invoiceUrl || "";
  };

  const goBack = () => {
    if (editInvoice) {
      setEditInvoice(false);
    } else if (updateInvoice) {
      setUpdateInvoice(false);
    } else {
      closeModal();
    }
  };

  const handleOptionSelect = (_id, item, option) => {
    if (option.value === "View More Details") {
      goToViewInvoice(item, sendInvoice);
    } else if (option.value === "Edit") {
      goToEditInvoiceMain(item);
    } else if (option.value === "Duplicate") {
      goToNewInvoice(item);
    } else if (option.value === "Send to Customer") {
      sendInvoice(item._id);
    } else if (option.value === "Update to Receipt") {
      goToUpdateInvoice(item);
    } else if (option.value === "Download") {
      downloadInvoice(item);
    }
  };

  //Invoice update with the new react-query api call method

  const invoiceTableQuery = useMemo(() => {
    return `offset=${currentPage + 1}&limit=${limit}`;
  }, [currentPage, limit]);

  const { isLoading, data, isFetching } = InvoiceController.useInvoiceList(
    invoiceTableQuery,
    filterQuery,
    searchQuery
  );

  useMemo(() => {
    if (data?.status === constant.Success) {
      invoiceStore.setBusinessDetails(data?.data?.value?.business);
      invoiceStore.setTotalInvoiceCount(data?.data?.totalCounts);
    }
  }, [data]);

  const onLimitChange = (limit) => {
    setLimit(limit);
    setCurrentPage(0);
  };

  const invoiceDetails = invoiceStore.invoices;
  const overDue = invoiceStore.invoicesAnalysis.overDue;
  const dueInThirtyDays = invoiceStore.invoicesAnalysis.dueInThirtyDays;
  const invoiceListItem = invoiceStore.invoices;
  const quotationList = quotationStore.quotations;
  const curr = invoiceStore.selectedCurrency;

  return {
    invoiceDetails,
    statusesAnalysis,
    loading,
    addBulkInvoice,
    refresh,
    onDownload,
    overDue,
    viewInvoicesDueIn30Days,
    viewOverDueInvoices,
    dueInThirtyDays,
    goToBulkInvoice,
    onSubmit,
    onQuotSubmit,
    validationSchema,
    viewFullyPaidInvoices,
    viewNotPaidInvoices,
    viewPartiallyPaidInvoices,
    invoiceListItem,
    quotationList,
    invoiceStatus,
    discountedAmount,
    goToNewInvoice,
    addNewInvoice,
    closeModal,
    goToViewInvoice,
    viewInvoiceModal,
    addNewReceipt,
    goToNewReceipt,
    invoiceItem,
    editInvoiceMain,
    goToEditInvoiceMain,
    sendInvoice,
    sendQuotation,
    updateInvoiceModal,
    goToUpdateInvoice,
    invoiceFilteredText,
    filterPeriod,
    resetFilter,
    resetQuoteFilter,
    editInvoice,
    setEditInvoice,
    updateInvoice,
    setUpdateInvoice,
    goBack,
    handleOptionSelect,
    // handleQuoteOptionSelect={handleQuoteOptionSelect}
    curr,
    addNewQuotation,
    goToNewQuotation,
    goToQuoteSummary,
    goToEditQuote,
    quoteSummaryModal,
    editQuoteModal,
    tableType,
    setTableType,
    setEditQuoteModal,
    updateQuoteModal,
    goToUpdateQuote,
    dupQuoteModal,
    goToDuplicateQuoteModal,
    isLoading,
    data,
    isFetching,
    onLimitChange,
    limit,
    filterPeriods,
    getDate,
    currentPage,
    setCurrentPage,
    setSearchQuery,
  };
};

export default useInvoiceLogic;
