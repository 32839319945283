import React, { useState } from "react";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { ReportsController } from "../../../controllers";
import { date, object, string } from "yup";

const useReportDLLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = () => {
    return object({
      reportModule: string().required("A report module is required"),
      reportPeriod: string().required("A report period is required"),
      startDate: date().when("reportPeriod", {
        is: "Custom",
        then: (schema) => schema.required("Start Date is required"),
        otherwise: (schema) => schema.optional(),
      }),
      endDate: date().when("reportPeriod", {
        is: "Custom",
        then: (schema) => schema.required("End Date is required"),
        otherwise: (schema) => schema.optional(),
      }),
    });
  };

  const chooseAPI = async (item, payload) => {
    switch (item) {
      case "Bill Payment":
        return await ReportsController.getBillsReportByDate(payload);

      case "Customer":
        return await ReportsController.getCustomerReportByDate(payload);

      case "Invoice":
        return await ReportsController.getInvoiceReportByDate(payload);

      case "Payroll":
        return await ReportsController.getPayrollReportByDate(payload);

      case "Split Payment":
        return await ReportsController.getSplitPaymentReportByDate(payload);

      case "Financial Transaction":
        return await ReportsController.getTransactionReportByDate(payload);

      case "Stock":
        return await ReportsController.getInventoryReportByDate(payload);

      case "Petty Cash":
        return await ReportsController.getWalletReportByDate(payload);

      default:
        // Handle default case or do nothing
        return { status: "error", errorMessage: "Invalid item" };
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);

    const getPeriodDates = (period) => {
      const currentDate = new Date();
      let startDate, endDate;

      switch (period) {
        case "This Week":
          startDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() - currentDate.getDay()
          );
          endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + (6 - currentDate.getDay())
          );
          break;
        case "Last Week":
          startDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() - currentDate.getDay() - 7
          );
          endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() - currentDate.getDay() - 1
          );
          break;
        case "This Month":
          startDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          );
          endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
          );
          break;
        case "Last Month":
          startDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 1,
            1
          );
          endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            0
          );
          break;
        case "This Quarter":
          startDate = new Date(
            currentDate.getFullYear(),
            Math.floor(currentDate.getMonth() / 3) * 3,
            1
          );
          endDate = new Date(
            currentDate.getFullYear(),
            Math.floor(currentDate.getMonth() / 3) * 3 + 3,
            0
          );
          break;
        case "Last Quarter":
          startDate = new Date(
            currentDate.getFullYear(),
            Math.floor(currentDate.getMonth() / 3) * 3 - 3,
            1
          );
          endDate = new Date(
            currentDate.getFullYear(),
            Math.floor(currentDate.getMonth() / 3) * 3,
            0
          );
          break;
        case "This Year":
          startDate = new Date(currentDate.getFullYear(), 0, 1);
          endDate = new Date(currentDate.getFullYear(), 11, 31);
          break;
        case "Last Year":
          startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
          endDate = new Date(currentDate.getFullYear() - 1, 11, 31);
          break;
        case "Today":
          startDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          );
          endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          );
          break;
        default:
          startDate = null;
          endDate = null;
          break;
      }

      if (endDate && endDate > currentDate) {
        endDate = currentDate;
      }

      return {
        startDate,
        endDate,
      };
    };

    const { startDate, endDate } = getPeriodDates(values.reportPeriod);

    let payload;

    if (values.reportPeriod === "Custom") {
      payload = {
        startDate: values.startDate,
        endDate: values.endDate,
      };
    } else {
      payload = {
        startDate: startDate,
        endDate: endDate,
      };
    }

    const { status, errorMessage } = await chooseAPI(
      values.reportModule,
      payload
    );

    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: `Your ${values.reportModule} report has been downloaded, kindly open with excel`,
      });

      closeModal();

      return;
    }

    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  return {
    loading,
    validationSchema,
    onSubmit,
  };
};

export default useReportDLLogic;
