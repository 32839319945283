import React from "react";
import { MimaText, Modal } from "../../components";
import { websiteStore } from "../../stores";
import styles from "../../assets/styles/website/website.module.scss";
import { amountFormatter } from "../../utils/utils";

const ViewMoreStyle = ({ closeModal }) => {
  const selectedStyleData = websiteStore.selectedStyle;

  return (
    <Modal closeModal={closeModal}>
      <div>
        <MimaText
          variant="subtitle"
          color="var(--color-fash-primary-3)"
          align="center"
          mb={5}
        >
          View More Style Info
        </MimaText>
        <MimaText labelTitle="Style Name" mb={2}>
          {selectedStyleData?.name}
        </MimaText>
        <MimaText labelTitle="Gender" mt={2}>
          {selectedStyleData?.gender}
        </MimaText>
        <MimaText labelTitle="Pricing Model" mt={2}>
          {selectedStyleData?.pricingModel}
        </MimaText>
        <MimaText labelTitle="Pin To Top" mt={2}>
          {selectedStyleData?.topView ? "Yes" : "No"}
        </MimaText>
        <div>
          <MimaText variant="small" mt={2}>
            Sizes Available
          </MimaText>
          {selectedStyleData?.sizes?.map((item) => (
            <MimaText>{item}</MimaText>
          ))}
        </div>
        {selectedStyleData?.pricingModel === "FIXED_PRICE" && (
          <>
            <MimaText mt={2} mb={1} variant="smallBold">
              Local Price
            </MimaText>
            <div className={styles.price}>
              <MimaText variant="small">With Material</MimaText>
              <MimaText variant="small">Without Material</MimaText>
              <MimaText variant="small">Promo Price</MimaText>
            </div>
            {selectedStyleData.sellingPrices
              .filter((x) => x?.type === "LOCAL")
              .map((item) => (
                <div className={styles.price}>
                  <MimaText>
                    {amountFormatter(item?.currencyCode).format(
                      item?.withMaterial
                    )}
                  </MimaText>
                  <MimaText>
                    {amountFormatter(item?.currencyCode).format(
                      item?.withoutMaterial
                    )}
                  </MimaText>
                  <MimaText>
                    {amountFormatter(item?.currencyCode).format(
                      item?.onSaleSlashPrice
                    )}
                  </MimaText>
                </div>
              ))}
          </>
        )}
        {selectedStyleData.pricingModel === "FIXED_PRICE" && (
          <>
            <MimaText mt={2} mb={1} variant="smallBold">
              International Price
            </MimaText>
            <div className={styles.price}>
              <MimaText variant="small">With Material</MimaText>
              <MimaText variant="small">Without Material</MimaText>
              <MimaText variant="small">Promo Price</MimaText>
            </div>
            {selectedStyleData.sellingPrices
              .filter((x) => x?.type === "INTERNATIONAL")
              .map((item) => (
                <div className={styles.price}>
                  <MimaText>
                    {amountFormatter(item?.currencyCode).format(
                      item?.withMaterial
                    )}
                  </MimaText>
                  <MimaText>
                    {amountFormatter(item?.currencyCode).format(
                      item?.withoutMaterial
                    )}
                  </MimaText>
                  <MimaText>
                    {amountFormatter(item?.currencyCode).format(
                      item?.onSaleSlashPrice
                    )}
                  </MimaText>
                </div>
              ))}
          </>
        )}
        <div>
          <MimaText variant="small" mt={2}>
            hash Tags
          </MimaText>
          {selectedStyleData?.hashTags.map((item) => (
            <MimaText>{item}</MimaText>
          ))}
        </div>
        <div>
          <MimaText variant="small" mt={2}>
            Style Collections
          </MimaText>
          {selectedStyleData?.styleCollections.map((item) => (
            <MimaText>{item}</MimaText>
          ))}
        </div>
        {selectedStyleData?.colorsAvailable.length > 1 ? (
          <div>
            <MimaText variant="small" mt={2} mb={1}>
              Colors Available
            </MimaText>
            {selectedStyleData?.colorsAvailable.map((item) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: item?.code,
                    borderRadius: "10PX",
                  }}
                ></div>
                <MimaText>{item?.color}</MimaText>
              </div>
            ))}
          </div>
        ) : (
          <MimaText labelTitle="Colors Available" variant="body" mt={2} mb={1}>
            No color added
          </MimaText>
        )}
        <MimaText labelTitle="Description" mt={2}>
          {selectedStyleData?.description}
        </MimaText>{" "}
        <>
          <MimaText mt={2} mb={1} align="center">
            Style Image
          </MimaText>
          {selectedStyleData?.imageUrls.map((imgUrl, i) => (
            <div key={i} className={styles.img}>
              <img src={imgUrl} alt="Style" width="100%" />
            </div>
          ))}
        </>
      </div>
    </Modal>
  );
};

export default ViewMoreStyle;
