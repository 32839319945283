import styles from '../../assets/styles/Components/InvoiceListItem.module.scss';
import { FaExpandAlt } from 'react-icons/fa';
import MimaText from '../MimaText';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	service: PropTypes.string,
	quantity: PropTypes.number,
	amount: PropTypes.number,
	date: PropTypes.string,
};
const defaultProps = {
	service: 'Change Service',
	quantity: 1,
	amount: 15000000,
	date: 'DD/MM/YY',
};

const InvoiceListItem = ({ service, quantity, amount, date, ...props }) => {
	return (
		<div className={styles.inv}>
			<div>
				<MimaText variant="xsmall">Service</MimaText>
				<p className={styles.inv__main}>{service}</p>
			</div>
			<div>
				<MimaText variant="xsmall">QTY</MimaText>
				<p className={styles.inv__main}>{quantity}</p>
			</div>
			<div>
				<MimaText variant="xsmall">Amount</MimaText>
				<p className={styles.inv__other}>{amount}</p>
			</div>
			<div>
				<MimaText variant="xsmall">Payment due on</MimaText>
				<p className={styles.inv__other}>{date}</p>
			</div>
			<div className={styles.inv__expand}>
				<FaExpandAlt />
			</div>
		</div>
	);
};

InvoiceListItem.propTypes = propTypes;

InvoiceListItem.defaultProps = defaultProps;

export default InvoiceListItem;
