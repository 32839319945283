import styles from "../../assets/styles/MimaInput.module.scss";

import React, { useMemo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import * as inputStyles from "./styles";
import { BsInfoCircleFill } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { Searchbar2 } from "../Searchbar";

const propTypes = {
  labelTitle: PropTypes.string,
  type: PropTypes.oneOf(["text", "number"]),
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["dropInput", "dropError"]),
  width: PropTypes.any,
  icon: PropTypes.element,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  rows: PropTypes.number,
  cols: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.any,
  passwordIcon: PropTypes.bool,
  fontSize: PropTypes.number,
  suggestionData: PropTypes.array,
  handleFilter: PropTypes.func,
  note: PropTypes.string,
};

const MimaExpressInput = ({
  labelTitle,
  placeholder,
  onBlur,
  onChange,
  value,
  disabled = false,
  type = "text",
  variant = "dropInput",
  width,
  height,
  id,
  max,
  min,
  name,
  mt,
  mr,
  mb,
  ml,
  rows,
  cols,
  touched,
  error,
  passwordIcon,
  fontSize,
  note,
  currentDropdownValue,
  onChangeDrop,
  dropData,
  ...props
}) => {
  const currentValue = useMemo(() => {
    return dropData.filter((x) => x.value === currentDropdownValue)[0];
  }, [currentDropdownValue, dropData]);
  const [openDrop, setOpenDrop] = useState(false);
  const [selected, setSelected] = useState(currentValue);
  const [filteredDropdown, setFilteredDropdown] = useState([]);

  const openDropHandler = () => {
    setOpenDrop(!openDrop);
  };

  const dropDownSetHandler = (dropDownOption) => {
    setSelected(dropDownOption);
    onChangeDrop(dropDownOption);
    setOpenDrop(false);
  };

  const errorVariant = useMemo(() => {
    if (variant === "dropInput") {
      return "dropError";
    }
    // return "error";
  }, [variant]);

  const dropDownList = filteredDropdown.map((item) => (
    <DropItem key={item.key} onClick={() => dropDownSetHandler(item)}>
      {item.icon ? <img src={item?.icon} alt="Icon" /> : ""}
      <span>{item.value}</span>
    </DropItem>
  ));
  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width} height={height}>
      <label className={styles.form__label}>{labelTitle}</label>
      <InputDropSkeleton>
        <InputSkeleton
          type={type}
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={disabled}
          onChange={onChange}
          variant={error && touched ? errorVariant : variant}
          onWheel={(event) => event.currentTarget.blur()}
          value={value}
          id={id}
          name={name}
          error={error}
        />
        <DropSkeleton onClick={openDropHandler}>
          {selected?.icon ? <img src={selected?.icon} alt="Icon" /> : ""}
          <span>{selected?.value}</span>

          <FaChevronDown
            style={
              openDrop
                ? { transition: "0.5s", transform: "rotate(180deg)" }
                : { transition: "0.5s" }
            }
          />
        </DropSkeleton>
      </InputDropSkeleton>
      {openDrop && (
        <DropdownBody
          width={width}
          // bodyVariant={bodyVariant}
          id="scrollableDiv"
        >
          <div className="searchInMiddle">
            <Searchbar2
              placeholder="Search list"
              input={dropData}
              loading={false}
              output={setFilteredDropdown}
              handleFilter={(item, searchQuery) => {
                return item?.value?.toLowerCase()?.includes(searchQuery);
              }}
              variant="dropdown"
              showIcon={false}
              mb={1}
            />
          </div>
          {dropDownList}
        </DropdownBody>
      )}

      {error ? <div className={styles.error}>{touched && error}</div> : null}
      {note ? (
        <div className={styles.note}>
          <BsInfoCircleFill style={{ fontSize: "2rem", marginRight: "1rem" }} />{" "}
          {note}
        </div>
      ) : null}
    </DivSkeleton>
  );
};

const InputSkeleton = styled.input`
  ${inputStyles.dropInput};
  ${(props) => inputStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(touched) => (touched ? `color: var(--color-dark)` : "")}
`;

const DivSkeleton = styled.div`
  ${inputStyles.expressBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const InputDropSkeleton = styled.div`
  ${inputStyles.inputDrop};
`;
const DropSkeleton = styled.div`
  ${inputStyles.drop};
`;
const DropdownBody = styled.div`
  ${inputStyles.dropBody};
`;
const DropItem = styled.div`
  ${inputStyles.dropItem};
`;

MimaExpressInput.propTypes = propTypes;

export default MimaExpressInput;
