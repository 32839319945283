import { css } from "styled-components";

export const medium = css`
  width: 38rem;
`;
export const form = css`
  width: 100%;
`;

export const error = css`
  width: 38rem;
  height: 6rem;
  border: 2px solid var(--color-error);
  outline: none;
  margin-bottom: 0.5rem;
`;

export const fullWidthError = css`
  width: 100%;
  height: 6rem;
  border: 2px solid var(--color-error);
  outline: none;
  margin-bottom: 0.5rem;
`;

export const divBase = css`
  margin-bottom: 2rem;
`;

export const multiBodyBase = css`
  background-color: var(--color-white);
  color: var(--color-dark);
  border: 1px solid var(--color-dark);
  width: 38rem;
  border-radius: 1rem;
  transition: all 0.4s;
  order: 1;
  max-height: 27rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 1rem

  &::-webkit-scrollbar {
    width: 1rem;
    background-color: var(--color-white);
    border-radius: 0 1rem 1rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 1rem;
  }

  &>: first-child {
    align-self: center;
  }
`;
export const iconBase = css`
  width: 6rem;
  height: 6rem;
  color: var(--color-dark);
  font-size: 2.4rem;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const multiDivBase = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid var(--color-dark);
  border-radius: 1rem;
  padding: 1rem;
  min-height: 6rem;
  max-height: 12rem;
  overflow-y: scroll;
  padding-left: 2rem;
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-dark);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  order: 0;
  position: relative;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    color: var(--color-dark);
    outline: none;
    border: 1px solid var(--color-primary);
  }
`;

export const searchDivBase = css`
  position: relative;
`;

export const searchBase = css`
  font-size: 1.8rem;
  font-family: inherit;
  color: var(--color-dark);
  padding-left: 2rem;
  height: 6rem;
  border-radius: 1rem;
  background-color: var(--color-white);
  border: 2px solid var(--color-primary);
  display: block;
  box-sizing: border-box;
`;
