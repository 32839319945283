import { isValidPhoneNumber } from "libphonenumber-js";
import { MimaToastUtil, SidebarData } from "../components";
import moment from "moment";
import constant from "./constant";
import { MeasurementController } from "../controllers";
import { useEffect, useState } from "react";
import axios from "axios";

export const generateRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";

  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const selectRandomColorByIndex = (index = 0) => {
  const options = ["#CCEDFF", "#E7D788", "#C1DAFF", "#97C1FF"];
  return options[index % options.length];
};

export const sortedDesc = (array) =>
  array.sort((objA, objB) => Number(objB.date) - Number(objA.date));

export const calculatePensionAmount = ({
  basicSalary,
  housingAllowance,
  transportAllowance,
}) => {
  return (basicSalary + housingAllowance + transportAllowance) * 0.08;
};

export const calculateNHFAmount = ({ basicSalary }) => {
  return basicSalary > 0 ? (2.5 * basicSalary) / 100 : 0;
};

export const calculateCRA = ({
  chargedPension,
  chargedNHF,
  grossPay,
  basicSalary,
  housingAllowance,
  transportAllowance,
}) => {
  const annualGross = grossPay * 12;
  const pension = chargedPension
    ? calculatePensionAmount({
        basicSalary,
        housingAllowance,
        transportAllowance,
      }) * 12
    : 0;
  const nhf = chargedNHF ? calculateNHFAmount({ basicSalary }) * 12 : 0;
  let taxRelief = 0.01 * grossPay * 12;
  taxRelief = taxRelief < 200000 ? 200000 : taxRelief;
  const salaryRelief = 0.2 * (annualGross - pension - nhf);

  return { taxRelief, salaryRelief };
};

export const calculateWHTAmount = (grossPay) => {
  return (5 * grossPay) / 100;
};

export const calculatePAYEAmount = ({
  grossPay,
  chargedPension,
  chargedNHF,
  basicSalary,
  housingAllowance,
  transportAllowance,
}) => {
  let yearlyGrossPay = grossPay * 12;
  const { taxRelief, salaryRelief } = calculateCRA({
    chargedPension,
    chargedNHF,
    grossPay,
    basicSalary,
    housingAllowance,
    transportAllowance,
  });

  const nhf = calculateNHFAmount({ basicSalary });

  const pension = calculatePensionAmount({
    basicSalary,
    housingAllowance,
    transportAllowance,
  });
  const onePercentGross = 0.01 * yearlyGrossPay;

  yearlyGrossPay -= taxRelief + salaryRelief;
  yearlyGrossPay -= chargedNHF ? nhf * 12 : 0;
  yearlyGrossPay -= chargedPension ? pension * 12 : 0;

  if (yearlyGrossPay < onePercentGross) {
    return onePercentGross / 12;
  }

  let taxAmount = 0;
  let next300 = 0;
  let first500 = 0;
  let next500 = 0;
  let next16M = 0;
  let next32M = 0;

  if (yearlyGrossPay < 300000) return 0;
  const first300 = yearlyGrossPay - 300000;
  taxAmount += 21000;
  if (first300 === 0) return taxAmount / 12;
  if (first300 > 0) {
    next300 = first300 - 300000;
    if (next300 === 0) return (taxAmount + 33000) / 12;
    if (next300 < 0) return (taxAmount + first300 * 0.15) / 12;
    taxAmount += next300 > 0 ? 33000 : next300 * 0.11;
  }
  if (next300 > 0) {
    first500 = next300 - 500000;
    if (first500 === 0) return (taxAmount + 75000) / 12;
    if (first500 < 0) return (taxAmount + next300 * 0.15) / 12;
    taxAmount += 75000;
  }
  if (first500 > 0) {
    next500 = first500 - 500000;
    if (next500 === 0) return (taxAmount + 95000) / 12;
    if (next500 < 0) return (taxAmount + first500 * 0.19) / 12;
    taxAmount += 95000;
  }
  if (next500 > 0) {
    next16M = next500 - 1600000;
    if (next16M === 0) return (taxAmount + 336000) / 12;
    if (next16M < 0) return (taxAmount + next500 * 0.21) / 12;
    taxAmount += 336000;
  }
  if (next16M > 0) {
    next32M = next16M - 3200000;
    if (next32M === 0) return (taxAmount + next16M * 0.24) / 12;
    if (next32M < 0) return (taxAmount + next16M * 0.24) / 12;
    taxAmount += next32M * 0.24;
  }
  return taxAmount / 12;
};

export const calculateNetAmount = (values) => {
  let totalAmount = 0;
  let { grossPay } = values;
  totalAmount = grossPay;
  totalAmount += values.performanceBonus || 0;
  if (values.deductions?.tax)
    totalAmount -= calculatePAYEAmount({
      grossPay,
      chargedPension: values.deductions?.pension,
      chargedNHF: values.deductions?.nhf,
      basicSalary: values.basicSalary,
      housingAllowance: values.housingAllowance,
      transportAllowance: values.transportAllowance,
    });
  if (values.deductions?.wht) totalAmount -= calculateWHTAmount(grossPay);
  if (values.deductions?.pension)
    totalAmount -= calculatePensionAmount({
      basicSalary: values.basicSalary,
      housingAllowance: values.housingAllowance,
      transportAllowance: values.transportAllowance,
    });
  if (values.deductions?.nhf)
    totalAmount -= calculateNHFAmount({ basicSalary: values.basicSalary });
  if (values.otherDeductionAmount && values.otherDeductionAmount > 0)
    totalAmount -= values.otherDeductionAmount;
  return amountFormatter().format(totalAmount);
};

export const getInitials = (name = "") =>
  name
    .match(/(\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();

export const isPhoneNumberValid = (number) => {
  return number?.length > 8 && isValidPhoneNumber(number);
};
export const currencies = ["NGN", "USD", "GBP", "EUR"].map((curr) => ({
  key: curr,
  value: curr,
}));

export const chargeSplitValues = ["CUSTOMER", "MY BUSINESS", "EQUAL SPLIT"].map(
  (charge) => ({
    key: charge,
    value: charge,
  })
);

export const amountFormatter = (currency) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: currency || "NGN",
  });

export const amountFormatterWithoutCurrency = (amount) => {
  // Use toLocaleString to add commas for thousands separator
  return amount.toLocaleString("en-NG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const capitalizeFirstLetter = function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
export const navigateToFirstRoute = (permissions) => {
  // const permissions = userStore.user?.permissions || [];
  const userPerm = [...new Set(permissions.map((perm) => perm.module))];
  const flattenedSidebarData = SidebarData.flatMap((item) => [
    item,
    ...(item.subNav || []),
  ]);
  const value = [
    ...new Set(
      flattenedSidebarData.map((bar) => userPerm.find((x) => x === bar.module))
    ),
  ].filter((perm) => perm !== undefined)[0];
  const { link } = flattenedSidebarData.find((bar) => bar.module === value);
  return link;
};

export function getMultipleRandom(arr, num) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
}

export const removeIdFromArray = (ids = [], id) => {
  const filterIds = ids.filter((x) => x._id !== id);
  return filterIds;
};

export const addIdFromArray = (ids = [], id) => {
  ids.push(id);
  return ids;
};

export const groupTransactionsByParam = (transactions, groupBy = "date") => {
  if (!transactions || !transactions.length || transactions.length === 0)
    return [];
  const formattedTransactions = [];
  const transactionsTitleSet = new Set(
    transactions.map((transaction) => transaction[groupBy])
  );
  [...transactionsTitleSet.values()].forEach((title) => {
    const savedTransactions = formattedTransactions.map(
      (savedTransaction) => savedTransaction[groupBy]
    );
    if (!savedTransactions.includes(title)) {
      const transactionsByParam = [];
      transactions.forEach((transaction) => {
        if (transaction[groupBy] === title) {
          transactionsByParam.push(transaction);
        }
      });
      formattedTransactions.push({ title, data: transactionsByParam });
    }
  });
  return formattedTransactions;
};

const today = new Date();
const month = today.getMonth() + 1;
export const theYear = today.getFullYear();
export let monthName = "";

switch (month) {
  case 1:
    monthName = "January";
    break;
  case 2:
    monthName = "February";
    break;
  case 3:
    monthName = "March";
    break;
  case 4:
    monthName = "April";
    break;
  case 5:
    monthName = "May";
    break;
  case 6:
    monthName = "June";
    break;
  case 7:
    monthName = "July";
    break;
  case 8:
    monthName = "August";
    break;
  case 9:
    monthName = "September";
    break;
  case 10:
    monthName = "October";
    break;
  case 11:
    monthName = "November";
    break;
  case 12:
    monthName = "December";
    break;
  default:
    monthName = "January";
}

export const salesChannelData = [
  {
    key: "Physical sale",
    value: "Physical sale",
  },
  {
    key: "Instagram",
    value: "Instagram",
  },
  {
    key: "WhatsApp",
    value: "WhatsApp",
  },
  {
    key: "Facebook",
    value: "Facebook",
  },
  {
    key: "Twitter",
    value: "Twitter",
  },
  {
    key: "Jiji",
    value: "Jiji",
  },
  {
    key: "Jumia",
    value: "Jumia",
  },
  {
    key: "Paystack",
    value: "Paystack",
  },
  {
    key: "Flutterwave",
    value: "Flutterwave",
  },
  {
    key: "Konga",
    value: "Konga",
  },
  {
    key: "Snapchat",
    value: "Snapchat",
  },
  {
    key: "Others",
    value: "Others",
  },
];

export const phoneRegExp = /^(\+?[0-9] ?(?:[0-9] ?){5,13}[0-9]|\d{11})$/;

export const getDate = (period) => {
  switch (period) {
    case "today": {
      const currentDate = moment().format("YYYY-MM-DD");
      return {
        startDate: currentDate,
        endDate: currentDate,
      };
    }
    case "thisweek": {
      const startDate = moment().startOf("weeks").format("YYYY-MM-DD");
      const endDate = moment().endOf("weeks").format("YYYY-MM-DD");
      return {
        startDate: startDate,
        endDate: endDate,
      };
    }
    case "lastweek": {
      const startDate = moment()
        .subtract(1, "weeks")
        .startOf("weeks")
        .format("YYYY-MM-DD");
      const endDate = moment()
        .subtract(1, "weeks")
        .endOf("weeks")
        .format("YYYY-MM-DD");
      return {
        startDate: startDate,
        endDate: endDate,
      };
    }
    case "thismonth": {
      const startDate = moment().startOf("month").format("YYYY-MM-DD");
      const endDate = moment().endOf("month").format("YYYY-MM-DD");
      return {
        startDate: startDate,
        endDate: endDate,
      };
    }
    case "lastmonth": {
      const startDate = moment()
        .subtract(1, "months")
        .startOf("months")
        .format("YYYY-MM-DD");
      const endDate = moment()
        .subtract(1, "months")
        .endOf("months")
        .format("YYYY-MM-DD");
      return {
        startDate: startDate,
        endDate: endDate,
      };
    }
    case "thisquarter": {
      const startDate = moment().startOf("quarter").format("YYYY-MM-DD");
      const endDate = moment().endOf("quarter").format("YYYY-MM-DD");
      return {
        startDate: startDate,
        endDate: endDate,
      };
    }
    case "lastquarter": {
      const startDate = moment()
        .subtract(1, "quarter")
        .startOf("quarter")
        .format("YYYY-MM-DD");
      const endDate = moment()
        .subtract(1, "quarter")
        .endOf("quarter")
        .format("YYYY-MM-DD");
      return {
        startDate: startDate,
        endDate: endDate,
      };
    }
    case "thisyear": {
      const startDate = moment().startOf("year").format("YYYY-MM-DD");
      const endDate = moment().format("YYYY-MM-DD");
      return {
        startDate: startDate,
        endDate: endDate,
      };
    }
    case "lastyear": {
      const startDate = moment()
        .subtract(1, "year")
        .startOf("year")
        .format("YYYY-MM-DD");
      const endDate = moment()
        .subtract(1, "year")
        .endOf("year")
        .format("YYYY-MM-DD");
      return {
        startDate: startDate,
        endDate: endDate,
      };
    }
    default: {
      return {
        startDate: "",
        endDate: "",
      };
    }
  }
};

export const filterPeriods = [
  {
    key: "today",
    value: "Today",
  },
  {
    key: "thisweek",
    value: "This Week",
  },
  {
    key: "lastweek",
    value: "Last Week",
  },
  {
    key: "thismonth",
    value: "This Month",
  },
  {
    key: "lastmonth",
    value: "Last Month",
  },
  {
    key: "thisquarter",
    value: "This Quarter",
  },
  {
    key: "lastquarter",
    value: "Last Quarter",
  },
  {
    key: "thisyear",
    value: "This Year",
  },
  {
    key: "lastyear",
    value: "Last Year",
  },
  {
    key: "custom",
    value: "Custom (Date Range)",
  },
];

export const encodeQueryData = (query) => {
  const encodedQuery = Object.entries(query).map(([key, value]) => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value || "")}`;
  });
  return encodedQuery.join("&");
};

export const getAvailableQuantity = (stock, stockItem) => {
  if (!stock) return 0;
  const matchingStock = stockItem?.find((item) => item._id === stock);
  return matchingStock ? matchingStock?.quantity : 50;
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>\[\]_\-+=;'\/\\])[A-Za-z\d!@#$%^&*(),.?":{}|<>\[\]_\-+=;'\/\\]+$/;

export const removeEmptyKeys = (payload) => {
  for (const key in payload) {
    if (payload[key] && typeof payload[key] === "object") {
      removeEmptyKeys(payload[key]);

      if (Object.keys(payload[key]).length === 0) {
        delete payload[key];
      }
    } else if (
      payload[key] === undefined ||
      payload[key] === null ||
      payload[key] === ""
    ) {
      delete payload[key];
    }
  }
};

export const handleImageUpload = async (files) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append("assets", files[i]);
  }

  const { data, status, errorMessage } =
    await MeasurementController.uploadDocument(formData);

  if (status === constant.Success) {
    return data;
  }
  MimaToastUtil.error({ message: errorMessage });
};

export const imgUrlsToFiles = async (urlArray) => {
  const files = await Promise.all(
    urlArray.map(async (url, index) => {
      const response = await fetch(url);

      const blob = await response.blob();
      const fileName = `file${index + 1}.${url.split(".").pop()}`;

      // Create a FileWithPreview object by extending File and adding a preview property
      const fileWithPreview = new File([blob], fileName);
      fileWithPreview.preview = URL.createObjectURL(blob);
      return fileWithPreview;
    })
  );

  return files;
};

export const colorsArray = [
  { color: "Abbey", code: "#4c4f56" },
  { color: "Absolute Zero", code: "#0048ba" },
  { color: "Acadia", code: "#1b1404" },
  { color: "Acapulco", code: "#7cb0a1" },
  { color: "Acid Green", code: "#b0bf1a" },
  { color: "Aero", code: "#7cb9e8" },
  { color: "Aero Blue", code: "#c9ffe5" },
  { color: "Affair", code: "#714693" },
  { color: "African Violet", code: "#b284be" },
  { color: "Air Force Blue", code: "#00308f" },
  { color: "Air Superiority Blue", code: "#72a0c1" },
  { color: "Akaroa", code: "#d4c4a8" },
  { color: "Alabama Crimson", code: "#af002a" },
  { color: "Alabaster", code: "#fafafa" },
  { color: "Albescent White", code: "#f5e9d3" },
  { color: "Algae Green", code: "#93dfb8" },
  { color: "Alice Blue", code: "#f0f8ff" },
  { color: "Alien Armpit", code: "#84de02" },
  { color: "Alizarin Crimson", code: "#e32636" },
  { color: "Alloy Orange", code: "#c46210" },
  { color: "Allports", code: "#0076a3" },
  { color: "Almond", code: "#efdecd" },
  { color: "Almond Frost", code: "#907b71" },
  { color: "Alpine", code: "#af8f2c" },
  { color: "Alto", code: "#dbdbdb" },
  { color: "Aluminium", code: "#a9acb6" },
  { color: "Amaranth", code: "#e52b50" },
  { color: "Amaranth Pink", code: "#f19cbb" },
  { color: "Amaranth Purple", code: "#ab274f" },
  { color: "Amaranth Red", code: "#d3212d" },
  { color: "Amazon", code: "#3b7a57" },
  { color: "Amber", code: "#ffbf00" },
  { color: "American Rose", code: "#ff033e" },
  { color: "Americano", code: "#87756e" },
  { color: "Amethyst", code: "#9966cc" },
  { color: "Amethyst Smoke", code: "#a397b4" },
  { color: "Amour", code: "#f9eaf3" },
  { color: "Amulet", code: "#7b9f80" },
  { color: "Anakiwa", code: "#9de5ff" },
  { color: "Android Green", code: "#a4c639" },
  { color: "Anti Flash White", code: "#f2f3f4" },
  { color: "Antique Brass", code: "#cd9575" },
  { color: "Antique Bronze", code: "#665d1e" },
  { color: "Antique Fuchsia", code: "#915c83" },
  { color: "Antique Ruby", code: "#841b2d" },
  { color: "Antique White", code: "#faebd7" },
  { color: "Anzac", code: "#e0b646" },
  { color: "Ao", code: "#008000" },
  { color: "Apache", code: "#dfbe6f" },
  { color: "Apple", code: "#4fa83d" },
  { color: "Apple Blossom", code: "#af4d43" },
  { color: "Apple Green", code: "#8db600" },
  { color: "Apricot", code: "#fbceb1" },
  { color: "Apricot White", code: "#fffeec" },
  { color: "Aqua Deep", code: "#014b43" },
  { color: "Aqua Forest", code: "#5fa777" },
  { color: "Aqua Haze", code: "#edf5f5" },
  { color: "Aqua Island", code: "#a1dad7" },
  { color: "Aqua Spring", code: "#eaf9f5" },
  { color: "Aqua Squeeze", code: "#e8f5f2" },
  { color: "Aquamarine", code: "#7fffd4" },
  { color: "Aquamarine Blue", code: "#71d9e2" },
  { color: "Arapawa", code: "#110c6c" },
  { color: "Arctic Lime", code: "#d0ff14" },
  { color: "Armadillo", code: "#433e37" },
  { color: "Army Green", code: "#4b5320" },
  { color: "Arrowtown", code: "#948771" },
  { color: "Arsenic", code: "#3b444b" },
  { color: "Artichoke", code: "#8f9779" },
  { color: "Arylide Yellow", code: "#e9d66b" },
  { color: "Ash", code: "#c6c3b5" },
  { color: "Ash Grey", code: "#b2beb5" },
  { color: "Asparagus", code: "#87a96b" },
  { color: "Asphalt", code: "#130a06" },
  { color: "Astra", code: "#faeab9" },
  { color: "Astral", code: "#327da0" },
  { color: "Astronaut", code: "#283a77" },
  { color: "Astronaut Blue", code: "#013e62" },
  { color: "Athens Gray", code: "#eef0f3" },
  { color: "Aths Special", code: "#ecebce" },
  { color: "Atlantis", code: "#97cd2d" },
  { color: "Atoll", code: "#0a6f75" },
  { color: "Au Chico", code: "#97605d" },
  { color: "Aubergine", code: "#3b0910" },
  { color: "Auburn", code: "#a52a2a" },
  { color: "Aureolin", code: "#fdee00" },
  { color: "Auro Metal Saurus", code: "#6e7f80" },
  { color: "Australian Mint", code: "#f5ffbe" },
  { color: "Avocado", code: "#568203" },
  { color: "Axolotl", code: "#4e6649" },
  { color: "Azalea", code: "#f7c8da" },
  { color: "Aztec", code: "#0d1c19" },
  { color: "Aztec Gold", code: "#c39953" },
  { color: "Azure", code: "#007fff" },
  { color: "Azure Mist", code: "#f0ffff" },
  { color: "Azureish White", code: "#dbe9f4" },
  { color: "Baby Blue", code: "#89cff0" },
  { color: "Baby Blue Eyes", code: "#a1caf1" },
  { color: "Baby Powder", code: "#fefefa" },
  { color: "Bahama Blue", code: "#026395" },
  { color: "Bahia", code: "#a5cb0c" },
  { color: "Baja White", code: "#fff8d1" },
  { color: "Baker Miller Pink", code: "#ff91af" },
  { color: "Bali Hai", code: "#859faf" },
  { color: "Ball Blue", code: "#21abcd" },
  { color: "Baltic Sea", code: "#2a2630" },
  { color: "Bamboo", code: "#da6304" },
  { color: "Banana Mania", code: "#fae7b5" },
  { color: "Banana Yellow", code: "#ffe135" },
  { color: "Bandicoot", code: "#858470" },
  { color: "Barberry", code: "#ded717" },
  { color: "Barbie Pink", code: "#e0218a" },
  { color: "Barley Corn", code: "#a68b5b" },
  { color: "Barley White", code: "#fff4ce" },
  { color: "Barn Red", code: "#7c0a02" },
  { color: "Barossa", code: "#44012d" },
  { color: "Bastille", code: "#292130" },
  { color: "Battleship Gray", code: "#828f72" },
  { color: "Bay Leaf", code: "#7da98d" },
  { color: "Bay of Many", code: "#273a81" },
  { color: "Bazaar", code: "#98777b" },
  { color: "Bdazzled Blue", code: "#2e5894" },
  { color: "Beau Blue", code: "#bcd4e6" },
  { color: "Beauty Bush", code: "#eec1be" },
  { color: "Beaver", code: "#9f8170" },
  { color: "Beeswax", code: "#fef2c7" },
  { color: "Beige", code: "#f5f5dc" },
  { color: "Belgion", code: "#add8ff" },
  { color: "Bermuda", code: "#7dd8c6" },
  { color: "Bermuda Gray", code: "#6b8ba2" },
  { color: "Beryl Green", code: "#dee5c0" },
  { color: "Bianca", code: "#fcfbf3" },
  { color: "Big Dip Oruby", code: "#9c2542" },
  { color: "Big Foot Feet", code: "#e88e5a" },
  { color: "Big Stone", code: "#162a40" },
  { color: "Bilbao", code: "#327c14" },
  { color: "Biloba Flower", code: "#b2a1ea" },
  { color: "Birch", code: "#373021" },
  { color: "Bird Flower", code: "#d4cd16" },
  { color: "Biscay", code: "#1b3162" },
  { color: "Bismark", code: "#497183" },
  { color: "Bison Hide", code: "#c1b7a4" },
  { color: "Bisque", code: "#ffe4c4" },
  { color: "Bistre", code: "#3d2b1f" },
  { color: "Bitter", code: "#868974" },
  { color: "Bitter Lemon", code: "#cae00d" },
  { color: "Bittersweet", code: "#fe6f5e" },
  { color: "Bittersweet Shimmer", code: "#bf4f51" },
  { color: "Bizarre", code: "#eededa" },
  { color: "Black", code: "#000000" },
  { color: "Black Bean", code: "#3d0c02" },
  { color: "Black Coral", code: "#54626f" },
  { color: "Black Forest", code: "#0b1304" },
  { color: "Black Haze", code: "#f6f7f7" },
  { color: "Black Leather Jacket", code: "#253529" },
  { color: "Black Marlin", code: "#3e2c1c" },
  { color: "Black Olive", code: "#3b3c36" },
  { color: "Black Pearl", code: "#041322" },
  { color: "Black Rock", code: "#0d0332" },
  { color: "Black Rose", code: "#67032d" },
  { color: "Black Russian", code: "#0a001c" },
  { color: "Black Shadows", code: "#bfafb2" },
  { color: "Black Squeeze", code: "#f2fafa" },
  { color: "Black White", code: "#fffef6" },
  { color: "Blackberry", code: "#4d0135" },
  { color: "Blackcurrant", code: "#32293a" },
  { color: "Blanched Almond", code: "#ffebcd" },
  { color: "Blast Off Bronze", code: "#a57164" },
  { color: "Blaze Orange", code: "#ff6700" },
  { color: "Bleach White", code: "#fef3d8" },
  { color: "Bleached Cedar", code: "#2c2133" },
  { color: "Bleu De France", code: "#318ce7" },
  { color: "Blizzard Blue", code: "#a3e3ed" },
  { color: "Blond", code: "#faf0be" },
  { color: "Blossom", code: "#dcb4bc" },
  { color: "Blue", code: "#0000ff" },
  { color: "Blue Bayoux", code: "#496679" },
  { color: "Blue Bell", code: "#a2a2d0" },
  { color: "Blue Chalk", code: "#f1e9ff" },
  { color: "Blue Charcoal", code: "#010d1a" },
  { color: "Blue Chill", code: "#0c8990" },
  { color: "Blue Diamond", code: "#380474" },
  { color: "Blue Dianne", code: "#204852" },
  { color: "Blue Gem", code: "#2c0e8c" },
  { color: "Blue Gray", code: "#6699cc" },
  { color: "Blue Green", code: "#0d98ba" },
  { color: "Blue Haze", code: "#bfbed8" },
  { color: "Blue Jeans", code: "#5dadec" },
  { color: "Blue Lagoon", code: "#ace5ee" },
  { color: "Blue Magenta Violet", code: "#553592" },
  { color: "Blue Marguerite", code: "#7666c6" },
  { color: "Blue Ribbon", code: "#0066ff" },
  { color: "Blue Romance", code: "#d2f6de" },
  { color: "Blue Sapphire", code: "#126180" },
  { color: "Blue Smoke", code: "#748881" },
  { color: "Blue Stone", code: "#016162" },
  { color: "Blue Violet", code: "#8a2be2" },
  { color: "Blue Whale", code: "#042e4c" },
  { color: "Blue Yonder", code: "#5072a7" },
  { color: "Blue Zodiac", code: "#13264d" },
  { color: "Blueberry", code: "#4f86f7" },
  { color: "Bluebonnet", code: "#1c1cf0" },
  { color: "Blumine", code: "#18587a" },
  { color: "Blush", code: "#de5d83" },
  { color: "Bole", code: "#79443b" },
  { color: "Bombay", code: "#afb1b8" },
  { color: "Bon Jour", code: "#e5e0e1" },
  { color: "Bondi Blue", code: "#0095b6" },
  { color: "Bone", code: "#e3dac9" },
  { color: "Booger Buster", code: "#dde26a" },
  { color: "Bordeaux", code: "#5c0120" },
  { color: "Bossanova", code: "#4e2a5a" },
  { color: "Boston Blue", code: "#3b91b4" },
  { color: "Boston University Red", code: "#cc0000" },
  { color: "Botticelli", code: "#c7dde5" },
  { color: "Bottle Green", code: "#006a4e" },
  { color: "Boulder", code: "#7a7a7a" },
  { color: "Bouquet", code: "#ae809e" },
  { color: "Bourbon", code: "#ba6f1e" },
  { color: "Boysenberry", code: "#873260" },
  { color: "Bracken", code: "#4a2a04" },
  { color: "Brandeis Blue", code: "#0070ff" },
  { color: "Brandy", code: "#dec196" },
  { color: "Brandy Punch", code: "#cd8429" },
  { color: "Brandy Rose", code: "#bb8983" },
  { color: "Brass", code: "#b5a642" },
  { color: "Breaker Bay", code: "#5da19f" },
  { color: "Brick Red", code: "#cb4154" },
  { color: "Bridal Heath", code: "#fffaf4" },
  { color: "Bridesmaid", code: "#fef0ec" },
  { color: "Bright Cerulean", code: "#1dacd6" },
  { color: "Bright Gray", code: "#3c4151" },
  { color: "Bright Green", code: "#66ff00" },
  { color: "Bright Lavender", code: "#bf94e4" },
  { color: "Bright Lilac", code: "#d891ef" },
  { color: "Bright Maroon", code: "#c32148" },
  { color: "Bright Navy Blue", code: "#1974d2" },
  { color: "Bright Red", code: "#b10000" },
  { color: "Bright Sun", code: "#fed33c" },
  { color: "Bright Turquoise", code: "#08e8de" },
  { color: "Bright Ube", code: "#d19fe8" },
  { color: "Bright Yellow", code: "#ffaa1d" },
  { color: "Brilliant Azure", code: "#3399ff" },
  { color: "Brilliant Lavender", code: "#f4bbff" },
  { color: "Brilliant Rose", code: "#ff55a3" },
  { color: "Brink Pink", code: "#fb607f" },
  { color: "British Racing Green", code: "#004225" },
  { color: "Bronco", code: "#aba196" },
  { color: "Bronze", code: "#cd7f32" },
  { color: "Bronze Olive", code: "#4e420c" },
  { color: "Bronze Yellow", code: "#737000" },
  { color: "Bronzetone", code: "#4d400f" },
  { color: "Broom", code: "#ffec13" },
  { color: "Brown", code: "#964b00" },
  { color: "Brown Bramble", code: "#592804" },
  { color: "Brown Derby", code: "#492615" },
  { color: "Brown Pod", code: "#401801" },
  { color: "Brown Rust", code: "#af593e" },
  { color: "Brown Sugar", code: "#af6e4d" },
  { color: "Brown Tumbleweed", code: "#37290e" },
  { color: "Brown Yellow", code: "#cc9966" },
  { color: "Brunswick Green", code: "#1b4d3e" },
  { color: "Bubble Gum", code: "#ffc1cc" },
  { color: "Bubbles", code: "#e7feff" },
  { color: "Buccaneer", code: "#622f30" },
  { color: "Bud", code: "#a8ae9c" },
  { color: "Bud Green", code: "#7bb661" },
  { color: "Buddha Gold", code: "#c1a004" },
  { color: "Buff", code: "#f0dc82" },
  { color: "Bulgarian Rose", code: "#480607" },
  { color: "Bull Shot", code: "#864d1e" },
  { color: "Bunker", code: "#0d1117" },
  { color: "Bunting", code: "#151f4c" },
  { color: "Burgundy", code: "#800020" },
  { color: "Burlywood", code: "#deb887" },
  { color: "Burnham", code: "#002e20" },
  { color: "Burning Orange", code: "#ff7034" },
  { color: "Burning Sand", code: "#d99376" },
  { color: "Burnished Brown", code: "#a17a74" },
  { color: "Burnt Maroon", code: "#420303" },
  { color: "Burnt Orange", code: "#cc5500" },
  { color: "Burnt Sienna", code: "#e97451" },
  { color: "Burnt Umber", code: "#8a3324" },
  { color: "Bush", code: "#0d2e1c" },
  { color: "Buttercup", code: "#f3ad16" },
  { color: "Buttered Rum", code: "#a1750d" },
  { color: "Butterfly Bush", code: "#624e9a" },
  { color: "Buttermilk", code: "#fff1b5" },
  { color: "Buttery White", code: "#fffcea" },
  { color: "Byzantine", code: "#bd33a4" },
  { color: "Byzantium", code: "#702963" },
  { color: "CG Blue", code: "#007aa5" },
  { color: "CG Red", code: "#e03c31" },
  { color: "Cab Sav", code: "#4d0a18" },
  { color: "Cabaret", code: "#d94972" },
  { color: "Cabbage Pont", code: "#3f4c3a" },
  { color: "Cactus", code: "#587156" },
  { color: "Cadet", code: "#536872" },
  { color: "Cadet Blue", code: "#5f9ea0" },
  { color: "Cadet Grey", code: "#91a3b0" },
  { color: "Cadillac", code: "#b04c6a" },
  { color: "Cadmium Green", code: "#006b3c" },
  { color: "Cadmium Orange", code: "#ed872d" },
  { color: "Cadmium Red", code: "#e30022" },
  { color: "Cadmium Yellow", code: "#fff600" },
  { color: "Cafe Noir", code: "#4b3621" },
  { color: "Cafe Royale", code: "#6f440c" },
  { color: "Cal Poly Green", code: "#1e4d2b" },
  { color: "Calico", code: "#e0c095" },
  { color: "California", code: "#fe9d04" },
  { color: "Calypso", code: "#31728d" },
  { color: "Camarone", code: "#00581a" },
  { color: "Cambridge Blue", code: "#a3c1ad" },
  { color: "Camelot", code: "#893456" },
  { color: "Cameo", code: "#d9b99b" },
  { color: "Cameo Pink", code: "#efbbcc" },
  { color: "Camouflage", code: "#3c3910" },
  { color: "Camouflage Green", code: "#78866b" },
  { color: "Can Can", code: "#d591a4" },
  { color: "Canary", code: "#f3fb62" },
  { color: "Canary Yellow", code: "#ffef00" },
  { color: "Candlelight", code: "#fcd917" },
  { color: "Candy Apple Red", code: "#ff0800" },
  { color: "Cannon Black", code: "#251706" },
  { color: "Cannon Pink", code: "#894367" },
  { color: "Cape Cod", code: "#3c4443" },
  { color: "Cape Honey", code: "#fee5ac" },
  { color: "Cape Palliser", code: "#a26645" },
  { color: "Caper", code: "#dcedb4" },
  { color: "Capri", code: "#00bfff" },
  { color: "Caput Mortuum", code: "#592720" },
  { color: "Caramel", code: "#ffddaf" },
  { color: "Cararra", code: "#eeeee8" },
  { color: "Cardin Green", code: "#01361c" },
  { color: "Cardinal", code: "#c41e3a" },
  { color: "Cardinal Pink", code: "#8c055e" },
  { color: "Careys Pink", code: "#d29eaa" },
  { color: "Caribbean Green", code: "#00cc99" },
  { color: "Carissma", code: "#ea88a8" },
  { color: "Carla", code: "#f3ffd8" },
  { color: "Carmine", code: "#960018" },
  { color: "Carmine Pink", code: "#eb4c42" },
  { color: "Carmine Red", code: "#ff0038" },
  { color: "Carnaby Tan", code: "#5c2e01" },
  { color: "Carnation", code: "#f95a61" },
  { color: "Carnation Pink", code: "#ffa6c9" },
  { color: "Carnelian", code: "#b31b1b" },
  { color: "Carolina Blue", code: "#56a0d3" },
  { color: "Carousel Pink", code: "#f9e0ed" },
  { color: "Carrot Orange", code: "#ed9121" },
  { color: "Casablanca", code: "#f8b853" },
  { color: "Casal", code: "#2f6168" },
  { color: "Cascade", code: "#8ba9a5" },
  { color: "Cashmere", code: "#e6bea5" },
  { color: "Casper", code: "#adbed1" },
  { color: "Castleton Green", code: "#00563b" },
  { color: "Castro", code: "#52001f" },
  { color: "Catalina Blue", code: "#062a78" },
  { color: "Catawba", code: "#703642" },
  { color: "Catskill White", code: "#eef6f7" },
  { color: "Cavern Pink", code: "#e3bebe" },
  { color: "Cedar", code: "#3e1c14" },
  { color: "Cedar Chest", code: "#c95a49" },
  { color: "Cedar Wood Finish", code: "#711a00" },
  { color: "Ceil", code: "#92a1cf" },
  { color: "Celadon", code: "#ace1af" },
  { color: "Celadon Green", code: "#2f847c" },
  { color: "Celery", code: "#b8c25d" },
  { color: "Celeste", code: "#b2ffff" },
  { color: "Celestial Blue", code: "#4997d0" },
  { color: "Cello", code: "#1e385b" },
  { color: "Celtic", code: "#163222" },
  { color: "Cement", code: "#8d7662" },
  { color: "Ceramic", code: "#fcfff9" },
  { color: "Cerise", code: "#de3163" },
  { color: "Cerise Pink", code: "#ec3b83" },
  { color: "Cerulean", code: "#007ba7" },
  { color: "Cerulean Blue", code: "#2a52be" },
  { color: "Cerulean Frost", code: "#6d9bc3" },
  { color: "Chablis", code: "#fff4f3" },
  { color: "Chalet Green", code: "#516e3d" },
  { color: "Chalky", code: "#eed794" },
  { color: "Chambray", code: "#354e8c" },
  { color: "Chamois", code: "#eddcb1" },
  { color: "Chamoisee", code: "#a0785a" },
  { color: "Champagne", code: "#f7e7ce" },
  { color: "Chantilly", code: "#f8c3df" },
  { color: "Charade", code: "#292937" },
  { color: "Charcoal", code: "#36454f" },
  { color: "Chardon", code: "#fff3f1" },
  { color: "Chardonnay", code: "#ffcd8c" },
  { color: "Charleston Green", code: "#232b2b" },
  { color: "Charlotte", code: "#baeef9" },
  { color: "Charm", code: "#d47494" },
  { color: "Charm Pink", code: "#e68fac" },
  { color: "Chartreuse", code: "#dfff00" },
  { color: "Chateau Green", code: "#40a860" },
  { color: "Chatelle", code: "#bdb3c7" },
  { color: "Chathams Blue", code: "#175579" },
  { color: "Chelsea Cucumber", code: "#83aa5d" },
  { color: "Chelsea Gem", code: "#9e5302" },
  { color: "Chenin", code: "#dfcd6f" },
  { color: "Cherokee", code: "#fcda98" },
  { color: "Cherry Blossom Pink", code: "#ffb7c5" },
  { color: "Cherry Pie", code: "#2a0359" },
  { color: "Cherrywood", code: "#651a14" },
  { color: "Cherub", code: "#f8d9e9" },
  { color: "Chestnut", code: "#954535" },
  { color: "Chetwode Blue", code: "#8581d9" },
  { color: "Chicago", code: "#5d5c58" },
  { color: "Chiffon", code: "#f1ffc8" },
  { color: "Chilean Fire", code: "#f77703" },
  { color: "Chilean Heath", code: "#fffde6" },
  { color: "China Ivory", code: "#fcffe7" },
  { color: "China Rose", code: "#a8516e" },
  { color: "Chinese Red", code: "#aa381e" },
  { color: "Chinese Violet", code: "#856088" },
  { color: "Chino", code: "#cec7a7" },
  { color: "Chinook", code: "#a8e3bd" },
  { color: "Chlorophyll Green", code: "#4aff00" },
  { color: "Chocolate", code: "#7b3f00" },
  { color: "Christalle", code: "#33036b" },
  { color: "Christi", code: "#67a712" },
  { color: "Christine", code: "#e7730a" },
  { color: "Chrome White", code: "#e8f1d4" },
  { color: "Chrome Yellow", code: "#ffa700" },
  { color: "Cinder", code: "#0e0e18" },
  { color: "Cinderella", code: "#fde1dc" },
  { color: "Cinereous", code: "#98817b" },
  { color: "Cinnabar", code: "#e34234" },
  { color: "Cinnamon Satin", code: "#cd607e" },
  { color: "Cioccolato", code: "#55280c" },
  { color: "Citrine", code: "#e4d00a" },
  { color: "Citrine White", code: "#faf7d6" },
  { color: "Citron", code: "#9fa91f" },
  { color: "Citrus", code: "#a1c50a" },
  { color: "Clairvoyant", code: "#480656" },
  { color: "Clam Shell", code: "#d4b6af" },
  { color: "Claret", code: "#7f1734" },
  { color: "Classic Rose", code: "#fbcce7" },
  { color: "Clay Ash", code: "#bdc8b3" },
  { color: "Clay Creek", code: "#8a8360" },
  { color: "Clear Day", code: "#e9fffd" },
  { color: "Clementine", code: "#e96e00" },
  { color: "Clinker", code: "#371d09" },
  { color: "Cloud", code: "#c7c4bf" },
  { color: "Cloud Burst", code: "#202e54" },
  { color: "Cloudy", code: "#aca59f" },
  { color: "Clover", code: "#384910" },
  { color: "Cobalt Blue", code: "#0047ab" },
  { color: "Cocoa Bean", code: "#481c1c" },
  { color: "Cocoa Brown", code: "#d2691e" },
  { color: "Coconut", code: "#965a3e" },
  { color: "Coconut Cream", code: "#f8f7dc" },
  { color: "Cod Gray", code: "#0b0b0b" },
  { color: "Coffee", code: "#6f4e37" },
  { color: "Coffee Bean", code: "#2a140e" },
  { color: "Cognac", code: "#9f381d" },
  { color: "Cola", code: "#3f2500" },
  { color: "Cold Purple", code: "#aba0d9" },
  { color: "Cold Turkey", code: "#cebaba" },
  { color: "Colonial White", code: "#ffedbc" },
  { color: "Columbia Blue", code: "#c4d8e2" },
  { color: "Comet", code: "#5c5d75" },
  { color: "Como", code: "#517c66" },
  { color: "Conch", code: "#c9d9d2" },
  { color: "Concord", code: "#7c7b7a" },
  { color: "Concrete", code: "#f2f2f2" },
  { color: "Confetti", code: "#e9d75a" },
  { color: "Congo Brown", code: "#593737" },
  { color: "Congo Pink", code: "#f88379" },
  { color: "Congress Blue", code: "#02478e" },
  { color: "Conifer", code: "#acdd4d" },
  { color: "Contessa", code: "#c6726b" },
  { color: "Cool Black", code: "#002e63" },
  { color: "Cool Grey", code: "#8c92ac" },
  { color: "Copper", code: "#b87333" },
  { color: "Copper Canyon", code: "#7e3a15" },
  { color: "Copper Penny", code: "#ad6f69" },
  { color: "Copper Red", code: "#cb6d51" },
  { color: "Copper Rose", code: "#996666" },
  { color: "Copper Rust", code: "#944747" },
  { color: "Coquelicot", code: "#ff3800" },
  { color: "Coral", code: "#ff7f50" },
  { color: "Coral Red", code: "#ff4040" },
  { color: "Coral Reef", code: "#c7bca2" },
  { color: "Coral Tree", code: "#a86b6b" },
  { color: "Cordovan", code: "#893f45" },
  { color: "Corduroy", code: "#606e68" },
  { color: "Coriander", code: "#c4d0b0" },
  { color: "Cork", code: "#40291d" },
  { color: "Corn", code: "#e7bf05" },
  { color: "Corn Field", code: "#f8facd" },
  { color: "Corn Harvest", code: "#8b6b0b" },
  { color: "Cornflower Blue", code: "#6495ed" },
  { color: "Cornflower Lilac", code: "#ffb0ac" },
  { color: "Cornsilk", code: "#fff8dc" },
  { color: "Corvette", code: "#fad3a2" },
  { color: "Cosmic", code: "#76395d" },
  { color: "Cosmic Cobalt", code: "#2e2d88" },
  { color: "Cosmic Latte", code: "#fff8e7" },
  { color: "Cosmos", code: "#ffd8d9" },
  { color: "Costa Del Sol", code: "#615d30" },
  { color: "Cotton Candy", code: "#ffbcd9" },
  { color: "Cotton Seed", code: "#c2bdb6" },
  { color: "County Green", code: "#01371a" },
  { color: "Cowboy", code: "#4d282d" },
  { color: "Coyote Brown", code: "#81613e" },
  { color: "Crail", code: "#b95140" },
  { color: "Cranberry", code: "#db5079" },
  { color: "Crater Brown", code: "#462425" },
  { color: "Crayola Blue", code: "#1f75fe" },
  { color: "Crayola Green", code: "#1cac78" },
  { color: "Crayola Orange", code: "#ff7538" },
  { color: "Crayola Red", code: "#ee204d" },
  { color: "Crayola Yellow", code: "#fce883" },
  { color: "Cream", code: "#fffdd0" },
  { color: "Cream Brulee", code: "#ffe5a0" },
  { color: "Cream Can", code: "#f5c85c" },
  { color: "Creole", code: "#1e0f04" },
  { color: "Crete", code: "#737829" },
  { color: "Crimson", code: "#dc143c" },
  { color: "Crimson Glory", code: "#be0032" },
  { color: "Crimson Red", code: "#990000" },
  { color: "Crocodile", code: "#736d58" },
  { color: "Crown of Thorns", code: "#771f1f" },
  { color: "Crowshead", code: "#1c1208" },
  { color: "Cruise", code: "#b5ecdf" },
  { color: "Crusoe", code: "#004816" },
  { color: "Crusta", code: "#fd7b33" },
  { color: "Cumin", code: "#924321" },
  { color: "Cumulus", code: "#fdffd5" },
  { color: "Cupid", code: "#fbbeda" },
  { color: "Curious Blue", code: "#2596d1" },
  { color: "Cutty Sark", code: "#507672" },
  { color: "Cyan", code: "#00ffff" },
  { color: "Cyan Azure", code: "#4e82b4" },
  { color: "Cyan Blue Azure", code: "#4682bf" },
  { color: "Cyan Cobalt Blue", code: "#28589c" },
  { color: "Cyan Cornflower Blue", code: "#188bc2" },
  { color: "Cyber Grape", code: "#58427c" },
  { color: "Cyber Yellow", code: "#ffd300" },
  { color: "Cyclamen", code: "#f56fa1" },
  { color: "Cyprus", code: "#003e40" },
  { color: "Daffodil", code: "#ffff31" },
  { color: "Daintree", code: "#012731" },
  { color: "Dairy Cream", code: "#f9e4bc" },
  { color: "Daisy Bush", code: "#4f2398" },
  { color: "Dallas", code: "#6e4b26" },
  { color: "Dandelion", code: "#f0e130" },
  { color: "Danube", code: "#6093d1" },
  { color: "Dark Blue", code: "#00008b" },
  { color: "Dark Blue Gray", code: "#666699" },
  { color: "Dark Brown", code: "#654321" },
  { color: "Dark Brown Tangelo", code: "#88654e" },
  { color: "Dark Burgundy", code: "#770f05" },
  { color: "Dark Byzantium", code: "#5d3954" },
  { color: "Dark Candy Apple Red", code: "#a40000" },
  { color: "Dark Cerulean", code: "#08457e" },
  { color: "Dark Chestnut", code: "#986960" },
  { color: "Dark Coral", code: "#cd5b45" },
  { color: "Dark Cyan", code: "#008b8b" },
  { color: "Dark Ebony", code: "#3c2005" },
  { color: "Dark Fern", code: "#0a480d" },
  { color: "Dark Goldenrod", code: "#b8860b" },
  { color: "Dark Green", code: "#013220" },
  { color: "Dark Gunmetal", code: "#1f262a" },
  { color: "Dark Imperial Blue", code: "#6e6ef9" },
  { color: "Dark Jungle Green", code: "#1a2421" },
  { color: "Dark Khaki", code: "#bdb76b" },
  { color: "Dark Lavender", code: "#734f96" },
  { color: "Dark Liver", code: "#534b4f" },
  { color: "Dark Magenta", code: "#8b008b" },
  { color: "Dark Medium Gray", code: "#a9a9a9" },
  { color: "Dark Midnight Blue", code: "#003366" },
  { color: "Dark Moss Green", code: "#4a5d23" },
  { color: "Dark Olive Green", code: "#556b2f" },
  { color: "Dark Orange", code: "#ff8c00" },
  { color: "Dark Orchid", code: "#9932cc" },
  { color: "Dark Pastel Blue", code: "#779ecb" },
  { color: "Dark Pastel Green", code: "#03c03c" },
  { color: "Dark Pastel Purple", code: "#966fd6" },
  { color: "Dark Pastel Red", code: "#c23b22" },
  { color: "Dark Pink", code: "#e75480" },
  { color: "Dark Puce", code: "#4f3a3c" },
  { color: "Dark Purple", code: "#301934" },
  { color: "Dark Raspberry", code: "#872657" },
  { color: "Dark Red", code: "#8b0000" },
  { color: "Dark Salmon", code: "#e9967a" },
  { color: "Dark Scarlet", code: "#560319" },
  { color: "Dark Sea Green", code: "#8fbc8f" },
  { color: "Dark Sienna", code: "#3c1414" },
  { color: "Dark Sky Blue", code: "#8cbed6" },
  { color: "Dark Slate Blue", code: "#483d8b" },
  { color: "Dark Slate Gray", code: "#2f4f4f" },
  { color: "Dark Spring Green", code: "#177245" },
  { color: "Dark Tan", code: "#918151" },
  { color: "Dark Tangerine", code: "#ffa812" },
  { color: "Dark Terra Cotta", code: "#cc4e5c" },
  { color: "Dark Turquoise", code: "#00ced1" },
  { color: "Dark Vanilla", code: "#d1bea8" },
  { color: "Dark Violet", code: "#9400d3" },
  { color: "Dark Yellow", code: "#9b870c" },
  { color: "Dartmouth Green", code: "#00703c" },
  { color: "Davys Grey", code: "#555555" },
  { color: "Dawn", code: "#a6a29a" },
  { color: "Dawn Pink", code: "#f3e9e5" },
  { color: "De York", code: "#7ac488" },
  { color: "Debian Red", code: "#d70a53" },
  { color: "Deco", code: "#d2da97" },
  { color: "Deep Blue", code: "#220878" },
  { color: "Deep Blush", code: "#e47698" },
  { color: "Deep Bronze", code: "#4a3004" },
  { color: "Deep Carmine", code: "#a9203e" },
  { color: "Deep Carmine Pink", code: "#ef3038" },
  { color: "Deep Carrot Orange", code: "#e9692c" },
  { color: "Deep Cerise", code: "#da3287" },
  { color: "Deep Chestnut", code: "#b94e48" },
  { color: "Deep Cove", code: "#051040" },
  { color: "Deep Fir", code: "#002900" },
  { color: "Deep Forest Green", code: "#182d09" },
  { color: "Deep Fuchsia", code: "#c154c1" },
  { color: "Deep Green", code: "#056608" },
  { color: "Deep Green Cyan Turquoise", code: "#0e7c61" },
  { color: "Deep Jungle Green", code: "#004b49" },
  { color: "Deep Koamaru", code: "#333366" },
  { color: "Deep Lemon", code: "#f5c71a" },
  { color: "Deep Lilac", code: "#9955bb" },
  { color: "Deep Magenta", code: "#cc00cc" },
  { color: "Deep Maroon", code: "#820000" },
  { color: "Deep Oak", code: "#412010" },
  { color: "Deep Pink", code: "#ff1493" },
  { color: "Deep Puce", code: "#a95c68" },
  { color: "Deep Red", code: "#850101" },
  { color: "Deep Ruby", code: "#843f5b" },
  { color: "Deep Saffron", code: "#ff9933" },
  { color: "Deep Sapphire", code: "#082567" },
  { color: "Deep Sea", code: "#01826b" },
  { color: "Deep Sea Green", code: "#095859" },
  { color: "Deep Space Sparkle", code: "#4a646c" },
  { color: "Deep Taupe", code: "#7e5e60" },
  { color: "Deep Teal", code: "#003532" },
  { color: "Deep Tuscan Red", code: "#66424d" },
  { color: "Deep Violet", code: "#330066" },
  { color: "Deer", code: "#ba8759" },
  { color: "Del Rio", code: "#b09a95" },
  { color: "Dell", code: "#396413" },
  { color: "Delta", code: "#a4a49d" },
  { color: "Deluge", code: "#7563a8" },
  { color: "Denim", code: "#1560bd" },
  { color: "Denim Blue", code: "#2243b6" },
  { color: "Derby", code: "#ffeed8" },
  { color: "Desaturated Cyan", code: "#669999" },
  { color: "Desert", code: "#ae6020" },
  { color: "Desert Sand", code: "#edc9af" },
  { color: "Desert Storm", code: "#f8f8f7" },
  { color: "Desire", code: "#ea3c53" },
  { color: "Dew", code: "#eafffe" },
  { color: "Di Serria", code: "#db995e" },
  { color: "Diamond", code: "#b9f2ff" },
  { color: "Diesel", code: "#130000" },
  { color: "Dim Gray", code: "#696969" },
  { color: "Dingley", code: "#5d7747" },
  { color: "Dingy Dungeon", code: "#c53151" },
  { color: "Dirt", code: "#9b7653" },
  { color: "Disco", code: "#871550" },
  { color: "Dixie", code: "#e29418" },
  { color: "Dodger Blue", code: "#1e90ff" },
  { color: "Dogs", code: "#b86d29" },
  { color: "Dogwood Rose", code: "#d71868" },
  { color: "Dollar Bill", code: "#85bb65" },
  { color: "Dolly", code: "#f9ff8b" },
  { color: "Dolphin", code: "#646077" },
  { color: "Domino", code: "#8e775e" },
  { color: "Don Juan", code: "#5d4c51" },
  { color: "Donkey Brown", code: "#664c28" },
  { color: "Dorado", code: "#6b5755" },
  { color: "Double Colonial White", code: "#eee3ad" },
  { color: "Double Pearl Lusta", code: "#fcf4d0" },
  { color: "Double Spanish White", code: "#e6d7b9" },
  { color: "Dove Gray", code: "#6d6c6c" },
  { color: "Downriver", code: "#092256" },
  { color: "Downy", code: "#6fd0c5" },
  { color: "Driftwood", code: "#af8751" },
  { color: "Drover", code: "#fdf7ad" },
  { color: "Duke Blue", code: "#00009c" },
  { color: "Dull Lavender", code: "#a899e6" },
  { color: "Dune", code: "#383533" },
  { color: "Dust Storm", code: "#e5ccc9" },
  { color: "Dusty Gray", code: "#a8989b" },
  { color: "Dutch White", code: "#efdfbb" },
  { color: "Eagle", code: "#b6baa4" },
  { color: "Eagle Green", code: "#004953" },
  { color: "Earls Green", code: "#c9b93b" },
  { color: "Early Dawn", code: "#fff9e6" },
  { color: "Earth Yellow", code: "#e1a95f" },
  { color: "East Bay", code: "#414c7d" },
  { color: "East Side", code: "#ac91ce" },
  { color: "Eastern Blue", code: "#1e9ab0" },
  { color: "Ebb", code: "#e9e3e3" },
  { color: "Ebony", code: "#555d50" },
  { color: "Ebony Clay", code: "#26283b" },
  { color: "Eclipse", code: "#311c17" },
  { color: "Ecru", code: "#c2b280" },
  { color: "Ecru White", code: "#f5f3e5" },
  { color: "Ecstasy", code: "#fa7814" },
  { color: "Eden", code: "#105852" },
  { color: "Edgewater", code: "#c8e3d7" },
  { color: "Edward", code: "#a2aeab" },
  { color: "Eerie Black", code: "#1b1b1b" },
  { color: "Egg Sour", code: "#fff4dd" },
  { color: "Egg White", code: "#ffefc1" },
  { color: "Eggplant", code: "#614051" },
  { color: "Eggshell", code: "#f0ead6" },
  { color: "Egyptian Blue", code: "#1034a6" },
  { color: "El Paso", code: "#1e1708" },
  { color: "El Salva", code: "#8f3e33" },
  { color: "Electric Blue", code: "#7df9ff" },
  { color: "Electric Crimson", code: "#ff003f" },
  { color: "Electric Indigo", code: "#6f00ff" },
  { color: "Electric Lime", code: "#ccff00" },
  { color: "Electric Purple", code: "#bf00ff" },
  { color: "Electric Violet", code: "#8b00ff" },
  { color: "Electric Yellow", code: "#ffff33" },
  { color: "Elephant", code: "#123447" },
  { color: "Elf Green", code: "#088370" },
  { color: "Elm", code: "#1c7c7d" },
  { color: "Emerald", code: "#50c878" },
  { color: "Eminence", code: "#6c3082" },
  { color: "Emperor", code: "#514649" },
  { color: "Empress", code: "#817377" },
  { color: "Endeavour", code: "#0056a7" },
  { color: "Energy Yellow", code: "#f8dd5c" },
  { color: "Engineering International Orange", code: "#ba160c" },
  { color: "English Holly", code: "#022d15" },
  { color: "English Lavender", code: "#b48395" },
  { color: "English Red", code: "#ab4b52" },
  { color: "English Vermillion", code: "#cc474b" },
  { color: "English Walnut", code: "#3e2b23" },
  { color: "Envy", code: "#8ba690" },
  { color: "Equator", code: "#e1bc64" },
  { color: "Espresso", code: "#612718" },
  { color: "Eternity", code: "#211a0e" },
  { color: "Eton Blue", code: "#96c8a2" },
  { color: "Eucalyptus", code: "#44d7a8" },
  { color: "Eunry", code: "#cfa39d" },
  { color: "Evening Sea", code: "#024e46" },
  { color: "Everglade", code: "#1c402e" },
  { color: "FOGRA29 Rich Black", code: "#010b13" },
  { color: "FOGRA39 Rich Black", code: "#010203" },
  { color: "Faded Jade", code: "#427977" },
  { color: "Fair Pink", code: "#ffefec" },
  { color: "Falcon", code: "#7f626d" },
  { color: "Fallow", code: "#c19a6b" },
  { color: "Falu Red", code: "#801818" },
  { color: "Fandango", code: "#b53389" },
  { color: "Fandango Pink", code: "#de5285" },
  { color: "Fantasy", code: "#faf3f0" },
  { color: "Fashion Fuchsia", code: "#f400a1" },
  { color: "Fawn", code: "#e5aa70" },
  { color: "Fedora", code: "#796a78" },
  { color: "Feijoa", code: "#9fdd8c" },
  { color: "Feldgrau", code: "#4d5d53" },
  { color: "Fern", code: "#63b76c" },
  { color: "Fern Frond", code: "#657220" },
  { color: "Fern Green", code: "#4f7942" },
  { color: "Ferra", code: "#704f50" },
  { color: "Ferrari Red", code: "#ff2800" },
  { color: "Festival", code: "#fbe96c" },
  { color: "Feta", code: "#f0fcea" },
  { color: "Field Drab", code: "#6c541e" },
  { color: "Fiery Orange", code: "#b35213" },
  { color: "Fiery Rose", code: "#ff5470" },
  { color: "Finch", code: "#626649" },
  { color: "Finlandia", code: "#556d56" },
  { color: "Finn", code: "#692d54" },
  { color: "Fiord", code: "#405169" },
  { color: "Fire", code: "#aa4203" },
  { color: "Fire Bush", code: "#e89928" },
  { color: "Fire Engine Red", code: "#ce2029" },
  { color: "Firebrick", code: "#b22222" },
  { color: "Firefly", code: "#0e2a30" },
  { color: "Flame", code: "#e25822" },
  { color: "Flame Pea", code: "#da5b38" },
  { color: "Flamenco", code: "#ff7d07" },
  { color: "Flamingo", code: "#f2552a" },
  { color: "Flamingo Pink", code: "#fc8eac" },
  { color: "Flavescent", code: "#f7e98e" },
  { color: "Flax", code: "#eedc82" },
  { color: "Flax Smoke", code: "#7b8265" },
  { color: "Flint", code: "#6f6a61" },
  { color: "Flirt", code: "#a2006d" },
  { color: "Floral White", code: "#fffaf0" },
  { color: "Flush Mahogany", code: "#ca3435" },
  { color: "Foam", code: "#d8fcfa" },
  { color: "Fog", code: "#d7d0ff" },
  { color: "Foggy Gray", code: "#cbcab6" },
  { color: "Folly", code: "#ff004f" },
  { color: "Forest Green", code: "#228b22" },
  { color: "Forget Me Not", code: "#fff1ee" },
  { color: "Fountain Blue", code: "#56b4be" },
  { color: "Frangipani", code: "#ffdeb3" },
  { color: "French Bistre", code: "#856d4d" },
  { color: "French Blue", code: "#0072bb" },
  { color: "French Fuchsia", code: "#fd3f92" },
  { color: "French Gray", code: "#bdbdc6" },
  { color: "French Lilac", code: "#86608e" },
  { color: "French Lime", code: "#9efd38" },
  { color: "French Mauve", code: "#d473d4" },
  { color: "French Pass", code: "#bdedfd" },
  { color: "French Pink", code: "#fd6c9e" },
  { color: "French Plum", code: "#811453" },
  { color: "French Puce", code: "#4e1609" },
  { color: "French Raspberry", code: "#c72c48" },
  { color: "French Rose", code: "#f64a8a" },
  { color: "French Sky Blue", code: "#77b5fe" },
  { color: "French Violet", code: "#8806ce" },
  { color: "French Wine", code: "#ac1e44" },
  { color: "Fresh Air", code: "#a6e7ff" },
  { color: "Fresh Eggplant", code: "#990066" },
  { color: "Friar Gray", code: "#807e79" },
  { color: "Fringy Flower", code: "#b1e2c1" },
  { color: "Froly", code: "#f57584" },
  { color: "Frost", code: "#edf5dd" },
  { color: "Frostbite", code: "#e936a7" },
  { color: "Frosted Mint", code: "#dbfff8" },
  { color: "Frostee", code: "#e4f6e7" },
  { color: "Fruit Salad", code: "#4f9d5d" },
  { color: "Fuchsia", code: "#ff00ff" },
  { color: "Fuchsia Blue", code: "#7a58c1" },
  { color: "Fuchsia Pink", code: "#ff77ff" },
  { color: "Fuchsia Purple", code: "#cc397b" },
  { color: "Fuchsia Rose", code: "#c74375" },
  { color: "Fuego", code: "#bede0d" },
  { color: "Fuel Yellow", code: "#eca927" },
  { color: "Fulvous", code: "#e48400" },
  { color: "Fun Blue", code: "#1959a8" },
  { color: "Fun Green", code: "#016d39" },
  { color: "Fuscous Gray", code: "#54534d" },
  { color: "Fuzzy Wuzzy", code: "#cc6666" },
  { color: "Fuzzy Wuzzy Brown", code: "#c45655" },
  { color: "GO Green", code: "#00ab66" },
  { color: "Gable Green", code: "#163531" },
  { color: "Gainsboro", code: "#dcdcdc" },
  { color: "Gallery", code: "#efefef" },
  { color: "Galliano", code: "#dcb20c" },
  { color: "Gamboge", code: "#e49b0f" },
  { color: "Gamboge Orange", code: "#996600" },
  { color: "Gargoyle Gas", code: "#ffdf46" },
  { color: "Geebung", code: "#d18f1b" },
  { color: "Generic Viridian", code: "#007f66" },
  { color: "Genoa", code: "#15736b" },
  { color: "Geraldine", code: "#fb8989" },
  { color: "Geyser", code: "#d4dfe2" },
  { color: "Ghost", code: "#c7c9d5" },
  { color: "Ghost White", code: "#f8f8ff" },
  { color: "Giants Club", code: "#b05c52" },
  { color: "Giants Orange", code: "#fe5a1d" },
  { color: "Gigas", code: "#523c94" },
  { color: "Gimblet", code: "#b8b56a" },
  { color: "Gin", code: "#e8f2eb" },
  { color: "Gin Fizz", code: "#fff9e2" },
  { color: "Ginger", code: "#b06500" },
  { color: "Givry", code: "#f8e4bf" },
  { color: "Glacier", code: "#80b3c4" },
  { color: "Glade Green", code: "#61845f" },
  { color: "Glaucous", code: "#6082b6" },
  { color: "Glitter", code: "#e6e8fa" },
  { color: "Glossy Grape", code: "#ab92b3" },
  { color: "Go Ben", code: "#726d4e" },
  { color: "Goblin", code: "#3d7d52" },
  { color: "Gold Drop", code: "#f18200" },
  { color: "Gold Fusion", code: "#85754e" },
  { color: "Gold Tips", code: "#deba13" },
  { color: "Golden", code: "#ffd700" },
  { color: "Golden Bell", code: "#e28913" },
  { color: "Golden Brown", code: "#996515" },
  { color: "Golden Dream", code: "#f0d52d" },
  { color: "Golden Fizz", code: "#f5fb3d" },
  { color: "Golden Gate Bridge", code: "#c0362c" },
  { color: "Golden Glow", code: "#fde295" },
  { color: "Golden Poppy", code: "#fcc200" },
  { color: "Golden Sand", code: "#f0db7d" },
  { color: "Golden Tainoi", code: "#ffcc5c" },
  { color: "Golden Yellow", code: "#ffdf00" },
  { color: "Goldenrod", code: "#daa520" },
  { color: "Gondola", code: "#261414" },
  { color: "Gordons Green", code: "#0b1107" },
  { color: "Gorse", code: "#fff14f" },
  { color: "Gossamer", code: "#069b81" },
  { color: "Gossip", code: "#d2f8b0" },
  { color: "Gothic", code: "#6d92a1" },
  { color: "Governor Bay", code: "#2f3cb3" },
  { color: "Grain Brown", code: "#e4d5b7" },
  { color: "Grandis", code: "#ffd38c" },
  { color: "Granite Gray", code: "#676767" },
  { color: "Granite Green", code: "#8d8974" },
  { color: "Granny Apple", code: "#d5f6e3" },
  { color: "Granny Smith", code: "#84a0a0" },
  { color: "Granny Smith Apple", code: "#a8e4a0" },
  { color: "Grape", code: "#6f2da8" },
  { color: "Graphite", code: "#251607" },
  { color: "Gravel", code: "#4a444b" },
  { color: "Gray", code: "#808080" },
  { color: "Gray Asparagus", code: "#465945" },
  { color: "Gray Chateau", code: "#a2aab3" },
  { color: "Gray Nickel", code: "#c3c3bd" },
  { color: "Gray Nurse", code: "#e7ece6" },
  { color: "Gray Olive", code: "#a9a491" },
  { color: "Gray Suit", code: "#c1becd" },
  { color: "Green", code: "#00ff00" },
  { color: "Green Blue", code: "#1164b4" },
  { color: "Green Cyan", code: "#009966" },
  { color: "Green Haze", code: "#01a368" },
  { color: "Green House", code: "#24500f" },
  { color: "Green Kelp", code: "#25311c" },
  { color: "Green Leaf", code: "#436a0d" },
  { color: "Green Lizard", code: "#a7f432" },
  { color: "Green Mist", code: "#cbd3b0" },
  { color: "Green Pea", code: "#1d6142" },
  { color: "Green Sheen", code: "#6eaea1" },
  { color: "Green Smoke", code: "#a4af6e" },
  { color: "Green Spring", code: "#b8c1b1" },
  { color: "Green Vogue", code: "#032b52" },
  { color: "Green Waterloo", code: "#101405" },
  { color: "Green White", code: "#e8ebe0" },
  { color: "Green Yellow", code: "#adff2f" },
  { color: "Grenadier", code: "#d54600" },
  { color: "Grizzly", code: "#885818" },
  { color: "Grullo", code: "#a99a86" },
  { color: "Guardsman Red", code: "#ba0101" },
  { color: "Gulf Blue", code: "#051657" },
  { color: "Gulf Stream", code: "#80b3ae" },
  { color: "Gull Gray", code: "#9dacb7" },
  { color: "Gum Leaf", code: "#b6d3bf" },
  { color: "Gumbo", code: "#7ca1a6" },
  { color: "Gun Powder", code: "#414257" },
  { color: "Gunmetal", code: "#2a3439" },
  { color: "Gunsmoke", code: "#828685" },
  { color: "Gurkha", code: "#9a9577" },
  { color: "Hacienda", code: "#98811b" },
  { color: "Hairy Heath", code: "#6b2a14" },
  { color: "Haiti", code: "#1b1035" },
  { color: "Halayà Úbe", code: "#663854" },
  { color: "Half Baked", code: "#85c4cc" },
  { color: "Half Colonial White", code: "#fdf6d3" },
  { color: "Half Dutch White", code: "#fef7de" },
  { color: "Half Spanish White", code: "#fef4db" },
  { color: "Half and Half", code: "#fffee1" },
  { color: "Hampton", code: "#e5d8af" },
  { color: "Han Blue", code: "#446ccf" },
  { color: "Han Purple", code: "#5218fa" },
  { color: "Harlequin", code: "#3fff00" },
  { color: "Harlequin Green", code: "#46cb18" },
  { color: "Harp", code: "#e6f2ea" },
  { color: "Harvard Crimson", code: "#c90016" },
  { color: "Harvest Gold", code: "#da9100" },
  { color: "Havelock Blue", code: "#5590d9" },
  { color: "Hawaiian Tan", code: "#9d5616" },
  { color: "Hawkes Blue", code: "#d4e2fc" },
  { color: "Heat Wave", code: "#ff7a00" },
  { color: "Heath", code: "#541012" },
  { color: "Heather", code: "#b7c3d0" },
  { color: "Heathered Gray", code: "#b6b095" },
  { color: "Heavy Metal", code: "#2b3228" },
  { color: "Heliotrope", code: "#df73ff" },
  { color: "Heliotrope Gray", code: "#aa98a9" },
  { color: "Heliotrope Magenta", code: "#aa00bb" },
  { color: "Hemlock", code: "#5e5d3b" },
  { color: "Hemp", code: "#907874" },
  { color: "Hibiscus", code: "#b6316c" },
  { color: "Highland", code: "#6f8e63" },
  { color: "Hillary", code: "#aca586" },
  { color: "Himalaya", code: "#6a5d1b" },
  { color: "Hint of Green", code: "#e6ffe9" },
  { color: "Hint of Red", code: "#fbf9f9" },
  { color: "Hint of Yellow", code: "#fafde4" },
  { color: "Hippie Blue", code: "#589aaf" },
  { color: "Hippie Green", code: "#53824b" },
  { color: "Hippie Pink", code: "#ae4560" },
  { color: "Hit Gray", code: "#a1adb5" },
  { color: "Hit Pink", code: "#ffab81" },
  { color: "Hokey Pokey", code: "#c8a528" },
  { color: "Hoki", code: "#65869f" },
  { color: "Holly", code: "#011d13" },
  { color: "Honey Flower", code: "#4f1c70" },
  { color: "Honeydew", code: "#f0fff0" },
  { color: "Honeysuckle", code: "#edfc84" },
  { color: "Honolulu Blue", code: "#006db0" },
  { color: "Hookers Green", code: "#49796b" },
  { color: "Hopbush", code: "#d06da1" },
  { color: "Horizon", code: "#5a87a0" },
  { color: "Horses", code: "#543d37" },
  { color: "Horses Neck", code: "#604913" },
  { color: "Hot Magenta", code: "#ff1dce" },
  { color: "Hot Pink", code: "#ff69b4" },
  { color: "Hot Toddy", code: "#b38007" },
  { color: "Humming Bird", code: "#cff9f3" },
  { color: "Hunter Green", code: "#355e3b" },
  { color: "Hurricane", code: "#877c7b" },
  { color: "Husk", code: "#b7a458" },
  { color: "Ice Cold", code: "#b1f4e7" },
  { color: "Iceberg", code: "#71a6d2" },
  { color: "Icterine", code: "#fcf75e" },
  { color: "Illuminating Emerald", code: "#319177" },
  { color: "Illusion", code: "#f6a4c9" },
  { color: "Imperial", code: "#602f6b" },
  { color: "Imperial Blue", code: "#002395" },
  { color: "Imperial Red", code: "#ed2939" },
  { color: "Inch Worm", code: "#b0e313" },
  { color: "Inchworm", code: "#b2ec5d" },
  { color: "Independence", code: "#4c516d" },
  { color: "India Green", code: "#138808" },
  { color: "Indian Red", code: "#cd5c5c" },
  { color: "Indian Tan", code: "#4d1e01" },
  { color: "Indian Yellow", code: "#e3a857" },
  { color: "Indigo", code: "#4b0082" },
  { color: "Indigo Dye", code: "#091f92" },
  { color: "Indochine", code: "#c26b03" },
  { color: "International Klein Blue", code: "#002fa7" },
  { color: "International Orange", code: "#ff4f00" },
  { color: "Iris", code: "#5a4fcf" },
  { color: "Irish Coffee", code: "#5f3d26" },
  { color: "Iroko", code: "#433120" },
  { color: "Iron", code: "#d4d7d9" },
  { color: "Ironside Gray", code: "#676662" },
  { color: "Ironstone", code: "#86483c" },
  { color: "Irresistible", code: "#b3446c" },
  { color: "Isabelline", code: "#f4f0ec" },
  { color: "Islamic Green", code: "#009000" },
  { color: "Island Spice", code: "#fffcee" },
  { color: "Ivory", code: "#fffff0" },
  { color: "Jacaranda", code: "#2e0329" },
  { color: "Jacarta", code: "#3a2a6a" },
  { color: "Jacko Bean", code: "#2e1905" },
  { color: "Jacksons Purple", code: "#20208d" },
  { color: "Jade", code: "#00a86b" },
  { color: "Jaffa", code: "#ef863f" },
  { color: "Jagged Ice", code: "#c2e8e5" },
  { color: "Jagger", code: "#350e57" },
  { color: "Jaguar", code: "#080110" },
  { color: "Jambalaya", code: "#5b3013" },
  { color: "Janna", code: "#f4ebd3" },
  { color: "Japanese Carmine", code: "#9d2933" },
  { color: "Japanese Indigo", code: "#264348" },
  { color: "Japanese Laurel", code: "#0a6906" },
  { color: "Japanese Maple", code: "#780109" },
  { color: "Japanese Violet", code: "#5b3256" },
  { color: "Japonica", code: "#d87c63" },
  { color: "Jasmine", code: "#f8de7e" },
  { color: "Jasper", code: "#d73b3e" },
  { color: "Java", code: "#1fc2c2" },
  { color: "Jazzberry Jam", code: "#a50b5e" },
  { color: "Jelly Bean", code: "#da614e" },
  { color: "Jet", code: "#343434" },
  { color: "Jet Stream", code: "#b5d2ce" },
  { color: "Jewel", code: "#126b40" },
  { color: "Jon", code: "#3b1f1f" },
  { color: "Jonquil", code: "#f4ca16" },
  { color: "Jordy Blue", code: "#8ab9f1" },
  { color: "Judge Gray", code: "#544333" },
  { color: "Jumbo", code: "#7c7b82" },
  { color: "June Bud", code: "#bdda57" },
  { color: "Jungle Green", code: "#29ab87" },
  { color: "Jungle Mist", code: "#b4cfd3" },
  { color: "Juniper", code: "#6d9292" },
  { color: "Just Right", code: "#eccdb9" },
  { color: "KU Crimson", code: "#e8000d" },
  { color: "Kabul", code: "#5e483e" },
  { color: "Kaitoke Green", code: "#004620" },
  { color: "Kangaroo", code: "#c6c8bd" },
  { color: "Karaka", code: "#1e1609" },
  { color: "Karry", code: "#ffead4" },
  { color: "Kashmir Blue", code: "#507096" },
  { color: "Kelly Green", code: "#4cbb17" },
  { color: "Kelp", code: "#454936" },
  { color: "Kenyan Copper", code: "#7c1c05" },
  { color: "Keppel", code: "#3ab09e" },
  { color: "Key Lime", code: "#e8f48c" },
  { color: "Key Lime Pie", code: "#bfc921" },
  { color: "Khaki", code: "#c3b091" },
  { color: "Kidnapper", code: "#e1ead4" },
  { color: "Kilamanjaro", code: "#240c02" },
  { color: "Killarney", code: "#3a6a47" },
  { color: "Kimberly", code: "#736c9f" },
  { color: "Kingfisher Daisy", code: "#3e0480" },
  { color: "Kobi", code: "#e79fc4" },
  { color: "Kobicha", code: "#6b4423" },
  { color: "Kokoda", code: "#6e6d57" },
  { color: "Kombu Green", code: "#354230" },
  { color: "Korma", code: "#8f4b0e" },
  { color: "Koromiko", code: "#ffbd5f" },
  { color: "Kournikova", code: "#ffe772" },
  { color: "Kumera", code: "#886221" },
  { color: "La Palma", code: "#368716" },
  { color: "La Rioja", code: "#b3c110" },
  { color: "La Salle Green", code: "#087830" },
  { color: "Languid Lavender", code: "#d6cadd" },
  { color: "Lapis Lazuli", code: "#26619c" },
  { color: "Las Palmas", code: "#c6e610" },
  { color: "Laser", code: "#c8b568" },
  { color: "Laurel", code: "#749378" },
  { color: "Laurel Green", code: "#a9ba9d" },
  { color: "Lava", code: "#cf1020" },
  { color: "Lavender", code: "#b57edc" },
  { color: "Lavender Blush", code: "#fff0f5" },
  { color: "Lavender Gray", code: "#c4c3d0" },
  { color: "Lavender Indigo", code: "#9457eb" },
  { color: "Lavender Magenta", code: "#ee82ee" },
  { color: "Lavender Mist", code: "#e6e6fa" },
  { color: "Lavender Pink", code: "#fbaed2" },
  { color: "Lavender Purple", code: "#967bb6" },
  { color: "Lavender Rose", code: "#fba0e3" },
  { color: "Lawn Green", code: "#7cfc00" },
  { color: "Leather", code: "#967059" },
  { color: "Lemon", code: "#fff700" },
  { color: "Lemon Chiffon", code: "#fffacd" },
  { color: "Lemon Curry", code: "#cca01d" },
  { color: "Lemon Ginger", code: "#ac9e22" },
  { color: "Lemon Glacier", code: "#fdff00" },
  { color: "Lemon Grass", code: "#9b9e8f" },
  { color: "Lemon Lime", code: "#e3ff00" },
  { color: "Lemon Meringue", code: "#f6eabe" },
  { color: "Lemon Yellow", code: "#fff44f" },
  { color: "Lenurple", code: "#ba93d8" },
  { color: "Liberty", code: "#545aa7" },
  { color: "Licorice", code: "#1a1110" },
  { color: "Light Apricot", code: "#fdd5b1" },
  { color: "Light Blue", code: "#add8e6" },
  { color: "Light Brilliant Red", code: "#fe2e2e" },
  { color: "Light Brown", code: "#b5651d" },
  { color: "Light Carmine Pink", code: "#e66771" },
  { color: "Light Cobalt Blue", code: "#88ace0" },
  { color: "Light Coral", code: "#f08080" },
  { color: "Light Cornflower Blue", code: "#93ccea" },
  { color: "Light Crimson", code: "#f56991" },
  { color: "Light Cyan", code: "#e0ffff" },
  { color: "Light Deep Pink", code: "#ff5ccd" },
  { color: "Light French Beige", code: "#c8ad7f" },
  { color: "Light Fuchsia Pink", code: "#f984ef" },
  { color: "Light Goldenrod Yellow", code: "#fafad2" },
  { color: "Light Gray", code: "#d3d3d3" },
  { color: "Light Grayish Magenta", code: "#cc99cc" },
  { color: "Light Green", code: "#90ee90" },
  { color: "Light Hot Pink", code: "#ffb3de" },
  { color: "Light Khaki", code: "#f0e68c" },
  { color: "Light Medium Orchid", code: "#d39bcb" },
  { color: "Light Moss Green", code: "#addfad" },
  { color: "Light Orchid", code: "#e6a8d7" },
  { color: "Light Pastel Purple", code: "#b19cd9" },
  { color: "Light Pink", code: "#ffb6c1" },
  { color: "Light Salmon", code: "#ffa07a" },
  { color: "Light Salmon Pink", code: "#ff9999" },
  { color: "Light Sea Green", code: "#20b2aa" },
  { color: "Light Sky Blue", code: "#87cefa" },
  { color: "Light Slate Gray", code: "#778899" },
  { color: "Light Steel Blue", code: "#b0c4de" },
  { color: "Light Taupe", code: "#b38b6d" },
  { color: "Light Turquoise", code: "#afeeee" },
  { color: "Light Yellow", code: "#ffffe0" },
  { color: "Lightning Yellow", code: "#fcc01e" },
  { color: "Lilac", code: "#c8a2c8" },
  { color: "Lilac Bush", code: "#9874d3" },
  { color: "Lilac Luster", code: "#ae98aa" },
  { color: "Lily", code: "#c8aabf" },
  { color: "Lily White", code: "#e7f8ff" },
  { color: "Lima", code: "#76bd17" },
  { color: "Lime", code: "#bfff00" },
  { color: "Lime Green", code: "#32cd32" },
  { color: "Limeade", code: "#6f9d02" },
  { color: "Limed Ash", code: "#747d63" },
  { color: "Limed Oak", code: "#ac8a56" },
  { color: "Limed Spruce", code: "#394851" },
  { color: "Limerick", code: "#9dc209" },
  { color: "Lincoln Green", code: "#195905" },
  { color: "Linen", code: "#faf0e6" },
  { color: "Link Water", code: "#d9e4f5" },
  { color: "Lipstick", code: "#ab0563" },
  { color: "Lisbon Brown", code: "#423921" },
  { color: "Little Boy Blue", code: "#6ca0dc" },
  { color: "Liver", code: "#674c47" },
  { color: "Liver Chestnut", code: "#987456" },
  { color: "Livid Brown", code: "#4d282e" },
  { color: "Loafer", code: "#eef4de" },
  { color: "Loblolly", code: "#bdc9ce" },
  { color: "Lochinvar", code: "#2c8c84" },
  { color: "Lochmara", code: "#007ec7" },
  { color: "Locust", code: "#a8af8e" },
  { color: "Log Cabin", code: "#242a1d" },
  { color: "Logan", code: "#aaa9cd" },
  { color: "Lola", code: "#dfcfdb" },
  { color: "London Hue", code: "#bea6c3" },
  { color: "Lonestar", code: "#6d0101" },
  { color: "Lotus", code: "#863c3c" },
  { color: "Loulou", code: "#460b41" },
  { color: "Lucky", code: "#af9f1c" },
  { color: "Lucky Point", code: "#1a1a68" },
  { color: "Lumber", code: "#ffe4cd" },
  { color: "Lunar Green", code: "#3c493a" },
  { color: "Lust", code: "#e62020" },
  { color: "Luxor Gold", code: "#a7882c" },
  { color: "Lynch", code: "#697e9a" },
  { color: "MSU Green", code: "#18453b" },
  { color: "Mabel", code: "#d9f7ff" },
  { color: "Macaroni And Cheese", code: "#ffbd88" },
  { color: "Macaroni and Cheese", code: "#ffb97b" },
  { color: "Madang", code: "#b7f0be" },
  { color: "Madison", code: "#09255d" },
  { color: "Madras", code: "#3f3002" },
  { color: "Magenta", code: "#ca1f7b" },
  { color: "Magenta Haze", code: "#9f4576" },
  { color: "Magenta Pink", code: "#cc338b" },
  { color: "Magic Mint", code: "#aaf0d1" },
  { color: "Magic Potion", code: "#ff4466" },
  { color: "Magnolia", code: "#f8f4ff" },
  { color: "Mahogany", code: "#c04000" },
  { color: "Mai Tai", code: "#b06608" },
  { color: "Maize", code: "#fbec5d" },
  { color: "Majorelle Blue", code: "#6050dc" },
  { color: "Makara", code: "#897d6d" },
  { color: "Mako", code: "#444954" },
  { color: "Malachite", code: "#0bda51" },
  { color: "Malibu", code: "#7dc8f7" },
  { color: "Mallard", code: "#233418" },
  { color: "Malta", code: "#bdb2a1" },
  { color: "Mamba", code: "#8e8190" },
  { color: "Manatee", code: "#979aaa" },
  { color: "Mandalay", code: "#ad781b" },
  { color: "Mandarin", code: "#f37a48" },
  { color: "Mandy", code: "#e25465" },
  { color: "Mandys Pink", code: "#f2c3b2" },
  { color: "Mango Tango", code: "#ff8243" },
  { color: "Manhattan", code: "#f5c999" },
  { color: "Mantis", code: "#74c365" },
  { color: "Mantle", code: "#8b9c90" },
  { color: "Manz", code: "#eeef78" },
  { color: "Mardi Gras", code: "#880085" },
  { color: "Marigold", code: "#eaa221" },
  { color: "Marigold Yellow", code: "#fbe870" },
  { color: "Mariner", code: "#286acd" },
  { color: "Maroon", code: "#800000" },
  { color: "Maroon Oak", code: "#520c17" },
  { color: "Marshland", code: "#0b0f08" },
  { color: "Martini", code: "#afa09e" },
  { color: "Martinique", code: "#363050" },
  { color: "Marzipan", code: "#f8db9d" },
  { color: "Masala", code: "#403b38" },
  { color: "Matisse", code: "#1b659d" },
  { color: "Matrix", code: "#b05d54" },
  { color: "Matterhorn", code: "#4e3b41" },
  { color: "Mauve", code: "#e0b0ff" },
  { color: "Mauve Taupe", code: "#915f6d" },
  { color: "Mauvelous", code: "#ef98aa" },
  { color: "Maverick", code: "#d8c2d5" },
  { color: "Maximum Blue", code: "#47abcc" },
  { color: "Maximum Yellow", code: "#fafa37" },
  { color: "May Green", code: "#4c9141" },
  { color: "Maya Blue", code: "#73c2fb" },
  { color: "Meat Brown", code: "#e5b73b" },
  { color: "Medium Aquamarine", code: "#66ddaa" },
  { color: "Medium Blue", code: "#0000cd" },
  { color: "Medium Candy Apple Red", code: "#e2062c" },
  { color: "Medium Electric Blue", code: "#035096" },
  { color: "Medium Jungle Green", code: "#1c352d" },
  { color: "Medium Orchid", code: "#ba55d3" },
  { color: "Medium Purple", code: "#9370db" },
  { color: "Medium Red Violet", code: "#bb3385" },
  { color: "Medium Ruby", code: "#aa4069" },
  { color: "Medium Sea Green", code: "#3cb371" },
  { color: "Medium Sky Blue", code: "#80daeb" },
  { color: "Medium Slate Blue", code: "#7b68ee" },
  { color: "Medium Spring Bud", code: "#c9dc87" },
  { color: "Medium Spring Green", code: "#00fa9a" },
  { color: "Medium Turquoise", code: "#48d1cc" },
  { color: "Medium Vermilion", code: "#d9603b" },
  { color: "Melanie", code: "#e4c2d5" },
  { color: "Melanzane", code: "#300529" },
  { color: "Mellow Apricot", code: "#f8b878" },
  { color: "Melon", code: "#fdbcb4" },
  { color: "Melrose", code: "#c7c1ff" },
  { color: "Mercury", code: "#e5e5e5" },
  { color: "Merino", code: "#f6f0e6" },
  { color: "Merlin", code: "#413c37" },
  { color: "Merlot", code: "#831923" },
  { color: "Metal Pink", code: "#ff00fd" },
  { color: "Metallic Bronze", code: "#49371b" },
  { color: "Metallic Copper", code: "#71291d" },
  { color: "Metallic Gold", code: "#d4af37" },
  { color: "Metallic Seaweed", code: "#0a7e8c" },
  { color: "Metallic Sunburst", code: "#9c7c38" },
  { color: "Meteor", code: "#d07d12" },
  { color: "Meteorite", code: "#3c1f76" },
  { color: "Mexican Pink", code: "#e4007c" },
  { color: "Mexican Red", code: "#a72525" },
  { color: "Mid Gray", code: "#5f5f6e" },
  { color: "Midnight", code: "#702670" },
  { color: "Midnight Blue", code: "#191970" },
  { color: "Midnight Moss", code: "#041004" },
  { color: "Mikado", code: "#2d2510" },
  { color: "Mikado Yellow", code: "#ffc40c" },
  { color: "Milan", code: "#faffa4" },
  { color: "Milano Red", code: "#b81104" },
  { color: "Milk Punch", code: "#fff6d4" },
  { color: "Millbrook", code: "#594433" },
  { color: "Mimosa", code: "#f8fdd3" },
  { color: "Mindaro", code: "#e3f988" },
  { color: "Mine Shaft", code: "#323232" },
  { color: "Mineral Green", code: "#3f5d53" },
  { color: "Ming", code: "#36747d" },
  { color: "Minion Yellow", code: "#f5e050" },
  { color: "Minsk", code: "#3f307f" },
  { color: "Mint", code: "#3eb489" },
  { color: "Mint Cream", code: "#f5fffa" },
  { color: "Mint Green", code: "#98ff98" },
  { color: "Mint Julep", code: "#f1eec1" },
  { color: "Mint Tulip", code: "#c4f4eb" },
  { color: "Mirage", code: "#161928" },
  { color: "Mischka", code: "#d1d2dd" },
  { color: "Mist Gray", code: "#c4c4bc" },
  { color: "Misty Moss", code: "#bbb477" },
  { color: "Misty Rose", code: "#ffe4e1" },
  { color: "Mobster", code: "#7f7589" },
  { color: "Moccaccino", code: "#6e1d14" },
  { color: "Moccasin", code: "#ffe4b5" },
  { color: "Mocha", code: "#782d19" },
  { color: "Mojo", code: "#c04737" },
  { color: "Mona Lisa", code: "#ffa194" },
  { color: "Monarch", code: "#8b0723" },
  { color: "Mondo", code: "#4a3c30" },
  { color: "Mongoose", code: "#b5a27f" },
  { color: "Monsoon", code: "#8a8389" },
  { color: "Monte Carlo", code: "#83d0c6" },
  { color: "Monza", code: "#c7031e" },
  { color: "Moody Blue", code: "#7f76d3" },
  { color: "Moon Glow", code: "#fcfeda" },
  { color: "Moon Mist", code: "#dcddcc" },
  { color: "Moon Raker", code: "#d6cef6" },
  { color: "Moonstone Blue", code: "#73a9c2" },
  { color: "Mordant Red", code: "#ae0c00" },
  { color: "Morning Glory", code: "#9edee0" },
  { color: "Morocco Brown", code: "#441d00" },
  { color: "Mortar", code: "#504351" },
  { color: "Mosque", code: "#036a6e" },
  { color: "Moss Green", code: "#8a9a5b" },
  { color: "Mountain Meadow", code: "#30ba8f" },
  { color: "Mountain Mist", code: "#959396" },
  { color: "Mountbatten Pink", code: "#997a8d" },
  { color: "Muddy Waters", code: "#b78e5c" },
  { color: "Muesli", code: "#aa8b5b" },
  { color: "Mughal Green", code: "#306030" },
  { color: "Mulberry", code: "#c54b8c" },
  { color: "Mulberry Wood", code: "#5c0536" },
  { color: "Mule Fawn", code: "#8c472f" },
  { color: "Mulled Wine", code: "#4e4562" },
  { color: "Mummys Tomb", code: "#828e84" },
  { color: "Munsell Blue", code: "#0093af" },
  { color: "Munsell Green", code: "#00a877" },
  { color: "Munsell Purple", code: "#9f00c5" },
  { color: "Munsell Red", code: "#f2003c" },
  { color: "Munsell Yellow", code: "#efcc00" },
  { color: "Mustard", code: "#ffdb58" },
  { color: "My Pink", code: "#d69188" },
  { color: "My Sin", code: "#ffb31f" },
  { color: "Myrtle Green", code: "#317873" },
  { color: "Mystic", code: "#d65282" },
  { color: "Mystic Maroon", code: "#ad4379" },
  { color: "NCS Blue", code: "#0087bd" },
  { color: "NCS Green", code: "#009f6b" },
  { color: "NCS Red", code: "#c40233" },
  { color: "Nadeshiko Pink", code: "#f6adc6" },
  { color: "Nandor", code: "#4b5d52" },
  { color: "Napa", code: "#aca494" },
  { color: "Napier Green", code: "#2a8000" },
  { color: "Naples Yellow", code: "#fada5e" },
  { color: "Narvik", code: "#edf9f1" },
  { color: "Natural Gray", code: "#8b8680" },
  { color: "Navajo White", code: "#ffdead" },
  { color: "Navy", code: "#000080" },
  { color: "Nebula", code: "#cbdbd6" },
  { color: "Negroni", code: "#ffe2c5" },
  { color: "Neon Carrot", code: "#ffa343" },
  { color: "Neon Fuchsia", code: "#fe4164" },
  { color: "Neon Green", code: "#39ff14" },
  { color: "Nepal", code: "#8eabc1" },
  { color: "Neptune", code: "#7cb7bb" },
  { color: "Nero", code: "#140600" },
  { color: "Nevada", code: "#646e75" },
  { color: "New Car", code: "#214fc6" },
  { color: "New Orleans", code: "#f3d69d" },
  { color: "New York Pink", code: "#d7837f" },
  { color: "Niagara", code: "#06a189" },
  { color: "Nickel", code: "#727472" },
  { color: "Night Rider", code: "#1f120f" },
  { color: "Night Shadz", code: "#aa375a" },
  { color: "Nile Blue", code: "#193751" },
  { color: "Nobel", code: "#b7b1b1" },
  { color: "Nomad", code: "#bab1a2" },
  { color: "Non Photo Blue", code: "#a4dded" },
  { color: "North Texas Green", code: "#059033" },
  { color: "Norway", code: "#a8bd9f" },
  { color: "Nugget", code: "#c59922" },
  { color: "Nutmeg", code: "#81422c" },
  { color: "Nutmeg Wood Finish", code: "#683600" },
  { color: "Nyanza", code: "#e9ffdb" },
  { color: "Oasis", code: "#feefce" },
  { color: "Observatory", code: "#02866f" },
  { color: "Ocean Blue", code: "#4f42b5" },
  { color: "Ocean Boat Blue", code: "#0077be" },
  { color: "Ocean Green", code: "#48bf91" },
  { color: "Ochre", code: "#cc7722" },
  { color: "Off Green", code: "#e6f8f3" },
  { color: "Off Yellow", code: "#fef9e3" },
  { color: "Ogre Odor", code: "#fd5240" },
  { color: "Oil", code: "#281e15" },
  { color: "Old Brick", code: "#901e1e" },
  { color: "Old Burgundy", code: "#43302e" },
  { color: "Old Copper", code: "#724a2f" },
  { color: "Old Gold", code: "#cfb53b" },
  { color: "Old Heliotrope", code: "#563c5c" },
  { color: "Old Lace", code: "#fdf5e6" },
  { color: "Old Lavender", code: "#796878" },
  { color: "Old Moss Green", code: "#867e36" },
  { color: "Old Rose", code: "#c08081" },
  { color: "Old Silver", code: "#848482" },
  { color: "Olive", code: "#808000" },
  { color: "Olive Drab", code: "#6b8e23" },
  { color: "Olive Drab Seven", code: "#3c341f" },
  { color: "Olive Green", code: "#b5b35c" },
  { color: "Olive Haze", code: "#8b8470" },
  { color: "Olivetone", code: "#716e10" },
  { color: "Olivine", code: "#9ab973" },
  { color: "Onahau", code: "#cdf4ff" },
  { color: "Onion", code: "#2f270e" },
  { color: "Onyx", code: "#353839" },
  { color: "Opal", code: "#a9c6c2" },
  { color: "Opera Mauve", code: "#b784a7" },
  { color: "Opium", code: "#8e6f70" },
  { color: "Oracle", code: "#377475" },
  { color: "Orange", code: "#ff7f00" },
  { color: "Orange Peel", code: "#ff9f00" },
  { color: "Orange Red", code: "#ff4500" },
  { color: "Orange Roughy", code: "#c45719" },
  { color: "Orange Soda", code: "#fa5b3d" },
  { color: "Orange White", code: "#fefced" },
  { color: "Orange Yellow", code: "#f8d568" },
  { color: "Orchid", code: "#da70d6" },
  { color: "Orchid Pink", code: "#f2bdcd" },
  { color: "Orchid White", code: "#fffdf3" },
  { color: "Oregon", code: "#9b4703" },
  { color: "Organ", code: "#6c2e1f" },
  { color: "Orient", code: "#015e85" },
  { color: "Oriental Pink", code: "#c69191" },
  { color: "Orinoco", code: "#f3fbd4" },
  { color: "Orioles Orange", code: "#fb4f14" },
  { color: "Oslo Gray", code: "#878d91" },
  { color: "Ottoman", code: "#e9f8ed" },
  { color: "Outer Space", code: "#414a4c" },
  { color: "Outrageous Orange", code: "#ff6e4a" },
  { color: "Oxford Blue", code: "#002147" },
  { color: "Oxley", code: "#779e86" },
  { color: "Oyster Bay", code: "#dafaff" },
  { color: "Oyster Pink", code: "#e9cecd" },
  { color: "Paarl", code: "#a65529" },
  { color: "Pablo", code: "#776f61" },
  { color: "Pacific Blue", code: "#1ca9c9" },
  { color: "Pacifika", code: "#778120" },
  { color: "Paco", code: "#411f10" },
  { color: "Padua", code: "#ade6c4" },
  { color: "Pakistan Green", code: "#006600" },
  { color: "Palatinate Blue", code: "#273be2" },
  { color: "Palatinate Purple", code: "#682860" },
  { color: "Pale Brown", code: "#987654" },
  { color: "Pale Canary", code: "#ffff99" },
  { color: "Pale Carmine", code: "#af4035" },
  { color: "Pale Cerulean", code: "#9bc4e2" },
  { color: "Pale Chestnut", code: "#ddadaf" },
  { color: "Pale Copper", code: "#da8a67" },
  { color: "Pale Cornflower Blue", code: "#abcdef" },
  { color: "Pale Cyan", code: "#87d3f8" },
  { color: "Pale Gold", code: "#e6be8a" },
  { color: "Pale Goldenrod", code: "#eee8aa" },
  { color: "Pale Green", code: "#98fb98" },
  { color: "Pale Lavender", code: "#dcd0ff" },
  { color: "Pale Leaf", code: "#c0d3b9" },
  { color: "Pale Magenta", code: "#f984e5" },
  { color: "Pale Magenta Pink", code: "#ff99cc" },
  { color: "Pale Oyster", code: "#988d77" },
  { color: "Pale Pink", code: "#fadadd" },
  { color: "Pale Plum", code: "#dda0dd" },
  { color: "Pale Prim", code: "#fdfeb8" },
  { color: "Pale Red Violet", code: "#db7093" },
  { color: "Pale Robin Egg Blue", code: "#96ded1" },
  { color: "Pale Rose", code: "#ffe1f2" },
  { color: "Pale Silver", code: "#c9c0bb" },
  { color: "Pale Sky", code: "#6e7783" },
  { color: "Pale Slate", code: "#c3bfc1" },
  { color: "Pale Spring Bud", code: "#ecebbd" },
  { color: "Pale Taupe", code: "#bc987e" },
  { color: "Pale Violet", code: "#cc99ff" },
  { color: "Palm Green", code: "#09230f" },
  { color: "Palm Leaf", code: "#19330e" },
  { color: "Pampas", code: "#f4f2ee" },
  { color: "Panache", code: "#eaf6ee" },
  { color: "Pancho", code: "#edcdab" },
  { color: "Pansy Purple", code: "#78184a" },
  { color: "Pantone Blue", code: "#0018a8" },
  { color: "Pantone Green", code: "#00ad43" },
  { color: "Pantone Magenta", code: "#d0417e" },
  { color: "Pantone Orange", code: "#ff5800" },
  { color: "Pantone Pink", code: "#d74894" },
  { color: "Pantone Yellow", code: "#fedf00" },
  { color: "Paolo Veronese Green", code: "#009b7d" },
  { color: "Papaya Whip", code: "#ffefd5" },
  { color: "Paprika", code: "#8d0226" },
  { color: "Paradise Pink", code: "#e63e62" },
  { color: "Paradiso", code: "#317d82" },
  { color: "Parchment", code: "#f1e9d2" },
  { color: "Paris Daisy", code: "#fff46e" },
  { color: "Paris M", code: "#26056a" },
  { color: "Paris White", code: "#cadcd4" },
  { color: "Parsley", code: "#134f19" },
  { color: "Pastel Blue", code: "#aec6cf" },
  { color: "Pastel Brown", code: "#836953" },
  { color: "Pastel Gray", code: "#cfcfc4" },
  { color: "Pastel Green", code: "#77dd77" },
  { color: "Pastel Magenta", code: "#f49ac2" },
  { color: "Pastel Orange", code: "#ffb347" },
  { color: "Pastel Pink", code: "#dea5a4" },
  { color: "Pastel Purple", code: "#b39eb5" },
  { color: "Pastel Red", code: "#ff6961" },
  { color: "Pastel Violet", code: "#cb99c9" },
  { color: "Pastel Yellow", code: "#fdfd96" },
  { color: "Patina", code: "#639a8f" },
  { color: "Pattens Blue", code: "#def5ff" },
  { color: "Paua", code: "#260368" },
  { color: "Pavlova", code: "#d7c498" },
  { color: "Paynes Grey", code: "#536878" },
  { color: "Peach", code: "#ffcba4" },
  { color: "Peach Cream", code: "#fff0db" },
  { color: "Peach Orange", code: "#ffcc99" },
  { color: "Peach Puff", code: "#ffdab9" },
  { color: "Peach Schnapps", code: "#ffdcd6" },
  { color: "Peach Yellow", code: "#fadfad" },
  { color: "Peanut", code: "#782f16" },
  { color: "Pear", code: "#d1e231" },
  { color: "Pearl", code: "#eae0c8" },
  { color: "Pearl Aqua", code: "#88d8c0" },
  { color: "Pearl Bush", code: "#e8e0d5" },
  { color: "Pearl Lusta", code: "#fcf4dc" },
  { color: "Pearl Mystic Turquoise", code: "#32c6a6" },
  { color: "Pearly Purple", code: "#b768a2" },
  { color: "Peat", code: "#716b56" },
  { color: "Pelorous", code: "#3eabbf" },
  { color: "Peppermint", code: "#e3f5e1" },
  { color: "Perano", code: "#a9bef2" },
  { color: "Perfume", code: "#d0bef8" },
  { color: "Peridot", code: "#e6e200" },
  { color: "Periglacial Blue", code: "#e1e6d6" },
  { color: "Periwinkle", code: "#ccccff" },
  { color: "Periwinkle Gray", code: "#c3cde6" },
  { color: "Permanent Geranium Lake", code: "#e12c2c" },
  { color: "Persian Blue", code: "#1c39bb" },
  { color: "Persian Green", code: "#00a693" },
  { color: "Persian Indigo", code: "#32127a" },
  { color: "Persian Orange", code: "#d99058" },
  { color: "Persian Pink", code: "#f77fbe" },
  { color: "Persian Plum", code: "#701c1c" },
  { color: "Persian Red", code: "#cc3333" },
  { color: "Persian Rose", code: "#fe28a2" },
  { color: "Persimmon", code: "#ec5800" },
  { color: "Peru", code: "#cd853f" },
  { color: "Peru Tan", code: "#7f3a02" },
  { color: "Pesto", code: "#7c7631" },
  { color: "Petite Orchid", code: "#db9690" },
  { color: "Pewter", code: "#96a8a1" },
  { color: "Pewter Blue", code: "#8ba8b7" },
  { color: "Pharlap", code: "#a3807b" },
  { color: "Phthalo Blue", code: "#000f89" },
  { color: "Phthalo Green", code: "#123524" },
  { color: "Picasso", code: "#fff39d" },
  { color: "Pickled Bean", code: "#6e4826" },
  { color: "Pickled Bluewood", code: "#314459" },
  { color: "Picton Blue", code: "#45b1e8" },
  { color: "Pictorial Carmine", code: "#c30b4e" },
  { color: "Pig Pink", code: "#fdd7e4" },
  { color: "Pigeon Post", code: "#afbdd9" },
  { color: "Piggy Pink", code: "#fddde6" },
  { color: "Pigment Blue", code: "#333399" },
  { color: "Pigment Green", code: "#00a550" },
  { color: "Pigment Red", code: "#ed1c24" },
  { color: "Pine Cone", code: "#6d5e54" },
  { color: "Pine Glade", code: "#c7cd90" },
  { color: "Pine Green", code: "#01796f" },
  { color: "Pine Tree", code: "#171f04" },
  { color: "Pink", code: "#ffc0cb" },
  { color: "Pink Flamingo", code: "#fc74fd" },
  { color: "Pink Flare", code: "#e1c0c8" },
  { color: "Pink Lace", code: "#ffddf4" },
  { color: "Pink Lady", code: "#fff1d8" },
  { color: "Pink Lavender", code: "#d8b2d1" },
  { color: "Pink Orange", code: "#ff9966" },
  { color: "Pink Pearl", code: "#e7accf" },
  { color: "Pink Raspberry", code: "#980036" },
  { color: "Pink Sherbet", code: "#f78fa7" },
  { color: "Pink Swan", code: "#beb5b7" },
  { color: "Piper", code: "#c96323" },
  { color: "Pipi", code: "#fef4cc" },
  { color: "Pippin", code: "#ffe1df" },
  { color: "Pirate Gold", code: "#ba7f03" },
  { color: "Pistachio", code: "#93c572" },
  { color: "Pixie Green", code: "#c0d8b6" },
  { color: "Pixie Powder", code: "#391285" },
  { color: "Pizazz", code: "#ff9000" },
  { color: "Pizza", code: "#c99415" },
  { color: "Plantation", code: "#27504b" },
  { color: "Platinum", code: "#e5e4e2" },
  { color: "Plum", code: "#8e4585" },
  { color: "Plump Purple", code: "#5946b2" },
  { color: "Pohutukawa", code: "#8f021c" },
  { color: "Polar", code: "#e5f9f6" },
  { color: "Polished Pine", code: "#5da493" },
  { color: "Polo Blue", code: "#8da8cc" },
  { color: "Pomegranate", code: "#f34723" },
  { color: "Pompadour", code: "#660045" },
  { color: "Popstar", code: "#be4f62" },
  { color: "Porcelain", code: "#eff2f3" },
  { color: "Porsche", code: "#eaae69" },
  { color: "Port Gore", code: "#251f4f" },
  { color: "Portafino", code: "#ffffb4" },
  { color: "Portage", code: "#8b9fee" },
  { color: "Portica", code: "#f9e663" },
  { color: "Portland Orange", code: "#ff5a36" },
  { color: "Pot Pourri", code: "#f5e7e2" },
  { color: "Potters Clay", code: "#8c5738" },
  { color: "Powder Ash", code: "#bcc9c2" },
  { color: "Powder Blue", code: "#b0e0e6" },
  { color: "Prairie Sand", code: "#9a3820" },
  { color: "Prelude", code: "#d0c0e5" },
  { color: "Prim", code: "#f0e2ec" },
  { color: "Primrose", code: "#edea99" },
  { color: "Princess Perfume", code: "#ff85cf" },
  { color: "Princeton Orange", code: "#f58025" },
  { color: "Process Cyan", code: "#00b7eb" },
  { color: "Process Magenta", code: "#ff0090" },
  { color: "Provincial Pink", code: "#fef5f1" },
  { color: "Prussian Blue", code: "#003153" },
  { color: "Psychedelic Purple", code: "#df00ff" },
  { color: "Puce", code: "#cc8899" },
  { color: "Pueblo", code: "#7d2c14" },
  { color: "Puerto Rico", code: "#3fc1aa" },
  { color: "Pullman Brown", code: "#644117" },
  { color: "Pullman Green", code: "#3b331c" },
  { color: "Pumice", code: "#c2cac4" },
  { color: "Pumpkin", code: "#ff7518" },
  { color: "Pumpkin Skin", code: "#b1610b" },
  { color: "Punch", code: "#dc4333" },
  { color: "Punga", code: "#4d3d14" },
  { color: "Purple", code: "#800080" },
  { color: "Purple Heart", code: "#69359c" },
  { color: "Purple Mountain Majesty", code: "#9678b6" },
  { color: "Purple Navy", code: "#4e5180" },
  { color: "Purple Pizzazz", code: "#fe4eda" },
  { color: "Purple Plum", code: "#9c51b6" },
  { color: "Purple Taupe", code: "#50404d" },
  { color: "Purpureus", code: "#9a4eae" },
  { color: "Putty", code: "#e7cd8c" },
  { color: "Quarter Pearl Lusta", code: "#fffdf4" },
  { color: "Quarter Spanish White", code: "#f7f2e1" },
  { color: "Quartz", code: "#51484f" },
  { color: "Queen Blue", code: "#436b95" },
  { color: "Queen Pink", code: "#e8ccd7" },
  { color: "Quick Silver", code: "#a6a6a6" },
  { color: "Quicksand", code: "#bd978e" },
  { color: "Quill Gray", code: "#d6d6d1" },
  { color: "Quinacridone Magenta", code: "#8e3a59" },
  { color: "Quincy", code: "#623f2d" },
  { color: "RYB Blue", code: "#0247fe" },
  { color: "RYB Green", code: "#66b032" },
  { color: "RYB Orange", code: "#fb9902" },
  { color: "RYB Red", code: "#fe2712" },
  { color: "RYB Violet", code: "#8601af" },
  { color: "RYB Yellow", code: "#fefe33" },
  { color: "Racing Green", code: "#0c1911" },
  { color: "Radical Red", code: "#ff355e" },
  { color: "Raffia", code: "#eadab8" },
  { color: "Rainee", code: "#b9c8ac" },
  { color: "Raisin Black", code: "#242124" },
  { color: "Rajah", code: "#fbab60" },
  { color: "Rangitoto", code: "#2e3222" },
  { color: "Rangoon Green", code: "#1c1e13" },
  { color: "Raspberry", code: "#e30b5d" },
  { color: "Raspberry Pink", code: "#e25098" },
  { color: "Raven", code: "#727b89" },
  { color: "Raw Sienna", code: "#d68a59" },
  { color: "Raw Umber", code: "#826644" },
  { color: "Razzle Dazzle Rose", code: "#ff33cc" },
  { color: "Razzmatazz", code: "#e3256b" },
  { color: "Razzmic Berry", code: "#8d4e85" },
  { color: "Rebecca Purple", code: "#663399" },
  { color: "Rebel", code: "#3c1206" },
  { color: "Red", code: "#ff0000" },
  { color: "Red Beech", code: "#7b3801" },
  { color: "Red Berry", code: "#8e0000" },
  { color: "Red Damask", code: "#da6a41" },
  { color: "Red Devil", code: "#860111" },
  { color: "Red Orange", code: "#ff5349" },
  { color: "Red Oxide", code: "#6e0902" },
  { color: "Red Purple", code: "#e40078" },
  { color: "Red Ribbon", code: "#ed0a3f" },
  { color: "Red Robin", code: "#80341f" },
  { color: "Red Salsa", code: "#fd3a4a" },
  { color: "Red Stage", code: "#d05f04" },
  { color: "Red Violet", code: "#c71585" },
  { color: "Redwood", code: "#a45a52" },
  { color: "Reef", code: "#c9ffa2" },
  { color: "Reef Gold", code: "#9f821c" },
  { color: "Regal Blue", code: "#013f6a" },
  { color: "Regalia", code: "#522d80" },
  { color: "Regent Gray", code: "#86949f" },
  { color: "Regent St Blue", code: "#aad6e6" },
  { color: "Remy", code: "#feebf3" },
  { color: "Reno Sand", code: "#a86515" },
  { color: "Resolution Blue", code: "#002387" },
  { color: "Revolver", code: "#2c1632" },
  { color: "Rhino", code: "#2e3f62" },
  { color: "Rhythm", code: "#777696" },
  { color: "Rice Cake", code: "#fffef0" },
  { color: "Rice Flower", code: "#eeffe2" },
  { color: "Rich Black", code: "#004040" },
  { color: "Rich Brilliant Lavender", code: "#f1a7fe" },
  { color: "Rich Carmine", code: "#d70040" },
  { color: "Rich Electric Blue", code: "#0892d0" },
  { color: "Rich Gold", code: "#a85307" },
  { color: "Rich Lavender", code: "#a76bcf" },
  { color: "Rich Lilac", code: "#b666d2" },
  { color: "Rich Maroon", code: "#b03060" },
  { color: "Rifle Green", code: "#444c38" },
  { color: "Rio Grande", code: "#bbd009" },
  { color: "Ripe Lemon", code: "#f4d81c" },
  { color: "Ripe Plum", code: "#410056" },
  { color: "Riptide", code: "#8be6d8" },
  { color: "River Bed", code: "#434c59" },
  { color: "Roast Coffee", code: "#704241" },
  { color: "Rob Roy", code: "#eac674" },
  { color: "Robin Egg Blue", code: "#00cccc" },
  { color: "Rock", code: "#4d3833" },
  { color: "Rock Blue", code: "#9eb1cd" },
  { color: "Rock Spray", code: "#ba450c" },
  { color: "Rocket Metallic", code: "#8a7f80" },
  { color: "Rodeo Dust", code: "#c9b29b" },
  { color: "Rolling Stone", code: "#747d83" },
  { color: "Roman", code: "#de6360" },
  { color: "Roman Coffee", code: "#795d4c" },
  { color: "Roman Silver", code: "#838996" },
  { color: "Romance", code: "#fffefd" },
  { color: "Romantic", code: "#ffd2b7" },
  { color: "Ronchi", code: "#ecc54e" },
  { color: "Roof Terracotta", code: "#a62f20" },
  { color: "Rope", code: "#8e4d1e" },
  { color: "Rose", code: "#ff007f" },
  { color: "Rose Bonbon", code: "#f9429e" },
  { color: "Rose Bud", code: "#fbb2a3" },
  { color: "Rose Bud Cherry", code: "#800b47" },
  { color: "Rose Dust", code: "#9e5e6f" },
  { color: "Rose Ebony", code: "#674846" },
  { color: "Rose Fog", code: "#e7bcb4" },
  { color: "Rose Gold", code: "#b76e79" },
  { color: "Rose Pink", code: "#ff66cc" },
  { color: "Rose Red", code: "#c21e56" },
  { color: "Rose Taupe", code: "#905d5d" },
  { color: "Rose Vale", code: "#ab4e52" },
  { color: "Rose White", code: "#fff6f5" },
  { color: "Rose of Sharon", code: "#bf5500" },
  { color: "Rosewood", code: "#65000b" },
  { color: "Rosso Corsa", code: "#d40000" },
  { color: "Rosy Brown", code: "#bc8f8f" },
  { color: "Roti", code: "#c6a84b" },
  { color: "Rouge", code: "#a23b6c" },
  { color: "Royal Air Force Blue", code: "#5d8aa8" },
  { color: "Royal Azure", code: "#0038a8" },
  { color: "Royal Blue", code: "#4169e1" },
  { color: "Royal Fuchsia", code: "#ca2c92" },
  { color: "Royal Heath", code: "#ab3472" },
  { color: "Royal Purple", code: "#7851a9" },
  { color: "Ruber", code: "#ce4676" },
  { color: "Rubine Red", code: "#d10056" },
  { color: "Ruby", code: "#e0115f" },
  { color: "Ruby Red", code: "#9b111e" },
  { color: "Ruddy", code: "#ff0028" },
  { color: "Ruddy Brown", code: "#bb6528" },
  { color: "Ruddy Pink", code: "#e18e96" },
  { color: "Rufous", code: "#a81c07" },
  { color: "Rum", code: "#796989" },
  { color: "Rum Swizzle", code: "#f9f8e4" },
  { color: "Russet", code: "#80461b" },
  { color: "Russett", code: "#755a57" },
  { color: "Russian Green", code: "#679267" },
  { color: "Russian Violet", code: "#32174d" },
  { color: "Rust", code: "#b7410e" },
  { color: "Rustic Red", code: "#480404" },
  { color: "Rusty Nail", code: "#86560a" },
  { color: "Rusty Red", code: "#da2c43" },
  { color: "SAE ECE Amber", code: "#ff7e00" },
  { color: "Sacramento State Green", code: "#043927" },
  { color: "Saddle", code: "#4c3024" },
  { color: "Saddle Brown", code: "#8b4513" },
  { color: "Safety Orange", code: "#ff7800" },
  { color: "Safety Yellow", code: "#eed202" },
  { color: "Saffron", code: "#f4c430" },
  { color: "Saffron Mango", code: "#f9bf58" },
  { color: "Sage", code: "#bcb88a" },
  { color: "Sahara", code: "#b7a214" },
  { color: "Sahara Sand", code: "#f1e788" },
  { color: "Sail", code: "#b8e0f9" },
  { color: "Salem", code: "#097f4b" },
  { color: "Salmon", code: "#fa8072" },
  { color: "Salmon Pink", code: "#ff91a4" },
  { color: "Salomie", code: "#fedb8d" },
  { color: "Salt Box", code: "#685e6e" },
  { color: "Saltpan", code: "#f1f7f2" },
  { color: "Sambuca", code: "#3a2010" },
  { color: "San Felix", code: "#0b6207" },
  { color: "San Juan", code: "#304b6a" },
  { color: "San Marino", code: "#456cac" },
  { color: "Sand Dune", code: "#967117" },
  { color: "Sandal", code: "#aa8d6f" },
  { color: "Sandrift", code: "#ab917a" },
  { color: "Sandstone", code: "#796d62" },
  { color: "Sandstorm", code: "#ecd540" },
  { color: "Sandwisp", code: "#f5e7a2" },
  { color: "Sandy Beach", code: "#ffeac8" },
  { color: "Sandy Brown", code: "#f4a460" },
  { color: "Sangria", code: "#92000a" },
  { color: "Sanguine Brown", code: "#8d3d38" },
  { color: "Santa Fe", code: "#b16d52" },
  { color: "Santas Gray", code: "#9fa0b1" },
  { color: "Sap Green", code: "#507d2a" },
  { color: "Sapling", code: "#ded4a4" },
  { color: "Sapphire", code: "#0f52ba" },
  { color: "Sapphire Blue", code: "#0067a5" },
  { color: "Saratoga", code: "#555b10" },
  { color: "Sasquatch Socks", code: "#ff4681" },
  { color: "Satin Linen", code: "#e6e4d4" },
  { color: "Satin Sheen Gold", code: "#cba135" },
  { color: "Sauvignon", code: "#fff5f3" },
  { color: "Sazerac", code: "#fff4e0" },
  { color: "Scampi", code: "#675fa6" },
  { color: "Scandal", code: "#cffaf4" },
  { color: "Scarlet", code: "#ff2400" },
  { color: "Scarlet Gum", code: "#431560" },
  { color: "Scarlett", code: "#950015" },
  { color: "Scarpa Flow", code: "#585562" },
  { color: "Schist", code: "#a9b497" },
  { color: "School Bus Yellow", code: "#ffd800" },
  { color: "Schooner", code: "#8b847e" },
  { color: "Science Blue", code: "#0066cc" },
  { color: "Scooter", code: "#2ebfd4" },
  { color: "Scorpion", code: "#695f62" },
  { color: "Scotch Mist", code: "#fffbdc" },
  { color: "Screamin Green", code: "#66ff66" },
  { color: "Sea Blue", code: "#006994" },
  { color: "Sea Buckthorn", code: "#fba129" },
  { color: "Sea Green", code: "#2e8b57" },
  { color: "Sea Mist", code: "#c5dbca" },
  { color: "Sea Nymph", code: "#78a39c" },
  { color: "Sea Pink", code: "#ed989e" },
  { color: "Sea Serpent", code: "#4bc7cf" },
  { color: "Seagull", code: "#80ccea" },
  { color: "Seal Brown", code: "#59260b" },
  { color: "Seance", code: "#731e8f" },
  { color: "Seashell", code: "#fff5ee" },
  { color: "Seaweed", code: "#1b2f11" },
  { color: "Selago", code: "#f0eefd" },
  { color: "Selective Yellow", code: "#ffba00" },
  { color: "Sepia", code: "#704214" },
  { color: "Sepia Black", code: "#2b0202" },
  { color: "Sepia Skin", code: "#9e5b40" },
  { color: "Serenade", code: "#fff4e8" },
  { color: "Shadow", code: "#8a795d" },
  { color: "Shadow Blue", code: "#778ba5" },
  { color: "Shadow Green", code: "#9ac2b8" },
  { color: "Shady Lady", code: "#aaa5a9" },
  { color: "Shakespeare", code: "#4eabd1" },
  { color: "Shalimar", code: "#fbffba" },
  { color: "Shampoo", code: "#ffcff1" },
  { color: "Shamrock", code: "#33cc99" },
  { color: "Shamrock Green", code: "#009e60" },
  { color: "Shark", code: "#25272c" },
  { color: "Sheen Green", code: "#8fd400" },
  { color: "Sherpa Blue", code: "#004950" },
  { color: "Sherwood Green", code: "#02402c" },
  { color: "Shilo", code: "#e8b9b3" },
  { color: "Shimmering Blush", code: "#d98695" },
  { color: "Shingle Fawn", code: "#6b4e31" },
  { color: "Shiny Shamrock", code: "#5fa778" },
  { color: "Ship Cove", code: "#788bba" },
  { color: "Ship Gray", code: "#3e3a44" },
  { color: "Shiraz", code: "#b20931" },
  { color: "Shocking", code: "#e292c0" },
  { color: "Shocking Pink", code: "#fc0fc0" },
  { color: "Shuttle Gray", code: "#5f6672" },
  { color: "Siam", code: "#646a54" },
  { color: "Sidecar", code: "#f3e7bb" },
  { color: "Sienna", code: "#882d17" },
  { color: "Silk", code: "#bdb1a8" },
  { color: "Silver", code: "#c0c0c0" },
  { color: "Silver Chalice", code: "#acacac" },
  { color: "Silver Lake Blue", code: "#5d89ba" },
  { color: "Silver Pink", code: "#c4aead" },
  { color: "Silver Sand", code: "#bfc1c2" },
  { color: "Silver Tree", code: "#66b58f" },
  { color: "Sinbad", code: "#9fd7d3" },
  { color: "Sinopia", code: "#cb410b" },
  { color: "Siren", code: "#7a013a" },
  { color: "Sirocco", code: "#718080" },
  { color: "Sisal", code: "#d3cbba" },
  { color: "Sizzling Red", code: "#ff3855" },
  { color: "Sizzling Sunrise", code: "#ffdb00" },
  { color: "Skeptic", code: "#cae6da" },
  { color: "Skobeloff", code: "#007474" },
  { color: "Sky Blue", code: "#87ceeb" },
  { color: "Sky Magenta", code: "#cf71af" },
  { color: "Slate Blue", code: "#6a5acd" },
  { color: "Slate Gray", code: "#708090" },
  { color: "Slimy Green", code: "#299617" },
  { color: "Smalt", code: "#003399" },
  { color: "Smalt Blue", code: "#51808f" },
  { color: "Smashed Pumpkin", code: "#ff6d3a" },
  { color: "Smitten", code: "#c84186" },
  { color: "Smoke", code: "#738276" },
  { color: "Smokey Topaz", code: "#832a0d" },
  { color: "Smoky", code: "#605b73" },
  { color: "Smoky Black", code: "#100c08" },
  { color: "Smoky Topaz", code: "#933d41" },
  { color: "Snow", code: "#fffafa" },
  { color: "Snow Drift", code: "#f7faf7" },
  { color: "Snow Flurry", code: "#e4ffd1" },
  { color: "Snowy Mint", code: "#d6ffdb" },
  { color: "Snuff", code: "#e2d8ed" },
  { color: "Soap", code: "#cec8ef" },
  { color: "Soapstone", code: "#fffbf9" },
  { color: "Soft Amber", code: "#d1c6b4" },
  { color: "Soft Peach", code: "#f5edef" },
  { color: "Solid Pink", code: "#893843" },
  { color: "Solitaire", code: "#fef8e2" },
  { color: "Solitude", code: "#eaf6ff" },
  { color: "Sonic Silver", code: "#757575" },
  { color: "Sorbus", code: "#fd7c07" },
  { color: "Sorrell Brown", code: "#ceb98f" },
  { color: "Soya Bean", code: "#6a6051" },
  { color: "Space Cadet", code: "#1d2951" },
  { color: "Spanish Bistre", code: "#807532" },
  { color: "Spanish Blue", code: "#0070b8" },
  { color: "Spanish Carmine", code: "#d10047" },
  { color: "Spanish Crimson", code: "#e51a4c" },
  { color: "Spanish Gray", code: "#989898" },
  { color: "Spanish Green", code: "#009150" },
  { color: "Spanish Orange", code: "#e86100" },
  { color: "Spanish Pink", code: "#f7bfbe" },
  { color: "Spanish Red", code: "#e60026" },
  { color: "Spanish Sky Blue", code: "#00aae4" },
  { color: "Spanish Violet", code: "#4c2882" },
  { color: "Spanish Viridian", code: "#007f5c" },
  { color: "Spartan Crimson", code: "#9e1316" },
  { color: "Spectra", code: "#2f5a57" },
  { color: "Spice", code: "#6a442e" },
  { color: "Spicy Mix", code: "#8b5f4d" },
  { color: "Spicy Mustard", code: "#74640d" },
  { color: "Spicy Pink", code: "#816e71" },
  { color: "Spindle", code: "#b6d1ea" },
  { color: "Spiro Disco Ball", code: "#0fc0fc" },
  { color: "Spray", code: "#79deec" },
  { color: "Spring Bud", code: "#a7fc00" },
  { color: "Spring Frost", code: "#87ff2a" },
  { color: "Spring Green", code: "#00ff7f" },
  { color: "Spring Leaves", code: "#578363" },
  { color: "Spring Rain", code: "#accbb1" },
  { color: "Spring Sun", code: "#f6ffdc" },
  { color: "Spring Wood", code: "#f8f6f1" },
  { color: "Sprout", code: "#c1d7b0" },
  { color: "Spun Pearl", code: "#aaabb7" },
  { color: "Squirrel", code: "#8f8176" },
  { color: "St Patricks Blue", code: "#23297a" },
  { color: "St Tropaz", code: "#2d569b" },
  { color: "Stack", code: "#8a8f8a" },
  { color: "Star Command Blue", code: "#007bb8" },
  { color: "Star Dust", code: "#9f9f9c" },
  { color: "Stark White", code: "#e5d7bd" },
  { color: "Starship", code: "#ecf245" },
  { color: "Steel Blue", code: "#4682b4" },
  { color: "Steel Gray", code: "#262335" },
  { color: "Steel Pink", code: "#cc33cc" },
  { color: "Steel Teal", code: "#5f8a8b" },
  { color: "Stiletto", code: "#9c3336" },
  { color: "Stonewall", code: "#928573" },
  { color: "Storm Dust", code: "#646463" },
  { color: "Storm Gray", code: "#717486" },
  { color: "Stormcloud", code: "#4f666a" },
  { color: "Stratos", code: "#000741" },
  { color: "Straw", code: "#e4d96f" },
  { color: "Strawberry", code: "#fc5a8d" },
  { color: "Strikemaster", code: "#956387" },
  { color: "Stromboli", code: "#325d52" },
  { color: "Studio", code: "#714ab2" },
  { color: "Submarine", code: "#bac7c9" },
  { color: "Sugar Cane", code: "#f9fff6" },
  { color: "Sugar Plum", code: "#914e75" },
  { color: "Sulu", code: "#c1f07c" },
  { color: "Summer Green", code: "#96bbab" },
  { color: "Sun", code: "#fbac13" },
  { color: "Sunburnt Cyclops", code: "#ff404c" },
  { color: "Sundance", code: "#c9b35b" },
  { color: "Sundown", code: "#ffb1b3" },
  { color: "Sunflower", code: "#e4d422" },
  { color: "Sunglo", code: "#e16865" },
  { color: "Sunglow", code: "#ffcc33" },
  { color: "Sunny", code: "#f2f27a" },
  { color: "Sunray", code: "#e3ab57" },
  { color: "Sunset", code: "#fad6a5" },
  { color: "Sunset Orange", code: "#fd5e53" },
  { color: "Sunshade", code: "#ff9e2c" },
  { color: "Super Pink", code: "#cf6ba9" },
  { color: "Supernova", code: "#ffc901" },
  { color: "Surf", code: "#bbd7c1" },
  { color: "Surf Crest", code: "#cfe5d2" },
  { color: "Surfie Green", code: "#0c7a79" },
  { color: "Sushi", code: "#87ab39" },
  { color: "Suva Gray", code: "#888387" },
  { color: "Swamp", code: "#001b1c" },
  { color: "Swamp Green", code: "#acb78e" },
  { color: "Swans Down", code: "#dcf0ea" },
  { color: "Sweet Brown", code: "#a83731" },
  { color: "Sweet Corn", code: "#fbea8c" },
  { color: "Sweet Pink", code: "#fd9fa2" },
  { color: "Swirl", code: "#d3cdc5" },
  { color: "Swiss Coffee", code: "#ddd6d5" },
  { color: "Sycamore", code: "#908d39" },
  { color: "Tabasco", code: "#a02712" },
  { color: "Tacao", code: "#edb381" },
  { color: "Tacha", code: "#d6c562" },
  { color: "Tahiti Gold", code: "#e97c07" },
  { color: "Tahuna Sands", code: "#eef0c8" },
  { color: "Tall Poppy", code: "#b32d29" },
  { color: "Tallow", code: "#a8a589" },
  { color: "Tamarillo", code: "#991613" },
  { color: "Tamarind", code: "#341515" },
  { color: "Tan", code: "#d2b48c" },
  { color: "Tan Hide", code: "#fa9d5a" },
  { color: "Tana", code: "#d9dcc1" },
  { color: "Tangaroa", code: "#03163c" },
  { color: "Tangelo", code: "#f94d00" },
  { color: "Tangerine", code: "#f28500" },
  { color: "Tangerine Yellow", code: "#ffcc00" },
  { color: "Tango", code: "#ed7a1c" },
  { color: "Tango Pink", code: "#e4717a" },
  { color: "Tapa", code: "#7b7874" },
  { color: "Tapestry", code: "#b05e81" },
  { color: "Tara", code: "#e1f6e8" },
  { color: "Tarawera", code: "#073a50" },
  { color: "Tart Orange", code: "#fb4d46" },
  { color: "Tasman", code: "#cfdccf" },
  { color: "Taupe", code: "#483c32" },
  { color: "Taupe Gray", code: "#8b8589" },
  { color: "Tawny Port", code: "#692545" },
  { color: "Te Papa Green", code: "#1e433c" },
  { color: "Tea", code: "#c1bab0" },
  { color: "Tea Green", code: "#d0f0c0" },
  { color: "Tea Rose", code: "#f4c2c2" },
  { color: "Teak", code: "#b19461" },
  { color: "Teal", code: "#008080" },
  { color: "Teal Blue", code: "#367588" },
  { color: "Teal Deer", code: "#99e6b3" },
  { color: "Teal Green", code: "#00827f" },
  { color: "Telemagenta", code: "#cf3476" },
  { color: "Temptress", code: "#3b000b" },
  { color: "Tenne", code: "#cd5700" },
  { color: "Tequila", code: "#ffe6c7" },
  { color: "Terra Cotta", code: "#e2725b" },
  { color: "Texas", code: "#f8f99c" },
  { color: "Texas Rose", code: "#ffb555" },
  { color: "Thatch", code: "#b69d98" },
  { color: "Thatch Green", code: "#403d19" },
  { color: "Thistle", code: "#d8bfd8" },
  { color: "Thistle Green", code: "#cccaa8" },
  { color: "Thulian Pink", code: "#de6fa1" },
  { color: "Thunder", code: "#33292f" },
  { color: "Thunderbird", code: "#c02b18" },
  { color: "Tia Maria", code: "#c1440e" },
  { color: "Tiara", code: "#c3d1d1" },
  { color: "Tiber", code: "#063537" },
  { color: "Tickle Me Pink", code: "#fc89ac" },
  { color: "Tidal", code: "#f1ffad" },
  { color: "Tide", code: "#bfb8b0" },
  { color: "Tiffany Blue", code: "#0abab5" },
  { color: "Tigers Eye", code: "#e08d3c" },
  { color: "Timber Green", code: "#16322c" },
  { color: "Timberwolf", code: "#dbd7d2" },
  { color: "Titan White", code: "#f0eeff" },
  { color: "Titanium Yellow", code: "#eee600" },
  { color: "Toast", code: "#9a6e61" },
  { color: "Tobacco Brown", code: "#715d47" },
  { color: "Toledo", code: "#3a0020" },
  { color: "Tolopea", code: "#1b0245" },
  { color: "Tom Thumb", code: "#3f583b" },
  { color: "Tomato", code: "#ff6347" },
  { color: "Tonys Pink", code: "#e79f8c" },
  { color: "Toolbox", code: "#746cc0" },
  { color: "Topaz", code: "#ffc87c" },
  { color: "Torea Bay", code: "#0f2d9e" },
  { color: "Tory Blue", code: "#1450aa" },
  { color: "Tosca", code: "#8d3f3f" },
  { color: "Totem Pole", code: "#991b07" },
  { color: "Tower Gray", code: "#a9bdbf" },
  { color: "Tractor Red", code: "#fd0e35" },
  { color: "Tradewind", code: "#5fb3ac" },
  { color: "Tranquil", code: "#e6ffff" },
  { color: "Travertine", code: "#fffde8" },
  { color: "Tree Poppy", code: "#fc9c1d" },
  { color: "Treehouse", code: "#3b2820" },
  { color: "Trendy Green", code: "#7c881a" },
  { color: "Trendy Pink", code: "#8c6495" },
  { color: "Trinidad", code: "#e64e03" },
  { color: "Tropical Blue", code: "#c3ddf9" },
  { color: "Tropical Rain Forest", code: "#00755e" },
  { color: "Tropical Violet", code: "#cda4de" },
  { color: "Trout", code: "#4a4e5a" },
  { color: "True Blue", code: "#0073cf" },
  { color: "True V", code: "#8a73d6" },
  { color: "Tuatara", code: "#363534" },
  { color: "Tuft Bush", code: "#ffddcd" },
  { color: "Tufts Blue", code: "#417dc1" },
  { color: "Tulip", code: "#ff878d" },
  { color: "Tulip Tree", code: "#eab33b" },
  { color: "Tumbleweed", code: "#deaa88" },
  { color: "Tuna", code: "#353542" },
  { color: "Tundora", code: "#4a4244" },
  { color: "Turbo", code: "#fae600" },
  { color: "Turkish Rose", code: "#b57281" },
  { color: "Turmeric", code: "#cabb48" },
  { color: "Turquoise", code: "#40e0d0" },
  { color: "Turquoise Blue", code: "#00ffef" },
  { color: "Turquoise Green", code: "#a0d6b4" },
  { color: "Turtle Green", code: "#2a380b" },
  { color: "Tuscan Red", code: "#7c4848" },
  { color: "Tuscan Tan", code: "#a67b5b" },
  { color: "Tuscany", code: "#c09999" },
  { color: "Tusk", code: "#eef3c3" },
  { color: "Tussock", code: "#c5994b" },
  { color: "Tutu", code: "#fff1f9" },
  { color: "Twilight", code: "#e4cfde" },
  { color: "Twilight Blue", code: "#eefdff" },
  { color: "Twilight Lavender", code: "#8a496b" },
  { color: "Twine", code: "#c2955d" },
  { color: "Tyrian Purple", code: "#66023c" },
  { color: "UA Blue", code: "#0033aa" },
  { color: "UA Red", code: "#d9004c" },
  { color: "UCLA Blue", code: "#536895" },
  { color: "UCLA Gold", code: "#ffb300" },
  { color: "UFO Green", code: "#3cd070" },
  { color: "UP Forest Green", code: "#014421" },
  { color: "UP Maroon", code: "#7b1113" },
  { color: "USAFA Blue", code: "#004f98" },
  { color: "Ube", code: "#8878c3" },
  { color: "Ultra Pink", code: "#ff6fff" },
  { color: "Ultramarine", code: "#3f00ff" },
  { color: "Ultramarine Blue", code: "#4166f5" },
  { color: "Umber", code: "#635147" },
  { color: "Unbleached Silk", code: "#ffddca" },
  { color: "Underage Pink", code: "#f9e6f4" },
  { color: "United Nations Blue", code: "#5b92e5" },
  { color: "University Of California Gold", code: "#b78727" },
  { color: "University Of Tennessee Orange", code: "#f77f00" },
  { color: "Unmellow Yellow", code: "#ffff66" },
  { color: "Upsdell Red", code: "#ae2029" },
  { color: "Urobilin", code: "#e1ad21" },
  { color: "Utah Crimson", code: "#d3003f" },
  { color: "Valencia", code: "#d84437" },
  { color: "Valentino", code: "#350e42" },
  { color: "Valhalla", code: "#2b194f" },
  { color: "Van Cleef", code: "#49170c" },
  { color: "Van Dyke Brown", code: "#664228" },
  { color: "Vanilla", code: "#f3e5ab" },
  { color: "Vanilla Ice", code: "#f38fa9" },
  { color: "Varden", code: "#fff6df" },
  { color: "Vegas Gold", code: "#c5b358" },
  { color: "Venetian Red", code: "#c80815" },
  { color: "Venice Blue", code: "#055989" },
  { color: "Venus", code: "#928590" },
  { color: "Verdigris", code: "#43b3ae" },
  { color: "Verdun Green", code: "#495400" },
  { color: "Vermilion", code: "#d9381e" },
  { color: "Veronica", code: "#a020f0" },
  { color: "Very Light Azure", code: "#74bbfb" },
  { color: "Very Light Blue", code: "#6666ff" },
  { color: "Very Light Malachite Green", code: "#64e986" },
  { color: "Very Light Tangelo", code: "#ffb077" },
  { color: "Very Pale Orange", code: "#ffdfbf" },
  { color: "Very Pale Yellow", code: "#ffffbf" },
  { color: "Vesuvius", code: "#b14a0b" },
  { color: "Victoria", code: "#534491" },
  { color: "Vida Loca", code: "#549019" },
  { color: "Viking", code: "#64ccdb" },
  { color: "Vin Rouge", code: "#983d61" },
  { color: "Viola", code: "#cb8fa9" },
  { color: "Violent Violet", code: "#290c5e" },
  { color: "Violet", code: "#7f00ff" },
  { color: "Violet Blue", code: "#324ab2" },
  { color: "Violet Eggplant", code: "#991199" },
  { color: "Violet Red", code: "#f75394" },
  { color: "Viridian", code: "#40826d" },
  { color: "Viridian Green", code: "#009698" },
  { color: "Vis Vis", code: "#ffefa1" },
  { color: "Vista Blue", code: "#7c9ed9" },
  { color: "Vista White", code: "#fcf8f7" },
  { color: "Vivid Amber", code: "#cc9900" },
  { color: "Vivid Auburn", code: "#922724" },
  { color: "Vivid Burgundy", code: "#9f1d35" },
  { color: "Vivid Cerise", code: "#da1d81" },
  { color: "Vivid Cerulean", code: "#00aaee" },
  { color: "Vivid Crimson", code: "#cc0033" },
  { color: "Vivid Gamboge", code: "#ff9900" },
  { color: "Vivid Lime Green", code: "#a6d608" },
  { color: "Vivid Malachite", code: "#00cc33" },
  { color: "Vivid Mulberry", code: "#b80ce3" },
  { color: "Vivid Orange", code: "#ff5f00" },
  { color: "Vivid Orange Peel", code: "#ffa000" },
  { color: "Vivid Orchid", code: "#cc00ff" },
  { color: "Vivid Raspberry", code: "#ff006c" },
  { color: "Vivid Red", code: "#f70d1a" },
  { color: "Vivid Red Tangelo", code: "#df6124" },
  { color: "Vivid Sky Blue", code: "#00ccff" },
  { color: "Vivid Tangelo", code: "#f07427" },
  { color: "Vivid Tangerine", code: "#ffa089" },
  { color: "Vivid Vermilion", code: "#e56024" },
  { color: "Vivid Violet", code: "#9f00ff" },
  { color: "Vivid Yellow", code: "#ffe302" },
  { color: "Volt", code: "#ceff00" },
  { color: "Voodoo", code: "#533455" },
  { color: "Vulcan", code: "#10121d" },
  { color: "Wafer", code: "#decbc6" },
  { color: "Waikawa Gray", code: "#5a6e9c" },
  { color: "Waiouru", code: "#363c0d" },
  { color: "Walnut", code: "#773f1a" },
  { color: "Warm Black", code: "#004242" },
  { color: "Wasabi", code: "#788a25" },
  { color: "Water Leaf", code: "#a1e9de" },
  { color: "Watercourse", code: "#056f57" },
  { color: "Waterloo", code: "#7b7c94" },
  { color: "Waterspout", code: "#a4f4f9" },
  { color: "Wattle", code: "#dcd747" },
  { color: "Watusi", code: "#ffddcf" },
  { color: "Wax Flower", code: "#ffc0a8" },
  { color: "We Peep", code: "#f7dbe6" },
  { color: "Web Chartreuse", code: "#7fff00" },
  { color: "Web Orange", code: "#ffa500" },
  { color: "Wedgewood", code: "#4e7f9e" },
  { color: "Weldon Blue", code: "#7c98ab" },
  { color: "Well Read", code: "#b43332" },
  { color: "Wenge", code: "#645452" },
  { color: "West Coast", code: "#625119" },
  { color: "West Side", code: "#ff910f" },
  { color: "Westar", code: "#dcd9d2" },
  { color: "Wewak", code: "#f19bab" },
  { color: "Wheat", code: "#f5deb3" },
  { color: "Wheatfield", code: "#f3edcf" },
  { color: "Whiskey", code: "#d59a6f" },
  { color: "Whisper", code: "#f7f5fa" },
  { color: "White", code: "#ffffff" },
  { color: "White Ice", code: "#ddf9f1" },
  { color: "White Lilac", code: "#f8f7fc" },
  { color: "White Linen", code: "#f8f0e8" },
  { color: "White Pointer", code: "#fef8ff" },
  { color: "White Rock", code: "#eae8d4" },
  { color: "White Smoke", code: "#f5f5f5" },
  { color: "Wild Blue Yonder", code: "#a2add0" },
  { color: "Wild Orchid", code: "#d470a2" },
  { color: "Wild Rice", code: "#ece090" },
  { color: "Wild Sand", code: "#f4f4f4" },
  { color: "Wild Strawberry", code: "#ff43a4" },
  { color: "Wild Watermelon", code: "#fc6c85" },
  { color: "Wild Willow", code: "#b9c46a" },
  { color: "William", code: "#3a686c" },
  { color: "Willow Brook", code: "#dfecda" },
  { color: "Willow Grove", code: "#65745d" },
  { color: "Willpower Orange", code: "#fd5800" },
  { color: "Windsor", code: "#3c0878" },
  { color: "Windsor Tan", code: "#a75502" },
  { color: "Wine", code: "#722f37" },
  { color: "Wine Berry", code: "#591d35" },
  { color: "Wine Dregs", code: "#673147" },
  { color: "Winter Hazel", code: "#d5d195" },
  { color: "Winter Sky", code: "#ff007c" },
  { color: "Winter Wizard", code: "#a0e6ff" },
  { color: "Wintergreen Dream", code: "#56887d" },
  { color: "Wisp Pink", code: "#fef4f8" },
  { color: "Wisteria", code: "#c9a0dc" },
  { color: "Wistful", code: "#a4a6d3" },
  { color: "Witch Haze", code: "#fffc99" },
  { color: "Wood Bark", code: "#261105" },
  { color: "Woodland", code: "#4d5328" },
  { color: "Woodrush", code: "#302a0f" },
  { color: "Woodsmoke", code: "#0c0d0f" },
  { color: "Woody Brown", code: "#483131" },
  { color: "X11 Dark Green", code: "#006400" },
  { color: "X11 Gray", code: "#bebebe" },
  { color: "Xanadu", code: "#738678" },
  { color: "Yale Blue", code: "#0f4d92" },
  { color: "Yankees Blue", code: "#1c2841" },
  { color: "Yellow", code: "#ffff00" },
  { color: "Yellow Green", code: "#9acd32" },
  { color: "Yellow Metal", code: "#716338" },
  { color: "Yellow Orange", code: "#ffae42" },
  { color: "Yellow Rose", code: "#fff000" },
  { color: "Yellow Sea", code: "#fea904" },
  { color: "Your Pink", code: "#ffc3c0" },
  { color: "Yukon Gold", code: "#7b6608" },
  { color: "Yuma", code: "#cec291" },
  { color: "Zaffre", code: "#0014a8" },
  { color: "Zambezi", code: "#685558" },
  { color: "Zanah", code: "#daecd6" },
  { color: "Zest", code: "#e5841b" },
  { color: "Zeus", code: "#292319" },
  { color: "Ziggurat", code: "#bfdbe2" },
  { color: "Zinnwaldite", code: "#ebc2af" },
  { color: "Zinnwaldite Brown", code: "#2c1608" },
  { color: "Zircon", code: "#f4f8ff" },
  { color: "Zombie", code: "#e4d69b" },
  { color: "Zomp", code: "#39a78e" },
  { color: "Zorba", code: "#a59b91" },
  { color: "Zuccini", code: "#044022" },
  { color: "Zumthor", code: "#edf6ff" },
];

export const useGeoLocation = () => {
  const [locationData, setLocationData] = useState(null);

  useEffect(() => {
    getLocation();
  }, []);

  async function getLocation() {
    try {
      const res = await axios.get("https://api.country.is/");
      if (res.status === 200) setLocationData(res.data);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  }

  return {
    country: locationData?.country,
  };
};
