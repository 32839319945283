import styles from "../../assets/styles/Settings/Settings.module.scss";
import { MimaButton, MimaText, Modal } from "../../components";
import { FaRegEnvelope } from "react-icons/fa";
import Avatar from "../../assets/img/Mdp.png";
import { IoMdCall } from "react-icons/io";
import React from "react";
import useAccountManagerLogic from "./useLogic/useAccountManagerLogic";

const AccountManager = ({ closeModal }) => {
  const { accountManager } = useAccountManagerLogic();
  const { firstname, lastname, mobile, whatsappNumber, email } =
    accountManager || {};
  const accountManagerName = `${firstname || ""} ${lastname || ""}`;

  const callIcon = (
    <IoMdCall
      style={{
        color: "var(--color-primary)",
      }}
    />
  );

  const msgIcon = <FaRegEnvelope />;
  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      <MimaText variant="subtitle" mb={4}>
        Account Manager
      </MimaText>
      <div className={styles.am__img_contain}>
        <img src={Avatar} className={styles.am__img} />
      </div>
      <MimaText variant="bodyBold" mt={1} mb={2}>
        {accountManagerName || ""}
      </MimaText>

      <MimaText variant="subtitle" mb={3} mt={3} align="center">
        To speak with <br /> your Account Manager
      </MimaText>
      <div className={styles.cs__dials}>
        {callIcon} <MimaText>Dial</MimaText>
        <MimaText variant="bodyBold">{mobile || ""}</MimaText>
      </div>
      <MimaText variant="subtitle" mb={3} mt={3} align="center">
        Chat on WhatsApp with <br /> your Account Manager
      </MimaText>
      <div className={styles.cs__dials}>
        <MimaText variant="bodyBold">{whatsappNumber || ""}</MimaText>
      </div>
    </Modal>
  );
};

export default AccountManager;
