import { businessStore } from "../../../stores";
import React, { useEffect } from "react";
import { amountFormatter, useGeoLocation } from "../../../utils/utils";
import { useMemo, useState } from "react";
import * as yup from "yup";
import { MimaToastUtil } from "../../../components";
import { BusinessController } from "../../../controllers";
import constant from "../../../utils/constant";

const useRenewSubLogic = ({ closeModal }) => {
  const { subscriptionPlans, business } = businessStore;
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(business.plan);

  const { country } = useGeoLocation();

  const planQuery = useMemo(() => {
    return `countryCode=${country}&app=FASH`;
  }, [country]);

  const getSubscriptions = async () => {
    setLoading(true);
    await Promise.all([BusinessController.getAllSubscriptions(planQuery)]);
    setLoading(false);
  };

  useEffect(() => {
    getSubscriptions();
  }, [planQuery]);

  const calculatePrice = (months, amount, discount) => {
    return (amount * months * (100 - discount)) / 100;
  };

  const monthData = useMemo(() => {
    return [
      {
        key: "1",
        value: `1 Month - ${amountFormatter("USD").format(plan?.amount)}`,
      },
      {
        key: "3",
        value: `3 Months - ${amountFormatter("USD").format(calculatePrice(3, plan?.amount, plan?.threeMonthsDiscountPer))}`,
      },
      {
        key: "6",
        value: `6 Months - ${amountFormatter("USD").format(calculatePrice(6, plan?.amount, plan?.sixMonthsDiscountPer))}`,
      },
      {
        key: "12",
        value: `12 Months - ${amountFormatter("USD").format(calculatePrice(12, plan?.amount, plan?.oneYearDiscountPer))}`,
      },
    ];
  }, [plan]);

  const plansData = useMemo(() => {
    return subscriptionPlans
      .filter((x) => x.name !== "Lite" && x.name !== "Free")
      .map((x) => ({
        key: x._id,
        value: x.name,
        amount: x.amount,
        threeMonthsDiscountPer: x.threeMonthsDiscountPer,
        sixMonthsDiscountPer: x.sixMonthsDiscountPer,
        oneYearDiscountPer: x.oneYearDiscountPer,
      }));
  }, [subscriptionPlans]);

  const validationSchema = () =>
    yup.object({
      months: yup.string().required("Number of months is required"),
      plan: yup.string().required("Plan is required"),
    });

  const onSubmit = async (values) => {
    setLoading(true);

    const payload = {
      newPlan: values.plan,
      months: values.months,
    };

    const { status, errorMessage, data } =
      await BusinessController.renewSubscription(payload);

    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    } else {
      setLoading(false);
      if (data?.url) {
        MimaToastUtil.info({
          message: "redirecting to Paystack",
        });
        return window.open(data?.url, "_self");
      }
      return MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  return {
    closeModal,
    monthData,
    plansData,
    onSubmit,
    validationSchema,
    loading,
    plan,
    setPlan,
  };
};

export default useRenewSubLogic;
