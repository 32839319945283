import {
  MimaButton,
  MimaDropdown,
  MimaText,
  Modal,
  PageLoader,
} from "../../components";
import styles from "../../assets/styles/Settings/Settings.module.scss";
import React from "react";
import constant from "../../utils/constant";
import { amountFormatter } from "../../utils/utils";
import { BsCheckLg } from "react-icons/bs";
import { TiCancel } from "react-icons/ti";
import moment from "moment";
import { Formik } from "formik";
import useSubscriptionsLogic from "./useLogic/useSubscriptionsLogic";

const Coverage = ({ subscriptionSummary }) => {
  return (
    <div className={styles.subscription__planDetails__deselected}>
      {subscriptionSummary?.coverage?.map((coverage, i) => (
        <div className={styles.subscription__planDetails__item} key={i}>
          <BsCheckLg style={{ color: "var(--color-green)" }} />
          <MimaText mb={1}>{coverage}</MimaText>
        </div>
      ))}
      {subscriptionSummary?.noCoverage?.map((noCoverage, i) => (
        <div className={styles.subscription__planDetails__item} key={i}>
          <TiCancel style={{ color: "var(--color-error)", fontSize: "2rem" }} />
          <MimaText mb={1}>{noCoverage}</MimaText>
        </div>
      ))}
    </div>
  );
};

const Subscriptions = ({ closeModal }) => {
  const {
    loading,
    goBack,
    plansPricing,
    selectedTab,
    setSelectedTab,
    selectedIndex,
    setSelectedIndex,
    onContinueClick,
    showSummary,
    onSelectPlan,
    subscriptionSummary,
    subscribeAction,
    currentSubscription,
    business,
    changePlan,
    setChangePlan,
    upgradePlans,
    setSubscriptionSummary,
    getCost,
    planChangeCost,
    upgradeDowngradeHandler,
    generalSettings,
    cancelPlan,
    setCancelPlan,
    subCancelHandler,
    monthData,
    validationSchema,
    setPlanName,
  } = useSubscriptionsLogic({ closeModal });
  const { basicPricing, premiumPricing, enterprisePricing } = plansPricing;

  const plansOrder = ["Basic", "Premium", "Enterprise"];

  const filteredAndOrderedUpgradePlans = Array.from(
    new Map(upgradePlans.map((plan) => [plan.planName, plan])).values()
  ).sort(
    (a, b) => plansOrder.indexOf(a.planName) - plansOrder.indexOf(b.planName)
  );

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <MimaText variant="subtitle" mb={1}>
        Subscriptions
      </MimaText>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {currentSubscription.trialStartDate ? (
            <>
              {showSummary ? (
                <div className={styles.subscription}>
                  <MimaText variant="bodyBold" align="center" mb={3}>
                    Subscription Summary
                  </MimaText>
                  <MimaText
                    variant="subtitleBold"
                    mb={1}
                    color="var(--color-primary)"
                    labelTitle="Amount"
                  >
                    {amountFormatter("USD").format(subscriptionSummary.price)}
                    <span
                      style={{
                        color: "var(--color-dark)",
                        fontSize: "var(--default-font-small)",
                      }}
                    >
                      /{subscriptionSummary.period}
                    </span>
                  </MimaText>
                  <MimaText mb={2}>{subscriptionSummary.description}</MimaText>
                  <div className={styles.subscription__summary}>
                    <div className={styles.subscription__summary__item}>
                      <MimaText labelTitle="Duration">
                        {subscriptionSummary.duration} Month(s)
                      </MimaText>
                    </div>
                    <div className={styles.subscription__summary__item}>
                      <MimaText labelTitle="Plan">
                        {subscriptionSummary.plan}
                      </MimaText>
                    </div>
                    <div className={styles.subscription__summary__item}>
                      <MimaText labelTitle="Billing Period">
                        {subscriptionSummary.period}
                      </MimaText>
                    </div>
                  </div>
                  <MimaButton
                    title={`Pay ${amountFormatter("USD").format(subscriptionSummary.price)} ${
                      subscriptionSummary?.period
                    }`}
                    onClick={() => {
                      subscribeAction();
                    }}
                    variant="form"
                    mt={2}
                    mb={2}
                  />
                  <MimaText variant="bodyBold" align="center" mb={2}>
                    {subscriptionSummary.plan} Plan Details
                  </MimaText>
                  <Coverage subscriptionSummary={subscriptionSummary} />
                </div>
              ) : (
                <div className={styles.subscription}>
                  <MimaText variant="bodyBold" align="center">
                    Select a plan
                  </MimaText>

                  <div className={styles.subscription__planSelect}>
                    <div
                      className={
                        selectedTab === constant.SUBSCRIPTION_TYPE.BASIC
                          ? `${styles.subscription__planSelect__active} ${styles.subscription__planSelect__active__1}`
                          : `${styles.subscription__planSelect__inactive} ${styles.subscription__planSelect__inactive__1}`
                      }
                      onClick={() => {
                        setSelectedTab(constant.SUBSCRIPTION_TYPE.BASIC);
                      }}
                    >
                      {constant.SUBSCRIPTION_TYPE.BASIC}
                    </div>
                    <div
                      className={
                        selectedTab === constant.SUBSCRIPTION_TYPE.PREMIUM
                          ? styles.subscription__planSelect__active
                          : styles.subscription__planSelect__inactive
                      }
                      onClick={() => {
                        setSelectedTab(constant.SUBSCRIPTION_TYPE.PREMIUM);
                      }}
                    >
                      {constant.SUBSCRIPTION_TYPE.PREMIUM}
                    </div>
                    <div
                      className={
                        selectedTab === constant.SUBSCRIPTION_TYPE.ENTERPRISE
                          ? `${styles.subscription__planSelect__active} ${styles.subscription__planSelect__active__2}`
                          : `${styles.subscription__planSelect__inactive} ${styles.subscription__planSelect__inactive__2}`
                      }
                      onClick={() => {
                        setSelectedTab(constant.SUBSCRIPTION_TYPE.ENTERPRISE);
                      }}
                    >
                      {constant.SUBSCRIPTION_TYPE.ENTERPRISE}
                    </div>
                  </div>
                  <div className={styles.subscription__planDetails}>
                    {selectedTab === constant.SUBSCRIPTION_TYPE.BASIC && (
                      <>
                        {basicPricing.map((plan, i) => (
                          <div
                            className={
                              selectedIndex === plan.index
                                ? styles.subscription__planDetails__selected
                                : styles.subscription__planDetails__deselected
                            }
                            onClick={() => {
                              onSelectPlan(plan);
                            }}
                            key={i}
                          >
                      
                            <div className="modal__beside">
                              <MimaText variant="subtitleBold" mb={1}>
                                {amountFormatter("USD").format(plan.price)}
                              </MimaText>
                              {plan.period !== "Monthly" ? (
                                <div className={styles.subscription__save}>
                                  Save {plan.discount}%
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            
                            <MimaText variant="bodyBold">
                              {plan.period}
                            </MimaText>
                            <MimaText>{plan.description}</MimaText>
                          </div>
                        ))}
                        <MimaButton
                          title="Continue"
                          variant="form"
                          mb={2}
                          onClick={onContinueClick}
                        />
                        <MimaText variant="bodyBold" align="center" mb={2}>
                          {selectedTab} Plan Details
                        </MimaText>
                        <Coverage subscriptionSummary={basicPricing[0]} />
                      </>
                    )}

                    {selectedTab === constant.SUBSCRIPTION_TYPE.PREMIUM && (
                      <>
                        {premiumPricing.map((plan, i) => (
                          <div
                            className={
                              selectedIndex === plan.index
                                ? styles.subscription__planDetails__selected
                                : styles.subscription__planDetails__deselected
                            }
                            onClick={() => {
                              onSelectPlan(plan);
                            }}
                            key={i}
                          >
                            
                            <div className="modal__beside">
                              <MimaText variant="subtitleBold" mb={1}>
                                {amountFormatter("USD").format(plan.price)}
                              </MimaText>
                              {plan.period !== "Monthly" ? (
                                <div className={styles.subscription__save}>
                                  Save {plan.discount}%
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            
                            <MimaText variant="bodyBold">
                              {plan.period}
                            </MimaText>
                            <MimaText>{plan.description}</MimaText>
                          </div>
                        ))}
                        <MimaButton
                          title="Continue"
                          variant="form"
                          mb={2}
                          onClick={onContinueClick}
                        />
                        <MimaText variant="bodyBold" align="center" mb={2}>
                          {selectedTab} Plan Details
                        </MimaText>
                        <Coverage subscriptionSummary={premiumPricing[0]} />
                      </>
                    )}

                    {selectedTab === constant.SUBSCRIPTION_TYPE.ENTERPRISE && (
                      <>
                        {enterprisePricing.map((plan, i) => (
                          <div
                            className={
                              selectedIndex === plan.index
                                ? styles.subscription__planDetails__selected
                                : styles.subscription__planDetails__deselected
                            }
                            onClick={() => {
                              onSelectPlan(plan);
                            }}
                            key={i}
                          >
                            
                            <div className="modal__beside">
                              <MimaText variant="subtitleBold" mb={1}>
                                {amountFormatter("USD").format(plan.price)}
                              </MimaText>
                              {plan.period !== "Monthly" ? (
                                <div className={styles.subscription__save}>
                                  Save {plan.discount}%
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            
                            <MimaText variant="bodyBold">
                              {plan.period}
                            </MimaText>
                            <MimaText>{plan.description}</MimaText>
                          </div>
                        ))}
                        <MimaButton
                          title="Continue"
                          variant="form"
                          mb={2}
                          onClick={onContinueClick}
                        />
                        <MimaText variant="bodyBold" align="center" mb={2}>
                          {selectedTab} Plan Details
                        </MimaText>
                        <Coverage subscriptionSummary={enterprisePricing[0]} />
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {cancelPlan ? (
                <div className={styles.subscription}>
                  <MimaText
                    variant="bodyBold"
                    align="center"
                    color="var(--color-error)"
                    mb={2}
                  >
                    {`Are you sure you want to ${generalSettings.cancelAutoRenew ? "deactivate" : "activate"} automatic renewal on the ${business.plan.name} plan?`}
                  </MimaText>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <MimaButton
                      title="Yes, Confirm"
                      onClick={subCancelHandler}
                      variant="form"
                      buttonColor="var(--color-fash-primary-3)"
                      width={20}
                    />

                    <MimaButton
                      title="No, Go back"
                      buttonColor="var(--color-dark)"
                      variant="form"
                      onClick={() => {
                        setCancelPlan(false);
                      }}
                      width={20}
                    />
                  </div>
                </div>
              ) : changePlan ? (
                <div className={styles.subscription}>
                  <MimaText variant="bodyBold" align="center" mb={2}>
                    Please choose the plan you would like to upgrade/downgrade
                    to, from your current {business.plan.name} subscription.
                  </MimaText>
                  <div className={styles.subscription__upgrade}>
                    {filteredAndOrderedUpgradePlans.map((upgrade) => (
                      <div
                        className={
                          subscriptionSummary.planId === upgrade.planId
                            ? styles.subscription__upgrade__item__active
                            : ""
                        }
                        key={upgrade.planId}
                        onClick={() => {
                          setSubscriptionSummary(upgrade);
                          getCost(upgrade);
                          setPlanName(upgrade.planName);
                        }}
                      >
                        <div className={styles.subscription__upgrade__item}>
                          {upgrade.planName}
                        </div>
                      </div>
                    ))}
                  </div>
                  {planChangeCost?.msg && (
                    <MimaText
                      variant="bodyBold"
                      align="center"
                      color="var(--color-error)"
                      mt={2}
                    >
                      {planChangeCost.msg}
                    </MimaText>
                  )}

                  <Formik
                    initialValues={{
                      months: "",
                    }}
                    validationSchema={
                      business.plan.name === "Free" ? validationSchema : null
                    }
                    onSubmit={upgradeDowngradeHandler}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                    }) => (
                      <div style={{ width: "100%" }}>
                        {business.plan.name === "Free" && (
                          <MimaDropdown
                            labelTitle="Select Months"
                            placeholder="Select months"
                            id="months"
                            name="months"
                            value={values.months}
                            onChange={(data) => {
                              setFieldValue("months", data.key);
                            }}
                            onBlur={handleBlur}
                            error={errors.months}
                            touched={touched.months}
                            data={monthData}
                            width={42}
                            mt={2}
                            align="center"
                          />
                        )}

                        <MimaText align="center" mt={2}>
                          Clicking Continue will carry out upgrade or Downgrade
                          action
                        </MimaText>

                        <MimaButton
                          title="Continue"
                          mt={2}
                          onClick={handleSubmit}
                          variant="form"
                        />
                      </div>
                    )}
                  </Formik>

                  {planChangeCost?.msg && (
                    <>
                      <MimaText variant="bodyBold" align="center" mt={2} mb={2}>
                        {subscriptionSummary.planName} Plan Details
                      </MimaText>
                      <Coverage subscriptionSummary={subscriptionSummary} />
                    </>
                  )}
                </div>
              ) : (
                <div className={styles.subscription}>
                  {/* <img src={AdBanner} alt="Testimonial" className={styles.subscription__img} /> */}
                  <MimaText variant="bodyBold">
                    Hi {business.tradingName},
                  </MimaText>
                  {business.plan.name !== "Free" ? (
                    <MimaText variant="bodyBold">
                      You are currently subscribed to our {business.plan.name}{" "}
                      plan which expires on{" "}
                      <span style={{ color: "var(--color-error)" }}>
                        {moment(business.subscription.expiryDate).format(
                          "dddd DD/MM/YY"
                        )}
                      </span>
                    </MimaText>
                  ) : (
                    <MimaText variant="bodyBold">
                      You are currently subscribed to our {business.plan.name}{" "}
                      plan with limited services.
                    </MimaText>
                  )}

                  <MimaButton
                    title="Change Plan"
                    mt={2}
                    onClick={() => {
                      setChangePlan(true);
                    }}
                    variant="form"
                  />

                  <MimaButton
                    title={`${
                      generalSettings.cancelAutoRenew
                        ? "Deactivate"
                        : "Activate"
                    } Automatic Renewal`}
                    onClick={() => {
                      setCancelPlan(true);
                    }}
                    mt={2}
                    variant="form"
                    buttonColor="var(--color-dark)"
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default Subscriptions;
