/* eslint-disable no-unused-vars */
import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  ReceiptCheckBox as RCB,
  Modal,
  MimaDateInput,
} from "../../components";

import styles from "../../assets/styles/Invoice/Invoice.module.scss";
import { amountFormatter } from "../../utils/utils";
import constant from "../../utils/constant";
import { Formik } from "formik";
import React from "react";
import useUpdateQuotationLogic from "./useLogic/useUpdateQuotationLogic";

const UpdateQuotation = ({ quote, closeModal }) => {
  const { loading, onSubmit, validationSchema } = useUpdateQuotationLogic({
    quote,
    closeModal,
  });
  const statuses = [
    {
      key: "VOID",
      value: "VOID",
    },
    {
      key: "ORDERED",
      value: "ORDERED",
    },
  ];

  return (
    <div>
      <MimaText
        variant="subtitle"
        color="var(--color-fash-primary-3)"
        align="center"
      >
        Update Quotation Status
      </MimaText>
      <MimaText variant="small" align="center" mb={4}>
        Manually update the status of your Quotation
      </MimaText>
      <Formik
        initialValues={{
          status: "",
          totalAmount: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div>
            <div className={styles.summary__to}>
              <MimaText>
                {" "}
                {`Quotation To ${quote?.customer?.[0].fullname}`}{" "}
              </MimaText>

              <div className={styles.summary__line}></div>
            </div>
            <div className={styles.summary__total}>
              <MimaText> Quotation Total Amount </MimaText>
              <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
                {amountFormatter(quote.currencyCode).format(
                  quote.transactionAmount
                )}
              </MimaText>
            </div>
            <MimaDropdown
              labelTitle="Quotation Status"
              placeholder="Select Status"
              name="status"
              currentValue={values.status}
              id="status"
              onChange={(data) => {
                setFieldValue("status", data.key);
              }}
              data={statuses}
              width={38}
            />
            {errors.status ? (
              <div className={styles.error}>
                {""}
                {touched.status && errors.status}
                {""}
              </div>
            ) : null}

            <MimaButton
              title="Update"
              onClick={handleSubmit}
              type="submit"
              loading={loading}
              variant="form"
              mt={2}
            />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default UpdateQuotation;
