import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class InventoryStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'InventoryStore',
			properties: [
				'products',
				'stocks',
				'stockAnalysis',
				'selectedProduct',
				'selectedStock',
				'splitPayments',
				'rates',
				'rateCards',
				'eurPrice',
				'gbpPrice',
				'selectedRateCard',
				'productConfig',
			],
		});
	}

	products = [];
	stocks = [];
	stockAnalysis = {};
	selectedProduct = {};
	selectedStock = {};
	selectedRateCard = {};
	splitPayments = [];
	rateCards = {};
	rates = [];
	eurPrice = 0;
	gbpPrice = 0;
	productConfig = {};

	reset = () => {
		this.products = [];
		this.stocks = [];
		this.rateCards = {};
		this.stockAnalysis = {};
		this.selectedProduct = {};
		this.selectedStock = {};
		this.splitPayments = [];
		this.rates = [];
		this.selectedRateCard = {};
		this.productConfig = {};
	};

	setSelectedRateCard = async state => {
		runInAction(async () => {
			this.selectedRateCard = state;
		});
	};

	setSplitPayments = async state => {
		runInAction(async () => {
			this.splitPayments = state;
		});
	};

	setProducts = async state => {
		runInAction(async () => {
			this.products = state;
		});
	};
	setStocks = async state => {
		runInAction(async () => {
			this.stocks = state;
		});
	};
	setRateCards = async state => {
		runInAction(async () => {
			this.rateCards = state;
		});
	};
	setStockAnalysis = async state => {
		runInAction(async () => {
			this.stockAnalysis = state;
		});
	};
	setSelectedProduct = async state => {
		runInAction(async () => {
			this.selectedProduct = state;
		});
	};
	setSelectedStock = async state => {
		runInAction(async () => {
			this.selectedStock = state;
		});
	};

	setRates = async state => {
		runInAction(async () => {
			this.rates = state;
		});
	}

	setEurPrice = async state => {
		runInAction(async () => {
			this.eurPrice = state;
		});
	}

	setGbpPrice = async state => {
		runInAction(async () => {
			this.gbpPrice = state;
		});
	}
	setProductConfig = async state => {
		runInAction(async () => {
			this.productConfig = state;
		});
	}
}
