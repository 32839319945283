import { MimaButton, AppShell } from "../../components";

import styles from "../../assets/styles/WithdrawalAccounts/WithdrawalAccounts.module.scss";
import { AnimatePresence } from "framer-motion";
import React from "react";

import useWithdrawalAccountLogic from "./useLogic/useWithdrawalAccountLogic";
import { WithdrawalAccount } from "../Settings";

const WithdrawalAccounts = () => {
  const {
    withdrawalAccounts,
    withdrawalAccountModal,
    setWithdrawalAccountModal,
    closeModal,
  } = useWithdrawalAccountLogic();

  return (
    <AppShell pageTitle="Withdrawal Accounts">
      <MimaButton
        title="Add Withdrwal Account"
        onClick={() => {
          setWithdrawalAccountModal(true);
        }}
        variant="form"
        width={30}
        mb={1}
      />
      <div className={styles.container}>
        {withdrawalAccounts.map((item) => (
          <div key={item._id} className={styles.container_card}>
            {item.foreign.data.length > 0 ? (
              <>
                <div className={styles.container_item}>
                  <span className={styles.container_item__title}>
                    Account Type:
                  </span>
                  <span className={styles.container_item__text}>
                    {item.accountType}
                  </span>
                </div>
                <div className={styles.container_item}>
                  <span className={styles.container_item__title}>
                    Payment Type:
                  </span>
                  <span className={styles.container_item__text}>
                    {item.foreign.paymentType}
                  </span>
                </div>
                {item.foreign.data.map((foreign) => (
                  <div className={styles.container_item}>
                    <span className={styles.container_item__title}>
                      {`${foreign.label}:`}
                    </span>
                    <span className={styles.container_item__text}>
                      {foreign.value}
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className={styles.container_item}>
                  <span className={styles.container_item__title}>
                    Account Type:
                  </span>
                  <span className={styles.container_item__text}>
                    {item.accountType}
                  </span>
                </div>
                <div className={styles.container_item}>
                  <span className={styles.container_item__title}>
                    Payment Type:
                  </span>
                  <span className={styles.container_item__text}>
                    Bank Transfer
                  </span>
                </div>
                <div className={styles.container_item}>
                  <span className={styles.container_item__title}>
                    Account Name:
                  </span>
                  <span className={styles.container_item__text}>
                    {item.accountName}
                  </span>
                </div>
                <div className={styles.container_item}>
                  <span className={styles.container_item__title}>
                    Account Number:
                  </span>
                  <span className={styles.container_item__text}>
                    {item.accountNumber}
                  </span>
                </div>
                <div className={styles.container_item}>
                  <span className={styles.container_item__title}>Bank:</span>
                  <span className={styles.container_item__text}>
                    {item.bank}
                  </span>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      {withdrawalAccountModal && (
        <div className="modal">
          <AnimatePresence>
            {withdrawalAccountModal && (
              <WithdrawalAccount closeModal={closeModal} />
            )}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default WithdrawalAccounts;
