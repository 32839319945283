import styles from '../../assets/styles/Wallet/WalletQuickLinksCard.module.scss';
import { MimaButton, MimaText } from '../../components';
import { GiTakeMyMoney } from 'react-icons/gi';
import { FaPeopleArrows } from 'react-icons/fa';
import { IoIosSend } from 'react-icons/io';
import { RiDownloadCloudLine } from 'react-icons/ri';
import React from 'react';

const WalletQuickLinksCard = ({ withdraw }) => {
	return (
		<div className={styles.wql}>
			<div className={styles.wql__title}>
				<MimaText variant="bodyBold">Quick Links</MimaText>
			</div>
			<div className={styles.wql__links}>
				<div className={styles.wql__links_item} onClick={withdraw}>
					<GiTakeMyMoney />
					<MimaText variant="smallBold" align="center">Withdraw</MimaText>
				</div>
			</div>
		</div>
	);
};

export default WalletQuickLinksCard;
