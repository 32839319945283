import { apiRequest } from "../utils/useAPIRequest";
import constant from "../utils/constant";
import { businessStore, userStore, websiteStore } from "../stores";
import { encodeQueryData } from "../utils/utils";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { queryClient } from "../App";

export const GET_STYLES = "get-styles";
export const GET_NEWS_LETTER = "get-news-letter";
export const GET_STYLE_REQUESTS = "get-style-requests";
export const GET_CONTACT_US = "get-contact-us";

const useGetStyles = (measurementTableQuery, filterQuery, searchQuery) => {
  const id = userStore.bId;

  const query = useMemo(() => {
    if (searchQuery) {
      return `${measurementTableQuery}&${filterQuery}&search=${searchQuery}`;
    } else if (filterQuery) {
      return `${measurementTableQuery}&${filterQuery}`;
    } else {
      return measurementTableQuery;
    }
  }, [filterQuery, measurementTableQuery, searchQuery]);

  return useQuery({
    queryKey: [id, GET_STYLES, query],
    queryFn: () => {
      return apiRequest({
        url: `/fashion/styles?${query}`,
        method: "get",
      });
    },
    keepPreviousData: true,
  });
};
const useGetNewsLetter = (newsLetterTableQuery, filterQuery, searchQuery) => {
  const id = userStore.bId;

  const query = useMemo(() => {
    if (searchQuery) {
      return `${newsLetterTableQuery}&${filterQuery}&search=${searchQuery}`;
    } else if (filterQuery) {
      return `${newsLetterTableQuery}&${filterQuery}`;
    } else {
      return newsLetterTableQuery;
    }
  }, [filterQuery, newsLetterTableQuery, searchQuery]);

  return useQuery({
    queryKey: [id, GET_NEWS_LETTER, query],
    queryFn: () => {
      return apiRequest({
        url: `/fashion/newsletter/contacts?${query}`,
        method: "get",
      });
    },
    keepPreviousData: true,
  });
};
const useGetContactUs = (contactUsTableQuery, filterQuery, searchQuery) => {
  const id = userStore.bId;

  const query = useMemo(() => {
    if (searchQuery) {
      return `${contactUsTableQuery}&${filterQuery}&search=${searchQuery}`;
    } else if (filterQuery) {
      return `${contactUsTableQuery}&${filterQuery}`;
    } else {
      return contactUsTableQuery;
    }
  }, [filterQuery, contactUsTableQuery, searchQuery]);

  return useQuery({
    queryKey: [id, GET_CONTACT_US, query],
    queryFn: () => {
      return apiRequest({
        url: `/fashion/contacts-us-details?${query}`,
        method: "get",
      });
    },
    keepPreviousData: true,
  });
};
const useGetStyleRequests = (
  styleRequestsTableQuery,
  filterQuery,
  searchQuery
) => {
  const id = userStore.bId;

  const query = useMemo(() => {
    if (searchQuery) {
      return `${styleRequestsTableQuery}&${filterQuery}&search=${searchQuery}`;
    } else if (filterQuery) {
      return `${styleRequestsTableQuery}&${filterQuery}`;
    } else {
      return styleRequestsTableQuery;
    }
  }, [filterQuery, styleRequestsTableQuery, searchQuery]);

  return useQuery({
    queryKey: [id, GET_STYLE_REQUESTS, query],
    queryFn: () => {
      return apiRequest({
        url: `/fashion/style/request?${query}`,
        method: "get",
      });
    },
    keepPreviousData: true,
  });
};

const uploadDocument = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/businesses/documents/upload",
    method: "post",
    payload,
    hasImageUpload: true,
  });
  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { errorMessage, status, data };
};

const createWebsite = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/businesses/create-storefront-url",
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    queryClient.invalidateQueries({ queryKey: [GET_STYLES] });

    return { status };
  }
  return { status, errorMessage };
};

export const useGetValidateDomain = (subDomain) => {
  const encodeQuery = encodeQueryData({
    subDomain: subDomain ? subDomain?.trim()?.toLowerCase() : "",
  });
  const response = useQuery({
    queryKey: [businessStore.bId, subDomain],
    queryFn: () => {
      return apiRequest({
        url: `/businesses/validate-storefront-url?${encodeQuery}`,
        method: "get",
      });
    },
    enabled: !!subDomain,
  });
  const isValid = useMemo(() => {
    if (response.data?.status === constant.Success) {
      return Boolean(response.data.data);
      // return response.data.data;
    }
    return false;
  }, [response.data]);

  return { ...response, isValid };
};

const uploadStyle = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/fashion/style",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    const id = businessStore.bId;
    queryClient.invalidateQueries({ queryKey: [id, GET_STYLES] });

    return { status };
  }
  return { status, errorMessage };
};

const getCollections = async () => {
  let query;

  query = `bId=${businessStore.bId}&subDomain=${businessStore.subdomain}&currencyCode=${businessStore.localCurrency}`;
  const { status, errorMessage, data } = await apiRequest({
    url: `/fashion/collections?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    websiteStore.setCollections(data);

    return { status };
  }

  return { status, errorMessage };
};

const editStyle = async (payload, id) => {
  const { status, errorMessage } = await apiRequest({
    url: `/fashion/style?styleId=${id}`,
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    const id = businessStore.bId;
    queryClient.invalidateQueries({ queryKey: [id, GET_STYLES] });

    return { status };
  }
  return { status, errorMessage };
};

const deleteStyle = async (styleId) => {
  const { status, errorMessage } = await apiRequest({
    url: `/fashion/deactivate/${styleId}`,
    method: "patch",
  });
  if (status === constant.Success) {
    const id = businessStore.bId;
    queryClient.invalidateQueries({ queryKey: [id, GET_STYLES] });

    return { status };
  }
  return { status, errorMessage };
};

const configureWebsite = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/settings/update-setting",
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    websiteStore.setWebConfig(data);
    businessStore.setSettings(data);

    const id = businessStore.bId;
    queryClient.invalidateQueries({ queryKey: [id, GET_STYLES] });

    return { status };
  }
  return { status, errorMessage };
};

const WebsiteController = {
  createWebsite,
  useGetValidateDomain,
  useGetStyles,
  uploadStyle,
  uploadDocument,
  getCollections,
  editStyle,
  deleteStyle,
  useGetStyleRequests,
  configureWebsite,
  useGetNewsLetter,
  useGetContactUs,
};

export default WebsiteController;
