import React, { memo } from "react";
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";
import styles from "../../assets/styles/MimaInput.module.scss";
import MimaText from "../MimaText";

const propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  inputType: PropTypes.oneOf(["tel", "password"]),
  boxCount: PropTypes.number,
  shouldAutoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
};

const containerStyle = {
  gap: "2rem",
};

const inputStyle = {
  width: "4.8rem",
  height: "4.8rem",
  flexShrink: 0,
  borderRadius: "1rem",
  border: `1px solid var(--color-dark)`,
  fontSize: "1.8rem",
  fontFamily: "inherit",
  margin: ".4rem",
};

const MimaOtpInput = ({
  title,
  inputType = "tel",
  boxCount = 5,
  errorMessage,
  style,
  className,
  ...rest
}) => {
  const handlePaste = (event) => {
    const data = event.clipboardData.getData("text");
    rest.onChange(data);
  };

  return (
    <div className={`${styles.otpInput} ${className}`} style={style}>
      {title ? <p className={styles.otpInput__title}>{title}</p> : null}
      <OtpInput
        {...rest}
        numInputs={boxCount}
        onPaste={handlePaste}
        inputType={inputType}
        inputStyle={inputStyle}
        containerStyle={containerStyle}
        renderInput={(props) => <input {...props} />}
      />
      {errorMessage && (
        <MimaText
          align="center"
          variant="small"
          color="var(--color-error)"
          mt={0.5}
        >
          {errorMessage}
        </MimaText>
      )}
    </div>
  );
};

MimaOtpInput.propTypes = propTypes;

export default memo(MimaOtpInput);
