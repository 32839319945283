import {
  MimaButton,
  MimaInput,
  MimaText,
  Modal,
  MimaDropdown,
  ReceiptCheckBox as RCB,
  MimaDateInput,
  MimaTextArea,
  MimaPaginatedDropdown,
} from "../../components";
import styles from "../../assets/styles/Transactions/Transactions.module.scss";
import TransactionAddedSuccess from "./TransactionAddedSuccess";
import { amountFormatter, currencies } from "../../utils/utils";
import { Formik, Field, FieldArray } from "formik";
import React from "react";
import { salesChannelData } from "../../utils/utils";
import { businessStore } from "../../stores";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};

const FieldTextArea = ({ field, ...props }) => {
  return <MimaTextArea {...field} {...props} />;
};

const FieldPaginatedDropdown = ({ field, ...props }) => {
  return <MimaPaginatedDropdown {...field} {...props} />;
};

const AddIncome = ({
  closeModal,
  openIncomeModal,
  loading,
  incomeSuccess,
  addAnotherIncome,
  setShowSurvey,
  needFeedback,
  onSubmitAddIncome,
  validationSchemaAddIncome,
}) => {
  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      <MimaText
        variant="subtitle"
        color="var(--color-fash-primary-2)"
        align="center"
        mb={3}
      >
        Add Income
      </MimaText>
      {openIncomeModal && (
        <Formik
          initialValues={{
            customer: "",
            customerName: "",
            orders: [
              {
                quantity: 0,
                unitPrice: 0,
                item: "",
                stock: "",
              },
            ],
            selectFromStock: false,
            paidDate: "",
            currencyCode: "NGN",
            discountAmount: 0,
            deposit: 0,
            shipping: 0,
            paymentMethod: "",
            salesChannel: "",
          }}
          validationSchema={validationSchemaAddIncome}
          onSubmit={onSubmitAddIncome}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <div>
              <MimaPaginatedDropdown
                mb={2}
                labelTitle="Bill To"
                placeholder="Select Customer"
                name="customer"
                value={values.customer}
                id="customer"
                optionLabel="fullname"
                touched={touched.customer}
                error={errors.customer}
                url="/customers/business"
                queryKey={[businessStore.bId, "customers"]}
                getPageValue={(page) => page?.data?.customers || []}
                getPageTotalCount={(lastPage) =>
                  lastPage?.data?.totalCounts || 0
                }
                onChange={(data) => {
                  setFieldValue("customer", data._id);
                  setFieldValue("customerName", data.fullname);
                }}
              />

              <MimaDropdown
                labelTitle="Select Currency"
                name="currencyCode"
                value={values.currencyCode}
                placeholder={values.currencyCode}
                id="currencyCode"
                currentValue={values.currencyCode}
                onChange={(data) => {
                  setFieldValue("currencyCode", data.key);
                }}
                data={currencies}
                touched={touched.currencyCode}
                error={errors.currencyCode}
              />
              <MimaDropdown
                labelTitle="Select a sales channel"
                placeholder="Select a sales channel"
                name="salesChannel"
                value={values.salesChannel}
                id="salesChannel"
                onChange={(data) => {
                  setFieldValue("salesChannel", data.key);
                }}
                data={salesChannelData}
                touched={touched.salesChannel}
                error={errors.salesChannel}
              />
              <RCB
                title="Select Product from Stock"
                onChange={(e) => {
                  setFieldValue("selectFromStock", e.target.checked);
                  setFieldValue("orders", [
                    {
                      quantity: 1,
                      unitPrice: 0,
                      item: "",
                      stock: "",
                    },
                  ]);
                }}
                checked={values.selectFromStock}
                value={values.selectFromStock}
                name="selectFromStock"
              />
              <FieldArray name="orders">
                {({ push, remove }) => (
                  <React.Fragment>
                    {values.orders.map((_, i) => (
                      <div key={i}>
                        {!values.selectFromStock && (
                          <Field
                            name={`orders.${i}.item`}
                            id={`orders.${i}.item`}
                            component={FieldTextArea}
                            type="text"
                            placeholder="Product/Service"
                            labelTitle="Product/Service"
                            mt={2}
                            touched={touched?.orders?.[i]?.item}
                            error={errors?.orders?.[i]?.item}
                          />
                        )}

                        {values.selectFromStock ? (
                          <Field
                            type="text"
                            mt={2}
                            name={`orders.${i}.item`}
                            id={`orders.${i}.item`}
                            value={values.orders[i].item}
                            queryKey={[businessStore.bId, "stock"]}
                            optionLabel="name"
                            component={FieldPaginatedDropdown}
                            labelTitle="Product/Service"
                            placeholder={
                              values.orders[i].item || "Select from Stock"
                            }
                            touched={touched?.orders?.[i]?.item}
                            error={errors?.orders?.[i]?.item}
                            url="/products/stocks/business"
                            getPageValue={(page) => page?.data?.value || []}
                            getPageTotalCount={(lastPage) =>
                              lastPage?.data?.totalCounts || 0
                            }
                            onChange={(data) => {
                              setFieldValue(`orders.${i}.stock`, data._id);
                              setFieldValue(`orders.${i}.item`, data.name);
                              setFieldValue(
                                `orders.${i}.unitPrice`,
                                data.sellingPrice
                              );
                            }}
                          />
                        ) : null}

                        <div className={styles.beside}>
                          <Field
                            name={`orders.${i}.unitPrice`}
                            component={FieldInput}
                            type="number"
                            min="0"
                            labelTitle="Price"
                            width={18}
                            // disabled={values.selectFromStock}
                            touched={touched?.orders?.[i]?.unitPrice}
                            error={errors?.orders?.[i]?.unitPrice}
                          />
                          <Field
                            name={`orders.${i}.quantity`}
                            component={FieldInput}
                            type="number"
                            min={0.1}
                            labelTitle="Quantity"
                            width={18}
                            touched={touched?.orders?.[i]?.quantity}
                            error={errors?.orders?.[i]?.quantity}
                          />
                        </div>
                        {i !== 0 ? (
                          <MimaButton
                            title="Remove"
                            variant="filter"
                            type="button"
                            buttonColor="var(--color-error)"
                            width={10}
                            onClick={() => remove(i)}
                          />
                        ) : (
                          ""
                        )}
                        <div className={styles.summary__line}></div>
                      </div>
                    ))}
                    <MimaButton
                      title="Add Another Item"
                      variant="text"
                      type="button"
                      onClick={() =>
                        push({
                          quantity: 0,
                          unitPrice: 0,
                          item: "",
                        })
                      }
                      width={38}
                    />
                  </React.Fragment>
                )}
              </FieldArray>
              <div className={styles.beside}>
                <div>
                  <MimaInput
                    type="number"
                    labelTitle="Shipping Fee"
                    placeholder="0.00"
                    min={0}
                    name="shipping"
                    width={18}
                    value={values.shipping}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div>
                  <MimaInput
                    type="number"
                    labelTitle="Discount"
                    placeholder="0.00"
                    min={0}
                    name="discountAmount"
                    width={18}
                    value={values.discountAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.discountAmount}
                    touched={touched.discountAmount}
                  />
                </div>
              </div>
              <div className={styles.beside}>
                <div>
                  <MimaInput
                    type="number"
                    labelTitle="Amount Received"
                    placeholder="0.00"
                    min={0}
                    name="deposit"
                    width={18}
                    value={values.deposit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.deposit}
                    touched={touched.deposit}
                  />
                </div>

                <MimaButton
                  title={`Balance:
								${amountFormatter(values.currencyCode).format(
                  values.orders.reduce(
                    (previousValue, currentValue) =>
                      previousValue +
                      currentValue.quantity * currentValue.unitPrice,
                    0
                  ) -
                    values.deposit -
                    values.discountAmount +
                    values.shipping
                )}`}
                  variant="text"
                  type="button"
                />
              </div>

              <MimaDateInput
                labelTitle="Paid Date"
                name="paidDate"
                value={values.paidDate}
                onChange={(text) => {
                  setFieldValue("paidDate", text);
                }}
                onBlur={handleBlur}
                error={errors.paidDate}
                touched={touched.paidDate}
              />

              <MimaText variant="small" mt={2} ml={2}>
                Payment type
              </MimaText>
              <div className={styles.checks}>
                <RCB
                  title="POS"
                  onChange={() => {
                    setFieldValue("paymentMethod", "POS");
                  }}
                  checked={values.paymentMethod === "POS"}
                  name="paymentMethod"
                />
                <RCB
                  title="Transfer"
                  checked={values.paymentMethod === "TRANSFER"}
                  onChange={() => setFieldValue("paymentMethod", "TRANSFER")}
                  name="paymentMethod"
                />
                <RCB
                  title="Cash"
                  checked={values.paymentMethod === "CASH"}
                  onChange={() => setFieldValue("paymentMethod", "CASH")}
                  name="paymentMethod"
                />
              </div>
              {errors.paymentMethod ? (
                <div className={styles.error}>
                  {""}
                  {touched.paymentMethod && errors.paymentMethod}
                  {""}
                </div>
              ) : null}

              <MimaButton
                title="Save"
                type="submit"
                variant="regular"
                loading={loading}
                onClick={handleSubmit}
                width={38}
                mt={2}
              />
            </div>
          )}
        </Formik>
      )}

      {incomeSuccess && (
        <TransactionAddedSuccess
          addAnotherIncome={addAnotherIncome}
          closeModal={closeModal}
          setShowSurvey={setShowSurvey}
          needFeedback={needFeedback}
        />
      )}
    </Modal>
  );
};

export default AddIncome;
