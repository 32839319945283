import {
  Customer,
  Dashboard,
  TransactionsNew,
  Orders,
  Booking,
  Wallet,
  Invoice,
  PaymentLink,
  AppHome,
  Measurement,
  CreateWebsite,
  Styles,
  StyleRequest,
  WebsiteConfig,
  Settings,
  ApiKeys,
  SubscriptionExpired,
  NewsLetter,
  ContactUs,
  ThemeConfig,
  WithdrawalAccounts,
} from "../pages";
import PayBills from "../pages/PayBills/PayBills";

import constant from "../utils/constant";

const protectedRoutes = [
  // {
  //   path: constant.Routes.Dashboard,
  //   module: "TRANSACTION",
  //   type: "GENERAL",
  //   element: <Dashboard />,
  // },
  {
    path: constant.Routes.Dashboard,
    module: "TRANSACTION",
    type: "GENERAL",
    element: <AppHome />,
  },
  {
    path: constant.Routes.Customer,
    module: "CUSTOMER",
    type: "GENERAL",
    element: <Customer />,
  },
  {
    path: constant.Routes.Transactions,
    module: "TRANSACTION",
    type: "GENERAL",
    element: <TransactionsNew />,
  },
  {
    path: constant.Routes.Orders,
    // module: 'ORDERS',
    element: <Orders />,
  },
  {
    path: constant.Routes.Booking,
    element: <Booking />,
  },
  {
    path: constant.Routes.Wallet,
    module: "WALLET",
    type: "GENERAL",
    element: <Wallet />,
  },
  {
    path: constant.Routes.Invoice,
    module: "INVOICE/RECEIPT",
    element: <Invoice />,
  },
  {
    path: constant.Routes.PaymentLink,
    element: <PaymentLink />,
  },
  {
    path: constant.Routes.Measurement,
    element: <Measurement />,
  },
  {
    path: constant.Routes.CreateWebsite,
    element: <CreateWebsite />,
  },
  {
    path: constant.Routes.Styles,
    element: <Styles />,
  },
  {
    path: constant.Routes.StyleRequest,
    element: <StyleRequest />,
  },
  {
    path: constant.Routes.Settings,
    element: <Settings />,
  },
  {
    path: constant.Routes.WebsiteConfig,
    element: <WebsiteConfig />,
  },
  {
    path: constant.Routes.ApiKeys,
    element: <ApiKeys />,
  },
  {
    path: constant.Routes.SubExpired,
    element: <SubscriptionExpired />,
  },
  {
    path: constant.Routes.PayBills,
    module: "BILL PAYMENT",
    type: "GENERAL",
    element: <PayBills />,
  },
  {
    path: constant.Routes.NewsLetter,
    element: <NewsLetter />,
  },
  {
    path: constant.Routes.ContactUs,
    element: <ContactUs />,
  },
  {
    path: constant.Routes.ThemeConfig,
    element: <ThemeConfig />,
  },
  {
    path: constant.Routes.WithdrawalAccounts,
    element: <WithdrawalAccounts />,
  },
];

export default protectedRoutes;
