import React, { useState } from "react";
import { MeasurementController } from "../../../controllers";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
import { handleImageUpload, removeEmptyKeys } from "../../../utils/utils";
import { userStore } from "../../../stores";
import * as yup from "yup";

const useTakeMeasurementLogic = ({ closeModal, reFetch }) => {
  const [loading, setLoading] = useState(false);

  const bId = userStore.userId;

  const validationSchema = yup.object().shape({
    customer: yup.string().when("measurementFor", {
      is: "Customer",
      then: yup.string().required("Customer is required"),
      otherwise: yup.string(),
    }),
    style: yup.string().when("measurementFor", {
      is: "Design",
      then: yup.string().required("Style is required"),
      otherwise: yup.string(),
    }),
    unitOfMeasurement: yup.string().required("Select Unit of Measurement"),
    measurementImage: yup
      .array()
      .of(yup.string())
      .test("measurementImage", "Delete attached measurement image", function (value) {
        const { measurementPic } = this.parent;
        return !(value.length > 0 && measurementPic === false);
      }),
    measurementPic: yup.boolean(),
    customerImage: yup
      .array()
      .of(yup.string())
      .test("customerImage", "Delete attached customer image", function (value) {
        const { customerPic } = this.parent;
        return !(value.length > 0 && customerPic === false);
      }),
    customerPic: yup.boolean(),
  });

  const onSubmit = async (values) => {
    setLoading(true);

    let measurementImageUrls = [];
    if (values.measurementImage.length > 0) {
      measurementImageUrls = await handleImageUpload(values.measurementImage);
    }

    let customerImageUrls = [];
    if (values.customerImage.length > 0) {
      customerImageUrls = await handleImageUpload(values.customerImage);
    }

    let payload = {
      ...values,
      imageUrls: [],
    };

    if (measurementImageUrls.length > 0) {
      payload.imageUrls.push({
        type: "Measurement NoteBook",
        value: measurementImageUrls[0],
      });
    }

    if (customerImageUrls.length > 0) {
      payload.imageUrls.push({
        type: "Customer Picture",
        value: customerImageUrls[0],
      });
    }

    delete payload.customerImage;
    delete payload.measurementImage;
    delete payload.measurementPic;
    delete payload.customerPic;
    delete payload.value;
    delete payload.customerName;
    delete payload.styleName;

    removeEmptyKeys(payload);

    const { status, errorMessage } =
      await MeasurementController.takeMeasurement(payload);

    if (status === constant.Success) {
      reFetch();
      MimaToastUtil.success({
        message: constant.Success,
      });
      setLoading(false);
      return closeModal();
    } else {
      setLoading(false);
      MimaToastUtil.error({ message: errorMessage });
    }
  };

  return {
    loading,
    bId,
    onSubmit,
    validationSchema,
  };
};

export default useTakeMeasurementLogic;
