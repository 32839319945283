import styles from "../../assets/styles/PayBills/PayBills.module.scss";
import { MimaText, PageLoader } from "../../components";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Formik } from "formik";
import useEnterPinLogic from "./useLogic/useEnterPinLogic";

const EnterPin = ({ closeModal }) => {
  const [otp, setOtp] = useState("");

  const { loading, onSubmit } = useEnterPinLogic({ closeModal });

  return (
    <>
      <Formik
        initialValues={{
          otp: "",
        }}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleBlur, values, errors, touched }) => (
          <form onSubmit={onSubmit}>
            {loading ? (
              <PageLoader title="In progess ..." loading={loading} />
            ) : (
              <div>
                <MimaText mb={3} align="center" color="var(--color-primary)">
                  Please Enter your Pin
                </MimaText>
                <div className={styles.pin__item}>
                  <MimaText variant="small" mb={1} ml={3}>
                    Enter Pin
                  </MimaText>
                  <OtpInput
                    value={otp}
                    onChange={(otp) => {
                      setOtp(otp);
                      if (otp >= 1000) {
                        onSubmit(otp);
                      }
                    }}
                    numInputs={4}
                    isInputSecure={true}
                    inputStyle={{
                      fontSize: "2.4rem",
                      fontFamily: "inherit",
                      width: "6rem",
                      height: "6rem",
                      margin: ".4rem",
                      borderTop: "0px",
                      border: "1px solid #4A4A68",
                      borderRadius: "1rem",
                      display: "block",
                      outline: "none",
                      textAlign: "center",
                      marginRight: "2rem",
                    }}
                    isInputNum
                  />
                </div>
              </div>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default EnterPin;
