import xlsx from "json-as-xlsx"

const downloadCSV = (text, filename = `mima-${downloadFormatDate(new Date())}.csv`) => {
    const dataType = 'text/csv;charset=utf-8';
    
    const pom = document.createElement('a');

    pom.setAttribute('href', `data:${dataType},${encodeURIComponent(text)}`);
    pom.setAttribute('download', filename);
  
    if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    } else {
        pom.click();
    }
};

const downloadXlsx = (tableID, filename = `mima-${downloadFormatDate(new Date())}.xls`) => {
    const dataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';

    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    
    const pom = document.createElement('a');

    pom.setAttribute('href', `data:${dataType},${tableHTML}`);
    pom.setAttribute('download', filename);
  
    if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    } else {
        pom.click();
    }
};

const downloadXlsx2 = (
    jsonArray = [], 
    filename = `mima-${downloadFormatDate(new Date())}`
) => {
    const headers = jsonArray.length ? Object.keys(jsonArray[0]) : [];

    let settings = {
        fileName: filename,
        extraLength: 3,
        writeMode: 'writeFile', 
        writeOptions: {},
        RTL: false,
    }
	
	let data = [
		{
			sheet: "Sheet1",
			columns: headers.map(x => ({ label: x, value: x })),			
			content: jsonArray,
		},
	];
    
    xlsx(data, settings)
}

const downloadFormatDate = date => date.toISOString().slice(0, 10);

export { downloadCSV, downloadXlsx, downloadXlsx2, downloadFormatDate };