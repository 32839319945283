import { Formik } from "formik";
import {
  MimaText,
  Modal,
  MimaButton,
  PageLoader,
  MimaDateInput,
  MimaDropdown,
} from "../../components";
import React from "react";
import useAccountStatementLogic from "./useLogics/useAccountStatementLogic";

const AccountStatement = ({ closeModal, goBack }) => {
  const { onSubmit, validationSchema, loading } = useAccountStatementLogic({
    closeModal,
  });
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <MimaText variant="subtitle">Account Statement Request</MimaText>
      {loading ? (
        <PageLoader loading={loading} />
      ) : (
        <>
          <MimaText variant="small" mb={3} width={38} align="center">
            Select a currency and choose the date period for which you want your
            account statement
          </MimaText>

          <Formik
            initialValues={{
              currencyCode: "",
              startDate: "",
              endDate: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div>
                <MimaDropdown
                  labelTitle="Select Currency"
                  name="currencyCode"
                  value={values.currencyCode}
                  placeholder="Select Currency"
                  id="currencyCode"
                  currentValue={values.currencyCode}
                  onChange={(data) => {
                    setFieldValue("currencyCode", data.key);
                  }}
                  data={["NGN", "USD", "GBP", "EUR"].map((x) => {
                    return {
                      value: x,
                      key: x,
                    };
                  })}
                  touched={touched.currencyCode}
                  error={errors.currencyCode}
                />
                <div className="modal__beside">
                  <MimaDateInput
                    labelTitle="Start date"
                    name="startDate"
                    value={values.startDate}
                    onChange={(text) => {
                      setFieldValue("startDate", text);
                    }}
                    onBlur={handleBlur}
                    width={18}
                    touched={touched.startDate}
                    error={errors.startDate}
                    excludeFutureDays
                  />

                  <MimaDateInput
                    labelTitle="End date"
                    name="endDate"
                    value={values.endDate}
                    onChange={(text) => {
                      setFieldValue("endDate", text);
                    }}
                    onBlur={handleBlur}
                    width={18}
                    touched={touched.endDate}
                    error={errors.endDate}
                    excludeFutureDays
                  />
                </div>
                <MimaButton
                  title="Continue"
                  onClick={handleSubmit}
                  type="submit"
                  variant="form"
                />
              </div>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};

export default AccountStatement;
