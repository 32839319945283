import styles from "../../assets/styles/MimaInput.module.scss";

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import * as inputStyles from "./styles";

const propTypes = {
  labelTitle: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "password",
    "radio",
    "date",
    "textarea",
    "tel",
    "time",
  ]),
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    "medium",
    "wide",
    "form",
    "mobile",
    "message",
    "exchange",
    "form2",
    "error",
  ]),
  width: PropTypes.any,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.any,
  fontSize: PropTypes.number,
  note: PropTypes.string,
};

export const MimaDateInput = ({
  labelTitle,
  placeholder,
  onBlur,
  onChange,
  value,
  disabled = false,
  type = "text",
  variant = "medium",
  width,
  height,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  touched,
  error,
  fontSize,
  showTimeSelect,
  excludeDates,
  excludeTimes,
  excludeWeekends,
  excludePastDays,
  excludeFutureDays,
  ...props
}) => {
  // const [selectedDate, setSelectedDate] = useState(null);

  const excludeWeekendsAction = (date) => {
    const day = date.getDay();
    const isWeekend = day === 6 || day === 0;
    return !isWeekend;
  };

  const pastDays = (date) => {
    const currentDate = new Date();
    const isPastDay = date < currentDate.setHours(0, 0, 0, 0);
    return !isPastDay;
  };

  const futureDays = (date) => {
    const currentDate = new Date();
    const isFutureDay = date > currentDate.setHours(0, 0, 0, 0);
    return !isFutureDay;
  };

  const excludeWeekendsPastDays = (date) => {
    const day = date.getDay();
    const currentDate = new Date();

    // Check if the day is Saturday (6) or Sunday (0)
    const isWeekend = day === 6 || day === 0;

    // Check if the date is in the past
    const isPastDay = date < currentDate.setHours(0, 0, 0, 0);

    return !isWeekend && !isPastDay;
  };

  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
      <label className={styles.form__label}>{labelTitle}</label>

      <DatePicker
        dateFormat={showTimeSelect ? "dd/MM/yyyy h:mm aa" : "dd/MM/yyyy"}
        showYearDropdown
        scrollableYearDropdown
        placeholderText="dd/mm/yyyy"
        calendarClassName={styles.form__calendar}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChange}
        selected={value}
        id={id}
        name={name}
        error={error}
        fontSize={fontSize}
        height={height}
        showTimeSelect={showTimeSelect}
        showTimeInput={showTimeSelect}
        excludeTimes={excludeTimes}
        excludeDates={excludeDates}
        excludeWeekends={excludeWeekends}
        excludePastDays={excludePastDays}
        excludeFutureDays={excludeFutureDays}
        filterDate={
          excludeWeekends && excludePastDays
            ? excludeWeekendsPastDays
            : excludeWeekends
              ? excludeWeekendsAction
              : excludePastDays
                ? pastDays
                : excludeFutureDays
                  ? futureDays
                  : props.filterDate
        }
        filterTime={props.filterTime}
        timeIntervals={props.timeIntervals}
        minDate={props.minDate}
        variant={variant}
        wrapperClassName={styles.dateInput}
        customInput={
          <InputSkeleton variant={variant ? variant : "form"} width={width} />
        }
        {...props}
        autoComplete="off"
      />

      {error ? <div className={styles.error}>{touched && error}</div> : null}
    </DivSkeleton>
  );
};

const InputSkeleton = styled.input`
  ${inputStyles.base};
  ${(props) => inputStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(touched) => (touched ? `color: var(--color-dark)` : "")}
`;

const DivSkeleton = styled.div`
  ${inputStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

MimaDateInput.propTypes = propTypes;

export default MimaDateInput;
