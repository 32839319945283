import styles from "../../assets/styles/Settings/Settings.module.scss";
import React, { useState } from "react";
import {
  Modal,
  MimaText,
  MimaDropdown,
  ReceiptCheckBox as RCB,
  MimaButton,
  MimaDateInput,
  PageLoader,
} from "../../components";
import { Formik } from "formik";
import useGeneralSettingsLogic from "./useLogic/useGeneralSettingsLogic";

const GeneralSettings = ({ closeModal }) => {
  const { loading, onSubmit, currencyCode, validationSchema, settings } =
    useGeneralSettingsLogic({ closeModal });

  const [isConfig, setIsConfig] = useState(settings._id);

  return (
    <Modal closeModal={closeModal}>
      {loading ? (
        <PageLoader loading={loading} />
      ) : (
        <>
          {isConfig && (
            <div>
              <MimaText
                variant="subtitleBold"
                mb={6}
                color="var(--color-dark)"
                align="center"
              >
                General Settings
              </MimaText>
              <div className={styles.configDetails}>
                <MimaText variant="body" color="var(--color-primary)">
                  Default Currency
                </MimaText>
                <MimaText variant="body">{settings.defaultCurrency}</MimaText>
              </div>
              <div className={styles.configDetails}>
                <MimaText variant="body" color="var(--color-primary)">
                  Activated Transactions SMS Notification
                </MimaText>
                <MimaText variant="body">
                  {settings.activateTransactionsSMS.toString()}
                </MimaText>
              </div>
              <div className={styles.configDetails}>
                <MimaText variant="body" color="var(--color-primary)">
                  Activated Transactions Email Notification
                </MimaText>
                <MimaText variant="body">
                  {settings.activateTransactionsEmail.toString()}
                </MimaText>
              </div>
              <div>
                <MimaButton
                  title="Edit"
                  onClick={() => {
                    setIsConfig(!isConfig);
                  }}
                  mt={4}
                  variant="form"
                />
              </div>
            </div>
          )}

          {!isConfig && (
            <Formik
              initialValues={{
                defaultCurrency: settings.defaultCurrency
                  ? settings.defaultCurrency
                  : "NGN",
                activateTransactionsSMS: settings.activateTransactionsSMS
                  ? settings.activateTransactionsSMS
                  : false,
                activateTransactionsEmail: settings.activateTransactionsEmail
                  ? settings.activateTransactionsEmail
                  : false,
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              }) => (
                <div>
                  <MimaText
                    variant="subtitleBold"
                    mb={4}
                    color="var(--color-dark)"
                    align="center"
                  >
                    General Settings Page
                  </MimaText>

                  <MimaDropdown
                    labelTitle={"Default Currency"}
                    placeholder={
                      values.defaultCurrency || "Select default currency"
                    }
                    name="defaultCurrency"
                    id="defaultCurrency"
                    value={values.defaultCurrency}
                    currentValue={values.defaultCurrency}
                    data={currencyCode}
                    onChange={(data) => {
                      setFieldValue("defaultCurrency", data.key);
                    }}
                    error={errors.defaultCurrency}
                    touched={touched.defaultCurrency}
                  />

                  <MimaText width={38} mb={1}>
                    Activate transactions SMS
                  </MimaText>
                  <div className="modal__beside">
                    <RCB
                      title={"Yes"}
                      name="activateTransactionsSMS"
                      value="true"
                      checked={values.activateTransactionsSMS}
                      onChange={() => {
                        setFieldValue("activateTransactionsSMS", true);
                      }}
                    />
                    <RCB
                      title={"No"}
                      name="activateTransactionsSMS"
                      value="false"
                      checked={!values.activateTransactionsSMS}
                      onChange={() => {
                        setFieldValue("activateTransactionsSMS", false);
                      }}
                    />
                  </div>
                  <MimaText width="38" mb={1} mt={2}>
                    Activate transactions Email
                  </MimaText>
                  <div className="modal__beside">
                    <RCB
                      title={"Yes"}
                      name="activateTransactionsEmail"
                      value="true"
                      checked={values.activateTransactionsEmail}
                      onChange={() => {
                        setFieldValue("activateTransactionsEmail", true);
                      }}
                    />
                    <RCB
                      title={"No"}
                      name="activateTransactionsEmail"
                      value="false"
                      checked={!values.activateTransactionsEmail}
                      onChange={() => {
                        setFieldValue("activateTransactionsEmail", false);
                      }}
                    />
                  </div>
                  {/* <div className="modal__beside">
                                        <RCB
                                            title={"Mima Account"}
                                            name="invoiceBankAccount"
                                            value="MIMA"
                                            checked={values.invoiceBankAccount === "MIMA"}
                                            onChange={() => {
                                                setFieldValue('invoiceBankAccount', "MIMA");
                                            }}
                                        />
                                        <RCB
                                            title={"Withdrawal Account"}
                                            name="invoiceBankAccount"
                                            value="WITHDRAWAL"
                                            checked={values.invoiceBankAccount === "WITHDRAWAL"}
                                            onChange={() => {
                                                setFieldValue('invoiceBankAccount', "WITHDRAWAL");
                                            }}
                                        />
                                    </div> */}

                  <MimaButton
                    title={"Continue"}
                    mt={3}
                    onClick={handleSubmit}
                    loading={loading}
                    variant="form"
                  />
                </div>
              )}
            </Formik>
          )}
        </>
      )}
    </Modal>
  );
};

export default GeneralSettings;
