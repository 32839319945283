import React, { useMemo, useState } from "react";
import { AppShell, MimaTable, MimaText } from "../../components";
import constant from "../../utils/constant";
import useStyleRequestLogic from "./useLogic/useStyleRequestLogic";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import ViewNote from "./ViewNote";

const StyleRequest = () => {
  const [item, setItem] = useState({});
  const {
    isLoading,
    data,
    isFetching,
    onLimitChange,
    limit,
    currentPage,
    setCurrentPage,
    setSearchQuery,
    closeModal,
    setViewNoteModal,
    viewNoteModal,
  } = useStyleRequestLogic();

  const tableData = useMemo(() => {
    if (data?.status === constant.Success) {
      return data?.data?.requests || [];
    }
    return [];
  }, [data]);

  const tableColumns = [
    {
      Header: "Image",
      Cell: ({ cell }) => {
        const imageUrls = cell.row.original?.style?.imageUrls;
        return (
          <div>
            <img src={imageUrls?.[0]} alt="Style" />
          </div>
        );
      },
    },
    {
      Header: "Style Name",
      accessor: "name",
      Cell: ({ cell }) => {
        const fullname = cell.row.original?.style?.name;
        return <span>{fullname}</span>;
      },
    },
    {
      Header: "Customer Name",
      Cell: ({ cell }) => {
        const fullname = cell.row.original?.customer.fullname;
        return <span>{fullname}</span>;
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: ({ cell }) => {
        const email = cell.row.original?.customer.email;
        return <span>{email}</span>;
      },
    },
    {
      Header: "Phone Number",
      accessor: "mobile",
      Cell: ({ cell }) => {
        const mobile = cell.row.original?.customer.mobile;
        return <span>{mobile}</span>;
      },
    },
    {
      Header: "Request Date",
      accessor: "createdAt",
      Cell: ({ cell }) => {
        const createdAt = cell.row.original?.createdAt;
        return <span>{moment(createdAt).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        const item = cell.row.original;

        return (
          <span
            className="action__text"
            onClick={() => {
              setItem(item);
              setViewNoteModal(true);
            }}
          >
            View Note
          </span>
        );
      },
    },
  ];
  return (
    <AppShell pageTitle="Style Enquiries">
      <MimaTable
        tableData={tableData}
        tableColumns={tableColumns}
        searchPlaceholder="Search Enquiry"
        searchVariant="wide"
        totalItems={data?.data?.totalCounts}
        onLimitChange={onLimitChange}
        isLoading={isLoading}
        isFetching={isFetching}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchQuery={setSearchQuery}
      />
      {viewNoteModal && (
        <div className="modal">
          <AnimatePresence>
            {viewNoteModal && <ViewNote closeModal={closeModal} item={item} />}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default StyleRequest;
