import React, { useState } from "react";
import { useStores, walletStore } from "../../../stores";
import { SettingsController, WalletController } from "../../../controllers";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
import { date, object, string } from "yup";
const useAccountStatementLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = () => {
    return object({
      currencyCode: string().required("Currency is required"),
      startDate: date().required("Start Date is required"),
      endDate: date().required("End Date is required"),
    });
  };

  const onSubmit = async (values) => {
    if (values.startDate > values.endDate) {
      return MimaToastUtil.error({
        message: "Please select an end date After the start Date",
      });
    }

    setLoading(true);

    const { status, errorMessage } =
      await WalletController.downloadAccountStatement(values);

    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: "Your Account Statement has been sent to your email",
      });

      return closeModal();
    }

    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  return { onSubmit, validationSchema, loading };
};
export default useAccountStatementLogic;
