import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { userStore } from "../../../stores";
import { MimaToastUtil } from "../../../components";
import { UserController } from "../../../controllers";
import constant from "../../../utils/constant";

const useOtpLogic = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isResendEnabled, setResendEnabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(60);

  useEffect(() => {
    let countdownTimer;

    if (resendCountdown > 0 && !isResendEnabled) {
      countdownTimer = setTimeout(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
    }

    return () => {
      clearTimeout(countdownTimer);
    };
  }, [resendCountdown, isResendEnabled]);

  const handleResendClick = async () => {
    if (userStore.otpType === constant.OtpType.ONBOARDING) {
      const { status, errorMessage } = await UserController.resendCode(
        userStore.userId
      );

      if (status === constant.Success) {
        setResendEnabled(false);
        setResendCountdown(60);
        return MimaToastUtil.success({
          message: "Otp resent to your email",
        });
      }
      return MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  const validationSchema = yup.object().shape({
    otpValue: yup.string().min(5, "Invalid OTP").required("Otp is required"),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    if (userStore.otpType === constant.OtpType.ONBOARDING) {
      const { status, errorMessage } = await UserController.activateUser(
        values.otpValue
      );
      if (status === constant.Success) {
        setLoading(false);
        return navigate(constant.Routes.Dashboard);
      }
      setLoading(false);
      return MimaToastUtil.error({
        message: errorMessage,
      });
    }

    if (userStore.otpType === constant.OtpType.FORGETPASSWORD) {
      const { status, errorMessage } =
        await UserController.validateForgetPasswordToken(
          userStore.email,
          values.otpValue
        );
      if (status === constant.Success) {
        setLoading(false);
        return navigate(constant.Routes.ForgotPasswordReset);
      }
      setLoading(false);
      return MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  return {
    loading,
    validationSchema,
    onSubmit,
    isResendEnabled,
    resendCountdown,
    handleResendClick,
  };
};

export default useOtpLogic;
