import { appStore, supportStore, useStores } from "../../../stores";
import { ContactSupport } from "../../../pages/Settings";
import { SupportController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { string, object } from "yup";
import { useFileUpload } from "use-file-upload";

const useContactSupportLogic = ({ closeModal }) => {
  const [faq, setFaq] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [speakRep, setSpeakRep] = useState(false);
  const [msgSent, setMsgSent] = useState(false);
  const [file, selectFile] = useFileUpload();

  const selectClick = () => {
    selectFile({ accept: "file/*" });
  };

  const fileReader = async (file, field, values) => {
    if (file && typeof file.source === "string") {
      const fileToSend = await fetch(file.source)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new File([blob], "name", {
              type: blob.type,
              lastModified: new Date(),
            })
        );
      values[field] = fileToSend;
    }
  };
  const goBack = () => {
    if (faq) {
      setFaq(false);
    } else if (sendMessage) {
      setSendMessage(false);
    } else if (speakRep) {
      setSpeakRep(false);
    } else {
      closeModal();
    }
  };

  const {
    appStore: { loading },
    supportStore,
  } = useStores();

  const faqs = supportStore.faqs;
  const showFaq = () => {
    setFaq(true);
  };

  const showSendMessage = () => {
    setSendMessage(true);
  };

  const showMsgSent = () => {
    setMsgSent(true);
  };

  const showSpeakRep = () => {
    setSpeakRep(true);
  };

  const validationSchema = () =>
    object({
      title: string().required("Title is required"),
      message: string().required("Message is required"),
    });

  const onSubmit = async (payload) => {
    appStore.setLoading(true);
    if (file && file.source) await fileReader(file, "file", payload);
    const { status, errorMessage } =
      await SupportController.createSupport(payload);
    appStore.setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return showMsgSent();
    }

    MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const [category, setCategory] = useState("");
  const showCategory = (i) => {
    if (category === i) {
      return setCategory(" ");
    } else {
      setCategory(i);
    }
  };

  const supportMainMenu = () => {
    setFaq(false);
    setSendMessage(false);
    setMsgSent(false);
    setSpeakRep(false);
  };

  const faqData = supportStore.faqs;
  const faqData2 = faqs;

  return {
    faq,
    loading,
    showFaq,
    onSubmit,
    validationSchema,
    faqData,
    sendMessage,
    showSendMessage,
    speakRep,
    showSpeakRep,
    msgSent,
    showMsgSent,
    supportMainMenu,
    category,
    showCategory,
    faqData2,
    goBack,
    file,
    selectClick,
  };
};

export default useContactSupportLogic;
