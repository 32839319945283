import styles from '../../assets/styles/Components/InvoiceListItem.module.scss';
import MimaText from '../MimaText';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	service: PropTypes.string,
	quantity: PropTypes.number,
	amount: PropTypes.number,
	totalAmount: PropTypes.string,
};
const defaultProps = {
	service: 'Change Service',
	quantity: 1,
	amount: 15000000,
	totalAmount: '',
};

const InvoiceSummaryItem = ({ service, quantity, amount, totalAmount }) => {
	return (
		<div className={styles.item}>
			<div>
				<p> {service}</p>
				<p className={styles.item__qty}>
					{quantity} x {amount}
				</p>
			</div>
			<p className={styles.item__total}>{totalAmount}</p>
		</div>
	);
};

export default InvoiceSummaryItem;
