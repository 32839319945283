import styles from "../../assets/styles/Wallet/RecentActivities.module.scss";
import MimaText from "../MimaText";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { MimaButton } from "../Button";
import { MimaToastUtil } from "../../components";
import { WalletController } from "../../controllers";
import constant from "../../utils/constant";
import { AnimatePresence } from "framer-motion";
// import { TagContainer } from '../../containers';
import { transactionStore } from "../../stores";

const propTypes = {
  description: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["debit", "credit", "noTag"]),
  date: PropTypes.any,
  amount: PropTypes.string,
};

const defaultProps = {
  description: "",
  variant: "credit",
  date: "",
  amount: "",
};

const RecentActivitiesList = ({
  description,
  variant,
  date,
  bankName,
  amount,
  balance,
  paymentMethod,
  accountName,
  accountNumber,
  paymentStatus,
  transferReceipt,
  hide,
  transaction,
  ...props
}) => {
  const [tagModal, setTagModal] = useState(false);
  const closeModal = () => {
    setTagModal(false);
  };
  const [loading, setLoading] = useState(false);

  const receiptDLHandler = async () => {
    setLoading(true);
    const { status, data } = await WalletController.getWalletReceipt(
      transaction.transfer._id
    );

    setLoading(false);
    if (status === constant.Success) {
      window.open(data.url);
      return MimaToastUtil.success({
        message: constant.Success,
      });
    }

    return MimaToastUtil.error({
      message: "Your receipt is being generated, try again after 60 seconds",
    });
  };

  return (
    <div className={styles.ra__content_list}>
      <div
        className={`${
          variant === "credit" || variant === "noTag"
            ? styles.ra__content_icon
            : `${styles.ra__content_icon} ${styles.debit}`
        }`}
      ></div>
      <div className={styles.ra__content_details}>
        <>
          <MimaText variant="small" mb={0.5}>
            {description}
          </MimaText>
          <MimaText variant="small" mb={0.5}>{`Amount: ${amount}`}</MimaText>
        </>
        {/* {variant === 'noTag' ? (
					<>
						<MimaText color="var(--color-error)" variant="small" mb={0.5}>
							{description}
						</MimaText>
						<MimaText color="var(--color-error)" variant="small" mb={0.5}>{`Amount: ${amount}`}</MimaText>
					</>
				) : (
					<>
						<MimaText variant="small" mb={0.5}>
							{description}
						</MimaText>
						<MimaText variant="small" mb={0.5}>{`Amount: ${amount}`}</MimaText>
					</>
				)} */}
        {accountNumber && (
          <>
            <MimaText variant="small" mb={0.5}>{`Bank: ${bankName}`}</MimaText>
            <MimaText
              variant="small"
              mb={0.5}
            >{`Acct. No: ${accountNumber}`}</MimaText>
            <MimaText
              variant="small"
              mb={0.5}
            >{`Acct. Name: ${accountName}`}</MimaText>
            <MimaText
              variant="small"
              mb={0.5}
            >{`Wallet Balance After : ${!hide ? balance : "*******"}`}</MimaText>
            <MimaText
              variant="small"
              mb={0.5}
            >{`Payment Status : ${paymentStatus}`}</MimaText>
            <MimaText variant="xsmall">{`Date: ${date}`}</MimaText>
          </>
          //   <>
          //     {variant === "noTag" ? (
          //       <>
          //         <MimaText
          //           color="var(--color-error)"
          //           variant="small"
          //           mb={0.5}
          //         >{`Bank: ${bankName}`}</MimaText>
          //         <MimaText
          //           color="var(--color-error)"
          //           variant="small"
          //           mb={0.5}
          //         >{`Acct. No: ${accountNumber}`}</MimaText>
          //         <MimaText
          //           color="var(--color-error)"
          //           variant="small"
          //           mb={0.5}
          //         >{`Acct. Name: ${accountName}`}</MimaText>
          //         <MimaText
          //           color="var(--color-error)"
          //           variant="small"
          //           mb={0.5}
          //         >{`Wallet Balance After : ${!hide ? balance : "*******"}`}</MimaText>
          //         <MimaText
          //           color="var(--color-error)"
          //           variant="small"
          //           mb={0.5}
          //         >{`Payment Status : ${paymentStatus}`}</MimaText>
          //         <MimaText
          //           color="var(--color-error)"
          //           variant="xsmall"
          //         >{`Date: ${date}`}</MimaText>
          //       </>
          //     ) : (
          //       <>
          //         <MimaText
          //           variant="small"
          //           mb={0.5}
          //         >{`Bank: ${bankName}`}</MimaText>
          //         <MimaText
          //           variant="small"
          //           mb={0.5}
          //         >{`Acct. No: ${accountNumber}`}</MimaText>
          //         <MimaText
          //           variant="small"
          //           mb={0.5}
          //         >{`Acct. Name: ${accountName}`}</MimaText>
          //         <MimaText
          //           variant="small"
          //           mb={0.5}
          //         >{`Wallet Balance After : ${!hide ? balance : "*******"}`}</MimaText>
          //         <MimaText
          //           variant="small"
          //           mb={0.5}
          //         >{`Payment Status : ${paymentStatus}`}</MimaText>
          //         <MimaText variant="xsmall">{`Date: ${date}`}</MimaText>
          //       </>
          //     )}
          //   </>
        )}
      </div>
      {variant === "debit" &&
      bankName !== "" &&
      paymentStatus === constant.TRANSACTION_STATUS.PAID ? (
        <MimaButton
          title="Get Receipt"
          variant="smaller"
          buttonColor="var(--color-fash-primary-3)"
          titleColor="var(--color-white)"
          onClick={receiptDLHandler}
          loading={loading}
        />
      ) : (
        ""
      )}
      {/* {variant === 'noTag' && (
				<MimaButton title="Tag Me" variant="smaller" buttonColor="var(--color-error)" onClick={() => {
					transactionStore.setSelectedIncome(transaction)
					setTagModal(true)
				}}></MimaButton>
			)} */}

      {/* {tagModal && (
				<div className='modal'>
					<AnimatePresence>
						{tagModal && (
							<TagContainer closeModal={closeModal} />
						)}
					</AnimatePresence>

				</div>
			)} */}
      {/* <MimaButton title="Get Receipt" variant="smaller" buttonColor="var(--color-green)" onClick={receiptDLHandler} loading={loading} /> : ""} */}
    </div>
  );
};

RecentActivitiesList.propTypes = propTypes;

RecentActivitiesList.defaultProps = defaultProps;

export default RecentActivitiesList;
