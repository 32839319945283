import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class AppStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'AppStore',
			properties: [
				'businessCategories',
				'expenseCategories',
				'banks',
				'beneficiaries',
				'securityQuestions',
				'liveData',
				'generalSettings',
				
			],
		});
	}
	loading = false;
	businessCategories = [];
	expenseCategories = [];
	banks = [];
	beneficiaries = [];
	dashboardSetup = false;
	securityQuestions = [];
	payBillsSetup= false;
	transactionSetup = false;
	bankingSetup = false;
	liveData = [];
	generalSettings = {};
	  

	setBusinessCategories = async businessCategories => {
		runInAction(async () => {
			this.businessCategories = businessCategories;
		});
	};

	setBanks = async banks => {
		runInAction(async () => {
			this.banks = banks;
		});
	};

	setSecurityQuestions = async data => {
		runInAction(async () => {
			this.securityQuestions = data;
		});
	};

	setDashboardSetup = async data => {
		runInAction(async () => {
			this.dashboardSetup = data;
		});
	};

	setPayBillsSetup = async data => {
		runInAction(async () => {
			this.payBillsSetup = data;
		});
	};

	setBankingSetup = async data => {
		runInAction(async () => {
			this.bankingSetup = data;
		});
	};

	setTransactionSetup = async data => {
		runInAction(async () => {
			this.transactionSetup = data;
		});
	};

	setBeneficiaries = async beneficiaries => {
		runInAction(async () => {
			this.beneficiaries = beneficiaries;
		});
	};

	setExpenseCategories = async expenseCategories => {
		runInAction(async () => {
			this.expenseCategories = expenseCategories;
		});
	};

	setLoading = async isLoading => {
		runInAction(() => {
			this.loading = isLoading;
		});
	};
	setLiveData = async liveData => {
		runInAction(() => {
			this.liveData = liveData;
		});
	};
	setGeneralSettings = async state => {
		runInAction(() => {
			this.generalSettings = state;
		});
	};
}
