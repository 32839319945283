import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../assets/styles/AppHome/QuickLinksCard.module.scss';
import { FaChevronRight } from 'react-icons/fa';

const propTypes = {
	title: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};

const defaultProps = {
	title: '',
	onClick: () => {},
	disabled: false,
};

const QuickLinksComponent = ({ title, onClick, ...props }) => {
	return (
		<div>
			<a className={styles.ql__link} onClick={onClick}>
				<p>{title}</p> <FaChevronRight className={styles.ql__icon} />
			</a>
		</div>
	);
};

export default QuickLinksComponent;
