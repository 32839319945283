import PulseLoader from "react-spinners/PulseLoader";
import * as buttonStyles from "./styles";
import styled from "styled-components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { lighten } from "polished";

const getCSSVariableValue = (variable) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
};

const propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    "regular",
    "contained",
    "outlined",
    "outlined2",
    "form",
    "close",
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.any,
  buttonColor: PropTypes.string,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  labelTitle: PropTypes.string,
  titleColor: PropTypes.string,
};

const MimaButton = ({
  title,
  loading = false,
  disabled = false,
  icon,
  labelTitle,
  mt,
  mb,
  mr,
  ml,
  width,
  height,
  type = "button",
  variant = "contained",
  buttonColor,
  ...props
}) => {
  const [hoverColor, setHoverColor] = useState("");

  useEffect(() => {
    let colorValue = buttonColor;
    if (!buttonColor) {
      const defaultVariantColors = {
        regular: getCSSVariableValue("--color-dark"),
        contained: getCSSVariableValue("--color-fash-primary"),
        form: getCSSVariableValue("--color-dark"),

        default: getCSSVariableValue("--color-dark"),
      };

      colorValue =
        defaultVariantColors[variant] || defaultVariantColors.default;
    }
    if (buttonColor && buttonColor.startsWith("var(")) {
      const variableName = buttonColor.slice(4, -1);
      colorValue = getCSSVariableValue(variableName);
    }
    setHoverColor(
      lighten(0.1, colorValue || getCSSVariableValue("--color-fash-black-2"))
    );
  }, [buttonColor, variant]);
  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width} height={height}>
      {labelTitle !== "" ? <label>{labelTitle}</label> : null}
      <ButtonSkeleton
        {...props}
        disabled={loading || disabled}
        icon={icon}
        width={width}
        height={height}
        variant={variant}
        buttonColor={buttonColor}
        hoverColor={hoverColor}
        type={type}
        mt={mt}
        mb={mb}
        mr={mr}
        ml={ml}
      >
        {loading ? (
          <PulseLoader color={"white"} loading={loading} size={15} />
        ) : (
          <>
            {icon} {title}
          </>
        )}
      </ButtonSkeleton>
    </DivSkeleton>
  );
};
const ButtonSkeleton = styled.button`
  ${buttonStyles.base};
  ${(props) => props.variant && buttonStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
  ${(props) =>
    props.buttonColor ? `background-color: ${props.buttonColor}` : ""};
  ${(props) => (props.titleColor ? `color: ${props.titleColor}` : "")};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
`;
const DivSkeleton = styled.div`
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
`;
MimaButton.propTypes = propTypes;

export default MimaButton;
