import { IconButton, MimaButton, MimaText } from "../../components";
import styles from "../../assets/styles/Wallet/TransferFunds.module.scss";
import TransferSuccessImage from "../../assets/img/MimaCongrats2.png";
import { amountFormatter } from "../../utils/utils";
import React from "react";

const TransferSuccess = ({ closeModal, payload }) => {
  return (
    <div className={styles.centerContent}>
      <img
        src={TransferSuccessImage}
        className={styles.transferSuccessImg}
        alt={"success"}
      />
      <MimaText align="center">Transfer Successful</MimaText>
      <MimaText align="center" variant="bodyBold">
        {amountFormatter().format(payload.amount)}
      </MimaText>
      <MimaText
        mb={5}
        align="center"
        variant="bodyBold"
        color="var(--color-fash-primary)"
      >
        {payload.accountName} {payload.accountNumber}
      </MimaText>
      <div className={styles.buttonBeside}>
        {/* <IconButton variant="download" />g */}
        <MimaButton
          title="Continue"
          type="submit"
          width={15}
          height={5}
          onClick={closeModal} //Temp nav
        />
      </div>
    </div>
  );
};

export default TransferSuccess;
