import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../assets/styles/AppHome/ThisWeekCard.module.scss';
import { FaChevronRight } from 'react-icons/fa';

const propTypes = {
	title: PropTypes.string,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	amount: PropTypes.string,
};

const defaultProps = {
	title: '',
	loading: false,
	onClick: () => {},
	disabled: false,
	amount: '',
};

const ThisWeekComponent = ({ title, amount, styleClass, ...props }) => {
	return (
		<div className={styles.tw__card}>
			<div className={styles.tw__card__text}>
				<div className={styles.tw__card__title}>{title}</div>
				<div className={styles.tw__card__value}>{amount}</div>
			</div>

			<FaChevronRight className={styles.tw__icon} />
		</div>
	);
};

export default ThisWeekComponent;
