import { object, string, number } from "yup";
import constant from "../../../utils/constant";
import React from "react";
import { observer } from "mobx-react";
import { billStore } from "../../../stores";
import { phoneRegExp } from "../../../utils/utils";

const useBuyAirtimeLogic = ({
  closeModal,
  pinContent,
  setPinContent,  
}) => {
  const validationSchema = () =>
    object({
      phone: string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Please enter phone number"),
      networkId: string().required("Please select network"),
      amount: number()
        .required("Please enter amount")
        .min(100, "The minimum Airtime to purchase is N100"),
    });

  const onSubmit = async (payload) => {
    
    delete payload.networkName;
    
    payload.controller = "buyAirtime";
    
    localStorage.setItem(constant.RouteParams, JSON.stringify(payload));
    
    setPinContent(true);
  };
  const bill = billStore.copiedBill;

  return { bill, pinContent, validationSchema, onSubmit, closeModal };
};

export default useBuyAirtimeLogic;
