import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as searchStyle from './styles';
import PropTypes from 'prop-types';
import {BiSearchAlt} from 'react-icons/bi';
import { observer } from 'mobx-react';

const propTypes = {
	placeholder: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.any,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf(['regular', 'wide', "dropdown"]),
	width: PropTypes.any,
	icon: PropTypes.element,
	mt: PropTypes.number,
	mr: PropTypes.number,
	mb: PropTypes.number,
	ml: PropTypes.number,
	error: PropTypes.any,
	touched: PropTypes.any,
    input: PropTypes.array,
    output: PropTypes.func,
    loading: PropTypes.bool,
    handleFilter: PropTypes.func,
	showIcon: PropTypes.bool,
};

const defaultProps = {
	placeholder: '',
	id: '',
	value: '',
	name: '',
	onBlur: () => {},
	onChange: () => {},
	disabled: false,
	variant: 'regular',
	width: '',
    loading: false,
    input: [],
    output: (result) => {},
    handleFilter: (item, search) => {},
	showIcon: true,
};

const Searchbar = ({
	labelTitle,
	placeholder,
	onBlur,
	onChange,
	value,
	disabled,
	variant,
	width,
	height,
	id,
	max,
	min,
	name,
	mt,
	mr,
	mb,
	ml,
    input, 
    output,
    loading,
    handleFilter,
	showIcon,
	...props
}) => {
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (searchQuery) {
            //Check if input is an array
            if (!Array.isArray(input)) {
                return onOutputReady([]);
            }
            
            const filter = input.filter((item) => handleFilter(item, searchQuery?.toLowerCase()));
            onOutputReady(filter);
        } else {
            onOutputReady(input)
        }
	}, [searchQuery, input])

    const onOutputReady = (data) => {
        if (!Array.isArray(input)) {
            return output([]);
        }

        output(data);
    }

    const onChangeHandler = e => {
        const text = e.target.value
        setSearchQuery(text)
    };

	return (
        <DivSkeleton>
            <SearchSkeleton
			{...props}
			max={max}
			min={min}
			placeholder={placeholder}
			onBlur={onBlur}
			disabled={disabled}
			onChange={onChangeHandler}
			variant={variant}
			width={width}
			id={id}
			name={name}
			height={height}
            input={input}
            output={output}
            value={searchQuery}
		    />
            {showIcon && <SearchIcon><BiSearchAlt/></SearchIcon>}
           
            {/* {searchTerm.map((value, key) => (
                <div key={key}>{value}</div>
            ))} */}

            
        </DivSkeleton>
		
	);
};

export const Searchbar2 = ({
	labelTitle,
	placeholder,
	onBlur,
	onChange,
	value,
	disabled,
	variant,
	width,
	height,
	id,
	max,
	min,
	name,
	mt,
	mr,
	mb,
	ml,
    input, 
    output,
    loading,
    handleFilter,
	showIcon,
	...props
}) => {
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (searchQuery) {
            //Check if input is an array
            if (!Array.isArray(input)) {
                return onOutputReady([]);
            }
            
            const filter = input.filter((item) => handleFilter(item, searchQuery?.toLowerCase()));
            onOutputReady(filter);
        } else {
            onOutputReady(input)
        }
	}, [searchQuery])

    const onOutputReady = (data) => {
        if (!Array.isArray(input)) {
            return output([]);
        }

        output(data);
    }

    const onChangeHandler = e => {
        const text = e.target.value
        setSearchQuery(text)
    };

	return (
        <DivSkeleton mb={mb} ml={ml} mr={mr} mt={mt}>
            <SearchSkeleton
			{...props}
			max={max}
			min={min}
			placeholder={placeholder}
			onBlur={onBlur}
			disabled={disabled}
			onChange={onChangeHandler}
			variant={variant}
			width={width}
			id={id}
			name={name}
			height={height}
            input={input}
            output={output}
            value={searchQuery}
		    />
            {showIcon && <SearchIcon><BiSearchAlt/></SearchIcon>}
           
            {/* {searchTerm.map((value, key) => (
                <div key={key}>{value}</div>
            ))} */}

            
        </DivSkeleton>
		
	);
};

const SearchSkeleton = styled.input`
	${searchStyle.base};
	${props => searchStyle[props.variant]};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.height ? `height: ${props.height}rem` : '')};
	${touched => (touched ? `color: var(--color-dark)` : '')}
`;

const DivSkeleton = styled.div`
	${searchStyle.divBase};
	${props => searchStyle[props.variant]};
	${props => (props.mt ? `margin-top: ${props.mt}rem` : '')};
	${props => (props.mr ? `margin-right: ${props.mr}rem` : '')};
	${props => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
	${props => (props.ml ? `margin-left: ${props.ml}rem` : '')};
	${props => (props.width ? `width: ${props.width}rem` : '')};
`;

const SearchIcon =styled.div`
    ${searchStyle.iconBase};
`;
Searchbar.propTypes = propTypes;

Searchbar.defaultProps = defaultProps;

export default observer(Searchbar);
