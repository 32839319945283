import { businessStore, userStore, walletStore, websiteStore } from "../stores";
import { apiRequest } from "../utils/useAPIRequest";
import constant from "../utils/constant";

const update = async (payload) => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/businesses/business/update",
    method: "patch",
    payload,
    hasImageUpload: true,
    constantStore: [{ store: userStore.setOnBoardingDone, value: "true" }],
  });
  if (status === constant.Success) {
    businessStore.setBusiness(data.business);
    return { status };
  }
  return { status, errorMessage };
};
const activateBvn = async (token) =>
  await apiRequest({
    url: `/businesses/bvn/activate/${token}`,
    method: "get",
  });
const resendBVNCode = async () =>
  await apiRequest({
    url: "/businesses/resend-bvn-token",
    method: "get",
  });
const signUpBusiness = async (payload) => {
  localStorage.clear();
  return await apiRequest({
    url: "/businesses/business",
    method: "post",
    payload,
    constantStore: [{ store: userStore.setActivated, value: "false" }],
    responseStore: [{ store: userStore.setUserId, value: "userId" }],
  });
};

const createAnotherBusiness = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/businesses/add-a-business",
    method: "post",
    payload,
  });
  return { status, errorMessage };
};

const businessDetails = async () => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/businesses/business-details",
    method: "get",
  });
  if (status === constant.Success) {
    businessStore.setBusiness(data);
    businessStore.setLocalCurrency(data.localCurrency);
    const subdomain = data?.storeFrontUrl?.split("//")[1].split(".")[0];
    businessStore.setSubdomain(subdomain);
    websiteStore.setWebConfig(data?.settings);
    businessStore.setSettings(data?.settings);

    return { status };
  }
  return { status, errorMessage };
};

const generateBankAccount = async () => {
  const { status, errorMessage } = await apiRequest({
    url: "/businesses/generate-business-bank-account",
    method: "get",
  });
  if (status === constant.Success) {
    walletStore.setCreatingAccNo(true);
    businessDetails();
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const getBusinessReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage } = await apiRequest({
    url: `/businesses/business-report-by-dates?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const getAllSubscriptions = async (query) => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/subscriptions/plans?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    businessStore.setSubscriptionPlans(data);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const getBusinessReportAsPdf = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage } = await apiRequest({
    url: `/businesses/business-report-pdf?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const getPreviousSubscriptionDetails = async () => {
  const { status, errorMessage } = await apiRequest({
    url: `/subscriptions`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success };
  }

  return { errorMessage, status };
};

const subscribe = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: `/subscriptions/business/subscribe`,
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    userStore.setLoggedIn(false);
    return { status: constant.Success, data };
  }

  return { errorMessage, status, data };
};

const changeSubscription = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: `/subscriptions/business/change-plan`,
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    userStore.setLoggedIn(false);
    return { status: constant.Success };
  }

  return { errorMessage, status };
};

const cancelSubscription = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: `/subscriptions/suspend`,
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    userStore.setLoggedIn(false);
    return { status: constant.Success };
  }

  return { errorMessage, status };
};

const planChangeCost = async (payload) => {
  const query = `plan=${payload.plan}&months=${payload.months}`;
  const { status, errorMessage, data } = await apiRequest({
    url: `/subscriptions/business/get-plan-cost?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success, data };
  }

  return { errorMessage, status };
};

const renewSubscription = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: `/subscriptions/business/renew`,
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    userStore.setLoggedIn(false);
    return { status: constant.Success, data };
  }

  return { errorMessage, status, data };
};

const sendToken = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/businesses/validate-business-mobile",
    method: "patch",
    payload,
  });

  if (status === constant.Success) {
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const verifyToken = async (query) => {
  const { status, errorMessage } = await apiRequest({
    url: `/businesses/verify-mobile-token?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    businessDetails();
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const donwgradeToFreePlan = async () => {
  const { status, errorMessage, data } = await apiRequest({
    url: `/subscriptions/business/set-to-free-plan`,
    method: "post",
  });

  if (status === constant.Success) {
    userStore.setLoggedIn(false);
    return { status: constant.Success, data };
  }

  return { errorMessage, status, data };
};

const BusinessController = {
  signUpBusiness,
  update,
  resendBVNCode,
  activateBvn,
  businessDetails,
  generateBankAccount,
  createAnotherBusiness,
  getBusinessReportByDate,
  getAllSubscriptions,
  getBusinessReportAsPdf,
  getPreviousSubscriptionDetails,
  subscribe,
  changeSubscription,
  cancelSubscription,
  planChangeCost,
  renewSubscription,
  sendToken,
  verifyToken,
  donwgradeToFreePlan,
};

export default BusinessController;
