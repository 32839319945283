/* eslint-disable no-unused-vars */
import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  ReceiptCheckBox as RCB,
  Modal,
  MimaDateInput,
} from "../../components";

import styles from "../../assets/styles/Transactions/Transactions.module.scss";
import { amountFormatter } from "../../utils/utils";
import constant from "../../utils/constant";
import { transaction } from "mobx";
import { Formik } from "formik";
import React from "react";

const UpdateDebt = ({
  closeModal,
  loading,
  transaction,
  balanceAmountUpdateDebt,
  onSubmitUpdateDebt,
  validationSchemaUpdateDebt,
}) => {
  const statuses =
    transaction.status === constant.TRANSACTION_STATUS.UNPAID
      ? [
          {
            key: constant.TRANSACTION_STATUS.VOID,
            value: constant.TRANSACTION_STATUS.VOID,
          },
          {
            key: constant.TRANSACTION_STATUS.PAID,
            value: constant.TRANSACTION_STATUS.PAID,
          },
          {
            key: constant.TRANSACTION_STATUS.PARTIALLY_PAID,
            value: constant.TRANSACTION_STATUS.PARTIALLY_PAID,
          },
        ]
      : [
          {
            key: constant.TRANSACTION_STATUS.VOID,
            value: constant.TRANSACTION_STATUS.VOID,
          },
        ];

  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      <div>
        <MimaText
          variant="subtitle"
          color="var(--color-primary)"
          align="center"
        >
          {`Update Debt Status`}
        </MimaText>
        <MimaText variant="small" align="center" mb={4}>
          Manually update the status of your Debt
        </MimaText>
        <Formik
          initialValues={{
            paidDate: "",
            balanceDueDate: "",
            paymentMethod: "",
            status: "",
            amountPaid: 0,
            paidViaMima: false,
          }}
          validationSchema={validationSchemaUpdateDebt}
          onSubmit={onSubmitUpdateDebt}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <div>
              <div className={styles.summary__total}>
                <MimaText>Debt Total Amount</MimaText>
                <MimaText variant="bodyBold" color="var(--color-primary)">
                  {amountFormatter(transaction.currencyCode).format(
                    transaction.deposit
                  )}
                </MimaText>
              </div>
              <MimaDropdown
                labelTitle="Debt Status"
                placeholder="Select Status"
                name="status"
                currentValue={values.status}
                id="status"
                onChange={(data) => {
                  setFieldValue("status", data.key);
                }}
                data={statuses}
              />
              {errors.status ? (
                <div className={styles.error}>
                  {""}
                  {touched.status && errors.status}
                  {""}
                </div>
              ) : null}
              {values.status !== constant.TRANSACTION_STATUS.VOID &&
                transaction.status === constant.TRANSACTION_STATUS.UNPAID && (
                  <>
                    <div className={styles.beside}>
                      <div>
                        <MimaInput
                          type="number"
                          labelTitle="Amount Paid"
                          placeholder="0.00"
                          name="amountPaid"
                          width={18}
                          disabled={
                            values.status === constant.TRANSACTION_STATUS.PAID
                              ? true
                              : false
                          }
                          value={
                            values.status === constant.TRANSACTION_STATUS.PAID
                              ? transaction.deposit
                              : values.amountPaid
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          mr={2}
                        />

                        {errors.amountPaid ? (
                          <div className={styles.error}>
                            {""}
                            {touched.amountPaid && errors.amountPaid}
                            {""}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <MimaDateInput
                          labelTitle="Paid date"
                          name="paidDate"
                          value={values.paidDate}
                          onChange={(text) => {
                            setFieldValue("paidDate", text);
                          }}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.paidDate}
                          error={errors.paidDate}
                          mr={2}
                        />
                        {errors.paidDate ? (
                          <div className={styles.error}>
                            {touched.paidDate && errors.paidDate}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {values.status ===
                      constant.TRANSACTION_STATUS.PARTIALLY_PAID && (
                      <div>
                        <MimaDateInput
                          labelTitle="Balance Due date"
                          name="balanceDueDate"
                          value={values.balanceDueDate}
                          onChange={(text) => {
                            setFieldValue("balanceDueDate", text);
                          }}
                          onBlur={handleBlur}
                          width={38}
                          touched={touched.balanceDueDate}
                          error={errors.balanceDueDate}
                          mr={2}
                        />
                        {errors.balanceDueDate ? (
                          <div className={styles.error}>
                            {touched.balanceDueDate && errors.balanceDueDate}
                          </div>
                        ) : null}
                      </div>
                    )}
                    <MimaButton
                      title={`Expected Balance Amount:
														${balanceAmountUpdateDebt(values)}`}
                      variant="text"
                      type="button"
                      width={38}
                    />

                    <MimaText variant="small" ml={2}>
                      Payment Method
                    </MimaText>
                    <div className={styles.checks}>
                      <RCB
                        title="Cash"
                        id="CASH"
                        name="paymentMethod"
                        onChange={() => {
                          setFieldValue("paymentMethod", "CASH");
                        }}
                        checked={values.paymentMethod === "CASH"}
                        value="cash"
                      />
                      <RCB
                        title="Transfer"
                        onChange={() => {
                          setFieldValue("paymentMethod", "TRANSFER");
                        }}
                        checked={values.paymentMethod === "TRANSFER"}
                        id="transfer"
                        name="paymentMethod"
                        value="transfer"
                      />
                      <RCB
                        title="P.O.S"
                        id="pos"
                        onChange={() => {
                          setFieldValue("paymentMethod", "POS");
                        }}
                        checked={values.paymentMethod === "POS"}
                        name="paymentMethod"
                        value="pos"
                      />
                    </div>
                    {errors.paymentMethod ? (
                      <div className={styles.error}>
                        {touched.paymentMethod && errors.paymentMethod}
                      </div>
                    ) : null}

                    <MimaText variant="small" ml={2}>
                      Paid via Mima
                    </MimaText>
                    <div className={styles.checks}>
                      <RCB
                        title="Paid From Mima Wallet"
                        id="wasPaidOnMima"
                        name="paidViaMima"
                        onChange={() => {
                          setFieldValue("paidViaMima", true);
                        }}
                        checked={values.paidViaMima === true}
                      />
                      <RCB
                        title="Paid From Bank"
                        id="outsidePaidOnMima"
                        name="paidViaMima"
                        onChange={() => {
                          setFieldValue("paidViaMima", false);
                        }}
                        checked={values.paidViaMima === false}
                      />
                    </div>
                    {errors.paidViaMima ? (
                      <div className={styles.error}>
                        {touched.paidViaMima && errors.paidViaMima}
                      </div>
                    ) : null}
                  </>
                )}
              <MimaButton
                title="Update"
                onClick={handleSubmit}
                type="submit"
                loading={loading}
                variant="form"
                mt={2}
              />
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdateDebt;
