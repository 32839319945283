import React from "react";
import { MimaText, Modal } from "../../components";
import { measurementStore } from "../../stores";
import styles from "../../assets/styles/Measurement/Measurement.module.scss";

const ViewMoreMeasurement = ({ closeModal }) => {
  const selectedMeasurement = measurementStore.selectedMeasurement;
  const isMeasurementNoteBook =
    selectedMeasurement.imageUrls.filter(
      (x) => x.type === "Measurement NoteBook"
    ).length > 0;
  const isCustomerPicture =
    selectedMeasurement.imageUrls.filter((x) => x.type === "Customer Picture")
      .length > 0;
  return (
    <Modal closeModal={closeModal}>
      <div>
        <MimaText
          variant="subtitle"
          color="var(--color-fash-primary-3)"
          align="center"
          mb={5}
        >
          View More Measurement Info
        </MimaText>
        <MimaText labelTitle="measurement For" mb={2}>
          {selectedMeasurement.measurementFor}
        </MimaText>
        {selectedMeasurement?.customer && (
          <MimaText labelTitle="Customer Name" mb={2}>
            {selectedMeasurement?.customer?.fullname}
          </MimaText>
        )}
        {selectedMeasurement?.style && (
          <MimaText labelTitle="Style Name" mb={2}>
            {selectedMeasurement?.style?.name}
          </MimaText>
        )}
        <MimaText labelTitle="Unit Of Measurement">
          {selectedMeasurement.unitOfMeasurement}
        </MimaText>

        <div>
          <MimaText mt={2} mb={1}>
            Standard Size
          </MimaText>
          <div className={styles.standardSize}>
            <MimaText labelTitle="Country">
              {selectedMeasurement?.standardSize?.country
                ? selectedMeasurement?.standardSize?.country
                : "NA"}
            </MimaText>
            <MimaText labelTitle="Value">
              {selectedMeasurement?.standardSize?.value
                ? selectedMeasurement?.standardSize?.value
                : "NA"}
            </MimaText>
          </div>
        </div>
        <div>
          <MimaText mt={2} mb={1}>
            Body Parts
          </MimaText>
          <div className={styles.part}>
            <MimaText variant="small">Part</MimaText>
            <MimaText variant="small">Actual</MimaText>
            <MimaText variant="small">Temporary</MimaText>
          </div>
          {selectedMeasurement.parts.map((item) => (
            <div className={styles.part} key={item._id}>
              <MimaText>{item.part}</MimaText>
              <MimaText>{item.value}</MimaText>
              <MimaText>{item.temp}</MimaText>
            </div>
          ))}
        </div>
        <MimaText labelTitle="Note" mt={2}>
          {selectedMeasurement.note}
        </MimaText>
        <div>
          {isMeasurementNoteBook && (
            <>
              <MimaText mt={2} mb={1} align="center">
                Measurement NoteBook
              </MimaText>
              {selectedMeasurement.imageUrls
                .filter((x) => x.type === "Measurement NoteBook")
                .map((imgUrl, i) => (
                  <div key={i} className={styles.img}>
                    <img src={imgUrl.value} alt={imgUrl.type} width="100%" />
                  </div>
                ))}
            </>
          )}
        </div>
        <div>
          {isCustomerPicture && (
            <>
              <MimaText mt={2} mb={1} align="center">
                Customer Picture
              </MimaText>
              {selectedMeasurement.imageUrls
                .filter((x) => x.type === "Customer Picture")
                .map((imgUrl, i) => (
                  <div key={i} className={styles.img}>
                    <img src={imgUrl.value} alt={imgUrl.type} width="100%" />
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewMoreMeasurement;
