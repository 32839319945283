import {
	MimaButton,
	MimaDateInput,
	MimaInput,
	MimaText,
	Modal,
	ReceiptCheckBox as RCB,
} from '../../components';
import styles from '../../assets/styles/Transactions/Transactions.module.scss';
import TransactionAddedSuccess from './TransactionAddedSuccess';
import { Formik } from 'formik';
import React from 'react';

const EditIncome = ({
	closeModal,
	openIncomeModal,
	loading,
	validationSchema,
	incomeSuccess,
	onSubmit,
	addAnotherIncome,
}) => {
	return (
		<Modal closeModal={closeModal}>
			{openIncomeModal && (
				<Formik
					initialValues={{
						transactionAmount: 0,
						deposit: 0,
						narration: '',
						paidDate: '',
						paymentMethod: '',
					}}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
						values,
						errors,
						touched,
					}) => (
						<>
							<MimaText
								variant="subtitle"
								color="var(--color-primary)"
								align="center"
							>
								Edit Income
							</MimaText>
							<MimaInput
								type="text"
								labelTitle="Service"
								placeholder="Enter Service Rendered"
								name="narration"
								value={values.narration}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors.narration}
								touched={touched.narration}
							/>

							<div className={styles.beside}>
								<div>
									<MimaInput
										type="number"
										labelTitle="Enter Amount Charged"
										placeholder="0.00"
										name="transactionAmount"
										variant="income"
										value={values.transactionAmount}
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.transactionAmount}
										touched={touched.transactionAmount}
									/>
								</div>

								<MimaInput
									type="text"
									labelTitle="Amount Deposited"
									placeholder="0.00"
									name="deposit"
									variant="income"
									value={values.deposit}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</div>

							<MimaDateInput
								labelTitle="Paid Date"
								name="paidDate"
								value={values.paidDate}
								onChange={text => {
									setFieldValue('paidDate', text);
								}}
								onBlur={handleBlur}
								error={errors.paidDate}
								touched={touched.paidDate}
							/>
							<MimaText variant="small" mt={2} ml={-24}>
								Payment type
							</MimaText>
							<div className={styles.checks}>
								<RCB
									title="POS"
									onChange={() => {
										setFieldValue('paymentMethod', 'POS');
									}}
									checked={values.paymentMethod === 'POS'}
									name="paymentMethod"
								/>
								<RCB
									title="Transfer"
									checked={values.paymentMethod === 'TRANSFER'}
									onChange={() => setFieldValue('paymentMethod', 'TRANSFER')}
									name="paymentMethod"
								/>
								<RCB
									title="Cash"
									checked={values.paymentMethod === 'CASH'}
									onChange={() => setFieldValue('paymentMethod', 'CASH')}
									name="paymentMethod"
								/>
							</div>
							{errors.paymentMethod ? (
								<div className={styles.error}>
									{''}
									{touched.paymentMethod && errors.paymentMethod}
									{''}
								</div>
							) : null}

							<MimaButton
								title="Save"
								type="submit"
								loading={loading}
								onClick={handleSubmit}
								mt={2}
							/>
						</>
					)}
				</Formik>
			)}

			{incomeSuccess && (
				<TransactionAddedSuccess
					addAnotherIncome={addAnotherIncome}
					closeModal={closeModal}
				/>
			)}
		</Modal>
	);
};

export default EditIncome;
