import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class GeneralNotificationStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'GeneralNotificationStore',
			properties: ['notifications'],
		});
	}

	notifications = {};

	reset = () => {
		this.notifications = {};
	}

	setNotifications = async state => {
		runInAction(async () => {
			this.notifications = state;
		});
	};
}
