import {
	MimaButton,
	MimaInput,
	MimaText,
	Modal,
	MimaDropdown,
	ReceiptCheckBox as RCB,
} from '../../components';
import styles from '../../assets/styles/Transactions/Transactions.module.scss';
import TransactionAddedSuccess from './TransactionAddedSuccess';
import { useStores } from '../../stores';
import { Formik } from 'formik';
import React from 'react';

const EditExpenses = ({
	closeModal,
	openExpenseModal,
	loading,
	validationSchema,
	expenseSuccess,
	onSubmit,
	addAnotherExpense,
}) => {
	const {
		appStore: { expenseCategories },
	} = useStores();
	return (
		<Modal closeModal={closeModal}>
			{openExpenseModal && (
				<Formik
					initialValues={{
						transactionAmount: 0,
						expenseCategory: '',
						narration: '',
						paidDate: '',
						paymentMethod: '',
					}}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
						values,
						errors,
						touched,
					}) => (
						<>
							<MimaText
								variant="subtitle"
								color="var(--color-primary)"
								align="center"
							>
								Edit Expense
							</MimaText>
							<MimaDropdown
								labelTitle="Expense Categories"
								placeholder="-Select-"
								data={expenseCategories}
								id="expenseCategory"
								onChange={data => {
									setFieldValue('expenseCategory', data.key);
								}}
								name="expenseCategory"
								onBlur={handleBlur}
								error={errors.expenseCategory}
								touched={touched.expenseCategory}
							/>

							<MimaInput
								type="text"
								labelTitle="Narration"
								placeholder="Description of the expense made"
								name="narration"
								value={values.narration}
								onChange={handleChange}
								onBlur={handleBlur}
								mt={2}
								error={errors.narration}
								touched={touched.narration}
							/>

							<div className={styles.beside}>
								<div>
									<MimaInput
										type="number"
										labelTitle="Amount Charged"
										placeholder="0.00"
										min={0}
										name="transactionAmount"
										variant="income"
										value={values.transactionAmount}
										onChange={handleChange}
										onBlur={handleBlur}
										mr={2}
										error={errors.transactionAmount}
										touched={touched.transactionAmount}
									/>
								</div>
								<div>
									<MimaInput
										type="date"
										labelTitle="Paid Date"
										name="paidDate"
										mb={2}
										variant="income"
										value={values.paidDate}
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.paidDate}
										touched={touched.paidDate}
									/>
								</div>
							</div>

							<MimaText variant="small" mt={2} ml={-24}>
								Payment type
							</MimaText>
							<div className={styles.checks}>
								<RCB
									title="POS"
									onChange={() => {
										setFieldValue('paymentMethod', 'POS');
									}}
									checked={values.paymentMethod === 'POS'}
									name="paymentMethod"
								/>
								<RCB
									title="Transfer"
									checked={values.paymentMethod === 'TRANSFER'}
									onChange={() => setFieldValue('paymentMethod', 'TRANSFER')}
									name="paymentMethod"
								/>
								<RCB
									title="Cash"
									checked={values.paymentMethod === 'CASH'}
									onChange={() => setFieldValue('paymentMethod', 'CASH')}
									name="paymentMethod"
								/>
							</div>
							{errors.paymentMethod ? (
								<div className={styles.error}>
									{''}
									{touched.paymentMethod && errors.paymentMethod}
									{''}
								</div>
							) : null}

							<MimaButton
								title="Save"
								type="submit"
								loading={loading}
								onClick={handleSubmit}
								mt={2}
							/>
						</>
					)}
				</Formik>
			)}

			{expenseSuccess && (
				<TransactionAddedSuccess
					addAnotherExpense={addAnotherExpense}
					closeModal={closeModal}
				/>
			)}
		</Modal>
	);
};

export default EditExpenses;
