import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class InvoiceStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'InvoiceStore',
			properties: [
				'invoices',
				'invoicesAnalysis',
				'partiallyPaidInvoices',
				'notPaidInvoices',
				'copiedInvoice',
				'fullyPaidInvoices',
				'selectedInvoice',
				'dueIn30days',
				'overDue',
				'selectedCurrency',
				'totalInvoiceCount',
				'businessDetails',
				'offset',
				'invoiceConfig',
				'stocks'
			],
		});
	}

	invoices = [];
	fullyPaidInvoices = [];
	partiallyPaidInvoices = [];
	notPaidInvoices = [];
	dueIn30days = [];
	overDue = [];
	copiedInvoice = {};
	invoicesAnalysis = {};
	selectedInvoice = {};
	loading = false;
	selectedCurrency = "NGN";
	checkoutData = {};
	totalInvoiceCount = 0;
	businessDetails = {};
	stocks = [];
	offset = 1;
	invoiceConfig = {};

	reset = () => {
		this.stocks = [];
		this.invoices = [];
		this.fullyPaidInvoices = [];
		this.partiallyPaidInvoices = [];
		this.notPaidInvoices = [];
		this.overDue = [];
		this.copiedInvoice = {};
		this.invoicesAnalysis = {};
		this.dueIn30days = [];
		this.selectedInvoice = {};
		this.selectedCurrency = "";
		this.checkoutData = {};
		this.totalInvoiceCount = 0;
		this.businessDetails = {};
		this.offset = 1;
		this.invoiceConfig = {};
	};

	setLoading = async isLoading => {
		runInAction(() => {
			this.loading = isLoading;
		});
	};
	setDueIn30days = async state => {
		runInAction(async () => {
			this.dueIn30days = state;
		});
	};
	setOverDue = async state => {
		runInAction(async () => {
			this.overDue = state;
		});
	};
	setStocks = async state => {
		runInAction(async () => {
			this.stocks = state;
		});
	};
	setInvoicesAnalysis = async state => {
		runInAction(async () => {
			this.invoicesAnalysis = state;
		});
	};

	setPartiallyPaidInvoices = async state => {
		runInAction(async () => {
			this.partiallyPaidInvoices = state;
		});
	};
	
	setFullyPaidInvoices = async state => {
		runInAction(async () => {
			this.fullyPaidInvoices = state;
		});
	};
	
	setNotPaidInvoices = async state => {
		runInAction(async () => {
			this.notPaidInvoices = state;
		});
	};
	
	setSelectedInvoice = async state => {
		runInAction(async () => {
			this.selectedInvoice = state;
		});
	};

	setCopiedInvoice = async state => {
		runInAction(async () => {
			this.copiedInvoice = state;
		});
	};

	setInvoices = async state => {
		runInAction(async () => {
			// this.invoices = state;
			if (state?.length === 0 || state?.length === undefined) {
				this.invoices = [];
			} else if (this.invoices.length === 0) {
				this.invoices = state;
			} else {
				const uniqueInvoices = [...new Set([...this.invoices, ...state])];
				this.invoices = uniqueInvoices;
			}
		});
	};

	setSelectedCurrency = async state => {
		runInAction(async () => {
			this.selectedCurrency = state;
		});
	};

	setCheckoutData = async state => {
		runInAction(async () => {
			this.checkoutData = state;
		});
	};

	setTotalInvoiceCount = async state => {
		runInAction(async () => {
			this.totalInvoiceCount = state;
		});
	};

	setBusinessDetails = async state => {
		runInAction(async () => {
			this.businessDetails = state;
		});
	};

	setOffset = async state => {
		runInAction(async () => {
			this.offset = state;
		});
	};

	setInvoiceConfig = async state => {
		runInAction(async () => {
			this.invoiceConfig = state;
		})
	}
}
