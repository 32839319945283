import {
	MimaButton,
	MimaDropdown,
	MimaInput,
	MimaText,
	Modal,
} from '../../components';
import styles from '../../assets/styles/Settings/Settings.module.scss';
import { Formik } from 'formik';
import React from 'react';
import { useStores } from '../../stores';

const AddBusiness = ({
	closeModal,
	validationSchema,
	loading,
	onSubmit,
	goBack,
}) => {
	const {
		appStore: { businessCategories },
	} = useStores();

	return (
		<Modal closeModal={closeModal} goBack={goBack}>
			<MimaText variant="subtitle" align="center" mb={4}>
				Add A Business
			</MimaText>
			<div className={styles.bus}>
				<div className={styles.bus__details}>
					<div className={styles.bus__details}>
						<Formik
							initialValues={{
								tradingName: '',
								email: '',
								mobile: '',
								businessCategory: '',
							}}
							validationSchema={validationSchema}
							onSubmit={onSubmit}>
							{({
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
								touched,
								errors,
								values,
							}) => (
								<>
									<MimaInput
										labelTitle="Business Name"
										type="text"
										placeholder="Enter your Business name"
										id="tradingName"
										name="tradingName"
										value={values.tradingName}
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.tradingName}
										touched={touched.tradingName}
									/>
									<MimaDropdown
										labelTitle="Business Category"
										onChange={data => {
											setFieldValue('businessCategory', data.key);
										}}
										placeholder="Select a Business Category"
										data={businessCategories}
										name="businessCategory"
										error={errors.businessCategory}
										touched={touched.businessCategory}
									/>

									<MimaInput
										labelTitle="Business Email"
										type="text"
										placeholder="Enter your Business Email"
										id="email"
										name="email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.email}
										touched={touched.email}
									/>

									<MimaInput
										labelTitle="Phone Number"
										type="tel"
										placeholder="Enter your mobile number"
										id="mobile"
										name="mobile"
										value={values.mobile}
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.mobile}
										touched={touched.mobile}
									/>

									<MimaButton
										title="Continue"
										loading={loading}
										type="submit"
										onClick={handleSubmit}
									/>
								</>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default AddBusiness;
