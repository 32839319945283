import constant from "./constant";
import client from "./client";
import config from "./config";

const UseRequest = async ({
  baseUrl = config.BASE_API_URL,
  url,
  method = "get",
  payload = {},
  headers = {},
}) => await client[method](baseUrl + url, payload, { headers });

const apiRequest = async ({
  url,
  method,
  payload,
  hasImageDownload,
  params,
  query,
  hasImageUpload,
  constantStore,
  responseStore,
}) => {
  try {
    const requestObject = {
      url,
      method,
    };
    if (payload) requestObject["payload"] = payload;
    if (params) requestObject["params"] = params;
    if (query) requestObject["query"] = query;

    if (hasImageUpload) {
      requestObject["headers"] = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      };
    }
    if (hasImageDownload) {
      requestObject["headers"] = {
        responseType: "blob",
      };
    }

    const response = await UseRequest(requestObject);
    if (constantStore && constantStore.length > 0) {
      for (let index = 0; index < constantStore.length; index++) {
        const item = constantStore[index];
        item.store(item.value);
      }
    }
    if (responseStore && responseStore.length > 0) {
      for (let index = 0; index < responseStore.length; index++) {
        const item = responseStore[index];
        item.store(response.data[item.value]);
      }
    }
    return { status: constant.Success, data: response.data };
  } catch (error) {
    const { data } = error.response || {};
    return { status: constant.Failed, errorMessage: data?.message, data: data };
  }
};

export { UseRequest, apiRequest };
