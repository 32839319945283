import React, { useState } from "react";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { PaymentLinkController } from "../../../controllers";
import { paymentLinkStore } from "../../../stores";
import { object, string, number, array, boolean, date } from "yup";

const useEditPaymentLinkLogic = ({ goToViewLinks, editLink }) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = () => {
    return object({
      title: string().required("Enter a Payment Link Name"),
      description: string().required("Enter the Payment Link description"),
      acceptPhoneNumber: boolean().required(
        "Should payment link require phone numbers?"
      ),
      acceptQuantity: boolean().required(
        "Should payment link require quantity?"
      ),
      acceptPartPayment: boolean().required(
        "Should payment link accept part payment?"
      ),
      currencyCode: string().required("Select Currency"),
      chargeSplit: string().required("Select How you want to split charges"),
      minDeposit: number("enter a number"),
      amount: number("enter a number").when("acceptPartPayment", {
        is: true,
        then: (schema) =>
          schema.min(1, "Minimum should be 1").required("Amount is required"),
        otherwise: (schema) => schema.optional(),
      }),
    });
  };

  const selectedLink = paymentLinkStore.selectedPaymentLink;

  const onSubmit = async (values) => {
    setLoading(true);
    let payload = {};
    payload = {
      ...values,
    };

    delete payload.previewAmount;
    delete payload.previewIG;
    delete payload.previewFullName;
    delete payload.previewEmail;
    delete payload.previewMobile;
    delete payload.previewQuantity;
    delete payload.previewAddress;

    if (payload.amount === "") {
      delete payload.amount;
    }

    if (payload.minDeposit === "") {
      delete payload.minDeposit;
    }
    const id = selectedLink._id;

    if (editLink) {
      delete payload.amount;
      delete payload.currencyCode;

      const { status, errorMessage } =
        await PaymentLinkController.editPaymentLink(id, payload);

      if (status === constant.Success) {
        MimaToastUtil.success({
          message: constant.Success,
        });
        setLoading(false);
        goToViewLinks();

        return;
      } else {
        setLoading(false);
        MimaToastUtil.error({ message: errorMessage });
      }
    }
  };
  return { validationSchema, onSubmit, loading };
};

export default useEditPaymentLinkLogic;
