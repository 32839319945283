import { css } from "styled-components";

export const base = css`
  padding: 0.5rem 1.2rem;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
  font-size: var(--default-font-small);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  min-height: 3.2rem;
  min-width: fit-content;
  border: none;
  font-family: inherit;
  &:disabled {
    background-color: var(--color-grey-2);
    &:hover {
      background-color: var(--color-grey-2);
    }
  }
`;

export const contained = css`
  background-color: var(--color-fash-primary);
  color: var(--color-dark);
  width: fit-content;
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

export const regular = css`
  color: var(--color-white);
  max-width: 38rem;
  height: 4.8rem;
  background-color: var(--color-dark);
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

export const outlined = css`
  border: 1px solid var(--color-dark);
  color: var(--color-dark);
  box-sizing: border-box;
  &:hover {
    border: 2px solid var(--color-fash-primary-2);
    color: var(--color-fash-primary-2);
  }
`;

export const outlined2 = css`
	border: 1px solid var(--color-dark);
	color: var(--color-dark);
	padding: 0.5rem;
	height: 4rem;	
	font-size: var(--default-font-small);
	width: 100%;
	&:hover {
		border: 2px solid var(--color-primary);
		color: var(--color-primary);
	}		
`;

export const form = css`
  background-color: var(--color-dark);
  color: var(--color-white);
  width: 100%;
  height: 4.8rem;
  font-size: var(--default-font-size);
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

export const close = css`
  background-color: var(--color-error);
  color: var(--color-white);
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  font-size: 2rem;
  &:hover {
    background-color: var(--color-deep-red);
  }
`;
