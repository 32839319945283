import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useState } from "react";
import { businessStore, userStore } from "../../../stores";
import { MimaToastUtil } from "../../../components";
import { UserController } from "../../../controllers";
import constant from "../../../utils/constant";

const useLoginLogic = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const payload = {
      userEmailMobile: values.email,
      password: values.password,
      accountType: "Business",
    };

    const { status, data, errorMessage } = await UserController.login(payload);
    if (status === constant.Success) {
      if (businessStore.expiredSubscription) {
        setLoading(false);
        return navigate(constant.Routes.SubExpired);
      } else {
        setLoading(false);
        return navigate(constant.Routes.Dashboard);
      }
    } else if (data?.activated === false) {
      setLoading(false);
      userStore.setUserId(data?.userId);
      userStore.setOtpType(constant.OtpType.ONBOARDING);
      return navigate(constant.Routes.Otp);
    } else {
      setLoading(false);
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  return {
    loading,
    validationSchema,
    onSubmit,
  };
};

export default useLoginLogic;
