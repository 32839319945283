import TransactionsController from "./TransactionsController";
import { apiRequest } from "../utils/useAPIRequest";
import constant from "../utils/constant";
import { businessStore, invoiceStore } from "../stores";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../App";
import { useMemo } from "react";

const getAnalysis = async (query) => {
  if (!query) {
    const startDate = moment().startOf("year");
    const endDate = moment().endOf("month");
    query = `startDate=${startDate}&endDate=${endDate}&dateField=createdAt`;
  }
  const { status, data, errorMessage } = await apiRequest({
    url: `/invoices/new/analysis?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    invoiceStore.setInvoicesAnalysis(data);
    // invoiceStore.setSelectedCurrency(data.statuses.length === 0 ? "NGN" : data.statuses[0]._id);
    invoiceStore.setSelectedCurrency(
      businessStore.defaultCurrency
        ? businessStore.defaultCurrency
        : data.statuses[0]?._id
    );

    return { status: constant.Success };
  }
  return { errorMessage, status };
};

//deprecated will be deleted soon
const getInvoices = async (query) => {
  if (!query) {
    const startDate = moment().subtract(1, "month").startOf("month");
    const endDate = moment().endOf("month");
    query = `startDate=${startDate}&endDate=${endDate}&dateField=createdAt`;
  }
  const { status, data, errorMessage } = await apiRequest({
    url: `/invoices/business?${query}&limit=50`,
    method: "get",
  });

  if (status === constant.Success) {
    let invoices = [];
    data.invoices.forEach((invoice) => {
      invoices.push(...invoice.details);
    });
    const sortedInvoices = invoices.sort(
      (objA, objB) => Number(objA.number) - Number(objB.number)
    );
    const invoiceList = sortedInvoices.reverse();

    // invoiceStore.setInvoices(invoiceList);
    // invoiceStore.setPartiallyPaidInvoices(
    // 	invoiceList.filter(invoice => invoice.status === 'PARTIALLY-PAID')
    // );
    // invoiceStore.setNotPaidInvoices(
    // 	invoiceList.filter(invoice => invoice.status === 'UNPAID')
    // );
    // invoiceStore.setFullyPaidInvoices(
    // 	invoiceList.filter(invoice => invoice.status === 'FULLY-PAID')
    // );
    // invoiceStore.setOverDue(
    // 	invoiceList.filter(
    // 		invoice =>
    // 			invoice.status === 'UNPAID' &&
    // 			invoice.dueDate &&
    // 			moment(invoice.dueDate) <= moment()
    // 	)
    // );
    // invoiceStore.setDueIn30days(
    // 	invoiceList.filter(
    // 		invoice =>
    // 			invoice.status === 'UNPAID' &&
    // 			invoice.dueDate &&
    // 			moment(invoice.dueDate) <= moment().add(30, 'days') &&
    // 			moment(invoice.dueDate) >= moment()
    // 	)
    // );
    return { status: constant.Success };
  }
  return { errorMessage, status };
};
//deprecated end

const getInvoiceConfig = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/settings/business",
    method: "get",
  });

  if (status === constant.Success) {
    invoiceStore.setInvoiceConfig(data);

    return { status: constant.Success };
  }

  return { errorMessage, status };
};

const updateInvoiceConfig = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/settings/update-setting",
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    await getInvoiceConfig();
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const useInvoiceList = (invoiceTableQuery, filterQuery, searchQuery) => {
  const id = businessStore.bId;

  const query = useMemo(() => {
    if (searchQuery) {
      return `${invoiceTableQuery}&${filterQuery}&search=${searchQuery}`;
    } else if (filterQuery) {
      return `${invoiceTableQuery}&${filterQuery}`;
    } else {
      return invoiceTableQuery;
    }
  }, [filterQuery, invoiceTableQuery, searchQuery]);

  return useQuery({
    queryKey: [id, "invoicelist", query],
    queryFn: () => {
      return apiRequest({
        url: `/invoices/new/business?${query}`,
        method: "get",
      });
    },
    keepPreviousData: true,
  });
};

const sendInvoice = async (id) =>
  await apiRequest({
    url: `/invoices/invoice/send/${id}`,
    method: "get",
  });

const download = async (id) =>
  await apiRequest({
    url: `/invoices/download-invoice/${id}`,
    method: "get",
    hasImageDownload: true,
  });

const updateInvoice = async (payload, id) => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/invoices/invoice/${id}`,
    method: "put",
    payload,
  });
  if (status === constant.Success) {
    invoiceStore.setSelectedInvoice(data);
    const id = businessStore.bId;
    queryClient.invalidateQueries({ queryKey: [id, "invoicelist"] });
    Promise.all([
      getAnalysis(),
      TransactionsController.transactions(),
      TransactionsController.transactionSummary(),
    ]);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const updateInvoiceStatus = async (payload) => {
  const id = businessStore.bId;

  const { status, data, errorMessage } = await apiRequest({
    url: "/invoices/invoice/status",
    method: "patch",
    payload,
  });
  if (status === constant.Success) {
    invoiceStore.setSelectedInvoice(data);

    queryClient.invalidateQueries({ queryKey: [id, "invoicelist"] });
    await Promise.all([
      getAnalysis(),
      TransactionsController.transactions(),
      TransactionsController.transactionSummary(),
    ]);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const createInvoice = async (payload) => {
  const id = businessStore.bId;

  const { status, data, errorMessage } = await apiRequest({
    url: "/invoices/invoice",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    queryClient.invalidateQueries({ queryKey: [id, "invoicelist"] });
    invoiceStore.setSelectedInvoice(data);
    await getInvoices();
    Promise.all([
      getAnalysis(),
      TransactionsController.transactions(),
      TransactionsController.transactionSummary(),
    ]);
    return { status: constant.Success, data };
  }
  return { errorMessage, status };
};

const createBulkInvoice = async (payload) => {
  const id = businessStore.bId;

  const { status, data, errorMessage } = await apiRequest({
    url: "/invoices/invoice/bulk",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    invoiceStore.setSelectedInvoice(data);
    queryClient.invalidateQueries({ queryKey: [id, "invoicelist"] });

    await Promise.all([
      getAnalysis(),
      TransactionsController.transactions(),
      TransactionsController.transactionSummary(),
    ]);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const createToshoInvoice = async (payload) => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/invoices/tosho-invoice",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    invoiceStore.setSelectedInvoice(data);

    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const createStockInvoice = async (payload) => {
  const { status, data, errorMessage } = await apiRequest({
    url: "/invoices/stock-invoice",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    invoiceStore.setSelectedInvoice(data);

    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const invoicePaymentCheckout = async (id) => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/invoices/payment-gateway/${id}`,
    method: "get",
  });

  if (status === constant.Success) {
    invoiceStore.setCheckoutData(data);
    return { status: constant.Success };
  }

  // if (status === constant.Failed) {
  //   const {data} = await Promise.all([invoicePaymentConfirm(id)])
  //   return {status: constant.Failed, data: data}
  // }

  return { errorMessage, status };
};

const invoicePaymentConfirm = async (id) => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/invoices/confirm-payment/${id}`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { errorMessage, status };
};

export default {
  updateInvoiceConfig,
  getInvoiceConfig,
  getInvoices,
  getAnalysis,
  download,
  createInvoice,
  createBulkInvoice,
  sendInvoice,
  updateInvoice,
  updateInvoiceStatus,
  createToshoInvoice,
  createStockInvoice,
  invoicePaymentCheckout,
  invoicePaymentConfirm,
  useInvoiceList,
};
