import { appStore, businessStore, userStore } from "../../../stores";
import {
  BusinessController,
  SettingsController,
  WalletController,
} from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import { object, string } from "yup";
import constant from "../../../utils/constant";
import React, { useEffect, useState } from "react";

const useCardInfoLogic = ({ closeModal }) => {
  const [stage, setStage] = useState("");
  const [loading, setLoading] = useState(false);
  const [cardToDelete, setCardToDelete] = useState("");

  const hasAddedCard = businessStore.business.card;

  useEffect(() => {
    const initialSetup = async () => {
      setLoading(true);
      if (hasAddedCard) {
        setStage("stage2");
        await SettingsController.getStoredCards();
      }
      setLoading(false);
    };

    initialSetup();
  }, []);

  const onSubmit = (payload) => {};

  const validationSchema = () =>
    object({
      bankCode: string().required("Select a bank"),
      accountNumber: string().required("Account Number is required"),
    });

  const chargeCard = (amount) => {
    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

    const metadata = {
      user: userStore.user._id,
      type: "FUNDING",
    };
    if (userStore.user.accountType === "Business")
      metadata.business = businessStore.bId;
    return {
      email: userStore.user.email,
      amount,
      metadata,
      publicKey,
      text: "Click to add Card",
      onSuccess: (response) => {
        const payload = { reference: response.reference };
        const sendReference = async () => {
          setLoading(true);
          const { status, errorMessage } =
            await SettingsController.addNewCard(payload);

          if (status === constant.Success) {
            setLoading(false);
            MimaToastUtil.success({
              message: "Card Added",
            });
            closeModal();
          } else {
            setLoading(false);
            MimaToastUtil.error({
              message: errorMessage,
            });
            closeModal();
          }
        };

        sendReference();

        setLoading(true);
        setTimeout(async () => {
          await SettingsController.runTransactionSetup();
          setLoading(false);
        }, 5000);
      },
    };
  };

  const confirmDelete = (cardId) => {
    setCardToDelete(cardId);
    setStage("stage3");
  };

  const deleteCardHandler = async () => {
    setLoading(true);
    const { status, errorMessage } =
      await SettingsController.deleteCard(cardToDelete);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: "Card Deleted Successfully",
      });
      return closeModal();
    }
    setLoading(false);
    MimaToastUtil.error({
      message: errorMessage,
    });
    return closeModal();
  };

  const goBack = () => {
    if ((hasAddedCard && stage === "") || stage === "stage3") {
      setStage("stage2");
    } else if (stage === "" || stage === "stage2") {
      closeModal();
    }
  };

  return {
    stage,
    onSubmit,
    setLoading,
    validationSchema,
    loading,
    closeModal,
    chargeCard,
    setStage,
    deleteCardHandler,
    goBack,
    confirmDelete,
  };
};

export default useCardInfoLogic;
