import React, { useState } from "react";

const useAirtimeDataLogic = ({ closeModal }) => {
  const [buyAirtimeContent, setBuyAirtimeContent] = useState(false);
  const [buyDataContent, setBuyDataContent] = useState(false);
  const [mainContent, setMainContent] = useState(true);
  const [pinContent, setPinContent] = useState(false);

  const buydata = () => {
    setBuyDataContent(true);
    setMainContent(false);
    setPinContent(false);
  };

  const buyAirtime = () => {
    setBuyAirtimeContent(true);
    setMainContent(false);
    setPinContent(false);
  };

  const goBack = () => {
    if (buyAirtimeContent) {
      setBuyAirtimeContent(false);
      setMainContent(true);
    } else if (buyDataContent) {
      setBuyDataContent(false);
      setMainContent(true);
    } else if (pinContent) {
      setPinContent(false);
    } else {
      closeModal();
    }
  };

  return {
    buyAirtimeContent,
    buyDataContent,
    buydata,
    buyAirtime,
    mainContent,
    goBack,
    pinContent,
    setPinContent,
  };
};

export default useAirtimeDataLogic;
