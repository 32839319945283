/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  MimaButton,
  MimaDateInput,
  MimaDropdown,
  MimaInput,
  MimaText,
  MimaTextArea,
  ReceiptCheckBox as RCB,
  MimaPaginatedDropdown,
} from "../../components";
import styles from "../../assets/styles/Invoice/Invoice.module.scss";
import React, { useState } from "react";
import { currencies } from "../../utils/utils";
import { Formik, Field, FieldArray } from "formik";
import { businessStore } from "../../stores";
import useEditQuotationLogic from "./useLogic/useEditQuotationLogic";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};

const FieldTextArea = ({ field, ...props }) => {
  return <MimaTextArea {...field} {...props} />;
};

const FieldPaginatedDropdown = ({ field, ...props }) => {
  return <MimaPaginatedDropdown {...field} {...props} />;
};

const EditQuotation = ({ quote, closeModal }) => {
  const { invoice, onSubmit, loading, orders, invoiceDetailsValidation } =
    useEditQuotationLogic({
      closeModal,
      quote,
    });
  const [discountStatus, setDiscountStatus] = useState(false);

  return (
    <div>
      <MimaText
        variant="subtitle"
        color="var(--color-fash-primary-3)"
        align="center"
        mb={4}
      >
        Edit Quotation
      </MimaText>
      <Formik
        initialValues={{
          customer: quote.customer.length
            ? quote.customer[0]._id
            : quote.customer._id,
          customerName: quote.customer.length
            ? quote.customer[0].fullname
            : quote.customer.fullname,
          orders,
          selectFromStock: orders[0]?.stock === undefined ? false : true,
          vat: !!(quote.vat && quote.vat.amount),
          wht: !!(quote.wht && quote.wht.amount),
          dueDate: quote.dueDate ? new Date(quote.dueDate) : "",
          currencyCode: quote.currencyCode,
          discountAmount: quote.discountAmount || 0,
          paymentMethod: quote.paymentMethod || "",
          note: quote.note,
          deposit: quote.deposit,
          discountPer: quote.discountPer || 0,
          depositPer: 100,
          shipping: quote.shipping || 0,
        }}
        validationSchema={invoiceDetailsValidation}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => {
          return (
            <div>
              <MimaPaginatedDropdown
                mb={2}
                labelTitle="Bill To"
                placeholder="Select Customer"
                name="customer"
                value={values.customerName}
                id="customer"
                optionLabel="fullname"
                touched={touched.customer}
                error={errors.customer}
                url="/customers/business"
                queryKey={[businessStore.bId, "customers"]}
                getPageValue={(page) => page?.data?.customers || []}
                getPageTotalCount={(lastPage) =>
                  lastPage?.data?.totalCounts || 0
                }
                onChange={(data) => {
                  setFieldValue("customer", data._id);
                  setFieldValue("customerName", data.fullname);
                }}
              />

              <MimaDropdown
                labelTitle="Select Currency"
                name="currencyCode"
                value={values.currencyCode}
                placeholder={values.currencyCode}
                id="currencyCode"
                currentValue={values.currencyCode}
                onChange={(data) => {
                  setFieldValue("currencyCode", data.key);
                }}
                data={currencies}
                touched={touched.currencyCode}
                error={errors.currencyCode}
                mb={3}
              />
              <RCB
                title="Select Product from Stock"
                onChange={(e) => {
                  setFieldValue("selectFromStock", e.target.checked);
                  setFieldValue("orders", [
                    {
                      quantity: 1,
                      unitPrice: 0,
                      item: "",
                      stock: "",
                    },
                  ]);
                }}
                checked={values.selectFromStock}
                value={String(values.selectFromStock)}
                name="selectFromStock"
              />
              <FieldArray name="orders">
                {({ push, remove }) => (
                  <React.Fragment>
                    {values?.orders?.map((_, i) => (
                      <div key={i}>
                        {!values.selectFromStock && (
                          <Field
                            name={`orders.${i}.item`}
                            id={`orders.${i}.item`}
                            component={FieldTextArea}
                            type="text"
                            placeholder="Product/Service"
                            labelTitle="Product/Service"
                            mt={1}
                            touched={touched?.orders?.[i]?.item}
                            error={errors?.orders?.[i]?.item}
                          />
                        )}

                        {values.selectFromStock ? (
                          <Field
                            type="text"
                            mt={2}
                            name={`orders.${i}.item`}
                            id={`orders.${i}.item`}
                            value={values.orders[i].item}
                            queryKey={[businessStore.bId, "stock"]}
                            optionLabel="name"
                            component={FieldPaginatedDropdown}
                            labelTitle="Product/Service"
                            placeholder={
                              values.orders[i].item || "Select from Stock"
                            }
                            touched={touched?.orders?.[i]?.item}
                            error={errors?.orders?.[i]?.item}
                            url="/products/stocks/business"
                            getPageValue={(page) => page?.data?.value || []}
                            getPageTotalCount={(lastPage) =>
                              lastPage?.data?.totalCounts || 0
                            }
                            onChange={(data) => {
                              setFieldValue(`orders.${i}.stock`, data._id);
                              setFieldValue(`orders.${i}.item`, data.name);
                              setFieldValue(
                                `orders.${i}.unitPrice`,
                                data.sellingPrice
                              );
                            }}
                          />
                        ) : null}

                        <div className={styles.invoice__beside}>
                          <Field
                            name={`orders.${i}.unitPrice`}
                            component={FieldInput}
                            type="number"
                            min="0"
                            labelTitle="Price"
                            width={18}
                            // disabled={values.selectFromStock}
                            touched={touched?.orders?.[i]?.unitPrice}
                            error={errors?.orders?.[i]?.unitPrice}
                          />
                          <Field
                            name={`orders.${i}.quantity`}
                            component={FieldInput}
                            type="number"
                            min="0"
                            labelTitle="Quantity"
                            width={18}
                            touched={touched?.orders?.[i]?.quantity}
                            error={errors?.orders?.[i]?.quantity}
                          />
                        </div>
                        {i !== 0 ? (
                          <MimaButton
                            title="Remove"
                            variant="filter"
                            type="button"
                            buttonColor="var(--color-error)"
                            width={10}
                            onClick={() => remove(i)}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    {values.orders?.length > 0 && (
                      <MimaButton
                        title="Add Another Item"
                        variant="text"
                        type="button"
                        onClick={() =>
                          push({
                            quantity: 1,
                            unitPrice: 0,
                            item: "",
                          })
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </FieldArray>

              <>
                <MimaInput
                  type="number"
                  labelTitle="Shipping Fee"
                  placeholder="0.00"
                  name="shipping"
                  mb={1}
                  min={0}
                  value={values.shipping}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className={styles.invoice__beside}>
                  <MimaInput
                    type="number"
                    labelTitle="Discount Amount"
                    placeholder="0"
                    name="discountAmount"
                    mb={1}
                    min={0}
                    value={values.discountAmount}
                    onChange={(e) => {
                      setDiscountStatus(false);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    width={18}
                    touched={touched.discountAmount}
                    error={errors.discountAmount}
                  />
                  <MimaInput
                    type="number"
                    labelTitle="Discount %"
                    placeholder="0%"
                    name="discountPer"
                    mb={1}
                    min={0}
                    max={100}
                    value={values.discountPer}
                    disabled={true}
                    onBlur={handleBlur}
                    width={18}
                    touched={touched.discountPer}
                    error={errors.discountPer}
                  />
                </div>

                <div className={styles.invoice__beside}>
                  <MimaDateInput
                    labelTitle="Valid date"
                    name="dueDate"
                    value={values.dueDate}
                    onChange={(text) => {
                      setFieldValue("dueDate", text);
                    }}
                    onBlur={handleBlur}
                    width={38}
                    touched={touched.dueDate}
                    error={errors.dueDate}
                  />

                  <div className={styles.invoice__checks_group}>
                    <MimaText variant="small">
                      Do you want to charge VAT?
                    </MimaText>
                    <div className={styles.invoice__checks}>
                      <RCB
                        title="Yes"
                        onChange={() => {
                          setFieldValue("vat", true);
                        }}
                        checked={values.vat}
                        value="true"
                        name="vat"
                      />
                      <RCB
                        title="No"
                        checked={!values.vat}
                        onChange={() => setFieldValue("vat", false)}
                        value="false"
                        name="vat"
                      />
                    </div>
                  </div>
                  <div className={styles.invoice__checks_group}>
                    <MimaText variant="small">
                      Do you want to deduct WHT?
                    </MimaText>
                    <div className={styles.invoice__checks}>
                      <RCB
                        title="Yes"
                        onChange={() => {
                          setFieldValue("wht", true);
                        }}
                        checked={values.wht}
                        value="true"
                        name="wht"
                      />
                      <RCB
                        title="No"
                        checked={!values.wht}
                        onChange={() => {
                          setFieldValue("wht", false);
                        }}
                        value="false"
                        name="wht"
                      />
                    </div>
                  </div>
                </div>
              </>
              {/* )} */}

              {/* Samuel check from here for receipt items. */}

              <MimaTextArea
                type="text"
                labelTitle="Note or Terms and Conditions"
                name="note"
                variant="wide"
                value={values.note}
                onChange={handleChange}
                onBlur={handleBlur}
                height={10}
              />

              <MimaButton
                title="Update"
                onClick={handleSubmit}
                type="submit"
                loading={loading}
                variant="form"
                mt={2}
              />
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditQuotation;
