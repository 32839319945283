import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { InvoiceController } from "../../../controllers";
import { invoiceStore } from "../../../stores";

const useInvoiceReminderLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const setInvoiceConfig = async () => {
      setLoading(true);
      await Promise.all([InvoiceController.getInvoiceConfig()]);
      setLoading(false);
    };
    setInvoiceConfig();
  }, []);

  const validationSchema = () =>
    yup.object({
      invoiceTimer: yup.string().required("Select repeat time"),
      invoiceReminderDate: yup.string().required("Set date"),
    });

  const onSubmit = async (payload) => {
    delete payload.invoiceTimerValue;
    setLoading(true);
    const { status, errorMessage } =
      await InvoiceController.updateInvoiceConfig(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
    } else {
      MimaToastUtil.error({
        message: errorMessage,
      });
      setLoading(false);
    }
    closeModal();
  };

  const timePeriod = [
    {
      key: 6,
      value: "Every 6hrs",
    },
    {
      key: 12,
      value: "Every 12hrs",
    },
    {
      key: 24,
      value: "Every 24hrs",
    },
    {
      key: 168,
      value: "Every 168hrs",
    },
    {
      key: 731,
      value: "Every Month",
    },
  ];

  const invoiceBankAccount = [
    {
      key: "WITHDRAWAL",
      value: "Withdrawal Account",
    },
    {
      key: "MIMA",
      value: "Mima Account",
    },
  ];
  const invoiceReminder = invoiceStore.invoiceConfig;

  return {
    loading,
    timePeriod,
    onSubmit,
    validationSchema,
    invoiceReminder,
    invoiceBankAccount,
  };
};

export default useInvoiceReminderLogic;
