import {
  MimaButton,
  MimaInput,
  MimaText,
  Modal,
  MimaDropdown,
  ReceiptCheckBox as RCB,
  MimaDateInput,
} from "../../components";
import styles from "../../assets/styles/Transactions/Transactions.module.scss";
import TransactionAddedSuccess from "./TransactionAddedSuccess";
import { amountFormatter, currencies } from "../../utils/utils";
import { useStores } from "../../stores";
import { Formik } from "formik";
import React from "react";

const AddExpenses = ({
  closeModal,
  openExpenseModal,
  loading,
  type,
  expenseSuccess,
  addAnotherExpense,
  addNewExpense,
  validationSchemaAddExpense,
  onSubmitAddExpense,
}) => {
  const {
    appStore: { expenseCategories },
  } = useStores();

  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      {openExpenseModal && (
        <Formik
          initialValues={{
            transactionAmount: 0,
            deposit: 0,
            currencyCode: "NGN",
            expenseCategory: "",
            narration: "",
            dueDate: "",
            balanceDueDate: "",
            paidDate: "",
            paymentMethod: "",
          }}
          validationSchema={validationSchemaAddExpense}
          onSubmit={onSubmitAddExpense}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <>
              <MimaText
                variant="subtitle"
                color="var(--color-fash-primary-3)"
                align="center"
              >
                {`Add ${type === "OUTFLOW" ? "Expense" : "Debt"}`}
              </MimaText>
              <MimaDropdown
                labelTitle="Expense Categories"
                placeholder="-Select-"
                data={expenseCategories}
                id="expenseCategory"
                onChange={(data) => {
                  setFieldValue("expenseCategory", data.key);
                }}
                name="expenseCategory"
                onBlur={handleBlur}
                error={errors.expenseCategory}
                touched={touched.expenseCategory}
              />
              <MimaDropdown
                labelTitle="Select Currency"
                name="currencyCode"
                value={values.currencyCode}
                placeholder={values.currencyCode}
                id="currencyCode"
                currentValue={values.currencyCode}
                onChange={(data) => {
                  setFieldValue("currencyCode", data.key);
                }}
                data={currencies}
                touched={touched.currencyCode}
                error={errors.currencyCode}
              />
              <MimaInput
                type="text"
                labelTitle="Narration"
                placeholder="e.g. 30 jerseys"
                name="narration"
                value={values.narration}
                onChange={handleChange}
                onBlur={handleBlur}
                mt={2}
                error={errors.narration}
                touched={touched.narration}
                width={38}
              />
              <div className={styles.beside}>
                <div>
                  <MimaInput
                    type="number"
                    labelTitle="Amount Charged"
                    placeholder="0.00"
                    mb={2}
                    min={0}
                    name="transactionAmount"
                    value={values.transactionAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width={type !== "DEBT" ? 18 : 38}
                    mr={2}
                    error={errors.transactionAmount}
                    touched={touched.transactionAmount}
                  />
                </div>
                {type === "OUTFLOW" && (
                  <div>
                    <MimaInput
                      type="number"
                      labelTitle="Amount Paid"
                      placeholder="0.00"
                      name="deposit"
                      mb={2}
                      min={0}
                      variant="income"
                      width={18}
                      value={values.deposit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      mr={2}
                      error={errors.deposit}
                      touched={touched.deposit}
                    />
                  </div>
                )}
              </div>
              {type === "OUTFLOW" && (
                <MimaButton
                  title={`Expected Balance:
													${amountFormatter(values.currencyCode).format(
                            values.transactionAmount - values.deposit
                          )}`}
                  variant="text"
                  type="button"
                />
              )}

              <div className={styles.beside}>
                {type === "OUTFLOW" && (
                  <div>
                    <MimaDateInput
                      labelTitle="Paid Date"
                      name="paidDate"
                      mb={2}
                      value={values.paidDate}
                      onChange={(text) => {
                        setFieldValue("paidDate", text);
                      }}
                      onBlur={handleBlur}
                      error={errors.paidDate}
                      touched={touched.paidDate}
                      width={
                        values.transactionAmount - values.deposit > 0 ? 18 : 38
                      }
                    />
                  </div>
                )}
                {type === "OUTFLOW" &&
                values.transactionAmount - values.deposit > 0 ? (
                  <div>
                    <MimaDateInput
                      labelTitle="Bal. Due Date"
                      name="balanceDueDate"
                      value={values.balanceDueDate}
                      onChange={(text) => {
                        setFieldValue("balanceDueDate", text);
                      }}
                      onBlur={handleBlur}
                      error={errors.balanceDueDate}
                      touched={touched.balanceDueDate}
                      width={18}
                    />
                  </div>
                ) : (
                  ""
                )}
                {type === "DEBT" ? (
                  <div>
                    <MimaDateInput
                      labelTitle="Due Date"
                      name="dueDate"
                      value={values.dueDate}
                      onChange={(text) => {
                        setFieldValue("dueDate", text);
                      }}
                      width={38}
                      onBlur={handleBlur}
                      error={errors.dueDate}
                      touched={touched.dueDate}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {type !== "DEBT" && (
                <>
                  <MimaText variant="small" mt={2} ml={-24}>
                    Payment type
                  </MimaText>
                  <div className={styles.checks}>
                    <RCB
                      title="POS"
                      onChange={() => {
                        setFieldValue("paymentMethod", "POS");
                      }}
                      checked={values.paymentMethod === "POS"}
                      name="paymentMethod"
                    />
                    <RCB
                      title="Transfer"
                      checked={values.paymentMethod === "TRANSFER"}
                      onChange={() =>
                        setFieldValue("paymentMethod", "TRANSFER")
                      }
                      name="paymentMethod"
                    />
                    <RCB
                      title="Cash"
                      checked={values.paymentMethod === "CASH"}
                      onChange={() => setFieldValue("paymentMethod", "CASH")}
                      name="paymentMethod"
                    />
                  </div>
                  {errors.paymentMethod ? (
                    <div className={styles.error}>
                      {""}
                      {touched.paymentMethod && errors.paymentMethod}
                      {""}
                    </div>
                  ) : null}
                </>
              )}

              <MimaButton
                title="Save"
                type="submit"
                variant="regular"
                loading={loading}
                onClick={handleSubmit}
                mt={2}
                width={38}
              />
            </>
          )}
        </Formik>
      )}

      {expenseSuccess && (
        <TransactionAddedSuccess
          addAnotherExpense={addAnotherExpense}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};

export default AddExpenses;
