import React, { useMemo } from "react";
import {
  MimaButton,
  MimaDropdown,
  MimaDropZone,
  MimaInput,
  MimaMultiDropdown,
  MimaPaginatedDropdown,
  MimaTagInput,
  MimaText,
  MimaTextArea,
  Modal,
  ReceiptCheckBox as RCB,
} from "../../components";
import styles from "../../assets/styles/website/website.module.scss";
import { Field, FieldArray, Formik } from "formik";
import useUploadStyleLogic from "./useLogic/useUploadStyleLogic";
import { businessStore, userStore, websiteStore } from "../../stores";
import { RiDeleteBin5Line } from "react-icons/ri";
import { colorsArray } from "../../utils/utils";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};

const UploadStyle = ({ closeModal, refetch }) => {
  const {
    onSubmit,
    newCollections,
    setNewCollections,
    validationSchema,
    hashTags,
    setHashTags,
    loading,
  } = useUploadStyleLogic({ closeModal, refetch });

  const foreignCurrencyData = [{ key: "USD", value: "USD" }];

  const newColorsArray = colorsArray.map((x) => {
    return { key: x.code, value: x.color };
  });

  const collections = websiteStore?.collections;
  const localCurrency = businessStore.localCurrency;

  const collectionsData = useMemo(() => {
    return collections.map((x) => ({
      key: x.key,
      value: x.name,
    }));
  }, [collections]);

  const collectionSuggestion = useMemo(() => {
    return collections.map((x) => ({
      a: x.name,
    }));
  }, [collections]);
  return (
    <Modal closeModal={closeModal}>
      <>
        <MimaText
          variant="subtitleBold"
          align="center"
          color="var(--color-dark)"
          mb={4}
        >
          Upload Style
        </MimaText>
        <Formik
          initialValues={{
            name: "",
            pricingModel: "FIXED_PRICE",
            parts: [],
            imageUrls: [],
            hashTags: [],
            topView: false,
            colorsAvailable: [],
            sizes: [],
            styleCollections: [],
            createCollections: false,
            addDeliveryDuration: false,
            daysRequiredForDelivery: {
              early: 0,
              late: 0,
            },
            local: {
              withoutMaterial: 0,
              currencyCode: localCurrency,
              onSaleSlashPrice: 0,
              withMaterial: 0,
            },
            international: {
              currencyCode: "",
              withMaterial: 0,
              onSaleSlashPrice: 0,
            },
            description: "",
            gender: "MALE",
            addBookingLinkUrl: false,
            bookingLinkUrl: "",
            addCustomColor: false,
            customColors: [],
            accessories: [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <div>
              <MimaInput
                type="text"
                labelTitle="Style Name"
                placeholder="Enter style name"
                name="name"
                mb={2}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                touched={touched.name}
              />

              <div style={{ marginBottom: "2rem" }}>
                <MimaText variant="small" mb={1} mt={1}>
                  Pin Style To Top
                </MimaText>
                <div className="modal__beside">
                  <RCB
                    title="Yes"
                    onChange={() => {
                      setFieldValue("topView", true);
                    }}
                    checked={values.topView}
                    value={values.topView}
                    name="topView"
                  />
                  <RCB
                    title="No"
                    onChange={() => {
                      setFieldValue("topView", false);
                    }}
                    checked={!values.topView}
                    value={!values.topView}
                    name="topView"
                  />
                </div>
              </div>
              <div style={{ marginBottom: "2rem" }}>
                <MimaText variant="small" mb={1}>
                  Do You Want To Create New Collection ?
                </MimaText>
                <div className="modal__beside">
                  <RCB
                    title="Yes"
                    onChange={() => {
                      setFieldValue("createCollections", true);
                    }}
                    checked={values.createCollections}
                    value={values.createCollections}
                    name="createCollections"
                  />
                  <RCB
                    title="No"
                    onChange={() => {
                      setFieldValue("createCollections", false);
                    }}
                    checked={!values.createCollections}
                    value={!values.createCollections}
                    name="createCollections"
                  />
                </div>
                {errors.createCollections ? (
                  <MimaText variant="small" color="var(--color-error)">
                    {""}
                    {touched.createCollections && errors.createCollections}
                    {""}
                  </MimaText>
                ) : null}

                {!values.createCollections ? (
                  <>
                    <MimaMultiDropdown
                      labelTitle="Style Collection"
                      name="styleCollections"
                      id="styleCollections"
                      value={values.styleCollections}
                      placeholder="Select Collection"
                      currentValue={values.styleCollections}
                      onChange={(data) => {
                        setFieldValue("styleCollections", data);
                      }}
                      data={collectionsData}
                      touched={touched.styleCollections}
                      error={errors.styleCollections}
                      mt={2}
                    />
                  </>
                ) : (
                  <MimaTagInput
                    placeholder="Style Collection"
                    labelTitle="Enter New Collection"
                    tags={newCollections}
                    setTags={setNewCollections}
                    name="styleCollections"
                    onBlur={handleBlur}
                    error={errors.mobile}
                    touched={touched.mobile}
                    width={38}
                    mt={2}
                    suggestionData={collectionSuggestion}
                    handleFilter={(item, text) => {
                      return item?.a?.toLowerCase()?.includes(text);
                    }}
                  />
                )}
              </div>

              <MimaText variant="small" ml={2}>
                Gender
              </MimaText>
              <div className={styles.checks}>
                <RCB
                  title="Male"
                  id="MALE"
                  name="gender"
                  onChange={() => {
                    setFieldValue("gender", "MALE");
                  }}
                  checked={values.gender === "MALE"}
                  value="Male"
                />
                <RCB
                  title="Female"
                  onChange={() => {
                    setFieldValue("gender", "FEMALE");
                  }}
                  checked={values.gender === "FEMALE"}
                  id="FEMALE"
                  name="gender"
                  value="Female"
                />
                <RCB
                  title="Unisex"
                  id="UNISEX"
                  onChange={() => {
                    setFieldValue("gender", "UNISEX");
                  }}
                  checked={values.gender === "UNISEX"}
                  name="gender"
                  value="UNISEX"
                />
              </div>
              <div>
                <MimaText variant="small" ml={2}>
                  Pricing Model
                </MimaText>
                <div className={styles.checks}>
                  <RCB
                    title="Fixed Price"
                    name="pricingModel"
                    onChange={() => {
                      setFieldValue("pricingModel", "FIXED_PRICE");
                    }}
                    checked={values.pricingModel === "FIXED_PRICE"}
                    value="FIXED_PRICE"
                  />
                  <RCB
                    title="Bespoke"
                    onChange={() => {
                      setFieldValue("pricingModel", "BESPOKE");
                    }}
                    checked={values.pricingModel === "BESPOKE"}
                    name="pricingModel"
                    value="BESPOKE"
                  />
                </div>
              </div>
              <div className={styles.dropZone}>
                <MimaDropZone
                  title="Click to upload style image"
                  info="Min of 1 image and Max of 4 images"
                  maxNumOfImages={4}
                  files={values.imageUrls}
                  setFiles={(newFiles) => {
                    setFieldValue("imageUrls", newFiles);
                  }}
                  labelTitle="Upload Image"
                  name="value"
                />
              </div>
              <FieldArray name="sizes">
                {({ push, remove }) => (
                  <React.Fragment>
                    {values.sizes.map((_, i) => (
                      <div key={i} className={styles.sizesRow}>
                        <Field
                          name={`sizes.${i}`}
                          id={`sizes.${i}`}
                          component={FieldInput}
                          type="number"
                          placeholder="e.g 10"
                          labelTitle="Size"
                          variant="form"
                          width={10}
                        />

                        {i !== 0 ? (
                          <div className={styles.measurement_delete}>
                            <RiDeleteBin5Line
                              onClick={() => remove(i)}
                              color="var(--color-error)"
                              size={20}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    <MimaButton
                      title={
                        values.sizes.length === 0
                          ? "Add Standard Size"
                          : "Add Another Size"
                      }
                      variant="outlined"
                      type="button"
                      height={3}
                      mt={1}
                      mb={3}
                      onClick={() => push("")}
                    />
                  </React.Fragment>
                )}
              </FieldArray>

              <MimaMultiDropdown
                labelTitle="Style Colors"
                name="colorsAvailable"
                id="colorsAvailable"
                value={values.colorsAvailable}
                placeholder="Select Colors"
                currentValue={values.colorsAvailable}
                onChange={(data) => {
                  setFieldValue("colorsAvailable", data);
                }}
                data={newColorsArray}
                touched={touched.colorsAvailable}
                error={errors.colorsAvailable}
                mt={2}
                mb={2}
              />
              {/* <>
                <MimaText variant="small" mb={2} mt={2} align="center">
                  Add standard Size
                </MimaText>
                <div className="modal__beside">
                  <MimaDropdown
                    labelTitle="Country"
                    placeholder="Select Country"
                    name="country"
                    value={values.standardSize?.[0]?.country}
                    id={values.standardSize?.[0]?.country}
                    onChange={(data) => {
                      setFieldValue(`standardSize?${0}?.country`, data.key);
                    }}
                    data={[
                      { key: "US", value: "US" },
                      { key: "UK", value: "UK" },
                      { key: "EUROPE", value: "EUROPE" },
                      { key: "CHINA", value: "CHINA" },
                    ]}
                    touched={touched?.standardSize?.[0]?.country}
                    width={18}
                    mb={2}
                    variant="form"
                  />

                  <MimaInput
                    type="text"
                    labelTitle="Size"
                    name="standardSize.value"
                    id="standardSize.value"
                    placeholder="Enter size"
                    value={values.standardSize?.[0]?.value}
                    onChange={handleChange}
                    error={errors?.standardSize?.[0]?.value}
                    touched={touched?.standardSize?.[0]?.value}
                    onBlur={handleBlur}
                    width={18}
                    mb={2}
                    variant="form"
                  />
                </div>
              </> */}
              <div style={{ marginBottom: "2rem" }}>
                <MimaText variant="small" mb={1}>
                  Add Custom Colors or Color combinations
                </MimaText>
                <div className="modal__beside">
                  <RCB
                    title="Yes"
                    onChange={() => {
                      setFieldValue("addCustomColor", true);
                    }}
                    checked={values.addCustomColor}
                    value={values.addCustomColor}
                    name="addCustomColor"
                  />
                  <RCB
                    title="No"
                    onChange={() => {
                      setFieldValue("addCustomColor", false);
                    }}
                    checked={!values.addCustomColor}
                    value={!values.addCustomColor}
                    name="addCustomColor"
                  />
                </div>
                {errors.addCustomColor ? (
                  <MimaText variant="small" color="var(--color-error)">
                    {""}
                    {touched.addCustomColor && errors.addCustomColor}
                    {""}
                  </MimaText>
                ) : null}
              </div>

              {values.addCustomColor ? (
                <MimaTagInput
                  placeholder="Enter custom colors"
                  labelTitle="Custom Colors"
                  tags={values.customColors}
                  setTags={(custom) => {
                    setFieldValue("customColors", custom);
                  }}
                  name="customColors"
                  onBlur={handleBlur}
                  error={errors.mobile}
                  touched={touched.mobile}
                  width={38}
                  mt={2}
                  // suggestionData={collectionSuggestion}
                  // handleFilter={(item, text) => {
                  //   return item?.a?.toLowerCase()?.includes(text);
                  // }}
                />
              ) : (
                ""
              )}

              <MimaTagInput
                placeholder="Add Accessories Types"
                labelTitle="Accessory Informations"
                tags={values.accessories}
                setTags={(item) => {
                  setFieldValue("accessories", item);
                }}
                name="accessories"
                onBlur={handleBlur}
                error={errors.mobile}
                touched={touched.mobile}
                width={38}
                mt={2}
                // suggestionData={collectionSuggestion}
                // handleFilter={(item, text) => {
                //   return item?.a?.toLowerCase()?.includes(text);
                // }}
              />

              {values.pricingModel === "FIXED_PRICE" && (
                <>
                  <div style={{ marginBottom: "2rem" }}>
                    <MimaText align="center" variant="small" mb={2} mt={2}>
                      Add Local Selling Price
                    </MimaText>
                    {/* <div className="modal__beside">
                      <RCB
                        title="Yes"
                        onChange={() => {
                          setFieldValue("addLocalSellingPrice", true);
                        }}
                        checked={values.addLocalSellingPrice}
                        value={values.addLocalSellingPrice}
                        name="addLocalSellingPrice"
                      />
                      <RCB
                        title="No"
                        onChange={() => {
                          setFieldValue("addLocalSellingPrice", false);
                        }}
                        checked={!values.addLocalSellingPrice}
                        value={!values.addLocalSellingPrice}
                        name="addLocalSellingPrice"
                      />
                    </div> */}

                    <>
                      {/* <MimaInput
                          labelTitle="Local Currency"
                          name={`local.currencyCode`}
                          value={values.local.currencyCode}
                          placeholder={values.local.currencyCode}
                          id={`local.currencyCode`}
                          currentValue={values.local.currencyCode}
                          disabled
                          width={18}
                          variant="form"
                          mt={2}
                        /> */}

                      <div className="modal__beside">
                        <MimaInput
                          type="number"
                          labelTitle="Ready To wear Price(RTW)"
                          name={`local.withMaterial`}
                          id={`local.withMaterial`}
                          value={values.local.withMaterial}
                          onChange={handleChange}
                          error={errors?.local?.withMaterial}
                          touched={touched?.local?.withMaterial}
                          onBlur={handleBlur}
                          width={18}
                        />

                        <MimaInput
                          type="number"
                          labelTitle="Price With Customer's Material"
                          name={`local.withoutMaterial`}
                          id={`local.withoutMaterial`}
                          value={values.local.withoutMaterial}
                          onChange={handleChange}
                          error={errors?.local?.withoutMaterial}
                          touched={touched?.local?.withoutMaterial}
                          onBlur={handleBlur}
                          width={18}
                        />
                      </div>
                      <MimaInput
                        type="number"
                        labelTitle="Sales Slash Price"
                        name={`local.onSaleSlashPrice`}
                        id={`local.onSaleSlashPrice`}
                        value={values.local.onSaleSlashPrice}
                        onChange={handleChange}
                        error={errors?.local?.onSaleSlashPrice}
                        touched={touched?.local?.onSaleSlashPrice}
                        onBlur={handleBlur}
                      />
                    </>
                  </div>
                  <div style={{ marginBottom: "2rem" }}>
                    <MimaText variant="small" mb={1} mt={1}>
                      Sell This Style Abroad
                    </MimaText>
                    <div className="modal__beside">
                      <RCB
                        title="Yes"
                        onChange={() => {
                          setFieldValue("sellAbroad", true);
                        }}
                        checked={values.sellAbroad}
                        value={values.sellAbroad}
                        name="sellAbroad"
                      />
                      <RCB
                        title="No"
                        onChange={() => {
                          setFieldValue("sellAbroad", false);
                        }}
                        checked={!values.sellAbroad}
                        value={!values.sellAbroad}
                        name="sellAbroad"
                      />
                    </div>
                    {values.sellAbroad && (
                      <>
                        <MimaDropdown
                          labelTitle="Select Currency"
                          name={`international.currencyCode`}
                          value={values.international.currencyCode}
                          placeholder={values.international.currencyCode}
                          id={`international.currencyCode`}
                          currentValue={values.international.currencyCode}
                          onChange={(data) => {
                            setFieldValue(
                              `international.currencyCode`,
                              data.key
                            );
                          }}
                          data={foreignCurrencyData}
                          touched={touched?.international?.currencyCode}
                          error={errors?.international?.currencyCode}
                          width={18}
                          mt={2}
                          variant="form"
                        />

                        <div className="modal__beside">
                          <MimaInput
                            type="number"
                            labelTitle="International Price"
                            name={`international.withMaterial`}
                            id={`international.withMaterial`}
                            value={values.international.withMaterial}
                            onChange={handleChange}
                            error={errors?.international?.withMaterial}
                            touched={touched?.international?.withMaterial}
                            onBlur={handleBlur}
                            width={18}
                          />
                          <MimaInput
                            type="number"
                            labelTitle="Sales Slash Price"
                            name={`international.onSaleSlashPrice`}
                            id={`international.onSaleSlashPrice`}
                            value={values.international.onSaleSlashPrice}
                            onChange={handleChange}
                            error={errors?.international?.onSaleSlashPrice}
                            touched={touched?.international?.onSaleSlashPrice}
                            onBlur={handleBlur}
                            width={18}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              <MimaText variant="small" mb={0.5} mt={1} align="center">
                Add Delivery Duration
              </MimaText>
              <div className="modal__beside">
                <MimaInput
                  type="number"
                  labelTitle="Min Est. Duration"
                  name={`daysRequiredForDelivery.early`}
                  id={`daysRequiredForDelivery.early`}
                  value={values.daysRequiredForDelivery.early}
                  onChange={handleChange}
                  error={errors?.daysRequiredForDelivery?.early}
                  touched={touched?.daysRequiredForDelivery?.early}
                  width={18}
                  mt={2}
                />

                <MimaInput
                  type="number"
                  labelTitle="Max Est. Duration"
                  name={`daysRequiredForDelivery.late`}
                  id={`daysRequiredForDelivery.late`}
                  value={values.daysRequiredForDelivery.late}
                  onChange={handleChange}
                  error={errors?.daysRequiredForDelivery?.late}
                  touched={touched?.daysRequiredForDelivery?.late}
                  onBlur={handleBlur}
                  width={18}
                  mt={2}
                />
              </div>
              {/* <div style={{ marginBottom: "2rem" }}>
                <MimaText variant="small" mb={1} mt={1}>
                  Add Delivery Duration
                </MimaText>
                <div className="modal__beside">
                  <RCB
                    title="Yes"
                    onChange={() => {
                      setFieldValue("addDeliveryDuration", true);
                    }}
                    checked={values.addDeliveryDuration}
                    value={values.addDeliveryDuration}
                    name="addDeliveryDuration"
                  />
                  <RCB
                    title="No"
                    onChange={() => {
                      setFieldValue("addDeliveryDuration", false);
                    }}
                    checked={!values.addDeliveryDuration}
                    value={!values.addDeliveryDuration}
                    name="addDeliveryDuration"
                  />
                </div>
                {values.addDeliveryDuration && (
                  <div className="modal__beside">
                    <MimaInput
                      type="number"
                      labelTitle="Min Est. Duration"
                      name={`daysRequiredForDelivery.early`}
                      id={`daysRequiredForDelivery.early`}
                      value={values.daysRequiredForDelivery.early}
                      onChange={handleChange}
                      error={errors?.daysRequiredForDelivery?.early}
                      touched={touched?.daysRequiredForDelivery?.early}
                      width={18}
                      mt={2}
                    />

                    <MimaInput
                      type="number"
                      labelTitle="Max Est. Duration"
                      name={`daysRequiredForDelivery.late`}
                      id={`daysRequiredForDelivery.late`}
                      value={values.daysRequiredForDelivery.late}
                      onChange={handleChange}
                      error={errors?.daysRequiredForDelivery?.late}
                      touched={touched?.daysRequiredForDelivery?.late}
                      onBlur={handleBlur}
                      width={18}
                      mt={2}
                    />
                  </div>
                )}
              </div> */}
              <div style={{ marginBottom: "2rem" }}>
                <MimaText variant="small" mb={1} mt={1}>
                  Add Booking Link
                </MimaText>
                <div className="modal__beside">
                  <RCB
                    title="Yes"
                    onChange={() => {
                      setFieldValue("addBookingLinkUrl", true);
                    }}
                    checked={values.addBookingLinkUrl}
                    value={values.addBookingLinkUrl}
                    name="addBookingLinkUrl"
                  />
                  <RCB
                    title="No"
                    onChange={() => {
                      setFieldValue("addBookingLinkUrl", false);
                    }}
                    checked={!values.addBookingLinkUrl}
                    value={!values.addBookingLinkUrl}
                    name="addBookingLinkUrl"
                  />
                </div>
                {values.addBookingLinkUrl && (
                  <div className="modal__beside">
                    <MimaPaginatedDropdown
                      mb={2}
                      mt={2}
                      labelTitle="Booking Link"
                      placeholder="Select Booking link"
                      name="bookingLinkUrl"
                      value={values.bookingLinkUrl}
                      id="bookingLinkUrl"
                      optionLabel="title"
                      touched={touched.bookingLinkUrl}
                      error={errors.bookingLinkUrl}
                      url="/banking/booking-links"
                      queryKey={[userStore.bId, "bookingLinkUrls"]}
                      getPageValue={(page) => page?.data?.value || []}
                      getPageTotalCount={(lastPage) =>
                        lastPage?.data?.totalCounts || 0
                      }
                      onChange={(data) => {
                        setFieldValue("bookingLinkUrl", data._id);
                        setFieldValue("bookingLinkUrlName", data.title);
                      }}
                    />
                  </div>
                )}
              </div>
              <MimaTagInput
                placeholder="Enter Hashtags"
                labelTitle="Hashtags"
                tags={hashTags}
                setTags={setHashTags}
                name="hashTags"
                onBlur={handleBlur}
                error={errors.mobile}
                touched={touched.mobile}
                width={38}
                mt={2}
                mb={2}
              />

              <MimaTextArea
                type="textarea"
                labelTitle="Description"
                placeholder="description"
                name="description"
                mb={2}
                variant="message"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <MimaButton
                title="Save"
                loading={loading}
                variant="regular"
                type="submit"
                onClick={handleSubmit}
                width={38}
              />
            </div>
          )}
        </Formik>
      </>
    </Modal>
  );
};

export default UploadStyle;
