import { css } from "styled-components";

export const base = css`
  font-size: 1.8rem;
  font-family: inherit;
  color: var(--color-grey);
  padding-left: 2rem;
  height: 4.8rem;
  margin-top: 0.4rem;
  border-radius: 0.8rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-dark);
  display: block;
  box-sizing: border-box;

  &:focus,
  &:focus-visible,
  &:focus-within {
    color: var(--color-dark);
    border: 2px solid var(--color-dark);
    outline: none;
    box-shadow: inset 2px 2px 3px #b0c1e2;
  }
`;

export const medium = css`
  width: 38rem;
  margin-bottom: 0.5rem;
`;
export const error = css`
  width: 38rem;
  border: 2px solid var(--color-error);
  outline: none;
  margin-bottom: 0.5rem;
`;

export const exchange = css`
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--color-dark);
  width: 15rem;
  padding-left: 0;
  height: auto;

  &:focus,
  &:focus-visible,
  &:focus-within {
    border: none;
    border-radius: 0;
    color: var(--color-dark);
    border-bottom: 1px solid var(--color-dark);
  }
`;

export const wide = css`
  width: 60rem;

  @media only screen and (max-width: 31.25em) {
    width: 38rem;
  }
`;

export const form = css`
  width: 100%;
`;

export const formError = css`
  width: 100%;
  border: 2px solid var(--color-error);
  outline: none;
`;

export const form2 = css`
  width: 100%;
  height: 4.5rem;
  overflow-wrap: break-word;
  font-size: var(--default-font-small);
  padding: 1rem;
  padding-left: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--color-dark);

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: 1px solid var(--color-dark);
  }
`;

export const mobile = css`
  width: 35rem;
`;

export const income = css`
  width: 17.9rem;
`;

export const resize = css`
  width: 35rem;
  margin-left: 8.3rem;
  margin-bottom: 2rem;
`;

export const message = css`
  width: 38rem;
  height: 12rem;
  overflow-wrap: break-word;
  hyphens: auto;
  padding: 2rem;
`;

export const type1 = css`
  width: 38rem;
  overflow-wrap: break-word;
  padding-left: 2rem;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }
`;

export const type2 = css`
  width: 100%;
  min-height: 17rem;
  max-height: 50rem;
  overflow-wrap: break-word;
  font-size: var(--default-font-small);
  padding: 1rem;
  padding-left: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--color-dark);

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: 1px solid var(--color-dark);
  }
`;

export const type3 = css`
  width: 100%;
  min-height: 10rem;
  max-height: 25rem;
  overflow-wrap: break-word;
  font-size: var(--default-font-small);
  padding: 1rem;
  padding-left: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--color-dark);

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: 1px solid var(--color-dark);
  }
`;

export const textArea = css`
  font-size: 1.8rem;
  font-family: inherit;
  color: var(--color-grey);
  width: 38rem;
  min-height: 6rem;
  max-height: 50rem;
  margin-top: 0.5rem;
  border-radius: 1rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-dark);
  display: block;
  box-sizing: border-box;
  padding: 2rem;
  line-height: 3rem;

  &:focus,
  &:focus-visible,
  &:focus-within {
    color: var(--color-dark);
    border: 1px solid var(--color-dark);
  }

  &::-webkit-scrollbar {
    width: 1rem;
    background-color: var(--color-white);
    border-radius: 0 3rem 3rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 3rem;
  }
`;

export const tagInput = css`
  font-size: 1.4rem;
  font-family: inherit;
  color: var(--color-grey);
  margin-top: 0.5rem;
  background-color: var(--color-white);
  display: block;
  box-sizing: border-box;
  width: 80%;
  outline: none;
  border: none;
  padding: 1rem 1rem;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: none;
  }
`;

export const divBase = css`
  width: 100%;
`;

export const innerDivBase = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--color-dark);
  border-radius: 1rem;
  padding: 1rem;
  min-height: 4rem;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    color: var(--color-dark);
    outline: none;
    border: 1px solid var(--color-dark);
  }
`;

export const sideBase = css`
  display: flex;
`;

export const pwIcon = css`
  position: relative;

  & > :nth-child(2) {
    position: absolute;
    bottom: 0.8rem;
    right: 0;
  }
`;
export const inputDrop = css`
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
`;
export const drop = css`
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: var(--color-grey-3);
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;

  img {
    width: 3rem;
  }
`;
export const dropInput = css`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  padding: 1rem 1rem;
  padding-left: 2rem;
  width: 100%;
  font-size: 1.8rem;
  font-family: inherit;
  height: 4.8rem;
  margin-top: 0.4rem;
  display: block;
  box-sizing: border-box;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;
export const dropError = css`
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  outline: none;
  border: none;
`;
export const dropBody = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid var(--color-grey-3);
  transition: all 0.4s;
  order: 1;
  max-height: 27rem;
  overflow-y: scroll;
  z-index: 500;
  min-width: 100%;
  left: 0;

  &::-webkit-scrollbar {
    width: 1rem;
    background-color: var(--color-white);
    border-radius: 0 1rem 1rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 1rem;
  }
`;
export const dropItem = css`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  cursor: pointer;

  &: hover {
    background-color: var(--color-fash-green-1);
  }

  img {
    width: 3rem;
  }
`;

export const expressBase = css`
  border: 1px solid var(--color-fash-green-1);
  padding: 1rem;
  position: relative;
  border-radius: 1rem;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
