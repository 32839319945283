import React from "react";
import { MimaText, Modal } from "../../components";

const ViewMessage = ({ closeModal, item }) => {
  return (
    <Modal closeModal={closeModal}>
      <MimaText
        variant="subtitleBold"
        align="center"
        mb={4}
      >{`Message from ${item.fullname}`}</MimaText>
      <MimaText variant="body">{item.message}</MimaText>
    </Modal>
  );
};

export default ViewMessage;
