import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { quotationStore } from '../stores';
import moment from 'moment';
import TransactionsController from './TransactionsController';

const getQuotations = async (query = '') => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/invoices/quotation?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		let quotes = [];
		data.quotations.forEach(quote => {
			quotes.push(...quote.details);
		});
		const sortedQuotes = quotes.sort(
			(objA, objB) => Number(objA.number) - Number(objB.number)
		);
		const quoteList = sortedQuotes.reverse();
		quotationStore.setQuotations(quoteList);
		return { status: constant.Success };
	}
	return { status, errorMessage };
};

const createQuotation = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/invoices/quotation',
		method: 'post',
		payload,
	});

	if (status === constant.Success) {
		await Promise.all([
			getQuotations(),
			// getAnalysis(),
			TransactionsController.transactions(),
			TransactionsController.transactionSummary(),
		]);

		return { status: constant.Success };
	}


	return { errorMessage, status };
};


const sendQuotation = async id =>
	await apiRequest({
		url: `/invoices/quotes/send/${id}`,
		method: 'get',
	});


const updateQuotation = async (payload, id) => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/invoices/quotation/${id}`,
		method: 'put',
		payload,
	});
	if (status === constant.Success) {
		// quotationStore.setSelectedInvoice(data);
		await Promise.all([
			getQuotations(),
			// getAnalysis(),			
		]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};




export default {
	getQuotations,
	createQuotation,
	sendQuotation,
	updateQuotation,
}


