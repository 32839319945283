import TransactionsController from './TransactionsController';
import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { invoiceStore, bookingStore } from '../stores';
import moment from 'moment';

const createBookingLink = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/banking/create-booking-link',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await getAllBookingLinks();
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const getAllBookingLinks = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/banking/booking-links',
		method: 'get',
	});

	if (status === constant.Success) {
		bookingStore.setAllBookingLinks(data.value);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const getAllBookings = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/banking/bookings',
		method: 'get',
	});

	if (status === constant.Success) {
		bookingStore.setAllBookings(data.value);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const editBookingLink = async (id, payload) => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/banking/edit-booking-link/${id}`,
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await getAllBookingLinks();
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const bookingLinkUnavailableDates = async (id) => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/banking/booking-link-unavailable-dates/${id}`,
		method: 'get',
	});
	if (status === constant.Success) {
		bookingStore.setBookingLinkUnavailableDates(data);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};


const deleteBookingLink = async id => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/banking/booking-link?id=${id}`,
		method: 'delete',
	});
	if (status === constant.Success) {
		await getAllBookingLinks();
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const getBookingLinkDetails = async id => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/banking/booking-link-details/${id}`,
		method: 'get',
	});

	if (status === constant.Success) {
		bookingStore.setBookingLinkDetails(data);
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

const createBookingLinkInvoice = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/invoices/accept-booking-invoice',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		return { status: constant.Success, data };		
	}
	return { errorMessage, status };
};

export default {
	createBookingLink,
	getAllBookingLinks,
	editBookingLink,
	deleteBookingLink,
	getBookingLinkDetails,
	createBookingLinkInvoice,
	bookingLinkUnavailableDates,
	getAllBookings
};
