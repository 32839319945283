import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class TransactionStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'TransactionStore',
			properties: [
				'transactions',
				'walletTransactions',
				'offlineTransactions',
				'weekSummary',
				'permissions',
				'transactionsSummary',
				'expenseAnalysis',
				'subWalletTransactions',
				'selectedTransaction',
				'splitAnalysis',
				'selectedSplitTrans',
				'selectedIncome',
				'tag',
				'tagQuery',
				'untagged',
			],
		});
	}
	loading = false;
	selectedTransaction = {};
	transactions = [];
	splitAnalysis = {};
	permissions = [];
	walletTransactions = [];
	offlineTransactions = [];
	weekSummary = {};
	transactionsSummary = [];
	expenseAnalysis = [];
	subWalletTransactions = [];
	selectedSplitTrans = {};
	selectedIncome = {};
	tag = {}
	tagQuery = false;
	untagged = {};

	reset = () => {
		this.transactions = [];
		this.walletTransactions = [];
		this.offlineTransactions = [];
		this.permissions = [];
		this.weekSummary = {};
		this.transactionsSummary = [];
		this.expenseAnalysis = [];
		this.splitAnalysis = {};
		this.subWalletTransactions = [];
		this.selectedTransaction = {};
		this.selectedIncome = {};
		this.selectedSplitTrans = {};
		this.tag = {};
		this.tagQuery = false;
		this.untagged = {};
	};

	setLoading = async isLoading => {
		runInAction(() => {
			this.loading = isLoading;
		});
	};
	setExpenseAnalysis = async data => {
		runInAction(async () => {
			this.expenseAnalysis = data;
		});
	};
	setSplitAnalysis = async data => {
		runInAction(async () => {
			this.splitAnalysis = data;
		});
	};
	setSelectedSplitTrans = async data => {
		runInAction(async () => {
			this.selectedSplitTrans = data;
		});
	};
	setPermissions = async data => {
		runInAction(async () => {
			this.permissions = data;
		});
	};
	setSelectedTransaction = async transaction => {
		runInAction(async () => {
			this.selectedTransaction = transaction;
		});
	};
	setTransactions = async transactions => {
		runInAction(async () => {
			this.transactions = transactions;
		});
	};
	setSubWalletTransactions = async transactions => {
		runInAction(async () => {
			this.subWalletTransactions = transactions;
		});
	};

	setWalletTransactions = async transactions => {
		runInAction(async () => {
			this.walletTransactions = transactions;
		});
	};

	setOfflineTransactions = async transactions => {
		runInAction(async () => {
			this.offlineTransactions = transactions;
		});
	};

	setWeeklySummary = async summary => {
		runInAction(async () => {
			this.weekSummary = summary;
		});
	};
	setTransactionsSummary = async summary => {
		runInAction(async () => {
			this.transactionsSummary = summary;
		});
	};
	setSelectedIncome = async transaction => {
		runInAction(async () => {
			this.selectedIncome = transaction;
		});
	};
	setTag = async state => {
		runInAction(async () => {
			this.tag = state;
		});
	};
	setTagQuery = async state => {
		runInAction(async () => {
			this.tagQuery = state;
		});
	};
	setUntagged = async state => {
		runInAction(async () => {
			this.untagged = state;
		});
	};
}
