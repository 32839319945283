/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  ReceiptCheckBox as RCB,
  Modal,
  MimaTextArea,
  MimaDateInput,
  MimaPaginatedDropdown,
} from "../../components";

import styles from "../../assets/styles/Quotation/Quotation.module.scss";
import { businessStore, customerStore } from "../../stores";
import React, { useEffect, useRef, useState } from "react";
import { amountFormatter, currencies } from "../../utils/utils";
import { Formik, Field, FieldArray } from "formik";
import { FaHandPointRight } from "react-icons/fa";
import InvoiceSummary from "./InvoiceSummary";
import useNewQuotationLogic from "./useLogic/useNewQuotationLogic";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};

const FieldTextArea = ({ field, ...props }) => {
  return <MimaTextArea {...field} {...props} />;
};

const FieldPaginatedDropdown = ({ field, ...props }) => {
  return <MimaPaginatedDropdown {...field} {...props} />;
};

const NewQuotation = ({ closeModal }) => {
  const {
    invoiceDetailsValidation,
    loading,
    balanceAmount,
    stage2,
    orders,
    invoice,
    onSubmit,
    invoiceItem,
    discountAmountCal,
    discountPerCal,
    depositPerCal,
    depositAmountCal,
    newCustomer,
    createNewCustomer,
    customerDetailsValidation,
    onSubmitCustomer,
    goBack,
  } = useNewQuotationLogic({ closeModal });
  const isCopied = Object.keys(invoice).length > 0;
  const [values, setValues] = useState({});
  const [discountStatus, setDiscountStatus] = useState(false);
  const [depositStatus, setDepositStatus] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const discountPer = discountPerCal(values);
    ref?.current &&
      ref.current.setFieldValue("discountPer", discountPer?.toFixed(2));
  }, [values.discountAmount, values.orders, values.tax, values.wht]);

  useEffect(() => {
    if (depositStatus) {
      const deposit = depositAmountCal(values);
      ref?.current && ref.current.setFieldValue("deposit", deposit?.toFixed(2));
    } else {
      const depositPer = depositPerCal(values);
      ref?.current &&
        ref.current.setFieldValue("depositPer", depositPer?.toFixed(2));
    }
  }, [
    values.depositPer,
    values.discountPer,
    values.discountAmount,
    values.deposit,
    values.shipping,
    values.orders,
    values.tax,
    values.wht,
  ]);

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {!newCustomer && (
        <>
          <MimaText
            variant="subtitle"
            color="var(--color-fash-primary-3)"
            align="center"
            mb={4}
          >
            {invoiceItem && isCopied
              ? "Duplicate Quotation"
              : "Create Quotation"}
          </MimaText>
          <Formik
            innerRef={ref}
            initialValues={{
              customer: "",
              customerName: "",
              orders: isCopied
                ? orders
                : [
                    {
                      quantity: 0,
                      unitPrice: 0,
                      item: "",
                      stock: "",
                    },
                  ],
              vat: !!(invoice?.vat && invoice.vat?.amount),
              wht: !!(invoice?.wht && invoice.wht?.amount),
              selectFromStock: false,
              dueDate: "",
              balanceDueDate: "",
              currencyCode: "NGN",
              discountAmount: 0,
              paymentMethod: "",
              note: "",
              deposit: 0,
              discountPer: 0,
              depositPer: 100,
              shipping: 0,
            }}
            validationSchema={invoiceDetailsValidation}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => {
              return (
                <div className={styles.container}>
                  <MimaPaginatedDropdown
                    mb={2}
                    labelTitle="Bill To"
                    placeholder="Select Customer"
                    name="customer"
                    value={values.customerName}
                    id="customer"
                    optionLabel="fullname"
                    touched={touched.customer}
                    error={errors.customer}
                    url="/customers/business"
                    queryKey={[businessStore.bId, "customers"]}
                    getPageValue={(page) => page?.data?.customers || []}
                    getPageTotalCount={(lastPage) =>
                      lastPage?.data?.totalCounts || 0
                    }
                    onChange={(data) => {
                      setFieldValue("customer", data._id);
                      setFieldValue("customerName", data.fullname);
                    }}
                  />

                  <MimaText
                    variant="smallBold"
                    color="var(--color-fash-primary-3)"
                    align="center"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    mb={2}
                    onClick={createNewCustomer}
                  >
                    <FaHandPointRight
                      style={{ fontSize: "3rem", marginRight: "1rem" }}
                    />{" "}
                    Click here if Quotation is for a new Customer
                  </MimaText>
                  <MimaDropdown
                    labelTitle="Select Currency"
                    name="currencyCode"
                    value={values.currencyCode}
                    placeholder={values.currencyCode}
                    id="currencyCode"
                    currentValue={values.currencyCode}
                    onChange={(data) => {
                      setFieldValue("currencyCode", data.key);
                    }}
                    data={currencies}
                    touched={touched.currencyCode}
                    error={errors.currencyCode}
                    mb={3}
                  />
                  <RCB
                    title="Select Product from Stock"
                    onChange={(e) => {
                      setFieldValue("selectFromStock", e.target.checked);
                      setFieldValue("orders", [
                        {
                          quantity: 1,
                          unitPrice: 0,
                          item: "",
                          stock: "",
                        },
                      ]);
                    }}
                    checked={values.selectFromStock}
                    value={String(values.selectFromStock)}
                    name="selectFromStock"
                  />
                  <FieldArray name="orders">
                    {({ push, remove }) => (
                      <React.Fragment>
                        {values.orders.map((_, i) => (
                          <div key={i}>
                            {!values.selectFromStock && (
                              <Field
                                name={`orders.${i}.item`}
                                id={`orders.${i}.item`}
                                component={FieldTextArea}
                                type="text"
                                placeholder="Product/Service"
                                labelTitle="Product/Service"
                                mt={2}
                                touched={touched?.orders?.[i]?.item}
                                error={errors?.orders?.[i]?.item}
                              />
                            )}

                            {values.selectFromStock ? (
                              <Field
                                type="text"
                                mt={2}
                                name={`orders.${i}.item`}
                                id={`orders.${i}.item`}
                                value={values.orders[i].item}
                                queryKey={[businessStore.bId, "stock"]}
                                optionLabel="name"
                                component={FieldPaginatedDropdown}
                                labelTitle="Product/Service"
                                placeholder={
                                  values.orders[i].item || "Select from Stock"
                                }
                                touched={touched?.orders?.[i]?.item}
                                error={errors?.orders?.[i]?.item}
                                url="/products/stocks/business"
                                getPageValue={(page) => page?.data?.value || []}
                                getPageTotalCount={(lastPage) =>
                                  lastPage?.data?.totalCounts || 0
                                }
                                onChange={(data) => {
                                  setFieldValue(`orders.${i}.stock`, data._id);
                                  setFieldValue(`orders.${i}.item`, data.name);
                                  setFieldValue(
                                    `orders.${i}.unitPrice`,
                                    data.sellingPrice
                                  );
                                }}
                              />
                            ) : null}

                            <div className={styles.invoice__beside}>
                              <Field
                                name={`orders.${i}.unitPrice`}
                                component={FieldInput}
                                type="number"
                                min="0"
                                labelTitle="Price"
                                width={18}
                                // disabled={values.selectFromStock}
                                touched={touched?.orders?.[i]?.unitPrice}
                                error={errors?.orders?.[i]?.unitPrice}
                              />
                              <Field
                                name={`orders.${i}.quantity`}
                                component={FieldInput}
                                type="number"
                                min="0"
                                labelTitle="Quantity"
                                width={18}
                                touched={touched?.orders?.[i]?.quantity}
                                error={errors?.orders?.[i]?.quantity}
                              />
                            </div>
                            {i !== 0 ? (
                              <MimaButton
                                title="Remove"
                                variant="filter"
                                type="button"
                                buttonColor="var(--color-error)"
                                width={10}
                                onClick={() => remove(i)}
                              />
                            ) : (
                              ""
                            )}
                            <div className={styles.summary__line}></div>
                          </div>
                        ))}
                        <MimaButton
                          title="Add Another Item"
                          variant="text"
                          type="button"
                          onClick={() =>
                            push({
                              quantity: 0,
                              unitPrice: 0,
                              item: "",
                            })
                          }
                          width={38}
                        />
                      </React.Fragment>
                    )}
                  </FieldArray>

                  <MimaInput
                    type="number"
                    labelTitle="Shipping Fee"
                    placeholder="0.00"
                    name="shipping"
                    min={0}
                    mb={1}
                    value={values.shipping}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width={38}
                  />
                  <div className={styles.invoice__beside}>
                    <MimaInput
                      type="number"
                      labelTitle="Discount Amount"
                      placeholder="0%"
                      name="discountAmount"
                      mb={1}
                      min={0}
                      value={values.discountAmount}
                      onChange={(e) => {
                        setDiscountStatus(false);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      width={18}
                      touched={touched.discountAmount}
                      error={errors.discountAmount}
                    />
                    <MimaInput
                      type="number"
                      labelTitle="Discount %"
                      placeholder="0%"
                      name="discountPer"
                      mb={1}
                      min={0}
                      max={100}
                      value={values.discountPer}
                      disabled={true}
                      onBlur={handleBlur}
                      width={18}
                      touched={touched.discountPer}
                      error={errors.discountPer}
                    />
                  </div>

                  <div className={styles.invoice__checks_group}>
                    <MimaText variant="small">Did you charge VAT?</MimaText>
                    <div className={styles.invoice__checks}>
                      <RCB
                        title="Yes"
                        onChange={() => {
                          setFieldValue("vat", true);
                        }}
                        checked={values.vat}
                        value="true"
                        name="vat"
                      />
                      <RCB
                        title="No"
                        checked={!values.vat}
                        onChange={() => setFieldValue("vat", false)}
                        value="false"
                        name="vat"
                        mb={1}
                      />
                    </div>
                    {errors.vat ? (
                      <div className={styles.error}>
                        {""}
                        {touched.vat && errors.vat}
                        {""}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.invoice__checks_group}>
                    <MimaText variant="small">Did you deduct WHT?</MimaText>
                    <div className={styles.invoice__checks}>
                      <RCB
                        title="Yes"
                        onChange={() => {
                          setFieldValue("wht", true);
                        }}
                        checked={values.wht}
                        value="true"
                        name="wht"
                      />
                      <RCB
                        title="No"
                        checked={!values.wht}
                        onChange={() => setFieldValue("wht", false)}
                        value="false"
                        name="wht"
                        mb={1}
                      />
                    </div>
                    {errors.wht ? (
                      <div className={styles.error}>
                        {""}
                        {touched.wht && errors.wht}
                        {""}
                      </div>
                    ) : null}
                  </div>

                  <MimaDateInput
                    labelTitle="Valid Date"
                    name="dueDate"
                    value={values.dueDate}
                    onChange={(text) => {
                      setFieldValue("dueDate", text);
                    }}
                    onBlur={handleBlur}
                    touched={touched.dueDate}
                    error={errors.dueDate}
                    width={38}
                  />

                  <div className={styles.invoice__beside}>
                    <MimaButton
                      title={`Total amount: ${amountFormatter(
                        values.currencyCode
                      ).format(balanceAmount(values))}`}
                      variant="text"
                      type="button"
                      // width={balanceAmount(values) > 0 ? 18 : 38}
                      width="38"
                    />
                  </div>
                  {errors.paymentMethod ? (
                    <div className={styles.error}>
                      {touched.paymentMethod && errors.paymentMethod}
                    </div>
                  ) : null}
                  <div>
                    <MimaTextArea
                      type="text"
                      labelTitle="Note or Terms and Conditions"
                      name="note"
                      variant="wide"
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      height={10}
                      width={38}
                    />
                  </div>

                  <MimaButton
                    title="Save"
                    onClick={handleSubmit}
                    type="submit"
                    loading={loading}
                    variant="form"
                    width={38}
                    mt={2}
                  />
                </div>
              );
            }}
          </Formik>
        </>
      )}
      {newCustomer && (
        <>
          <MimaText
            variant="subtitle"
            color="var(--color-fash-primary-3)"
            align="center"
          >
            Add Customer
          </MimaText>
          <Formik
            initialValues={{
              fullname: "",
              mobile: "",
              email: "",
              customerGroup: "",
              gender: "",
              address: "",
            }}
            validationSchema={customerDetailsValidation()}
            onSubmit={onSubmitCustomer}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div>
                <MimaInput
                  type="text"
                  labelTitle="Name"
                  placeholder="Enter Customer Name"
                  name="fullname"
                  mb={2}
                  value={values.fullname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.fullname}
                  touched={touched.fullname}
                />

                <MimaInput
                  type="text"
                  labelTitle="Phone Number"
                  placeholder="Customer Phone number"
                  name="mobile"
                  mb={2}
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.mobile}
                  touched={touched.mobile}
                />
                <MimaInput
                  type="email"
                  labelTitle="Email Address"
                  placeholder=" Customer Email"
                  name="email"
                  mb={2}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <MimaText variant="small" ml={2}>
                  Gender/Entity
                </MimaText>
                <div className={styles.invoice__checks}>
                  <RCB
                    title="Male"
                    id="MALE"
                    name="gender"
                    onChange={() => {
                      setFieldValue("gender", "MALE");
                    }}
                    checked={values.gender === "MALE"}
                    value="Male"
                  />
                  <RCB
                    title="Female"
                    onChange={() => {
                      setFieldValue("gender", "FEMALE");
                    }}
                    checked={values.gender === "FEMALE"}
                    id="FEMALE"
                    name="gender"
                    value="Female"
                  />
                  <RCB
                    title="Organization"
                    id="BUSINESS"
                    onChange={() => {
                      setFieldValue("gender", "BUSINESS");
                    }}
                    checked={values.gender === "BUSINESS"}
                    name="gender"
                    value="Business"
                  />
                </div>
                <MimaDropdown
                  labelTitle="Group"
                  placeholder="Select"
                  name="customerGroup"
                  value={values.customerGroup}
                  id="customerGroup"
                  onChange={(data) => {
                    setFieldValue("customerGroup", data.key);
                  }}
                  data={customerStore.mappedGroups}
                  touched={touched.customerGroup}
                />

                <MimaTextArea
                  type="textarea"
                  labelTitle="Customer Address"
                  placeholder="Customer's Address"
                  name="address"
                  mb={2}
                  variant="message"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <MimaInput
                  type="text"
                  labelTitle="Secondary Phone Number"
                  name="secondaryMobile"
                  mb={2}
                  value={values.secondaryMobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <MimaButton
                  title="Save"
                  loading={loading}
                  type="submit"
                  onClick={handleSubmit}
                  variant="form"
                />
              </div>
            )}
          </Formik>
        </>
      )}
      {stage2 && <InvoiceSummary closeModal={closeModal} />}
    </Modal>
  );
};

export default NewQuotation;
