import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from 'mobx-persist-store';
export default class SupportStore {
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: 'SupportStore', properties: ['faqs', 'contactDetails', 'contactUs'] });
    }
    faqs = [];
    contactDetails = {}
    contactUs = {}

    setFaqs = async (faqs) => {
        runInAction(async() => {
            this.faqs = faqs;
        });
    }

    setContactDetails = async (contact) => {
        runInAction(async() => {
            this.contactDetails = contact;
        });
    }
    setContactUs = async (state) => {
        runInAction(async() => {
            this.contactUs = state;
        });
    }
}
