import * as yup from "yup";
import { encodeQueryData } from "../../utils/utils";
import { MimaToastUtil } from "../../components";
import constant from "../../utils/constant";
import { useState } from "react";
import { OrderController } from "../../controllers";

const useUpdateOrdersLogic = (closeModal) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = () =>
    yup.object({
      trackingStatus: yup.string().required("Please select status"),
      // trackingNumber: string().when("trackingStatus", {
      //   is: "SHIPPED",
      //   then: yup.string().required("Please Enter Tracking Number"),
      //   otherwise: string().optional(),
      // }),
      // shippingVendor: string().when("trackingStatus", {
      //   is: "SHIPPED",
      //   then: yup.string().required("Please Enter Shipping Vendors Name"),
      //   otherwise: string().optional(),
      // }),
      // shippingTrackingUrl: string().when("trackingStatus", {
      //   is: "SHIPPED",
      //   then: yup
      //     .string()
      //     .required("Please Enter Shipping Tracking web Link or Url"),
      //   otherwise: string().optional(),
      // }),
      // expectedDeliveryDate: string().when("trackingStatus", {
      //   is: "SHIPPED",
      //   then: yup.string().required("Please Enter Shipping Delivery date"),
      //   otherwise: string().optional(),
      // }),
    });

  const onSubmit = async (values) => {
    const query = {
      ...(values?.trackingNumber && { trackingNumber: values?.trackingNumber }),
      ...(values?.shippingVendor && { shippingVendor: values?.shippingVendor }),
      ...(values?.shippingTrackingUrl && {
        shippingTrackingUrl: values?.shippingTrackingUrl,
      }),
      ...(values?.expectedDeliveryDate && {
        expectedDeliveryDate: values?.expectedDeliveryDate,
      }),
    };

    let encodeQuery;

    if (values.trackingStatus === "SHIPPED") {
      encodeQuery = encodeQueryData({
        ...query,
      });
    } else {
      encodeQuery = "";
    }

    const payload = {
      trackingStatus: values.trackingStatus,
      id: values.id,
    };
    setLoading(true);
    const { status, errorMessage } = await OrderController.updateOrderStatus(
      payload,
      encodeQuery
    );
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    }

    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const status = [
    {
      key: "DELIVERED",
      value: "DELIVERED",
    },
    {
      key: "PROCESSING",
      value: "PROCCESSING",
    },
    {
      key: "REFUNDED",
      value: "REFUNDED",
    },
    {
      key: "SHIPPED",
      value: "SHIPPED",
    },
    {
      key: "VOID",
      value: "VOID",
    },
  ];

  return { validationSchema, loading, onSubmit, status };
};

export default useUpdateOrdersLogic;
