import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { removeEmptyKeys } from "../../../utils/utils";
import { UserController } from "../../../controllers";
import { userStore } from "../../../stores";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";

const useForgotPasswordLogic = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    emailMobile: yup
      .string()
      .email("Invalid email")
      .required("Email is required"),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    removeEmptyKeys(values);

    userStore.setEmail(values.emailMobile);

    const { status, errorMessage } = await UserController.forgotPassword(
      values.emailMobile
    );
    if (status === constant.Success) {
      setLoading(false);
      userStore.setOtpType(constant.OtpType.FORGETPASSWORD);
      return navigate(constant.Routes.Otp);
    }

    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  return {
    loading,
    validationSchema,
    onSubmit,
  };
};

export default useForgotPasswordLogic;
