import { MimaText, Modal } from "../../components";
import { amountFormatter } from "../../utils/utils";
import React from "react";
import { transactionStore } from "../../stores";
import moment from "moment";

const ExpenseViewMore = ({ closeModal }) => {
  const transaction = transactionStore.selectedTransaction;

  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      <MimaText
        variant="subtitleBold"
        mb={1}
        color="var(--color-fash-primary-3)"
      >
        Expense Breakdown
      </MimaText>
      <div style={{ width: "38rem" }}>
        <MimaText labelTitle="Created Date" mb={1}>
          {moment(transaction.createdAt).format("DD-MM-YYYY hh:mm")}
        </MimaText>
        <MimaText labelTitle="Narration" mb={1}>
          {transaction.narration
            ? transaction.narration
            : transaction.expenseCategory
              ? transaction.expenseCategory.name
              : "NA"}
        </MimaText>
        <MimaText labelTitle="Amount Charged" mb={1}>
          {amountFormatter(transaction.currencyCode).format(
            transaction.transactionAmount || 0
          )}
        </MimaText>
        <MimaText labelTitle="Amount Paid" mb={1}>
          {amountFormatter(transaction.currencyCode).format(
            transaction.amountPaid || 0
          )}
        </MimaText>
        <MimaText labelTitle="Bank" mb={1}>
          {transaction.bankName || "NA"}
        </MimaText>
        <MimaText labelTitle="Account Number" mb={1}>
          {transaction.receiverAccountNumber || "NA"}
        </MimaText>
        <MimaText labelTitle="Receiver Name" mb={1}>
          {transaction.receiverAccountName || "NA"}
        </MimaText>
        <MimaText labelTitle="Balance" mb={1}>
          {amountFormatter(transaction.currencyCode).format(
            transaction.balanceAmount || 0
          )}
        </MimaText>
        <MimaText labelTitle="Due Date" mb={1}>
          {transaction.dueDate
            ? moment(transaction.dueDate).format("DD-MM-YYYY hh:mm")
            : ""}
        </MimaText>
      </div>
    </Modal>
  );
};

export default ExpenseViewMore;
