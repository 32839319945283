import React, { useState } from "react";
import { SettingsController, WalletController } from "../../../controllers";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
import { businessStore, userStore, walletStore } from "../../../stores";
import moment from "moment";
import { object, number } from "yup";

const useFundWalletLogic = ({
  closeWalletModal,
  bankAccounts,
  urlSet,
  hasBusinessAccount,
}) => {
  const fundWallet = (amount) => {
    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

    const metadata = {
      user: userStore.user._id,
      type: "FUNDING",
    };
    if (userStore.user.accountType === "Business")
      metadata.business = businessStore.bId;
    return {
      email: userStore.user.email,
      amount,
      metadata,
      publicKey,
      text: "Continue",
      onSuccess: () => {
        closeWalletModal();
        setLoading(true);
        setTimeout(async () => {
          await SettingsController.runTransactionSetup();
          setLoading(false);
        }, 5000);
      },
      onClose: () => closeWalletModal(),
    };
  };

  const hasAddedCard = businessStore.business.card;

  const wallet = walletStore.selectedWallet;

  const fundWalletValidation = () => {
    return object({
      amount: number()
        .min(1, "Amount must be greater than 1")
        .required("Amount is required"),
    });
  };

  const [fundNaira, setFundNaira] = useState(false);
  const [enterPin, setEnterPin] = useState(false);
  const [fundFx, setFundFx] = useState(false);
  const [fundAmount, setFundAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");

  const timedOut = timeLeft === "Time is up!" ? true : false;

  const countdown = (minutes, seconds) => {
    let endTime, hours, mins, msLeft, time;

    function twoDigits(n) {
      return n <= 9 ? "0" + n : n;
    }

    function updateTimer() {
      msLeft = endTime - +new Date();
      if (msLeft < 1000) {
        setTimeLeft("Time is up!");
      } else {
        time = new Date(msLeft);
        hours = time.getUTCHours();
        mins = time.getUTCMinutes();
        setTimeLeft(
          (hours ? hours + ":" + twoDigits(mins) : mins) +
            ":" +
            twoDigits(time.getUTCSeconds())
        );
        setTimeout(updateTimer, time.getUTCMilliseconds() + 500);
      }
    }

    endTime = +new Date() + 1000 * (60 * minutes + seconds) + 500;
    updateTimer();
  };

  const onSubmitAmount = async (values) => {
    setFundAmount(values.amount);
    if (values.walletCurrency === "NGN") {
      setLoading(true);
      if (hasBusinessAccount === undefined) {
        walletStore.setDynamicAccountNumbers({});
        setTimeLeft("Time is up!");
        const { status } =
          await WalletController.getDynamicWalletFundingAccount();
        if (status === constant.Success) {
          const expiryDate = moment(
            walletStore.dynamicAccountNumbers.expiryDate,
            "YYYY-MM-DDThh:mm:ss:sssZ"
          );
          const currentDate = moment(new Date(), "YYYY-MM-DDThh:mm:ss:sssZ");
          const timeDiff = expiryDate.diff(currentDate, "seconds");
          const mins = Math.floor(timeDiff / 60);
          const secs = timeDiff % 60;

          countdown(mins, secs);
        }
      }
      setFundNaira(true);
      setLoading(false);
    } else {
      setFundFx(true);
    }
  };

  const goBack = () => {
    if (fundNaira) {
      setFundNaira(false);
      setFundFx(false);
    } else if (enterPin) {
      setEnterPin(false);
    } else {
      closeWalletModal();
    }
  };

  const fundFxWallet = async (value) => {
    setLoading(true);
    let payload = {};

    payload = {
      amount: value,
      currencyCode: wallet.currencyCode,
    };

    const { status, errorMessage, data } =
      await WalletController.fundForeignWallet(payload);

    if (status === constant.Success) {
      window.open(data.url, "_blank");
      MimaToastUtil.success({
        message: constant.Success,
      });
      setLoading(false);
      return closeWalletModal();
    }
    setLoading(false);
    MimaToastUtil.error({ message: errorMessage });
  };

  const cardFundWallet = async (value) => {
    setLoading(true);
    const payload = {
      amount: fundAmount,
      pin: value,
    };

    const { status, errorMessage } =
      await WalletController.cardFundWallet(payload);

    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      setLoading(false);
      closeWalletModal();
      return;
    }
    setLoading(false);
    MimaToastUtil.error({ message: errorMessage });
  };

  const closeModal = closeWalletModal;
  return {
    closeModal,
    fundWallet,
    fundWalletValidation,
    fundNaira,
    fundFx,
    fundAmount,
    onSubmitAmount,
    goBack,
    fundFxWallet,
    wallet,
    loading,
    hasAddedCard,
    cardFundWallet,
    enterPin,
    setEnterPin,
    timeLeft,
    timedOut,
  };
};

export default useFundWalletLogic;
