import styled from "styled-components";
import * as textStyles from "./styles";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "subtitle",
    "subtitleBold",
    "body",
    "bodyBold",
    "small",
    "smallBold",
    "xsmall",
    "footer",
    "label",
  ]),
  className: PropTypes.any,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  width: PropTypes.number,
  fontStyle: PropTypes.string,
  style: PropTypes.object,
  align: PropTypes.string,
  labelTitle: PropTypes.string,
  labelColor: PropTypes.string,
};

const MimaText = ({
  children,
  labelTitle,
  labelColor,
  variant = "body",
  color = "var(--color-dark)",
  ...props
}) => {
  return (
    <DivSkeleton {...props}>
      {labelTitle ? (
        <LabelSkeleton labelColor={labelColor}>{labelTitle}</LabelSkeleton>
      ) : null}
      <TextSkeleton {...props} variant={variant} color={color}>
        {children}{" "}
      </TextSkeleton>
    </DivSkeleton>
  );
};

const TextSkeleton = styled.div`
  ${textStyles.base};
  ${(props) => textStyles[props.variant]};
  ${(props) => (props.fontStyle ? `font-style: ${props.fontStyle}` : "")};
  ${(props) => (props.color ? `color: ${props.color}` : "")};
  ${(props) => (props.align ? `text-align: ${props.align}` : "")};
`;

const DivSkeleton = styled.div`
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const LabelSkeleton = styled.label`
  ${textStyles.label};
  ${(props) => (props.labelColor ? `color: ${props.labelColor}` : "")};
`;

MimaText.propTypes = propTypes;

export default MimaText;
