import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { generalNotificationStore } from '../stores';

const getNotifications = async () => {
	const { status, errorMessage, data } = await apiRequest({
		url: '/notification/business',
		method: 'get',
	});
	if (status === constant.Success) {
		generalNotificationStore.setNotifications(data);
		return { status }
	}
	return { status, errorMessage };
};

const readAllNotifications = async () => {
	const { status, errorMessage } = await apiRequest({
		url: '/notification/read-all',
		method: 'patch',
	});
	if (status === constant.Success) {
		await getNotifications();
		return { status };
	}
	return { status, errorMessage };
};
const readOneNotification = async (payload) => {
	const { status, errorMessage } = await apiRequest({
		url: '/notification/read-one',
		method: 'patch',
		payload,
	});
	if (status === constant.Success) {
		await getNotifications();
		return { status };
	}
	return { status, errorMessage };
};

const GeneralNotificationController = {
	getNotifications,
	readAllNotifications,
	readOneNotification,
};

export default GeneralNotificationController;