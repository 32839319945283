import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class PayrollStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'PayrollStore',
			properties: [
				'payrolls',
				'selectedPayroll',
				'groupPayrolls',
				'payrollSummaries',
				'payrollConfigurations',
				'tags',
				'selectedConfiguredPayroll',
				'productSalesList',
				'selectedProductSale',
				'salesList',
				'deductions',
			],
		});
	}

	payrolls = [];
	productSalesList = [];
	groupPayrolls = [];
	selectedPayroll = {};
	payrollSummaries = [];
	loading = false;
	deductions = [];
	payrollConfigurations = {};
	tags = [];
	selectedConfiguredPayroll = {};
	selectedProductSale = {};
	salesList = [];

	reset = () => {
		this.payrolls = [];
		this.payrollConfigurations = {};
		this.payrollSummaries = [];
		this.selectedPayroll = {};
		this.groupPayrolls = [];
		this.tags = [];
		this.deductions = [];
		this.productSalesList = [];
		this.selectedConfiguredPayroll = {};
		this.selectedProductSale = {};
		this.salesList = [];
	};
	setPayrollConfigurations = async configs => {
		runInAction(async () => {
			this.payrollConfigurations = configs;
		});
	};
	setProductSalesList = async state => {
		runInAction(async () => {
			this.productSalesList = state;
		});
	}
	setSalesList = async state => {
		runInAction(async () => {
			this.salesList = state;
		});
	}
	setSelectedProductSale = async state => {
		runInAction(async () => {
			this.selectedProductSale = state;
		});
	}
	setMapTags = async tags => {
		runInAction(async () => {
			this.tags = tags;
		});
	};
	setLoading = async isLoading => {
		runInAction(() => {
			this.loading = isLoading;
		});
	};

	setDeductions = async state => {
		runInAction(async () => {
			this.deductions = state;
		});
	}

	setPayrolls = async payrolls => {
		runInAction(async () => {
			this.payrolls = payrolls;
		});
	};
	setGroupPayrolls = async payrolls => {
		runInAction(async () => {
			this.groupPayrolls = payrolls;
		});
	};

	setPayrollSummaries = async data => {
		runInAction(async () => {
			this.payrollSummaries = data;
		});
	};

	setSelectedPayroll = async state => {
		runInAction(async () => {
			this.selectedPayroll = state;
		});
	};

	setSelectedConfiguredPayroll = async state => {
		runInAction(async () => {
			this.selectedConfiguredPayroll = state;
		});
	};
}
