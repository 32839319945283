import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { openRoutes, protectedRoutes } from './router';
import { navigateToFirstRoute } from './utils/utils';
import './assets/styles/Global/GlobalStyles.scss';
import { ToastContainer } from 'react-toastify';
import { CacheCleanup, ScrollToTop } from './components';
import constant from './utils/constant';
import { observer } from 'mobx-react';
import { useStores } from './stores';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';


export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			networkMode: 'offlineFirst',
			refetchOnWindowFocus: false,
		},
		mutations: {
			networkMode: 'online'
		}
	}
})

function App() {
	const { userStore } = useStores();
	const { isLoggedIn, user } = userStore;
	const userPermissions = isLoggedIn
		? new Set(user.permissions.map(perm => perm.module))
		: [];

	return (
		<QueryClientProvider client={queryClient}>
			<CacheCleanup />
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					{openRoutes.map(route => (
						<Route key={route.path} path={route.path} element={route.element} />
					))}
					{isLoggedIn ? (
						protectedRoutes.map(route => (
							<React.Fragment key={route.path}>
								{!route.module ||
									userPermissions.has(route.module) ||
									(route.type === 'GENERAL' &&
										user.accountType === 'Employee') ? (
									<Route
										key={route.path}
										path={route.path}
										element={route.element}
									/>
								) : (
									<Route
										path={route.path}
										key={route.path}
										element={
											<Navigate
												to={navigateToFirstRoute(user.permissions)}
												replace
											/>
										}
									/>
								)}
							</React.Fragment>
						))
					) : (
						<Route
							path="*"
							element={<Navigate to={constant.Routes.Login} replace />}
						/>
					)}
				</Routes>
				<ToastContainer closeButton={false} position="bottom-right" />
			</BrowserRouter>
		</QueryClientProvider>
	);
}



export default observer(App);
