import { billStore, useStores } from "../../../stores";
import React, { useEffect, useState } from "react";
import { BillController, SettingsController } from "../../../controllers";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { MimaToastUtil, QuickLinksComponent } from "../../../components";
import constant from "../../../utils/constant";
import { monthName, theYear } from "../../../utils/utils";
import * as yup from "yup";
import { toJS } from "mobx";
import moment from "moment";
import { getDate } from "../../../utils/utils";
import { filterPeriods } from "../../../utils/utils";

const useBillLogic = () => {
  const [loading, setLoading] = useState(false);
  const [airtimeModal, setAirtimeModal] = useState(false);
  const [cabletvModal, setCabletvModal] = useState(false);
  const [utilitiesModal, setUtilitiesModal] = useState(false);
  const [onReset, setOnReset] = useState(false);
  const [filteredBills, setFilteredBills] = useState([]);

  const [filterPeriod, setFilterPeriod] = useState(`${theYear}`);
  const [billType, setBillType] = useState("AIRTIME");
  const navigate = useNavigate();

  const isOnboardingDone = () => {
    if (userStore.isOnBoardingDone === "false") {
      MimaToastUtil.error({
        message: constant.OnboardingRequired,
      });
      localStorage.setItem(constant.RoutePath, constant.Routes.PayBills);
      return navigate(constant.Routes.OnboardingStageOne);
    }
  };
  useEffect(() => {
    const setBills = async () => {
      setLoading(true);
      await SettingsController.runPayBillsSetup();
      setLoading(false);
    };
    setBills();
  }, [billStore]);

  const refresh = async () => {
    await SettingsController.runPayBillsSetup();
  };

  const { userStore } = useStores();

  const logout = () => {
    userStore.setLoggedIn(false);
  };

  const goToAirtime = () => {
    // isOnboardingDone();
    setAirtimeModal(true);
  };

  const goToCabletv = () => {
    // isOnboardingDone();
    setCabletvModal(true);
  };

  const goToUtilities = () => {
    // isOnboardingDone();
    setUtilitiesModal(true);
  };

  const closeModal = (e) => {
    setAirtimeModal(false);
    setCabletvModal(false);
    setUtilitiesModal(false);
  };

  const validationSchema = () => {
    return yup.object({
      // startDate: yup.date().notRequired(),
      // endDate: yup.date().notRequired(),
      startDate: yup.string().when("billType", {
        is: (billType) => !billType,
        then: yup.string().required("Start Date is required"),
      }),
      endDate: yup.string().when("billType", {
        is: (billType) => !billType,
        then: yup.string().required("End Date is required"),
      }),
    });
  };

  const onSubmit = async (payload) => {
    let query;

    if (payload && Object.keys(payload).length > 0) {
      if (payload.startDate && payload.endDate) {
        query = `startDate=${payload.startDate}&endDate=${payload.endDate}&type=${payload.billType}`;
      } else {
        query = `type=${payload.billType}`;
      }

      if (payload.startDate && payload.endDate) {
        if (payload.period === "custom") {
          setFilterPeriod(
            `${moment(payload?.startDate).format("DD-MM-YYYY")} - ${moment(payload.endDate).format("DD-MM-YYYY")}`
          );
        } else {
          setFilterPeriod(`${payload?.periodText}`);
        }
      }
    }
    setLoading(true);
    await Promise.all([BillController.getBills(query)]);
    setLoading(false);
  };

  const resetFilter = () => {
    onSubmit();
    setFilterPeriod(`${theYear}`);
  };
  const refreshBill = async (billId) => {
    setLoading(true);
    const { status, errorMessage } = await BillController.refreshBills(billId);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      setLoading(false);
    } else {
      setLoading(false);
      MimaToastUtil.error({ message: errorMessage });
    }
  };
  const billList = billStore.bills;
  const bills = billStore.bills;

  return {
    setBillType,
    billType,
    logout,
    loading,
    airtimeModal,
    cabletvModal,
    utilitiesModal,
    goToAirtime,
    goToUtilities,
    closeModal,
    goToCabletv,
    bills,
    resetFilter,
    onSubmit,
    validationSchema,
    onReset,
    setOnReset,
    billList,
    filteredBills,
    setFilteredBills,
    refresh,
    refreshBill,
    filterPeriods,
    getDate,
  };
};

export default useBillLogic;
