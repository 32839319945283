import React, { useState } from "react";
import * as yup from "yup";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { InvoiceController } from "../../../controllers";
import { invoiceStore, useStores } from "../../../stores";
import { object, string, number, array, boolean, date } from "yup";

const useNewBulkInvoiceLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);

  let {
    // appStore: { loading },
    customerStore,
  } = useStores();
  const orders =
    invoiceStore.copiedInvoice?.orders?.map((order) => {
      return {
        quantity: order.quantity,
        unitPrice: order.unitPrice,
        item: order.item,
      };
    }) || [];

  const invoiceDetailsValidation = () => {
    return object({
      group: string().required("Select a group"),
      orders: array(
        object({
          availableStockQuantity: number(),
          selectFromStock: boolean(),
          // quantity: number().min(0.1, 'Quantity should be greater than 0.1'),
          quantity: number().when("selectFromStock", {
            is: true,
            then: number()
              .min(1, "Quantity should be atleast 1")
              .max(yup.ref("availableStockQuantity"), ({ max }) => {
                return `only ${max} units left in stock.`;
              }),
            otherwise: number().min(1, "Quantity should be atleast 1"),
          }),
          unitPrice: number().min(1, "Unit price should be greater than 1"),
          item: string().required("Service is required"),
        })
      ),
      vat: boolean().required("Are you charging VAT?"),
      wht: boolean().required("Are you deducting WHT?"),
      currencyCode: string().required("Select Currency"),
      dueDate: date().required("Enter Payment due date"),
      discountPer: number()
        .default(0)
        .min(0, "Min discount should be greater than 0%")
        .max(100, "Max discount should be less than or equal to 100%"),
      acceptPartPay: boolean(),
      partPayment: number().when("acceptPartPay", {
        is: true,
        then: (schema) => schema.required("Part payment is required"),
        otherwise: (schema) => schema.optional(),
      }),

      balanceDueDate: date().when("acceptPartPay", {
        is: true,
        then: (schema) => schema.required("Balance due date is required"),
        otherwise: (schema) => schema.optional(),
      }),
    });
  };

  const balanceAmount = (values) => {
    return totalAmountCal(values) - values.partPayment;
  };

  const totalAmountCal = (values) =>
    totalWithoutDiscount(values) -
    discountAmountCal(values) +
    (values.shipping || 0);

  const totalWithoutDiscount = (values) => {
    const totalItemPrice = values?.orders?.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.quantity * currentValue.unitPrice,
      0
    );
    let totalAmount = totalItemPrice;
    totalAmount = values.vat ? totalAmount + totalAmount * 0.075 : totalAmount;
    totalAmount = values.wht ? totalAmount - totalAmount * 0.05 : totalAmount;
    return totalAmount;
  };
  const discountAmountCal = (values) =>
    (values.discountPer * totalWithoutDiscount(values)) / 100 || 0;

  const discountPerCal = (values) =>
    (values.discountAmount / totalWithoutDiscount(values)) * 100 || 0;

  const depositPerCal = (values) =>
    (values.deposit / totalAmountCal(values)) * 100 || 0;

  const depositAmountCal = (values) =>
    (values.depositPer * totalAmountCal(values)) / 100 || 0;

  const onSubmit = async (values) => {
    let payload = {};
    setLoading(true);
    values.orders.forEach((item) => {
      if (item.stock === "") {
        delete item.stock;
      }

      delete item.selectFromStock;
      delete item.availableStockQuantity;
    });
    delete values.selectFromStock;
    delete values.groupName;

    if (values.discountPer === "") values.discountPer = 0;
    delete values.paidDate;
    delete values.paymentMethod;
    if (values.partPayment !== 0) {
      values.deposit = values.partPayment;
    } else {
      values.deposit = totalAmountCal(values);
    }

    values.depositPer = (values.deposit / totalAmountCal(values)) * 100 || 0;

    delete values.acceptPartPay;
    delete values.partPayment;

    values.discountAmount = discountAmountCal(values);

    if (values.balanceDueDate === "") {
      delete values.balanceDueDate;
      payload = {
        ...values,
        type: "INVOICE",
        dueDate: values.dueDate.toDateString(),
      };
    } else {
      payload = {
        ...values,
        type: "INVOICE",
        dueDate: values.dueDate.toDateString(),
        // balanceDueDate: values.balanceDueDate.toDateString(),
      };
    }

    const { status, errorMessage } =
      await InvoiceController.createBulkInvoice(payload);

    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    }
    MimaToastUtil.error({ message: errorMessage });
  };

  const onCloseModal = () => {
    invoiceStore.setCopiedInvoice({});
    closeModal();
  };

  const goBack = () => {
    closeModal();
  };
  const invoice = invoiceStore.copiedInvoice;
  const customers = customerStore.mappedCustomers;
  return {
    closeModal,
    balanceAmount,
    loading,
    orders,
    invoice,
    discountAmountCal,
    depositAmountCal,
    customers,
    onSubmit,
    invoiceDetailsValidation,
    discountPerCal,
    depositPerCal,
    goBack,
  };
};

export default useNewBulkInvoiceLogic;
