import React from "react";
import AuthOnboardingShell from "../AuthOnboardingShell";
import useSignupLogic from "./useSignupLogic";
import { Formik } from "formik";
import { MimaButton, MimaText, IconButton } from "../../../components";
import styles from "../../../assets/styles/Auth/AuthOnboarding.module.scss";
import { useUtitlityRoutes } from "../../../hooks";

const Signup = () => {
  const { switchToLogin } = useUtitlityRoutes();
  const { loading, validationSchema, onSubmit, renderStep, step, setStep } =
    useSignupLogic();
  return (
    <AuthOnboardingShell>
      <MimaText variant="subtitleBold" align="center" mb={2}>
        Sign Up
      </MimaText>
      <MimaText variant="small" align="center" mb={2}>
        Already have an account?{" "}
        <span className={styles.clickable} onClick={switchToLogin}>
          Log in{" "}
        </span>
      </MimaText>
      <Formik
        initialValues={{
          fullname: "",
          tradingName: "",
          email: "",
          mobile: "",
          businessRegNum: "",
          instagramHandle: "",
          twitterHandle: "",
          referralSource: "",
          password: "",
          referredBy: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className={styles.form}>
            {step > 1 && (
              <div
                onClick={() => {
                  setStep(step - 1);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  cursor: "pointer",
                  marginBottom: "1rem",
                }}
              >
                <IconButton variant="back" />{" "}
                <MimaText variant="small" ml={-1.5}>
                  Go Back
                </MimaText>
              </div>
            )}
            {renderStep(
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              setFieldValue
            )}

            <MimaButton
              title={step < 2 ? "Continue" : "Sign Up"}
              onClick={() => {
                handleSubmit();
              }}
              type="submit"
              loading={loading}
              variant="form"
            />

            {step === 2 ? (
              <MimaText variant="small" align="center" mt={2}>
                Creating an account means you agree with <br />{" "}
                <span className={styles.clickable}>
                  {" "}
                  Fash Creatives Terms of Service and Privacy Policy
                </span>
              </MimaText>
            ) : (
              ""
            )}
          </div>
        )}
      </Formik>
    </AuthOnboardingShell>
  );
};

export default Signup;
