import { apiRequest } from '../utils/useAPIRequest';
import { businessStore, inventoryStore, transactionStore } from '../stores';
import constant from '../utils/constant';
import moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../App';

const useSubWalletTransactions = (
	PettyCashTableQuery,
	filterQuery,
	searchQuery
) => {
	const id = businessStore.bId;

	const query = useMemo(() => {
		if (searchQuery) {
			return `${PettyCashTableQuery}&${filterQuery}&search=${searchQuery}`;
		} else if (filterQuery) {
			return `${PettyCashTableQuery}&${filterQuery}`;
		} else {
			return PettyCashTableQuery;
		}
	}, [filterQuery, PettyCashTableQuery, searchQuery]);

	return useQuery({
		queryKey: [id, 'subWalletTransactions', query],
		queryFn: () => {
			return apiRequest({
				url: `/transactions/sub-wallet/business?${query}`,
				method: 'get',
			});
		},
		keepPreviousData: true,
	});
};

const transactions = async query => {
	if (!query) {
		// const startDate = moment().startOf('year');
		const startDate = moment('2022-01-01');
		const endDate = moment().endOf('month');
		query = `startDate=${startDate}&endDate=${endDate}&dateField=createdAt`;
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/transactions/transactions/business?${query}`,
		method: 'get',
	});

	if (status === constant.Success) {
		const wallet = [];
		const offline = [];

		transactionStore.setTransactions(data.transactions);

		data.transactions.forEach(trans => {
			if (trans.category === constant.CATEGORY.WALLET) {
				wallet.push(trans);
			}
			if (trans.category === constant.CATEGORY.OFFLINE) {
				offline.push(trans);
			}
		});
		transactionStore.setWalletTransactions(wallet);
		transactionStore.setOfflineTransactions(offline);

		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const getWalletTransactions = async query => {
	if (!query) {
		// const startDate = moment().startOf('year');
		const startDate = moment().startOf('month');
		const endDate = moment().endOf('month');
		query = `startDate=${startDate}&endDate=${endDate}&dateField=createdAt`;
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/transactions/transactions/business?category=WALLET&${query}`,
		method: 'get',
	});

	if (status === constant.Success) {
		const wallet = [];
		const offline = [];

		transactionStore.setTransactions(data.transactions);

		data.transactions.forEach(trans => {
			if (trans.category === constant.CATEGORY.WALLET) {
				wallet.push(trans);
			}
			if (trans.category === constant.CATEGORY.OFFLINE) {
				offline.push(trans);
			}
		});
		transactionStore.setWalletTransactions(wallet);
		transactionStore.setOfflineTransactions(offline);

		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const subWalletTransactions = async query => {
	if (!query) {
		const startDate = moment('2022-01-01');
		const endDate = moment().endOf('month');
		query = `startDate=${startDate}&endDate=${endDate}&limit=50`;
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/transactions/sub-wallet/business?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		transactionStore.setSubWalletTransactions(data.transactions);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const transactionById = async id =>
	await apiRequest({ url: `/transactions/${id}`, method: 'get' });

const transactionSummary = async query => {
	if (!query) {
		const startDate = moment('2022-01-01');
		const endDate = moment().endOf('month');
		query = `startDate=${startDate}&endDate=${endDate}&dateField=createdAt`;
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/transactions/new/transaction-summary?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		transactionStore.setTransactionsSummary(data);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const expenseAnalysis = async query => {
	if (!query) {
		const startDate = moment().startOf('year');
		const endDate = moment().endOf('month');
		query = `startDate=${startDate}&endDate=${endDate}`;
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/transactions/new/expense-analysis?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		transactionStore.setExpenseAnalysis(data);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const splitAnalysis = async query => {
	if (!query) {
		const startDate = moment().startOf('year');
		const endDate = moment().endOf('month');
		query = `startDate=${startDate}&endDate=${endDate}`;
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/transactions/split-analysis?${query}`,
		method: 'get',
	});

	if (status === constant.Success) {
		transactionStore.setSplitAnalysis(data);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const updateDebtStatus = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/transactions/transaction/status',
		method: 'patch',
		payload,
	});
	if (status === constant.Success) {
		// transactionStore.setSelectedTransaction(data);
		queryClient.invalidateQueries({
			queryKey: [
				businessStore.bId,
				constant.apiQueryKey.Transactions.TransactionList,
			],
		});

		await Promise.all([splitAnalysis(), transactionSummary()]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};
const tagIncome = async payload => {
	const transactionId =
		transactionStore.tag._id || transactionStore.untagged._id;
	const { status, errorMessage } = await apiRequest({
		url: `/transactions/taggedTransaction/${transactionId}`,
		method: 'put',
		payload,
	});
	if (status === constant.Success) {
		transactionStore.setTag({});

		queryClient.invalidateQueries({
			queryKey: [
				businessStore.bId,
				constant.apiQueryKey.Transactions.TransactionList,
			],
		});

		await Promise.all([splitAnalysis(), transactionSummary()]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const updateOfflineTransactionBalance = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/transactions/balance/status',
		method: 'patch',
		payload,
	});
	if (status === constant.Success) {
		// transactionStore.setSelectedTransaction(data);

		queryClient.invalidateQueries({
			queryKey: [
				businessStore.bId,
				constant.apiQueryKey.Transactions.TransactionList,
			],
		});

		await Promise.all([transactionSummary()]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const splitPaymentTransactions = async query => {
	if (!query) {
		const startDate = moment('2022-01-01');
		const endDate = moment().endOf('month');
		query = `startDate=${startDate}&endDate=${endDate}`;
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/transactions/split/business?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		inventoryStore.setSplitPayments(data.value);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const postExpense = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/transactions/expense',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		queryClient.invalidateQueries({
			queryKey: [
				businessStore.bId,
				constant.apiQueryKey.Transactions.TransactionList,
			],
		});

		await Promise.all([transactionSummary()]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const postIncome = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/transactions/income',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		queryClient.invalidateQueries({
			queryKey: [
				businessStore.bId,
				constant.apiQueryKey.Transactions.TransactionList,
			],
		});

		await Promise.all([transactionSummary()]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const actionSplitPayment = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/transactions/action-split/business',
		method: 'put',
		payload,
	});
	if (status === constant.Success) {
		queryClient.invalidateQueries({
			queryKey: [
				businessStore.bId,
				constant.apiQueryKey.Transactions.TransactionList,
			],
		});
		const id = businessStore.bId;
		queryClient.invalidateQueries({
			queryKey: [id, 'getSplitpaymentTransactions'],
		});
		await Promise.all([splitAnalysis(), transactionSummary()]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};
const updateSplitAmount = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/transactions/update-split-payment',
		method: 'put',
		payload,
	});
	if (status === constant.Success) {
		queryClient.invalidateQueries({
			queryKey: [
				businessStore.bId,
				constant.apiQueryKey.Transactions.TransactionList,
			],
		});
		const id = businessStore.bId;
		queryClient.invalidateQueries({
			queryKey: [id, 'getSplitpaymentTransactions'],
		});
		await Promise.all([splitAnalysis(), transactionSummary()]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const useTransactionTypeList = (
	productTypeTableQuery,
	filterQuery,
	searchQuery
) => {
	const id = businessStore.bId;

	const query = useMemo(() => {
		if (searchQuery) {
			return `${productTypeTableQuery}&${filterQuery}&search=${searchQuery}`;
		} else if (filterQuery) {
			return `${productTypeTableQuery}&${filterQuery}`;
		} else {
			return productTypeTableQuery;
		}
	}, [filterQuery, productTypeTableQuery, searchQuery]);

	return useQuery({
		queryKey: [id, constant.apiQueryKey.Transactions.TransactionList, query],
		queryFn: () => {
			return apiRequest({
				url: `/transactions/transactions/business?${query}`,
				method: 'get',
			});
		},
		keepPreviousData: true,
	});
};

const useGetSplitPayments = (
	splitPaymentTableQuery,
	filterQuery,
	searchQuery
) => {
	const id = businessStore.bId;

	const query = useMemo(() => {
		if (searchQuery) {
			return `${splitPaymentTableQuery}&${filterQuery}&search=${searchQuery}`;
		} else if (filterQuery) {
			return `${splitPaymentTableQuery}&${filterQuery}`;
		} else {
			return splitPaymentTableQuery;
		}
	}, [filterQuery, splitPaymentTableQuery, searchQuery]);

	return useQuery({
		queryKey: [id, 'getSplitpaymentTransactions', query],
		queryFn: () => {
			return apiRequest({
				url: `/transactions/split/business?${query}`,
				method: 'get',
			});
		},
		keepPreviousData: true,
	});
};

const TransactionsController = {
	useSubWalletTransactions,
	transactions,
	actionSplitPayment,
	expenseAnalysis,
	updateSplitAmount,
	transactionById,
	splitAnalysis,
	transactionSummary,
	postExpense,
	splitPaymentTransactions,
	postIncome,
	subWalletTransactions,
	updateDebtStatus,
	updateOfflineTransactionBalance,
	tagIncome,
	useTransactionTypeList,
	useGetSplitPayments,
	getWalletTransactions,
};

export default TransactionsController;
