export default Object.freeze({
  IsActivated: "IsActivated",
  Success: "Success",
  InReview: "IN-REVIEW",
  OnboardingRequired:
    "Hello! You need to complete your Onboarding to access this feature",
  Failed: "Failed",
  LIMIT_EXCEEDED:
    "Account limit is exceeded for this transaction, kindly contact the Admin",
  TokenSent: "Token Sent",
  RouteParams: "RouteParams",
  business: "business",
  walletBalance: "walletBalance",
  TransactionsSummary: "TransactionsSummary",
  walleTransferLimit: "walleTransferLimit",
  OfflineTransactions: "OfflineTransactions",
  WalletTransactions: "WalletTransactions",
  WeekSummary: "WeekSummary",
  NewBeneficiaryPayload: "NewBeneficiaryPayload",
  SavedBeneficiaryPayload: "SavedBeneficiaryPayload",
  TransferPayload: "TRANSFERPAYLOAD",
  Banks: "Banks",
  Sent: "Sent",
  INVALID_PIN: "Invalid Pin",
  SET_UP_PIN: "Please set your pin to use this feature",
  LOW_WALLET_BALANCE: "Account balance is low, kindly top up",
  Beneficiaries: "Beneficiaries",
  MakerCheckerUserType: {
    REQUESTER: "REQUESTER",
    REVIEWER: "REVIEWER",
    APPROVER: "APPROVER",
  },
  CATEGORY: {
    WALLET: "WALLET",
    OFFLINE: "OFFLINE",
  },
  TRANSACTION_TYPE: {
    EXPENSE: "EXPENSE",
    INCOME: "INCOME",
    INCOME_DEBT: "INCOME-DEBT",
    EXPENSE_DEBT: "EXPENSE-DEBT",
  },
  STATUS: {
    APPROVED: "APPROVED",
    PENDING: "PENDING",
    VOID: "VOID",
    FAILED: "FAILED",
    PAID: "PAID",
    IGNORE: "IGNORED",
    IN_REVIEW: "IN REVIEW",
    REVIEWED: "REVIEWED",
    PARTIALLY_REVIEWED: "PARTIALLY REVIEWED",
    REQUEST_CHANGE: "REQUEST CHANGE",
    PROCESSING: "PROCESSING",
    REVERSED: "REVERSED",
  },
  TRANSACTION_STATUS: {
    PAID: "PAID",
    VOID: "VOID",
    UNPAID: "UNPAID",
    PARTIALLY_PAID: "PARTIALLY-PAID",
    FULLY_PAID: "FULLY-PAID",
  },
  QUOTATION_STATUS: {
    DRAFT: "DRAFT",
    VOID: "VOID",
    ORDERED: "ORDERED",
    EXPIRED: "EXPIRED",
  },
  MissingWithdrawalAccount: "Kindly Setup Your Withdrawal Account",
  RoutePath: "RoutePath",
  Routes: {
    Home: "/",
    Login: "/auth/login",
    SignUp: "/auth/signup",
    ForgotPassword: "/auth/forgot-password",
    ForgotPasswordReset: "/auth/forgot-password-reset",
    Otp: "/auth/otp",
    Dashboard: "/dashboard",
    ResetPassword: "/reset-password",
    ResetPasswordChangeSuccess: "/reset-password-success",
    SignUpVerify: "/signup-verify",
    OnboardingStageOne: "/onboarding-stage-one",
    OnboardingStageTwo: "/onboarding-stage-two",
    AlmostThereThree: "/almost-there-three",
    AppHome: "/home",
    Wallet: "/wallet",
    Booking: "/booking",
    SalesAgentReport: "/sales-agent-report",
    Customer: "/customer",
    Vendor: "/vendor",
    Invoice: "/invoice",
    PayBills: "/paybills",
    Transactions: "/transactions",
    TransactionsNew: "/transactions/new",
    Receipt: "/receipt",
    Settings: "/settings",
    BusinessInsights: "/business-insights",
    Error404: "/error-404",
    Employees: "/employees",
    BusinessAdmin: "/business-admin",
    Payroll: "/payroll",
    Subwallet: "/subwallet",
    // Checkout: '/checkout',
    Commissions: "/commissions",
    Inventory: "/inventory",
    OrderPage: "/orderPage",
    ItemsStock: "/itemsStock",
    //ProductDisplay: 'product-display',
    ProductDisplay: "/checkout/:productCode",
    ProductCheckOut: "/product-checkout",
    ProductCheckOutFilled: "/product-checkingout",
    EditModal: "/editModal",
    BulkSms: "/bulk-sms",
    MimaCheckout: "/mima-checkout",
    AccountInReview: "/account-in-review",
    PaymentLink: "/payment-link",
    PaymentLinkCheckout: "/payments/:id",
    SplitPayments: "/split-payments",
    Faqs: "/faqs",
    MakeBookings: "/bookings/:id",
    About: "/about",
    Terms: "/terms",
    Privacy: "/privacy-policy",
    Contact: "/contact",
    SubExpired: "/subcription-expired",
    Article: "/article",
    Article1: "/benefits-of-digital-business-management",
    Article2: "/business-management-solutions",
    Article3: "/mima-business-bill-payments",
    Article4: "/payroll-management-solution",
    Article5: "/best-business-management-software",
    Accounting: "/accounting",
    BlogLogin: "/blog/auth/login",
    BlogLanding: "/blog",
    BlogAdminLanding: "/blog/admin-landing",
    BlogAddNew: "/blog/admin-new-user",
    BlogAddNewPost: "/blog/admin-new-post",
    BlogSinglePost: "/blog/:id",
    BlogPost1: "/blog/business-accounting-the-pillar-of-financial-management",
    Orders: "/orders",
    Sitemap: "/sitemap_index.xml",
    ApiKeys: "/api-keys",
    Catalogue: "/catalogue",
    ViewMoreDesign: "/view-more-design",
    FxTransferCheckout: "/fx-transfer-checkout",
    Measurement: "/measurement",
    Website: "/website",
    WebsiteConfig: "/website-config",
    ThemeConfig: "/theme-config",
    CreateWebsite: "/create-website",
    Styles: "/styles",
    StyleRequest: "/style-request",
    NewsLetter: "/news-letter",
    ContactUs: "/contact-us",
    AndroidApp: "https://play.google.com/store/apps/details?id=com.mima.fash",
    IosApp: "https://apps.apple.com/ng/app/fash-creatives/id6484272354",
    WithdrawalAccounts: "/withdrawal-accounts",
  },
  IsOnBoardingDone: "IsOnBoardingDone",
  OtpType: {
    FORGETPASSWORD: "FORGETPASSWORD",
    ONBOARDING: "ONBOARDING",
    BVNONBOARDING: "BVNONBOARDING",
  },
  SUBSCRIPTION_TYPE: {
    BASIC: "Basic",
    PREMIUM: "Premium",
    ENTERPRISE: "Enterprise",
  },
  PLAN_STATUS: {
    ACTIVE: "ACTIVE",
    LOCKED: "LOCKED",
    EXPIRED: "EXPIRED",
  },

  FEEDBACK: {
    CUSTOMERCREATE: "CUSTOMER-CREATE",
    CUSTOMERUPDATE: "CUSTOMER-UPDATE",
    INVOICECREATE: "INVOICE-CREATE",
    INVOICEUPDATE: "INVOICE-UPDATE",
    INVOICEEDIT: "INVOICE-EDIT",
    RECEIPTCREATE: "RECEIPT-CREATE",
    WALLETCREATEVIRTUALBUSINESSBANKACCOUNT:
      "WALLET-CREATE-VIRTUAL-BUSINESS-BANK-ACCOUNT",
    WALLETWITHDRAWAL: "WALLET-WITHDRAWAL",
    WALLETMAKEPAYMENT: "WALLET-MAKE-PAYMENT",
    // WALLETFUNDTRANSFER: 'WALLET-FUND-TRANSFER',
    WALLETFUNDWALLET: "WALLET-FUND-WALLET",
    WALLETEXCHANGE: "WALLET-EXCHANGE",
    PETTYCASHCREATE: "PETTY-CASH-CREATE",
    PETTYCASHMAKEPAYMENT: "PETTY-CASH-MAKE-PAYMENT",
    // PETTYCASHFUNDWALLET: 'PETTY-CASH-FUND-WALLET',
    PAYBILLSBUYAIRTIMEDATA: "PAY-BILLS-BUY-AIRTIME/DATA",
    PAYBILLSBUYELECTRICITY: "PAY-BILLS-BUY-ELECTRICITY",
    PAYBILLSBUYCABLETV: "PAY-BILLS-BUY-CABLE-TV",
    BOOKINGCREATE: "BOOKING-CREATE",
    BOOKINGEDIT: "BOOKING-EDIT",
    PAYMENTLINKCREATE: "PAYMENT-LINK-CREATE",
    PAYMENTLINKEDIT: "PAYMENT-LINK-EDIT",
    EMPLOYEEADD: "EMPLOYEE-ADD",
    EMPLOYEEADDBULK: "EMPLOYEE-ADD-BULK",
    EMPLOYEETERMINATE: "EMPLOYEE-TERMINATE",
    EMPLOYEEADDDEPARTMENT: "EMPLOYEE-ADD-DEPARTMENT",
    EMPLOYEEADDEMPLOYEELOAN: "EMPLOYEE-ADD-EMPLOYEE-LOAN",
    TRANSACTIONSADDINCOME: "TRANSACTIONS-ADD-INCOME",
    TRANSACTIONSADDEXPENSE: "TRANSACTIONS-ADD-EXPENSE",
    TRANSACTIONSADDDEBT: "TRANSACTIONS-ADD-DEBT",
    TRANSACTIONSTAG: "TRANSACTIONS-TAG",
    VENDORADD: "VENDOR-ADD",
    VENDOREDIT: "VENDOR-EDIT",
    STOCKADDPRODUCTTYPE: "STOCK-ADD-PRODUCT-TYPE",
    STOCKADDSTOCK: "STOCK-ADD-STOCK",
    STOCKADDUPDATEQUANTITY: "STOCK-ADD-UPDATE-QUANTITY",
    STOCKEDITSTOCK: "STOCK-EDIT-STOCK",
    STOCKEDITPRODUCTTYPE: "STOCK-EDIT-PRODUCT-TYPE",
    MULTITENANCYADD: "MULTI-TENANCY-ADD",
    BUSINESSREPORTDOWNLOAD: "BUSINESS-REPORT-DOWNLOAD",
  },

  FLOW_TYPE: {
    All: "All",
    Income: "Income",
    Debt: "Debt",
    Funding: "Funding",
    Expense: "Expense",
    Withdraw: "Withdraw",
    Refund: "Refund",
    Untagged: "Untagged",
    Investment: "Investment",
    Gifts: "Gifts",
    Assets: "Assets",
    Loan: "Loan",
  },

  apiQueryKey: {
    Transactions: {
      TransactionList: "transactionList",
    },
  },
});
