// import {
// 	TransferFundOptionContainer,
// 	TransferPinConfirmationContainer,
// 	TransferSuccessContainer,
// 	TransferFailedContainer,
// } from '../../../containers';
import ConfirmTransfer from "../ConfirmTransfer";
import { Modal } from "../../../components";
import React from "react";
import TransferSuccess from "../TransferSuccess";
import TransferFailed from "../TransferFailed";
import TransferPinConfirm from "../TransferPinConfirm";
import TransferFundOptions from "./TransferFundOptions";
import useTransferFundLogic from "../useLogics/useTransferFundLogic";

const TransferFunds = ({
  sharedState,
  closeWalletModal,
  transferSwitchState,
  pinContent,
  setPinContent,
}) => {
  const {
    closeModal,
    bankListContent,
    setBankListContent,
    goBack,
    beneficiaryContent,
    setBeneficiaryContent,
    stage2,
    setStage2,
    onSubmitTransferPinConfirm,
    data,
    loading,
  } = useTransferFundLogic({
    sharedState,
    closeWalletModal,
    transferSwitchState,
    pinContent,
    setPinContent,
  });
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {sharedState.pinContent ? (
        <TransferPinConfirm
          transferSwitchState={transferSwitchState}
          payload={data}
          onSubmitTransferPinConfirm={onSubmitTransferPinConfirm}
          loading={loading}
          closeTransferModal={closeModal}
        />
      ) : sharedState.confirmTransferContent ? (
        <ConfirmTransfer transferSwitchState={transferSwitchState} />
      ) : sharedState.transferSuccessContent ? (
        <TransferSuccess closeModal={closeModal} payload={data} />
      ) : sharedState.transferFailedContent ? (
        <TransferFailed closeModal={closeModal} />
      ) : (
        <TransferFundOptions
          closeTransferModal={closeModal}
          transferSwitchState={transferSwitchState}
          bankListContent={bankListContent}
          setBankListContent={setBankListContent}
          beneficiaryContent={beneficiaryContent}
          setBeneficiaryContent={setBeneficiaryContent}
          stage2={stage2}
          setStage2={setStage2}
        />
      )}
    </Modal>
  );
};

export default TransferFunds;
