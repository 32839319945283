import styles from "../../../assets/styles/Wallet/Wallet.module.scss";
import styles2 from "../../../assets/styles/Wallet/AccountInfoCard.module.scss";
import {
  MimaInput,
  MimaText,
  Modal,
  MimaToastUtil,
  MimaButton,
  PageLoader,
} from "../../../components";
import { MdOutlineContentCopy } from "react-icons/md";
import { PaystackButton } from "react-paystack";
import { Formik } from "formik";
import React, { useState } from "react";
import { amountFormatter } from "../../../utils/utils";
import OtpInput from "react-otp-input";
import { walletStore } from "../../../stores";
import useFundWalletLogic from "../useLogics/useFundWalletLogic";

const FundWallet = ({
  closeWalletModal,
  bankAccounts,
  urlSet,
  hasBusinessAccount,
}) => {
  const [otp, setOtp] = useState("");
  const {
    closeModal,
    fundWallet,
    fundWalletValidation,
    fundNaira,
    fundFx,
    fundAmount,
    onSubmitAmount,
    goBack,
    fundFxWallet,
    wallet,
    loading,
    hasAddedCard,
    cardFundWallet,
    enterPin,
    setEnterPin,
    timeLeft,
    timedOut,
  } = useFundWalletLogic({
    closeWalletModal,
    bankAccounts,
    urlSet,
    hasBusinessAccount,
  });

  const currencyName =
    wallet.currencyCode === "NGN"
      ? "Naira"
      : wallet.currencyCode === "USD"
        ? "Dollar"
        : wallet.currencyCode === "GBP"
          ? "British Pounds"
          : wallet.currencyCode === "EUR"
            ? "Euro"
            : wallet.currencyCode;

  const { dynamicAccountNumbers } = walletStore;

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <Formik
        initialValues={{
          amount: 0,
          walletCurrency: wallet.currencyCode,
        }}
        validationSchema={fundWalletValidation()}
        onSubmit={onSubmitAmount}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
          handleSubmit,
        }) => (
          <>
            <MimaText variant="subtitleBold" mb={3}>
              Fund {currencyName} wallet
            </MimaText>

            {!fundNaira && !fundFx && (
              <div>
                <MimaInput
                  labelTitle={`Enter amount to fund in ${currencyName}`}
                  type="number"
                  placeholder="Enter Amount"
                  name="amount"
                  id="amount"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.amount}
                  touched={touched.amount}
                />

                <MimaButton
                  title="Continue"
                  onClick={handleSubmit}
                  type="submit"
                  variant="form"
                  loading={loading}
                />

                {/* <PaystackButton
									{...fundWallet(values.amount * 100)}
									className={styles.paystackButton}
								/> */}
              </div>
            )}
          </>
        )}
      </Formik>

      {fundNaira && (
        <>
          {!enterPin ? (
            <>
              <div>
                {hasBusinessAccount !== undefined && (
                  <div className={styles2.ai}>
                    <MimaText variant="bodyBold">
                      To fund your {currencyName} wallet, please make a transfer
                      of{" "}
                      <span
                        style={{
                          color: "var(--color-fash-primary-3)",
                          fontSize: "var(--default-font-subtitle)",
                        }}
                      >
                        {amountFormatter(wallet.currencyCode).format(
                          fundAmount
                        )}
                      </span>{" "}
                      to any of the account details below
                    </MimaText>
                    {bankAccounts?.map((bankAccount, i) => (
                      <div className={styles2.ai__bank} key={i}>
                        <MimaText
                          variant="small"
                          color="var(--color-fash-primary-3)"
                        >
                          <b>{bankAccount ? bankAccount.bank : ""}</b>
                        </MimaText>
                        <MimaText>
                          {bankAccount ? bankAccount.accountName : ""}
                        </MimaText>
                        <MimaText
                          variant="subtitleBold"
                          color="var(--color-fash-primary-3)"
                        >
                          {bankAccount ? bankAccount.accountNumber : ""}
                        </MimaText>
                        <div className={styles2.ai__line}></div>
                        <div
                          onClick={() => {
                            navigator.clipboard.writeText(
                              bankAccount ? bankAccount.accountNumber : ""
                            );
                            MimaToastUtil.success({
                              message: "Copied!",
                            });
                          }}
                          className={styles2.ai__copy}
                        >
                          <MdOutlineContentCopy />
                          <MimaText
                            variant="smallBold"
                            color="var(--color-white)"
                          >
                            Click to copy Account number
                          </MimaText>
                        </div>
                      </div>
                    ))}

                    <MimaButton
                      title="Continue"
                      mt={2}
                      onClick={closeModal}
                      variant="form"
                      loading={loading}
                    />
                  </div>
                )}

                {hasAddedCard && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {hasBusinessAccount !== undefined && (
                      <MimaText variant="h2" mt={5} mb={3} align="center">
                        {" "}
                        OR{" "}
                      </MimaText>
                    )}
                    <MimaText align="center">
                      Fund{" "}
                      <span
                        style={{
                          color: "var(--color-fash-primary-3)",
                          fontSize: "var(--default-font-subtitle)",
                        }}
                      >
                        {amountFormatter(wallet.currencyCode).format(
                          fundAmount
                        )}
                      </span>{" "}
                      with your added Card
                    </MimaText>
                    <MimaText
                      variant="small"
                      color="var(--color-error)"
                      align="center"
                    >
                      This method will charge you 1.5% + NGN100 capped at
                      NGN2000. To avoid these charges create a Virtual business
                      account number
                    </MimaText>
                    <MimaButton
                      title="Fund with Card"
                      mt={2}
                      width={38}
                      onClick={() => setEnterPin(true)}
                      variant="form"
                      loading={loading}
                    />
                  </div>
                )}

                {urlSet === "http://localhost:3000/wallet" ||
                urlSet === "https://staging.trymima.com/wallet" ||
                urlSet === "http://localhost:3000/subcription-expired" ||
                hasBusinessAccount === undefined ? (
                  <div>
                    {hasBusinessAccount === undefined && hasAddedCard && (
                      <MimaText variant="h2" mt={5} mb={3} align="center">
                        {" "}
                        OR{" "}
                      </MimaText>
                    )}
                    {hasBusinessAccount === undefined && (
                      <>
                        {timedOut ? (
                          <MimaText align="center">
                            Funding account numbers have expired or are
                            unavailable, and the transaction has timed out.
                            Please restart after 3 minutes if your transfer is
                            not completed
                          </MimaText>
                        ) : (
                          <div className={styles2.ai}>
                            <MimaText variant="bodyBold">
                              To fund your {currencyName} wallet, please make a
                              transfer of{" "}
                              <span
                                style={{
                                  color: "var(--color-fash-primary-3)",
                                  fontSize: "var(--default-font-subtitle)",
                                }}
                              >
                                {amountFormatter(wallet.currencyCode).format(
                                  fundAmount
                                )}
                              </span>{" "}
                              to any of the account details below
                            </MimaText>
                            {dynamicAccountNumbers?.accounts?.map(
                              (bankAccount, i) => (
                                <div className={styles2.ai__bank} key={i}>
                                  <MimaText
                                    variant="small"
                                    color="var(--color-primary)"
                                  >
                                    <b>{bankAccount ? bankAccount.bank : ""}</b>
                                  </MimaText>
                                  <MimaText>
                                    {bankAccount ? bankAccount.accountName : ""}
                                  </MimaText>
                                  <MimaText
                                    variant="subtitleBold"
                                    color="var(--color-fash-primary-3)"
                                  >
                                    {bankAccount
                                      ? bankAccount.accountNumber
                                      : ""}
                                  </MimaText>
                                  <div className={styles2.ai__line}></div>
                                  <div
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        bankAccount
                                          ? bankAccount.accountNumber
                                          : ""
                                      );
                                      MimaToastUtil.success({
                                        message: "Copied!",
                                      });
                                    }}
                                    className={styles2.ai__copy}
                                  >
                                    <MdOutlineContentCopy />
                                    <MimaText
                                      variant="smallBold"
                                      color="var(--color-white)"
                                    >
                                      Click to copy to Account number
                                    </MimaText>
                                  </div>
                                </div>
                              )
                            )}

                            <MimaText
                              color="var(--color-error)"
                              variant="smallBold"
                              align="center"
                              mt={2}
                            >
                              The accounts above Expires in{" "}
                              <span
                                className={styles.span}
                                style={{
                                  color: "var(--color-error)",
                                  fontSize: "1.5rem",
                                }}
                              >
                                {timeLeft}
                              </span>{" "}
                              mins
                            </MimaText>

                            <MimaButton
                              title="Continue"
                              mt={2}
                              onClick={closeModal}
                              variant="form"
                              loading={loading}
                            />
                          </div>
                        )}
                      </>
                    )}
                    <MimaText variant="h2" mt={5} mb={3} align="center">
                      {" "}
                      OR{" "}
                    </MimaText>

                    <MimaText align="center">
                      Fund{" "}
                      <span
                        style={{
                          color: "var(--color-fash-primary-3)",
                          fontSize: "var(--default-font-subtitle)",
                        }}
                      >
                        {amountFormatter(wallet.currencyCode).format(
                          fundAmount
                        )}
                      </span>{" "}
                      with Paystack
                    </MimaText>
                    <MimaText
                      variant="form"
                      color="var(--color-error)"
                      align="center"
                    >
                      This method will charge you 1.5% + NGN100 capped at
                      NGN2000. To avoid these charges create a Virtual business
                      account number
                    </MimaText>

                    <div className={styles.paystackButton_container}>
                      <PaystackButton
                        {...fundWallet(fundAmount * 100)}
                        className={styles.paystackButton}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <Formik
              initialValues={{
                otp: "",
              }}
              onSubmit={cardFundWallet}
            >
              {({ handleChange, handleBlur, values, errors, touched }) => (
                <form onSubmit={cardFundWallet}>
                  {loading ? (
                    <PageLoader loading={loading} />
                  ) : (
                    <div className={styles.centerContent}>
                      <MimaText align="center">
                        Enter your Transfer Pin to complete funding
                      </MimaText>
                      <MimaText mb={1.5} align="center" variant="bodyBold">
                        {amountFormatter(wallet.currencyCode).format(
                          fundAmount
                        )}
                      </MimaText>

                      <div className={styles.pin__group}>
                        <MimaText ml={4}>Enter Pin</MimaText>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "38rem",
                          }}
                        >
                          <OtpInput
                            value={otp}
                            onChange={(otp) => {
                              setOtp(otp);
                              if (otp >= 1000) {
                                cardFundWallet(otp);
                              }
                            }}
                            numInputs={4}
                            isInputSecure={true}
                            inputStyle={{
                              fontSize: "2.4rem",
                              fontFamily: "inherit",
                              width: "6rem",
                              height: "6rem",
                              margin: ".4rem",
                              borderTop: "0px",
                              border: "1px solid #4A4A68",
                              borderRadius: "1rem",
                              display: "block",
                              outline: "none",
                              textAlign: "center",
                              marginRight: "2rem",
                            }}
                            isInputNum
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          )}
        </>
      )}

      {fundFx && (
        <div>
          <MimaText width={38}>
            Click the button below to fund your {currencyName} wallet with{" "}
            <span
              style={{
                color: "var(--color-fash-primary-3)",
                fontSize: "var(--default-font-subtitle)",
              }}
            >
              {" "}
              {amountFormatter(wallet.currencyCode).format(fundAmount)}
            </span>{" "}
          </MimaText>
          <MimaButton
            title="Continue"
            mt={2}
            onClick={() => {
              fundFxWallet(fundAmount);
            }}
            variant="form"
            loading={loading}
          />
        </div>
      )}
    </Modal>
  );
};

export default FundWallet;
