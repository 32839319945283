import React from "react";
import { MimaDropdown, MimaInput } from "../../../components";

const SignupStepTwo = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) => {
  const referralSourceData = [
    "Webinar/Seminar",
    "Instagram",
    "Facebook",
    "Twitter",
    "Google Search",
    "Friends & Family",
    "Mima Sales Agent",
    "Others",
  ].map((item) => ({
    key: item,
    value: item,
  }));

  return (
    <>
      <MimaInput
        labelTitle="Instagram handle (Optional)"
        placeholder="Enter your instagram handle"
        id="instagramHandle"
        name="instagramHandle"
        error={errors.instagramHandle}
        touched={touched.instagramHandle}
        value={values.instagramHandle}
        onChange={handleChange}
        onBlur={handleBlur}
        mb={2}
        variant="form"
      />
      <MimaInput
        labelTitle="Twitter handle (Optional)"
        placeholder="Enter your twitter handle"
        id="twitterHandle"
        name="twitterHandle"
        error={errors.twitterHandle}
        touched={touched.twitterHandle}
        value={values.twitterHandle}
        onChange={handleChange}
        onBlur={handleBlur}
        mb={2}
        variant="form"
      />
      <MimaDropdown
        labelTitle="How did you hear about Fash Creatives?"
        placeholder="Select"
        name="referralSource"
        data={referralSourceData}
        error={errors.referralSource}
        value={values.referralSource}
        onChange={(data) => {
          setFieldValue("referralSource", data.key);
        }}
        onBlur={handleBlur}
        mb={2}
        variant="form"
        bodyVariant="bodyBase2"
      />
      <MimaInput
        labelTitle="Password"
        placeholder="Enter your password"
        passwordIcon
        error={errors.password}
        touched={touched.password}
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        mb={2}
        variant="form"
      />
      <MimaInput
        labelTitle="Referral code (Optional)"
        placeholder="Enter referral code"
        id="referredBy"
        name="referredBy"
        touched={touched.referredBy}
        error={errors.referredBy}
        value={values.referredBy}
        onChange={handleChange}
        onBlur={handleBlur}
        mb={2}
        variant="form"
      />
    </>
  );
};

export default SignupStepTwo;
