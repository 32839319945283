/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  ReceiptCheckBox as RCB,
  Modal,
  MimaTextArea,
  MimaDateInput,
  MimaPaginatedDropdown,
} from "../../components";
import styles from "../../assets/styles/Invoice/Invoice.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, FieldArray } from "formik";
import { businessStore, customerStore } from "../../stores";
import { amountFormatter, currencies } from "../../utils/utils";
import useNewBulkInvoiceLogic from "./useLogic/useNewBulkInvoiceLogic";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};

const FieldTextArea = ({ field, ...props }) => {
  return <MimaTextArea {...field} {...props} />;
};

const FieldPaginatedDropdown = ({ field, ...props }) => {
  return <MimaPaginatedDropdown {...field} {...props} />;
};

const NewBulkInvoice = ({ closeModal }) => {
  const {
    balanceAmount,
    loading,
    orders,
    invoice,
    discountAmountCal,
    depositAmountCal,
    customers,
    onSubmit,
    invoiceDetailsValidation,
    discountPerCal,
    depositPerCal,
    goBack,
  } = useNewBulkInvoiceLogic({ closeModal });
  const isCopied = Object.keys(invoice).length > 0;
  const [values, setValues] = useState({});
  const [discountStatus, setDiscountStatus] = useState(false);
  const [depositStatus, setDepositStatus] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (discountStatus) {
      const discountAmount = discountAmountCal(values);
      ref?.current &&
        ref.current.setFieldValue("discountAmount", discountAmount?.toFixed(2));
    } else {
      const discountPer = discountPerCal(values);
      ref?.current &&
        ref.current.setFieldValue("discountPer", discountPer?.toFixed(2));
    }
  }, [
    values.discountPer,
    values.discountAmount,
    values.orders,
    values.tax,
    values.wht,
  ]);

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {
        <>
          <MimaText
            variant="subtitle"
            color="var(--color-fash-primary-3)"
            align="center"
            mb={4}
          >
            Create Bulk Invoice'
          </MimaText>
          <Formik
            innerRef={ref}
            initialValues={{
              group: "",
              groupName: "",
              orders: isCopied
                ? orders
                : [
                    {
                      quantity: 1,
                      unitPrice: 0,
                      item: "",
                      stock: "",
                      availableStockQuantity: "",
                      selectFromStock: false,
                    },
                  ],
              vat: !!(invoice?.vat && invoice.vat?.amount),
              wht: !!(invoice?.wht && invoice.wht?.amount),
              selectFromStock: false,
              dueDate: "",
              paidDate: "",
              currencyCode: "NGN",
              balanceDueDate: "",
              discountAmount:
                isCopied && invoice.discountAmount ? invoice.discountAmount : 0,
              paymentMethod:
                isCopied && invoice.paymentMethod ? invoice.paymentMethod : "",
              note: isCopied && invoice.note ? invoice.note : "",
              deposit: isCopied ? invoice.deposit : 0,
              discountPer: isCopied ? invoice.discountPer : 0,
              depositPer: isCopied ? invoice.depositPer : 0,
              shipping: isCopied ? invoice.shipping : 0,
              // salesChannel: isCopied ? invoice.salesChannel : "",
              acceptPartPay: isCopied ? invoice.acceptPartPay : false,
              partPayment: isCopied ? invoice.partPayment : 0,
            }}
            validationSchema={invoiceDetailsValidation}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => {
              return (
                <div>
                  <MimaDropdown
                    labelTitle="Bill To"
                    placeholder="Select Group"
                    name="groupName"
                    value={values.groupName}
                    id="groupName"
                    onChange={(data) => {
                      setFieldValue("group", data.key);
                      setFieldValue("groupName", data.value);
                    }}
                    data={customerStore.mappedGroups}
                    touched={touched.group}
                    error={errors.group}
                  />
                  <MimaDropdown
                    labelTitle="Select Currency"
                    name="currencyCode"
                    value={values.currencyCode}
                    placeholder={values.currencyCode}
                    id="currencyCode"
                    currentValue={values.currencyCode}
                    onChange={(data) => {
                      setFieldValue("currencyCode", data.key);
                    }}
                    data={currencies}
                    touched={touched.currencyCode}
                    error={errors.currencyCode}
                  />
                  {/* <MimaDropdown
										labelTitle="Select a sales channel"
										placeholder="Select a sales channel"
										name="salesChannel"
										value={values.salesChannel}
										id="salesChannel"
										onChange={data => {
											setFieldValue('salesChannel', data.key);
										}}
										data={salesChannelData}
										touched={touched.salesChannel}
										error={errors.salesChannel}
										mb={3}
									/> */}
                  <RCB
                    title="Select Product from Stock"
                    onChange={(e) => {
                      setFieldValue("selectFromStock", e.target.checked);
                      setFieldValue("orders", [
                        {
                          quantity: 1,
                          unitPrice: 0,
                          item: "",
                          stock: "",
                          selectFromStock: e.target.checked,
                        },
                      ]);
                    }}
                    checked={values.selectFromStock}
                    value={String(values.selectFromStock)}
                    name="selectFromStock"
                  />
                  <FieldArray name="orders">
                    {({ push, remove }) => (
                      <React.Fragment>
                        {values.orders.map((_, i) => (
                          <div key={i}>
                            {!values.selectFromStock && (
                              <Field
                                name={`orders.${i}.item`}
                                id={`orders.${i}.item`}
                                component={FieldTextArea}
                                type="text"
                                placeholder="Product/Service"
                                labelTitle="Product/Service"
                                mt={2}
                                touched={touched?.orders?.[i]?.item}
                                error={errors?.orders?.[i]?.item}
                              />
                            )}

                            {values.selectFromStock ? (
                              <Field
                                type="text"
                                mt={2}
                                name={`orders.${i}.item`}
                                id={`orders.${i}.item`}
                                value={values.orders[i].item}
                                queryKey={[businessStore.bId, "stock"]}
                                optionLabel="name"
                                component={FieldPaginatedDropdown}
                                labelTitle="Product/Service"
                                placeholder={
                                  values.orders[i].item || "Select from Stock"
                                }
                                touched={touched?.orders?.[i]?.item}
                                error={errors?.orders?.[i]?.item}
                                url="/products/stocks/business"
                                getPageValue={(page) => page?.data?.value || []}
                                getPageTotalCount={(lastPage) =>
                                  lastPage?.data?.totalCounts || 0
                                }
                                onChange={(data) => {
                                  setFieldValue(`orders.${i}.stock`, data._id);
                                  setFieldValue(`orders.${i}.item`, data.name);
                                  setFieldValue(
                                    `orders.${i}.unitPrice`,
                                    data.sellingPrice
                                  );
                                  setFieldValue(
                                    `orders.${i}.availableStockQuantity`,
                                    data.quantity
                                  );
                                }}
                              />
                            ) : null}

                            <div className={styles.invoice__beside}>
                              <Field
                                name={`orders.${i}.unitPrice`}
                                component={FieldInput}
                                type="number"
                                min="0"
                                labelTitle="Price"
                                width={18}
                                // disabled={values.selectFromStock}
                                touched={touched?.orders?.[i]?.unitPrice}
                                error={errors?.orders?.[i]?.unitPrice}
                              />
                              <Field
                                name={`orders.${i}.quantity`}
                                component={FieldInput}
                                type="number"
                                min="0"
                                labelTitle="Quantity"
                                width={18}
                                touched={touched?.orders?.[i]?.quantity}
                                error={errors?.orders?.[i]?.quantity}
                              />
                            </div>
                            {i !== 0 ? (
                              <MimaButton
                                title="Remove"
                                variant="filter"
                                type="button"
                                buttonColor="var(--color-error)"
                                width={10}
                                onClick={() => remove(i)}
                              />
                            ) : (
                              ""
                            )}
                            <div className={styles.summary__line}></div>
                          </div>
                        ))}
                        <MimaButton
                          title="Add Another Item"
                          variant="text"
                          type="button"
                          onClick={() =>
                            push({
                              quantity: 0,
                              unitPrice: 0,
                              item: "",
                            })
                          }
                          width={38}
                        />
                      </React.Fragment>
                    )}
                  </FieldArray>
                  <>
                    <MimaInput
                      type="number"
                      labelTitle="Shipping Fee"
                      placeholder="0.00"
                      name="shipping"
                      mb={1}
                      min={0}
                      value={values.shipping}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className={styles.invoice__beside}>
                      <MimaInput
                        type="number"
                        labelTitle="Discount %"
                        placeholder="0%"
                        name="discountPer"
                        mb={1}
                        min={0}
                        max={100}
                        value={values.discountPer}
                        onChange={(e) => {
                          setDiscountStatus(true);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        width={18}
                        touched={touched.discountPer}
                        error={errors.discountPer}
                      />
                      <MimaInput
                        type="number"
                        labelTitle="Discount Amount"
                        placeholder="0%"
                        name="discountAmount"
                        mb={1}
                        min={0}
                        value={values.discountAmount}
                        onChange={(e) => {
                          setDiscountStatus(false);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        width={18}
                        touched={touched.discountAmount}
                        error={errors.discountAmount}
                      />
                    </div>
                    <div className={styles.invoice__checks_group}>
                      <MimaText variant="small">
                        Do you want to charge VAT?
                      </MimaText>
                      <div className={styles.invoice__checks}>
                        <RCB
                          title="Yes"
                          onChange={() => {
                            setFieldValue("vat", true);
                          }}
                          checked={values.vat}
                          value="true"
                          name="vat"
                        />
                        <RCB
                          title="No"
                          checked={!values.vat}
                          onChange={() => setFieldValue("vat", false)}
                          value="false"
                          name="vat"
                        />
                      </div>
                      {errors.vat ? (
                        <div className={styles.error}>
                          {""}
                          {touched.vat && errors.vat}
                          {""}
                        </div>
                      ) : null}
                    </div>

                    <div className={styles.invoice__checks_group}>
                      <MimaText variant="small">
                        Do you want to deduct WHT?
                      </MimaText>
                      <div className={styles.invoice__checks}>
                        <RCB
                          title="Yes"
                          onChange={() => {
                            setFieldValue("wht", true);
                          }}
                          checked={values.wht}
                          value="true"
                          name="wht"
                        />
                        <RCB
                          title="No"
                          checked={!values.wht}
                          onChange={() => setFieldValue("wht", false)}
                          value="false"
                          name="wht"
                          mb={1}
                        />
                      </div>
                      {errors.wht ? (
                        <div className={styles.error}>
                          {""}
                          {touched.wht && errors.wht}
                          {""}
                        </div>
                      ) : null}
                    </div>

                    <div className={styles.invoice__checks_group}>
                      <MimaText variant="small">
                        Do you want to accept part payment?
                      </MimaText>
                      <div className={styles.invoice__checks}>
                        <RCB
                          title="Yes"
                          onChange={() => {
                            setFieldValue("acceptPartPay", true);
                          }}
                          checked={values.acceptPartPay}
                          value="true"
                          name="acceptPartPay"
                        />
                        <RCB
                          title="No"
                          checked={!values.acceptPartPay}
                          onChange={() => setFieldValue("acceptPartPay", false)}
                          value="false"
                          name="acceptPartPay"
                          mb={1}
                        />
                      </div>
                    </div>

                    <div className={styles.invoice__beside}>
                      {values.acceptPartPay ? (
                        <MimaInput
                          type="number"
                          labelTitle="Part Payment"
                          placeholder="0.00"
                          name="partPayment"
                          mb={1}
                          min={0}
                          value={values.partPayment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.partPayment}
                          error={errors.partPayment}
                          width={18}
                        />
                      ) : (
                        ""
                      )}
                      <MimaDateInput
                        labelTitle="Due date"
                        name="dueDate"
                        value={values.dueDate}
                        onChange={(text) => {
                          setFieldValue("dueDate", text);
                        }}
                        onBlur={handleBlur}
                        width={values.acceptPartPay ? 18 : 38}
                        touched={touched.dueDate}
                        error={errors.dueDate}
                      />
                    </div>

                    {values.acceptPartPay ? (
                      <div className={styles.invoice__beside}>
                        <MimaText
                          labelTitle="Expected Balance"
                          width={18}
                          variant="bodyBold"
                        >
                          {amountFormatter(values.currencyCode).format(
                            balanceAmount(values)
                          )}
                        </MimaText>
                        {balanceAmount(values) > 0 ? (
                          <MimaDateInput
                            labelTitle="Balance due date"
                            name="balanceDueDate"
                            value={values.balanceDueDate}
                            onChange={(text) => {
                              setFieldValue("balanceDueDate", text);
                            }}
                            onBlur={handleBlur}
                            width={18}
                            touched={touched.balanceDueDate}
                            error={errors.balanceDueDate}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                  <MimaTextArea
                    type="text"
                    labelTitle="Note or Terms and Conditions"
                    name="note"
                    variant="wide"
                    value={values.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    height={10}
                  />

                  <MimaButton
                    title="Continue"
                    onClick={handleSubmit}
                    type="submit"
                    loading={loading}
                    variant="form"
                    mt={2}
                  />
                </div>
              );
            }}
          </Formik>
        </>
      }
    </Modal>
  );
};

export default NewBulkInvoice;
