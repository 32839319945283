/**
 * MobX store classes instantiation.
 * This is where all MobX stores are instantiated
 * and exported for use within the Provider.
 */
import { configurePersistable } from "mobx-persist-store";
import React from "react";
import { configure } from "mobx";
import UserStore from "./UserStore";
import BusinessStore from "./BusinessStore";
import CustomerStore from "./CustomerStore";
import WalletStore from "./WalletStore";
import BillStore from "./BillStore";
import SupportStore from "./SupportStore";
// import VendorStore from './VendorStore';
import EmployeeStore from "./EmployeeStore";
import PayrollStore from "./PayrollStore";
import TransactionStore from "./TransactionStore";
import InvoiceStore from "./InvoiceStore";
import AppStore from "./AppStore";
import InventoryStore from "./InventoryStore";
import ExchangeStore from "./ExchangeStore";
import VirtualCardStore from "./VirtualCardStore";
import PaymentLinkStore from "./PaymentLinkStore";
import BookingStore from "./BookingStore";
// import BulkSmsStore from './BulkSmsStore';
import QuotationStore from "./QuotationStore";
import GeneralNotificationStore from "./GeneralNotificationStore";
import OrdersStore from "./OrdersStore";
import MeasurementStore from "./MeasurementStore";
import WebsiteStore from "./WebsiteStore";
// import ReportStore from './ReportStore';
/**
 * Configuration to ensure all state changes occur via MobX actions
 */

configure({ enforceActions: "observed" });
configurePersistable(
  {
    storage: window.localStorage,
    expireIn: 86400000,
    removeOnExpiration: true,
    stringify: true,
    debugMode: false,
  },
  { delay: 200, fireImmediately: false }
);

export const appStore = new AppStore();

export const userStore = new UserStore();
export const businessStore = new BusinessStore();

export const walletStore = new WalletStore();

export const billStore = new BillStore();

export const supportStore = new SupportStore();

export const customerStore = new CustomerStore();
export const measurementStore = new MeasurementStore();

export const transactionStore = new TransactionStore();
export const invoiceStore = new InvoiceStore();
export const employeeStore = new EmployeeStore();
export const payrollStore = new PayrollStore();
export const inventoryStore = new InventoryStore();
export const exchangeStore = new ExchangeStore();
export const virtualCardStore = new VirtualCardStore();
export const paymentLinkStore = new PaymentLinkStore();
export const bookingStore = new BookingStore();
// export const bulkSmsStore = new BulkSmsStore();
export const quotationStore = new QuotationStore();
export const generalNotificationStore = new GeneralNotificationStore();
// export const vendorStore = new VendorStore();
export const ordersStore = new OrdersStore();
export const websiteStore = new WebsiteStore();
// export const reportStore = new ReportStore();

export const storesContext = React.createContext({
  userStore,
  businessStore,
  walletStore,
  billStore,
  appStore,
  customerStore,
  measurementStore,
  websiteStore,
  // vendorStore,
  employeeStore,
  payrollStore,
  supportStore,
  transactionStore,
  invoiceStore,
  inventoryStore,
  exchangeStore,
  virtualCardStore,
  paymentLinkStore,
  // bulkSmsStore,
  quotationStore,
  bookingStore,
  generalNotificationStore,
  ordersStore,
  // reportStore,
});

export const useStores = () => React.useContext(storesContext);
