import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { IoCaretForward, IoCaretBack } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";
import TableSearch from "./TableSearch";
import styles from "../../assets/styles/Button.module.scss";
import PageLoader from "../PageLoader";
import MimaText from "../MimaText";

const propTypes = {
  tableData: PropTypes.array,
  tableColumns: PropTypes.array,
  searchPlaceholder: PropTypes.string,
  searchVariant: PropTypes.oneOf(["regular", "wide"]),
  totalItems: PropTypes.number,
  onLimitChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  limit: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setSearchQuery: PropTypes.func,
};

const defaultProps = {
  tableData: [
    {
      id: 1,
      first_name: "Millicent",
      last_name: "Whatham",
      email: "mwhatham0@comsenz.com",
      gender: "Female",
      university: "Samarkand State University",
    },
    {
      id: 2,
      first_name: "Siward",
      last_name: "Amberger",
      email: "samberger1@behance.net",
      gender: "Male",
      university: "Institute of Industrial Electronics Engineering",
    },
    {
      id: 3,
      first_name: "Sheree",
      last_name: "Madeley",
      email: "smadeley2@google.com",
      gender: "Female",
      university: "Kateb Institute of Higher Education",
    },
  ],
  tableColumns: [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "University",
      accessor: "university",
    },
  ],
  searchPlaceholder: "search",
  searchVariant: "regular",
  onLimitChange: (limit) => {},
  isLoading: false,
  isFetching: false,
  limit: 50,
  currentPage: 1,
  setCurrentPage: () => {},
  setSearchQuery: () => {},
};

const MimaTable = ({
  tableData,
  tableColumns,
  searchPlaceholder,
  searchVariant,
  totalItems,
  onLimitChange,
  isLoading,
  isFetching,
  limit,
  currentPage,
  setCurrentPage,
  setSearchQuery,
  expandedRows,
  ExpandedRowComponent,
  toggleRowExpansion,
  refetch,
  ...props
}) => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const usedLimit = useMemo(() => limit, [limit]);

  // const [currentPage, setCurrentPage] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    setPageSize,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: usedLimit } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;

  const maxDisplayedPages = 3; // Maximum number of page buttons to display

  const { pageSize } = state;

  const totalPages = totalItems ? Math.ceil(totalItems / pageSize) : pageCount;

  const pageButtons = useMemo(() => {
    let pageButtons = [];
    if (totalPages <= maxDisplayedPages) {
      pageButtons = Array.from({ length: totalPages }, (_, index) => index);
      return pageButtons;
    } else {
      const startPage = Math.max(
        0,
        currentPage - Math.floor(maxDisplayedPages / 2)
      );
      const endPage = Math.min(
        totalPages - 1,
        startPage + maxDisplayedPages - 1
      );

      if (startPage > 0) {
        pageButtons.push("...");
      }

      pageButtons = pageButtons.concat(
        Array.from(
          { length: endPage - startPage + 1 },
          (_, index) => startPage + index
        )
      );

      if (endPage < totalPages - 1) {
        pageButtons.push("...");
      }
      return pageButtons;
    }
  }, [totalPages, maxDisplayedPages, currentPage]);

  return (
    <>
      <div className="searchInMiddle">
        <TableSearch
          filter={globalFilter}
          setFilter={setGlobalFilter}
          placeholder={searchPlaceholder}
          variant={searchVariant}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <div className="whiteTable">
        {isLoading ? (
          <PageLoader loading={isLoading} title="while we fetch your data" />
        ) : (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FaArrowDown />
                          ) : (
                            <FaArrowUp />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {tableData.length > 0 ? (
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={rowIndex}>
                      {expandedRows === rowIndex ? (
                        <tr>
                          <td colSpan={row.cells.length}>
                            <ExpandedRowComponent
                              row={row}
                              toggleRowExpansion={toggleRowExpansion}
                              refetch={refetch}
                              isFetching={isFetching}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {" "}
                              {cell.render("Cell")}{" "}
                            </td>
                          ))}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            ) : (
              <tr>
                <td colSpan={100}>
                  <MimaText variant="subtitleBold" mt={2} align="center">
                    No Data Available
                  </MimaText>
                </td>
              </tr>
            )}
          </table>
        )}
      </div>

      {isFetching && <PageLoader loading={isFetching} type="bar" />}

      <div className={styles.pagination__group}>
        <div className={styles.pagination}>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              onLimitChange(Number(e.target.value));
            }}
          >
            {[50, 200, 300, 400, 500].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <strong>
            {currentPage + 1} of {totalItems ? totalPages : pageOptions.length}
          </strong>
        </div>
        <div className={styles.pagination}>
          <button
            onClick={() => {
              previousPage();
              setCurrentPage(currentPage - 1);
            }}
            disabled={currentPage === 1}
            className={styles.numberedPage}
          >
            <IoCaretBack style={{ fontSize: "2rem" }} />
          </button>
          {pageButtons.map((page, index) =>
            page === "..." ? (
              <BsThreeDots key={index} style={{ fontSize: "2rem" }} />
            ) : (
              <button
                key={index}
                onClick={() => {
                  gotoPage(page);
                  setCurrentPage(page + 1);
                }}
                className={`${styles.numberedPage} ${currentPage === page ? styles.numberPageActive : ""}`}
              >
                {page + 1}
              </button>
            )
          )}

          <button
            onClick={() => {
              nextPage();
              setCurrentPage(currentPage + 1);
            }}
            disabled={currentPage === totalPages}
            className={styles.numberedPage}
          >
            <IoCaretForward style={{ fontSize: "2rem" }} />
          </button>
        </div>
      </div>
    </>
  );
};

MimaTable.propTypes = propTypes;

MimaTable.defaultProps = defaultProps;

MimaTable.displayName = "MimaTable";

export default MimaTable;
