import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useState } from "react";
import { userStore } from "../../../stores";
import { MimaToastUtil } from "../../../components";
import { UserController } from "../../../controllers";
import constant from "../../../utils/constant";
import {
  passwordRegex,
  phoneRegExp,
  removeEmptyKeys,
} from "../../../utils/utils";
import SignupStepOne from "./SignupStepOne";
import SignupStepTwo from "./SignupStepTwo";

const useSignupLogic = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const renderStep = (
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue
  ) => {
    switch (step) {
      case 1:
        return (
          <SignupStepOne
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <SignupStepTwo
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        );
      default:
        return (
          <SignupStepOne
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
        );
    }
  };

  const stepOneValidation = yup.object().shape({
    fullname: yup
      .string()
      .required("Full Name is required")
      .test("fullname", "First and Last name is required", function (value) {
        const names = value ? value.split(" ") : [];
        return names.length > 1 && names[0].length > 1 && names[1].length > 1;
      }),
    mobile: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Mobile is required"),
    tradingName: yup.string().required("Business Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    businessRegNum: yup.string().optional(),
  });
  const stepTwoValidation = yup.object().shape({
    password: yup
      .string()
      .matches(
        passwordRegex,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .required("Password is required"),
    referralSource: yup
      .string()
      .required("Please select how you heard about Fash Creatives"),
    referredBy: yup.string().optional(),
    businessRegNum: yup
      .string()
      .test(
        "oneRequired",
        "Please enter either your business registration number, twitter handle or instagram handle",
        function () {
          return (
            this.parent.twitterHandle ||
            this.parent.instagramHandle ||
            this.parent.businessRegNum
          );
        }
      ),
    twitterHandle: yup
      .string()
      .test(
        "oneRequired",
        "Please enter either your business registration number, twitter handle or instagram handle",
        function () {
          return (
            this.parent.twitterHandle ||
            this.parent.instagramHandle ||
            this.parent.businessRegNum
          );
        }
      ),
    instagramHandle: yup
      .string()
      .test(
        "oneRequired",
        "Please enter either your business registration number, twitter handle or instagram handle",
        function () {
          return (
            this.parent.twitterHandle ||
            this.parent.instagramHandle ||
            this.parent.businessRegNum
          );
        }
      ),
  });

  const validationSchema = () => {
    switch (step) {
      case 1:
        return stepOneValidation;
      case 2:
        return stepTwoValidation;
      default:
        return stepOneValidation;
    }
  };
  const onSubmit = async (values) => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      const names = values.fullname.trim().split(" ");

      const { fullname, ...rest } = values;

      const payload = {
        ...rest,
        firstname: names[0],
        lastname: names.length > 2 ? names[2] : names[1],
        middlename: names.length > 2 ? names[1] : "",
        isFash: true,
      };
      removeEmptyKeys(payload);

      setLoading(true);

      const { status, errorMessage } = await UserController.signup(payload);
      if (status === constant.Success) {
        setLoading(false);
        userStore.setOtpType(constant.OtpType.ONBOARDING);
        return navigate(constant.Routes.Otp);
      }
      setLoading(false);
      return MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  return {
    loading,
    validationSchema,
    onSubmit,
    renderStep,
    step,
    setStep,
  };
};

export default useSignupLogic;
