import styles from "../../assets/styles/Components/AppPage.module.scss";
import Sidebar from "../Sidebar";
// import Sidebar, { MobileSidebar } from "../Sidebar";
// import { GeneralNotificationContainer, LogoutContainer, SubscriptionsContainer } from '../../containers';
import { useMediaQuery } from "react-responsive";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import constant from "../../utils/constant";
import { useStores } from "../../stores";
import { FaBars } from "react-icons/fa";
import React, { useState } from "react";
import IconButton from "../IconButton";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useEffect } from "react";
import MimaText from "../MimaText";
import { GeneralNotificationController } from "../../controllers";
import { toJS } from "mobx";
import { CountDown } from "..";

const propTypes = {
  children: PropTypes.any,
  pageTitle: PropTypes.string,
  tradingName: PropTypes.string,
};
const defaultProps = {
  pageTitle: "",
};

const AppPage = ({ children, pageTitle, refresh, tradingName, ...props }) => {
  const { generalNotificationStore } = useStores();
  const { notifications } = generalNotificationStore || {};
  const notificationsList = toJS(
    notifications?.notifications?.find((x) => x.read.toString() === "false")
  );

  const [mobileMenu, setMobileMenu] = useState(false);
  const [tier, setTier] = useState("Tier 0");
  const [logoutModal, setLogoutModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  // const [noticeBadge, setNoticeBadge] = useState(notificationsList?._id);
  const [subscriptionModal, setSubscriptionModal] = useState(false);

  const { userStore, businessStore } = useStores();

  const noticeBadge = notificationsList?._id;

  useEffect(() => {
    const setNotifications = async () => {
      await Promise.all([GeneralNotificationController.getNotifications()]);
    };

    if (notifications?.notifications?.length !== 0) {
      setNotifications();
    }
  }, [notifications]);

  const navigate = useNavigate();
  const goToSettings = () => {
    navigate(constant.Routes.Settings);
  };

  const goTologout = () => {
    setLogoutModal(true);
  };

  const goToNotificationModal = () => {
    if (notificationModal === false) {
      setNotificationModal(true);
    } else {
      setNotificationModal(false);
    }
  };

  const closeModal = () => {
    setLogoutModal(false);
    setNotificationModal(false);
    setSubscriptionModal(false);
  };

  const isBelow1000 = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  const mobileMenuClicked = () => {
    setMobileMenu(!mobileMenu);
  };

  const isActivated = userStore?.isActivated;
  const hasPin = userStore?.user?.hasPin;
  const isOnboarded = userStore?.isOnBoardingDone !== "false";
  const hasWithdrawal = businessStore?.business?.withdrawalBankAccount;
  const hasBizReg = false;

  let [a, b, c, d, e] = [0, 0, 0, 0, 0];

  isActivated ? (a = 25) : (a = 0);
  isOnboarded ? (b = 25) : (b = 0);
  hasPin ? (c = 25) : (c = 0);
  hasWithdrawal ? (d = 25) : (d = 0);
  hasBizReg ? (e = 25) : (e = 0);

  useEffect(() => {
    const getTier = () => {
      if (isActivated && isOnboarded && hasBizReg) {
        setTier("Tier 3");
      } else if (isActivated && isOnboarded) {
        setTier("Tier 2");
      } else if (isActivated) {
        setTier("Tier 1");
      }
    };

    getTier();
  }, [isActivated, isOnboarded, hasBizReg]);

  // For free trial
  const subscriberList = businessStore.business?.subscription;
  const subscriptionAmount = subscriberList?.amountPaid;
  const startDate = subscriberList?.trialStartDate;

  return (
    <div className={styles.container} {...props}>
      {!isBelow1000 && <Sidebar />}
      {isBelow1000 && mobileMenu && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          className={styles.mobileSidebar}
        >
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              x: -100,
              transition: {
                duration: 0.2,
              },
            }}
          >
            {/* <MobileSidebar mobileMenu={mobileMenuClicked} /> */}
          </motion.div>
          <div onClick={mobileMenuClicked}></div>
        </motion.div>
      )}
      <div className={styles.ghost}></div>
      <div className={styles.appright}>
        <CountDown
          startDate={startDate}
          subscriptionAmount={subscriptionAmount}
          subscriberList={subscriberList}
          setSubscriptionModal={setSubscriptionModal}
        />
        <div className={styles.topside}>
          <div className={styles.topside_1}>
            {isBelow1000 && (
              <FaBars className={styles.menuIcon} onClick={mobileMenuClicked} />
            )}
            <p className={styles.topside_welcome}>
              Welcome back, <br />
              <span className={styles.topside_name}>
                {businessStore.business?.tradingName}
              </span>
            </p>
          </div>
          <div className={styles.topside}>
            <div className={styles.topside_icon_main}>
              {!businessStore.expiredSubscription && (
                <div
                  className={styles.topside_tier}
                  onClick={goToSettings}
                  style={
                    tier === "Tier 2"
                      ? {
                          backgroundColor: "var(--color-success)",
                        }
                      : tier === "Tier 3"
                        ? {
                            backgroundColor: "var(--color-dark)",
                          }
                        : {}
                  }
                >
                  {tier} user
                </div>
              )}
              <div className={styles.topside_icon}>
                {!businessStore.expiredSubscription && (
                  <>
                    {noticeBadge ? (
                      <IconButton
                        variant="notificationActive"
                        onClick={goToNotificationModal}
                      />
                    ) : (
                      <IconButton
                        variant="notification"
                        onClick={goToNotificationModal}
                      />
                    )}
                    <IconButton onClick={goToSettings} />
                  </>
                )}
                <IconButton variant="logout" onClick={goTologout} />
              </div>
            </div>
          </div>
        </div>

        <MimaText variant="h3" align="center" mb={3}>
          {pageTitle}
        </MimaText>
        {children}
      </div>

      {(logoutModal || notificationModal || subscriptionModal) && (
        <div className="modal">
          <AnimatePresence>
            {/* {logoutModal && <LogoutContainer closeModal={closeModal} />} */}
            {/* {notificationModal && <GeneralNotificationContainer closeModal={closeModal} />} */}
            {/* {subscriptionModal && <SubscriptionsContainer closeModal={closeModal} />} */}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

AppPage.propTypes = propTypes;

AppPage.defaultProps = defaultProps;

export default AppPage;
