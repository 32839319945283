import { MimaButton, MimaText, InvoiceSummaryItem } from "../../components";
import styles from "../../assets/styles/Quotation/Quotation.module.scss";
import { GiUpgrade } from "react-icons/gi";
import { amountFormatter } from "../../utils/utils";
import moment from "moment";
import React from "react";

const QuotationSummary = ({
  sendQuotation,
  editQuoteModal,
  goToEditQuote,
  closeModal,
  quote,
}) => {
  return (
    <>
      {!editQuoteModal && (
        <div className={styles.summary}>
          <div className={styles.invoice__beside}>
            {quote.status !== "ORDERED" && (
              <div className={styles.summary__edit} onClick={goToEditQuote}>
                <GiUpgrade /> Edit
              </div>
            )}
          </div>

          <div className={styles.summary__title}>
            <MimaText variant="bodyBold">{`QTN-${quote.number}`}</MimaText>
            <div className={styles.summary__initials}>
              {quote?.business?.tradingName?.charAt(0).toUpperCase()}
            </div>
          </div>
          <div className={styles.summary__from}>
            <MimaText variant="small">From</MimaText>
            <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
              {quote.business.tradingName}
            </MimaText>
            <MimaText>{quote.business.businessAddress}</MimaText>
            <MimaText>{quote.business.mobile}</MimaText>
            <MimaText>{quote.business.email}</MimaText>
            <div className={styles.summary__line}></div>
          </div>
          <div className={styles.summary__to}>
            <MimaText variant="small">To</MimaText>
            <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
              {quote.customer?.[0].fullname}
            </MimaText>
            <MimaText>{quote.customer[0]?.mobile}</MimaText>
            <MimaText>{quote.customer[0]?.address}</MimaText>
            <MimaText>{quote.customer[0]?.email}</MimaText>
            <div className={styles.summary__line}></div>
          </div>
          <div className={styles.summary__dates}>
            <div>
              <MimaText labelTitle="Quotation created on">
                {moment(quote.createdAt).format("DD/MM/YY")}
              </MimaText>
            </div>
            <div>
              <MimaText labelTitle="Quotation due">
                {moment(quote.dueDate).format("DD/MM/YY")}
              </MimaText>
            </div>
          </div>
          <div className={styles.summary__services}>
            <MimaText
              variant="bodyBold"
              color="var(--color-fash-primary-3)"
              mb={1}
            >
              Service
            </MimaText>
            {quote.orders.map((order, id) => (
              <InvoiceSummaryItem
                key={id}
                service={order?.item}
                quantity={order.quantity}
                amount={amountFormatter(quote.currencyCode).format(
                  order.unitPrice
                )}
                totalAmount={amountFormatter(quote.currencyCode).format(
                  order.itemPrice
                )}
              />
            ))}

            <div className={styles.summary__line}></div>
            {quote.vat?.amount > 0 && (
              <div className={styles.summary__total}>
                <MimaText>VAT</MimaText>
                <MimaText
                  variant="bodyBold"
                  color="var(--color-fash-primary-3)"
                >
                  {amountFormatter(quote.currencyCode).format(
                    quote.vat.amount || 0
                  )}
                </MimaText>
              </div>
            )}

            {quote.wht?.amount > 0 && (
              <div className={styles.summary__total}>
                <MimaText>WHT</MimaText>
                <MimaText
                  variant="bodyBold"
                  color="var(--color-fash-primary-3)"
                >
                  {amountFormatter(quote.currencyCode).format(
                    quote?.wht?.amount || 0
                  )}
                </MimaText>
              </div>
            )}
            <div className={styles.summary__total}>
              <MimaText>Discounted Amount</MimaText>
              <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
                {amountFormatter(quote.currencyCode).format(
                  quote.discountAmount
                )}
              </MimaText>
            </div>
            <div className={styles.summary__total}>
              <MimaText>Shipping Fee</MimaText>
              <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
                {amountFormatter(quote.currencyCode).format(
                  quote.shipping || 0
                )}
              </MimaText>
            </div>
            <div className={styles.summary__total}>
              <MimaText>Total</MimaText>
              <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
                {amountFormatter(quote.currencyCode).format(
                  quote.transactionAmount
                )}
              </MimaText>
            </div>
            <div>
              <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
                Terms & Conditions
              </MimaText>
              <MimaText>{quote.note}</MimaText>
              <div className={styles.summary__button}>
                <MimaButton
                  title="Done"
                  variant="form"
                  buttonColor="var(--color-dark-3)"
                  width={20}
                  onClick={closeModal}
                />
                <MimaButton
                  // title={`Send ${type}`}
                  title="Send Quote"
                  onClick={() => sendQuotation(quote._id)}
                  variant="form"
                  width={20}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuotationSummary;
