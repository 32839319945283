import { useNavigate } from "react-router-dom";
import constant from "../utils/constant";

const useUtitlityRoutes = () => {
  const navigate = useNavigate();
  const switchToLanding = () => {
    navigate(constant.Routes.Home);
  };
  const switchToSignup = () => {
    navigate(constant.Routes.SignUp);
  };

  const switchToLogin = () => {
    navigate(constant.Routes.Login);
  };

  const switchToForgotPassword = () => {
    navigate(constant.Routes.ForgotPassword);
  };
  return {
    switchToLanding,
    switchToSignup,
    switchToLogin,
    switchToForgotPassword,
  };
};

export default useUtitlityRoutes;
