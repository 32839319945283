import styles from "../../assets/styles/Wallet/TransferFunds.module.scss";
import { Searchbar } from "../Searchbar";
import React, { useState } from "react";
import MimaText from "../MimaText";
import PropTypes from "prop-types";
import { MdDeleteForever } from "react-icons/md";
import { MimaButton } from "../Button";
import { WalletController } from "../../controllers";
import { MimaToastUtil } from "../Toast";
import constant from "../../utils/constant";
import PageLoader from "../PageLoader";
import { useEffect } from "react";
import { useStores, walletStore } from "../../stores";

const propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.array.isRequired,
};

const defaultProps = {
  onClick: () => {},
  data: [],
};

const Beneficiaries = ({ onSelectBeneficiary, ...props }) => {
  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");

  const {
    appStore: { beneficiaries },
  } = useStores();

  useEffect(() => {
    const getBeneficiaryList = async () => {
      setLoading(true);
      const { status, data, errorMessage } =
        await WalletController.beneficiaries();
      if (status === constant.Success) {
        setLoading(false);
      }
    };
    getBeneficiaryList();
  }, []);

  const deleteBeneficiaryHandler = async () => {
    setLoading(true);
    const { status, data, errorMessage } =
      await WalletController.deleteBeneficiary(id);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      setConfirmDelete(false);
      setLoading(false);
    } else {
      setLoading(false);
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  return (
    <div>
      {loading ? (
        <>
          <PageLoader title="while we load your information" />
        </>
      ) : (
        <>
          {confirmDelete ? (
            <>
              <MimaText
                width={38}
                align="center"
                mb={2}
                color="var(--color-error)"
              >
                Are you sure you want to Delete this Beneficiary
              </MimaText>
              <div className={styles.beneficiaries}>
                <MimaButton
                  title="Yes"
                  width={18}
                  variant="regular"
                  buttonColor="var(--color-fash-primary)"
                  titleColor="var(--color-dark)"
                  onClick={deleteBeneficiaryHandler}
                />
                <MimaButton
                  title="No"
                  width={18}
                  buttonColor="var(--color-dark)"
                  variant="regular"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {beneficiaries?.length === 0 ? (
                <>
                  <MimaText>No Beneficiaries added</MimaText>
                </>
              ) : (
                <>
                  <Searchbar
                    placeholder="Search Beneficiaries"
                    input={beneficiaries}
                    loading={false}
                    output={setFilteredBeneficiaries}
                    handleFilter={(item, searchQuery) => {
                      return (
                        item?.value?.accountName
                          ?.toLowerCase()
                          ?.includes(searchQuery) ||
                        item?.value?.accountNumber
                          ?.toLowerCase()
                          ?.includes(searchQuery) ||
                        item?.value?.bankName
                          ?.toLowerCase()
                          ?.includes(searchQuery) ||
                        item?.value?.nickName
                          ?.toLowerCase()
                          ?.includes(searchQuery)
                      );
                    }}
                  />
                  <div style={{ marginTop: "4rem" }}>
                    {filteredBeneficiaries.map((item) => {
                      return (
                        <div key={item.key}>
                          <div
                            className={styles.beneficiaries}
                            onClick={() => onSelectBeneficiary(item)}
                          >
                            <div className={styles.beneficiaries__details}>
                              <p>{item.value.accountName}</p>
                              <p>{item.value.accountNumber}</p>
                              <p className={styles.beneficiaries__bank}>
                                {item.value.bankName}
                              </p>
                              <p>{item.value.nickName}</p>
                            </div>
                            <div
                              className={styles.beneficiaries__delete}
                              onClick={(e) => {
                                e.stopPropagation();
                                setId(item.key);
                                setConfirmDelete(true);
                              }}
                            >
                              <MdDeleteForever />
                              <p> Delete </p>
                            </div>
                          </div>

                          <div className={styles.line}></div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

Beneficiaries.propTypes = propTypes;

Beneficiaries.defaultProps = defaultProps;

export default Beneficiaries;
