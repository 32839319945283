import React, { useMemo, useState } from "react";
import * as yup from "yup";
import { appStore, invoiceStore, useStores } from "../../../stores";
import constant from "../../../utils/constant";
import { getAvailableQuantity } from "../../../utils/utils";
import { InvoiceController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import { object, string, number, array, boolean } from "yup";
import { useGetAllStocks } from "../../../controllers/InventoryController";

const useEditInvoiceLogic = ({ closeModal }) => {
  const [stage2, setStage2] = useState(false);
  const {
    appStore: { loading },
  } = useStores();

  const invoice = invoiceStore.selectedInvoice;
  const invoiceItem = invoice.status === constant.TRANSACTION_STATUS.UNPAID;

  const { stocks } = useGetAllStocks();

  const acceptPartPayValue =
    invoice.transactionAmount - invoice.deposit !== 0 ? true : false;

  const orders = useMemo(() => {
    return invoice?.orders?.map((order) => {
      return {
        quantity: order.quantity,
        unitPrice: order.unitPrice,
        item: order.item,
        stock: order.stock,
        availableStockQuantity: getAvailableQuantity(order.stock, stocks),
        selectFromStock: !!order.stock,
      };
    });
  }, [invoice.orders, stocks]);

  const invoiceDetailsValidation = () => {
    return object({
      customer: string().required("Select a customer"),
      orders: array(
        object({
          availableStockQuantity: number(),
          selectFromStock: boolean(),
          // quantity: number().min(0.1, 'Quantity should be greater than 0.1'),
          quantity: number().when("selectFromStock", {
            is: true,
            then: number()
              .min(1, "Quantity should be atleast 1")
              .max(yup.ref("availableStockQuantity"), ({ max }) => {
                return `only ${max} units left in stock.`;
              }),
            otherwise: number().min(1, "Quantity should be atleast 1"),
          }),
          unitPrice: number().min(1, "Unit price should be greater than 1"),
          item: string().required("Service is required"),
        })
      ),
      currencyCode: string().required("Select Currency"),
      vat: boolean().required("Are you charging VAT?"),
      wht: boolean().required("Are you deducting WHT?"),
      dueDate: string().required("Enter Payment due date"),
      discountPer: number()
        .min(0, "Min discount should be greater than 0%")
        .max(100, "Max discount should be less than or equal to 100%"),
      // depositPer: number()
      // 	.min(1, 'Min deposit should be greater than 1%')
      // 	.max(100, 'Max deposit should be less than or equal to 100%')
      // 	.required('Deposit is required'),
      salesChannel: string().required("Sales Channel is required"),
      chargeSplit: string().test(function () {
        const { currencyCode, chargeSplit } = this.parent;
        return currencyCode !== "NGN" && !chargeSplit
          ? this.createError({
              message: "Please select who bears payment charges",
              path: "chargeSplit",
            })
          : true;
      }),
      acceptPartPay: boolean(),
      partPayment: number().when("acceptPartPay", {
        is: true,
        then: (schema) => schema.required("Part payment is required"),
        otherwise: (schema) => schema.optional(),
      }),

      balanceDueDate: string().test(function () {
        const { balanceDueDate, acceptPartPay } = this.parent;
        return acceptPartPay &&
          balanceAmount(this.parent) > 0 &&
          !balanceDueDate
          ? this.createError({
              message: "Balance due date is required for the expected balance",
              path: "balanceDueDate",
            })
          : true;
      }),
    });
  };

  const receiptDetailsValidation = () => {
    return object({
      customer: string().required("Select a customer"),
      orders: array(
        object({
          quantity: number().min(0.1, "Quantity should be greater than 0.1"),
          unitPrice: number().min(1, "Unit price should be greater than 1"),
          item: string().required("Service is required"),
        })
      ),
      currencyCode: string().required("Select Currency"),
      paymentMethod: string().required("Select payment method"),
      // balanceDueDate: string().test(function () {
      // 	const { balanceDueDate } = this.parent;
      // 	return balanceAmount(this.parent) > 0 && !balanceDueDate
      // 		? this.createError({
      // 				message: 'Balance due date is required for the expected balance',
      // 				path: 'balanceDueDate',
      // 		  })
      // 		: true;
      // }),
      paidDate: string().required("Enter Payment date"),
      discountPer: number()
        .min(0, "Min discount should be greater than 0%")
        .max(100, "Max discount should be less than or equal to 100%"),
      // deposit: number()
      // 	.min(1, 'Minimum deposit amount should be greater than 1')
      // 	.required('Amount Received is required')
      // 	.test(function () {
      // 		return totalAmountCal(this.parent) < this.parent.deposit
      // 			? this.createError({
      // 					message: `Maximum amount received should be ${amountFormatter().format(
      // 						totalAmountCal(this.parent)
      // 					)}`,
      // 					path: 'deposit',
      // 			  })
      // 			: true;
      // 	}),
      salesChannel: string().required("Sales Channel is required"),
    });
  };

  const balanceAmount = (values) => {
    return values.partPayment > 0
      ? totalAmountCal(values) - values.partPayment
      : 0;
  };

  const totalAmountCal = (values) =>
    totalWithoutDiscount(values) + values.shipping - values.discountAmount;

  const totalWithoutDiscount = (values) => {
    const totalItemPrice = values?.orders?.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.quantity * currentValue.unitPrice,
      0
    );
    let totalAmount = totalItemPrice;
    totalAmount = values.vat ? totalAmount + totalAmount * 0.075 : totalAmount;
    totalAmount = values.wht ? totalAmount - totalAmount * 0.05 : totalAmount;
    return totalAmount;
  };

  const discountAmountCal = (values) =>
    totalWithoutDiscount(values) - values.discountAmount || 0;

  const discountPerCal = (values) =>
    (values.discountAmount / totalWithoutDiscount(values)) * 100 || 0;

  const depositPerCal = (values) =>
    (values.deposit / totalAmountCal(values)) * 100 || 0;

  const depositAmountCal = (values) =>
    (values.depositPer * totalAmountCal(values)) / 100 || 0;

  const onSubmit = async (values) => {
    let payload = { ...values };

    delete payload.selectFromStock;
    delete payload.customerName;
    payload.orders.forEach((item) => {
      !item.stock && delete item.stock;
      delete item.selectFromStock;
      delete item.availableStockQuantity;
    });

    if (invoiceItem) {
      delete payload.paidDate;
      delete payload.paymentMethod;
      if (payload.partPayment !== 0) {
        payload.deposit = payload.partPayment;
      } else {
        payload.deposit = totalAmountCal(payload);
      }
      if (!payload.chargeSplit) delete payload.chargeSplit;
      delete payload.acceptPartPay;
      delete payload.partPayment;
      // payload.discountAmount = discountAmountCal(payload);
    } else {
      delete payload.dueDate;
      delete payload.acceptPartPay;
      delete payload.partPayment;
      // payload.discountAmount = discountAmountCal(payload);
      if (payload.balanceDueDate) {
        payload.balanceDueDate = payload.balanceDueDate.toDateString();
      }
      if (payload.paidDate) {
        payload.paidDate = payload.paidDate.toDateString();
      }
    }

    payload.depositPer = (payload.deposit / totalAmountCal(payload)) * 100;

    appStore.setLoading(true);

    const { status, errorMessage } = await InvoiceController.updateInvoice(
      payload,
      invoiceStore.selectedInvoice._id
    );

    await InvoiceController.getInvoices();
    appStore.setLoading(false);

    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return goToStage2();
    }

    MimaToastUtil.error({ message: errorMessage });
  };

  const goToStage2 = () => {
    setStage2(true);
  };

  const onCloseModal = () => {
    invoiceStore.setCopiedInvoice({});
    closeModal();

    return;
  };

  return {
    invoiceDetailsValidation,
    receiptDetailsValidation,
    loading,
    balanceAmount,
    stage2,
    invoice,
    orders,
    onSubmit,
    invoiceItem,
    discountAmountCal,
    depositAmountCal,
    discountPerCal,
    depositPerCal,
    acceptPartPayValue,
  };
};

export default useEditInvoiceLogic;
