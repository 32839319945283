import styles from "../../assets/styles/Wallet/AccountInfoCard.module.scss";
import { MimaText, MimaToastUtil } from "../../components";
import { MdOutlineContentCopy } from "react-icons/md";
import React from "react";

const AccountInfoCard = ({ bankAccounts }) => {
  return (
    <div className={styles.ai}>
      <MimaText variant="bodyBold">
        To fund your wallet with Naira, please make a transfer to any of the
        account details below
      </MimaText>
      {bankAccounts.map((bankAccount) => (
        <div className={styles.ai__bank}>
          <MimaText variant="small" color="var(--color-fash-primary-3)">
            <b>{bankAccount ? bankAccount.bank : ""}</b>
          </MimaText>
          <MimaText>{bankAccount ? bankAccount.accountName : ""}</MimaText>
          <MimaText variant="subtitleBold" color="var(--color-fash-primary-3)">
            {bankAccount ? bankAccount.accountNumber : ""}
          </MimaText>
          <div className={styles.ai__line}></div>
          <div
            onClick={() => {
              navigator.clipboard.writeText(
                bankAccount ? bankAccount.accountNumber : ""
              );
              MimaToastUtil.success({
                message: "Copied!",
              });
            }}
            className={styles.ai__copy}
          >
            <MdOutlineContentCopy />
            <MimaText variant="smallBold" color="var(--color-white)">
              Click to copy to Account number
            </MimaText>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccountInfoCard;
