// import {
// 	TransferSuccessContainer,
// 	TransferFailedContainer,
// 	TransferPinConfirmationContainer,
// } from '../../../containers';
import { MimaButton, MimaInput, MimaText, Modal } from "../../../components";
import { businessStore, userStore } from "../../../stores";
import ConfirmTransfer from "../ConfirmTransfer";
import { MdOutlineInfo } from "react-icons/md";
import { Formik } from "formik";
import React from "react";
import TransferPinConfirm from "../TransferPinConfirm";
import TransferSuccess from "../TransferSuccess";
import TransferFailed from "../TransferFailed";
import useWithdrawLogic from "../useLogics/useWithdrawLogic";

const Withdraw = ({
  pinContent,
  setPinContent,
  closeModal,
  sharedState,
  transferSwitchState,
}) => {
  const {
    validationSchema,
    goBack,
    onSubmit,
    loading,
    onSubmitTransferPin,
    data,
  } = useWithdrawLogic({
    pinContent,
    setPinContent,
    transferSwitchState,
    closeModal,
  });
  const bankAccount =
    userStore.user.accountType === "Business"
      ? businessStore.business["withdrawalBankAccount"]
      : userStore.user["withdrawalBankAccount"];

  // console.log("bankAccount", bankAccount);
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {sharedState.pinContent ? (
        <TransferPinConfirm
          transferSwitchState={transferSwitchState}
          payload={data}
          loading={loading}
          onSubmitTransferPinConfirm={onSubmitTransferPin}
        />
      ) : sharedState.confirmTransferContent ? (
        <ConfirmTransfer closeModal={closeModal} />
      ) : sharedState.transferSuccessContent ? (
        <TransferSuccess closeModal={closeModal} payload={data} />
      ) : sharedState.transferFailedContent ? (
        <TransferFailed
          closeModal={closeModal}
          transferSwitchState={transferSwitchState}
        />
      ) : (
        <>
          {!bankAccount ? (
            <MimaText variant="subtitle">
              Kindly set your withdrawal account in the Settings Page.
            </MimaText>
          ) : (
            <>
              <MimaText variant="subtitle">Withdraw Funds To: </MimaText>
              <div>
                <MimaText color="var(--color-fash-primary-3)" align="center">
                  Bank: {bankAccount.bank}
                </MimaText>
                <MimaText color="var(--color-fash-primary-3)" align="center">
                  Account Name: {bankAccount.accountName}
                </MimaText>
                <MimaText color="var(--color-fash-primary-3)" align="center">
                  Account Number: {bankAccount.accountNumber}
                </MimaText>
              </div>
            </>
          )}
          <MimaText variant="subtitle"></MimaText>
          <MimaText
            mb={2}
            variant="small"
            color="var(--color-error)"
            width={38}
          >
            {" "}
            <MdOutlineInfo /> Note: This action will not add to your expense.
            Use the <b>Make Payment</b> feature, If this is an expense.
          </MimaText>
          <Formik
            initialValues={{
              transactionAmount: 0,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div>
                <MimaInput
                  type="number"
                  labelTitle="Amount"
                  placeholder="Enter Amount"
                  name="transactionAmount"
                  mt={2}
                  mb={2}
                  id="transactionAmount"
                  value={values.transactionAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.transactionAmount}
                  touched={touched.transactionAmount}
                />
                <MimaButton
                  title="Continue"
                  onClick={handleSubmit}
                  type="submit"
                  variant="form"
                />
              </div>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};

export default Withdraw;
