import React, { useState, useEffect } from "react";
import { PaymentLink } from "../../../pages";
import { MimaToastUtil } from "../../../components";
import { PaymentLinkController } from "../../../controllers";
import { paymentLinkStore } from "../../../stores";

const usePaymentLink = () => {
  const [viewLinks, setViewLinks] = useState(true);
  const [createLinks, setCreateLinks] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editLink, setEditLink] = useState(false);
  const [deleteLink, setDeleteLink] = useState(false);

  useEffect(() => {
    const allCreatedLinks = async () => {
      setLoading(true);
      await PaymentLinkController.getAllPaymentLinks();
      setLoading(false);
    };

    allCreatedLinks();
  }, []);

  const goToViewLinks = () => {
    setViewLinks(true);
    setCreateLinks(false);
    setEditLink(false);
  };

  const refresh = async () => {
    setLoading(true);
    await PaymentLinkController.getAllPaymentLinks();
    setLoading(false);
  };

  const goToCreateLinks = () => {
    setViewLinks(false);
    setCreateLinks(true);
  };

  const goToEditLinks = (link) => {
    setViewLinks(false);
    setEditLink(true);
    paymentLinkStore.setSelectedPaymentLink(link);
  };

  const goToDeleteLink = (link) => {
    paymentLinkStore.setSelectedPaymentLink(link);
    setDeleteLink(true);
  };

  const copySelectedLink = (link) => {
    navigator.clipboard.writeText(link.paymentUrl);
    MimaToastUtil.success({
      message: "Copied!",
    });
  };

  const cancelEdit = () => {
    setViewLinks(true);
    setEditLink(false);
    paymentLinkStore.setSelectedPaymentLink();
  };

  const closeModal = () => {
    paymentLinkStore.setSelectedPaymentLink();
    setDeleteLink(false);
  };

  const paymentLinkNav = [
    {
      title: "View Links",
      clickAction: goToViewLinks,
      activeState: viewLinks,
    },
    {
      title: "Create Payment Link",
      clickAction: goToCreateLinks,
      activeState: createLinks,
    },
  ];

  const handleOptionSelect = (_id, link, option) => {
    if (option.value === "Edit Link") {
      goToEditLinks(link);
    } else if (option.value === "Deactivate Link") {
      goToDeleteLink(link);
    } else if (option.value === "Copy Link Url") {
      copySelectedLink(link);
    }
  };

  return {
    viewLinks,
    createLinks,
    paymentLinkNav,
    loading,
    editLink,
    refresh,
    handleOptionSelect,
    closeModal,
    deleteLink,
    cancelEdit,
    goToCreateLinks,
    goToViewLinks,
  };
};

export default usePaymentLink;
