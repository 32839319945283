import React from "react";
import { MimaText, Modal } from "../../components";

const ViewNote = ({ closeModal, item }) => {
  return (
    <Modal closeModal={closeModal}>
      <MimaText
        variant="subtitleBold"
        align="center"
        mb={4}
      >{`Style request note from ${item.customer.fullname}`}</MimaText>
      <MimaText variant="body">{item.note}</MimaText>
    </Modal>
  );
};

export default ViewNote;
