import {
  MimaInput,
  MimaText,
  MimaTextArea,
  ReceiptCheckBox as RCB,
  MimaButton,
  PageLoader,
  MimaDropdown,
} from "../../components";
import { currencies, chargeSplitValues } from "../../utils/utils";
import styles from "../../assets/styles/Booking/Booking.module.scss";
import { Formik } from "formik";
import React from "react";

const CreateBooking = ({
  validationSchemaWithFreeBooking,
  validationSchemaWithoutFreeBooking,
  onSubmitCreateBooking,
  loading,
  createLinks,
}) => {
  const initialValues = {
    amount: "",
    minDeposit: "",
    currencyCode: "NGN",
    title: "",
    description: "",
    chargeSplit: "",
    durationInMinutes: 0,
    videoConferencingLink: "",
    acceptPhoneNumber: false,
    availableWeekends: false,
    acceptPartPayment: false,
    previewAmount: "",
    previewFirstName: "",
    previewLastName: "",
    previewEmail: "",
    previewMobile: "",
    previewQuantity: 1,
    freeBooking: false,
  };
  const validationSchema = !initialValues.freeBooking
    ? validationSchemaWithFreeBooking
    : validationSchemaWithoutFreeBooking;
  return (
    <div className={styles.main}>
      <div className={styles.main__content}>
        {createLinks && (
          <div>
            <MimaText
              variant="subtitleBold"
              mb={2}
              align="center"
              color="var(--color-dark)"
            >
              Create Booking Link
            </MimaText>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitCreateBooking}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              }) => {
                return (
                  <div className={styles.createLinks}>
                    <div className={styles.createLinks_1}>
                      {loading ? (
                        <PageLoader title="While your link is being generated" />
                      ) : (
                        <div>
                          <MimaInput
                            labelTitle="Title"
                            variant="form2"
                            placeholder="Name"
                            name="title"
                            id="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched.title}
                            error={errors.title}
                          />

                          <MimaTextArea
                            labelTitle="Description"
                            textAreaVariant="type3"
                            placeholder="Description"
                            name="description"
                            id="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched.description}
                            error={errors.description}
                          />
                          <div className={styles.beside}>
                            <MimaInput
                              labelTitle="Video Conferencing Url"
                              variant="form2"
                              placeholder="Zoom link or google meet"
                              name="videoConferencingLink"
                              id="videoConferencingLink"
                              value={values.videoConferencingLink}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              touched={touched.videoConferencingLink}
                            />
                            <MimaInput
                              labelTitle="How many minutes is a session?"
                              variant="form2"
                              type="number"
                              placeholder="30"
                              name="durationInMinutes"
                              id="durationInMinutes"
                              value={values.durationInMinutes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              mb={1}
                              touched={touched.durationInMinutes}
                              error={errors.durationInMinutes}
                            />
                          </div>

                          <div className={styles.beside}>
                            <MimaText variant="small">
                              Do you accept free booking sessions?
                            </MimaText>
                            <div className={styles.beside}>
                              <RCB
                                title="Yes"
                                onChange={() => {
                                  setFieldValue("freeBooking", true);
                                }}
                                checked={values.freeBooking}
                                value="true"
                                name="freeBooking"
                              />
                              <RCB
                                title="No"
                                checked={!values.freeBooking}
                                onChange={() =>
                                  setFieldValue("freeBooking", false)
                                }
                                value="false"
                                name="freeBooking"
                              />
                            </div>
                            {errors.acceptQuantity ? (
                              <div className={styles.error}>
                                {""}
                                {touched.acceptQuantity &&
                                  errors.acceptQuantity}
                                {""}
                              </div>
                            ) : null}
                          </div>

                          {values.freeBooking !== true && (
                            <>
                              <div
                                className={
                                  values.amount !== "" ? styles.beside : ""
                                }
                              >
                                <MimaInput
                                  labelTitle="Amount"
                                  variant="form2"
                                  type="number"
                                  placeholder="Amount"
                                  name="amount"
                                  id="amount"
                                  value={values.amount}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  touched={touched.amount}
                                  error={errors.amount}
                                />

                                {values.amount !== "" ? (
                                  <div className={styles.beside}>
                                    <MimaText variant="small" mt={2}>
                                      Accept Part payment?
                                    </MimaText>
                                    <div className={styles.beside}>
                                      <RCB
                                        title="Yes"
                                        onChange={() => {
                                          setFieldValue(
                                            "acceptPartPayment",
                                            true
                                          );
                                        }}
                                        checked={values.acceptPartPayment}
                                        value="true"
                                        name="acceptPartPayment"
                                      />
                                      <RCB
                                        title="No"
                                        checked={!values.acceptPartPayment}
                                        onChange={() =>
                                          setFieldValue(
                                            "acceptPartPayment",
                                            false
                                          )
                                        }
                                        value="false"
                                        name="acceptPartPayment"
                                      />
                                    </div>
                                    {errors.acceptPartPayment ? (
                                      <div className={styles.error}>
                                        {""}
                                        {touched.acceptPartPayment &&
                                          errors.acceptPartPayment}
                                        {""}
                                      </div>
                                    ) : null}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              {values.acceptPartPayment ? (
                                <MimaInput
                                  labelTitle="Minimum Part Payment (Optional)"
                                  variant="form2"
                                  type="number"
                                  placeholder="Minimum Part Payment"
                                  name="minDeposit"
                                  id="minDeposit"
                                  value={values.minDeposit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  mb={2}
                                  touched={touched.minDeposit}
                                  error={errors.minDeposit}
                                />
                              ) : (
                                ""
                              )}

                              <div className={styles.beside}>
                                <MimaDropdown
                                  labelTitle="Select Currency"
                                  name="currencyCode"
                                  value={values.currencyCode}
                                  placeholder={values.currencyCode}
                                  id="currencyCode"
                                  currentValue={values.currencyCode}
                                  onChange={(data) => {
                                    setFieldValue("currencyCode", data.key);
                                  }}
                                  data={currencies}
                                  touched={touched.currencyCode}
                                  error={errors.currencyCode}
                                  variant="filterForm"
                                  bodyVariant="bodyBase2"
                                  mb={-1}
                                />

                                <MimaDropdown
                                  labelTitle="Who bears the payment charge"
                                  name="chargeSplit"
                                  value={values.chargeSplit}
                                  placeholder="who bears the payment charge"
                                  id="chargeSplit"
                                  currentValue={values.chargeSplit}
                                  onChange={(data) => {
                                    setFieldValue("chargeSplit", data.key);
                                  }}
                                  data={chargeSplitValues}
                                  touched={touched.chargeSplit}
                                  error={errors.chargeSplit}
                                  variant="filterForm"
                                  bodyVariant="bodyBase2"
                                />
                              </div>
                            </>
                          )}
                          <div className={styles.beside}>
                            <MimaText variant="small">
                              Collect Phone Number?
                            </MimaText>
                            <div className={styles.beside}>
                              <RCB
                                title="Yes"
                                onChange={() => {
                                  setFieldValue("acceptPhoneNumber", true);
                                }}
                                checked={values.acceptPhoneNumber}
                                value="true"
                                name="acceptPhoneNumber"
                              />
                              <RCB
                                title="No"
                                checked={!values.acceptPhoneNumber}
                                onChange={() =>
                                  setFieldValue("acceptPhoneNumber", false)
                                }
                                value="false"
                                name="acceptPhoneNumber"
                              />
                            </div>
                            {errors.acceptPhoneNumber ? (
                              <div className={styles.error}>
                                {""}
                                {touched.acceptPhoneNumber &&
                                  errors.acceptPhoneNumber}
                                {""}
                              </div>
                            ) : null}
                          </div>

                          <div className={styles.beside}>
                            <MimaText variant="small">
                              Do you accept weekends booking sessions?
                            </MimaText>
                            <div className={styles.beside}>
                              <RCB
                                title="Yes"
                                onChange={() => {
                                  setFieldValue("availableWeekends", true);
                                }}
                                checked={values.availableWeekends}
                                value="true"
                                name="availableWeekends"
                              />
                              <RCB
                                title="No"
                                checked={!values.availableWeekends}
                                onChange={() =>
                                  setFieldValue("availableWeekends", false)
                                }
                                value="false"
                                name="availableWeekends"
                              />
                            </div>
                            {errors.acceptQuantity ? (
                              <div className={styles.error}>
                                {""}
                                {touched.acceptQuantity &&
                                  errors.acceptQuantity}
                                {""}
                              </div>
                            ) : null}
                          </div>

                          <MimaButton
                            title="Create Booking Link"
                            onClick={handleSubmit}
                            type="submit"
                            loading={loading}
                            variant="form"
                            mt={2}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateBooking;
