import styles from "../../assets/styles/Invoice/Invoice.module.scss";
import { BusinessInsightComponent as BIC } from "../../components/Home";
import { amountFormatter } from "../../utils/utils";
import { businessStore, transactionStore, userStore } from "../../stores";
import React, { useState } from "react";
import { MimaText } from "../../components";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";

const TransactionInsightsCard = ({ filterPeriod }) => {
  const summary = transactionStore.transactionsSummary;
  const [curr, setCurr] = useState(
    `${businessStore.defaultCurrency ? businessStore.defaultCurrency : summary[0]?.currencyCode}`
  );
  const [showCurr, setShowCurr] = useState(false);

  return (
    <div className={styles.upper__multiple}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <MimaText color="var(--color-fash-primary-3)" mb={1} width={30}>
          Transaction Insights for {summary.length === 0 ? filterPeriod : curr}{" "}
          transactions
        </MimaText>

        {summary.length > 1 ? (
          <div className={styles.selectCurrency__text}>
            <MimaText
              variant="small"
              color="var(--color-primary-4)"
              style={{ paddingTop: ".5rem" }}
              width={12}
            >
              Click arrow for other currencies
            </MimaText>

            <div className={styles.selectCurrency}>
              <div
                className={
                  !showCurr
                    ? styles.selectCurrency__icon
                    : styles.selectCurrency__icon_active
                }
                onClick={() => {
                  setShowCurr(!showCurr);
                }}
              >
                <MdKeyboardArrowDown />
              </div>

              <AnimatePresence>
                {showCurr && (
                  <motion.div
                    initial={{
                      opacity: 0,
                      scaleY: 0,
                    }}
                    animate={{
                      scaleY: 1,
                      opacity: 1,
                      transition: {
                        duration: 0.2,
                      },
                    }}
                    exit={{
                      scaleY: 0,
                      opacity: 0,
                      transition: {
                        duration: 0.2,
                      },
                    }}
                    style={{ originY: 0 }}
                    className={styles.selectCurrency__list}
                  >
                    {summary.map((item) => (
                      <li
                        key={item.currencyCode}
                        className={
                          curr === item.currencyCode
                            ? styles.selectCurrency__list_active
                            : ""
                        }
                        onClick={() => {
                          setCurr(item.currencyCode);
                          setShowCurr(false);
                        }}
                      >
                        {item.currencyCode}
                      </li>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <>
        {summary.length === 0 ? (
          <div className={styles.upper__metrics}>
            <BIC
              title="Total Inflow"
              amount={0}
              styleClass={styles.upper__metrics_paid}
            />

            <BIC
              title="Total OutFlow"
              amount={0}
              styleClass={styles.upper__metrics_unpaid}
            />
            {userStore.user.accountType === "Business" ? (
              <BIC
                title="Outstanding Balance"
                amount={0}
                styleClass={styles.upper__metrics_partPaid}
              />
            ) : (
              ""
            )}
            <BIC
              title="You Owe"
              amount={0}
              styleClass={styles.upper__metrics_owe}
            />

            <BIC
              title="Total VAT"
              amount={0}
              styleClass={styles.upper__metrics_vat}
            />
          </div>
        ) : (
          <>
            {summary
              .filter((x) => {
                return x.currencyCode === curr;
              })
              .map((transactionsSummary, i) => (
                <div className={styles.upper__metrics} key={i}>
                  <BIC
                    title="Total Inflow"
                    amount={amountFormatter(
                      transactionsSummary.currencyCode
                    ).format(transactionsSummary?.totalIncome || 0)}
                    styleClass={styles.upper__metrics_paid}
                  />

                  <BIC
                    title="Total Outflow"
                    amount={amountFormatter(
                      transactionsSummary.currencyCode
                    ).format(transactionsSummary?.totalExpenses || 0)}
                    styleClass={styles.upper__metrics_unpaid}
                  />
                  {userStore.user.accountType === "Business" ? (
                    <BIC
                      title="Outstanding Balance"
                      amount={amountFormatter(
                        transactionsSummary.currencyCode
                      ).format(transactionsSummary?.totalExpectedBalance || 0)}
                      styleClass={styles.upper__metrics_partPaid}
                    />
                  ) : (
                    ""
                  )}
                  <BIC
                    title="You Owe"
                    amount={amountFormatter(
                      transactionsSummary.currencyCode
                    ).format(transactionsSummary?.totalAmountOwed || 0)}
                    styleClass={styles.upper__metrics_owe}
                  />
                  {userStore.user.accountType === "Business" ? (
                    <>
                      <BIC
                        title="Total VAT"
                        amount={amountFormatter(
                          transactionsSummary.currencyCode
                        ).format(transactionsSummary.totalVAT || 0)}
                        styleClass={styles.upper__metrics_vat}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </>
        )}
      </>
    </div>
  );
};

export default TransactionInsightsCard;
