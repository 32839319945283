import { MdPayments, MdPeopleAlt, MdReceiptLong } from "react-icons/md";
import { FaHome, FaWallet } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import constant from "../../utils/constant";
import { SlLink } from "react-icons/sl";
import React from "react";
import { BsCalendarCheck } from "react-icons/bs";
import { GiExpander } from "react-icons/gi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { TbRulerMeasure } from "react-icons/tb";
import { FaRegNewspaper } from "react-icons/fa";
import { TiContacts } from "react-icons/ti";
import { CgWebsite } from "react-icons/cg";

export const SidebarData = [
  {
    title: "Dashboard",
    module: "TRANSACTION",
    type: "GENERAL",
    icon: <FaHome />,
    link: constant.Routes.Dashboard,
  },

  {
    title: "Styles",
    // module: "CUSTOMER",
    icon: <CgWebsite />,
    link: constant.Routes.Styles,
  },
  {
    title: "Orders",
    // module: 'ORDERS',
    icon: <AiOutlineShoppingCart />,
    link: constant.Routes.Orders,
  },
  {
    title: "Payment Link",
    module: "PAYMENT-LINK",
    icon: <SlLink />,
    link: constant.Routes.PaymentLink,
  },
  {
    title: "Booking",
    module: "PAYMENT-LINK",
    icon: <BsCalendarCheck />,
    link: constant.Routes.Booking,
  },
  {
    title: "Wallet",
    module: "WALLET",
    type: "GENERAL",
    icon: <FaWallet />,
    link: constant.Routes.Wallet,
  },
  {
    title: "Withdrawal Accounts",
    // module: "WALLET",
    type: "GENERAL",
    icon: <FaWallet />,
    link: constant.Routes.WithdrawalAccounts,
  },

  {
    title: "Customer",
    module: "CUSTOMER",
    icon: <MdPeopleAlt />,
    link: constant.Routes.Customer,
  },

  {
    title: "Invoice / Receipt",
    // module: "INVOICE/RECEIPT",
    icon: <MdReceiptLong />,
    link: constant.Routes.Invoice,
  },
  {
    title: "Transactions",
    // module: "TRANSACTION",
    // type: "GENERAL",
    icon: <MdPayments />,
    link: constant.Routes.Transactions,
  },

  {
    title: "Measurement",
    // module: "INVOICE/RECEIPT",
    icon: <TbRulerMeasure />,
    link: constant.Routes.Measurement,
  },
  {
    title: "Contact Us Request",
    // module: "TRANSACTION",
    // type: "GENERAL",
    icon: <TiContacts />,
    link: constant.Routes.ContactUs,
  },
  {
    title: "Newsletter Subscription",
    // module: "TRANSACTION",
    // type: "GENERAL",
    icon: <FaRegNewspaper />,
    link: constant.Routes.NewsLetter,
  },

  {
    title: "Settings",
    icon: <IoMdSettings />,
    type: "GENERAL",
    link: constant.Routes.Settings,
  },
];

export const ExpiredSidebarData = [
  {
    title: "Subscription Expiry",
    // module: 'BILL PAYMENT',
    type: "GENERAL",
    icon: <GiExpander />,
    link: constant.Routes.SubExpired,
  },
];
