import React from "react";
import { MimaInput, MimaPhoneInput } from "../../../components";

const SignupStepOne = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) => {
  return (
    <>
      <MimaInput
        labelTitle="Full Name"
        placeholder="Firstname Middlename Lastname"
        id="fullname"
        name="fullname"
        error={errors.fullname}
        touched={touched.fullname}
        value={values.fullname}
        onChange={handleChange}
        onBlur={handleBlur}
        mb={2}
        variant="form"
      />

      <MimaInput
        labelTitle="Business Name"
        placeholder="Enter your Business name"
        id="tradingName"
        name="tradingName"
        error={errors.tradingName}
        touched={touched.tradingName}
        value={values.tradingName}
        onChange={handleChange}
        onBlur={handleBlur}
        mb={2}
        variant="form"
      />

      <MimaInput
        labelTitle="Business Email"
        placeholder="Enter your Business Email"
        id="email"
        name="email"
        error={errors.email}
        touched={touched.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        mb={2}
        variant="form"
      />

      <MimaPhoneInput
        labelTitle="Phone Number"
        type="tel"
        placeholder="Enter your mobile number"
        id="mobile"
        name="mobile"
        error={errors.mobile}
        touched={touched.mobile}
        value={values.mobile}
        onChange={(value) => {
          setFieldValue("mobile", value);
        }}
        onBlur={handleBlur}
        mb={2}
        variant="form"
        defaultCountry="NG"
      />

      <MimaInput
        labelTitle="Business Registration Number (Optional)"
        placeholder="Enter your business reg. number"
        id="businessRegNum"
        name="businessRegNum"
        error={errors.businessRegNum}
        touched={touched.businessRegNum}
        value={values.businessRegNum}
        onChange={handleChange}
        onBlur={handleBlur}
        mb={2}
        variant="form"
      />
    </>
  );
};

export default SignupStepOne;
