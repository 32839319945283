import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class BillStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'BillStore',
			properties: ['powerVendors', 'airtimeVendors', 'tvVendors', 'bills', 'copiedBill'],
		});
	}

	powerVendors = [];
	airtimeVendors = [];
	tvVendors = [];
	bills = [];
	loading = false;
	cablePackages = [];
	copiedBill = [];

	setLoading = async value => {
		runInAction(async () => {
			this.loading = value;
		});
	};

	setCablePackages = async value => {
		runInAction(async () => {
			this.cablePackages = value;
		});
	};

	reset = () => {
		this.powerVendors = [];
		this.airtimeVendors = [];
		this.tvVendors = [];
		this.bills = [];
		this.copiedBill = [];
	}
	setBills = async value => {
		runInAction(async () => {
			this.bills = value;
		});
	};
	setPowerVendors = async vendors => {
		runInAction(async () => {
			this.powerVendors = vendors;
		});
	};

	setAirtimeVendors = async vendors => {
		runInAction(async () => {
			this.airtimeVendors = vendors;
		});
	};

	setTvVendors = async vendors => {
		runInAction(async () => {
			this.tvVendors = vendors;
		});
	};
	setCopiedBill = async state => {
		runInAction(async () => {
			this.copiedBill = state;
		});
	};
}
