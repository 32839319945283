import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  ReceiptCheckBox as RCB,
} from "../../../components";
import styles from "../../../assets/styles/Login/Login.module.scss";
import constant from "../../../utils/constant";
import { useStores } from "../../../stores";
import React, { useState } from "react";
import { Formik } from "formik";

const NewBeneficiary = ({
  loading,
  setLoading,
  verifyAccountNumber,
  goToBankList,
  selectedState,
  stage2,
  setStage2,
  validationSchemaNewBeneficiary,
  onSubmitNewBeneficiary,
}) => {
  const {
    appStore: { expenseCategories },
  } = useStores();
  const [accountName, setAccountName] = useState("");

  return (
    <Formik
      initialValues={{
        beneficiaryAccountNumber: "",
        transactionAmount: 0,
        beneficiaryBank: selectedState.key,
        narration: "",
        expenseCategory: "",
        saveBeneficiary: false,
        nickName: "",
      }}
      validationSchema={validationSchemaNewBeneficiary()}
      onSubmit={onSubmitNewBeneficiary}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <div>
          {!stage2 && (
            <div>
              <MimaButton
                variant="outlined"
                title={selectedState.value}
                labelTitle="Select Bank"
                width={38}
                height={5}
                mb={4}
                onClick={goToBankList}
              />
              {touched.beneficiaryBank && errors.beneficiaryBank ? (
                <div className={styles.error}> {errors.beneficiaryBank} </div>
              ) : null}
              <MimaInput
                type="text"
                labelTitle="Account Number"
                placeholder="Enter Account Number"
                name="beneficiaryAccountNumber"
                mt={2}
                mb={2}
                id="beneficiaryAccountNumber"
                value={values.beneficiaryAccountNumber}
                onChange={(e) => {
                  setLoading(true);
                  verifyAccountNumber({
                    accountNumber: e.target.value,
                    beneficiaryBank: values.beneficiaryBank,
                  }).then((data) => {
                    setAccountName(data);
                    setLoading(false);
                  });
                  handleChange(e);
                }}
                onBlur={handleBlur}
                error={errors.beneficiaryAccountNumber}
                touched={touched.beneficiaryAccountNumber}
              />
              <MimaInput
                labelTitle="Account Name"
                name="accountName"
                id="accountName"
                mt={2}
                value={values.accountName}
                disabled={true}
                placeholder={accountName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <MimaButton
                title="Continue"
                type="submit"
                disabled={loading}
                loading={loading}
                onClick={() =>
                  accountName ? setStage2(true) : setStage2(false)
                }
                variant="form"
              />
            </div>
          )}
          {stage2 && (
            <div>
              <MimaInput
                type="number"
                labelTitle="Amount"
                placeholder="Enter Amount"
                name="transactionAmount"
                mt={2}
                mb={2}
                id="transactionAmount"
                value={values.transactionAmount}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.transactionAmount}
                touched={touched.transactionAmount}
              />
              <MimaDropdown
                labelTitle="Expense Categories"
                placeholder="-Select-"
                data={expenseCategories}
                id="expenseCategory"
                onChange={(data) => {
                  setFieldValue("expenseCategory", data.key);
                }}
                name="expenseCategory"
                onBlur={handleBlur}
                error={errors.expenseCategory}
                touched={touched.expenseCategory}
              />
              <MimaInput
                labelTitle="Description"
                placeholder="Describe this expense"
                name="narration"
                mt={1}
                mb={3}
                id="narration"
                value={values.narration}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.narration}
                touched={touched.narration}
              />

              <div className={styles.checkbox__group}>
                <MimaText variant="small">
                  Do you want to save this beneficiary?
                </MimaText>
                <div className={styles.checkbox}>
                  <RCB
                    title="Yes"
                    onChange={() => {
                      setFieldValue("saveBeneficiary", true);
                    }}
                    checked={values.saveBeneficiary}
                    value="true"
                    name="saveBeneficiary"
                  />
                  <RCB
                    title="No"
                    checked={!values.saveBeneficiary}
                    onChange={() => setFieldValue("saveBeneficiary", false)}
                    value="false"
                    name="saveBeneficiary"
                  />
                </div>
              </div>

              {values.saveBeneficiary ? (
                <MimaInput
                  labelTitle="Nickname(Optional)"
                  placeholder="Add Nickname"
                  name="nickName"
                  mt={2}
                  mb={2}
                  id="nickName"
                  value={values.nickName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.nickName}
                  touched={touched.nickName}
                />
              ) : (
                ""
              )}

              <MimaButton
                title="Continue"
                type="submit"
                onClick={handleSubmit}
                variant="form"
              />
            </div>
          )}
        </div>
      )}
    </Formik>
  );
};

export default NewBeneficiary;
