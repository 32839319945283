import React, { useState, useEffect } from "react";
import styles from "../../assets/styles/Onboarding/SubscriptionExpired.module.scss";
import { IconButton, MimaText, PageLoader, MimaButton } from "../../components";
import {
  businessStore,
  exchangeStore,
  userStore,
  walletStore,
} from "../../stores";
import SubscribeImg from "../../assets/img/expired plans.png";
import { useNavigate } from "react-router-dom";
import constant from "../../utils/constant";
import { amountFormatter } from "../../utils/utils";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
// import {
// 	FundWalletContainer,
// 	LogoutContainer,
// 	RenewSubContainer,
// 	SubscriptionsContainer,
// 	WalletExchangeContainer,
// } from '../../containers';
import { ExchangeController, WalletController } from "../../controllers";
import {
  FreePlanConfirmation,
  Logout,
  RenewSub,
  Subscriptions,
} from "../Settings";
import { FundWallet, WalletExchange } from "../Wallet";

const SubscriptionExpired = () => {
  const [logoutModal, setLogoutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [renewModal, setRenewModal] = useState(false);
  const [freePlanModal, setFreePlanModal] = useState(false);
  const navigate = useNavigate();
  const [curr, setCurr] = useState(
    `${businessStore.defaultCurrency ? businessStore.defaultCurrency : "NGN"}`
  );
  const [showCurr, setShowCurr] = useState(false);
  const [hide, setHide] = useState(true);
  const [fundModal, setFundModal] = useState(false);
  const [exchangeModal, setExchangeModal] = useState(false);

  const hasBusinessAccount =
    businessStore?.business?.bankAccount ||
    businessStore?.business?.bankAccountTwo;

  useEffect(() => {
    const setTransactions = async () => {
      setLoading(true);
      await Promise.all([WalletController.walletDetails()]);
      setLoading(false);
    };
    setTransactions();
  }, [hasBusinessAccount]);

  const urlSet = window.location.href;

  let eye;
  !hide ? (eye = "eyeOpen") : (eye = "eyeClose");

  const balanceToggle = () => {
    setHide(!hide);
  };

  const fundWallet = (wallet) => {
    // isOnboardingDone();
    walletStore.setSelectedWallet(wallet);
    setFundModal(true);
  };

  const exchangeFunds = async (wallet) => {
    // isOnboardingDone();
    walletStore.setSelectedWallet(wallet);
    const exchangeCurrencies = {
      fromCurrency: wallet.currencyCode === "NGN" ? "USD" : wallet.currencyCode,
      toCurrency: "NGN",
    };
    exchangeStore.setExchangeCurrencies(exchangeCurrencies);
    let query;
    query = `fromCurrency=${exchangeCurrencies.fromCurrency}&toCurrency=${exchangeCurrencies.toCurrency}`;
    await ExchangeController.getCurrentExchangeRate(query);
    setExchangeModal(true);
  };

  const goToPaybills = () => {
    navigate(constant.Routes.PayBills);
  };

  const goTologout = () => {
    setLogoutModal(true);
  };

  const goToSubscription = () => {
    setSubModal(true);
  };

  const goToRenewSubscription = () => {
    setRenewModal(true);
  };
  const goToFreePlan = () => {
    setFreePlanModal(true);
  };

  const closeModal = () => {
    setExchangeModal(false);
    setFundModal(false);
    walletStore.setSelectedSubWallet({});
    walletStore.setSelectedWallet({});
    setLogoutModal(false);
    setSubModal(false);
    setRenewModal(false);
    setFreePlanModal(false);
  };

  const { subscription: currentSubscription } = businessStore.business;

  return (
    <div className={styles.container}>
      <div className={styles.topside}>
        <div className={styles.topside_1}>
          <p className={styles.topside_welcome}>
            Welcome back, <br />
            <span className={styles.topside_name}>
              {businessStore.business.tradingName}
            </span>
          </p>
        </div>
        <div className={styles.topside}>
          <div className={styles.topside_icon_main}>
            <div className={styles.topside_icon}>
              <IconButton variant="logout" onClick={goTologout} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.banner}>
        <div>
          {currentSubscription?.trialStartDate ? (
            <MimaText variant="h3" align="center" color="var(--color-white)">
              Your free trial has expired, kindly fund your wallet to Subscribe.
            </MimaText>
          ) : (
            <MimaText variant="h3" align="center" color="var(--color-white)">
              Your subscription has expired, kindly fund your wallet to renew
              your subscription.{" "}
            </MimaText>
          )}

          <MimaText mt={1} align="center" color="var(--color-white)">
            Subscribe to any of our plans to enjoy all our features.{" "}
          </MimaText>
        </div>

        {walletStore?.wallets
          ?.filter((x) => {
            return x.currencyCode === curr;
          })
          ?.map((wallet) => (
            <div className={styles.banner__walletBg} key={wallet._id}>
              <div className={styles.balance}>
                <div className={styles.balance__side}>
                  <MimaText
                    color="var(--color-primary)"
                    variant="bodyBold"
                    mb={2}
                  >
                    {wallet.currencyCode} Wallet
                  </MimaText>
                  {walletStore?.wallets?.length > 1 ? (
                    <div className={styles.selectCurrency__text}>
                      <div className={styles.selectCurrency}>
                        <div
                          className={
                            !showCurr
                              ? styles.selectCurrency__icon
                              : styles.selectCurrency__icon_active
                          }
                          onClick={() => {
                            setShowCurr(!showCurr);
                          }}
                        >
                          <MdKeyboardArrowDown />
                        </div>
                        <AnimatePresence>
                          {showCurr && (
                            <motion.div
                              initial={{
                                opacity: 0,
                                scaleY: 0,
                              }}
                              animate={{
                                scaleY: 1,
                                opacity: 1,
                                transition: {
                                  duration: 0.2,
                                },
                              }}
                              exit={{
                                scaleY: 0,
                                opacity: 0,
                                transition: {
                                  duration: 0.2,
                                },
                              }}
                              style={{ originY: 0 }}
                              className={styles.selectCurrency__list}
                            >
                              {walletStore?.wallets?.map((item) => (
                                <li
                                  key={item.currencyCode}
                                  className={
                                    curr === item.currencyCode
                                      ? styles.selectCurrency__list_active
                                      : ""
                                  }
                                  onClick={() => {
                                    setCurr(item.currencyCode);
                                    setShowCurr(false);
                                  }}
                                >
                                  {item.currencyCode}
                                </li>
                              ))}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.balance__amount}>
                  <MimaText>Balance</MimaText>
                  {loading ? (
                    <PageLoader
                      title="Refreshing record..."
                      loading={loading}
                    />
                  ) : (
                    <div className={styles.balance__side}>
                      <MimaText variant="h3">
                        {!hide
                          ? amountFormatter(wallet.currencyCode).format(
                              wallet.balance
                            )
                          : "*******"}
                      </MimaText>
                      <IconButton
                        onClick={() => balanceToggle()}
                        variant={eye}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.balance__btns}>
                <MimaButton
                  title="Fund Wallet"
                  onClick={() => fundWallet(wallet)}
                  buttonColor="var(--color-primary)"
                  variant="form"
                  width={14}
                />
                <MimaButton
                  title="Exchange"
                  variant="form"
                  onClick={() => exchangeFunds(wallet)}
                  width={14}
                  mt={2}
                />
              </div>
            </div>
          ))}
      </div>
      <img src={SubscribeImg} alt="Subscribe" className={styles.subImg} />
      <div className={styles.btns}>
        {/* <button className={styles.btns__btn} onClick={goToPaybills}>
          <span>Paybills</span>
          <span style={{ fontSize: "var(--default-font-small)" }}>
            Buy airtime, Data & Electricity
          </span>
        </button> */}

        {currentSubscription?.trialStartDate ? (
          <button className={styles.btns__btn} onClick={goToSubscription}>
            <span>Click to Subscribe</span>
            <span style={{ fontSize: "var(--default-font-small)" }}>
              Subscribe to our Premium services
            </span>
          </button>
        ) : (
          <button className={styles.btns__btn} onClick={goToRenewSubscription}>
            <span>Click to Renew Subscription</span>
            <span style={{ fontSize: "var(--default-font-small)" }}>
              Subscribe to our Premium services
            </span>
          </button>
        )}
        {/* <button className={styles.btns__btn} onClick={goToFreePlan}>
          <span>Downgrade to Free Plan</span>
          <span style={{ fontSize: "var(--default-font-small)" }}>
            Downgrade to our Free Plan services
          </span>
        </button> */}
      </div>

      {(logoutModal ||
        subModal ||
        renewModal ||
        fundModal ||
        exchangeModal) && (
        <div className="modal">
          <AnimatePresence>
            {logoutModal && <Logout closeModal={closeModal} />}
            {subModal && <Subscriptions closeModal={closeModal} />}
            {renewModal && <RenewSub closeModal={closeModal} />}
            {fundModal && (
              <FundWallet
                closeWalletModal={closeModal}
                bankAccounts={
                  userStore.user.accountType === "Business"
                    ? [
                        // businessStore?.business?.bankAccountTwo,
                        businessStore?.business?.bankAccount,
                      ]
                    : [userStore?.user?.bankAccount]
                }
                urlSet={urlSet}
                hasBusinessAccount={hasBusinessAccount}
              />
            )}
            {/* {fundModal && (
							<FundWalletContainer
								closeWalletModal={closeModal}
								bankAccounts={
									userStore.user.accountType === 'Business'
										? [
												// businessStore?.business?.bankAccountTwo,
												businessStore?.business?.bankAccount,
											]
										: [userStore?.user?.bankAccount]
								}
								urlSet={urlSet}
								hasBusinessAccount={hasBusinessAccount}
							/>
						)} */}
            {exchangeModal && <WalletExchange closeWalletModal={closeModal} />}
            {/* {exchangeModal && (
              <WalletExchangeContainer closeWalletModal={closeModal} />
            )} */}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

export default SubscriptionExpired;
