import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from 'mobx-persist-store';
export default class ExchangeStore {
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: 'ExchangeStore', properties: ['currentExchangeRate', 'exchangeCurrencies', "exchangeRateValue", "exchangePayload"] });
    }

    currentExchangeRate = 0;
    exchangeCurrencies = {};
    exchangeRateValue = 0;
    exchangePayload = {};

    

    reset = () => {
		this.currentExchangeRate = 0;
        this.exchangeCurrencies = {};
        this.exchangeRateValue = 0;
        this.exchangePayload = {};
	};

    setCurrentExchangeRate = async (state) => {
        runInAction(async() => {
            this.currentExchangeRate = state;
        });
    }

    setExchangeCurrencies = async (state) => {
        runInAction(async() => {
            this.exchangeCurrencies = state;
        });
    }

    setExchangeRateValue = async (state) => {
        runInAction(async() => {
            this.exchangeRateValue = state;
        });
    }

    setExchangePayload = async (state) => {
        runInAction(async() => {
            this.exchangePayload = state;
        });
    }
}
