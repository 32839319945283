import React, { useMemo, useState } from "react";
import {
  CustomerController,
  MeasurementController,
} from "../../../controllers";
import { measurementStore, userStore } from "../../../stores";
import * as yup from "yup";
import { phoneRegExp } from "../../../utils/utils";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";

const useMeasurementLogic = () => {
  const [limit, setLimit] = useState(50);
  const [filterQuery, setFilterQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [takeMeasurementModal, setTakeMeasurementModal] = useState(false);
  const [editMeasurementModal, setEditMeasurementModal] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [viewMoreModal, setViewMoreModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const createNewCustomer = () => {
    setNewCustomer(true);
  };

  const customerDetailsValidation = () => {
    return yup.object({
      fullname: yup.string().required("Customer name is required"),
      mobile: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Customer phone number is required"),
    });
  };

  const returnToMeasurement = () => {
    setNewCustomer(false);
  };

  const onSubmitCustomer = async (payload) => {
    setLoading(true);
    for (const key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }
    const { status, errorMessage } =
      await CustomerController.createCustomer(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return returnToMeasurement();
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const goBack = () => {
    if (newCustomer) {
      setNewCustomer(false);
    } else closeModal();
  };

  const measurementTableQuery = useMemo(() => {
    return `offset=${currentPage + 1}&limit=${limit}`;
  }, [currentPage, limit]);

  const measurementData = MeasurementController.useGetMeasurements(
    measurementTableQuery,
    filterQuery,
    searchQuery
  );

  const onLimitChange = (limit) => {
    setLimit(limit);
    setCurrentPage(0);
  };

  const goToEditMeasurement = () => {
    setEditMeasurementModal(true);
  };
  const goToTakeMeasurement = () => {
    setTakeMeasurementModal(true);
  };
  const goToViewMoreMeasurement = () => {
    setViewMoreModal(true);
  };
  const handleOptionSelect = (measurementId, selectedMeasurement, option) => {
    measurementStore.setSelectedMeasurement(selectedMeasurement);
    if (option.value === "Edit Measurement") {
      goToEditMeasurement();
    } else if (option.value === "View More") {
      goToViewMoreMeasurement();
    }
  };

  const closeModal = () => {
    setEditMeasurementModal(false);
    setTakeMeasurementModal(false);
    setNewCustomer(false);
    setViewMoreModal(false);
  };

  return {
    measurementData,
    limit,
    currentPage,
    setCurrentPage,
    onLimitChange,
    searchQuery,
    setSearchQuery,
    handleOptionSelect,
    goToTakeMeasurement,
    takeMeasurementModal,
    editMeasurementModal,
    closeModal,
    goBack,
    newCustomer,
    createNewCustomer,
    onSubmitCustomer,
    customerDetailsValidation,
    loading,
    viewMoreModal,
  };
};

export default useMeasurementLogic;
