import React, { useEffect, useMemo, useState } from "react";
import { WebsiteController } from "../../../controllers";
import { userStore } from "../../../stores";

const useNewsLetterLogic = () => {
  const [limit, setLimit] = useState(50);
  const [filterQuery, setFilterQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const newsLetterTableQuery = useMemo(() => {
    return `offset=${currentPage + 1}&limit=${limit}`;
  }, [currentPage, limit]);

  const { isLoading, data, isFetching } = WebsiteController.useGetNewsLetter(
    newsLetterTableQuery,
    filterQuery,
    searchQuery
  );

  const onLimitChange = (limit) => {
    setLimit(limit);
    setCurrentPage(0);
  };

  return {
    isLoading,
    data,
    isFetching,
    onLimitChange,
    limit,
    currentPage,
    setCurrentPage,
    searchQuery,
    setSearchQuery,
  };
};

export default useNewsLetterLogic;
