import React, { useState } from "react";
import {
  InvoiceController,
  CustomerController,
  TransactionsController,
} from "../../../controllers";
import { object, string, number, array, boolean, date } from "yup";
import NewInvoice from "../../../pages/Invoice/NewInvoice";
import { invoiceStore, transactionStore, userStore } from "../../../stores";
import { amountFormatter, phoneRegExp } from "../../../utils/utils";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { observer } from "mobx-react";
import * as yup from "yup";

const useNewInvoiceLogic = ({
  closeModal,
  invoiceItem,
  inflowTypeData,
  setInflowTypeData,
  setShowSurvey,
}) => {
  const [stage2, setStage2] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [loading, setLoading] = useState(false);

  const createNewCustomer = () => {
    setNewCustomer(true);
  };

  const returnToInvoicing = () => {
    setNewCustomer(false);
  };

  const onDownload = async (invoiceId) => {
    const { errorMessage } = await InvoiceController.download(invoiceId);
    if (errorMessage) {
      MimaToastUtil.error({
        message: errorMessage,
      });
    } else {
      MimaToastUtil.success({
        message: constant.Success,
      });
    }
  };

  const orders = [];

  const invoiceDetailsValidation = () => {
    return object({
      customer: string().required("Select a customer"),
      orders: array(
        object({
          availableStockQuantity: number(),
          selectFromStock: boolean(),
          // quantity: number().min(0.1, 'Quantity should be greater than 0.1'),
          quantity: number().when("selectFromStock", {
            is: true,
            then: number()
              .min(1, "Quantity should be atleast 1")
              .max(yup.ref("availableStockQuantity"), ({ max }) => {
                return `only ${max} units left in stock.`;
              }),
            otherwise: number().min(1, "Quantity should be atleast 1"),
          }),
          unitPrice: number().min(1, "Unit price should be greater than 1"),
          item: string().required("Service is required"),
        })
      ),
      vat: boolean().required("Are you charging VAT?"),
      wht: boolean().required("Are you deducting WHT?"),
      currencyCode: string().required("Select Currency"),
      chargeSplit: string().test(function () {
        const { currencyCode, chargeSplit } = this.parent;
        return currencyCode !== "NGN" && !chargeSplit
          ? this.createError({
              message: "Please select who bears payment charges",
              path: "chargeSplit",
            })
          : true;
      }),
      balanceDueDate: string().test(function () {
        const { balanceDueDate, acceptPartPay } = this.parent;

        return acceptPartPay &&
          balanceAmount(this.parent) > 0 &&
          !balanceDueDate
          ? this.createError({
              message: "Balance due date is required for the expected balance",
              path: "balanceDueDate",
            })
          : true;
      }),
      dueDate: date().required("Enter Payment due date"),
      discountPer: number()
        .default(0)
        .min(0, "Min discount should be greater than 0%")
        .max(100, "Max discount should be less than or equal to 100%"),
      // depositPer: number()
      // 	.min(1, 'Min deposit should be greater than 1%')
      // 	.max(100, 'Max deposit should be less than or equal to 100%')
      // 	.required('Deposit is required'),
      salesChannel: string().required("Sales Channel is required"),
      acceptPartPay: boolean(),
      partPayment: number().when("acceptPartPay", {
        is: true,
        then: (schema) => schema.required("Part payment is required"),
        otherwise: (schema) => schema.optional(),
      }),
    });
  };

  const receiptDetailsValidation = () => {
    return object({
      customer: string().required("Select a customer"),
      orders: array(
        object({
          availableStockQuantity: number(),
          selectFromStock: boolean(),
          // quantity: number().min(0.1, 'Quantity should be greater than 0.1'),
          quantity: number().when("selectFromStock", {
            is: true,
            then: number()
              .min(1, "Quantity should be atleast 1")
              .max(yup.ref("availableStockQuantity"), ({ max }) => {
                return `only ${max} units left in stock.`;
              }),
            otherwise: number().min(1, "Quantity should be atleast 1"),
          }),
          unitPrice: number().min(1, "Unit price should be greater than 1"),
          item: string().required("Service is required"),
        })
      ),
      balanceDueDate: string().test(function () {
        const { balanceDueDate, acceptPartPay } = this.parent;
        return acceptPartPay &&
          balanceAmount(this.parent) > 0 &&
          !balanceDueDate
          ? this.createError({
              message: "Balance due date is required for the expected balance",
              path: "balanceDueDate",
            })
          : true;
      }),
      currencyCode: string().required("Select Currency"),
      paymentMethod: string().required("Select payment method"),
      paidDate: string().required("Enter Payment date"),
      discountPer: number()
        .min(0, "Min discount should be greater than 0%")
        .max(100, "Max discount should be less than or equal to 100%"),
      deposit: number()
        .min(1, "Minimum deposit amount should be greater than 1")
        .required("Amount Received is required")
        .test(function () {
          return totalAmountCal(this.parent) < this.parent.deposit
            ? this.createError({
                message: `Maximum amount received should be ${amountFormatter(
                  this.parent.currencyCode
                ).format(totalAmountCal(this.parent))}`,
                path: "deposit",
              })
            : true;
        }),
      salesChannel: string().required("Sales Channel is required"),
    });
  };

  const balanceAmount = (values) => {
    return values.partPayment > 0
      ? totalAmountCal(values) - values.partPayment
      : totalAmountCal(values) - values.deposit;
  };

  const totalAmountCal = (values) =>
    totalWithoutDiscount(values) + values.shipping - values.discountAmount;

  const totalItemPrice = (values) =>
    values?.orders?.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.quantity * currentValue.unitPrice,
      0
    );

  const totalWithoutDiscount = (values) => {
    let totalAmount = totalItemPrice(values);
    totalAmount = values.vat ? totalAmount + totalAmount * 0.075 : totalAmount;
    totalAmount = values.wht ? totalAmount - totalAmount * 0.05 : totalAmount;
    return totalAmount;
  };

  const discountPerCal = (values) =>
    ((values.discountAmount || 0) / totalItemPrice(values)) * 100 || 0;

  const depositPerCal = (values) =>
    (values.deposit / totalAmountCal(values)) * 100 || 0;

  const depositAmountCal = (values) =>
    (values.depositPer * totalAmountCal(values)) / 100 || 0;

  const onTagSubmit = async (dataId) => {
    const payload = {
      inflowType: transactionStore.untagged._id
        ? transactionStore.untagged.inflowType
        : inflowTypeData,
      invoiceId: dataId,
    };
    const { status, errorMessage } = await TransactionsController.tagIncome(
      payload,
      transactionStore.untagged._id
    );
    setLoading(false);
    if (status === constant.Success) {
      setInflowTypeData("");
      MimaToastUtil.success({
        message: constant.Success,
      });
      return onCloseModal();
    }
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const onSubmit = async (values) => {
    let payload = {};
    setLoading(true);

    values.orders.forEach((item) => {
      !item.stock && delete item.stock;
      delete item.selectFromStock;
      delete item.availableStockQuantity;
    });

    delete values.selectFromStock;
    delete values.customerName;

    if (values.discountPer === "") values.discountPer = 0;
    if (invoiceItem) {
      delete values.paidDate;
      delete values.paymentMethod;
      if (values.partPayment !== 0) {
        values.deposit = values.partPayment;
      } else {
        values.deposit = totalAmountCal(values);
      }

      values.depositPer = (values.deposit / totalAmountCal(values)) * 100;

      delete values.acceptPartPay;
      delete values.partPayment;
      if (!values.chargeSplit) delete values.chargeSplit;
      delete values.walletInflow;
      delete values.untagged;

      if (values.balanceDueDate === "") {
        delete values.balanceDueDate;
        payload = {
          ...values,
          type: "INVOICE",
          dueDate: values.dueDate.toDateString(),
        };
      } else {
        payload = {
          ...values,
          type: "INVOICE",
          dueDate: values.dueDate.toDateString(),
          balanceDueDate: values.balanceDueDate.toDateString(),
        };
      }
    } else {
      delete values.dueDate;
      delete values.acceptPartPay;
      delete values.partPayment;
      delete values.chargeSplit;
      delete values.walletInflow;
      delete values.untagged;

      payload = {
        ...values,
        type: "RECEIPT",
        paidDate: values.paidDate.toDateString(),
        balanceDueDate: values.balanceDueDate
          ? values.balanceDueDate?.toDateString()
          : "",
      };
    }
    if (transactionStore.tag._id || transactionStore.untagged._id) {
      const { status, data, errorMessage } =
        await InvoiceController.createInvoice(payload);
      if (status === constant.Success) {
        onTagSubmit(data._id);
        setLoading(false);
        return onCloseModal();
      }
      setLoading(false);
      MimaToastUtil.error({ message: errorMessage });
    } else {
      const { status, errorMessage } =
        await InvoiceController.createInvoice(payload);
      if (status === constant.Success) {
        MimaToastUtil.success({
          message: constant.Success,
        });
        setLoading(false);
        return goToStage2();
      }
      setLoading(false);
      MimaToastUtil.error({ message: errorMessage });
    }
  };

  const goToStage2 = () => {
    setStage2(true);
  };

  const onCloseModal = () => {
    invoiceStore.setCopiedInvoice({});
    transactionStore.setTag({});
    transactionStore.setUntagged({});
    // setInflowTypeData("");
    closeModal();

    return;
  };

  //Customer side
  const customerDetailsValidation = () => {
    return yup.object({
      fullname: yup.string().required("Customer name is required"),
      mobile: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Customer phone number is required"),
    });
  };

  const onSubmitCustomer = async (payload) => {
    setLoading(true);
    for (const key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }
    const { status, errorMessage } =
      await CustomerController.createCustomer(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return returnToInvoicing();
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const goBack = () => {
    if (newCustomer) {
      setNewCustomer(false);
    } else closeModal();
  };

  const invoice = invoiceStore.copiedInvoice;
  return {
    invoiceDetailsValidation,
    receiptDetailsValidation,
    loading,
    balanceAmount,
    stage2,
    orders,
    invoice,
    onSubmit,
    discountPerCal,
    depositPerCal,
    depositAmountCal,
    newCustomer,
    createNewCustomer,
    customerDetailsValidation,
    onSubmitCustomer,
    goBack,
  };
};

export default useNewInvoiceLogic;
