import styles from '../../../assets/styles/Wallet/VirtualCard.module.scss';
import { MimaText, Modal, ReceiptCheckBox as RCB, MimaButton, MimaToastUtil, PageLoader } from '../../../components';
import React from 'react';
import { amountFormatter } from '../../../utils/utils';
import VisaCard from '../../../assets/img/VisaCardLogo.png';
import MasterCard from '../../../assets/img/masterCardLogo.png';
import Copylogo from '../../../assets/img/copylogo.png';
import Alert from '../../../assets/img/Alert.png';
import { MdOutlineContentCopy } from 'react-icons/md';
import { bodyBold } from '../../../components/MimaText/styles';

const VirtualCard = ({
    cardDetails,
    closeModal,
    goBack,
    loading,
    isCardAvailable,
    createNewCard,
    confirmAction,
    setConfirmAction,
    handleCardAction,

}) => {

    return (
        <Modal closeModal={closeModal} goBack={goBack}>
            {loading ? <PageLoader loading={loading} /> : (
                <>
                    {!isCardAvailable && (
                        <>
                            {/* <MimaText mb={2} variant="subtitleBold" >
                                Virtual Card
                            </MimaText> */}
                            <MimaText mb={3} align="center" width="38">
                                You will be charged NGN3,000 for the creation of a virtual dollar card
                            </MimaText>
                            <MimaButton
                                title="Proceed"
                                mt={2}
                                onClick={createNewCard}
                                loading={loading}
                            />
                            <MimaButton
                                title="Cancel"
                                mt={2}
                                buttonColor="var(--color-dark)"
                                onClick={closeModal}
                                loading={loading}
                            />
                        </>
                    )}
                    {isCardAvailable && (
                        <>
                            {confirmAction === "" ? (
                                <>
                                    <MimaText variant='subtitleBold' mb={3}>Your Virtual Dollar Card</MimaText>
                                    {cardDetails.cardData.status === "inactive" ? (
                                        <MimaText variant='bodyBold' color="var(--color-error)" mt={-2} mb={3}>
                                            This Card is frozen
                                        </MimaText>) : ""}
                                    <div className={styles.main}>
                                        {/* <MimaText variant="small" mb={1}>
                                            Virtual Card
                                        </MimaText> */}
                                        <div className={styles.cardNo}>
                                            **** **** **** {cardDetails.cardData.number.slice(-4)}
                                        </div>
                                        <div className={styles.cardBeside} >
                                            <MimaText variant="small">
                                                {cardDetails.cardData.cardName}
                                            </MimaText>
                                            <MimaText color="var(--color-primary-4)" variant="small">
                                                USD
                                            </MimaText>
                                        </div>
                                        <div className={styles.cardBottom}>
                                            <div className={styles.main__cardDetails}>

                                                <MimaText labelTitle="Valid Till">
                                                    {`${cardDetails.cardData.expireMonth} / ${cardDetails.cardData.expireYear}`}
                                                </MimaText>

                                                <MimaText labelTitle="CVV">
                                                    {cardDetails.cardData.cvc}
                                                </MimaText>
                                            </div>
                                            <div >
                                                {cardDetails.cardData.brand === "Visa" && <img src={VisaCard} alt='Visa card logo' className={styles.main__icon} />}
                                                {cardDetails.cardData.brand === "Mastercard" && <img src={MasterCard} alt='Mastercard logo' className={styles.main__icon} />}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.fullCard}>
                                        <MimaText align="center" variant="bodyBold"> Full Card Details</MimaText>
                                        <MimaText align="center" variant="small" mb={2} color="var(--color-error)" width={38}>
                                            The maximum spending limit on this card is $500 per transaction. Contact support if you need to spend above the limit.
                                        </MimaText>
                                        <div className={styles.fullCard_info}>
                                            <MimaText labelTitle="Card Name ">
                                                {cardDetails.cardData.cardName}
                                            </MimaText>
                                            <MdOutlineContentCopy
                                                className={styles.fullCard_icon}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${cardDetails.cardData.cardName}`);
                                                    MimaToastUtil.success({ message: 'Copied!', });
                                                }}
                                            />
                                        </div>
                                        <div className={styles.fullCard_info}>
                                            <MimaText labelTitle="Card Number">
                                                **** **** **** {cardDetails.cardData.number.slice(-4)}
                                            </MimaText>
                                            <MdOutlineContentCopy
                                                className={styles.fullCard_icon}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${cardDetails.cardData.number}`);
                                                    MimaToastUtil.success({ message: 'Copied!', });
                                                }}
                                            />
                                        </div>
                                        <div className={styles.fullCard_info}>
                                            <MimaText labelTitle="Expiry Date">
                                                {cardDetails.cardData.expireMonth} / {cardDetails.cardData.expireYear}
                                            </MimaText>
                                            <MdOutlineContentCopy
                                                className={styles.fullCard_icon}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${cardDetails.cardData.expireMonth}/${cardDetails.cardData.expireYear}`);
                                                    MimaToastUtil.success({ message: 'Copied!', });
                                                }}
                                            />
                                        </div>
                                        <div className={styles.fullCard_info}>
                                            <MimaText labelTitle="CVV2">
                                                {cardDetails.cardData.cvc}
                                            </MimaText>
                                            <MdOutlineContentCopy
                                                className={styles.fullCard_icon}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${cardDetails.cardData.cvc}`);
                                                    MimaToastUtil.success({ message: 'Copied!', });
                                                }} />
                                        </div>
                                        <div className={styles.fullCard_info}>
                                            <MimaText labelTitle="Address">
                                                {cardDetails.address.line1}, {cardDetails.address.city}, {cardDetails.address.state}, {cardDetails.address.country}.
                                            </MimaText>
                                            <MdOutlineContentCopy
                                                className={styles.fullCard_icon}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${cardDetails.address.line1}, ${cardDetails.address.city}, ${cardDetails.address.state}, ${cardDetails.address.country}.`);
                                                    MimaToastUtil.success({ message: 'Copied!', });
                                                }} />
                                        </div>
                                        <div className={styles.fullCard_info}>
                                            <MimaText labelTitle="Postal Code">
                                                {cardDetails.address.postalCode}
                                            </MimaText>
                                            <MdOutlineContentCopy
                                                className={styles.fullCard_icon}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${cardDetails.address.postalCode}`);
                                                    MimaToastUtil.success({ message: 'Copied!', });
                                                }} />
                                        </div>
                                    </div>
                                    <MimaButton
                                        title={cardDetails.cardData.status === "inactive" ? 'Unfreeze Card' : 'Freeze Card'}
                                        onClick={() => {
                                            if (cardDetails.cardData.status === "active") {
                                                setConfirmAction('Freeze Card')
                                            }
                                            if (cardDetails.cardData.status === "inactive") {
                                                setConfirmAction('Unfreeze Card')
                                            }
                                        }}
                                        mb={2}
                                        mt={2}
                                    />

                                    <MimaButton
                                        title="Deactivate Card"
                                        onClick={() => {
                                            setConfirmAction('Deactivate Card')
                                        }}
                                        mb={2}
                                        buttonColor="var(--color-dark)"
                                    />
                                </>
                            ) : (
                                <>
                                    <div className={styles.imgBg}>
                                        <img
                                            className={styles.imgBg__img}
                                            src={Alert}
                                            alt='Alert'
                                        />
                                    </div>
                                    <MimaText variant="subtitle" width={38} align="center" mb={2}>
                                        Are you sure you want to <span style={{ fontWeight: "700", color: "var(--color-error)" }}>{confirmAction}</span>
                                    </MimaText>
                                    {confirmAction === "Deactivate Card" && (
                                        <MimaText variant="bodybold" width={38} align="center" mt={-1} mb={2} color="var(--color-error)">
                                            NB: Once deactivated, this card cannot be used again and you will be charged for creating another one.
                                        </MimaText>
                                    )}
                                    <div className='modal__beside'>
                                        <MimaButton
                                            title="Yes, Confirm"
                                            width={18}
                                            onClick={handleCardAction}
                                        />

                                        <MimaButton
                                            title="No, Go back"
                                            width={18}
                                            buttonColor="var(--color-dark)"
                                            onClick={goBack}
                                        />
                                    </div>
                                </>
                            )}

                        </>
                    )}


                </>)}

        </Modal>
    );
};

export default VirtualCard;
