import React, { useState } from "react";
import styles from "../../assets/styles/Components/AppShell.module.scss";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";
import constant from "../../utils/constant";
import { motion, AnimatePresence } from "framer-motion";
import { FaBars } from "react-icons/fa";
import IconButton from "../IconButton";
import { LogoutModal } from "..";
import CountDown from "../CountDown";
import { businessStore } from "../../stores";
import { Subscriptions } from "../../pages";

const AppShell = ({ children, pageTitle, tradingName }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);

  const navigate = useNavigate();
  const goToSettings = () => {
    navigate(constant.Routes.Settings);
  };

  const [logoutModal, setLogoutModal] = useState(false);

  const goTologout = () => {
    setLogoutModal(true);
  };

  const closeModal = () => {
    setLogoutModal(false);
    setSubscriptionModal(false);
  };

  const menuClicked = () => {
    setShowSidebar(!showSidebar);
  };

  //  For free trial
  const subscriberList = businessStore.business?.subscription;
  const subscriptionAmount = subscriberList?.amountPaid;
  const startDate = subscriberList?.trialStartDate;

  return (
    <div className={styles.container}>
      {showSidebar && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          className={styles.sidebar}
        >
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              x: -100,
              transition: {
                duration: 0.2,
              },
            }}
          >
            <Sidebar menuClicked={menuClicked} />
          </motion.div>
          <div onClick={menuClicked}></div>
        </motion.div>
      )}
      <div className={styles.topside}>
        <div className={styles.topside_left}>
          <FaBars className={styles.menuIcon} onClick={menuClicked} />
          {pageTitle !== "" ? (
            <h3> {pageTitle} </h3>
          ) : (
            <p className={styles.topside_welcome}>
              Welcome back, <br />
              <span className={styles.topside_name}>
                {tradingName ? tradingName : ""}
              </span>
            </p>
          )}
        </div>

        <div className={styles.topside_icon}>
          <IconButton onClick={goToSettings} />
          <IconButton variant="logout" onClick={goTologout} />
        </div>
      </div>
      <CountDown
        startDate={startDate}
        subscriptionAmount={subscriptionAmount}
        subscriberList={subscriberList}
        setSubscriptionModal={setSubscriptionModal}
      />

      <div>{children}</div>

      {(logoutModal || subscriptionModal) && (
        <div className="modal">
          <AnimatePresence>
            {logoutModal && <LogoutModal closeModal={closeModal} />}
            {subscriptionModal && <Subscriptions closeModal={closeModal} />}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

export default AppShell;
