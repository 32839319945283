import React, { useEffect, useMemo, useState } from "react";
import { SettingsController } from "../../../controllers";
import { userStore } from "../../../stores";

const useWithdrawalAccountLogic = () => {
  const [withdrawalAccountModal, setWithdrawalAccountModal] = useState(false);
  useEffect(() => {
    const fetchWithdrawalAccounts = async () => {
      await SettingsController.getWithdrawalAccounts();
    };

    fetchWithdrawalAccounts();
  }, []);

  const closeModal = () => {
    setWithdrawalAccountModal(false);
  };

  const withdrawalAccounts = userStore.withdrawalAccounts;

  return {
    withdrawalAccounts,
    withdrawalAccountModal,
    setWithdrawalAccountModal,
    closeModal,
  };
};

export default useWithdrawalAccountLogic;
