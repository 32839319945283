import {TransactionsController, WalletController} from './';
import { apiRequest } from '../utils/useAPIRequest';
import { exchangeStore } from '../stores';
import constant from '../utils/constant';


const getCurrentExchangeRate = async query => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/exchangeRates/active-rate?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		exchangeStore.setCurrentExchangeRate(data.ngnRate)
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

const getExchangeRateAmount = async query => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/exchangeRates/rate-amount?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {		
		exchangeStore.setExchangeRateValue(Math.floor(data.amount * 100) / 100);
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

const changeCurrency = async payload => {
	const { status, errorMessage, data } = await apiRequest({
		url: '/exchangeRates/change',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await Promise.all([
			TransactionsController.transactions(),
			WalletController.walletDetails(), 
		]);
		return { status: constant.Success, data };
	}
	return { errorMessage, status, data };
};


export default {
	getCurrentExchangeRate,
	getExchangeRateAmount,
	changeCurrency,
};
