import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { supportStore } from '../stores';

const faqCategories = async () => {
	const { status, errorMessage, data } = await apiRequest({
		url: '/supports/faq/categories',
		method: 'get',
	});
	if (status === constant.Success) {
		supportStore.setFaqs(data.faqs);
		return { status };
	}
	return { status, errorMessage };
};

const contactDetails = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/supports/contact-details',
		method: 'get',
	});
	if (status === constant.Success) {
		supportStore.setContactDetails(data);
		return { status };
	}
	return { status, errorMessage };
};
const contactUs = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/supports/contact-us',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		supportStore.setContactUs(data);
		return { status };
	}
	return { status, errorMessage };
};

const createSupport = async payload =>
	await apiRequest({
		url: '/supports',
		method: 'post',
		payload,
		hasImageUpload: true,
	});

export default {
	createSupport,
	faqCategories,
	contactDetails,
	contactUs,
};
