import {
  MimaDateFilter,
  MimaFilter,
  MimaText,
  PageLoader,
  MimaDropdownFilter,
  AppShell,
} from "../../components";
import styles from "../../assets/styles/AppHome/AppHome.module.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import { IoIosCheckmarkCircle } from "react-icons/io";
import QuickLinksCard from "./QuickLinksCard";
import { MdCancel } from "react-icons/md";
import { userStore } from "../../stores";
import React, { useState } from "react";
import { Formik } from "formik";
import { toJS } from "mobx";
import { AnimatePresence } from "framer-motion";
import {
  AddBusiness,
  BusinessInfo,
  Security,
  WithdrawalAccount,
} from "../Settings";
import BusinessReportDL from "./BusinessReportDL";
import useAppHomeLogic from "./useLogic/useAppHomeLogic";
import OrderSummary from "./OrderSummary";
import { observer } from "mobx-react";

const AppHome = () => {
  const {
    tradingName,
    // expenseAnalysis,
    // transactionsSummary,
    // expensePercentage,
    loading,
    onSubmit,
    validationSchema,
    filterPeriod,
    resetFilter,
    toDoListTotal,
    refresh,
    addBusinessModal,
    closeModal,
    reportModal,
    goToMeasurement,
    goToSetPin,
    goToWithdrawalAccount,
    pinModal,
    withdrawalAccountModal,
    filterPeriods,
    getDate,
    hasCreatedWebsite,
    hasSetBrandInfo,
    hasSetTransactionPin,
    hasSetWithdrawalAccount,
    hasAddedStyles,
    hasAddedMeasurements,
    goToCreateWebsite,
    goToUploadStyle,
    goToAddMeasurement,
    updateBusinessModal,
    goToUpdateBusinessInfo,
    orderSummary,
  } = useAppHomeLogic();
  const [onReset, setOnReset] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const permissions = new Set(
    toJS(userStore.user?.permissions?.map((perm) => perm.name))
  );

  return (
    <AppShell
      pageTitle={`Welcome ${tradingName}`}
      // tradingName={tradingName}
      refresh={refresh}
    >
      {loading ? (
        <PageLoader title="Refreshing record..." loading={loading} />
      ) : (
        <>
          <MimaText variant="subtitle" mb={2} align="center">
            You are viewing Insights for {filterPeriod}.
          </MimaText>

          <div className={styles.homeTop}>
            <Formik
              initialValues={{
                startDate: "",
                endDate: "",
                period: "",
                periodText: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              }) => (
                <MimaFilter
                  filterClick={() => {
                    setOnReset(false);
                    handleSubmit();
                  }}
                  loading={loading}
                  resetClick={() => {
                    setOnReset(true);
                    resetFilter();
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <MimaDropdownFilter
                      labelTitle="Filter period"
                      placeholder="Select Period"
                      variant="medium"
                      name="period"
                      data={filterPeriods}
                      onChange={(data) => {
                        const dates = getDate(data.key);
                        setFieldValue("period", data.key);
                        setFieldValue("startDate", dates.startDate);
                        setFieldValue("endDate", dates.endDate);
                        setFieldValue("periodText", data.value);
                      }}
                      error={errors.period}
                      touched={touched.period}
                    />

                    {values.period === "custom" && (
                      <div style={{ display: "flex", gap: "2rem" }}>
                        <MimaDateFilter
                          labelTitle="start date"
                          name="startDate"
                          value={!onReset ? values.startDate : ""}
                          onChange={(text) => {
                            setOnReset(false);
                            setFieldValue("startDate", text);
                          }}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.startDate}
                          error={errors.startDate}
                        />
                        <MimaDateFilter
                          labelTitle="End date"
                          name="endDate"
                          value={!onReset ? values.endDate : ""}
                          onChange={(text) => {
                            setOnReset(false);
                            setFieldValue("endDate", text);
                          }}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.endDate}
                          error={errors.endDate}
                        />
                      </div>
                    )}
                  </div>
                </MimaFilter>
              )}
            </Formik>
          </div>

          <div className={styles.main}>
            {permissions.has("CAN CREATE ANOTHER BUSINESS") && (
              <OrderSummary orderSummary={orderSummary} />
            )}
            <QuickLinksCard goToMeasurement={goToMeasurement} />
          </div>

          <>
            {toDoListTotal < 100 ? (
              <div className={styles.toDo}>
                <MimaText variant="bodyBold" mb={1} align="center">
                  You are almost done!
                </MimaText>
                <div className={styles.toDo__group}>
                  <div
                    className={
                      hasCreatedWebsite
                        ? styles.toDo__items
                        : styles.toDo__items_hover
                    }
                    onClick={!hasCreatedWebsite ? goToCreateWebsite : () => {}}
                  >
                    <span
                      className={hasCreatedWebsite ? styles.toDo__green : ""}
                    >
                      {hasCreatedWebsite ? (
                        <IoIosCheckmarkCircle />
                      ) : (
                        <MdCancel />
                      )}
                    </span>
                    <p>Create website</p>
                  </div>
                  <div
                    className={
                      hasSetTransactionPin
                        ? styles.toDo__items
                        : styles.toDo__items_hover
                    }
                    onClick={!hasSetTransactionPin ? goToSetPin : () => {}}
                  >
                    <span
                      className={hasSetTransactionPin ? styles.toDo__green : ""}
                    >
                      {hasSetTransactionPin ? (
                        <IoIosCheckmarkCircle />
                      ) : (
                        <MdCancel />
                      )}
                    </span>
                    <p> Set Transaction Pin</p>
                  </div>
                  <div
                    className={
                      hasSetWithdrawalAccount
                        ? styles.toDo__items
                        : styles.toDo__items_hover
                    }
                    onClick={
                      !hasSetWithdrawalAccount
                        ? goToWithdrawalAccount
                        : () => {}
                    }
                  >
                    <span
                      className={
                        hasSetWithdrawalAccount ? styles.toDo__green : ""
                      }
                    >
                      {hasSetWithdrawalAccount ? (
                        <IoIosCheckmarkCircle />
                      ) : (
                        <MdCancel />
                      )}
                    </span>
                    <p>Set Withdrawal Account</p>
                  </div>
                  <div
                    className={
                      hasSetBrandInfo
                        ? styles.toDo__items
                        : styles.toDo__items_hover
                    }
                    onClick={
                      !hasSetBrandInfo ? goToUpdateBusinessInfo : () => {}
                    }
                  >
                    <span className={hasSetBrandInfo ? styles.toDo__green : ""}>
                      {hasSetBrandInfo ? (
                        <IoIosCheckmarkCircle />
                      ) : (
                        <MdCancel />
                      )}
                    </span>
                    <p>Update Brand Info</p>
                  </div>
                  <div
                    className={
                      hasAddedStyles
                        ? styles.toDo__items
                        : styles.toDo__items_hover
                    }
                    onClick={!hasAddedStyles ? goToUploadStyle : () => {}}
                  >
                    <span className={hasAddedStyles ? styles.toDo__green : ""}>
                      {hasAddedStyles ? <IoIosCheckmarkCircle /> : <MdCancel />}
                    </span>
                    <p>Add Styles</p>
                  </div>
                  <div
                    className={
                      hasAddedMeasurements
                        ? styles.toDo__items
                        : styles.toDo__items_hover
                    }
                    onClick={
                      !hasAddedMeasurements ? goToAddMeasurement : () => {}
                    }
                  >
                    <span
                      className={hasAddedMeasurements ? styles.toDo__green : ""}
                    >
                      {hasAddedMeasurements ? (
                        <IoIosCheckmarkCircle />
                      ) : (
                        <MdCancel />
                      )}
                    </span>
                    <p>Add Measurements</p>
                  </div>
                </div>
                <ProgressBar
                  completed={toDoListTotal}
                  bgColor="var(--color-success)"
                  animateOnRender
                />
              </div>
            ) : (
              ""
            )}
          </>

          {(addBusinessModal ||
            reportModal ||
            pinModal ||
            withdrawalAccountModal ||
            updateBusinessModal) && (
            <div className="modal">
              <AnimatePresence>
                {addBusinessModal && (
                  <AddBusiness
                    closeModal={closeModal}
                    setShowSurvey={setShowSurvey}
                    showSurvey={showSurvey}
                  />
                )}

                {reportModal && <BusinessReportDL closeModal={closeModal} />}
                {pinModal && <Security closeModal={closeModal} />}
                {withdrawalAccountModal && (
                  <WithdrawalAccount closeModal={closeModal} />
                )}
                {updateBusinessModal && (
                  <BusinessInfo
                    closeModal={closeModal}
                    updateBusinessModal={updateBusinessModal}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
        </>
      )}
    </AppShell>
  );
};

export default observer(AppHome);
