import { appStore } from "../../../stores";
import {
  BusinessController,
  SettingsController,
  WalletController,
} from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import { object, string } from "yup";
import constant from "../../../utils/constant";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

const useWithdrawalAccountLogic = ({ closeModal }) => {
  const [stage2, setStage2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [step, setStep] = useState("STEP1");
  const [otpForm, setOtpForm] = useState(false);
  const [methods, setMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);

  const onSubmit = (payload) => {
    if (!accountName) {
      return MimaToastUtil.error({
        message: "Enter a valid bank and account number combination",
      });
    }
    payload.accountName = accountName;
    localStorage.setItem(constant.RouteParams, JSON.stringify(payload));
    setStage2(true);
  };
  const onSubmitInternational = (payload) => {
    localStorage.setItem(constant.RouteParams, JSON.stringify(payload));
    setOtpForm(true);
  };

  const onSubmitPIN = async (token) => {
    setLoading(true);
    const payload = JSON.parse(localStorage.getItem(constant.RouteParams));
    payload.pin = token;
    const { status, errorMessage } =
      await SettingsController.setWithdrawalAccount(payload);
    if (status === constant.Success) {
      await BusinessController.businessDetails();
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    }
    setLoading(false);
    MimaToastUtil.error({
      message: errorMessage,
    });
  };
  const onSubmitPinInternational = async (token) => {
    setLoading(true);

    const payload = JSON.parse(localStorage.getItem(constant.RouteParams));
    const currencyCode = payload.currencyCode;
    payload.pin = token;
    delete payload.currencyCode;
    const { status, errorMessage } =
      await SettingsController.setWithdrawalAccountInternational(
        payload,
        currencyCode
      );
    if (status === constant.Success) {
      await BusinessController.businessDetails();
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    }
    setLoading(false);
    MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const validationSchema = () =>
    object({
      bankCode: string().required("Select a bank"),
      accountNumber: string().required("Account Number is required"),
    });

  const verifyAccountNumber = async (payload) => {
    if (
      payload.beneficiaryBank &&
      payload.accountNumber &&
      payload.accountNumber.length === 10
    ) {
      const { status, data, errorMessage } =
        await WalletController.verifyAccountNumber(payload);
      if (status === constant.Success) {
        return data.accountName;
      } else {
        MimaToastUtil.error({
          message: errorMessage,
        });
      }
    }
    return "";
  };
  const verifyPaymentMethodsDetail = async (payload) => {
    if (payload.countryCode && payload.currencyCode) {
      setLoading(true);
      const { status, data, errorMessage } =
        await WalletController.verifyPaymentMethods(payload);
      if (status === constant.Success) {
        setLoading(false);
        return data;
      } else {
        MimaToastUtil.error({
          message: errorMessage,
        });
        setSelectedMethod(null);
        setLoading(false);
      }
    }
    return "";
  };
  const banks = appStore.banks.map((bank) => {
    return { id: bank.key, value: bank.value };
  });

  const goBack = () => {
    if (!stage2 && step === "STEP2") {
      setStep("STEP1");
      setSelectedMethod(null);
    } else {
      closeModal();
    }
  };

  // useEffect to auto-select the only method if available
  useEffect(() => {
    if (methods?.length === 1) {
      setSelectedMethod(methods[0]);
    }
  }, [methods]);

  // Create initialValues dynamically based on selected method
  const initialValues = selectedMethod?.requiredFields?.reduce((acc, field) => {
    acc[field] = ""; // Initialize each required field with an empty string
    return acc;
  }, {});

  // Create Yup validation schema dynamically
  const validationSchema2 = yup.object(
    selectedMethod?.requiredFields?.reduce((acc, field) => {
      acc[field] = yup.string().required(`${field} is required`); // Set each field as required
      return acc;
    }, {})
  );
  return {
    stage2,
    onSubmit,
    onSubmitPIN,
    accountName,
    setAccountName,
    setLoading,
    verifyAccountNumber,
    validationSchema,
    loading,
    banks,
    currencyCode,
    setCurrencyCode,
    step,
    setStep,
    goBack,
    verifyPaymentMethodsDetail,
    otpForm,
    onSubmitPinInternational,
    onSubmitInternational,
    initialValues,
    setMethods,
    methods,
    validationSchema2,
    selectedMethod,
    setSelectedMethod,
  };
};

export default useWithdrawalAccountLogic;
