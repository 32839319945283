import {
  FlexButton,
  MimaText,
  Modal,
  MimaInput,
  MimaButton,
} from "../../components";
// import { BuyAirtimeContainer, BuyDataContainer } from '../../containers';
import BuyAirtime from "./BuyAirtime";
import React, { useState } from "react";
import useAirtimeDataLogic from "./useLogic/useAirtimeDataLogic";

function AirtimeOne({ closeModal }) {
  const {
    buyAirtimeContent,
    buyDataContent,
    buydata,
    buyAirtime,
    mainContent,
    goBack,
    pinContent,
    setPinContent,
  } = useAirtimeDataLogic({ closeModal });
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {mainContent && (
        <>
          <MimaText variant="subtitle">Airtime</MimaText>
          <FlexButton title="Airtime" mt={4} onClick={buyAirtime} />
        </>
        // <>
        // 	<MimaText variant="subtitle">Airtime/Data</MimaText>
        // 	<FlexButton title="Airtime" mt={4} onClick={buyAirtime} />
        // 	<FlexButton title="Data" mt={3} onClick={buydata} />
        // </>
      )}

      {buyAirtimeContent && (
        <BuyAirtime
          closeModal={closeModal}
          pinContent={pinContent}
          setPinContent={setPinContent}
        />
      )}

      {/* {buyDataContent && <BuyDataContainer closeModal={closeModal} setShowSurvey={setShowSurvey}/>} */}
    </Modal>
  );
}

export default AirtimeOne;
