import { useStores } from "../../../stores";
import React from "react";

const useLogoutLogic = ({ closeModal }) => {
  const { userStore } = useStores();

  const logout = () => {
    userStore.setLoggedIn(false);
  };

  return { logout };
};

export default useLogoutLogic;
