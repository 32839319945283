import { MimaButton, MimaText, MimaToastUtil, Modal } from "../../components";
import styles from "../../assets/styles/Settings/Settings.module.scss";
import Friends from "../../assets/img/MimaCongrats2.png";
import { FaRegEnvelope } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { userStore } from "../../stores";
import React from "react";

const InviteFriends = ({ closeModal }) => {
  const callIcon = (
    <IoMdCall
      style={{
        color: "var(--color-primary)",
      }}
    />
  );

  const msgIcon = <FaRegEnvelope />;
  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      <MimaText variant="subtitleBold" mb={4}>
        Invite Friends
      </MimaText>
      <img src={Friends} className={styles.if__img} alt="" />

      <MimaText mt={2} mb={4}>
        Invite your Friends to join Fash Creatives
      </MimaText>
      <MimaText variant="small" mb={2} color="var(--color-primary)">
        Share Link.
      </MimaText>

      <div
        onClick={() => {
          navigator.clipboard.writeText(
            `https://app.fashcreatives.com/auth/signup?referrerCode=${userStore.user?.referrerCode}`
          );
          MimaToastUtil.success({
            message: "Copied!",
          });
        }}
        className={styles.if__share}
      >
        <div className={styles.if__share_1}>
          With Fash creatives, I can run and manage my fashion business with
          ease. Signup with the link to start managing your business also
          <span>
            {" "}
            {`https://app.fashcreatives.com/auth/signup?referrerCode=${userStore.user?.referrerCode}`}
          </span>
        </div>
        <div className={styles.if__share_2}>Copy Link</div>
      </div>
      {/* <MimaButton title="Learn More about Mima" mt={3} /> */}
    </Modal>
  );
};

export default InviteFriends;
