import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from 'mobx-persist-store';
export default class CustomerStore {
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: 'CustomerStore', properties: ['customers', 'selectedCustomer', 'mappedCustomers', 'mappedGroups'] });
    }

    customers = [];
    selectedCustomer = {};
    mappedCustomers = [];
    loading = false;
    mappedGroups = [];

    reset = () => {
		this.customers = [];
		this.mappedCustomers = [];
		this.selectedCustomer = {};
	};

    setLoading = async isLoading => {
		runInAction(() => {
			this.loading = isLoading;
		});
	};

    setCustomers = async (customers) => {
        runInAction(async() => {
            this.customers = customers;
        });
    }
    setMapGroups = async (state) => {
        runInAction(async() => {
            this.mappedGroups = state;
        });
    }
    setMapCustomers = async (state) => {
        runInAction(async() => {
            this.mappedCustomers = state;
        });
    }

    setSelectedCustomer = async (state) => {
        runInAction(async() => {
            this.selectedCustomer = state;
        });
    }

}
