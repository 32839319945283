import React, { useState, useEffect } from "react";
import { Booking } from "../../pages";
import { MimaToastUtil } from "../../components";
import constant from "../../utils/constant";
import { object, string, number, boolean } from "yup";
import { BookingController } from "../../controllers";
import { bookingStore, userStore } from "../../stores";
import { useGetAllActiveReviews } from "../../controllers/SurveyController";

const useBookinLogic = () => {
  const [viewLinks, setViewLinks] = useState(false);
  const [viewBookings, setViewBookings] = useState(true);
  const [createLinks, setCreateLinks] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editLink, setEditLink] = useState(false);
  const [deleteLink, setDeleteLink] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);

  useEffect(() => {
    const allCreatedLinks = async () => {
      setLoading(true);
      await BookingController.getAllBookings();
      await BookingController.getAllBookingLinks();
      setLoading(false);
    };

    allCreatedLinks();
  }, []);

  const goToViewLinks = () => {
    setViewLinks(true);
    setViewBookings(false);
    setCreateLinks(false);
    setEditLink(false);
  };

  const refresh = async () => {
    setLoading(true);
    await BookingController.getAllBookings();
    setLoading(false);
  };

  const goToCreateLinks = () => {
    setCreateLinks(true);
    setViewLinks(false);
    setEditLink(false);
    setViewBookings(false);
  };

  const goToViewBookings = () => {
    setViewLinks(false);
    setViewBookings(true);
    setCreateLinks(false);
  };

  const goToEditLinks = (link) => {
    setViewLinks(false);
    setViewBookings(false);
    setEditLink(true);
    bookingStore.setSelectedBookingLink(link);
  };

  const goToDeleteLink = (link) => {
    bookingStore.setSelectedBookingLink(link);
    setDeleteLink(true);
  };

  const copySelectedLink = (link) => {
    navigator.clipboard.writeText(link.bookingUrl);
    MimaToastUtil.success({
      message: "Copied!",
    });
  };

  const cancelEdit = () => {
    setViewLinks(true);
    setViewBookings(false);
    setEditLink(false);
    bookingStore.setSelectedBookingLink();
  };

  const closeModal = () => {
    bookingStore.setSelectedBooking();
    setDeleteLink(false);
  };

  const paymentLinkNav = [
    {
      title: "Bookings",
      clickAction: goToViewBookings,
      activeState: viewBookings,
    },
    {
      title: "View Links",
      clickAction: goToViewLinks,
      activeState: viewLinks,
    },
    {
      title: "Create Booking Link",
      clickAction: goToCreateLinks,
      activeState: createLinks,
    },
  ];

  const validationSchema = () => {
    return object({
      title: string().required("Enter a Booking Link Name"),
      description: string().required("Enter the Booking Link description"),
      durationInMinutes: number(
        "enter the number minutes for each session"
      ).required(),
      acceptPhoneNumber: boolean().required(
        "Should booking link require phone numbers?"
      ),
      availableWeekends: boolean().required(
        "Do you accept bookings in the weekends?"
      ),
      acceptPartPayment: boolean().required(
        "Should booking link accept part booking?"
      ),
      currencyCode: string().required("Select Currency"),
      chargeSplit: string().required("Select How you want to split charges"),
      minDeposit: number("enter a number"),
      amount: number("enter a number").required("Amount is required"),
    });
  };

  const handleOptionSelect = (_id, link, option) => {
    if (option.value === "Edit Link") {
      goToEditLinks(link);
    } else if (option.value === "Deactivate Link") {
      goToDeleteLink(link);
    } else if (option.value === "Copy Link Url") {
      copySelectedLink(link);
    }
  };
  const bookingNav = paymentLinkNav;

  //   Delete booking

  const selectedLink = bookingStore.selectedBookingLink;

  const confirmDelete = async () => {
    setLoading(true);
    const id = selectedLink._id;
    const { status, errorMessage } =
      await BookingController.deleteBookingLink(id);

    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      setLoading(false);
      closeModal();
    } else {
      setLoading(false);
      MimaToastUtil.error({ message: errorMessage });
    }
  };

  //   create Booking

  const validationSchemaWithFreeBooking = () => {
    return object({
      title: string().required("Enter a Booking Link Name"),
      description: string().required("Enter the Booking Link description"),
      durationInMinutes: number(
        "enter the number minutes for each session"
      ).required(),
      acceptPhoneNumber: boolean().required(
        "Should booking link require phone numbers?"
      ),
      availableWeekends: boolean().required(
        "Do you accept bookings in the weekends?"
      ),
      acceptPartPayment: boolean().required(
        "Should booking link accept part booking?"
      ),
      currencyCode: string().required("Select Currency"),
      minDeposit: number("enter a number"),
    });
  };
  const validationSchemaWithoutFreeBooking = () => {
    return object({
      title: string().required("Enter a Booking Link Name"),
      description: string().required("Enter the Booking Link description"),
      durationInMinutes: number(
        "enter the number minutes for each session"
      ).required(),
      acceptPhoneNumber: boolean().required(
        "Should booking link require phone numbers?"
      ),
      availableWeekends: boolean().required(
        "Do you accept bookings in the weekends?"
      ),
      acceptPartPayment: boolean().required(
        "Should booking link accept part booking?"
      ),
      currencyCode: string().required("Select Currency"),
      chargeSplit: string().required("Select How you want to split charges"),
      minDeposit: number("enter a number"),
      amount: number("enter a number").required("Amount is required"),
    });
  };

  const onSubmitCreateBooking = async (values) => {
    setLoading(true);
    let payload = {};
    payload = {
      ...values,
    };

    delete payload.previewAmount;
    delete payload.previewFirstName;
    delete payload.previewLastName;
    delete payload.previewEmail;
    delete payload.previewMobile;
    delete payload.previewQuantity;

    if (payload.amount === "") {
      delete payload.amount;
    }

    if (payload.minDeposit === "") {
      delete payload.minDeposit;
    }

    if (createLinks) {
      for (const key in payload) {
        if (payload[key] === "") {
          delete payload[key];
        }
      }
      const { status, errorMessage } =
        await BookingController.createBookingLink(payload);

      if (status === constant.Success) {
        MimaToastUtil.success({
          message: constant.Success,
        });
        setLoading(false);
        goToViewLinks();

        return;
      } else {
        setLoading(false);
        MimaToastUtil.error({ message: errorMessage });
      }
    }
  };

  //   Edit Booking

  const validationSchemaEditBooking = object().shape({
    amount: number().min(0, "Amount must be a positive number"),
    currencyCode: string()
      .required("Currency code is required")
      .oneOf(["NGN", "USD", "EUR", "GBP"], "Invalid currency code"),
    title: string()
      .required("Title is required")
      .max(100, "Title cannot exceed 100 characters"),
    description: string().max(500, "Description cannot exceed 500 characters"),
    videoConferencingLink: string().url("Invalid URL"),
    durationInMinutes: number()
      .min(1, "Duration must be at least 1 minute")
      .max(1440, "Duration cannot exceed 1440 minutes"), // 24 hours
  });

  const onSubmitEditBooking = async (values) => {
    setLoading(true);
    let payload = {};
    payload = {
      ...values,
    };

    delete payload.previewAmount;
    delete payload.previewFirstName;
    delete payload.previewLastName;
    delete payload.previewEmail;
    delete payload.previewMobile;
    delete payload.previewQuantity;

    if (payload.amount === "") {
      delete payload.amount;
    }

    if (payload.minDeposit === "") {
      delete payload.minDeposit;
    }

    if (editLink) {
      delete payload.amount;
      delete payload.currencyCode;

      const { status, errorMessage } = await BookingController.editBookingLink(
        selectedLink._id,
        payload
      );

      if (status === constant.Success) {
        MimaToastUtil.success({
          message: constant.Success,
        });
        setLoading(false);
        goToViewLinks();
        return;
      } else {
        setLoading(false);
        MimaToastUtil.error({ message: errorMessage });
      }
    }
  };

  return {
    viewBookings,
    viewLinks,
    createLinks,
    bookingNav,
    validationSchema,
    loading,
    editLink,
    refresh,
    handleOptionSelect,
    closeModal,
    deleteLink,
    cancelEdit,
    goToCreateLinks,
    showSurvey,
    setShowSurvey,
    setEditLink,
    setViewBookings,
    setViewLinks,
    setCreateLinks,
    confirmDelete,
    // Create Booking
    validationSchemaWithFreeBooking,
    validationSchemaWithoutFreeBooking,
    goToViewLinks,
    onSubmitCreateBooking,
    // Edit Booking
    onSubmitEditBooking,
    validationSchemaEditBooking,
  };
};
export default useBookinLogic;
