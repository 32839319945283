import { BillController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import { string, number, object } from "yup";
import constant from "../../../utils/constant";
import { billStore } from "../../../stores";
import React, { useState } from "react";
import { phoneRegExp } from "../../../utils/utils";

function useElectricityLogic({ closeModal, pinContent, setPinContent }) {
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");

  const validateMeterNumber = async (value, categorId) => {
    setLoading(true);
    setAccountName("");
    billStore.setCablePackages([]);
    const { status, data, errorMessage } =
      await BillController.verifyMeterNumber(value, categorId);
    setLoading(false);
    if (status === constant.Success) {
      return setAccountName(data.name);
    }

    MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const validationSchema = () =>
    object({
      meterNumber: string().required("Please enter your Meter Number"),
      categoryId: string().required("Please select an Electricity Vendor"),
      amount: number()
        .required("Please enter Amount")
        .min(100, "The Minimum amount is N100"),
      phone: string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Please enter a Phone Number"),
    });

  const onSubmit = async (payload) => {
    payload.controller = "buyPower";
    payload.accountName = accountName;
    localStorage.setItem(constant.RouteParams, JSON.stringify(payload));
    setPinContent(true);
  };
  const vendors = billStore.powerVendors;
  const bill = billStore.copiedBill;

  return {
    bill,
    loading,
    validationSchema,
    accountName,
    validateMeterNumber,
    pinContent,
    onSubmit,
    vendors,
  };
}

export default useElectricityLogic;
