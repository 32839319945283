import { Formik } from "formik";
import {
  MimaText,
  Modal,
  MimaButton,
  MimaDateInput,
  MimaDropdown,
} from "../../components";
import React from "react";
import useReportDLLogic from "./useLogic/useReportDLLogic";

const BusinessReportDL = ({ closeModal }) => {
  const { loading, validationSchema, onSubmit } = useReportDLLogic({
    closeModal,
  });
  const reportPeriodArr = [
    "This Week",
    "Last Week",
    "This Month",
    "Last Month",
    "This Quarter",
    "Last Quarter",
    "This Year",
    "Last Year",
    "Today",
    "Custom",
  ];

  const reportModules = [
    "Bill Payment",
    "Customer",
    "Stock",
    "Invoice",
    "Payroll",
    "Split Payment",
    "Financial Transaction",
    "Petty Cash",
  ];

  return (
    <Modal closeModal={closeModal}>
      <MimaText variant="subtitle">Get Business Report(s)</MimaText>
      <MimaText variant="small" mb={3} width={38} align="center">
        Select your desired report module and period
      </MimaText>

      <Formik
        initialValues={{
          reportPeriod: "",
          reportModule: "",
          startDate: "",
          endDate: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div>
            <MimaDropdown
              labelTitle="Select Report Module"
              name="reportModule"
              value={values.reportModule}
              placeholder="Select Report Module"
              id="reportModule"
              currentValue={values.reportModule}
              onChange={(data) => {
                setFieldValue("reportModule", data.key);
              }}
              data={reportModules.map((x) => {
                return {
                  value: `${x} Report`,
                  key: x,
                };
              })}
              touched={touched.reportModule}
              error={errors.reportModule}
            />
            <MimaDropdown
              labelTitle="Select Report Period"
              name="reportPeriod"
              value={values.reportPeriod}
              placeholder="Select Report Period"
              id="reportPeriod"
              currentValue={values.reportPeriod}
              onChange={(data) => {
                setFieldValue("reportPeriod", data.key);
              }}
              data={reportPeriodArr.map((x) => {
                return {
                  value: x === "Custom" ? "Custom (Date Range)" : x,
                  key: x,
                };
              })}
              touched={touched.reportPeriod}
              error={errors.reportPeriod}
            />
            {values.reportPeriod === "Custom" ? (
              <div className="modal__beside">
                <MimaDateInput
                  labelTitle="Start date"
                  name="startDate"
                  value={values.startDate}
                  onChange={(text) => {
                    setFieldValue("startDate", text);
                  }}
                  onBlur={handleBlur}
                  width={18}
                  touched={touched.startDate}
                  error={errors.startDate}
                  excludeFutureDays
                />

                <MimaDateInput
                  labelTitle="End date"
                  name="endDate"
                  value={values.endDate}
                  onChange={(text) => {
                    setFieldValue("endDate", text);
                  }}
                  onBlur={handleBlur}
                  width={18}
                  touched={touched.endDate}
                  error={errors.endDate}
                  excludeFutureDays
                />
              </div>
            ) : (
              ""
            )}
            <MimaButton
              title="Continue"
              onClick={handleSubmit}
              type="submit"
              loading={loading}
              variant="form"
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default BusinessReportDL;
