import styles from "../../assets/styles/CountDown/CountDown.module.scss";
import React from "react";
import { MimaButton, MimaText } from "..";
import { useMediaQuery } from "react-responsive";

const CountDown = ({
  subscriptionAmount,
  startDate,
  subscriberList,
  setSubscriptionModal,
}) => {
  const today = new Date();
  const notice = new Date(subscriberList?.expiryDate) - today;
  const daysLeft = Math.ceil(notice / (1000 * 60 * 60 * 24));

  const goToSubscription = () => {
    setSubscriptionModal(true);
  };

  const isBelow800 = useMediaQuery({
    query: "(max-width: 800px)",
  });

  return (
    <>
      {startDate && subscriptionAmount === 0 ? (
        <div className={daysLeft < 7 ? styles.main__red : styles.main}>
          <>
            {daysLeft > 0 ? (
              <div>
                <MimaText
                  mb={1}
                  mt={1}
                  variant="subtitleBold"
                  align={isBelow800 ? "center" : "left"}
                >
                  You are currently on your Free Trial, subscribe now to get one
                  extra month free.
                </MimaText>
                {daysLeft <= 7 ? (
                  <MimaText
                    variant="subtitleBold"
                    mb={2}
                    color="var(--color-error)"
                    align={isBelow800 ? "center" : "left"}
                  >
                    Your Trial expires in {daysLeft} days
                  </MimaText>
                ) : null}
              </div>
            ) : (
              <MimaText
                variant="subtitleBold"
                color="var(--color-white)"
                mb={2}
                mt={1}
                align={isBelow800 ? "center" : "left"}
              >
                Your free trial has expired, select a plan and subscribe.
              </MimaText>
            )}
            <MimaButton
              title={"Select a Plan"}
              onClick={goToSubscription}
              variant="form"
              buttonColor={daysLeft < 7 ? "var(--color-white)" : ""}
              width={isBelow800 ? 38 : 18}
              titleColor={daysLeft < 7 ? "var(--color-error)" : ""}
            />
          </>
        </div>
      ) : null}
    </>
  );
};
export default CountDown;
