import styles from "../../assets/styles/MimaInput.module.scss";

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import * as inputStyles from "./styles";

const propTypes = {
  labelTitle: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  textAreaVariant: PropTypes.oneOf(["type1", "type2", "type3"]),
  width: PropTypes.any,
  icon: PropTypes.element,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.any,
  fontSize: PropTypes.number,
  note: PropTypes.string,
};

export const MimaTextArea = ({
  labelTitle,
  placeholder,
  onBlur,
  onChange,
  value,
  disabled = false,
  width,
  height,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  touched,
  error,
  fontSize,
  textAreaVariant = "type1",
  ...props
}) => {
  const handleKeyDown = (e) => {
    let valueLength = e.target.value.length;
    e.target.style.height = "inherit";
    // e.target.style.height = `${e.target.scrollHeight}px`;
    // // In case you have a limitation
    e.target.style.height = `${Math.min(e.target.scrollHeight, 500)}px`;

    if (valueLength === 0) {
      e.target.style.height = `60px`;
    }
  };

  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml}>
      <label className={styles.form__label}>{labelTitle}</label>
      <TextAreaSkeleton
        placeholder={placeholder}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChange}
        width={width}
        value={value}
        id={id}
        name={name}
        height={height}
        fontSize={fontSize}
        onKeyDown={handleKeyDown}
        textAreaVariant={textAreaVariant}
      />
      {error ? <div className={styles.error}>{touched && error}</div> : null}
    </DivSkeleton>
  );
};

const TextAreaSkeleton = styled.textarea`
  ${inputStyles.textArea};
  ${(props) => inputStyles[props.textAreaVariant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(touched) => (touched.id || touched.name ? `color: var(--color-dark)` : "")}
`;

const DivSkeleton = styled.div`
  ${inputStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

MimaTextArea.propTypes = propTypes;

export default MimaTextArea;
