import styles from '../../assets/styles/Wallet/TransferFunds.module.scss';
import { Searchbar } from '../Searchbar';
import React, { useState } from 'react';
import MimaText from '../MimaText';
import PropTypes from 'prop-types';

const propTypes = {
	data: PropTypes.array.isRequired,
	onSelectBank: PropTypes.func,
	onClick: PropTypes.func,
};

const defaultProps = {
	onSelectBank: () => {},
	data: [],
};

const SelectBank = ({ data, onSelectBank, onClick, ...props }) => {
	const [filteredBank, setFilteredBank] = useState([]);

	return (
		<div>
			<Searchbar
				placeholder="Search for Bank"
				input={data}
				loading={false}
				output={setFilteredBank}
				handleFilter={(item, searchQuery) => {
					return item?.value?.toLowerCase()?.includes(searchQuery);
				}}
			/>

			<div style={{ marginTop: '4rem' }}>
				{filteredBank.map(item => {
					return (
						<div
							className={styles.bankList}
							key={item.key}
							onClick={() => {
								onSelectBank(item);
							}}
						>
							{item.value}
							<div className={styles.line}></div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

SelectBank.propTypes = propTypes;

SelectBank.defaultProps = defaultProps;

export default SelectBank;
