import { BillController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import React, { useState } from "react";
import { userStore } from "../../../stores";

const useEnterPinLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = async (pin) => {
    setLoading(true);
    const payload = JSON.parse(localStorage.getItem(constant.RouteParams));
    payload.pin = pin;
    const controller = payload.controller;
    delete payload.controller;
    localStorage.setItem(constant.RouteParams, "");
    const { status, errorMessage } = await BillController[controller](payload);
    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    } else {
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  return { loading, onSubmit };
};

export default useEnterPinLogic;
