import React, { useState } from "react";
import { useStores, walletStore } from "../../../stores";
import { SettingsController, WalletController } from "../../../controllers";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
const useTransferFundLogic = ({
  closeWalletModal,
  transferSwitchState,
  sharedState,
  pinContent,
  setPinContent,
}) => {
  const [bankListContent, setBankListContent] = useState(false);
  const [beneficiaryContent, setBeneficiaryContent] = useState(false);
  const [stage2, setStage2] = useState(false);

  const closeModal = () => {
    walletStore.setSelectedSubWallet({});
    closeWalletModal();
  };

  const goBack = () => {
    if (bankListContent) {
      setBankListContent(false);
    } else if (beneficiaryContent) {
      setBeneficiaryContent(false);
    } else if (pinContent) {
      setPinContent(false);
    } else if (stage2) {
      setStage2(false);
    } else {
      closeModal();
    }
  };

  // transfer pin confirm logic

  const { walletStore } = useStores();
  const payload = walletStore.transferPayload;
  const data = {
    amount: payload.transactionAmount,
    accountName: payload.accountName || "test",
  };
  const [loading, setLoading] = useState(false);
  const onSubmitTransferPinConfirm = async (pin) => {
    payload.pin = pin;
    setLoading(true);
    delete payload.accountName;
    payload.currencyCode = "NGN";
    let response;
    const { selectedSubWallet } = walletStore;
    if (Object.keys(selectedSubWallet).length > 0) {
      payload.subWallet = selectedSubWallet?._id;
      response = await WalletController.transferSubWalletFund(payload);
    } else {
      response = await WalletController.transferFund(payload);
    }
    const { status, errorMessage } = response;
    walletStore.setSelectedSubWallet({});
    SettingsController.runTransactionSetup();
    setLoading(false);
    if (status === constant.Success) {
      return transferSwitchState.goToTransferSuccess();
    }

    if (errorMessage) {
      return MimaToastUtil.error({ message: errorMessage });
    }
    return transferSwitchState.goToTransferFailed();
  };

  return {
    goBack,
    stage2,
    setStage2,
    transferSwitchState,
    bankListContent,
    setBankListContent,
    beneficiaryContent,
    setBeneficiaryContent,
    // transfer pin confirm logic
    payload,
    onSubmitTransferPinConfirm,
    data,
    loading,
    closeModal,
  };
};
export default useTransferFundLogic;
