import { useEffect, useMemo, useState } from "react";
import { SettingsController, TransactionsController } from "../../controllers";
import { transactionStore } from "../../stores";
import constant from "../../utils/constant";
import { MimaToastUtil } from "../../components";
import { amountFormatter, theYear } from "../../utils/utils";
import { object, string, number, array } from "yup";
import moment from "moment";

const useTransactionLogic = () => {
  const [loading, setLoading] = useState(false);
  const [filterPeriod, setFilterPeriod] = useState(`${theYear}`);

  const { transactionSummary, expenseAnalysis } = TransactionsController;

  useEffect(() => {
    const setTransactions = async () => {
      setLoading(true);
      await Promise.all([
        // transactions(),
        SettingsController.expenseCategories(),
        transactionSummary(),
        expenseAnalysis(),
      ]);
      setLoading(false);
    };
    setTransactions();
  }, []);

  const validationSchema = () => {
    return object({
      startDate: string().required("Start Date is required"),
      endDate: string().required("End Date is required"),
    });
  };

  //modals

  const refresh = async () => {
    setLoading(true);
    await Promise.all([
      // transactions(),
      transactionSummary(),
      expenseAnalysis(),
    ]);
    setLoading(false);
  };

  //for tables
  const tableTypeConstant = {
    INFLOW: "INFLOW",
    OUTFLOW: "OUTFLOW",
    DEBT: "DEBT",
  };

  const inflowTagData = [
    {
      key: constant.FLOW_TYPE.All,
      value: "All Inflow Transactions",
    },
    {
      key: constant.FLOW_TYPE.Income,
      value: "Income",
    },
    {
      key: constant.FLOW_TYPE.Untagged,
      value: "Untagged",
    },
    {
      key: constant.FLOW_TYPE.Refund,
      value: "Refund",
    },
    {
      key: constant.FLOW_TYPE.Funding,
      value: "Wallet Funding",
    },
    {
      key: constant.FLOW_TYPE.Loan,
      value: "Loan",
    },
    {
      key: constant.FLOW_TYPE.Investment,
      value: "Investment",
    },
    {
      key: constant.FLOW_TYPE.Gifts,
      value: "Gifts And Donations",
    },
    {
      key: constant.FLOW_TYPE.Assets,
      value: "Sales Of Business Properties",
    },
  ];

  const outflowTagData = [
    {
      key: constant.FLOW_TYPE.All,
      value: "All Outflow Transactions",
    },
    {
      key: constant.FLOW_TYPE.Withdraw,
      value: "Withdrawal",
    },
    {
      key: constant.FLOW_TYPE.Expense,
      value: "Expense",
    },
  ];

  const [tableType, setTableType] = useState(tableTypeConstant.INFLOW);
  const [showContent, setShowContent] = useState(false);
  const [tagTitle, setTagTitle] = useState(inflowTagData[0].value);
  const [tagKey, setTagKey] = useState(inflowTagData[0].key);

  const tableTypeData = [
    tableTypeConstant.INFLOW,
    tableTypeConstant.OUTFLOW,
    tableTypeConstant.DEBT,
  ];

  const tableTagData = useMemo(() => {
    if (tableType === tableTypeConstant.INFLOW) {
      return inflowTagData;
    } else if (tableType === tableTypeConstant.OUTFLOW) {
      return outflowTagData;
    } else {
      return inflowTagData;
    }
  }, [tableType]);

  const tableTypeNavHandler = (type) => {
    setTableType(type);
    if (type === tableTypeConstant.INFLOW) {
      setTagTitle(inflowTagData[0].value);
      setTagKey(inflowTagData[0].key);
    } else if (type === tableTypeConstant.OUTFLOW) {
      setTagTitle(outflowTagData[0].value);
      setTagKey(outflowTagData[0].key);
    } else if (type === tableTypeConstant.DEBT) {
      setTagTitle("");
      setTagKey("");
    }
  };

  const tagContentHandler = () => {
    setShowContent(!showContent);
  };

  const tagSetHandler = (item) => {
    setTagTitle(item.value);
    setTagKey(item.key);
    tagContentHandler();
  };

  //Update to transaction Table
  const [transactionLimit, setTransactionLimit] = useState(50);
  const [transactionFilterQuery, setTransactionFilterQuery] = useState("");
  const [transactionCurrentPage, setTransactionCurrentPage] = useState(0);
  const [transactionSearchQuery, setTransactionSearchQuery] = useState("");

  const transactionTableQuery = useMemo(() => {
    if (tagKey === constant.FLOW_TYPE.All || tagKey === "") {
      return `offset=${transactionCurrentPage + 1}&limit=${transactionLimit}&type=${tableType}`;
    } else {
      return `offset=${transactionCurrentPage + 1}&limit=${transactionLimit}&type=${tableType}&inflowType=${tagKey}`;
    }
  }, [transactionCurrentPage, transactionLimit, tableType, tagKey]);

  const transactionDataInfo = TransactionsController.useTransactionTypeList(
    transactionTableQuery,
    transactionFilterQuery,
    transactionSearchQuery
  );

  const onTransactionLimitChange = (limit) => {
    setTransactionLimit(limit);
    setTransactionCurrentPage(0);
  };

  //MODALS
  const modalConstant = {
    close: "CLOSE",
    addExpense: "ADD-EXPENSE",
    addIncome: "ADD-INCOME",
    addDebt: "ADD-DEBT",
    updateDebt: "UPDATE-DEBT",
    updateTransaction: "UPDATE-TRANSACTION",
    editExpense: "EDIT-EXPENSE",
    editIncome: "EDIT-INCOME",
    viewIncome: "VIEW-INCOME",
    viewExpense: "VIEW-EXPENSE",
    tagTransaction: "TAG-TRANSACTION",
  };

  const [modalType, setModalType] = useState(modalConstant.close);

  // seting type
  const [type, setType] = useState("");

  useEffect(() => {
    if (modalType === modalConstant.addExpense) {
      setType("OUTFLOW");
    } else if (modalType === modalConstant.addDebt) {
      setType("DEBT");
    } else {
      setType("");
    }
  }, [modalType, modalConstant.addExpense, modalConstant.addDebt]);

  const openModalHandler = (item) => {
    setModalType(item);
  };

  const closeModal = () => {
    setModalType("CLOSE");
  };

  const handleExpenseOptionSelect = (_id, transaction, option) => {
    transactionStore.setSelectedTransaction(transaction);
    if (option.value === "View More") {
      // setViewMoreExpense(true);
      setModalType(modalConstant.viewExpense);
    } else if (option.value === "Update Expense") {
      // setUpdateTransaction(true);
      setModalType(modalConstant.updateTransaction);
    }
  };

  const onSubmit = async (payload) => {
    let query;

    if (payload && Object.keys(payload).length > 0) {
      query = `startDate=${payload.startDate}&endDate=${payload.endDate}&dateField=createdAt`;
      setTransactionFilterQuery(query);

      if (payload.period === "custom") {
        setFilterPeriod(
          `${moment(payload?.startDate).format("DD-MM-YYYY")} - ${moment(payload.endDate).format("DD-MM-YYYY")}`
        );
      } else {
        setFilterPeriod(`${payload?.periodText}`);
      }
    }
    setLoading(true);
    await Promise.all([
      TransactionsController.transactionSummary(query),
      // TransactionsController.transactions(query),
    ]);

    setLoading(false);
  };

  const resetFilter = () => {
    onSubmit();
    setFilterPeriod(`${theYear}`);
    setTransactionFilterQuery("");
  };

  // Update transactions

  const transaction = transactionStore.selectedTransaction;
  const validationSchemaUpdateTransactions = () => {
    return object({
      paidDate: string().test(function () {
        const { status, paidDate } = this.parent;
        return (status === constant.TRANSACTION_STATUS.PAID ||
          status === constant.TRANSACTION_STATUS.PARTIALLY_PAID) &&
          !paidDate
          ? this.createError({
              message: `Payment date is required for a ${status} status`,
              path: "paidDate",
            })
          : true;
      }),

      amountPaid: number()
        .min(0)
        .test(function () {
          const { status, amountPaid } = this.parent;
          return status === constant.TRANSACTION_STATUS.PARTIALLY_PAID &&
            amountPaid === 0
            ? this.createError({
                message: `Amount Paid is required for ${status} status`,
                path: "amountPaid",
              })
            : true;
        }),
      paymentMethod: string().test(function () {
        const { status, paymentMethod } = this.parent;
        return (status === constant.TRANSACTION_STATUS.PAID ||
          status === constant.TRANSACTION_STATUS.PARTIALLY_PAID) &&
          !paymentMethod
          ? this.createError({
              message: `Payment method is required for a ${status} status`,
              path: "paymentMethod",
            })
          : true;
      }),
      status: string().required(" Status is required"),
    });
  };

  const balanceAmount = (values) =>
    amountFormatter(transaction.currencyCode).format(
      values.status === constant.TRANSACTION_STATUS.PAID
        ? 0
        : transaction.balanceAmount - values.amountPaid
    );

  const onSubmitUpdateTransaction = async (payload) => {
    setLoading(true);
    payload.transactionId = transaction._id;
    if (payload.status === constant.TRANSACTION_STATUS.PAID) {
      payload.amountPaid = transaction.balanceAmount;
    }
    const { status, errorMessage } =
      await TransactionsController.updateOfflineTransactionBalance(payload);
    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({ message: constant.Success });
    } else {
      MimaToastUtil.error({ message: errorMessage });
    }
    closeModal();
  };
  const onCloseModal = () => {
    closeModal();
    setIncomeSuccess(false);
    setOpenIncomeModal(true);
    setExpenseSuccess(false);
    setOpenExpenseModal(true);
    transactionStore.setSelectedTransaction({});
    setLoading(false);
  };

  // Update Debt

  const validationSchemaUpdateDebt = () => {
    return object({
      paidDate: string().test(function () {
        const { status, paidDate } = this.parent;
        return (status === constant.TRANSACTION_STATUS.PAID ||
          status === constant.TRANSACTION_STATUS.PARTIALLY_PAID) &&
          !paidDate
          ? this.createError({
              message: `Payment date is required for a ${status} status`,
              path: "paidDate",
            })
          : true;
      }),
      balanceDueDate: string().test(function () {
        const { status, balanceDueDate } = this.parent;
        return status === constant.TRANSACTION_STATUS.PARTIALLY_PAID &&
          !balanceDueDate
          ? this.createError({
              message: `Balance Due date is required for a ${status} status`,
              path: "balanceDueDate",
            })
          : true;
      }),
      amountPaid: number()
        .min(0)
        .test(function () {
          const { status, amountPaid } = this.parent;
          return status === constant.TRANSACTION_STATUS.PARTIALLY_PAID &&
            amountPaid === 0
            ? this.createError({
                message: `Amount Paid is required for ${status} status`,
                path: "amountPaid",
              })
            : true;
        }),
      paymentMethod: string().test(function () {
        const { status, paymentMethod } = this.parent;
        return (status === constant.TRANSACTION_STATUS.PAID ||
          status === constant.TRANSACTION_STATUS.PARTIALLY_PAID) &&
          !paymentMethod
          ? this.createError({
              message: `Payment method is required for a ${status} status`,
              path: "paymentMethod",
            })
          : true;
      }),
      paidViaMima: string().test(function () {
        const { status, paidViaMima } = this.parent;
        return (status === constant.TRANSACTION_STATUS.PAID ||
          status === constant.TRANSACTION_STATUS.PARTIALLY_PAID) &&
          !paidViaMima
          ? this.createError({
              message: `paidViaMima status is required for a ${status} status`,
              path: "paidViaMima",
            })
          : true;
      }),

      status: string().required(" Status is required"),
    });
  };

  const balanceAmountUpdateDebt = (values) =>
    amountFormatter(transaction.currencyCode).format(
      values.status === constant.TRANSACTION_STATUS.PAID
        ? 0
        : transaction.deposit - values.amountPaid
    );

  const onSubmitUpdateDebt = async (payload) => {
    setLoading(true);
    payload.transactionId = transaction._id;
    if (payload.status === constant.TRANSACTION_STATUS.PAID) {
      payload.amountPaid = transaction.deposit;
    }
    const { status, errorMessage } =
      await TransactionsController.updateDebtStatus(payload);
    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({ message: constant.Success });
    } else {
      MimaToastUtil.error({ message: errorMessage });
    }
    closeModal();
  };

  // Add Income

  const [openIncomeModal, setOpenIncomeModal] = useState(true);
  const [incomeSuccess, setIncomeSuccess] = useState(false);
  const [toggle, setToggle] = useState(false);

  const getAddedIncome = () => {
    setOpenIncomeModal(false);
    setIncomeSuccess(true);
  };

  const addAnotherIncome = () => {
    setOpenIncomeModal(true);
    setIncomeSuccess(false);
  };

  const getToggle = () => {
    setToggle(!toggle);
  };

  const validationSchemaAddIncome = () => {
    return object({
      customer: string().optional("Select a customer"),
      orders: array(
        object({
          quantity: number().min(0.1, "Quantity should be greater than 0.1"),
          unitPrice: number().min(1, "Unit price should be greater than 1"),
          item: string().required("Service is required"),
        })
      ),
      currencyCode: string().required("Select Currency"),
      paidDate: string().required("Enter Payment due date"),
      paymentMethod: string().required("Payment Method is required"),
      deposit: number()
        .min(1, "Amount received should be greater than 0")
        .required("Amount Received is required")
        .test(function () {
          const { deposit, orders, discountAmount, shipping, currencyCode } =
            this.parent;
          let totalAmount = 0;
          orders.forEach(
            (order) => (totalAmount += order.unitPrice * order.quantity)
          );
          totalAmount = totalAmount + shipping - discountAmount;
          return totalAmount < deposit
            ? this.createError({
                message: `Maximum expected deposit amount should be ${amountFormatter(
                  currencyCode
                ).format(totalAmount)}`,
                path: "deposit",
              })
            : true;
        }),
      salesChannel: string().required("Sales Channel is required"),
    });
  };

  const onSubmitAddIncome = async (values) => {
    let payload = {};

    setLoading(true);
    if (!values.customer) {
      delete values.customer;
    }

    values.orders.forEach((item) => {
      if (item.stock === "") {
        delete item.stock;
      }
    });

    delete values.selectFromStock;
    delete values.customerName;

    payload = {
      ...values,
      paidDate: values.paidDate.toDateString(),
    };

    const { status, errorMessage } =
      await TransactionsController.postIncome(payload);
    setLoading(false);

    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return getAddedIncome();
    }

    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  // Add Expenses

  const [openExpenseModal, setOpenExpenseModal] = useState(true);
  const [expenseSuccess, setExpenseSuccess] = useState(false);

  // const { needFeedback } = useGetAllActiveReviews(type === "OUTFLOW" ? constant.FEEDBACK.TRANSACTIONSADDEXPENSE
  // 	: type === 'DEBT' ? constant.FEEDBACK.TRANSACTIONSADDDEBT : "");

  const getAddedExpenses = () => {
    setOpenExpenseModal(false);
    setExpenseSuccess(true);
  };

  const addNewExpense = () => {
    setOpenExpenseModal(true);
    setExpenseSuccess(false);
  };

  const validationSchemaAddExpense = () => {
    return object({
      expenseCategory: string().required("Expense Category is required"),
      transactionAmount: number()
        .required("Amount charged is required")
        .min(1, "The minimum transaction amount should be 1"),
      currencyCode: string().required("Select Currency"),
      deposit: number().test(function () {
        const { deposit } = this.parent;
        return type === "OUTFLOW" && deposit === 0
          ? this.createError({
              message: "Amount Paid is required required",
              path: "deposit",
            })
          : true;
      }),
      narration: string().required("Narration is required"),
      balanceDueDate: string().test(function () {
        const { transactionAmount, deposit, balanceDueDate } = this.parent;
        return type === "OUTFLOW" &&
          transactionAmount - deposit > 0 &&
          !balanceDueDate
          ? this.createError({
              message: "Expected balance payment date is required",
              path: "balanceDueDate",
            })
          : true;
      }),
      paidDate: string().test(function () {
        const { paidDate } = this.parent;
        return type === "OUTFLOW" && !paidDate
          ? this.createError({
              message: "Paid date is required",
              path: "paidDate",
            })
          : true;
      }),
      paymentMethod: string().test(function () {
        const { paymentMethod } = this.parent;
        return type === "OUTFLOW" && !paymentMethod
          ? this.createError({
              message: "Payment Method is required",
              path: "paymentMethod",
            })
          : true;
      }),
      dueDate: string().test(function () {
        const { dueDate } = this.parent;
        return type !== "OUTFLOW" && !dueDate
          ? this.createError({
              message: "Due Date is required",
              path: "dueDate",
            })
          : true;
      }),
    });
  };

  const onSubmitAddExpense = async (values) => {
    let payload = {};

    setLoading(true);
    for (const key in values) {
      if (values[key] === "") {
        delete values[key];
      }
    }
    payload = {
      ...values,
    };
    if (!values.balanceDueDate && type === "OUTFLOW") {
      delete values.balanceDueDate;
      payload.paidDate = values.paidDate.toDateString();
    }
    if (values.balanceDueDate && type === "OUTFLOW") {
      payload.paidDate = values.paidDate.toDateString();
      payload.balanceDueDate = values.balanceDueDate.toDateString();
    }
    payload.type = type;

    const { status, errorMessage } =
      await TransactionsController.postExpense(payload);
    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return getAddedExpenses();
    }
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  return {
    loading,
    validationSchema,
    onSubmit,
    filterPeriod,
    resetFilter,
    tableType,
    refresh,
    tableTypeNavHandler,
    tableTypeData,
    tagContentHandler,
    tagTitle,
    showContent,
    tableTagData,
    tagSetHandler,
    tableTypeConstant,
    transactionDataInfo,
    onTransactionLimitChange,
    transactionLimit,
    transactionCurrentPage,
    setTransactionCurrentPage,
    setTransactionSearchQuery,
    modalType,
    modalConstant,
    openModalHandler,
    closeModal,
    handleExpenseOptionSelect,
    // update transaction
    onSubmitUpdateTransaction,
    validationSchemaUpdateTransactions,
    balanceAmount,
    onCloseModal,
    // Update Debt
    balanceAmountUpdateDebt,
    onSubmitUpdateDebt,
    validationSchemaUpdateDebt,
    transaction,
    // Add Income
    openIncomeModal,
    incomeSuccess,
    onSubmitAddIncome,
    validationSchemaAddIncome,
    getToggle,
    addAnotherIncome,
    // Add expenses
    openExpenseModal,
    expenseSuccess,
    addNewExpense,
    validationSchemaAddExpense,
    onSubmitAddExpense,
    type,
  };
};

export default useTransactionLogic;
