import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  Modal,
} from "../../components";
import styles from "../../assets/styles/Settings/Settings.module.scss";
import { userStore } from "../../stores";
import { Formik } from "formik";
import React from "react";
import useSecurityLogic from "./useLogic/useSecurityLogic";

const Security = ({ closeModal }) => {
  const {
    securityQuestions,
    onSubmitChangePin,
    onSubmitSetPin,
    hasPin,
    changePinValidationSchema,
    loading,
    goToPasswordChange,
    onSubmitChangePassword,
    goToPinChange,
    setPinValidationSchema,
    pinSet,
    goToSetPin,
    passwordChange,
    changePasswordValidationSchema,
    pinChange,
    goBack,
  } = useSecurityLogic({ closeModal });
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <MimaText variant="subtitle" mb={3}>
        Pin & Security
      </MimaText>

      {!pinSet && !passwordChange && !pinChange && (
        <div>
          {hasPin ? (
            <MimaButton
              title="Change Pin"
              onClick={goToPinChange}
              mb={2}
              variant="form"
            />
          ) : (
            <MimaButton
              title="Set Pin"
              onClick={goToSetPin}
              mb={2}
              variant="form"
            />
          )}
          <MimaButton
            title="Change Password"
            buttonColor="var(--color-dark)"
            mb={2}
            variant="form"
            onClick={goToPasswordChange}
          />
        </div>
      )}

      {passwordChange && (
        <div>
          <MimaText mb={3} color="var(--color-primary)" align="center">
            Change your password
          </MimaText>
          <Formik
            initialValues={{
              password: "",
              newPassword: "",
              confirmNewPassword: "",
            }}
            validationSchema={changePasswordValidationSchema}
            onSubmit={onSubmitChangePassword}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <>
                <div className={styles.bus__details}>
                  <MimaInput
                    type="text"
                    labelTitle="Current Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    passwordIcon
                    placeholder="Enter current password"
                    error={errors.password}
                    touched={touched.password}
                  />
                  <MimaInput
                    type="text"
                    labelTitle="New Password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    passwordIcon
                    placeholder="Enter new current password"
                    error={errors.newPassword}
                    touched={touched.newPassword}
                  />
                  <MimaInput
                    type="text"
                    labelTitle="Confirm New Password"
                    name="confirmNewPassword"
                    value={values.confirmNewPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    passwordIcon
                    placeholder="Confirm new password"
                    error={errors.confirmNewPassword}
                    touched={touched.confirmNewPassword}
                  />

                  <MimaButton
                    disabled={loading}
                    loading={loading}
                    title="Change Password"
                    type="submit"
                    variant="form"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            )}
          </Formik>
        </div>
      )}
      {pinSet && (
        <Formik
          initialValues={{
            password: "",
            pin: "",
            secretQuestion: "",
            secretAnswer: "",
            secretQuestionName: "",
          }}
          validationSchema={setPinValidationSchema}
          onSubmit={onSubmitSetPin}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <>
              <div>
                <MimaText mb={3} color="var(--color-primary)" align="center">
                  Set your pin
                </MimaText>

                <div className={styles.bus__details}>
                  <MimaDropdown
                    labelTitle="Select a security question"
                    placeholder="Select a security question"
                    name="secretQuestion"
                    value={values.secretQuestionName}
                    id="secretQuestion"
                    onChange={(data) => {
                      setFieldValue("secretQuestion", data.key);
                      setFieldValue("secretQuestionName", data.value);
                    }}
                    data={securityQuestions}
                  />
                  {errors.secretQuestion ? (
                    <div className={styles.error}>
                      {""}
                      {touched.secretQuestion && errors.secretQuestion}
                      {""}
                    </div>
                  ) : null}
                  <MimaInput
                    type="text"
                    labelTitle="Answer"
                    name="secretAnswer"
                    value={values.secretAnswer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter answer to secret question"
                    error={errors.secretAnswer}
                    touched={touched.secretAnswer}
                  />
                  <MimaInput
                    type="text"
                    labelTitle="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    passwordIcon
                    placeholder="Enter your current password"
                    error={errors.password}
                    touched={touched.password}
                  />

                  <MimaInput
                    type="text"
                    labelTitle="PIN"
                    name="pin"
                    value={values.pin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    passwordIcon
                    placeholder="Set 4-digit Pin"
                    error={errors.pin}
                    touched={touched.pin}
                  />

                  <MimaButton
                    disabled={loading}
                    loading={loading}
                    title="Set Pin"
                    type="submit"
                    variant="form"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      )}

      {pinChange && (
        <Formik
          initialValues={{
            newPin: "",
            secretAnswer: "",
            password: "",
          }}
          validationSchema={changePinValidationSchema}
          onSubmit={onSubmitChangePin}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <>
              <div>
                <MimaText mb={3} color="var(--color-primary)" align="center">
                  Change your pin
                </MimaText>

                <div className={styles.bus__details}>
                  <MimaText mb={2} color="var(--color-primary)" align="center">
                    {userStore?.user?.security?.question?.question}?
                  </MimaText>

                  <MimaInput
                    type="text"
                    labelTitle="Answer"
                    name="secretAnswer"
                    value={values.secretAnswer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter the answer to your question"
                    error={errors.secretAnswer}
                    touched={touched.secretAnswer}
                  />

                  <MimaInput
                    type="text"
                    labelTitle="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    passwordIcon
                    placeholder="Enter your current password"
                    error={errors.password}
                    touched={touched.password}
                  />

                  <MimaInput
                    type="text"
                    labelTitle="New PIN"
                    name="newPin"
                    value={values.newPin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    passwordIcon
                    placeholder="Set 4-digit Pin"
                    error={errors.newPin}
                    touched={touched.newPin}
                  />

                  <MimaButton
                    disabled={loading}
                    loading={loading}
                    title="Change Pin"
                    type="submit"
                    variant="form"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default Security;
