import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  Modal,
  PageLoader,
} from "../../components";
import styles from "../../assets/styles/Settings/Settings.module.scss";
import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Form, Formik } from "formik";
import useWithdrawalAccountLogic from "./useLogic/useWithdrawalAccountLogic";
import currencies from "../../utils/currencies.json";
import countries2 from "../../utils/countries2.json";
import * as yup from "yup";

const WithdrawalAccount = ({ closeModal }) => {
  const {
    stage2,
    onSubmit,
    onSubmitPIN,
    accountName,
    setAccountName,
    setLoading,
    verifyAccountNumber,
    validationSchema,
    loading,
    banks,
    currencyCode,
    setCurrencyCode,
    step,
    setStep,
    goBack,
    verifyPaymentMethodsDetail,
    otpForm,
    onSubmitPinInternational,
    onSubmitInternational,
    initialValues,
    setMethods,
    methods,
    validationSchema2,
    selectedMethod,
    setSelectedMethod,
  } = useWithdrawalAccountLogic({ closeModal });

  const [otp, setOtp] = useState("");

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {step === "STEP1" && (
        <>
          <MimaText
            variant="subtitleBold"
            mb={3}
            color="var(--color-dark)"
            align="center"
          >
            Add Withdrawal Account
          </MimaText>
          <div>
            <MimaDropdown
              labelTitle="Select Currency"
              placeholder="-Select-"
              data={currencies?.map((value) => ({
                key: value?.code,
                value: value?.code,
              }))}
              id="bankCode"
              onChange={(data) => {
                setCurrencyCode(data?.value);
                setStep("STEP2");
              }}
            />
          </div>
        </>
      )}
      {step === "STEP2" && (
        <>
          {currencyCode === "NGN" ? (
            <>
              {/* Nigerian Banks Step */}
              <MimaText variant="subtitle" mb={1}>
                Withdrawal Account
              </MimaText>
              {!stage2 && (
                <Formik
                  initialValues={{ bankCode: "", bank: "", accountNumber: "" }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <>
                      <div className={styles.bus__details}>
                        <MimaDropdown
                          labelTitle="Bank Name"
                          placeholder="-Select-"
                          data={banks}
                          id="bankCode"
                          onChange={(data) => {
                            setFieldValue("bankCode", data?.id);
                            setFieldValue("bank", data?.value);
                          }}
                          name="bankCode"
                          onBlur={handleBlur}
                          error={errors?.bankCode}
                          touched={touched?.bankCode}
                        />
                        <MimaInput
                          type="text"
                          labelTitle="Account Number"
                          name="accountNumber"
                          value={values?.accountNumber}
                          onChange={(e) => {
                            setLoading(true);
                            verifyAccountNumber({
                              accountNumber: e?.target?.value,
                              beneficiaryBank: values?.bankCode,
                            }).then((data) => {
                              setAccountName(data);
                              setLoading(false);
                            });
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          error={errors?.accountNumber}
                          touched={touched?.accountNumber}
                        />
                        <MimaInput
                          type="text"
                          disabled={true}
                          labelTitle="Account Name"
                          name="accountName"
                          value={accountName}
                          placeholder={accountName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <MimaButton
                          disabled={loading}
                          loading={loading}
                          title="Continue"
                          type="submit"
                          variant="form"
                          onClick={handleSubmit}
                        />
                      </div>
                    </>
                  )}
                </Formik>
              )}

              {stage2 && (
                <Formik initialValues={{ otp: "" }} onSubmit={onSubmitPIN}>
                  {() => (
                    <div className={styles.pin}>
                      {loading ? (
                        <PageLoader title="Processing" loading={loading} />
                      ) : (
                        <>
                          <form onSubmit={onSubmitPIN}>
                            <MimaText
                              mb={3}
                              color="var(--color-primary)"
                              align="center"
                            >
                              Enter your pin to complete withdrawal account set
                              up
                            </MimaText>
                            <MimaText variant="small" ml={6}>
                              Enter Pin
                            </MimaText>
                            <OtpInput
                              value={otp}
                              onChange={(otp) => {
                                setOtp(otp);
                                if (otp?.length >= 4) {
                                  onSubmitPIN(otp);
                                }
                              }}
                              numInputs={4}
                              isInputSecure
                              inputStyle={{
                                fontSize: "2.4rem",
                                fontFamily: "inherit",
                                width: "6rem",
                                height: "6rem",
                                margin: ".4rem",
                                borderTop: "0px",
                                border: "1px solid #4A4A68",
                                borderRadius: "1rem",
                                display: "block",
                                outline: "none",
                                textAlign: "center",
                                marginRight: "2rem",
                              }}
                              isInputNum
                            />
                          </form>
                        </>
                      )}
                    </div>
                  )}
                </Formik>
              )}
            </>
          ) : (
            <>
              {!otpForm ? (
                <>
                  {/* International Step */}
                  <MimaText
                    variant="subtitleBold"
                    mb={3}
                    color="var(--color-dark)"
                    align="center"
                  >
                    Add Withdrawal Account
                  </MimaText>
                  <Formik
                    initialValues={{
                      countryCode: "",
                      currencyCode: "",
                      countryName: "",
                    }}
                    onSubmit={onSubmit}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <>
                        <MimaDropdown
                          labelTitle="Country"
                          name="countryName"
                          value={values?.countryName}
                          placeholder="Select Country"
                          data={countries2?.map((value) => ({
                            key: value?.countryCode,
                            value: value?.countryName,
                          }))}
                          onChange={(data) => {
                            setFieldValue("countryCode", data?.key);
                            setFieldValue("countryName", data?.value);
                          }}
                          touched={touched?.countryName}
                          error={errors?.countryName}
                        />
                        <MimaDropdown
                          labelTitle="Select Currency"
                          placeholder="-Select-"
                          name="currencyCode"
                          data={currencies?.map((value) => ({
                            key: value?.code,
                            value: value?.code,
                          }))}
                          onChange={(data) => {
                            setFieldValue("currencyCode", data?.value);
                            // setLoading(true);
                            verifyPaymentMethodsDetail({
                              countryCode: values?.countryCode,
                              currencyCode: data?.value,
                            }).then((data) => {
                              setMethods(data?.methods);
                            });
                            // setLoading(false);
                          }}
                          touched={touched?.currencyCode}
                          error={errors?.currencyCode}
                        />
                        {loading ? (
                          <PageLoader title="Processing" loading={loading} />
                        ) : (
                          <>
                            {methods?.length > 1 && (
                              <>
                                <MimaText
                                  variant="smallBold"
                                  mb={3}
                                  color="var(--color-dark)"
                                  align="center"
                                >
                                  Select preferred option
                                </MimaText>
                                <div className={styles.buttonContainer}>
                                  {methods?.map((method, i) => (
                                    <MimaButton
                                      key={i}
                                      title={method?.name}
                                      onClick={() => setSelectedMethod(method)}
                                      className={`${styles.button} ${selectedMethod === method ? styles.active : styles.dormant}`}
                                    />
                                  ))}
                                </div>
                              </>
                            )}

                            {selectedMethod && (
                              <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema2}
                                onSubmit={(values) => {
                                  // Form the payload with pin, paymentType, and data
                                  const payload = {
                                    currencyCode,
                                    pin: otp,
                                    paymentType: selectedMethod?.name,
                                    data: selectedMethod?.requiredFields.map(
                                      (field) => ({
                                        label: field,
                                        value: values[field],
                                      })
                                    ),
                                  };

                                  onSubmitInternational(payload);
                                }}
                              >
                                {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                }) => (
                                  <Form>
                                    <MimaText
                                      variant="smallBold"
                                      align="center"
                                      mt={4}
                                      mb={2}
                                    >
                                      Fill Required fields
                                    </MimaText>
                                    {selectedMethod?.requiredFields?.map(
                                      (field, index) => (
                                        <div key={index}>
                                          <MimaInput
                                            type="text"
                                            labelTitle={field}
                                            name={field}
                                            value={values?.[field]}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          {errors?.[field] &&
                                            touched?.[field] && (
                                              <div className="error">
                                                {errors?.[field]}
                                              </div>
                                            )}
                                        </div>
                                      )
                                    )}
                                    <MimaButton
                                      title="Submit"
                                      type="submit"
                                      variant="form"
                                      mt={2}
                                      onClick={handleSubmit}
                                    />
                                  </Form>
                                )}
                              </Formik>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  {" "}
                  <Formik
                    initialValues={{ otp: "" }}
                    onSubmit={onSubmitPinInternational}
                  >
                    {() => (
                      <div className={styles.pin}>
                        {loading ? (
                          <PageLoader title="Processing" loading={loading} />
                        ) : (
                          <div>
                            <form onSubmit={onSubmitPinInternational}>
                              <MimaText
                                mb={3}
                                color="var(--color-primary)"
                                align="center"
                              >
                                Enter your pin to complete FX withdrawal account
                                set up
                              </MimaText>
                              <MimaText variant="small" ml={6}>
                                Enter Pin
                              </MimaText>
                              <OtpInput
                                value={otp}
                                onChange={(otp) => {
                                  setOtp(otp);
                                  if (otp?.length >= 4) {
                                    onSubmitPinInternational(otp);
                                  }
                                }}
                                numInputs={4}
                                isInputSecure
                                inputStyle={{
                                  fontSize: "2.4rem",
                                  fontFamily: "inherit",
                                  width: "6rem",
                                  height: "6rem",
                                  margin: ".4rem",
                                  borderTop: "0px",
                                  border: "1px solid #4A4A68",
                                  borderRadius: "1rem",
                                  display: "block",
                                  outline: "none",
                                  textAlign: "center",
                                  marginRight: "2rem",
                                }}
                                isInputNum
                              />
                            </form>
                          </div>
                        )}
                      </div>
                    )}
                  </Formik>
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default WithdrawalAccount;
