import {
  MimaText,
  MimaInput,
  MimaButton,
  MimaDropdown,
} from "../../components";
import styles from "../../assets/styles/PayBills/PayBills.module.scss";
import { Formik } from "formik";
import React, { useMemo } from "react";
import { useGetAirtimeDataVendors } from "../../controllers/BillController";
import useBuyAirtimeLogic from "./useLogic/useBuyAirtimeLogic";
import EnterPin from "./EnterPin";

const BuyAirtime = ({ closeModal, pinContent,
  setPinContent, }) => {
  const { bill, validationSchema, onSubmit } = useBuyAirtimeLogic({
    closeModal, pinContent,
    setPinContent,
  });
  const isCopied = Object.keys(bill).length > 0;

  const { networkVendors } = useGetAirtimeDataVendors();

  const venList = useMemo(() => {
    const vendors = networkVendors.map((network) => {
      return { id: network.id, value: network.carrier };
    });
    return vendors;
  }, [networkVendors]);

  return (
    <>
      {!pinContent && (
        <>
          <MimaText variant="subtitle" mb={4}>
            Airtime
          </MimaText>
          <Formik
            initialValues={{
              phone: isCopied ? bill?.phone : "",
              amount: isCopied ? bill?.amount : 0,
              networkName: isCopied ? bill?.networkId : "",
              networkId: isCopied
                ? venList.find((x) => x?.value === bill?.networkId)?.id
                : "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              setFieldValue,
              touched,
            }) => (
              <div>
                <MimaDropdown
                  labelTitle="Select Network"
                  placeholder="-Select-"
                  id="networkName"
                  name="networkName"
                  value={values.networkName}
                  data={venList}
                  onChange={async (data) => {
                    setFieldValue("networkName", data.value);
                    setFieldValue("networkId", data.id);
                  }}
                  error={errors.networkName}
                  touched={touched.networkName}
                />

                <MimaInput
                  type="text"
                  labelTitle="Phone Number"
                  placeholder="Phone number"
                  name="phone"
                  mb={2}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.phone}
                  error={errors.phone}
                />

                <MimaInput
                  type="number"
                  labelTitle="Amount"
                  placeholder="0.00"
                  min={100}
                  name="amount"
                  mb={2}
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.amount}
                  error={errors.amount}
                />
                <MimaButton
                  title="Continue"
                  type="submit"
                  onClick={handleSubmit}
                  variant="form"
                />
              </div>
            )}
          </Formik>
        </>
      )}

      {pinContent && <EnterPin closeModal={closeModal} />}
    </>
  );
};
export default BuyAirtime;
