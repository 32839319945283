import styles from "../../assets/styles/Transactions/Transactions.module.scss";
import Succes from "../../assets/img/MimaCongrats2.png";
import { MimaText } from "../../components";
import React from "react";

function TransactionAddedSuccess({ closeModal, setShowSurvey, needFeedback }) {
  return (
    <>
      <img
        src={Succes}
        className={styles.successImg}
        alt="transaction success"
      />
      <MimaText variant="bodyBold" mt={3} mb={3} color="var(--color-primary)">
        Transaction Successfully Added
      </MimaText>
    </>
  );
}

export default TransactionAddedSuccess;
