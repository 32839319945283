import {
  MimaInput,
  MimaText,
  MimaTextArea,
  ReceiptCheckBox as RCB,
  MimaButton,
  MimaToastUtil,
  PageLoader,
  MimaDropdown,
} from "../../components";
import { amountFormatter, chargeSplitValues } from "../../utils/utils";
import styles from "../../assets/styles/PaymentLink/PaymentLink.module.scss";
import { businessStore, paymentLinkStore } from "../../stores";
import React from "react";
import { Formik, Field, FieldArray } from "formik";
import useEditPaymentLinkLogic from "./useLogic/useEditPaymentLinkLogic";

const EditPaymentLink = ({ goToViewLinks, editLink, cancelEdit }) => {
  const { validationSchema, onSubmit, loading } = useEditPaymentLinkLogic({
    goToViewLinks,
    editLink,
    cancelEdit,
  });
  const business = businessStore.business;
  const selectedLink = paymentLinkStore.selectedPaymentLink;

  return (
    <div className={styles.main}>
      <div className={styles.main__content}>
        <MimaText variant="subtitleBold" mb={2} align="center">
          Edit Payment Link for {selectedLink.title}
        </MimaText>
        <MimaButton
          title="Cancel Edit"
          onClick={cancelEdit}
          loading={loading}
          variant="filter"
          buttonColor="var(--color-error)"
          mb={2}
          width={18}
        />
        <Formik
          initialValues={{
            amount: selectedLink.amount || "",
            minDeposit: selectedLink.minDeposit || "",
            currencyCode: selectedLink.currencyCode || "NGN",
            title: selectedLink.title || "",
            description: selectedLink.description || "",
            chargeSplit: selectedLink.chargeSplit || "MY BUSINESS",
            acceptPhoneNumber: selectedLink.acceptPhoneNumber || false,
            acceptQuantity: selectedLink.acceptQuantity || false,
            acceptAddress: selectedLink.acceptAddress || false,
            acceptInstagramHandle: selectedLink.acceptInstagramHandle || false,
            acceptPartPayment: selectedLink.acceptPartPayment || false,
            previewAmount: "",
            previewFullName: "",
            previewEmail: "",
            previewMobile: "",
            previewQuantity: 1,
            previewIG: "",
            previewAddress: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => {
            return (
              <div className={styles.createLinks}>
                <div className={styles.createLinks_1}>
                  {loading ? (
                    <PageLoader title="While your link is being generated" />
                  ) : (
                    <div>
                      <MimaInput
                        labelTitle="Payment Link Name"
                        variant="form2"
                        placeholder="Payment Link Name"
                        name="title"
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.title}
                        error={errors.title}
                        mb={2}
                      />

                      <MimaTextArea
                        labelTitle="Description"
                        textAreaVariant="type3"
                        placeholder="Description"
                        name="description"
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.description}
                        error={errors.description}
                        mb={2}
                      />

                      {selectedLink.amount !== 0 ? (
                        <div className={styles.beside}>
                          <MimaText variant="small">
                            Accept Part payment?
                          </MimaText>
                          <div className={styles.beside}>
                            <RCB
                              title="Yes"
                              onChange={() => {
                                setFieldValue("acceptPartPayment", true);
                              }}
                              checked={values.acceptPartPayment}
                              value="true"
                              name="acceptPartPayment"
                            />
                            <RCB
                              title="No"
                              checked={!values.acceptPartPayment}
                              onChange={() =>
                                setFieldValue("acceptPartPayment", false)
                              }
                              value="false"
                              name="acceptPartPayment"
                            />
                          </div>
                          {errors.acceptPartPayment ? (
                            <div className={styles.error}>
                              {""}
                              {touched.acceptPartPayment &&
                                errors.acceptPartPayment}
                              {""}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className={
                          values.acceptPartPayment ? styles.beside : ""
                        }
                      >
                        {values.acceptPartPayment ? (
                          <MimaInput
                            labelTitle="Minimum Part Payment (Optional)"
                            variant="form2"
                            type="number"
                            placeholder="Minimum Part Payment"
                            name="minDeposit"
                            id="minDeposit"
                            value={values.minDeposit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched.minDeposit}
                            error={errors.minDeposit}
                          />
                        ) : (
                          ""
                        )}
                        <MimaDropdown
                          labelTitle="Who bears the payment charge"
                          name="chargeSplit"
                          value={values.chargeSplit}
                          placeholder={values.chargeSplit}
                          id="chargeSplit"
                          currentValue={values.chargeSplit}
                          onChange={(data) => {
                            setFieldValue("chargeSplit", data.key);
                          }}
                          data={chargeSplitValues}
                          touched={touched.chargeSplit}
                          error={errors.chargeSplit}
                          variant="filterForm"
                          bodyVariant="bodyBase2"
                        />
                      </div>
                      <div className={styles.beside}>
                        <MimaText variant="small">
                          Collect Phone Number?
                        </MimaText>
                        <div className={styles.beside}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("acceptPhoneNumber", true);
                            }}
                            checked={values.acceptPhoneNumber}
                            value="true"
                            name="acceptPhoneNumber"
                          />
                          <RCB
                            title="No"
                            checked={!values.acceptPhoneNumber}
                            onChange={() =>
                              setFieldValue("acceptPhoneNumber", false)
                            }
                            value="false"
                            name="acceptPhoneNumber"
                          />
                        </div>
                        {errors.acceptPhoneNumber ? (
                          <div className={styles.error}>
                            {""}
                            {touched.acceptPhoneNumber &&
                              errors.acceptPhoneNumber}
                            {""}
                          </div>
                        ) : null}
                      </div>

                      <div className={styles.beside}>
                        <MimaText variant="small">
                          Allow Quantity input?
                        </MimaText>
                        <div className={styles.beside}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("acceptQuantity", true);
                            }}
                            checked={values.acceptQuantity}
                            value="true"
                            name="acceptQuantity"
                          />
                          <RCB
                            title="No"
                            checked={!values.acceptQuantity}
                            onChange={() =>
                              setFieldValue("acceptQuantity", false)
                            }
                            value="false"
                            name="acceptQuantity"
                          />
                        </div>
                        {errors.acceptQuantity ? (
                          <div className={styles.error}>
                            {""}
                            {touched.acceptQuantity && errors.acceptQuantity}
                            {""}
                          </div>
                        ) : null}
                      </div>

                      <div className={styles.beside}>
                        <MimaText variant="small">
                          Collect Instagram Handle?
                        </MimaText>
                        <div className={styles.beside}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("acceptInstagramHandle", true);
                            }}
                            checked={values.acceptInstagramHandle}
                            value="true"
                            name="acceptInstagramHandle"
                          />
                          <RCB
                            title="No"
                            checked={!values.acceptInstagramHandle}
                            onChange={() =>
                              setFieldValue("acceptInstagramHandle", false)
                            }
                            value="false"
                            name="acceptInstagramHandle"
                          />
                        </div>
                        {errors.acceptInstagramHandle ? (
                          <div className={styles.error}>
                            {""}
                            {touched.acceptInstagramHandle &&
                              errors.acceptInstagramHandle}
                            {""}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.beside}>
                        <MimaText variant="small">Collect address?</MimaText>
                        <div className={styles.beside}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("acceptAddress", true);
                            }}
                            checked={values.acceptAddress}
                            value="true"
                            name="acceptAddress"
                          />
                          <RCB
                            title="No"
                            checked={!values.acceptAddress}
                            onChange={() =>
                              setFieldValue("acceptAddress", false)
                            }
                            value="false"
                            name="acceptAddress"
                          />
                        </div>
                        {errors.acceptAddress ? (
                          <div className={styles.error}>
                            {""}
                            {touched.acceptAddress && errors.acceptAddress}
                            {""}
                          </div>
                        ) : null}
                      </div>

                      <MimaButton
                        title="Update Payment Link"
                        onClick={handleSubmit}
                        type="submit"
                        loading={loading}
                        variant="form"
                        mt={2}
                      />
                    </div>
                  )}
                </div>

                <div className={styles.createLinks_2}>
                  <img src={business.logoUrl} alt="Business Logo" />
                  <MimaText
                    variant="bodyBold"
                    mt={1}
                    align="center"
                    color="var(--color-white)"
                  >
                    {values.title}
                  </MimaText>
                  <MimaText
                    variant="small"
                    align="center"
                    mb={2}
                    color="var(--color-white)"
                  >
                    by {business.tradingName}
                  </MimaText>
                  <MimaText align="center" mb={2} color="var(--color-white)">
                    {values.description}
                  </MimaText>

                  <div className={styles.createLinks_mini}>
                    <MimaText variant="bodyBold" mt={1} align="center">
                      {values.currencyCode}
                    </MimaText>
                    {values.acceptQuantity ? (
                      <MimaInput
                        labelTitle="Quantity"
                        variant="form2"
                        type="number"
                        placeholder="1"
                        name="previewQuantity"
                        id="previewQuantity"
                        value={values.previewQuantity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    ) : (
                      ""
                    )}

                    <MimaInput
                      labelTitle="Enter Payment Amount"
                      variant="form2"
                      type="number"
                      placeholder="0.00"
                      name="previewAmount"
                      id="previewAmount"
                      value={values.previewAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <div className={styles.beside}>
                      {values.amount !== "" && values.acceptQuantity ? (
                        <MimaText
                          variant="smallBold"
                          color="var(--color-primary)"
                          mb={1}
                          labelTitle="Amount Due"
                        >
                          {amountFormatter(values.currencyCode).format(
                            values.amount * values.previewQuantity
                          )}
                        </MimaText>
                      ) : values.amount !== "" ? (
                        <MimaText
                          variant="smallBold"
                          color="var(--color-primary)"
                          mb={1}
                          labelTitle="Amount Due"
                        >
                          {amountFormatter(values.currencyCode).format(
                            values.amount
                          )}
                        </MimaText>
                      ) : (
                        " "
                      )}

                      {values.minDeposit !== "" && values.acceptQuantity ? (
                        <MimaText
                          variant="smallBold"
                          color="var(--color-error)"
                          mb={1}
                          labelTitle="Minimum Deposit"
                        >
                          {amountFormatter(values.currencyCode).format(
                            values.minDeposit * values.previewQuantity
                          )}
                        </MimaText>
                      ) : values.minDeposit !== "" ? (
                        <MimaText
                          variant="smallBold"
                          color="var(--color-error)"
                          mb={1}
                          labelTitle="Minimum Deposit"
                        >
                          {amountFormatter(values.currencyCode).format(
                            values.minDeposit
                          )}
                        </MimaText>
                      ) : (
                        " "
                      )}
                    </div>

                    <MimaInput
                      labelTitle="Full Name"
                      variant="form2"
                      placeholder="Full Name"
                      name="previewFullName"
                      id="previewFullName"
                      value={values.previewFullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <MimaInput
                      labelTitle="Your Email Address"
                      type="email"
                      variant="form2"
                      placeholder="Last Name"
                      name="previewEmail"
                      id="previewEmail"
                      value={values.previewEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {values.acceptPhoneNumber ? (
                      <MimaInput
                        labelTitle="Your Phone Number"
                        type="tel"
                        variant="form2"
                        placeholder="Your Phone Number"
                        name="previewMobile"
                        id="previewMobile"
                        value={values.previewMobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    ) : (
                      ""
                    )}

                    {values.acceptInstagramHandle ? (
                      <MimaInput
                        labelTitle="Your Instagram Handle"
                        variant="form2"
                        placeholder="Your Instagram Handle"
                        name="previewIG"
                        id="previewIG"
                        value={values.previewIG}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    ) : (
                      ""
                    )}

                    {values.acceptAddress ? (
                      <MimaTextArea
                        labelTitle="Address"
                        textAreaVariant="type3"
                        placeholder="Enter Address"
                        name="previewAddress"
                        id="previewAddress"
                        value={values.previewAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    ) : (
                      ""
                    )}

                    <MimaButton
                      title="Make Payment"
                      onClick={() => {
                        MimaToastUtil.success({
                          message: "This is sample Preview ",
                        });
                      }}
                      variant="form"
                      mt={2}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EditPaymentLink;
