import {
  Modal,
  MimaText,
  QuickLinksComponent as QLC,
  BusinessInsightComponent as BIC,
  PageLoader,
  Searchbar,
  MimaDateFilter,
  MimaFilter,
  MimaDropdownFilter,
  MimaButton,
  MimaTable,
  MimaOptionMenu,
  AppShell,
} from "../../components";
import styles from "../../assets/styles/Invoice/Invoice.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { MdKeyboardArrowDown } from "react-icons/md";
import { amountFormatter } from "../../utils/utils";
import constant from "../../utils/constant";
import { invoiceStore, userStore } from "../../stores";
import React, { useState } from "react";
import { Formik } from "formik";
import moment from "moment";
import { useMemo } from "react";
import { toJS } from "mobx";
import useInvoiceLogic from "./useLogic/useInvoiceLogic";
import NewInvoice from "./NewInvoice";
import UpdateQuotation from "./UpdateQuotation";
import QuotationSummary from "./QuotationSummary";
import DuplicateQuote from "./DuplicateQuotes";
import EditQuotation from "./EditQuotation";
import InvoiceSummary from "./InvoiceSummary";
import UpdateInvoice from "./UpdateInvoice";
import NewQuotation from "./NewQuotation";
import NewBulkInvoice from "./NewBulkInvoice";
import { observer } from "mobx-react";

const Invoice = () => {
  const {
    loading,
    overDue,
    dueInThirtyDays,
    goToNewInvoice,
    addNewInvoice,
    closeModal,
    goToBulkInvoice,
    viewInvoiceModal,
    goToNewReceipt,
    statusesAnalysis,
    addNewReceipt,
    invoiceItem,
    editInvoiceMain,
    sendInvoice,
    sendQuotation,
    updateInvoiceModal,
    viewFullyPaidInvoices,
    viewNotPaidInvoices,
    viewPartiallyPaidInvoices,
    viewOverDueInvoices,
    viewInvoicesDueIn30Days,
    validationSchema,
    onSubmit,
    onQuotSubmit,
    filterPeriod,
    refresh,
    resetFilter,
    resetQuoteFilter,
    editInvoice,
    setEditInvoice,
    updateInvoice,
    setUpdateInvoice,
    addBulkInvoice,
    handleOptionSelect,
    curr,
    addNewQuotation,
    goToNewQuotation,
    goToQuoteSummary,
    quoteSummaryModal,
    editQuoteModal,
    goToEditQuote,
    tableType,
    setTableType,
    goToUpdateQuote,
    updateQuoteModal,
    invoiceListItem,
    quotationList,
    dupQuoteModal,
    goToDuplicateQuoteModal,
    data,
    isLoading,
    isFetching,
    onLimitChange,
    limit,
    filterPeriods,
    getDate,
    currentPage,
    setCurrentPage,
    setSearchQuery,
  } = useInvoiceLogic();
  const [filteredQuotes, setFilteredQuotes] = useState([]);
  const [onReset, setOnReset] = useState(false);
  const [showCurr, setShowCurr] = useState(false);
  const [quote, setQuote] = useState({});

  const tableData = useMemo(() => {
    if (data?.status === constant.Success) {
      return data?.data?.value?.invoices || [];
    }
    return [];
  }, [data]);

  const tableColumns = [
    {
      Header: "ID",
      accessor: "number",
      Cell: ({ cell }) => {
        const status = cell.row.original.status;
        const number = cell.value;
        const tag = status === "UNPAID" ? "INV" : "REC";
        return (
          <span>
            {tag} - {number}
          </span>
        );
      },
    },
    {
      Header: "Customer",
      accessor: "customer.fullname",
      Cell: ({ cell }) => {
        const hasParentInvoice = cell.row.original?.parentInvoice;
        const fullname = cell.value;
        const displayValue = hasParentInvoice
          ? `Balance Invoice for ${fullname}`
          : fullname;
        return <span>{displayValue}</span>;
      },
    },
    {
      Header: "Amount",
      accessor: "amountPaid",
      Cell: ({ cell }) => {
        const amount = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedAmount = amountFormatter(currencyCode).format(amount);

        return <span>{formattedAmount}</span>;
      },
    },
    {
      Header: "Total",
      accessor: "transactionAmount",
      Cell: ({ cell }) => {
        const total = cell.value;
        const currencyCode = cell.row.original.currencyCode;
        const formattedTotal = amountFormatter(currencyCode).format(total);

        return <span>{formattedTotal}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => {
        const status = cell.value;
        const statusClass = `tableStatus ${
          status === constant.TRANSACTION_STATUS.UNPAID
            ? "tableStatus__red"
            : status === constant.TRANSACTION_STATUS.VOID
              ? "tableStatus__dark"
              : status === constant.TRANSACTION_STATUS.PARTIALLY_PAID
                ? "tableStatus__yellow"
                : "tableStatus__green"
        }`;
        return <span className={statusClass}>{status}</span>;
      },
    },
    {
      Header: "Date Due",
      accessor: "dueDate",
      Cell: ({ cell }) => {
        const formattedDate = moment(cell.value).format("DD/MM/YY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        const status = cell.row.original.status;
        const parentInvoice = cell.row.original.parentInvoice;
        const invoiceID = cell.row.original._id;
        const item = cell.row.original;
        return (
          status !== constant.TRANSACTION_STATUS.VOID && (
            <MimaOptionMenu
              options={[
                {
                  value: "View More Details",
                  label: "View More Details",
                },
                status === constant.TRANSACTION_STATUS.UNPAID && !parentInvoice
                  ? {
                      value: "Edit",
                      label: "Edit",
                    }
                  : "",
                (status === constant.TRANSACTION_STATUS.PARTIALLY_PAID ||
                  status === constant.TRANSACTION_STATUS.PAID ||
                  status === constant.TRANSACTION_STATUS.UNPAID) &&
                !parentInvoice
                  ? {
                      value: "Duplicate",
                      label: "Duplicate",
                    }
                  : "",
                {
                  value: "Send to Customer",
                  label: "Send to Customer",
                },
                status === constant.TRANSACTION_STATUS.UNPAID
                  ? {
                      value: "Update to Receipt",
                      label: "Update to Receipt",
                    }
                  : "",
                // {
                // 	value: 'Download',
                // 	label: 'Download',
                // },
              ]}
              onOptionSelected={(option) => {
                handleOptionSelect(invoiceID, item, option);
              }}
            />
          )
        );
      },
    },
  ];

  const permissions = new Set(
    toJS(userStore.user?.permissions?.map((perm) => perm.name))
  );

  return (
    <AppShell pageTitle="Invoice/Receipt" refresh={refresh}>
      <MimaText variant="subtitle" mb={2} align="center">
        You are viewing Invoice/Receipt Insights for {filterPeriod}.
      </MimaText>
      {loading ? (
        <PageLoader title="Refreshing record..." loading={loading} />
      ) : (
        <>
          <div className={styles.upper}>
            {permissions.has("CAN VIEW INVOICE ANALYSIS") && (
              <div className={styles.upper__multiple}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <MimaText
                    variant="bodyBold"
                    color="var(--color-fash-primary-3)"
                    mb={1}
                    width={30}
                  >
                    Invoice/Receipt Insights for{" "}
                    {statusesAnalysis?.length === 0 ? filterPeriod : curr}{" "}
                    transactions
                    {/* {`Invoice/Receipt Insights for ${statusesAnalysis?.length === 0 ? "NGN" : statusesAnalysis[0]?._id} transactions`} */}
                  </MimaText>

                  {statusesAnalysis?.length > 1 ? (
                    <div className={styles.selectCurrency__text}>
                      <MimaText
                        variant="small"
                        color="var(--color-primary-4)"
                        style={{ paddingTop: ".5rem" }}
                        width={12}
                      >
                        Click arrow for other currencies
                      </MimaText>

                      <div className={styles.selectCurrency}>
                        <div
                          className={
                            !showCurr
                              ? styles.selectCurrency__icon
                              : styles.selectCurrency__icon_active
                          }
                          onClick={() => {
                            setShowCurr(!showCurr);
                          }}
                        >
                          <MdKeyboardArrowDown />
                        </div>

                        <AnimatePresence>
                          {showCurr && (
                            <motion.div
                              initial={{
                                opacity: 0,
                                scaleY: 0,
                              }}
                              animate={{
                                scaleY: 1,
                                opacity: 1,
                                transition: {
                                  duration: 0.2,
                                },
                              }}
                              exit={{
                                scaleY: 0,
                                opacity: 0,
                                transition: {
                                  duration: 0.2,
                                },
                              }}
                              style={{ originY: 0 }}
                              className={styles.selectCurrency__list}
                            >
                              {statusesAnalysis?.map((item) => (
                                <li
                                  key={item._id}
                                  className={
                                    curr === item._id
                                      ? styles.selectCurrency__list_active
                                      : ""
                                  }
                                  onClick={() => {
                                    invoiceStore.setSelectedCurrency(item._id);
                                    setShowCurr(false);
                                  }}
                                >
                                  {item._id}
                                </li>
                              ))}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <>
                  {statusesAnalysis?.length === 0 ? (
                    <div className={styles.upper__metrics}>
                      <BIC
                        title="Total Invoices"
                        amount={0}
                        styleClass={styles.upper__metrics_discount}
                      />
                      <BIC
                        title="Fully Paid Invoices"
                        amount={0}
                        styleClass={styles.upper__metrics_paid}
                        onClick={() => viewFullyPaidInvoices()}
                      />

                      <BIC
                        title="Partly Paid Invoices"
                        amount={0}
                        styleClass={styles.upper__metrics_partPaid}
                        onClick={() => viewPartiallyPaidInvoices()}
                      />

                      <BIC
                        title="Unpaid Invoices"
                        amount={0}
                        styleClass={styles.upper__metrics_unpaid}
                        onClick={() => viewNotPaidInvoices()}
                      />

                      <BIC
                        title="Invoice Due in 30 Days"
                        amount={0}
                        styleClass={styles.upper__metrics_vat}
                        onClick={() => viewInvoicesDueIn30Days()}
                      />
                      <BIC
                        title="Over Due Invoices"
                        amount={0}
                        styleClass={styles.upper__metrics_owe}
                        onClick={() => viewOverDueInvoices()}
                      />
                    </div>
                  ) : (
                    <>
                      {statusesAnalysis
                        ?.filter((x) => {
                          return x._id === curr;
                        })
                        .map((stat, i) => (
                          <div className={styles.upper__metrics} key={i}>
                            <BIC
                              title="Total Invoices"
                              amount={amountFormatter(stat._id).format(
                                (stat.status.filter(
                                  (status) => status.type === "UNPAID"
                                )[0]?.totalDeposit || 0) +
                                  (stat.status.filter(
                                    (status) => status.type === "FULLY-PAID"
                                  )[0]?.totalAmountPaid || 0) +
                                  (stat.status.filter(
                                    (status) => status.type === "PARTIALLY-PAID"
                                  )[0]?.totalAmountPaid || 0)
                              )}
                              styleClass={styles.upper__metrics_discount}
                            />
                            <BIC
                              title="Fully Paid Invoices"
                              amount={amountFormatter(stat._id).format(
                                parseInt(
                                  stat.status.filter(
                                    (status) => status.type === "FULLY-PAID"
                                  )[0]?.totalAmountPaid || 0,
                                  10
                                ) || 0
                              )}
                              styleClass={styles.upper__metrics_paid}
                              onClick={() => viewFullyPaidInvoices()}
                            />

                            <BIC
                              title="Partly Paid Invoices"
                              amount={amountFormatter(stat._id).format(
                                stat.status.filter(
                                  (status) => status.type === "PARTIALLY-PAID"
                                )[0]?.totalAmountPaid || 0
                              )}
                              styleClass={styles.upper__metrics_partPaid}
                              onClick={() => viewPartiallyPaidInvoices()}
                            />

                            <BIC
                              title="Unpaid Invoices"
                              amount={amountFormatter(stat._id).format(
                                stat.status.filter(
                                  (status) => status.type === "UNPAID"
                                )[0]?.totalDeposit || 0
                              )}
                              styleClass={styles.upper__metrics_unpaid}
                              onClick={() => viewNotPaidInvoices()}
                            />

                            <BIC
                              title="Invoice Due in 30 Days"
                              amount={amountFormatter(stat._id).format(
                                dueInThirtyDays.filter(
                                  (curr) => curr._id === stat._id
                                )[0]?.totalTobeDue || 0
                              )}
                              styleClass={styles.upper__metrics_vat}
                              onClick={() => viewInvoicesDueIn30Days()}
                            />
                            <BIC
                              title="Over Due Invoices"
                              amount={amountFormatter(stat._id).format(
                                overDue.filter(
                                  (curr) => curr._id === stat._id
                                )[0]?.totalOverdue || 0
                              )}
                              styleClass={styles.upper__metrics_owe}
                              onClick={() => viewOverDueInvoices()}
                            />
                          </div>
                        ))}
                    </>
                  )}
                </>
              </div>
            )}

            <div className={styles.upper__ql}>
              <MimaText variant="bodyBold">Quick Links</MimaText>
              <nav className={styles.upper__ql__content}>
                <QLC
                  title="Create New Invoice"
                  onClick={() => {
                    goToNewInvoice();
                  }}
                />
                <QLC title="Create Bulk Invoice" onClick={goToBulkInvoice} />
                <QLC title="Create New Receipt" onClick={goToNewReceipt} />
                <QLC title="Create New Quotation" onClick={goToNewQuotation} />
              </nav>
            </div>
          </div>
          <div className="searchInMiddle">
            {tableType === "Quotation" && (
              <Searchbar
                placeholder="Search Quotations"
                variant="wide"
                input={quotationList}
                loading={false}
                output={setFilteredQuotes}
                handleFilter={(item, searchQuery) => {
                  return (
                    item?.customer?.[0].fullname
                      ?.toLowerCase()
                      ?.includes(searchQuery) ||
                    item?.status?.toLowerCase()?.includes(searchQuery)
                  );
                }}
              />
            )}
          </div>

          <div>
            <MimaText mt={4} variant="bodyBold" align="center">
              You are viewing {tableType} Transactions for {filterPeriod}
            </MimaText>
            <div className={styles.main_nav}>
              <div
                className={
                  tableType === "Invoice"
                    ? styles.main_nav_item_active
                    : styles.main_nav_item
                }
                onClick={() => setTableType("Invoice")}
              >
                Invoice
              </div>
              <div
                className={
                  tableType === "Quotation"
                    ? styles.main_nav_item_active
                    : styles.main_nav_item
                }
                onClick={() => setTableType("Quotation")}
              >
                Quotation
              </div>
            </div>

            {tableType === "Invoice" && (
              <Formik
                initialValues={{
                  startDate: "",
                  endDate: "",
                  dateField: "createdAt",
                  period: "",
                  periodText: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                }) => (
                  <MimaFilter
                    filterClick={() => {
                      setOnReset(false);
                      handleSubmit();
                    }}
                    loading={loading}
                    resetClick={() => {
                      setOnReset(true);
                      resetFilter();
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <MimaDropdownFilter
                        labelTitle="Date Type"
                        name="dateField"
                        value={values.dateField}
                        id="dateField"
                        onChange={(data) => {
                          setFieldValue("dateField", data.key);
                        }}
                        placeholder={"CREATED DATE"}
                        currentValue={values.dateField}
                        data={[
                          { key: "paidDate", value: "PAID DATE" },
                          { key: "createdAt", value: "CREATED DATE" },
                          { key: "dueDate", value: "DUE DATE" },
                        ]}
                        touched={touched.dateField}
                        error={errors.dateField}
                      />
                      <MimaDropdownFilter
                        labelTitle="Filter period"
                        placeholder="Select Period"
                        variant="medium"
                        name="period"
                        data={filterPeriods}
                        onChange={(data) => {
                          const dates = getDate(data.key);
                          setFieldValue("period", data.key);
                          setFieldValue("startDate", dates.startDate);
                          setFieldValue("endDate", dates.endDate);
                          setFieldValue("periodText", data.value);
                        }}
                        error={errors.period}
                        touched={touched.period}
                      />
                      {values.period === "custom" && (
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <MimaDateFilter
                            labelTitle="start date"
                            name="startDate"
                            value={!onReset ? values.startDate : ""}
                            onChange={(text) => {
                              setOnReset(false);
                              setFieldValue("startDate", text);
                            }}
                            onBlur={handleBlur}
                            width={18}
                            touched={touched.startDate}
                            error={errors.startDate}
                          />
                          <MimaDateFilter
                            labelTitle="End date"
                            name="endDate"
                            value={!onReset ? values.endDate : ""}
                            onChange={(text) => {
                              setOnReset(false);
                              setFieldValue("endDate", text);
                            }}
                            onBlur={handleBlur}
                            width={18}
                            touched={touched.endDate}
                            error={errors.endDate}
                          />
                        </div>
                      )}
                    </div>
                  </MimaFilter>
                )}
              </Formik>
            )}

            {tableType === "Quotation" && (
              <Formik
                initialValues={{
                  startDate: "",
                  endDate: "",
                  dateField: "createdAt",
                  period: "",
                  periodText: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onQuotSubmit}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                }) => (
                  <MimaFilter
                    filterClick={() => {
                      setOnReset(false);
                      handleSubmit();
                    }}
                    loading={loading}
                    resetClick={() => {
                      setOnReset(true);
                      resetQuoteFilter();
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          marginBottom: "2rem",
                        }}
                      >
                        <MimaDropdownFilter
                          labelTitle="Date Type"
                          name="dateField"
                          value={values.dateField}
                          id="dateField"
                          onChange={(data) => {
                            setFieldValue("dateField", data.key);
                          }}
                          placeholder={"CREATED DATE"}
                          currentValue={values.dateField}
                          data={[
                            { key: "paidDate", value: "PAID DATE" },
                            { key: "createdAt", value: "CREATED DATE" },
                            { key: "dueDate", value: "DUE DATE" },
                          ]}
                          touched={touched.dateField}
                          error={errors.dateField}
                        />
                        <MimaDropdownFilter
                          labelTitle="Filter period"
                          placeholder="Select Period"
                          variant="medium"
                          name="period"
                          data={filterPeriods}
                          onChange={(data) => {
                            const dates = getDate(data.key);
                            setFieldValue("period", data.key);
                            setFieldValue("startDate", dates.startDate);
                            setFieldValue("endDate", dates.endDate);
                            setFieldValue("periodText", data.value);
                          }}
                          error={errors.period}
                          touched={touched.period}
                        />
                      </div>

                      {values.period === "custom" && (
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <MimaDateFilter
                            labelTitle="start date"
                            name="startDate"
                            value={!onReset ? values.startDate : ""}
                            onChange={(text) => {
                              setOnReset(false);
                              setFieldValue("startDate", text);
                            }}
                            onBlur={handleBlur}
                            width={18}
                            touched={touched.startDate}
                            error={errors.startDate}
                          />
                          <MimaDateFilter
                            labelTitle="End date"
                            name="endDate"
                            value={!onReset ? values.endDate : ""}
                            onChange={(text) => {
                              setOnReset(false);
                              setFieldValue("endDate", text);
                            }}
                            onBlur={handleBlur}
                            width={18}
                            touched={touched.endDate}
                            error={errors.endDate}
                          />
                        </div>
                      )}
                    </div>
                  </MimaFilter>
                )}
              </Formik>
            )}

            <MimaButton
              variant="form"
              title="Refresh"
              buttonColor="var(--color-fash-primary-3)"
              onClick={refresh}
              width={10}
              mt={2}
              mb={2}
            />
            {tableType === "Invoice" && (
              <MimaTable
                tableData={tableData}
                tableColumns={tableColumns}
                searchPlaceholder="Search Invoices"
                searchVariant="wide"
                totalItems={data?.data?.totalCounts}
                onLimitChange={onLimitChange}
                isLoading={isLoading}
                isFetching={isFetching}
                limit={limit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setSearchQuery={setSearchQuery}
              />
            )}

            <div className="whiteTable">
              {tableType === "Quotation" && (
                <table style={{ marginBottom: "10rem" }}>
                  <thead>
                    <tr>
                      <th> ID </th>
                      <th>Customer</th>
                      <th> Total </th>
                      <th>Issue Date</th>
                      <th> Status </th>
                      <th> Date Due </th>
                      <th> Actions </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredQuotes.map((item, _id) => (
                      <tr key={item._id}>
                        <td>{`QTN-${item.number}`}</td>
                        <td> {item.customer?.[0].fullname} </td>
                        <td> {item.transactionAmount} </td>
                        <td> {moment(item.updatedAt).format("DD/MM/YYYY")} </td>
                        <td
                          className={`${
                            item.status === "DRAFT"
                              ? styles.draft
                              : item.status === "ORDERED"
                                ? styles.ordered
                                : styles.expired
                          }`}
                        >
                          {" "}
                          {item.status}{" "}
                        </td>
                        <td> {moment(item.dueDate).format("DD/MM/YY")} </td>

                        <td>
                          {item.status !== constant.TRANSACTION_STATUS.VOID && (
                            <MimaOptionMenu
                              options={[
                                {
                                  value: "View More Details",
                                  label: "View More Details",
                                },

                                item.status ===
                                  constant.QUOTATION_STATUS.EXPIRED ||
                                item.status ===
                                  constant.QUOTATION_STATUS.ORDERED
                                  ? ""
                                  : {
                                      value: "Update",
                                      label: "Update",
                                    },

                                item.status ===
                                  constant.QUOTATION_STATUS.EXPIRED ||
                                item.status ===
                                  constant.QUOTATION_STATUS.ORDERED
                                  ? ""
                                  : {
                                      value: "Edit",
                                      label: "Edit",
                                    },
                                // {
                                //   value: "Duplicate",
                                //   label: "Duplicate",
                                // },
                                {
                                  value: "Send To Customer",
                                  label: "Send To Customer",
                                },
                              ]}
                              onOptionSelected={(option) => {
                                setQuote(item);
                                if (option.value === "View More Details") {
                                  goToQuoteSummary(item);
                                } else if (option.value === "Update") {
                                  goToUpdateQuote();
                                } else if (option.value === "Edit") {
                                  goToEditQuote();
                                } else if (
                                  option.value === "Send To Customer"
                                ) {
                                  sendQuotation(item._id);
                                }
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {(addNewInvoice ||
            viewInvoiceModal ||
            addNewReceipt ||
            addBulkInvoice ||
            updateInvoiceModal ||
            updateQuoteModal ||
            addNewQuotation ||
            quoteSummaryModal ||
            editQuoteModal ||
            dupQuoteModal) && (
            <div className="modal">
              <AnimatePresence>
                {addBulkInvoice && <NewBulkInvoice closeModal={closeModal} />}

                {addNewQuotation && (
                  <NewQuotation closeModal={closeModal} quote={quote} />
                )}

                {(viewInvoiceModal ||
                  updateInvoiceModal ||
                  editQuoteModal ||
                  quoteSummaryModal ||
                  updateQuoteModal ||
                  dupQuoteModal ||
                  addNewInvoice ||
                  addNewReceipt) && (
                  <Modal closeModal={closeModal} goBack={false}>
                    {updateInvoiceModal && (
                      <UpdateInvoice closeModal={closeModal} />
                    )}

                    {viewInvoiceModal && (
                      <InvoiceSummary
                        closeModal={closeModal}
                        editInvoiceMain={editInvoiceMain}
                        sendInvoice={sendInvoice}
                        editInvoice={editInvoice}
                        setEditInvoice={setEditInvoice}
                        updateInvoice={updateInvoice}
                        setUpdateInvoice={setUpdateInvoice}
                      />
                    )}

                    {editQuoteModal && (
                      <EditQuotation quote={quote} closeModal={closeModal} />
                    )}
                    {dupQuoteModal && (
                      <DuplicateQuote closeModal={closeModal} quote={quote} />
                    )}
                    {quoteSummaryModal && (
                      <QuotationSummary
                        quote={quote}
                        closeModal={closeModal}
                        editQuoteModal={editQuoteModal}
                        goToEditQuote={goToEditQuote}
                        sendQuotation={sendQuotation}
                      />
                    )}
                    {updateQuoteModal && (
                      <UpdateQuotation closeModal={closeModal} quote={quote} />
                    )}
                    {(addNewInvoice || addNewReceipt) && (
                      <NewInvoice
                        closeModal={closeModal}
                        invoiceItem={invoiceItem}
                      />
                    )}
                  </Modal>
                )}
              </AnimatePresence>
            </div>
          )}
        </>
      )}
    </AppShell>
  );
};

export default observer(Invoice);
