import styles from "../../assets/styles/Wallet/Wallet.module.scss";
import WalletQuickLinksCard from "./WalletQuickLinksCard";
import RecentActivities from "./RecentActivities";
import AccountInfoCard from "./AccountInfoCard";
import { AppShell, Modal } from "../../components";
import { businessStore, userStore, walletStore } from "../../stores";
import React, { useState } from "react";
import { IconButton, MimaText, PageLoader, MimaButton } from "../../components";
import { amountFormatter } from "../../utils/utils";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
// import VirtualCardContainer from "../../containers/WalletContainer/VirtualCardContainer";
import BeneficiaryList from "./Transfer/BeneficiaryList";
import { BusinessInsightComponent as BIC } from "../../components/Home";
import { useGetAllActiveReviews } from "../../controllers/SurveyController";
import constant from "../../utils/constant";
import FundWallet from "./FundWallet";
import { TransferFunds } from "./Transfer";
import Withdraw from "./Withdraw";
import WalletExchange from "./WalletExchange";
import AccountStatement from "./AccountStatement";
import BankAccountReq from "./BankAccountReq";
import Kyc from "../Settings/Kyc";
import VirtualCard from "./VirtualCard";
import useWalletLogic from "./useLogics/useWalletLogic";

const Wallet = () => {
  const {
    summary,
    transactions,
    closeWalletModal,
    fundModal,
    transferModal,
    withdrawModal,
    virtualCardModal,
    fundWallet,
    onSubmit,
    validationSchema,
    loading,
    setLoading,
    transfer,
    withdraw,
    virtualCard,
    bankAccounts,
    refreshWallet,
    transferSwitchState,
    sharedState,
    filterPeriod,
    resetFilter,
    pinContent,
    setPinContent,
    exchangeFunds,
    walletExchangeModal,
    createBankAccount,
    hasBusinessAccount,
    beneficiaryModal,
    viewBeneficiaries,
    viewStatement,
    statementModal,
    filterPeriods,
    getDate,
    kycModal,
    tier,
    bankAccModal,
  } = useWalletLogic();
  const urlSet = window.location.href;
  const [hide, setHide] = useState(true);
  const [showSurvey, setShowSurvey] = useState(false);

  const { needFeedback } = useGetAllActiveReviews(
    constant.FEEDBACK.WALLETMAKEPAYMENT
  );

  let eye;
  !hide ? (eye = "eyeOpen") : (eye = "eyeClose");

  const balanceToggle = () => {
    setHide(!hide);
  };

  const [curr, setCurr] = useState(
    `${businessStore.defaultCurrency ? businessStore.localCurrency : "NGN"}`
  );
  const [showCurr, setShowCurr] = useState(false);

  return (
    <AppShell pageTitle="Wallet" refresh={refreshWallet}>
      <div className={styles.main}>
        <div className={styles.wallets}>
          {walletStore?.wallets
            ?.filter((x) => {
              return x.currencyCode === curr;
            })
            ?.map((wallet) => (
              <div className={styles.balance}>
                <div className={styles.balance__side}>
                  <MimaText color="var(--color-dark)" variant="bodyBold" mb={2}>
                    {wallet.currencyCode} Wallet
                  </MimaText>
                  {walletStore?.wallets?.length > 1 ? (
                    <div className={styles.selectCurrency__text}>
                      <div className={styles.selectCurrency}>
                        <div
                          className={
                            !showCurr
                              ? styles.selectCurrency__icon
                              : styles.selectCurrency__icon_active
                          }
                          onClick={() => {
                            setShowCurr(!showCurr);
                          }}
                        >
                          <MdKeyboardArrowDown />
                        </div>

                        <AnimatePresence>
                          {showCurr && (
                            <motion.div
                              initial={{
                                opacity: 0,
                                scaleY: 0,
                              }}
                              animate={{
                                scaleY: 1,
                                opacity: 1,
                                transition: {
                                  duration: 0.2,
                                },
                              }}
                              exit={{
                                scaleY: 0,
                                opacity: 0,
                                transition: {
                                  duration: 0.2,
                                },
                              }}
                              style={{ originY: 0 }}
                              className={styles.selectCurrency__list}
                            >
                              {walletStore?.wallets?.map((item) => (
                                <li
                                  key={item.currencyCode}
                                  className={
                                    curr === item.currencyCode
                                      ? styles.selectCurrency__list_active
                                      : ""
                                  }
                                  onClick={() => {
                                    setCurr(item.currencyCode);
                                    setShowCurr(false);
                                  }}
                                >
                                  {item.currencyCode}
                                </li>
                              ))}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.balance__side}>
                  <div className={styles.balance__amount}>
                    <MimaText>Balance</MimaText>
                    {loading ? (
                      <PageLoader
                        title="Refreshing record..."
                        loading={loading}
                      />
                    ) : (
                      <MimaText variant="h3">
                        {!hide
                          ? amountFormatter(wallet.currencyCode).format(
                              wallet.balance
                            )
                          : "*******"}
                      </MimaText>
                    )}
                  </div>
                  <IconButton onClick={() => balanceToggle()} variant={eye} />
                </div>

                <div className={styles.balance__btns}>
                  <MimaButton
                    buttonColor="var(--color-white)"
                    title="Fund Wallet"
                    variant="filter"
                    onClick={() => fundWallet(wallet)}
                    width={14}
                    mt={2}
                  />
                  <MimaButton
                    buttonColor="var(--color-white)"
                    title="Exchange"
                    variant="filter"
                    onClick={() => exchangeFunds(wallet)}
                    width={10}
                    mt={2}
                  />
                  <MimaButton
                    buttonColor="var(--color-white)"
                    variant="filter"
                    title="Refresh"
                    onClick={refreshWallet}
                    width={10}
                    mt={2}
                  />
                </div>
              </div>
            ))}
          <>
            {summary
              .filter((x) => {
                return x.currencyCode === curr;
              })
              .map((transactionsSummary, i) => (
                <div className={styles.card} key={i}>
                  <BIC
                    title="Money In"
                    amount={amountFormatter(
                      transactionsSummary.currencyCode
                    ).format(transactionsSummary.totalNetCapital || 0)}
                    styleClass={styles.card_moneyIn}
                  />
                  <BIC
                    title="Money Out"
                    amount={amountFormatter(
                      transactionsSummary.currencyCode
                    ).format(transactionsSummary.totalMoneyOut || 0)}
                    styleClass={styles.card_moneyOut}
                  />
                </div>
              ))}
          </>
        </div>
        <WalletQuickLinksCard
          fundWallet={fundWallet}
          setLoading={setLoading}
          transfer={transfer}
          withdraw={withdraw}
          virtualCard={virtualCard}
          urlSet={urlSet}
          createBankAccount={createBankAccount}
          hasBusinessAccount={hasBusinessAccount}
          viewBeneficiaries={viewBeneficiaries}
          viewStatement={viewStatement}
        />
        <RecentActivities
          loading={loading}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          transactions={transactions}
          filterPeriod={filterPeriod}
          resetFilter={resetFilter}
          hide={hide}
          filterPeriods={filterPeriods}
          getDate={getDate}
        />
        {!hasBusinessAccount ? (
          ""
        ) : (
          <AccountInfoCard bankAccounts={bankAccounts} />
        )}
      </div>

      {/* <div className={styles.lowerHalf}>
				<div>
				</div>
			</div> */}

      {(fundModal ||
        transferModal ||
        withdrawModal ||
        walletExchangeModal ||
        virtualCardModal ||
        beneficiaryModal ||
        statementModal ||
        kycModal ||
        bankAccModal ||
        showSurvey) && (
        <div className="modal">
          <AnimatePresence>
            {fundModal && (
              <FundWallet
                closeWalletModal={closeWalletModal}
                bankAccounts={bankAccounts}
                urlSet={urlSet}
                hasBusinessAccount={hasBusinessAccount}
                setShowSurvey={setShowSurvey}
              />
            )}
            {transferModal && (
              <TransferFunds
                sharedState={sharedState}
                closeWalletModal={closeWalletModal}
                transferSwitchState={transferSwitchState}
                pinContent={pinContent}
                setPinContent={setPinContent}
                setShowSurvey={setShowSurvey}
                needFeedback={needFeedback}
              />
            )}
            {withdrawModal && (
              <Withdraw
                closeModal={closeWalletModal}
                sharedState={sharedState}
                transferSwitchState={transferSwitchState}
                key="withdrawModal"
                pinContent={pinContent}
                setPinContent={setPinContent}
              />
            )}
            {walletExchangeModal && (
              <WalletExchange closeWalletModal={closeWalletModal} />
            )}
            {virtualCardModal && <VirtualCard closeModal={closeWalletModal} />}

            {statementModal && (
              <AccountStatement closeModal={closeWalletModal} />
            )}

            {beneficiaryModal && (
              <Modal closeModal={closeWalletModal}>
                <BeneficiaryList />
              </Modal>
            )}

            {kycModal && <Kyc closeModal={closeWalletModal} tier={tier} />}

            {bankAccModal && (
              <BankAccountReq
                closeModal={closeWalletModal}
                setShowSurvey={setShowSurvey}
              />
            )}
            {/* {showSurvey && (
              <Survey
                title=""
                reviewId={userStore.reviewId}
                closeModal={() => {
                  setShowSurvey(false);
                }}
              />
            )} */}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default Wallet;
