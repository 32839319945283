import React, { useEffect, useState } from "react";
import { WebsiteController } from "../../../controllers";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
import * as yup from "yup";
import { colorsArray, handleImageUpload } from "../../../utils/utils";

const useUploadStyleLogic = ({ closeModal, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [newCollections, setNewCollections] = useState([]);
  const [hashTags, setHashTags] = useState([]);

  useEffect(() => {
    const fetchColletions = async () => {
      await WebsiteController.getCollections();
    };
    fetchColletions();
  }, [newCollections]);

  const validationSchema = yup.object({
    name: yup.string().required("Enter Style Name"),
    pricingModel: yup.string().required("Pricing model is required"),
    local: yup.object().shape({
      // withoutMaterial: yup
      //   .number()
      //   .min(0.01, "Price must be greater than 0")
      //   .required("Price without material is required"),
      withMaterial: yup
        .number()
        .min(0.01, "Price with material must be greater than 0")
        .required("Price with material is required"),
    }),

    styleCollections: yup.array().when("createCollections", {
      is: false, // Only apply validation when createCollections is false
      then: yup
        .array()
        .min(
          1,
          "Please select at least one collection or click Yes to create collection."
        )
        .required("Style Collection is required."),
    }),

    addCustomColor: yup.boolean().required(),

    daysRequiredForDelivery: yup.object({
      early: yup
        .number()
        .required("Early delivery days are required")
        .min(1, "Early delivery days must be at least 1"),
      late: yup
        .number()
        .required("Late delivery days are required")
        .min(
          yup.ref("early"),
          "Late delivery days must be greater than or equal to early delivery days"
        ),
    }),

    customColors: yup.array().when("addCustomColor", {
      is: true,
      then: yup
        .array()
        .min(1, "Custom color is required")
        .required("Custom color is required"),
      otherwise: yup.array(),
    }),
  });

  const onSubmit = async (values) => {
    if (values.imageUrls?.length < 1) {
      return MimaToastUtil.error({
        message: "Kindly Add atleast 1 image ",
      });
    }

    setLoading(true);

    const filteredColors = colorsArray
      .filter((color) => values.colorsAvailable.includes(color.code))
      .map((color) => ({
        color: color.color,
        code: color.code,
        type: "PRIMARY",
      }));

    const customColors = values.customColors.map((color) => ({
      color: color,
      code: "",
      type: "CUSTOM",
    }));

    let urls = [];
    if (values.imageUrls.length > 0) {
      urls = await handleImageUpload(values.imageUrls);
    }

    const payload = {
      name: values.name,
      pricingModel: values.pricingModel,
      parts: ["string"],
      imageUrls: urls,
      hashTags: hashTags,
      sizesAvailable: values.sizesAvailable,
      topView: values.topView,
      colorsAvailable: [...filteredColors, ...customColors].filter(
        (color) => color.color && color.code
      ),
      sizes: values.sizes.map((size) => size.toString()),
      styleCollections:
        values.styleCollections.length > 0
          ? values.styleCollections.map((style) => style.trim())
          : newCollections.map((collection) => collection.trim()),

      daysRequiredForDelivery: values.daysRequiredForDelivery,
      local: values.local,
      international: values.international,
      description: values.description,
      gender: values.gender,
      bookingLinkUrl: values.bookingLinkUrl,
      accessories: values.accessories,
    };

    if (values.pricingModel === "FIXED-PRICE") {
      delete payload.local;
      delete payload.international;
    }
    if (values.hashTags.length < 1) {
      delete payload.hashTags;
    }

    if (!values.international.currencyCode) {
      delete payload.international.currencyCode;
    }
    if (!values.description) {
      delete payload.description;
    }
    if (!values.bookingLinkUrl) {
      delete payload.bookingLinkUrl;
    }

    const { status, errorMessage } =
      await WebsiteController.uploadStyle(payload);
    if (status === constant.Success) {
      refetch();
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };
  return {
    newCollections,
    setNewCollections,
    validationSchema,
    onSubmit,
    loading,
    hashTags,
    setHashTags,
  };
};

export default useUploadStyleLogic;
