/* eslint-disable no-unused-vars */
import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  ReceiptCheckBox as RCB,
  Modal,
  MimaDateInput,
} from "../../components";

import styles from "../../assets/styles/Transactions/Transactions.module.scss";
import { amountFormatter } from "../../utils/utils";
import { transactionStore } from "../../stores";
import constant from "../../utils/constant";
import { Formik } from "formik";
import React from "react";

const UpdateTransactions = ({
  loading,
  onSubmitUpdateTransaction,
  validationSchemaUpdateTransactions,
  balanceAmount,
  closeModal,
}) => {
  const transaction = transactionStore.selectedTransaction;
  const statuses =
    transaction.status === constant.TRANSACTION_STATUS.PARTIALLY_PAID
      ? [
          {
            key: constant.TRANSACTION_STATUS.PAID,
            value: constant.TRANSACTION_STATUS.PAID,
          },
          {
            key: constant.TRANSACTION_STATUS.PARTIALLY_PAID,
            value: constant.TRANSACTION_STATUS.PARTIALLY_PAID,
          },
          {
            key: constant.TRANSACTION_STATUS.VOID,
            value: constant.TRANSACTION_STATUS.VOID,
          },
        ]
      : [
          {
            key: constant.TRANSACTION_STATUS.VOID,
            value: constant.TRANSACTION_STATUS.VOID,
          },
        ];

  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      <div>
        <MimaText
          variant="subtitleBold"
          color="var(--color-fash-primary-3)"
          align="center"
          mb={2}
        >
          Update status
        </MimaText>
        <MimaText variant="small" align="center" mb={4}>
          Manually update the status of your {transaction.inflowType}
        </MimaText>
        <Formik
          initialValues={{
            paidDate: "",
            paymentMethod: "",
            status: "",
            amountPaid: 0,
          }}
          validationSchema={validationSchemaUpdateTransactions}
          onSubmit={onSubmitUpdateTransaction}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <div>
              <div className={styles.summary__total}>
                <MimaText>Total Amount</MimaText>
                <MimaText
                  variant="bodyBold"
                  color="var(--color-fash-primary-3)"
                >
                  {amountFormatter(transaction.currencyCode).format(
                    transaction.transactionAmount
                  )}
                </MimaText>
                <MimaText> Paid Amount </MimaText>
                <MimaText
                  variant="bodyBold"
                  color="var(--color-fash-primary-3)"
                >
                  {amountFormatter(transaction.currencyCode).format(
                    transaction.amountPaid
                  )}
                </MimaText>
                <MimaText>Balance Amount</MimaText>
                <MimaText
                  variant="bodyBold"
                  color="var(--color-fash-primary-3)"
                >
                  {amountFormatter(transaction.currencyCode).format(
                    transaction.balanceAmount
                  )}
                </MimaText>
              </div>
              <MimaDropdown
                labelTitle={`${transaction.inflowType} status`}
                placeholder="Select Status"
                name="status"
                currentValue={values.status}
                id="status"
                onChange={(data) => {
                  setFieldValue("status", data.key);
                }}
                data={statuses}
                mt={2}
              />
              {errors.status ? (
                <div className={styles.error}>
                  {""}
                  {touched.status && errors.status}
                  {""}
                </div>
              ) : null}
              {values.status !== constant.TRANSACTION_STATUS.VOID &&
                transaction.status ===
                  constant.TRANSACTION_STATUS.PARTIALLY_PAID && (
                  <>
                    <div className={styles.beside}>
                      <div>
                        <MimaInput
                          type="number"
                          labelTitle="Amount Received"
                          placeholder="0.00"
                          name="amountPaid"
                          width={18}
                          disabled={
                            values.status === constant.TRANSACTION_STATUS.PAID
                              ? true
                              : false
                          }
                          value={
                            values.status === constant.TRANSACTION_STATUS.PAID
                              ? transaction.balanceAmount
                              : values.amountPaid
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          mr={2}
                        />

                        {errors.amountPaid ? (
                          <div className={styles.error}>
                            {""}
                            {touched.amountPaid && errors.amountPaid}
                            {""}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <MimaDateInput
                          labelTitle="Paid date"
                          name="paidDate"
                          value={values.paidDate}
                          onChange={(text) => {
                            setFieldValue("paidDate", text);
                          }}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.paidDate}
                          error={errors.paidDate}
                          mr={2}
                        />
                        {errors.paidDate ? (
                          <div className={styles.error}>
                            {touched.paidDate && errors.paidDate}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <MimaText variant="small" ml={2}>
                      Payment Method
                    </MimaText>
                    <div className={styles.checks}>
                      <RCB
                        title="Cash"
                        id="CASH"
                        name="paymentMethod"
                        onChange={() => {
                          setFieldValue("paymentMethod", "CASH");
                        }}
                        checked={values.paymentMethod === "CASH"}
                        value="cash"
                      />
                      <RCB
                        title="Transfer"
                        onChange={() => {
                          setFieldValue("paymentMethod", "TRANSFER");
                        }}
                        checked={values.paymentMethod === "TRANSFER"}
                        id="transfer"
                        name="paymentMethod"
                        value="transfer"
                      />
                      <RCB
                        title="P.O.S"
                        id="pos"
                        onChange={() => {
                          setFieldValue("paymentMethod", "POS");
                        }}
                        checked={values.paymentMethod === "POS"}
                        name="paymentMethod"
                        value="pos"
                      />
                    </div>
                    {errors.paymentMethod ? (
                      <div className={styles.error}>
                        {touched.paymentMethod && errors.paymentMethod}
                      </div>
                    ) : null}
                  </>
                )}
              <MimaButton
                title="Update"
                onClick={handleSubmit}
                type="submit"
                loading={loading}
                variant="form"
                mt={2}
              />
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdateTransactions;
