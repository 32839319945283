import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { businessStore, userStore } from '../stores';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { queryClient } from '../App';

export const GET_ALL_ACTIVE_REVIEW_LIST = 'get-all-active-review-list';

export const useGetAllActiveReviews = (key) => {
	const response = useQuery({
		queryKey: [businessStore.bId, GET_ALL_ACTIVE_REVIEW_LIST],
		queryFn: () => {
			return apiRequest({
				url: '/reviews/active-review',
				method: 'get',
			});
		},
	});

	const needFeedback = useMemo(() => {
		if (response.data?.status === constant.Success) {
			const { data = [] } = (response.data || {});
			const unDoneReviews = userStore.unDoneReviews || [];
			const foundReview = data.find(x => x.key === key) || {};
			const unDoneReviewId = unDoneReviews.find(x => x === foundReview._id)
			return unDoneReviewId ? foundReview : {};
		}
		return {};
	}, [response.data]);

	return { ...response, needFeedback };
};

export const usePostReview = () => {
	return useMutation({
		mutationFn: (payload) => {
			return apiRequest({
				url: `/reviews/review-result`,
				payload: payload,
				method: 'post',
			});
		},
		onSuccess: (data, variables) => {
			if (data.status === constant.Success) {
				const { updatedUnDoneReviews = [] } = data.data || {}
				userStore.setUnDoneReviews(updatedUnDoneReviews);
			}
			queryClient.invalidateQueries({
				queryKey: [businessStore.bId, GET_ALL_ACTIVE_REVIEW_LIST],
			});
		},
	});
};



