import styles from "../../assets/styles/Settings/Settings.module.scss";
import React, { useState } from "react";
import {
  Modal,
  MimaText,
  MimaDropdown,
  ReceiptCheckBox as RCB,
  MimaButton,
  MimaDateInput,
} from "../../components";
import { Formik } from "formik";
import useInvoiceReminderLogic from "./useLogic/useInvoiceReminderLogic";

const InvoiceReminder = ({ closeModal }) => {
  const {
    invoiceReminder,
    loading,
    timePeriod,
    validationSchema,
    invoiceBankAccount,
    onSubmit,
  } = useInvoiceReminderLogic({ closeModal });
  const [isConfig, setIsConfif] = useState(invoiceReminder._id);

  return (
    <Modal closeModal={closeModal}>
      {isConfig && (
        <div>
          <MimaText
            variant="subtitleBold"
            mb={6}
            color="var(--color-dark)"
            align="center"
          >
            Invoice Settings
          </MimaText>

          <div className={styles.configDetails}>
            <MimaText variant="body" color="var(--color-primary)">
              Invoice Reminder Timeline
            </MimaText>
            <MimaText variant="body">{`Every ${invoiceReminder.invoiceTimer}hrs`}</MimaText>
          </div>
          {/* <div className={styles.configDetails}>
                        <MimaText variant="body" color="var(--color-primary)">Reminder Config date</MimaText>
                        <MimaText variant="body">{moment(invoiceReminder.createdAt).format("DD/MM/Y")}</MimaText>
                    </div> */}
          <div className={styles.configDetails}>
            <MimaText variant="body" color="var(--color-primary)">
              Repeat
            </MimaText>
            <MimaText variant="body">
              {invoiceReminder.activateInvoiceReminder.toString()}
            </MimaText>
          </div>
          <div className={styles.configDetails}>
            <MimaText variant="body" color="var(--color-primary)">
              Send SMS
            </MimaText>
            <MimaText variant="body">
              {invoiceReminder.activateTransactionsSMS.toString()}
            </MimaText>
          </div>
          <div className={styles.configDetails}>
            <MimaText variant="body" color="var(--color-primary)">
              Bank account on Invoice
            </MimaText>
            <MimaText variant="body">
              {invoiceReminder.invoiceBankAccount} Account
            </MimaText>
          </div>
          <div>
            <MimaButton
              title="Edit"
              onClick={() => {
                setIsConfif(!isConfig);
              }}
              mt={4}
              variant="form"
            />
          </div>
        </div>
      )}

      {!isConfig && (
        <Formik
          initialValues={{
            activateTransactionsSMS: invoiceReminder.activateTransactionsSMS
              ? invoiceReminder.activateTransactionsSMS
              : false,
            invoiceTimer: invoiceReminder.invoiceTimer
              ? invoiceReminder.invoiceTimer
              : 6,
            invoiceTimerValue: invoiceReminder.invoiceTimerValue
              ? invoiceReminder.invoiceTimerValue
              : "",
            // invoiceReminderDate: invoiceReminder.invoiceReminderDate ?
            //     new Date(invoiceReminder.invoiceReminderDate) : "",
            activateInvoiceReminder: invoiceReminder.activateInvoiceReminder
              ? invoiceReminder.activateInvoiceReminder
              : false,
            invoiceBankAccount: invoiceReminder.invoiceBankAccount
              ? invoiceReminder.invoiceBankAccount
              : "WITHDRAWAL",
          }}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <div>
              <MimaText
                variant="subtitleBold"
                mb={4}
                color="var(--color-dark)"
                align="center"
              >
                Invoice Settings
              </MimaText>

              <MimaDropdown
                labelTitle={"Reminder"}
                placeholder={"none"}
                name="invoiceTimer"
                id="invoiceTimer"
                value={values.invoiceTimerValue}
                currentValue={values.invoiceTimerValue}
                data={timePeriod}
                onChange={(data) => {
                  setFieldValue("invoiceTimer", data.key);
                  setFieldValue("invoiceTimerValue", data.value);
                }}
                error={errors.invoiceTimer}
                touched={touched.invoiceTimer}
              />

              <MimaDropdown
                labelTitle={"Bank account on Invoice"}
                placeholder={
                  `${values.invoiceBankAccount} Account` ||
                  "Select invoice BankAccount"
                }
                name="invoiceBankAccount"
                id="invoiceBankAccount"
                value={values.invoiceBankAccount}
                currentValue={values.invoiceBankAccount}
                data={invoiceBankAccount}
                onChange={(data) => {
                  setFieldValue("invoiceBankAccount", data.key);
                }}
                error={errors.invoiceBankAccount}
                touched={touched.invoiceBankAccount}
              />
              {/* <MimaDateInput
                                labelTitle="Reminder Config date"
                                name="invoiceReminderDate"
                                value={values.invoiceReminderDate}
                                onChange={text => {
                                    setFieldValue('invoiceReminderDate', text);
                                }}
                                width={38}
                            /> */}

              <MimaText width="38" mb={1}>
                Send SMS
              </MimaText>
              <div className={styles.repeat}>
                <RCB
                  title={"Yes"}
                  name="activateTransactionsSMS"
                  value="true"
                  checked={values.activateTransactionsSMS}
                  onChange={() => {
                    setFieldValue("activateTransactionsSMS", true);
                  }}
                />
                <RCB
                  title={"No"}
                  name="activateTransactionsSMS"
                  value="false"
                  checked={!values.activateTransactionsSMS}
                  onChange={() => {
                    setFieldValue("activateTransactionsSMS", false);
                  }}
                />
              </div>
              <MimaText width="38" mb={1} mt={4}>
                Repeat
              </MimaText>
              <div className={styles.repeat}>
                <RCB
                  title={"Yes"}
                  name="activateInvoiceReminder"
                  value="true"
                  checked={values.activateInvoiceReminder}
                  onChange={() => {
                    setFieldValue("activateInvoiceReminder", true);
                  }}
                />
                <RCB
                  title={"No"}
                  name="activateInvoiceReminder"
                  value="false"
                  checked={!values.activateInvoiceReminder}
                  onChange={() => {
                    setFieldValue("activateInvoiceReminder", false);
                  }}
                />
              </div>
              <MimaButton
                title={"Continue"}
                mt="5"
                onClick={handleSubmit}
                loading={loading}
                variant="form"
              />
            </div>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default InvoiceReminder;
