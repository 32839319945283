import React, { useMemo } from "react";
import {
  AppShell,
  MimaButton,
  MimaOptionMenu,
  MimaTable,
} from "../../components";
import useMeasurementLogic from "./useLogic/useMeasurementLogic";
import constant from "../../utils/constant";
import { AnimatePresence } from "framer-motion";
import TakeMeasurement from "./TakeMeasurement";
import moment from "moment";
import EditMeasurement from "./EditMeasurement";
import ViewMoreMeasurement from "./ViewMoreMeasurement";

const Measurement = () => {
  const {
    measurementData,
    limit,
    currentPage,
    setCurrentPage,
    onLimitChange,
    setSearchQuery,
    handleOptionSelect,
    goToTakeMeasurement,
    takeMeasurementModal,
    editMeasurementModal,
    closeModal,
    goBack,
    newCustomer,
    createNewCustomer,
    onSubmitCustomer,
    customerDetailsValidation,
    loading,
    viewMoreModal,
  } = useMeasurementLogic();

  const reFetch = measurementData.refetch;

  const tableData = useMemo(() => {
    if (measurementData?.data?.status === constant.Success) {
      return measurementData?.data?.data?.measurements || [];
    }
    return [];
  }, [measurementData]);

  const tableColumns = [
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ cell }) => {
        const createdAt = cell.row.original?.createdAt;
        return <span>{moment(createdAt).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      Header: "Measurement For",
      accessor: "measurementFor",
    },
    {
      Header: "Customer",
      Cell: ({ cell }) => {
        const customer = cell.row.original?.customer?.fullname;
        return <span>{customer ? customer : "NA"}</span>;
      },
    },
    {
      Header: "Design",
      Cell: ({ cell }) => {
        const style = cell.row.original?.style?.name;
        return <span>{style ? style : "NA"}</span>;
      },
    },

    {
      Header: "Unit Of Measurement",
      accessor: "unitOfMeasurement",
    },
    {
      Header: "Parts",

      Cell: ({ cell }) => {
        const parts = cell.row.original?.parts?.length;
        return (
          <div>
            <span>{parts}</span>
          </div>
        );
      },
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        const measurementId = cell.row.original._id;
        const selectedMeasurement = cell.row.original;
        return (
          <MimaOptionMenu
            options={[
              {
                value: "Edit Measurement",
                label: "Edit Measurement",
              },
              {
                value: "View More",
                label: "View More",
              },
            ]}
            onOptionSelected={(option) => {
              handleOptionSelect(measurementId, selectedMeasurement, option);
            }}
          />
        );
      },
    },
  ];
  return (
    <AppShell pageTitle="Measurements">
      <MimaButton
        title="Take Measurement"
        variant="regular"
        onClick={goToTakeMeasurement}
        loading={loading}
      />
      <MimaTable
        tableData={tableData}
        tableColumns={tableColumns}
        searchPlaceholder="Search Measurement"
        searchVariant="wide"
        totalItems={measurementData?.data?.data?.totalCounts}
        onLimitChange={onLimitChange}
        isLoading={measurementData.isLoading}
        isFetching={measurementData.isFetching}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchQuery={setSearchQuery}
      />
      {(takeMeasurementModal || editMeasurementModal || viewMoreModal) && (
        <div className="modal">
          <AnimatePresence>
            {takeMeasurementModal && (
              <TakeMeasurement
                closeModal={closeModal}
                goBack={goBack}
                newCustomer={newCustomer}
                createNewCustomer={createNewCustomer}
                onSubmitCustomer={onSubmitCustomer}
                customerDetailsValidation={customerDetailsValidation}
                reFetch={reFetch}
              />
            )}
            {editMeasurementModal && (
              <EditMeasurement
                closeModal={closeModal}
                goBack={goBack}
                newCustomer={newCustomer}
                createNewCustomer={createNewCustomer}
                onSubmitCustomer={onSubmitCustomer}
                customerDetailsValidation={customerDetailsValidation}
                reFetch={reFetch}
              />
            )}
            {viewMoreModal && <ViewMoreMeasurement closeModal={closeModal} />}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default Measurement;
