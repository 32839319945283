import styles from "../../assets/styles/Customer/Customer.module.scss";
import Avatar from "../../assets/img/Mima-avatar.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MimaText, Modal } from "../../components";
import React from "react";
import { customerStore } from "../../stores";

const ViewCustomer = ({
  closeModal,
  showCustomerViewMenu,
  customerViewMenu,
  goToEditCustomer,
  goBack,
}) => {
  const customer = customerStore.selectedCustomer;
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <div className={styles.view}>
        <div className={styles.view_menu} onClick={showCustomerViewMenu}>
          <BsThreeDotsVertical />
        </div>
        {customerViewMenu && (
          <div className={`${styles.customerMenu} ${styles.view_customerMenu}`}>
            <ul>
              <li onClick={goToEditCustomer}>Edit</li>
              {/* <li>Delete</li>
							<li>Send Invoice</li>
							<li>Send Receipt</li>
							<li>Add Note</li> */}
            </ul>
          </div>
        )}
      </div>
      <div className={styles.customer__avatar_container}>
        <img
          src={Avatar}
          alt="Customer image"
          className={styles.customer__avatar}
        />
      </div>
      <MimaText variant="subtitle" color="var(--color-primary)" align="center">
        {customer.fullname}
      </MimaText>
      <div className={styles.view_content}>
        <MimaText labelTitle="Phone Number" mb={2}>
          {customer.mobile}
        </MimaText>
        <MimaText labelTitle="Alternate Phone Number" mb={2}>
          {customer.secondaryMobile}
        </MimaText>
        <MimaText labelTitle="Email Address" mb={2}>
          {customer.email}
        </MimaText>
        <MimaText labelTitle="Gender" mb={2}>
          {customer.gender}
        </MimaText>
        <MimaText labelTitle="Delivery Address" mb={2}>
          {customer.address}
        </MimaText>
        <MimaText labelTitle="Description" mb={2}>
          {customer.description}
        </MimaText>
      </div>
    </Modal>
  );
};

export default ViewCustomer;
