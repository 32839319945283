import styles from "../../assets/styles/CountDown/CountDown.module.scss";
import React from "react";
import { MimaButton, MimaText } from "..";
import { userStore } from "../../stores";
import PlayStoreIcon from "../../assets/img/playstore.png";
import AppleStoreIcon from "../../assets/img/apple.png";
import constant from "../../utils/constant";

const AppDLBanner = ({ appLink, setAppLink, hasMobileApp }) => {
  const appleIcon = (
    <img
      src={AppleStoreIcon}
      alt="Mima Business on ios"
      className={styles.banner__icon}
    />
  );

  const playIcon = (
    <img
      src={PlayStoreIcon}
      alt="Mima Business on android"
      className={styles.banner__icon}
    />
  );

  return (
    <>
      {((appLink && !hasMobileApp) || !hasMobileApp) && (
        <div className={styles.banner}>
          <MimaText variant="small" mb={2} mt={1}>
            Download and use the Fash creatives app on your mobile devices.
          </MimaText>
          <div className={styles.banner__beside}>
            <a
              href={constant.Routes.AndroidApp}
              target="_blank"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <MimaButton
                title="Get app on Android"
                variant="outlined2"
                icon={playIcon}
                mb={1}
              />
            </a>

            <a
              href={constant.Routes.IosApp}
              target="_blank"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <MimaButton
                title="Get app on iPhone"
                variant="outlined2"
                icon={appleIcon}
                mb={1}
              />
            </a>
          </div>

          <MimaButton
            title="Close"
            variant="form"
            buttonColor="var(--color-error)"
            onClick={() => {
              setAppLink(false);
              userStore.setHasMobileApp(true);
            }}
            mb={1}
          />
        </div>
      )}
    </>
  );
};
export default AppDLBanner;
