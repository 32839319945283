import styles from "../../assets/styles/Settings/Settings.module.scss";
import {
  MimaButton,
  MimaInput,
  MimaText,
  Modal,
  MimaDropdown,
  PageLoader,
} from "../../components";
import React, { useState } from "react";
import { Formik } from "formik";
import { userStore, useStores } from "../../stores";
import { BsCheck } from "react-icons/bs";
import { toJS } from "mobx";
import OtpInput from "react-otp-input";
import useKycLogic from "./useLogic/useKycLogic";

const Kyc = ({ closeModal, tier }) => {
  const {
    addCac,
    addBvn,
    goToAddCac,
    goToAddBvn,
    goBack,
    loading,
    Tier2ValidationSchema,
    onSubmitTier2,
    selectClick,
    file,
    otpView,
    onSubmitOTP,
  } = useKycLogic({ closeModal, tier });
  const tierTwo = [
    "Unlmited daily Withdrawals from wallets ",
    "Unlmited Monthly Withdrawals from wallets",
    "Access to Receive Payment in Multicurency(USD, GBP, EUR)",
    "Generate a Business Bank Account",
    "Access to Sell to a Global Market",
  ];

  const {
    appStore: { businessCategories },
  } = useStores();

  const [otp, setOtp] = useState("");
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <MimaText variant="subtitleBold" mb={1}>
        KYC Verification
      </MimaText>
      <MimaText color="var(--color-primary)" mb={2}>
        You are currently a {tier} user
      </MimaText>

      {tier === "Tier 1" && (
        <>
          {!addBvn && (
            <>
              <MimaText
                mb={3}
                variant="smallBold"
                color="var(--color-dark-3)"
                width={38}
                align="justify"
              >
                In order to advance to Tier 2 classification, You must provide
                supplementary data to fulfill the local regulatory requirements.
                Upon successful advancement to Tier 2, you will gain access to
                additional privileges:
              </MimaText>
              <ul style={{ width: "38rem" }}>
                {tierTwo.map((item, i) => (
                  <li className={styles.kyc__list}>
                    {" "}
                    <BsCheck className={styles.kyc__list__icon} key={i} />{" "}
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
              {/* <MimaText width={38} mb={2} mt={2} align="center" color="var(--color-primary)">To enjoy these Benefits kindly Include your Business Information & Bank Verification Number (BVN)</MimaText> */}
              <MimaButton title="Upgrade to Tier 2" onClick={goToAddBvn} />
            </>
          )}

          {addBvn && (
            <>
              {!otpView && (
                <Formik
                  initialValues={{
                    bvn: "",
                    regNo: "",
                    mobile: userStore.user.mobile,
                    fullname: `${userStore.user.firstname} ${userStore.user.middlename || ""}${userStore.user.lastname}`,
                    address: "",
                    bizIndustry: "",
                    businessMobile: "",
                  }}
                  onSubmit={onSubmitTier2}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    values,
                    errors,
                    touched,
                  }) => (
                    <>
                      <MimaText variant="bodyBold" mb={1} mt={2}>
                        Add your Business & BVN Information
                      </MimaText>
                      <MimaInput
                        type="text"
                        labelTitle="Fullname"
                        name="fullname"
                        id="fullname"
                        value={values.fullname}
                        onChange={handleChange}
                        error={errors.fullname}
                        touched={touched.fullname}
                        onBlur={handleBlur}
                      />
                      <MimaInput
                        type="text"
                        labelTitle="Personal Phone Number"
                        name="mobile"
                        id="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        error={errors.mobile}
                        touched={touched.mobile}
                        onBlur={handleBlur}
                      />
                      <MimaInput
                        type="text"
                        labelTitle="Bank Verification Number(BVN)"
                        placeholder="BVN"
                        name="bvn"
                        id="bvn"
                        value={values.bvn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.bvn}
                        error={errors.bvn}
                      />
                      <MimaDropdown
                        labelTitle="Business Category"
                        onChange={(data) => {
                          setFieldValue("bizIndustry", data.key);
                        }}
                        placeholder="Select a Business Category"
                        data={toJS(businessCategories)}
                        name="bizIndustry"
                        error={errors.bizIndustry}
                        touched={touched.bizIndustry}
                      />
                      <MimaInput
                        type="text"
                        labelTitle="Business Phone Number"
                        name="businessMobile"
                        id="businessMobile"
                        value={values.businessMobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        mb={3}
                      />
                      <MimaButton
                        title="Select Logo"
                        variant="outlined"
                        width={38}
                        onClick={selectClick}
                      />
                      <MimaText
                        variant="small"
                        mt={2}
                        width={38}
                        align="center"
                        color="var(--color-primary)"
                      >
                        {file?.name || ""}{" "}
                      </MimaText>
                      <MimaInput
                        type="text"
                        labelTitle="Where is your Business Located?"
                        placeholder="Business Address"
                        name="address"
                        id="address"
                        error={errors.address}
                        touched={touched.address}
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        mt={2}
                      />

                      {/* <MimaInput
													type="text"
													labelTitle="Business Registration Number(optional)"
													placeholder="RC 12345678"
													name="regNo"
													id="regNo"
													value={values.regNo}
													onChange={handleChange}
													onBlur={handleBlur}
												/> */}

                      <MimaButton
                        disabled={loading}
                        loading={loading}
                        title="Upgrade to Tier 2"
                        type="submit"
                        onClick={handleSubmit}
                      />
                    </>
                  )}
                </Formik>
              )}

              {otpView && (
                <Formik
                  initialValues={{
                    otp: "",
                  }}
                  onSubmit={onSubmitOTP}
                >
                  {({ handleChange, handleBlur, values, errors, touched }) => (
                    <>
                      {loading ? (
                        <PageLoader
                          title="While we process your information"
                          loading={loading}
                        />
                      ) : (
                        <>
                          <MimaText
                            mb={2}
                            variant="smallBold"
                            color="var(--color-dark-3)"
                            width={38}
                            align="justify"
                          >
                            An OTP was sent to the email and phone number
                            registered to the BVN submitted, please confirm it
                            is yours by inputting the OTP
                          </MimaText>
                          <label htmlFor="otp" className={styles.form__label}>
                            Enter OTP
                          </label>

                          <div className={styles.otp__group}>
                            <OtpInput
                              value={otp}
                              onChange={(otp) => {
                                setOtp(otp);
                                if (otp >= 10000) {
                                  onSubmitOTP(otp);
                                }
                              }}
                              numInputs={5}
                              inputStyle={{
                                fontSize: "2.4rem",
                                fontFamily: "inherit",
                                width: "6rem",
                                height: "6rem",
                                margin: ".4rem",
                                borderTop: "0px",
                                border: "1px solid #4A4A68",
                                borderRadius: "1rem",
                                display: "block",
                                outline: "none",
                                textAlign: "center",
                                marginRight: "2rem",
                              }}
                              containerStyle={
                                {
                                  // margin: '20px auto',
                                  // padding: '10px',
                                }
                              }
                              isInputNum
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Formik>
              )}
            </>
          )}
        </>
      )}

      {/* {tier === "Tier 2" && (
				<>
					{
						!addCac && (
							<>
								<MimaText mb={1} variant="bodyBold">
									Tier 3 Benefits include
								</MimaText>
								<ul style={{width: "38rem"}}>
									<li>abbbbbbbbbsbnsbnsbn</li>
									<li>aabbbbbbbbbsbnsbnsbn</li>
									<li>aabbbbbbbbbsbnsbnsbn</li>
									<li>aabbbbbbbbbsbnsbnsbn</li>
								</ul>
								<MimaText width={38} mb={2} mt={2} align="center" color="var(--color-primary)">To enjoy these Benefits kindly Include your C.A.C registration Number</MimaText>
								<MimaButton title="Add C.A.C Registration No." onClick={goToAddCac}/>
							</>
						)
					}

					{
						addCac && (
							<>
								<Formik
									initialValues={{
									}}
									onSubmit={onSubmitCac}>
									{({
										handleChange,
										handleBlur,
										handleSubmit,
										setFieldValue,
										values,
										errors,
										touched,
									}) => (
										<>
											<MimaInput
												labelTitle="Enter C.A.C Registration Number"
											/>

											<MimaButton
												disabled={loading}
												loading={loading}
												title="Submit C.A.C Reg No."
												type="submit"
												onClick={handleSubmit}
											/>
										</>
									)}
								</Formik>
							</>
						)
					}
				</>
			)} */}
    </Modal>
  );
};

export default Kyc;
