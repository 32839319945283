import styles from "../../assets/styles/MimaInput.module.scss";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import * as inputStyles from "./styles";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Field } from "formik";
import { observer } from "mobx-react";

const QuillEditor = ({ field, form, error, touched, ...props }) => {
  return (
    <div>
      <ReactQuill
        value={field.value}
        onChange={(value) => form.setFieldValue(field.name, value)}
        {...props}
      />
      {touched && error && <div className="error">{error}</div>}
    </div>
  );
};

QuillEditor.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

const propTypes = {
  labelTitle: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.any,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.any,
};

const defaultProps = {
  labelTitle: "",
  placeholder: "",
  id: "",
  width: "",
};

const ReactQuillInput = ({
  labelTitle,
  placeholder,
  width,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  touched,
  error,
  ...props
}) => {
  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
      {labelTitle && (
        <label className={styles.form__label} htmlFor={name}>
          {labelTitle}
        </label>
      )}
      <Field
        name={name}
        component={QuillEditor}
        error={error}
        touched={touched}
        {...props}
        placeholder={placeholder}
      />
    </DivSkeleton>
  );
};

const DivSkeleton = styled.div`
  ${inputStyles.divBase};
  ${(props) => props.mt && `margin-top: ${props.mt}rem`};
  ${(props) => props.mr && `margin-right: ${props.mr}rem`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}rem`};
  ${(props) => props.ml && `margin-left: ${props.ml}rem`};
  ${(props) => props.width && `width: ${props.width}rem`};
`;

ReactQuillInput.propTypes = propTypes;
ReactQuillInput.defaultProps = defaultProps;

export default observer(ReactQuillInput);
