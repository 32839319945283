import Utilities from "../../../pages/PayBills/Utilities";
import React, { useState } from "react";
import constant from "../../../utils/constant";

const useUtilitiesLogic = ({ closeModal }) => {
  const [selectElectricBill, setSelectElectricBill] = useState(false);
  const [mainContents, setMainContents] = useState(true);
  const [pinContent, setPinContent] = useState(false);

  const payElectricBill = () => {
    setSelectElectricBill(true);
    setPinContent(false);
    setMainContents(false);
  };

  const goBack = () => {
    if (selectElectricBill) {
      setSelectElectricBill(false);
      setMainContents(true);
    } else if (pinContent) {
      setPinContent(false);
      setSelectElectricBill(true);
    } else {
      closeModal();
    }
  };

  return {
    selectElectricBill,
    mainContents,
    payElectricBill,
    goBack,
    pinContent,
    setPinContent,
  };
};

export default useUtilitiesLogic;
