import React, { useState } from "react";
import { object, string, number, array, boolean, date } from "yup";
import {
  CustomerController,
  InvoiceController,
  QuotationController,
} from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { invoiceStore } from "../../../stores";
import { phoneRegExp } from "../../../utils/utils";

const useNewQuotationLogic = ({ closeModal, invoiceItem }) => {
  const [stage2, setStage2] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [loading, setLoading] = useState(false);

  const createNewCustomer = () => {
    setNewCustomer(true);
  };

  const returnToInvoicing = () => {
    setNewCustomer(false);
  };

  const onDownload = async (invoiceId) => {
    const { errorMessage } = await InvoiceController.download(invoiceId);
    if (errorMessage) {
      MimaToastUtil.error({
        message: errorMessage,
      });
    } else {
      MimaToastUtil.success({
        message: constant.Success,
      });
    }
  };

  const orders =
    invoiceStore.copiedInvoice?.orders?.map((order) => {
      return {
        quantity: order.quantity,
        unitPrice: order.unitPrice,
        item: order.item,
        stock: order.stock,
        selectFromStock: order.stock === undefined ? false : true,
      };
    }) || [];

  const invoiceDetailsValidation = () => {
    return object({
      customer: string().required("Select a customer"),
      orders: array(
        object({
          quantity: number().min(1, "Quantity should be greater than 1"),
          unitPrice: number().min(1, "Unit price should be greater than 1"),
          item: string().required("Service is required"),
        })
      ),
      vat: boolean().required("Are you charging VAT?"),
      wht: boolean().required("Are you deducting WHT?"),
      currencyCode: string().required("Select Currency"),
      dueDate: date().required("valid date is required"),
      discountPer: number()
        .default(0)
        .min(0, "Min discount should be greater than 0%")
        .max(100, "Max discount should be less than or equal to 100%"),
    });
  };

  const balanceAmount = (values) => {
    return totalAmountCal(values) - values.deposit;
  };

  const totalAmountCal = (values) =>
    totalWithoutDiscount(values) + values.shipping - values.discountAmount;

  const totalWithoutDiscount = (values) => {
    const totalItemPrice = values?.orders?.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.quantity * currentValue.unitPrice,
      0
    );
    let totalAmount = totalItemPrice;
    totalAmount = values.vat ? totalAmount + totalAmount * 0.075 : totalAmount;
    totalAmount = values.wht ? totalAmount - totalAmount * 0.05 : totalAmount;
    // totalAmount = values.wht ? totalAmount + totalAmount * 0.05 : totalAmount;
    return totalAmount;
  };

  const discountAmountCal = (values) =>
    totalWithoutDiscount(values) - values.discountAmount || 0;

  const discountPerCal = (values) =>
    (values.discountAmount / totalWithoutDiscount(values)) * 100 || 0;

  const depositPerCal = (values) =>
    (values.deposit / totalAmountCal(values)) * 100 || 0;

  const depositAmountCal = (values) =>
    (values.depositPer * totalAmountCal(values)) / 100 || 0;

  const onSubmit = async (values) => {
    values.orders.forEach((item) => {
      !item.stock && delete item.stock;
    });

    setLoading(true);
    const payload = {
      customer: values.customer,
      currencyCode: values.currencyCode,
      note: values.note,
      shipping: values.shipping,
      orders: values.orders,
      discountAmount: values.discountAmount,
      vat: values.vat,
      wht: values.wht,
      dueDate: values.dueDate,
    };

    const { status, errorMessage } =
      await QuotationController.createQuotation(payload);

    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      setLoading(false);
      closeModal();
      // goToStage2();
      return;
    }

    setLoading(false);
    MimaToastUtil.error({ message: errorMessage });
  };

  const goToStage2 = () => {
    setStage2(true);
  };
  const onCloseModal = () => {
    invoiceStore.setCopiedInvoice({});
    closeModal();
  };

  //Customer side

  const customerDetailsValidation = () => {
    return object({
      fullname: string().required("Customer name is required"),
      mobile: string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Customer phone number is required"),
    });
  };

  const onSubmitCustomer = async (payload) => {
    setLoading(true);
    for (const key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }
    const { status, errorMessage } =
      await CustomerController.createCustomer(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return returnToInvoicing();
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const goBack = () => {
    if (newCustomer) {
      setNewCustomer(false);
    } else closeModal();
  };
  const invoice = invoiceStore.copiedInvoice;
  return {
    invoiceDetailsValidation,
    loading,
    balanceAmount,
    stage2,
    orders,
    invoice,
    onSubmit,
    invoiceItem,
    discountAmountCal,
    discountPerCal,
    depositPerCal,
    depositAmountCal,
    newCustomer,
    createNewCustomer,
    customerDetailsValidation,
    onSubmitCustomer,
    goBack,
  };
};

export default useNewQuotationLogic;
