import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { inventoryStore, businessStore, invoiceStore } from '../stores';
import BusinessController from './BusinessController';
import { encodeQueryData } from '../utils/utils';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { queryClient } from '../App';

export const GET_ALL_STOCKS = 'get-all-stocks';
export const GET_ALL_PRODUCTS = 'get-all-products';
export const GET_RATE_CARDS = 'get-rate-cards';

const getStockAnalysis = async (query) => {

	const { status, data, errorMessage } = await apiRequest({
		url: `/products/stocks/new-analysis`,
		method: 'get',
	});
	if (status === constant.Success) {
		inventoryStore.setStockAnalysis(data);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

//getProducts to be deprecated soon yeap

const getProducts = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/products/collection/business',
		method: 'get',
	});

	if (status === constant.Success) {
		inventoryStore.setProducts(data.value);
	}
	return { errorMessage, status };
};

const checkProduct = async (productCode) => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/products/${productCode}`,
		method: 'get',
	});
	if (status === constant.Success) {
		inventoryStore.setStocks(data.value);
	}
	return { errorMessage, status, data };
};

const deleteStock = async id => {
	const { status, errorMessage } = await apiRequest({
		url: `/products/deactivate/${id}`,
		method: 'patch',
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'productTypeList'] });
		queryClient.invalidateQueries({ queryKey: [id, 'stockList'] });

		await Promise.all([
			getStockAnalysis(),
			// getProducts(),
		]);
	}
	return { errorMessage, status };
}

const deleteProduct = async id => {
	const { status, errorMessage } = await apiRequest({
		url: `/products/deactivate/product/${id}`,
		method: 'patch',
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'productTypeList'] });
		queryClient.invalidateQueries({ queryKey: [id, 'stockList'] });

		await Promise.all([
			getStockAnalysis(),
			// getProducts(),
		]);
	}
	return { errorMessage, status };
}

const updateProduct = async (payload, id) => {
	const { status, errorMessage } = await apiRequest({
		url: `/products/update-product/${id}`,
		method: 'patch',
		payload,
		hasImageUpload: true,
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'productTypeList'] });
		queryClient.invalidateQueries({ queryKey: [id, 'stockList'] });

		await Promise.all([
			// getProducts(),
			getStockAnalysis(),
		]);

		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const getConfig = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/products/config',
		method: 'get',
	});
	if (status === constant.Success) {
		inventoryStore.setProductConfig(data);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const updateConfig = async (payload) => {
	const { status, errorMessage } = await apiRequest({
		url: '/products/update-config',
		method: 'patch',
		payload
	});
	if (status === constant.Success) {
		await Promise.all([
			BusinessController.businessDetails(),
			getConfig(),
		])
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const updateStocks = async (payload, id) => {
	const { status, errorMessage } = await apiRequest({
		url: `/products/update-stock/${id}`,
		method: 'patch',
		payload,
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'productTypeList'] });
		queryClient.invalidateQueries({ queryKey: [id, 'stockList'] });

		await Promise.all([
			// getProducts(),
			getStockAnalysis(),
		]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const updateStocksQuantity = async (payload) => {
	const stockId = inventoryStore.selectedStock._id;
	const { status, errorMessage } = await apiRequest({
		url: `/products/update-quantity/${stockId}`,
		method: 'patch',
		payload,
	});

	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'productTypeList'] });
		queryClient.invalidateQueries({ queryKey: [id, 'stockList'] });

		await Promise.all([
			// getProducts(),
			getStockAnalysis(),
		]);

		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const createProduct = async (payload) => {
	const { status, errorMessage } = await apiRequest({
		url: '/products',
		method: 'post',
		payload,
		hasImageUpload: true,
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'productTypeList'] });
		queryClient.invalidateQueries({ queryKey: [id, 'stockList'] });

		await Promise.all([
			// getProducts(),
			getStockAnalysis(),
		]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const createStock = async (payload, productId) => {
	const { status, errorMessage } = await apiRequest({
		url: `/products/add-variations/${productId}`,
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'productTypeList'] });
		queryClient.invalidateQueries({ queryKey: [id, 'stockList'] });

		await Promise.all([
			// getProducts(),
			getStockAnalysis(),
		]);
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const getRates = async (currencyCode, amount) => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/products/currency/rates?currencyCode=${currencyCode}&amount=${amount}`,
		method: 'get',
	});

	if (status === constant.Success) {
		const eurAmount = data?.reduce((sum, obj) => {
			if (obj.currencyCode === "EUR") {
				return sum + obj.amount;
			}
			return sum;
		}, 0);

		const gbpAmount = data?.reduce((sum, obj) => {
			if (obj.currencyCode === "GBP") {
				return sum + obj.amount;
			}
			return sum;
		}, 0);

		inventoryStore.setEurPrice(eurAmount);
		inventoryStore.setGbpPrice(gbpAmount);
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};


// const getDropDownStocks = async (query = {}) => {
// 	const encodedQuery = encodeQueryData({
// 		...query
// 	});
// 	const { status, data, errorMessage } = await apiRequest({
// 		url: `/products/stocks/business?${encodedQuery}`,
// 		method: 'get',
// 	});
// 	if (status === constant.Success) {

// 		invoiceStore.setStocks(data.value);
// 	}
// 	return { errorMessage, value: data.value };
// };

// export const useGetStocks = (query) => {
// 	const response = useInfiniteQuery({
// 		queryKey: [businessStore.bId, GET_STOCK, query],
// 		queryFn: ({ pageParam = 1 }) => {
// 			const encodeQuery = encodeQueryData({
// 				...query,
// 				offset: pageParam || 1,
// 			});
// 			return apiRequest({
// 				url: `/products/stocks/business?${encodeQuery}`,
// 				method: 'get',
// 			});
// 		},
// 		getNextPageParam: (lastPage, allPages) => {
// 			const allPagesCount =
// 				allPages.flatMap((page) => page?.data?.value || []).length || 0;
// 			const totalCount = lastPage?.data?.totalCounts || 0;
// 			const limit = query.limit || 50;
// 			if (totalCount > allPagesCount) {
// 				const page = Math.floor(allPagesCount / limit);
// 				return page + 1;
// 			}
// 			return undefined;
// 		},
// 	});

// 	const stocks = useMemo(() => {
// 		if (response?.data?.pages?.length) {
// 			const list = response?.data.pages
// 				.map((page) => page?.data?.value || [])
// 				.flat();
// 			return list || [];
// 		}
// 		return [];
// 	}, [response.data]);

// 	return { ...response, stocks };
// };

const createRateCards = async (payload) => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/products/create-rate-cards',
		method: 'post',
		payload
	});

	if (status === constant.Success) {
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

const updateRateCard = async (payload, id) => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/products/update-rate-card?id=${id}`,
		method: 'patch',
		payload
	});

	if (status === constant.Success) {
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

export const useGetStocks = (query) => {
	const response = useInfiniteQuery({
		queryKey: [businessStore.bId, GET_ALL_STOCKS, query],
		queryFn: ({ pageParam = 1 }) => {
			const encodeQuery = encodeQueryData({
				...query,
				offset: pageParam || 1,
			});
			return apiRequest({
				url: `/products/stocks/business?${encodeQuery}`,
				method: 'get',
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			const allPagesCount =
				allPages.flatMap((page) => page?.data?.value || []).length || 0;
			const totalCount = lastPage?.data?.totalCounts || 0;
			const limit = query.limit || 50;
			if (totalCount > allPagesCount) {
				const page = Math.floor(allPagesCount / limit);
				return page + 1;
			}
			return undefined;
		},
	});

	const stocks = useMemo(() => {
		if (response?.data?.pages?.length) {
			const list = response?.data.pages
				.map((page) => page?.data?.value || [])
				.flat();
			return list || [];
		}
		return [];
	}, [response.data]);

	return { ...response, stocks };
};

const createBulkProducts = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/products/bulk-products',
		method: 'post',
		payload,
		hasImageUpload: true,
	});

	if (status === constant.Success) {
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const useProductTypeList = (productTypeTableQuery, filterQuery, searchQuery) => {
	const id = businessStore.bId

	const query = useMemo(() => {
		if (searchQuery) {
			return `${productTypeTableQuery}&${filterQuery}&search=${searchQuery}`
		} else if (filterQuery) {
			return `${productTypeTableQuery}&${filterQuery}`
		} else {
			return productTypeTableQuery
		}
	}, [filterQuery, productTypeTableQuery, searchQuery])

	return useQuery({
		queryKey: [id, 'productTypeList', query],
		queryFn: () => {
			return apiRequest({
				url: `/products/collection/business?${query}`,
				method: 'get',
			});
		},
		keepPreviousData: true
	})
};

const useGetRateCardList = (tableQuery, filterQuery, searchQuery) => {
	const id = businessStore.bId

	const query = useMemo(() => {
		if (searchQuery) {
			return `${tableQuery}&${filterQuery}&search=${searchQuery}`
		} else if (filterQuery) {
			return `${tableQuery}&${filterQuery}`
		} else {
			return tableQuery
		}
	}, [filterQuery, tableQuery, searchQuery])

	const { data, isLoading, isFetching } = useQuery({
		queryKey: [id, 'rateCardList', query],
		queryFn: () => {
			return apiRequest({
				url: `/products/get-rate-cards?${query}`,
				method: 'get',
			});
		},
		keepPreviousData: true
	})

	const rateCardInfo = { data, isLoading, isFetching }
	return rateCardInfo
};

const useStockList = (stockTableQuery, filterQuery, searchQuery) => {
	const query = useMemo(() => {
		if (searchQuery) {
			return `${stockTableQuery}&${filterQuery}&search=${searchQuery}`
		} else if (filterQuery) {
			return `${stockTableQuery}&${filterQuery}`
		} else {
			return stockTableQuery
		}
	}, [filterQuery, stockTableQuery, searchQuery])

	const response = useQuery({
		queryKey: [businessStore.bId, 'stockList', query],
		queryFn: () => {
			return apiRequest({
				url: `/products/stocks/business?${query}`,
				method: 'get',
			});
		},
		keepPreviousData: true
	})

	return { ...response }
};

export const useGetAllStocks = (query = {}) => {
	const encodeQuery = encodeQueryData({
		...query,
	});

	const response = useQuery({
		queryKey: [businessStore.bId, GET_ALL_STOCKS, encodeQuery],
		queryFn: () => {
			return apiRequest({
				url: `/products/stocks/business?${encodeQuery}`,
				method: 'get',
			});
		},
		keepPreviousData: true
	})

	const stocks = useMemo(() => {
		if (response.data?.status === constant.Success) {
			const { value = [] } = (response.data.data || {});
			return value;
		}

		return [];
	}, [response.data]);

	return { ...response, stocks };
};

export const useGetAllProducts = (query = {}) => {
	const encodeQuery = encodeQueryData({
		...query,
	});

	const response = useQuery({
		queryKey: [businessStore.bId, GET_ALL_PRODUCTS, encodeQuery],
		queryFn: () => {
			return apiRequest({
				url: `/products/collection/business?${encodeQuery}`,
				method: 'get',
			});
		},
		keepPreviousData: true
	})

	const products = useMemo(() => {
		if (response.data?.status === constant.Success) {
			const { value = [] } = (response.data.data || {});
			return value;
		}

		return [];
	}, [response.data]);

	return { ...response, products };
};

const InventoryController = {
	getConfig,
	createProduct,
	createStock,
	deleteProduct,
	updateConfig,
	updateStocks,
	updateProduct,
	deleteStock,
	getProducts,
	getStockAnalysis,
	checkProduct,
	getRates,
	updateStocksQuantity,
	useProductTypeList,
	useStockList,
	createRateCards,
	updateRateCard,
	useGetRateCardList,
	createBulkProducts,
}

export default InventoryController;
