import React from "react";
import styles from "../../assets/styles/Orders/Orders.module.scss";
import { MimaText, Modal } from "../../components";
import { amountFormatter } from "../../utils/utils";
// import twitter from "../../assets/img/twitter.svg";
// import instagram from "../../assets/img/instagram.svg";
// import whatsapp from "../../assets/img/whatsapp.svg";
// import facebook from "../../assets/img/facebook.svg";
// import jiji from "../../assets/img/jiji.svg";
// import jumia from "../../assets/img/jumia.svg";
// import paystack from "../../assets/img/paystack.svg";
// import flutter_wave from "../../assets/img/flucter_wave.svg";
// import konga from "../../assets/img/konga.svg";
// import snapchat from "../../assets/img/snapchat.svg";
// import { BsFillPeopleFill } from "react-icons/bs";
import { ordersStore } from "../../stores";

const ViewOrders = ({ closeModal }) => {
  const data = ordersStore.singleItem;
  const currencyCode = data.invoice.currencyCode;

  return (
    <Modal closeModal={closeModal}>
      <MimaText
        variant="subtitleBold"
        mb={2}
        color="var(--color-fash-primary-3)"
      >
        Order Information
      </MimaText>
      <div className={styles.view_content}>
        <MimaText labelTitle="Customer Name" mb={2}>
          {data.invoice.customer.fullname}
        </MimaText>

        <MimaText labelTitle="Phone Number" mb={2}>
          {data.invoice.customer.mobile}
        </MimaText>

        <MimaText labelTitle="Email Address" mb={2}>
          {data.invoice.customer.email}
        </MimaText>

        <MimaText labelTitle="Customer Address" mb={2}>
          {data?.invoice?.customer?.companyName} <br />
          {data?.invoice?.customer?.street} <br />
          {data?.invoice?.customer?.state} <br />
          {data?.invoice?.customer?.country} <br />
          {data?.invoice?.customer?.postCode}
        </MimaText>

        <MimaText
          labelTitle="Order ID"
          mb={2}
        >{`ORD-${data.invoice.number}`}</MimaText>

        {data.items.map((x, i) => (
          <div key={x._id}>
            <MimaText labelTitle={`Order Item ${i + 1}`} mb={2}>
              Name: {x.item} <br />
              Price: {amountFormatter(currencyCode).format(x.itemPrice)} <br />
              Quantity: {x.quantity}
              <br />
              {x?.color?.title ? `Color: ${x?.color?.title}` : ""}
              <br />
              {x?.standardSize ? `Size: ${x?.standardSize}` : ""}
            </MimaText>
          </div>
        ))}

        <MimaText labelTitle="Payment Status" variant="smallBold" mb={2}>
          {data.invoice.status}
        </MimaText>

        <MimaText labelTitle="Order Status">
          <div
            className={
              data.trackingStatus === "DELIVERED"
                ? styles.delivered
                : data.trackingStatus === "PROCESSING"
                  ? styles.processing
                  : data.trackingStatus === "REFUNDED"
                    ? styles.refunded
                    : data.trackingStatus === "SHIPPED"
                      ? styles.shipped
                      : styles.void
            }
          >
            {data.trackingStatus}
          </div>
        </MimaText>

        {/* <MimaText labelTitle="Sales Channel" variant="body" mb={2}>
          {data.invoice.salesChannel === "twitter" ? (
            <img src={twitter} alt="Twitter" align="right" />
          ) : data.invoice.salesChannel === "Instagram" ? (
            <img src={instagram} alt="Instagram" />
          ) : data.invoice.salesChannel === "WhatsApp" ? (
            <img src={whatsapp} alt="Whatsapp" />
          ) : data.invoice.salesChannel === "Facebook" ? (
            <img src={facebook} alt="Facebook" />
          ) : data.invoice.salesChannel === "Jiji" ? (
            <img src={jiji} alt="Jiji" />
          ) : data.invoice.salesChannel === "Jumia" ? (
            <img src={jumia} alt="Jumia" />
          ) : data.invoice.salesChannel === "Paystack" ? (
            <img src={paystack} alt="Paystack" />
          ) : data.invoice.salesChannel === "Flutterwave" ? (
            <img src={flutter_wave} alt="Flutterwave" />
          ) : data.invoice.salesChannel === "Konga" ? (
            <img src={konga} alt="Konga" />
          ) : data.invoice.salesChannel === "Snapchat" ? (
            <img src={snapchat} alt="Snapchat" />
          ) : data.invoice.salesChannel === "Physical sale" ? (
            <BsFillPeopleFill />
          ) : (
            data.invoice.salesChannel
          )}
        </MimaText> */}

        <hr className={styles.line} />
        <div className={styles.sum}>
          <MimaText variant="body">Sub Total</MimaText>
          <MimaText variant="body">
            {amountFormatter(currencyCode).format(
              data.invoice.itemsTotalAmount
            )}
          </MimaText>
        </div>
        <div className={styles.sum}>
          <MimaText variant="body">Shipping Fee</MimaText>
          <MimaText variant="body">
            {amountFormatter(currencyCode).format(data.invoice.shipping)}
          </MimaText>
        </div>
        <div className={styles.sum}>
          <MimaText variant="body">WHT</MimaText>
          <MimaText variant="body">
            {amountFormatter(currencyCode).format(data.invoice.wht.amount)}
          </MimaText>
        </div>
        <div className={styles.sum}>
          <MimaText variant="body">VAT</MimaText>
          <MimaText variant="body">
            {amountFormatter(currencyCode).format(data.invoice.vat.amount)}
          </MimaText>
        </div>
        <div className={styles.sum}>
          <MimaText variant="body">Discount</MimaText>
          <MimaText variant="body">
            {amountFormatter(currencyCode).format(data.invoice.discountAmount)}
          </MimaText>
        </div>
        <hr className={styles.line} />
        <div className={styles.sum}>
          <MimaText variant="bodyBold">Total</MimaText>
          <MimaText variant="bodyBold">
            {amountFormatter(currencyCode).format(
              data.invoice.transactionAmount
            )}
          </MimaText>
        </div>
      </div>
    </Modal>
  );
};
export default ViewOrders;
