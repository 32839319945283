import React, { useState } from "react";
import { WebsiteController } from "../../../controllers";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";

const useDeleteStyleLogic = ({ closeModal, refetch }) => {
  const [loading, setLoading] = useState(false);

  const onDelete = async (id) => {
    setLoading(true);
    const { status, errorMessage } = await WebsiteController.deleteStyle(id);

    if (status === constant.Success) {
      refetch();
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };
  return { onDelete, loading };
};

export default useDeleteStyleLogic;
