import { MimaButton, MimaText, Modal } from "../../components";
import { customerStore } from "../../stores";
import React from "react";

const DeleteCustomer = ({ closeModal, goBack, onDelete, loading }) => {
  const { selectedCustomer } = customerStore;

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <div style={{ width: "38rem" }}>
        <MimaText variant="subtitleBold" align="center" mb={3}>
          Delete Customer
        </MimaText>

        <MimaText mb={2} align="center">
          Are you sure you want to delete{" "}
          <span
            style={{ fontWeight: "700", color: "var(--color-fash-primary-3)" }}
          >
            {selectedCustomer.fullname}?
          </span>
        </MimaText>
      </div>

      <div className="modal__beside">
        <MimaButton
          title={"Yes"}
          width={18}
          onClick={() => onDelete(selectedCustomer._id)}
          loading={loading}
          variant="regular"
          buttonColor="var(--color-fash-primary)"
          titleColor="var(--color-dark)"
        />

        <MimaButton
          title={"Cancel"}
          width={18}
          onClick={() => closeModal()}
          variant="regular"
        />
      </div>
    </Modal>
  );
};

export default DeleteCustomer;
