import React, { useState } from "react";
import { paymentLinkStore } from "../../../stores";
import { PaymentLinkController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";

const useDeletePaymentLinkLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);

  const selectedLink = paymentLinkStore.selectedPaymentLink;

  const confirmDelete = async () => {
    setLoading(true);
    const id = selectedLink._id;
    const { status, errorMessage } =
      await PaymentLinkController.deletePaymentLink(id);

    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      setLoading(false);
      closeModal();
    } else {
      setLoading(false);
      MimaToastUtil.error({ message: errorMessage });
    }
  };
  return { loading, confirmDelete, selectedLink };
};

export default useDeletePaymentLinkLogic;
