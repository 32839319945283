import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  MimaTextArea,
  Modal,
  ReceiptCheckBox as RCB,
} from "../../components";
import styles from "../../assets/styles/Customer/Customer.module.scss";
import { customerStore } from "../../stores";
import { Formik } from "formik";
import React from "react";

const AddCustomer = ({
  closeModal,
  customerDetailsValidation,
  onSubmitCreateCustomer,
  loading,
  goBack,
}) => {
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <MimaText
        variant="subtitle"
        color="var(--color-fash-primary-3)"
        align="center"
      >
        Add Customer
      </MimaText>
      <Formik
        initialValues={{
          fullname: "",
          mobile: "",
          email: "",
          gender: "",
          address: "",
          description: "",
        }}
        validationSchema={customerDetailsValidation()}
        onSubmit={onSubmitCreateCustomer}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div>
            <MimaInput
              type="text"
              labelTitle="Name"
              placeholder="Enter Customer Name"
              name="fullname"
              mb={2}
              value={values.fullname}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.fullname}
              touched={touched.fullname}
            />

            <MimaInput
              type="text"
              labelTitle="Phone Number"
              placeholder="Customer Phone Number"
              name="mobile"
              mb={2}
              value={values.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.mobile}
              touched={touched.mobile}
            />
            <MimaInput
              type="email"
              labelTitle="Email Address"
              placeholder=" Customer Email"
              name="email"
              mb={2}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <MimaText variant="small" ml={2}>
              Gender/Entity
            </MimaText>
            <div className={styles.checks}>
              <RCB
                title="Male"
                id="MALE"
                name="gender"
                onChange={() => {
                  setFieldValue("gender", "MALE");
                }}
                checked={values.gender === "MALE"}
                value="Male"
              />
              <RCB
                title="Female"
                onChange={() => {
                  setFieldValue("gender", "FEMALE");
                }}
                checked={values.gender === "FEMALE"}
                id="FEMALE"
                name="gender"
                value="Female"
              />
              <RCB
                title="Organization"
                id="BUSINESS"
                onChange={() => {
                  setFieldValue("gender", "BUSINESS");
                }}
                checked={values.gender === "BUSINESS"}
                name="gender"
                value="Business"
              />
            </div>

            <MimaTextArea
              type="textarea"
              labelTitle="Customer Address"
              placeholder="Customer's Address"
              name="address"
              mb={2}
              variant="message"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <MimaTextArea
              type="textarea"
              labelTitle="Note"
              placeholder="Enter Note/Description"
              name="description"
              mb={2}
              variant="message"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <MimaInput
              type="text"
              labelTitle="Secondary Phone Number"
              name="secondaryMobile"
              mb={2}
              value={values.secondaryMobile}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <MimaButton
              title="Save"
              loading={loading}
              variant="regular"
              type="submit"
              onClick={handleSubmit}
              width={38}
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCustomer;
