import { MimaButton, MimaText, Modal } from "../../components";
import { websiteStore } from "../../stores";
import React from "react";
import useDeleteStyleLogic from "./useLogic/useDeleteStyleLogic";

const DeleteStyle = ({ closeModal, refetch }) => {
  const selectedStyleData = websiteStore.selectedStyle;

  const { onDelete, loading } = useDeleteStyleLogic({ closeModal, refetch });

  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      <div style={{ width: "38rem" }}>
        <MimaText variant="subtitleBold" align="center" mb={3}>
          Delete Style
        </MimaText>

        <MimaText mb={2} align="center">
          Are you sure you want to delete Style{" "}
          <span
            style={{ fontWeight: "700", color: "var(--color-fash-primary-3)" }}
          >
            {selectedStyleData?.name}?
          </span>
        </MimaText>
      </div>

      <div className="modal__beside">
        <MimaButton
          title={"Yes"}
          width={18}
          onClick={() => onDelete(selectedStyleData?._id)}
          loading={loading}
          variant="regular"
          buttonColor="var(--color-fash-primary)"
          titleColor="var(--color-dark)"
        />

        <MimaButton
          title={"Cancel"}
          width={18}
          onClick={() => closeModal()}
          variant="regular"
        />
      </div>
    </Modal>
  );
};

export default DeleteStyle;
