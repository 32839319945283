import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
export default class WebsiteStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "WebsiteStore",
      properties: ["collections", "selectedStyle", "webConfig"],
    });
  }

  collections = [];
  selectedStyle = {};
  webConfig = {};

  reset = () => {
    this.collections = [];
    this.selectedStyle = {};
    this.webConfig = {};
  };

  setCollections = async (state) => {
    runInAction(async () => {
      this.collections = state;
    });
  };
  setSelectedStyle = async (state) => {
    runInAction(async () => {
      this.selectedStyle = state;
    });
  };
  setWebConfig = async (state) => {
    runInAction(async () => {
      this.webConfig = state;
    });
  };
}
