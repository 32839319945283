import React from "react";
import { Modal, MimaText, MimaButton, PageLoader } from "../../components";

const DeleteBookingLink = ({
  closeModal,
  selectedLink,
  loading,
  confirmDelete,
}) => {
  return (
    <Modal closeModal={closeModal}>
      {loading ? (
        <PageLoader title="Deactivation in progress" />
      ) : (
        <>
          <MimaText variant="subtitleBold" mb={2} align="center">
            Deactivate Booking Link
          </MimaText>
          <MimaText width={38} align="center" mb={2}>
            Are you sure you want to deactivate{" "}
            <span
              style={{
                fontWeight: "700",
                color: "var(--color-fash-primary-3)",
              }}
            >
              {selectedLink.title}
            </span>
          </MimaText>
          <div
            style={{
              display: "flex",
              width: "38rem",
              justifyContent: "space-between",
            }}
          >
            <MimaButton
              title="Yes, Deactivate"
              width={18}
              onClick={confirmDelete}
              variant="regular"
              buttonColor="var(--color-fash-primary)"
              titleColor="var(--color-dark)"
            />
            <MimaButton
              title="No"
              width={18}
              variant="regular"
              onClick={closeModal}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default DeleteBookingLink;
