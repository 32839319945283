/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  MimaButton,
  MimaDateInput,
  MimaDropdown,
  MimaInput,
  MimaPaginatedDropdown,
  MimaText,
  MimaTextArea,
  ReceiptCheckBox as RCB,
} from "../../components";
import styles from "../../assets/styles/Invoice/Invoice.module.scss";
// import { InvoiceSummaryContainer } from '../../containers';
import React, { useEffect, useRef, useState } from "react";
import { amountFormatter, currencies } from "../../utils/utils";
import { Formik, Field, FieldArray } from "formik";
import { businessStore } from "../../stores";
import { salesChannelData } from "../../utils/utils";
import InvoiceSummary from "./InvoiceSummary";
import useEditInvoiceLogic from "./useLogic/useEditInvoiceLogic";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};

const FieldTextArea = ({ field, ...props }) => {
  return <MimaTextArea {...field} {...props} />;
};

const FieldPaginatedDropdown = ({ field, ...props }) => {
  return <MimaPaginatedDropdown {...field} {...props} />;
};

const EditInvoice = ({ closeModal }) => {
  const {
    invoiceDetailsValidation,
    receiptDetailsValidation,
    loading,
    balanceAmount,
    stage2,
    invoice,
    orders,
    onSubmit,
    invoiceItem,
    discountAmountCal,
    depositAmountCal,
    discountPerCal,
    depositPerCal,
    acceptPartPayValue,
  } = useEditInvoiceLogic({ closeModal });
  const [values, setValues] = useState({});
  const [discountStatus, setDiscountStatus] = useState(false);
  const [depositStatus, setDepositStatus] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const discountPer = discountPerCal(values);
    ref?.current &&
      ref.current.setFieldValue("discountPer", discountPer?.toFixed(2));
  }, [values.discountAmount, values.orders]);

  useEffect(() => {
    if (depositStatus) {
      const deposit = depositAmountCal(values);
      ref?.current && ref.current.setFieldValue("deposit", deposit?.toFixed(2));
    } else {
      const depositPer = depositPerCal(values);
      ref?.current &&
        ref.current.setFieldValue("depositPer", depositPer?.toFixed(2));
    }
  }, [
    values.discountPer,
    values.discountAmount,
    values.deposit,
    values.shipping,
    values.orders,
    values.tax,
    values.wht,
  ]);

  return (
    <>
      {!stage2 && (
        <div>
          <MimaText
            variant="subtitle"
            color="var(--color-primary)"
            align="center"
            mb={4}
          >
            {invoiceItem ? "Edit Invoice" : "Edit Receipt"}
          </MimaText>
          <Formik
            innerRef={ref}
            initialValues={{
              customer: invoice?.customer?._id || "",
              customerName: invoice?.customer?.fullname || "",
              orders,
              selectFromStock: orders[0].stock === undefined ? false : true,
              vat: !!(invoice.vat && invoice.vat.amount),
              wht: !!(invoice.wht && invoice.wht.amount),

              dueDate: invoice.dueDate ? new Date(invoice.dueDate) : "",
              paidDate: invoice.paidDate ? new Date(invoice.paidDate) : "",
              balanceDueDate: invoice.balanceDueDate
                ? new Date(invoice.balanceDueDate)
                : "",
              currencyCode: invoice.currencyCode,
              discountAmount: invoice.discountAmount || 0,
              paymentMethod: invoice.paymentMethod || "",
              note: invoice.note,
              deposit: invoice.deposit,
              discountPer: invoice.discountPer || 0,
              depositPer: 100,
              shipping: invoice.shipping || 0,
              salesChannel: invoice.salesChannel || "",
              acceptPartPay: acceptPartPayValue,
              partPayment: acceptPartPayValue ? invoice.deposit : 0,
              chargeSplit: invoice.chargeSplit || "",
            }}
            validationSchema={
              invoiceItem ? invoiceDetailsValidation : receiptDetailsValidation
            }
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => {
              setValues(values);
              return (
                <div>
                  <MimaPaginatedDropdown
                    mb={2}
                    labelTitle="Bill To"
                    placeholder="Select Customer"
                    name="customer"
                    value={values.customerName}
                    id="customer"
                    optionLabel="fullname"
                    touched={touched.customer}
                    error={errors.customer}
                    url="/customers/business"
                    queryKey={[businessStore.bId, "customers"]}
                    getPageValue={(page) => page?.data?.customers || []}
                    getPageTotalCount={(lastPage) =>
                      lastPage?.data?.totalCounts || 0
                    }
                    onChange={(data) => {
                      setFieldValue("customer", data._id);
                      setFieldValue("customerName", data.fullname);
                    }}
                  />

                  <MimaDropdown
                    labelTitle="Select Currency"
                    name="currencyCode"
                    value={values.currencyCode}
                    placeholder={values.currencyCode}
                    id="currencyCode"
                    currentValue={values.currencyCode}
                    onChange={(data) => {
                      setFieldValue("currencyCode", data.key);
                    }}
                    data={currencies}
                    touched={touched.currencyCode}
                    error={errors.currencyCode}
                  />
                  <MimaDropdown
                    labelTitle="Select a sales channel"
                    placeholder={values.salesChannel}
                    name="salesChannel"
                    value={values.salesChannel}
                    id="salesChannel"
                    onChange={(data) => {
                      setFieldValue("salesChannel", data.key);
                    }}
                    data={salesChannelData}
                    touched={touched.salesChannel}
                    error={errors.salesChannel}
                    mb={3}
                  />

                  <RCB
                    title="Select Product from Stock"
                    onChange={(e) => {
                      setFieldValue("selectFromStock", e.target.checked);
                      setFieldValue("orders", [
                        {
                          quantity: 1,
                          unitPrice: 0,
                          item: "",
                          stock: "",
                          selectFromStock: e.target.checked,
                        },
                      ]);
                    }}
                    checked={values.selectFromStock}
                    value={String(values.selectFromStock)}
                    name="selectFromStock"
                  />
                  <FieldArray name="orders">
                    {({ push, remove }) => (
                      <React.Fragment>
                        {values.orders.map((_, i) => (
                          <div key={i}>
                            {!values.selectFromStock && (
                              <Field
                                name={`orders.${i}.item`}
                                id={`orders.${i}.item`}
                                component={FieldTextArea}
                                type="text"
                                placeholder="Product/Service"
                                labelTitle="Product/Service"
                                mt={1}
                                touched={touched?.orders?.[i]?.item}
                                error={errors?.orders?.[i]?.item}
                              />
                            )}

                            {values.selectFromStock ? (
                              <Field
                                type="text"
                                mt={2}
                                name={`orders.${i}.item`}
                                id={`orders.${i}.item`}
                                value={values.orders[i].item}
                                queryKey={[businessStore.bId, "stock"]}
                                optionLabel="name"
                                component={FieldPaginatedDropdown}
                                labelTitle="Product/Service"
                                placeholder={
                                  values.orders[i].item || "Select from Stock"
                                }
                                touched={touched?.orders?.[i]?.item}
                                error={errors?.orders?.[i]?.item}
                                url="/products/stocks/business"
                                getPageValue={(page) => page?.data?.value || []}
                                getPageTotalCount={(lastPage) =>
                                  lastPage?.data?.totalCounts || 0
                                }
                                onChange={(data) => {
                                  setFieldValue(`orders.${i}.stock`, data._id);
                                  setFieldValue(`orders.${i}.item`, data.name);
                                  setFieldValue(
                                    `orders.${i}.unitPrice`,
                                    data.sellingPrice
                                  );
                                  setFieldValue(
                                    `orders.${i}.availableStockQuantity`,
                                    data.quantity
                                  );
                                }}
                              />
                            ) : null}

                            <div className={styles.invoice__beside}>
                              <Field
                                name={`orders.${i}.unitPrice`}
                                component={FieldInput}
                                type="number"
                                min="0"
                                labelTitle="Price"
                                width={18}
                                // disabled={values.selectFromStock}
                                touched={touched?.orders?.[i]?.unitPrice}
                                error={errors?.orders?.[i]?.unitPrice}
                              />
                              <Field
                                name={`orders.${i}.quantity`}
                                component={FieldInput}
                                type="number"
                                min={1}
                                labelTitle="Quantity"
                                width={18}
                                touched={touched?.orders?.[i]?.quantity}
                                error={errors?.orders?.[i]?.quantity}
                              />
                            </div>
                            {i !== 0 ? (
                              <MimaButton
                                title="Remove"
                                variant="filter"
                                type="button"
                                buttonColor="var(--color-error)"
                                width={10}
                                onClick={() => remove(i)}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                        {values.orders.length > 0 && (
                          <MimaButton
                            title="Add Another Item"
                            variant="text"
                            type="button"
                            onClick={() =>
                              push({
                                quantity: 1,
                                unitPrice: 0,
                                item: "",
                              })
                            }
                          />
                        )}
                      </React.Fragment>
                    )}
                  </FieldArray>

                  {invoiceItem && (
                    <>
                      <MimaInput
                        type="number"
                        labelTitle="Shipping Fee"
                        placeholder="0.00"
                        name="shipping"
                        mb={1}
                        min={0}
                        value={values.shipping}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className={styles.invoice__beside}>
                        <MimaInput
                          type="number"
                          labelTitle="Discount Amount"
                          placeholder="0"
                          name="discountAmount"
                          mb={1}
                          min={0}
                          value={values.discountAmount}
                          onChange={(e) => {
                            setDiscountStatus(false);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.discountAmount}
                          error={errors.discountAmount}
                        />
                        <MimaInput
                          type="number"
                          labelTitle="Discount %"
                          placeholder="0%"
                          name="discountPer"
                          mb={1}
                          min={0}
                          max={100}
                          value={values.discountPer}
                          disabled={true}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.discountPer}
                          error={errors.discountPer}
                        />
                      </div>
                      {/* <div className={styles.invoice__beside}>
												<MimaInput
													type="number"
													labelTitle="Deposit %"
													placeholder="0%"
													name="depositPer"
													mb={1}
													min={0}
													max={100}
													value={values.depositPer}
													onChange={e => {
														setDepositStatus(true);
														handleChange(e);
													}}
													onBlur={handleBlur}
													width={18}
													touched={touched.depositPer}
													error={errors.depositPer}
												/>
												<MimaInput
													type="number"
													labelTitle="Deposit Amount"
													placeholder="0"
													name="deposit"
													mb={1}
													min={0}
													max={100}
													value={values.deposit}
													onChange={e => {
														setDepositStatus(false);
														handleChange(e);
													}}
													onBlur={handleBlur}
													width={18}
													touched={touched.deposit}
													error={errors.deposit}
												/>
											</div> */}

                      {/* <MimaButton
												title={`To Balance: ${amountFormatter().format(
													balanceAmount(values)
												)}`}
												variant="text"
												type="button"
												width={38}
											/> */}

                      <div className={styles.invoice__checks_group}>
                        <MimaText variant="small">
                          Do you want to charge VAT?
                        </MimaText>
                        <div className={styles.invoice__checks}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("vat", true);
                            }}
                            checked={values.vat}
                            value="true"
                            name="vat"
                          />
                          <RCB
                            title="No"
                            checked={!values.vat}
                            onChange={() => setFieldValue("vat", false)}
                            value="false"
                            name="vat"
                          />
                        </div>
                        {errors.vat ? (
                          <div className={styles.error}>
                            {""}
                            {touched.vat && errors.vat}
                            {""}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.invoice__checks_group}>
                        <MimaText variant="small">
                          Do you want to deduct WHT?
                        </MimaText>
                        <div className={styles.invoice__checks}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("wht", true);
                            }}
                            checked={values.wht}
                            value="true"
                            name="wht"
                          />
                          <RCB
                            title="No"
                            checked={!values.wht}
                            onChange={() => setFieldValue("wht", false)}
                            value="false"
                            name="wht"
                          />
                        </div>
                        {errors.wht ? (
                          <div className={styles.error}>
                            {""}
                            {touched.wht && errors.wht}
                            {""}
                          </div>
                        ) : null}
                      </div>

                      <div className={styles.invoice__checks_group}>
                        <MimaText variant="small">
                          Do you want to accept part payment?
                        </MimaText>
                        <div className={styles.invoice__checks}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("acceptPartPay", true);
                            }}
                            checked={values.acceptPartPay}
                            value="true"
                            name="acceptPartPay"
                          />
                          <RCB
                            title="No"
                            checked={!values.acceptPartPay}
                            onChange={() =>
                              setFieldValue("acceptPartPay", false)
                            }
                            value="false"
                            name="acceptPartPay"
                            mb={1}
                          />
                        </div>
                      </div>

                      <div className={styles.invoice__beside}>
                        {values.acceptPartPay ? (
                          <MimaInput
                            type="number"
                            labelTitle="Part Payment"
                            placeholder="0.00"
                            name="partPayment"
                            mb={1}
                            min={0}
                            value={values.partPayment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched.partPayment}
                            error={errors.partPayment}
                            width={18}
                          />
                        ) : (
                          ""
                        )}
                        <MimaDateInput
                          labelTitle="Due date"
                          name="dueDate"
                          value={values.dueDate}
                          onChange={(text) => {
                            setFieldValue("dueDate", text);
                          }}
                          onBlur={handleBlur}
                          width={values.acceptPartPay ? 18 : 38}
                          touched={touched.dueDate}
                          error={errors.dueDate}
                        />
                        {/* {balanceAmount(values) > 0 ? (
													<MimaDateInput
														labelTitle="Balance due date"
														name="balanceDueDate"
														value={values.balanceDueDate}
														onChange={text => {
															setFieldValue('balanceDueDate', text);
														}}
														onBlur={handleBlur}
														width={18}
														touched={touched.balanceDueDate}
														error={errors.balanceDueDate}
													/>
												) : (
													''
												)} */}
                      </div>
                      {values.acceptPartPay ? (
                        <div className={styles.invoice__beside}>
                          <MimaText
                            labelTitle="Expected Balance"
                            width={18}
                            variant="bodyBold"
                          >
                            {amountFormatter(values.currencyCode).format(
                              balanceAmount(values)
                            )}
                          </MimaText>
                          {balanceAmount(values) > 0 ? (
                            <MimaDateInput
                              labelTitle="Balance due date"
                              name="balanceDueDate"
                              value={values.balanceDueDate}
                              onChange={(text) => {
                                setFieldValue("balanceDueDate", text);
                              }}
                              onBlur={handleBlur}
                              width={18}
                              touched={touched.balanceDueDate}
                              error={errors.balanceDueDate}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {values.currencyCode !== "NGN" && (
                        <MimaDropdown
                          labelTitle="Who bears the payment charges?"
                          name="chargeSplit"
                          value={values.chargeSplit}
                          placeholder={values.chargeSplit}
                          id="chargeSplit"
                          currentValue={values.chargeSplit}
                          onChange={(data) => {
                            setFieldValue("chargeSplit", data.key);
                          }}
                          data={[
                            {
                              value: "Customer",
                              key: "CUSTOMER",
                            },
                            {
                              value: "My Business",
                              key: "MY BUSINESS",
                            },
                            {
                              value: "EQUAL SPLIT",
                              key: "EQUAL SPLIT",
                            },
                          ]}
                          touched={touched.chargeSplit}
                          error={errors.chargeSplit}
                        />
                      )}
                    </>
                  )}

                  {/* Samuel check from here for receipt items. */}

                  {!invoiceItem && (
                    <>
                      <MimaInput
                        type="number"
                        labelTitle="Shipping Fee"
                        placeholder="0.00"
                        name="shipping"
                        min={0}
                        mb={1}
                        value={values.shipping}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <div className={styles.invoice__beside}>
                        <MimaInput
                          type="number"
                          labelTitle="Discount %"
                          placeholder="0%"
                          name="discountPer"
                          mb={1}
                          min={0}
                          max={100}
                          value={values.discountPer}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.discountPer}
                          error={errors.discountPer}
                        />
                        <MimaInput
                          type="number"
                          labelTitle="Discount Amount"
                          placeholder="0%"
                          name="discountAmount"
                          mb={1}
                          min={0}
                          value={values.discountAmount}
                          onChange={(e) => {
                            setDiscountStatus(false);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.discountAmount}
                          error={errors.discountAmount}
                        />

                        {/* <MimaButton
													title={`Discount: ${amountFormatter().format(
														discountAmountCal(values)
													)}`}
													variant="text"
													type="button"
													width={18}
												/> */}
                      </div>
                      <div className={styles.invoice__checks_group}>
                        <MimaText variant="small">Did you charge VAT?</MimaText>
                        <div className={styles.invoice__checks}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("vat", true);
                            }}
                            checked={values.vat}
                            value="true"
                            name="vat"
                          />
                          <RCB
                            title="No"
                            checked={!values.vat}
                            onChange={() => setFieldValue("vat", false)}
                            value="false"
                            name="vat"
                            mb={1}
                          />
                        </div>
                        {errors.vat ? (
                          <div className={styles.error}>
                            {""}
                            {touched.vat && errors.vat}
                            {""}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.invoice__checks_group}>
                        <MimaText variant="small">
                          Did you want to deduct WHT?
                        </MimaText>
                        <div className={styles.invoice__checks}>
                          <RCB
                            title="Yes"
                            onChange={() => {
                              setFieldValue("wht", true);
                            }}
                            checked={values.wht}
                            value="true"
                            name="wht"
                          />
                          <RCB
                            title="No"
                            checked={!values.wht}
                            onChange={() => setFieldValue("wht", false)}
                            value="false"
                            name="wht"
                            mb={1}
                          />
                        </div>
                        {errors.wht ? (
                          <div className={styles.error}>
                            {""}
                            {touched.wht && errors.wht}
                            {""}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.invoice__beside}>
                        <MimaInput
                          type="number"
                          labelTitle="Amount Received?"
                          placeholder="0.00"
                          name="deposit"
                          min={0}
                          mb={2}
                          value={values.deposit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          width={18}
                          touched={touched.deposit}
                          error={errors.deposit}
                        />
                        <MimaDateInput
                          labelTitle="Paid date"
                          name="paidDate"
                          value={values.paidDate}
                          onChange={(text) => {
                            setFieldValue("paidDate", text);
                          }}
                          width={18}
                          onBlur={handleBlur}
                          touched={touched.paidDate}
                          error={errors.paidDate}
                        />
                      </div>
                      <div className={styles.invoice__beside}>
                        {balanceAmount(values) > 0 ? (
                          <MimaDateInput
                            labelTitle="Balance due date"
                            name="balanceDueDate"
                            value={values.balanceDueDate}
                            onChange={(text) => {
                              setFieldValue("balanceDueDate", text);
                            }}
                            onBlur={handleBlur}
                            width={18}
                            touched={touched.balanceDueDate}
                            error={errors.balanceDueDate}
                          />
                        ) : (
                          ""
                        )}
                        <MimaButton
                          title={`Balance: ${amountFormatter(
                            values.currencyCode
                          ).format(balanceAmount(values))}`}
                          variant="text"
                          type="button"
                          width={balanceAmount(values) > 0 ? 18 : 38}
                        />
                      </div>
                      <div className={styles.invoice__checks_group}>
                        <MimaText variant="small" ml={2}>
                          Payment Method
                        </MimaText>
                        <div className={styles.invoice__checks}>
                          <RCB
                            title="Cash"
                            id="CASH"
                            name="paymentMethod"
                            onChange={() => {
                              setFieldValue("paymentMethod", "CASH");
                            }}
                            checked={values.paymentMethod === "CASH"}
                            value="cash"
                          />
                          <RCB
                            title="Transfer"
                            onChange={() => {
                              setFieldValue("paymentMethod", "TRANSFER");
                            }}
                            checked={values.paymentMethod === "TRANSFER"}
                            id="transfer"
                            name="paymentMethod"
                            value="transfer"
                          />
                          <RCB
                            title="P.O.S"
                            id="pos"
                            onChange={() => {
                              setFieldValue("paymentMethod", "POS");
                            }}
                            checked={values.paymentMethod === "POS"}
                            name="paymentMethod"
                            value="pos"
                          />
                          <RCB
                            title="Cheque"
                            id="cheque"
                            onChange={() => {
                              setFieldValue("paymentMethod", "CHEQUE");
                            }}
                            checked={values.paymentMethod === "CHEQUE"}
                            name="paymentMethod"
                            value="cheque"
                          />
                        </div>
                        {errors.paymentMethod ? (
                          <div className={styles.error}>
                            {touched.paymentMethod && errors.paymentMethod}
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                  <MimaTextArea
                    type="text"
                    labelTitle="Note or Terms and Conditions"
                    name="note"
                    variant="wide"
                    value={values.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    height={10}
                  />

                  <MimaButton
                    title="Continue"
                    onClick={handleSubmit}
                    type="submit"
                    loading={loading}
                    variant="form"
                    mt={2}
                  />
                </div>
              );
            }}
          </Formik>
        </div>
      )}
      {stage2 && <InvoiceSummary closeModal={closeModal} />}
    </>
  );
};

export default EditInvoice;
