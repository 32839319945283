import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
export default class BusinessStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "BusinessStore",
      properties: [
        "business",
        "OnboardingStageOne",
        "bId",
        "businesses",
        "subscriptionPlans",
        "expiredSubscription",
        "businessMobile",
        "defaultCurrency",
        "localCurrency",
        "settings",
        "subdomain",
      ],
    });
  }

  business = {};
  businesses = [];
  bId = "";
  OnboardingStageOne = {};
  subscriptionPlans = [];
  expiredSubscription = false;
  businessMobile = "";
  defaultCurrency = "";
  localCurrency = "";
  settings = {};
  subdomain = "";

  setBusinessOnboardingStageOne = async (state) => {
    runInAction(async () => {
      this.OnboardingStageOne = state;
    });
  };

  setBusiness = async (business) => {
    runInAction(async () => {
      this.business = business;
    });
  };
  setBusinesses = async (businesses) => {
    runInAction(async () => {
      this.businesses = businesses;
    });
  };
  setBId = async (business) => {
    runInAction(async () => {
      this.bId = business;
    });
  };
  setSubscriptionPlans = async (state) => {
    runInAction(async () => {
      this.subscriptionPlans = state;
    });
  };

  setExpiredSubscription = async (state) => {
    runInAction(async () => {
      this.expiredSubscription = state;
    });
  };

  setBusinessMobile = async (state) => {
    runInAction(async () => {
      this.businessMobile = state;
    });
  };

  setDefaultCurrency = async (state) => {
    runInAction(async () => {
      this.defaultCurrency = state;
    });
  };
  setLocalCurrency = async (state) => {
    runInAction(async () => {
      this.localCurrency = state;
    });
  };
  setSettings = async (state) => {
    runInAction(async () => {
      this.settings = state;
    });
  };
  setSubdomain = async (state) => {
    runInAction(async () => {
      this.subdomain = state;
    });
  };

  reset = () => {
    this.business = {};
    this.OnboardingStageOne = {};
    this.bId = "";
    this.businesses = [];
    this.subscriptionPlans = [];
    this.expiredSubscription = false;
    this.businessMobile = "";
    this.defaultCurrency = "";
    this.localCurrency = "";
    this.settings = {};
    this.subdomain = "";
  };
}
