import React, { useEffect, useState } from "react";
import { ordersStore, useStores } from "../../../stores";
import { useNavigate } from "react-router-dom";
import constant from "../../../utils/constant";
import {
  BusinessController,
  InventoryController,
  OrderController,
  SettingsController,
  TransactionsController,
} from "../../../controllers";
import { theYear } from "../../../utils/utils";
import moment from "moment";
import { object, string } from "yup";
import { getDate } from "../../../utils/utils";
import { filterPeriods } from "../../../utils/utils";

const useAppHomeLogic = () => {
  const [addBusinessModal, setAddBusiness] = useState(false);
  const [allBusinesses, setAllBusinesses] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [withdrawalAccountModal, setWithdrawalAccountModal] = useState(false);
  const [cardInfoModal, setCardInfoModal] = useState(false);
  const [numVerificationModal, setNumVerificationModal] = useState(false);
  const [updateBusinessModal, setUpdateBusinessModal] = useState(false);
  const { userStore, businessStore, transactionStore } = useStores();
  const business = businessStore.business;
  const orderSummary = ordersStore.orderSummary;

  const hasCreatedWebsite = business?.todo?.createdWebsite;
  const hasSetBrandInfo = business?.todo?.setBrandInfo;
  const hasSetTransactionPin = business?.todo?.setTransactionPin;
  const hasSetWithdrawalAccount = business?.todo?.setWithdrawalAccount;
  const hasAddedStyles = business?.todo?.addedStyles > 0;
  const hasAddedMeasurements = business?.todo?.addedMeasurements > 0;

  const isOnboarded = userStore.isOnBoardingDone !== "false";

  let [a, b, c, d, e, f] = [0, 0, 0, 0, 0, 0];

  const subPercent = 16.7;

  hasCreatedWebsite ? (a = subPercent) : (a = 0);
  hasSetBrandInfo ? (b = subPercent) : (b = 0);
  hasSetTransactionPin ? (c = subPercent) : (c = 0);
  hasSetWithdrawalAccount ? (d = subPercent) : (d = 0);
  hasAddedStyles ? (e = subPercent) : (e = 0);
  hasAddedMeasurements ? (f = subPercent) : (f = 0);

  let toDoListTotal = a + b + c + d + e + f;

  const navigate = useNavigate();

  const goToOnboarding = () => {
    !isOnboarded && navigate(constant.Routes.OnboardingStageOne);
  };

  const goToSettings = () => {
    navigate(constant.Routes.Settings);
  };
  const goToCreateWebsite = () => {
    navigate(constant.Routes.CreateWebsite);
  };
  const goToMeasurement = () => {
    navigate(constant.Routes.Measurement);
  };

  const goToUploadStyle = () => {
    navigate(constant.Routes.Styles);
  };

  const goToAddMeasurement = () => {
    navigate(constant.Routes.Measurement);
  };

  useEffect(() => {
    const setTransactions = async () =>
      await Promise.all([
        SettingsController.dashboardData(),
        InventoryController.getConfig(),
        TransactionsController.transactions(),
        OrderController.getOrderSummary(),
        // InvoiceController.getAnalysis(),
      ]);
    setTransactions();
  }, []);

  // const [filterPeriod, setFilterPeriod] = useState(`${monthName} ${theYear}`);
  const [filterPeriod, setFilterPeriod] = useState(`${theYear}`);
  const [loading, setLoading] = useState(false);

  const validationSchema = () => {
    return object({
      startDate: string().required("Start Date is required"),
      endDate: string().required("End Date is required"),
    });
  };

  const showBusinesses = () => {
    if (businessStore.businesses.length === 1) {
      setAddBusiness(true);
    } else {
      setAllBusinesses(!allBusinesses);
    }
  };

  const onSubmit = async (payload) => {
    let query;

    if (payload && Object.keys(payload).length > 0) {
      query = `startDate=${payload.startDate}&endDate=${payload.endDate}&dateField=createdAt`;
      if (payload.period === "custom") {
        setFilterPeriod(
          `${moment(payload?.startDate).format("DD-MM-YYYY")} - ${moment(payload.endDate).format("DD-MM-YYYY")}`
        );
      } else {
        setFilterPeriod(`${payload?.periodText}`);
      }
      // setFilterPeriod(
      // 	`${payload?.startDate.toDateString()} - ${payload.endDate.toDateString()}`
      // );
    }
    setLoading(true);
    await Promise.all([
      TransactionsController.transactionSummary(query),
      TransactionsController.expenseAnalysis(query),
      TransactionsController.transactions(query),
    ]);
    setLoading(false);
  };
  const refresh = async () => {
    setLoading(true);
    await Promise.all([
      TransactionsController.transactionSummary(),
      TransactionsController.expenseAnalysis(),
      TransactionsController.transactions(),
    ]);
    setLoading(false);
  };
  const expensePercentage = (value, currencyCode) =>
    (
      (100 * value) /
      transactionStore.expenseAnalysis
        .filter((data) => data.currencyCode === currencyCode)[0]
        .value.reduce((partialSum, a) => partialSum + a.value, 0)
    ).toFixed(2);

  const logout = () => {
    userStore.setLoggedIn(false);
  };

  const resetFilter = async () => {
    await onSubmit();
    setFilterPeriod(`${theYear}`);
  };

  const viewReport = () => {
    setReportModal(true);
  };

  const goToSetPin = () => {
    setPinModal(true);
  };
  const goToUpdateBusinessInfo = () => {
    setUpdateBusinessModal(true);
  };

  const goToWithdrawalAccount = () => {
    setWithdrawalAccountModal(true);
  };

  const goToCardInfo = () => {
    setCardInfoModal(true);
  };
  const goToNumVerification = () => {
    setNumVerificationModal(true);
  };

  const closeModal = () => {
    setAddBusiness(false);
    setReportModal(false);
    setPinModal(false);
    setCardInfoModal(false);
    setWithdrawalAccountModal(false);
    setNumVerificationModal(false);
    setUpdateBusinessModal(false);
  };

  const tradingName = businessStore.business?.tradingName ?? "";

  const expenseAnalysis = transactionStore.expenseAnalysis;

  const transactionsSummary = transactionStore.transactionsSummary;

  return {
    tradingName,
    expenseAnalysis,
    transactionsSummary,
    expensePercentage,
    loading,
    onSubmit,
    validationSchema,
    filterPeriod,
    resetFilter,
    toDoListTotal,
    // isActivated,
    // hasPin,
    refresh,
    isOnboarded,
    // hasWithdrawal,
    goToOnboarding,
    goToSettings,
    showBusinesses,
    addBusinessModal,
    allBusinesses,
    closeModal,
    setAddBusiness,
    reportModal,
    viewReport,
    // hasAddedCard,
    goToSetPin,
    goToWithdrawalAccount,
    goToCardInfo,
    pinModal,
    withdrawalAccountModal,
    cardInfoModal,
    goToNumVerification,
    numVerificationModal,
    // hasVerifiedNumber,
    filterPeriods,
    getDate,
    hasCreatedWebsite,
    hasSetBrandInfo,
    hasSetTransactionPin,
    hasSetWithdrawalAccount,
    hasAddedStyles,
    hasAddedMeasurements,
    goToCreateWebsite,
    goToUploadStyle,
    goToAddMeasurement,
    updateBusinessModal,
    goToUpdateBusinessInfo,
    orderSummary,
    goToMeasurement,
  };
};

export default useAppHomeLogic;
