import React from "react";
import { MimaButton, MimaInput } from "../../../components";
import { Formik } from "formik";
import useForgotPasswordResetLogic from "./useForgotPasswordResetLogic";
import AuthOnboardingShell from "../AuthOnboardingShell";
import styles from "../../../assets/styles/Auth/AuthOnboarding.module.scss";

const ForgotPasswordReset = () => {
  const { loading, validationSchema, onSubmit } = useForgotPasswordResetLogic();

  return (
    <AuthOnboardingShell>
      <Formik
        initialValues={{
          password: "",
          newPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className={styles.form}>
            <MimaInput
              labelTitle="New Password"
              type="text"
              placeholder="Enter your new password"
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              passwordIcon
              error={errors.password}
              touched={touched.password}
              variant="form"
              mb={2}
            />
            <MimaInput
              labelTitle="Confirm Password"
              type="text"
              placeholder="Confirm your new password"
              id="newPassword"
              name="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              passwordIcon
              error={errors.newPassword}
              touched={touched.newPassword}
              variant="form"
              mb={2}
            />

            <MimaButton
              title="Continue"
              onClick={handleSubmit}
              type="submit"
              loading={loading}
              variant="form"
            />
          </div>
        )}
      </Formik>
    </AuthOnboardingShell>
  );
};

export default ForgotPasswordReset;
