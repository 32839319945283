import styles from "../../assets/styles/MimaInput.module.scss";

import React, { useMemo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import * as inputStyles from "./styles";
import IconButton from "../IconButton";
import { BsInfoCircleFill } from "react-icons/bs";

const propTypes = {
  labelTitle: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "password",
    "radio",
    "date",
    "textarea",
    "tel",
    "time",
  ]),
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    "medium",
    "wide",
    "form",
    "mobile",
    "message",
    "exchange",
    "form2",
    "error",
    "formError",
  ]),
  width: PropTypes.any,
  icon: PropTypes.element,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  rows: PropTypes.number,
  cols: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.any,
  passwordIcon: PropTypes.bool,
  fontSize: PropTypes.number,
  suggestionData: PropTypes.array,
  handleFilter: PropTypes.func,
  note: PropTypes.string,
};

const MimaInput = ({
  labelTitle,
  placeholder,
  onBlur,
  onChange,
  value,
  disabled = false,
  type = "text",
  variant = "medium",
  width,
  height,
  id,
  max,
  min,
  name,
  mt,
  mr,
  mb,
  ml,
  rows,
  cols,
  touched,
  error,
  passwordIcon,
  fontSize,
  note,
  ...props
}) => {
  const [hide, setHide] = useState(true);

  let eye;
  !hide ? (eye = "eyeOpen") : (eye = "eyeClose");

  const showpassword = () => {
    setHide(!hide);
  };

  const errorVariant = useMemo(() => {
    if (variant === "form") {
      return "formError";
    }
    return "error";
  }, [variant]);
  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
      <label className={styles.form__label}>{labelTitle}</label>
      <WithIconSkeleton>
        <InputSkeleton
          type={passwordIcon && hide ? "password" : type}
          max={max}
          min={min}
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={disabled}
          onChange={onChange}
          variant={error && touched ? errorVariant : variant}
          width={width}
          onWheel={(event) => event.currentTarget.blur()}
          value={value}
          id={id}
          name={name}
          height={height}
          rows={rows}
          cols={cols}
          passwordIcon={passwordIcon}
          error={error}
          fontSize={fontSize}
        />

        {passwordIcon && <IconButton variant={eye} onClick={showpassword} />}
      </WithIconSkeleton>

      {error ? <div className={styles.error}>{touched && error}</div> : null}
      {note ? (
        <div className={styles.note}>
          <BsInfoCircleFill style={{ fontSize: "2rem", marginRight: "1rem" }} />{" "}
          {note}
        </div>
      ) : null}
    </DivSkeleton>
  );
};

const InputSkeleton = styled.input`
  ${inputStyles.base};
  ${(props) => inputStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(touched) => (touched ? `color: var(--color-dark)` : "")}
`;

const DivSkeleton = styled.div`
  ${inputStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const WithIconSkeleton = styled.div`
  ${inputStyles.pwIcon};
`;

MimaInput.propTypes = propTypes;

export default MimaInput;
