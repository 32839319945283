// import {
// 	BusinessInfoContainer,
// 	SecurityContainer,
// 	AccountManagerContainer,
// 	InviteFriendsContainer,
// 	LogoutContainer,
// 	ContactSupportContainer,
// 	WithdrawalAccountContainer,
// 	ManagePayrollContainer,
// 	InventoryConfigContainer,
// 	ProductSalesConfigContainer,
// 	KycContainer,
// 	AddBusinessContainer,
// 	FaqsContainer,
// 	GeneralSettingsContainer,
// 	CardInfoContainer,
// 	InvoiceReminderContainer,
// 	SubscriptionsContainer,
// 	ApiKeysContainer,
// } from '../../containers';
import {
  MdSecurity,
  MdSwitchAccount,
  MdAdminPanelSettings,
} from "react-icons/md";
import styles from "../../assets/styles/Settings/Settings.module.scss";
import { MimaText, AppPage, PageLoader, AppShell } from "../../components";
import { businessStore, userStore } from "../../stores";
import { RiLogoutCircleFill } from "react-icons/ri";
import { IoIosArrowForward, IoIosCash } from "react-icons/io";
import { BiPlus, BiSupport } from "react-icons/bi";
import { AiFillContainer } from "react-icons/ai";
import { AnimatePresence } from "framer-motion";
import { FaUserFriends, FaQuestionCircle } from "react-icons/fa";
import { IoBusiness, IoSettings } from "react-icons/io5";
import { GiMoneyStack } from "react-icons/gi";
import React, { useState } from "react";
import { GrDocumentConfig } from "react-icons/gr";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { AiOutlineBell } from "react-icons/ai";
import { TbKeyframeAlignCenterFilled } from "react-icons/tb";
import useSettingsLogic from "./useLogic/useSettingsLogic";
import BusinessInfo from "./BusinessInfo";
import Security from "./Security";
import Kyc from "./Kyc";
import WithdrawalAccount from "./WithdrawalAccount";
import AccountManager from "./AccountManager";
import GeneralSettings from "./GeneralSettings";
import InvoiceReminder from "./InvoiceReminder";
import ContactSupport from "./ContactSupport";
import Logout from "./Logout";
import InviteFriends from "./InviteFriends";
import Subscriptions from "./Subscriptions";
import {
  BsPersonFill,
  BsPersonBadgeFill,
  BsFillCreditCard2BackFill,
  BsCreditCard2FrontFill,
} from "react-icons/bs";
import CardInfo from "./CardInfo";

const Settings = () => {
  const {
    goToInvoiceReminderModal,
    invoiceReminderModal,
    generalSettingsModal,
    goToGeneralSettings,
    goToFaq,
    faqModal,
    businessInfo,
    goToBusinessInfo,
    goToAddBusiness,
    addBusinessModal,
    securityModal,
    withdrawalAccountModal,
    accountManagerModal,
    inviteFriendsModal,
    supportModal,
    logoutModal,
    loading,
    goToSecurity,
    goToWithdrawalAccount,
    goToAccountManager,
    goToBusinessAdmin,
    goToInvite,
    goToSupport,
    goTologout,
    goToProductSalesConfig,
    closeModal,
    managePayroll,
    productSalesConfigModal,
    goToManagePayroll,
    inventoryConfigModal,
    goToInventoryConfig,
    goToKyc,
    kycModal,
    tier,
    refresh,
    cardInfoModal,
    goToCardInfo,
    goToSubscriptions,
    subscriptionsModal,
    goToApiKeys,
  } = useSettingsLogic();
  const navData = [
    {
      title: "Subscriptions",
      icon: <BsCreditCard2FrontFill />,
      link: goToSubscriptions,
    },
    {
      title: "Business Information",
      module: "BUSINESS DETAILS",
      icon: <BsPersonFill />,
      link: goToBusinessInfo,
    },
    {
      title: "Pin and Security",
      icon: <MdSecurity />,
      link: goToSecurity,
    },
    {
      title: "Business Account Settings",
      module: "BUSINESS DETAILS",
      icon: <IoBusiness />,
      // link: goToBusinessInfo,
      subNavData: [
        {
          title: "KYC Verification",
          type: "GENERAL",
          icon: <BsPersonBadgeFill />,
          link: goToKyc,
        },
        {
          title: "Set Withdrawal Account",
          module: "BUSINESS BANK ACCOUNT",
          type: "GENERAL",
          icon: <IoIosCash />,
          link: goToWithdrawalAccount,
        },
        {
          title: "Add Card Info",
          module: "BUSINESS BANK ACCOUNT",
          type: "GENERAL",
          icon: <BsFillCreditCard2BackFill />,
          link: goToCardInfo,
        },
        {
          title: "Account Manager",
          icon: <MdSwitchAccount />,
          link: goToAccountManager,
        },
        // {
        // 	title: 'Add Another Business',
        // 	icon: <BiPlus />,
        // 	link: goToAddBusiness,
        // },
      ],
    },
    {
      title: "Configurations",
      module: "BUSINESS DETAILS",
      icon: <GrDocumentConfig />,
      // link: goToBusinessInfo,
      subNavData: [
        // {
        // 	title: 'Payroll Configuration',
        // 	icon: <GiMoneyStack />,
        // 	module: 'PAYROLL',
        // 	link: goToManagePayroll,
        // },
        // {
        // 	title: 'Stock Configuration',
        // 	icon: <AiFillContainer />,
        // 	link: goToInventoryConfig,
        // },
        // {
        // 	title: "Products Sales Configuration",
        // 	icon: <MdOutlineProductionQuantityLimits />,
        // 	link: goToProductSalesConfig,
        // },
        {
          title: "General Settings",
          icon: <IoSettings />,
          link: goToGeneralSettings,
        },
        {
          title: "Invoice Settings",
          icon: <AiOutlineBell />,
          link: goToInvoiceReminderModal,
        },
      ],
    },
    // {
    //   title: "Staff Access Control",
    //   module: "BUSINESS ADMIN",
    //   icon: <MdAdminPanelSettings />,
    //   link: goToBusinessAdmin,
    // },
    {
      title: "Support",
      icon: <BiSupport />,
      link: goToSupport,
    },
    {
      title: "API Keys",
      icon: <TbKeyframeAlignCenterFilled />,
      link: goToApiKeys,
    },
    {
      title: "Invite Friends",
      icon: <FaUserFriends />,
      link: goToInvite,
    },
    // {
    // 	title: 'FAQs',
    // 	module: 'BUSINESS DETAILS',
    // 	icon: <FaQuestionCircle />,
    // 	link: goToFaq,
    // },
    {
      title: "Logout",
      icon: <RiLogoutCircleFill />,
      link: goTologout,
    },
  ];

  const { user } = userStore;
  const name =
    user.accountType === "Employee"
      ? `${user.firstname}`
      : businessStore.business?.tradingName;

  const userPermissions = user?.permissions || [];

  const [expandedNav, setExpandedNav] = useState(-1);

  return (
    <AppShell pageTitle="Settings" refresh={refresh}>
      {loading ? (
        <PageLoader title="While we set things up for you" loading={loading} />
      ) : (
        <>
          <div>
            <div className={styles.upper}>
              <div className={styles.upper__title}>
                <div className={styles.upper__title_shape}>
                  {" "}
                  {Array?.from(name)[0]?.toUpperCase()}
                </div>
                <MimaText variant="subtitleBold"> {name}</MimaText>
              </div>
            </div>
            <>
              <div className={styles.main__nav}>
                {navData.map((navItem, index) => (
                  <React.Fragment key={index}>
                    {!navItem.module ||
                    new Set(userPermissions.map((perm) => perm.module)).has(
                      navItem.module
                    ) ||
                    (navItem.type === "GENERAL" &&
                      user.accountType === "Employee") ? (
                      <>
                        <div
                          className={styles.main__nav_item}
                          onClick={() => {
                            if (navItem.subNavData) {
                              if (expandedNav === index) {
                                setExpandedNav(-1);
                              } else {
                                setExpandedNav(index);
                              }
                            } else {
                              navItem.link();
                            }
                          }}
                          key={index}
                        >
                          <div className={styles.main__nav_item__icon}>
                            {navItem.icon}
                          </div>
                          {navItem.title}

                          <IoIosArrowForward
                            className={
                              navItem.subNavData && expandedNav === index
                                ? styles.main__nav_item__icon2
                                : styles.main__nav_item__icon
                            }
                          />
                        </div>

                        {navItem.subNavData && expandedNav === index && (
                          <>
                            {navItem.subNavData.map((x, i) => (
                              <div
                                className={styles.sub__nav_item}
                                onClick={() => {
                                  setExpandedNav(-1);
                                  x.link();
                                }}
                                key={i}
                              >
                                <div className={styles.sub__nav_item__icon}>
                                  {x.icon}
                                </div>
                                {x.title}
                                <div className={styles.sub__nav_item__icon}>
                                  <IoIosArrowForward />
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ))}
              </div>
            </>
          </div>
          {(businessInfo ||
            securityModal ||
            kycModal ||
            withdrawalAccountModal ||
            accountManagerModal ||
            generalSettingsModal ||
            invoiceReminderModal ||
            supportModal ||
            logoutModal ||
            inviteFriendsModal ||
            subscriptionsModal ||
            cardInfoModal) && (
            <div className="modal">
              <AnimatePresence>
                {businessInfo && <BusinessInfo closeModal={closeModal} />}
                {securityModal && <Security closeModal={closeModal} />}
                {kycModal && <Kyc closeModal={closeModal} tier={tier} />}
                {withdrawalAccountModal && (
                  <WithdrawalAccount closeModal={closeModal} />
                )}
                {accountManagerModal && (
                  <AccountManager closeModal={closeModal} />
                )}
                {generalSettingsModal && (
                  <GeneralSettings closeModal={closeModal} />
                )}
                {invoiceReminderModal && (
                  <InvoiceReminder closeModal={closeModal} />
                )}
                {supportModal && <ContactSupport closeModal={closeModal} />}
                {logoutModal && <Logout closeModal={closeModal} />}
                {inviteFriendsModal && (
                  <InviteFriends closeModal={closeModal} />
                )}
                {subscriptionsModal && (
                  <Subscriptions closeModal={closeModal} />
                )}
                {cardInfoModal && <CardInfo closeModal={closeModal} />}
              </AnimatePresence>
            </div>
          )}
          {/* {(businessInfo ||
						securityModal ||
						withdrawalAccountModal ||
						managePayroll ||
						accountManagerModal ||
						inventoryConfigModal ||
						inviteFriendsModal ||
						supportModal ||
						productSalesConfigModal ||
						logoutModal ||
						kycModal ||
						addBusinessModal ||
						faqModal ||
						generalSettingsModal ||
						cardInfoModal ||
						invoiceReminderModal ||
						subscriptionsModal) && (
							<div className="modal">
								<AnimatePresence>
									{businessInfo && (
										<BusinessInfoContainer closeModal={closeModal} />
									)}
									{addBusinessModal && (
										<AddBusinessContainer closeModal={closeModal} />
									)}
									{securityModal && <SecurityContainer closeModal={closeModal} />}
									{managePayroll && (
										<ManagePayrollContainer closeModal={closeModal} />
									)}
									{withdrawalAccountModal && (
										<WithdrawalAccountContainer closeModal={closeModal} />
									)}
									{accountManagerModal && (
										<AccountManagerContainer closeModal={closeModal} />
									)}
									{inventoryConfigModal && (
										<InventoryConfigContainer closeModal={closeModal} />
									)}
									{productSalesConfigModal && (
										<ProductSalesConfigContainer closeModal={closeModal} />
									)}
									{inviteFriendsModal && (
										<InviteFriendsContainer closeModal={closeModal} />
									)}
									{supportModal && (
										<ContactSupportContainer closeModal={closeModal} />
									)}
									{logoutModal && <LogoutContainer closeModal={closeModal} />}
									{kycModal && (
										<KycContainer closeModal={closeModal} tier={tier} />
									)}
									{faqModal && <FaqsContainer closeModal={closeModal} />}
									{generalSettingsModal && (
										<GeneralSettingsContainer closeModal={closeModal} />
									)}

									{cardInfoModal && <CardInfoContainer closeModal={closeModal} />}
									{invoiceReminderModal && (
										<InvoiceReminderContainer closeModal={closeModal} />
									)}
									{subscriptionsModal && (
										<SubscriptionsContainer closeModal={closeModal} />
									)}
								</AnimatePresence>
							</div>
						)} */}
        </>
      )}
    </AppShell>
  );
};

export default Settings;
