import styles from "../../assets/styles/AppHome/BusinessInsightCard.module.scss";
import { BusinessInsightComponent as BIC } from "../../components/Home";
import React from "react";

const OrderSummary = ({ orderSummary }) => {
  return (
    <div className={styles.bi}>
      <div className={styles.bi__title}>
        <h4 className={styles.bi__title_text}>Order Summary</h4>
      </div>
      <div className={styles.bi__content}>
        <BIC title="Due Today" amount={orderSummary.dueToday} />
        <BIC title="Due Tomorrow" amount={orderSummary.dueTomorrow} />
        <BIC title="Due In Three Days" amount={orderSummary.dueInThreeDays} />
        <BIC title="Due This Week" amount={orderSummary.dueThisWeek} />
        <BIC title="Due Next Week" amount={orderSummary.dueNextWeek} />
        <BIC title="Over Due" amount={orderSummary.overDue} />
      </div>
    </div>
  );
};

export default OrderSummary;
