import { apiRequest } from '../utils/useAPIRequest';
import PayrollController from './PayrollController';
import { employeeStore } from '../stores';
import constant from '../utils/constant';

const { createPayroll } = PayrollController;

const getEmployees = async query => {
	if (!query) {
		query = '';
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/employees/business?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		employeeStore.setEmployees(data.employees);
		employeeStore.setEmployeesTotalCount(data.totalCounts);
		const activeEmployees = data.employees.filter(
			employee => employee.status === 'ACTIVE'
		);
		employeeStore.setMapEmployees(
			activeEmployees.map(employee => {
				return {
					key: employee._id,
					value: `${employee.user?.firstname} ${employee.user?.lastname}`,
				};
			})
		);
		return { status };
	}
	return { status, errorMessage };
};

const getCommissions = async query => {
	if (!query) {
		query = '';
	}
	const { status, data, errorMessage } = await apiRequest({
		url: `/banking/get-commission-payments?${query}`,
		method: 'get',
	});

	if (status === constant.Success) {
		employeeStore.setCommissions(data.transactions);
		employeeStore.setCommissionsTotalCount(data.totalCounts);

		return { status };
	}
	return { status, errorMessage };
};

const getDepartments = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/employees/dept',
		method: 'get',
	});
	if (status === constant.Success) {
		employeeStore.setDepartments(data.depts);
		employeeStore.setMapDepartments(
			data.depts.map(dept => {
				return {
					key: dept._id,
					value: dept.name,
				};
			})
		);
		return { status };
	}
	return { status, errorMessage };
};

const getUnits = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/employees/units',
		method: 'get',
	});
	if (status === constant.Success) {
		employeeStore.setUnits(data.units);
		employeeStore.setMapUnits(
			data.units.map(unit => {
				return {
					key: unit._id,
					value: unit.name,
				};
			})
		);
		return { status };
	}
	return { status, errorMessage };
};

const createDepartments = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/employees/dept',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await Promise.all([getEmployees(), getDepartments()]);
		return { status };
	}
	return { status, errorMessage };
};

const createUnits = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/employees/units',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await Promise.all([getEmployees(), getUnits()]);
		return { status };
	}
	return { status, errorMessage };
};

const getBusinessAdmins = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/employees/business-admins',
		method: 'get',
	});
	if (status === constant.Success) {
		employeeStore.setBusinessAdmins(data.value);
		return { status };
	}
	return { status, errorMessage };
};

const getEmployeeById = async id =>
	await apiRequest({ url: `/employees/employee/${id}`, method: 'get' });

const deleteEmployee = async (payload, id) => {
	const { status, errorMessage } = await apiRequest({
		url: `/employees/employee/deactivate/${id}`,
		method: 'patch',
		payload,
	});
	if (status === constant.Success) {
		await getEmployees();
		return { status };
	}
	return { status, errorMessage };
};

const updateEmployee = async (payload, id) => {
	const { status, errorMessage } = await apiRequest({
		url: `/users/business/employee/${id}`,
		method: 'patch',
		payload,
	});
	if (status === constant.Success) {
		await getEmployees();
		return { status };
	}
	return { status, errorMessage };
};

const createEmployee = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/users/business/employee',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await getEmployees();
		return { status };
	}
	return { status, errorMessage };
};

const createBulkEmployees = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/employees/bulk-employees',
		method: 'post',
		payload,
		hasImageUpload: true,
	});
	if (status === constant.Success) {
		const process = () =>
			new Promise(resolve =>
				setTimeout(async () => {
					await getEmployees();
					resolve({ status });
				}, 3000)
			);
		return await process();
	}
	return { status, errorMessage };
};

const updateBulkEmployeesSalary = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/employees/bulk/salary-updates',
		method: 'put',
		payload,
	});
	if (status === constant.Success) {
		await getEmployees();
		return { status };
	}
	return { status, errorMessage };
};

const getTags = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/banking/tags',
		method: 'get',
	});
	if (status === constant.Success) {
		employeeStore.setMapTags(
			data.map(tag => {
				return {
					key: tag,
					value: tag,
				};
			})
		);
	}
	return { status, errorMessage };
};

const createCommissionBulkPayment = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/banking/upload-commission-bulk-payment',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await Promise.all([getCommissions(), getTags()]);
		return { status };
	}
	return { status, errorMessage };
};
const deactivateBulkEmployee = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/employees/bulk/deactivate',
		method: 'put',
		payload,
	});
	if (status === constant.Success) {
		await getEmployees();
		return { status };
	}
	return { status, errorMessage };
};
const unSuspendEmployee  =  async id => {
	const { status, errorMessage } = await apiRequest({
		url: `/employees/unsuspend/${id}`,
		method: 'patch',
	});
	if (status === constant.Success) {
		await getEmployees();
		return { status };
	}
	return { status, errorMessage };
};

const updateBulkEmployeesStatusCSV = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/employees/bulk/status/email-csv',
		method: 'put',
		payload,
	});
	if (status === constant.Success) {
		await getEmployees();
		return { status };
	}
	return { status, errorMessage };
};

const getEmployeesGroups = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/employees/employee-groups/business',
		method: 'get',
	});
	if (status === constant.Success) {
		employeeStore.setGroups(data.groups);
		employeeStore.setMapGroups(
			data.groups?.map(group => {
				return {
					key: group._id,
					value: group.name,
					data: group
				};
			})
		);
		return { status };
	}
	return { status, errorMessage };
};

const createGroup = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/employees/employee-group',
		method: 'post',
		payload,
	});

	if (status === constant.Success) {
		await Promise.all([getEmployees(), getEmployeesGroups()]);
		return { status };
	}
	return { status, errorMessage };
};

const applyLoan = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/employees/apply-loan',
		method: 'patch',
		payload,
	});

	if (status === constant.Success) {
		await Promise.all([getEmployees(), getEmployeesGroups()]);
		return { status };
	}
	return { status, errorMessage };
};
const updateGroup = async (payload, id) => {
	const { status, errorMessage } = await apiRequest({
		url: `/employees/employee-group/${id}`,
		method: 'put',
		payload,
	});

	if (status === constant.Success) {
		await Promise.all([getEmployees(), getEmployeesGroups()]);
		return { status };
	}
	return { status, errorMessage };
};

const actionCommissionBulkPayment = async (payload) => {
	const { status, errorMessage } = await apiRequest({
		url: '/banking/action-commission-bulk-payment',
		method: 'put',
		payload,
	});

	if (status === constant.Success) {
		await Promise.all([getCommissions(), getTags()]);
		return { status };
	}
	return { status, errorMessage };
};
export default {
	createEmployee,
	actionCommissionBulkPayment,
	updateBulkEmployeesStatusCSV,
	createBulkEmployees,
	updateEmployee,
	deactivateBulkEmployee,
	getDepartments,
	applyLoan,
	createDepartments,
	getUnits,
	createUnits,
	getBusinessAdmins,
	deleteEmployee,
	getEmployeeById,
	getEmployees,
	getEmployeesGroups,
	createGroup,
	updateGroup,
	unSuspendEmployee,
	updateBulkEmployeesSalary,
	createCommissionBulkPayment,
	getTags,
	getCommissions,
};
