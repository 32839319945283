import constant from "../utils/constant";
import {
  ForgotPassword,
  //   Home,
  Login,
  Otp,
  Signup,
  ForgotPasswordReset,
} from "../pages";

const openRoutes = [
  {
    path: constant.Routes.Home,
    element: <Login />,
  },
  {
    path: constant.Routes.Login,
    element: <Login />,
  },
  {
    path: constant.Routes.SignUp,
    element: <Signup />,
  },
  {
    path: constant.Routes.Otp,
    element: <Otp />,
  },
  {
    path: constant.Routes.ForgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: constant.Routes.ForgotPasswordReset,
    element: <ForgotPasswordReset />,
  },
];

export default openRoutes;
