import {
	IconButton,
	Sidebar,
	MimaButton,
	MimaDropdown,
	MimaInput,
	MimaText,
	Modal,
} from '../../components';
import styles from '../../assets/styles/Wallet/TransferFunds.module.scss';
import ConfirmImage from '../../assets/img/MimaConfirm.png';
import React from 'react';

const ConfirmTransfer = ({ goToTransferSuccess }) => {
	return (
		<div className={styles.centerContent}>
			<img src={ConfirmImage} className={styles.confirmImage} />
			<MimaText align="center">Transfer Successful</MimaText>
			<MimaText align="center" variant="bodyBold">
				5,000 NGN to
			</MimaText>
			<MimaText
				mb={5}
				align="center"
				variant="bodyBold"
				color="var(--color-primary)"
			>
				QUAZIM DOE 0056678437
			</MimaText>

			<MimaButton
				title="Continue"
				type="submit"
				onClick={goToTransferSuccess}
			/>
		</div>
	);
};

export default ConfirmTransfer;
