import React, { Fragment, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import * as dropdownStyles from "./styles";
import { ReceiptCheckBox } from "../Receipt";
import { FaCaretDown } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import styles from "../../assets/styles/MimaDropdown.module.scss";
import { useDebounce, useIntersectionObserver } from "../../hooks";
import { useInfiniteQuery } from "@tanstack/react-query";
import { encodeQueryData } from "../../utils/utils";
import { apiRequest } from "../../utils/useAPIRequest";
import PageLoader from "../PageLoader";
import MimaText from "../MimaText";

const propTypes = {
  labelTitle: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["medium", "form", "error", "fullWidthError"]),
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.any,
  styleClass: PropTypes.any,
  pt: PropTypes.number,
  pb: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.any,
  mb: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  fontSize: PropTypes.number,
  //for edit
  currentValue: PropTypes.array,
  //Pagination
  queryKey: PropTypes.array.isRequired,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  optionKey: PropTypes.string,
  url: PropTypes.string.isRequired,
  getPageValue: PropTypes.func.isRequired,
  getPageTotalCount: PropTypes.func.isRequired,
};

const MimaMultiPaginatedDropdown = ({
  labelTitle,
  placeholder,
  onBlur,
  onChange,
  value,
  variant = "medium",
  width,
  id,
  name,
  touched,
  error,
  mb,
  mt,
  mr,
  ml,
  height,
  disabled = false,
  currentValue,
  url = "",
  queryKey = [],
  optionLabel = "label",
  optionValue = "value",
  optionKey = "_id",
  getPageValue = (page) => {},
  getPageTotalCount = (lastPage) => {},
  ...props
}) => {
  // useEffect(() => {
  //   if (currentValue && currentValue?.length > 0) {
  //     const filterDataArray = data.filter((item) =>
  //       currentValue.includes(item.key)
  //     );
  //     // setTags([...filterDataArray]);
  //   }
  // }, [currentValue, data]);

  const loadMoreRef = useRef();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const debouncedSearch = useDebounce(searchValue, 1000);

  const query = useMemo(() => {
    return {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      limit: 50,
      offset: 1,
    };
  }, [debouncedSearch]);

  const openDropdownHandler = () => {
    setOpenDropdown(!openDropdown);
  };

  const dropDownSetHandler = (dropDownOption) => {
    const refinedItem = {
      key: dropDownOption[optionKey],
      value: dropDownOption[optionLabel],
    };
    addTags(refinedItem);
  };

  const queryFn = async ({ pageParam = 1, queryKey }) => {
    const encodeQuery = encodeQueryData({
      ...query,
      offset: pageParam || 1,
    });

    return apiRequest({
      url: `${url}?${encodeQuery}`,
      method: "get",
    });
  };

  const getNextPageParam = (lastPage, allPages) => {
    const allPagesCount =
      allPages.flatMap((page) => getPageValue(page) || []).length || 0;
    const totalCount = getPageTotalCount(lastPage) || 0;
    const limit = query.limit || 50;

    if (totalCount > allPagesCount) {
      const page = Math.floor(allPagesCount / limit);
      return page + 1;
    }

    return undefined;
  };

  const {
    data: result = [],
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery([...queryKey, query], queryFn, { getNextPageParam });

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const [tags, setTags] = useState([]);

  //Remove tags
  const removeTags = (index) => {
    const updateTags = [...tags.filter((tag) => tags.indexOf(tag) !== index)];
    const keysArray = updateTags.map((item) => item.key);
    // const valuesArray = updateTags.map((item) => item.value);

    setTags(updateTags);
    onChange(keysArray);
  };

  //Add tags
  const addTags = (dropDownOption) => {
    const existingIndex = tags.findIndex(
      (item) => item.key === dropDownOption.key
    );

    if (existingIndex !== -1) {
      const refinedTags = [...tags];
      refinedTags.splice(existingIndex, 1);
      setTags(refinedTags);
      const keysArray = refinedTags.map((item) => item.key);
      // const valuesArray = refinedTags.map((item) => item.value);
      onChange([...keysArray]);
    } else {
      setTags((prevTags) => [...prevTags, dropDownOption]);
      const tagKeysArray = tags.map((item) => item.key);
      // const tagValuesArray = tags.map((item) => item.value);
      const keysArray = [...tagKeysArray, dropDownOption.key];
      // const valuesArray = [...tagValuesArray, dropDownOption.value];
      onChange([...keysArray]);
    }
  };

  const errorVariant = useMemo(() => {
    if (variant === "form") {
      return "fullWidthError";
    } else return "error";
  }, [variant]);

  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
      <label className={styles.label}>{labelTitle}</label>
      <>
        <DropdownSkeleton
          width={width}
          height={height}
          placeholder={placeholder}
          onClick={openDropdownHandler}
          variant={error && touched ? errorVariant : variant}
          onBlur={onBlur}
        >
          <div className={styles.tags}>
            {tags.length > 0 ? (
              <>
                {tags.map((tag, index) => (
                  <div className={styles.singleTag} key={index}>
                    <span>{tag.value}</span>
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTags(index);
                      }}
                    >
                      <MdClose />
                    </i>
                  </div>
                ))}
              </>
            ) : (
              <p>{placeholder}</p>
            )}
          </div>
          <DropIcon>
            <FaCaretDown
              className={openDropdown ? styles.arr__open : styles.arr__close}
              onClick={() => {
                setOpenDropdown(!openDropdown);
              }}
            />
          </DropIcon>
        </DropdownSkeleton>
        {openDropdown && (
          <DropdownBody
            width={width}
            onMouseLeave={() => {
              setOpenDropdown(!openDropdown);
            }}
          >
            <div className="searchInMiddle">
              <SearchDivSkeleton mb={1}>
                <SearchSkeleton
                  type="text"
                  value={searchValue}
                  placeholder="Search"
                  onChange={(e) => {
                    const text = e.target.value;
                    setSearchValue(text);
                  }}
                  variant="dropdown"
                />
              </SearchDivSkeleton>
            </div>

            <div>
              {isSuccess
                ? result?.pages?.map((page) => (
                    <Fragment key={String(page)}>
                      {page.status === "Success"
                        ? getPageValue(page).map((item) => (
                            <div
                              key={`${page}-${item[optionKey]}`}
                              className={styles.select__options}
                            >
                              <ReceiptCheckBox
                                title={item[optionLabel]}
                                name={item[optionKey]}
                                id={item[optionKey]}
                                value={item[optionKey]}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  dropDownSetHandler(item);
                                }}
                                checked={tags.find(
                                  (x) => x.key === item[optionKey]
                                )}
                              />
                            </div>
                          ))
                        : null}
                    </Fragment>
                  ))
                : null}

              <div
                ref={loadMoreRef}
                className={`${!hasNextPage ? "hidden" : ""}`}
              >
                <PageLoader loading={isFetchingNextPage} type="bar" />
              </div>

              {isLoading ? (
                <MimaText align="center">Loading...</MimaText>
              ) : null}
            </div>
          </DropdownBody>
        )}
      </>

      {error ? (
        <div className={styles.error}>{touched ? error : ""}</div>
      ) : null}
    </DivSkeleton>
  );
};

const DropdownSkeleton = styled.div`
  ${dropdownStyles.multiDivBase};
  ${(props) => props.variant && dropdownStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(touched) => (touched.id || touched.name ? `color: var(--color-dark)` : "")}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
`;

const DropdownBody = styled.div`
  ${dropdownStyles.multiBodyBase};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const DivSkeleton = styled.div`
  ${dropdownStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const SearchDivSkeleton = styled.div`
  ${dropdownStyles.searchDivBase};
  ${(props) => dropdownStyles[props.variant]};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const SearchSkeleton = styled.input`
  ${dropdownStyles.searchBase};
  ${(props) => dropdownStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
  ${(touched) => (touched ? `color: var(--color-dark)` : "")}
`;

const DropIcon = styled.div`
  ${dropdownStyles.iconBase};
`;

MimaMultiPaginatedDropdown.propTypes = propTypes;

export default MimaMultiPaginatedDropdown;
