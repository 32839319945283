import { MimaToastUtil } from "../../../components";
import { userStore, useStores } from "../../../stores";
import * as yup from "yup";
import constant from "../../../utils/constant";
import { useState } from "react";
import { SettingsController, WalletController } from "../../../controllers";

const useWithdrawLogic = ({
  pinContent,
  setPinContent,
  transferSwitchState,
  closeModal,
}) => {
  const { businessStore, walletStore } = useStores();

  const validationSchema = () => {
    return yup.object({
      transactionAmount: yup
        .number()
        .min(100, "Amount Should be greater than 100.00"),
    });
  };

  const onSubmit = (payload) => {
    const bankAccount =
      userStore.user.accountType === "Business"
        ? businessStore.business["withdrawalBankAccount"]
        : userStore.user["withdrawalBankAccount"];
    if (!bankAccount) {
      return MimaToastUtil.error({
        message: constant.MissingWithdrawalAccount,
      });
    }
    payload.currencyCode = "NGN";
    payload.narration = "";
    payload.saveBeneficiary = false;
    payload.beneficiaryAccountNumber = bankAccount.accountNumber;
    payload.accountName = bankAccount.accountName;
    payload.beneficiaryBank = bankAccount.bankCode;
    payload.isWithdrawal = true;
    walletStore.setTransferPayload(payload);
    transferSwitchState.goToConfirmPin();
  };

  const goBack = () => {
    if (pinContent) {
      setPinContent(false);
    } else {
      closeModal();
    }
  };

  // transfer pin logic

  const payload = walletStore.transferPayload;
  const data = {
    amount: payload.transactionAmount,
    accountName: payload.accountName || "test",
  };
  const [loading, setLoading] = useState(false);
  const onSubmitTransferPin = async (pin) => {
    payload.pin = pin;
    setLoading(true);
    delete payload.accountName;
    payload.currencyCode = "NGN";
    let response;
    const { selectedSubWallet } = walletStore;
    if (Object.keys(selectedSubWallet).length > 0) {
      payload.subWallet = selectedSubWallet?._id;
      response = await WalletController.transferSubWalletFund(payload);
    } else {
      response = await WalletController.transferFund(payload);
    }
    const { status, errorMessage } = response;
    walletStore.setSelectedSubWallet({});
    SettingsController.runTransactionSetup();
    setLoading(false);
    if (status === constant.Success) {
      return transferSwitchState.goToTransferSuccess();
    }

    if (errorMessage) {
      return MimaToastUtil.error({ message: errorMessage });
    }
    return transferSwitchState.goToTransferFailed();
  };

  return {
    validationSchema,
    goBack,
    onSubmit,
    // transfer Pin logic
    loading,
    onSubmitTransferPin,
    data,
  };
};
export default useWithdrawLogic;
