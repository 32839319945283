import React, { useMemo, useState } from "react";
import * as yup from "yup";
import { appStore, invoiceStore, useStores } from "../../../stores";
import constant from "../../../utils/constant";
import { getAvailableQuantity } from "../../../utils/utils";
import { InvoiceController, QuotationController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import { object, string, number, array, boolean, date } from "yup";
import { useGetAllStocks } from "../../../controllers/InventoryController";

const useEditQuotationLogic = ({ closeModal, quote }) => {
  const [loading, setLoading] = useState(false);

  const invoice = invoiceStore.selectedInvoice;
  const orders = quote?.orders?.map((order) => {
    return {
      quantity: order.quantity,
      unitPrice: order.unitPrice,
      item: order.item,
      stock: order.stock,
    };
  });

  const invoiceDetailsValidation = () => {
    return object({
      customer: string().required("Select a customer"),
      orders: array(
        object({
          quantity: number().min(1, "Quantity should be greater than 1"),
          unitPrice: number().min(1, "Unit price should be greater than 1"),
          item: string().required("Service is required"),
        })
      ),
      currencyCode: string().required("Select Currency"),
      vat: boolean().required("Are you charging VAT?"),
      wht: boolean().required("Are you deducting WHT?"),
      dueDate: string().required("Enter Payment due date"),
      discountPer: number()
        .min(0, "Min discount should be greater than 0%")
        .max(100, "Max discount should be less than or equal to 100%"),
      // depositPer: number()
      // 	.min(1, 'Min deposit should be greater than 1%')
      // 	.max(100, 'Max deposit should be less than or equal to 100%')
      // 	.required('Deposit is required'),
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    values.orders.forEach((item) => {
      if (item.stock === "") {
        delete item.stock;
      }
      delete item.itemPrice;
      delete item._id;
    });

    const payload = {
      customer: values.customer,
      currencyCode: values.currencyCode,
      note: values.note,
      shipping: values.shipping,
      orders: values.orders,
      discountAmount: values.discountAmount,
      vat: values.vat,
      wht: values.wht,
      dueDate: values.dueDate,
    };

    const { status, errorMessage } = await QuotationController.updateQuotation(
      payload,
      quote._id
    );

    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      closeModal();
    } else {
      setLoading(false);
      MimaToastUtil.error({ message: errorMessage });
    }
  };

  // Duplicate quote logic

  const invoiceItem = invoice.status === constant.TRANSACTION_STATUS.UNPAID;

  const validationSchemaDuplicateQuote = () => {
    return object({
      customer: string().required("Select a customer"),

      dueDate: date().required("valid date is required"),
    });
  };

  const balanceAmount = (values) => {
    return totalAmountCal(values) - values.deposit;
  };

  const totalAmountCal = (values) =>
    totalWithoutDiscount(values) + values.shipping - values.discountAmount;

  const totalWithoutDiscount = (values) => {
    const totalItemPrice = values?.orders?.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.quantity * currentValue.unitPrice,
      0
    );
    let totalAmount = totalItemPrice;
    totalAmount = values.vat ? totalAmount + totalAmount * 0.075 : totalAmount;
    totalAmount = values.wht ? totalAmount - totalAmount * 0.05 : totalAmount;
    return totalAmount;
  };

  const discountPerCal = (values) =>
    (values.discountAmount / totalWithoutDiscount(values)) * 100 || 0;

  const depositPerCal = (values) =>
    (values.deposit / totalAmountCal(values)) * 100 || 0;

  const depositAmountCal = (values) =>
    (values.depositPer * totalAmountCal(values)) / 100 || 0;

  const onSubmitDuplicateQuote = async (values) => {
    values.orders.forEach((item) => {
      !item.stock && delete item.stock;
      delete item.itemPrice;
      delete item._id;
    });

    setLoading(true);
    const payload = {
      customer: values.customer,
      currencyCode: values.currencyCode,
      note: values.note,
      shipping: values.shipping,
      orders: values.orders,
      discountAmount: values.discountAmount,
      vat: values.vat,
      wht: values.wht,
      dueDate: values.dueDate,
    };

    const { status, errorMessage } = await QuotationController.createQuotation(
      payload,
      quote._id
    );
    setLoading(false);

    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      closeModal();
      return;
    }
    MimaToastUtil.error({ message: errorMessage });
  };

  return {
    quote,
    invoice,
    onSubmit,
    loading,
    orders,
    invoiceDetailsValidation,
    // duplicate quote logic
    validationSchemaDuplicateQuote,
    onSubmitDuplicateQuote,
  };
};

export default useEditQuotationLogic;
