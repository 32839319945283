
import Papa from 'papaparse';
import { amountFormatterWithoutCurrency } from './utils';
import * as XLSX from 'xlsx';

export const customerReportDL = (data) => {
    const flattenedTop5 = data.top5FrequentCustomers.map(customer => [
        customer.customer,
        customer.count,
        'NGN',
        `${amountFormatterWithoutCurrency(customer.total)}`,
    ]);

    const flattenedTop5Naira = data.top5PayingCustomers.topNGN.map(customer => [
        customer.customer,
        `${amountFormatterWithoutCurrency(customer.total)}`,
        customer.count,
    ]);

    const flattenedTop5Dollar = data.top5PayingCustomers.topUSD.map(customer => [
        customer.customer,
        `${amountFormatterWithoutCurrency(customer.total)}`,
        customer.count,
    ]);

    const flattenedTop5Pounds = data.top5PayingCustomers.topGBP.map(customer => [
        customer.customer,
        `${amountFormatterWithoutCurrency(customer.total)}`,
        customer.count,
    ]);

    const flattenedTop5Euro = data.top5PayingCustomers.topEUR.map(customer => [
        customer.customer,
        `${amountFormatterWithoutCurrency(customer.total)}`,
        customer.count,
    ]);

    // Add headers to the flattened array
    const csvData = [
        [],
        [`${data.tradingName.toUpperCase()} CUSTOMER ANALYSIS REPORT FROM ${data.startDate} - ${data.endDate}`],
        [],
        ['Top 5 Frequent Customers'],
        ['Name', 'Count', 'Currency', 'Amount'],
        ...flattenedTop5,
        [],
        ['Top 5 Paying Customers'],
        [],
        ['NAIRA'],
        ['Name', 'Amount', 'Count',],
        ...flattenedTop5Naira,
        [],
        ['DOLLARS'],
        ['Name', 'Amount', 'Count',],
        ...flattenedTop5Dollar,
        [],
        ['POUNDS'],
        ['Name', 'Amount', 'Count',],
        ...flattenedTop5Pounds,
        [],
        ['Euro'],
        ['Name', 'Amount', 'Count',],
        ...flattenedTop5Euro,
        [],
        ['Total Customers', data.totalCustomers.totalCustomers],
        ['New Customers', data.newCustomers.newCustomers]
    ];

    // Convert to CSV using Papa Parse
    const csvString = Papa.unparse(csvData);

    // Trigger download
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${data.tradingName.toUpperCase()} CUSTOMER ANALYSIS REPORT FROM ${data.startDate} - ${data.endDate}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const customerReportDL2 = (data) => {
    const flattenedTop5 = data.top5FrequentCustomers.map(customer => [
        customer.customer,
        customer.count,
        'NGN',
        `${amountFormatterWithoutCurrency(customer.total)}`,
    ]);

    const flattenedTop5Naira = data.top5PayingCustomers.topNGN.map(customer => [
        customer.customer,
        `${amountFormatterWithoutCurrency(customer.total)}`,
        customer.count,
    ]);

    const flattenedTop5Dollar = data.top5PayingCustomers.topUSD.map(customer => [
        customer.customer,
        `${amountFormatterWithoutCurrency(customer.total)}`,
        customer.count,
    ]);

    const flattenedTop5Pounds = data.top5PayingCustomers.topGBP.map(customer => [
        customer.customer,
        `${amountFormatterWithoutCurrency(customer.total)}`,
        customer.count,
    ]);

    const flattenedTop5Euro = data.top5PayingCustomers.topEUR.map(customer => [
        customer.customer,
        `${amountFormatterWithoutCurrency(customer.total)}`,
        customer.count,
    ]);

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([
        [],
        [`${data.tradingName.toUpperCase()} CUSTOMER ANALYSIS REPORT FROM ${data.startDate} - ${data.endDate}`],
        [],
        ['Top 5 Frequent Customers'],
        ['Name', 'Count', 'Currency', 'Amount'],
        ...flattenedTop5,
        [],
        ['Top 5 Paying Customers'],
        [],
        ['NAIRA'],
        ['Name', 'Amount', 'Count',],
        ...flattenedTop5Naira,
        [],
        ['DOLLARS'],
        ['Name', 'Amount', 'Count',],
        ...flattenedTop5Dollar,
        [],
        ['POUNDS'],
        ['Name', 'Amount', 'Count',],
        ...flattenedTop5Pounds,
        [],
        ['Euro'],
        ['Name', 'Amount', 'Count',],
        ...flattenedTop5Euro,
        [],
        ['Total Customers', data.totalCustomers.totalCustomers],
        ['New Customers', data.newCustomers.newCustomers]
    ]);

    //styling

    if (!ws['!merges']) ws['!merges'] = []; // Create the '!merges' property if it doesn't exist
    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });


    /* create !cols array if it does not exist */
    if (!ws["!cols"]) ws["!cols"] = [];

    /* create column metadata object if it does not exist */
    if (!ws["!cols"][0]) ws["!cols"][0] = { wch: 8 };
    if (!ws["!cols"][1]) ws["!cols"][1] = { wch: 8 };
    if (!ws["!cols"][3]) ws["!cols"][3] = { wch: 8 };

    /* set column width */
    ws["!cols"][0].wpx = 150;
    ws["!cols"][1].wpx = 100;
    ws["!cols"][3].wpx = 100;

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, `${data.tradingName.toUpperCase()} CUSTOMER ANALYSIS REPORT FROM ${data.startDate} - ${data.endDate}.xlsx`);
};

export const invoiceReportDL = (data) => {
    const currencyOrder = ["NGN", "USD", "GBP", "EUR"];

    //fully paid

    const convertFullPaidArray = data.statuses.flatMap(({ _id, status }) =>
        status
            .filter(({ type }) => type === "FULLY-PAID")
            .map(({ type, count, totalAmountPaid }) => ({
                _id,
                type,
                count,
                totalAmountPaid,
            }))
    ).sort((a, b) =>
        currencyOrder.indexOf(a._id) - currencyOrder.indexOf(b._id)
    );

    const flattenedFullyPaid = convertFullPaidArray.reduce((result, { count, totalAmountPaid }) => {
        result.push(count, amountFormatterWithoutCurrency(totalAmountPaid));
        return result;
    }, []);

    //part paid

    const convertPartPaidArray = data.statuses.flatMap(({ _id, status }) =>
        status
            .filter(({ type }) => type === "PARTIALLY-PAID")
            .map(({ type, count, totalAmountPaid }) => ({
                _id,
                type,
                count,
                totalAmountPaid,
            }))
    ).sort((a, b) =>
        currencyOrder.indexOf(a._id) - currencyOrder.indexOf(b._id)
    );;

    const flattenedPartPaid = convertPartPaidArray.reduce((result, { count, totalAmountPaid }) => {
        result.push(count, amountFormatterWithoutCurrency(totalAmountPaid));
        return result;
    }, []);

    //not paid

    const convertUnPaidArray = data.statuses.flatMap(({ _id, status }) =>
        status
            .filter(({ type }) => type === "UNPAID")
            .map(({ type, count, totalAmount }) => ({
                _id,
                type,
                count,
                totalAmount,
            }))
    ).sort((a, b) =>
        currencyOrder.indexOf(a._id) - currencyOrder.indexOf(b._id)
    );;

    const flattenedUnPaid = convertUnPaidArray.reduce((result, { count, totalAmount }) => {
        result.push(count, amountFormatterWithoutCurrency(totalAmount));
        return result;
    }, []);


    //Void

    const convertVoidArray = data.statuses.flatMap(({ _id, status }) =>
        status
            .filter(({ type }) => type === "VOID")
            .map(({ type, count, totalAmount }) => ({
                _id,
                type,
                count,
                totalAmount,
            }))
    ).sort((a, b) =>
        currencyOrder.indexOf(a._id) - currencyOrder.indexOf(b._id)
    );;

    const flattenedVoid = convertVoidArray.reduce((result, { count, totalAmount }) => {
        result.push(count, amountFormatterWithoutCurrency(totalAmount));
        return result;
    }, []);

    //Overpaid

    const convertOverPaidArray = data.statuses.flatMap(({ _id, status }) =>
        status
            .filter(({ type }) => type === "OVER-PAID")
            .map(({ type, count, totalAmountPaid }) => ({
                _id,
                type,
                count,
                totalAmountPaid,
            }))
    ).sort((a, b) =>
        currencyOrder.indexOf(a._id) - currencyOrder.indexOf(b._id)
    );;

    const flattenedOverPaid = convertOverPaidArray.reduce((result, { count, totalAmountPaid }) => {
        result.push(count, amountFormatterWithoutCurrency(totalAmountPaid));
        return result;
    }, []);

    //Overdue
    const convertOverDueArray = data.overDue.map(({ _id, count = "NA", totalOverdue }) => ({
        _id,
        count,
        totalOverdue,
    })).sort((a, b) =>
        currencyOrder.indexOf(a._id) - currencyOrder.indexOf(b._id)
    );;

    const flattenedOverDue = convertOverDueArray.reduce((result, { count, totalOverdue }) => {
        result.push(count, amountFormatterWithoutCurrency(totalOverdue));
        return result;
    }, []);

    //all

    const fullPaidArray = ["Fully Paid", ...flattenedFullyPaid]
    const partPaidArray = ["Partially Paid", ...flattenedPartPaid]
    const unPaidArray = ["Not Paid", ...flattenedUnPaid]
    const voidArray = ["Void", ...flattenedVoid]
    const overPaidArray = ["Over Paid", ...flattenedOverPaid]
    const overDueArray = ["Over due", ...flattenedOverDue]



    const fileTitle = `${data.tradingName.toUpperCase()} INVOICE REPORT FROM ${data.startDate} - ${data.endDate}`

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([
        [],
        [`${fileTitle}`],
        [],
        ['Multicurrency'],
        ['', 'NGN', '', 'USD', '', 'GBP', '', 'EUR'],
        ['', 'Count', 'Amount', 'Count', 'Amount', 'Count', 'Amount', 'Count', 'Amount',],
        fullPaidArray,
        partPaidArray,
        unPaidArray,
        overDueArray,
        voidArray,
        overPaidArray,

    ]);

    //styling

    if (!ws['!merges']) ws['!merges'] = []; // Create the '!merges' property if it doesn't exist
    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 8 } });
    ws['!merges'].push({ s: { r: 2, c: 0 }, e: { r: 2, c: 8 } });
    ws['!merges'].push({ s: { r: 4, c: 1 }, e: { r: 4, c: 2 } });
    ws['!merges'].push({ s: { r: 4, c: 3 }, e: { r: 4, c: 4 } });
    ws['!merges'].push({ s: { r: 4, c: 5 }, e: { r: 4, c: 6 } });
    ws['!merges'].push({ s: { r: 4, c: 7 }, e: { r: 4, c: 8 } });


    /* create !cols array if it does not exist */
    if (!ws["!cols"]) ws["!cols"] = [];

    /* create column metadata object if it does not exist */
    if (!ws["!cols"][0]) ws["!cols"][0] = { wch: 8 };
    if (!ws["!cols"][1]) ws["!cols"][1] = { wch: 8 };
    if (!ws["!cols"][2]) ws["!cols"][2] = { wch: 8 };
    if (!ws["!cols"][3]) ws["!cols"][3] = { wch: 8 };
    if (!ws["!cols"][4]) ws["!cols"][4] = { wch: 8 };
    if (!ws["!cols"][5]) ws["!cols"][5] = { wch: 8 };
    if (!ws["!cols"][6]) ws["!cols"][6] = { wch: 8 };
    if (!ws["!cols"][7]) ws["!cols"][7] = { wch: 8 };
    if (!ws["!cols"][8]) ws["!cols"][8] = { wch: 8 };

    /* set column width */
    ws["!cols"][0].wpx = 150;
    ws["!cols"][1].wpx = 100;
    ws["!cols"][2].wpx = 100;
    ws["!cols"][3].wpx = 100;
    ws["!cols"][4].wpx = 100;
    ws["!cols"][5].wpx = 100;
    ws["!cols"][6].wpx = 100;
    ws["!cols"][7].wpx = 100;
    ws["!cols"][8].wpx = 100;



    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, `${fileTitle}.xlsx`);
};

export const payrollReportDL = (data) => {

    const flattenedData = data.data.map(payroll => [
        payroll._id,
        payroll.count,
        `${amountFormatterWithoutCurrency(payroll.totalAmount)}`,
    ]);

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([
        [`${data.tradingName.toUpperCase()} PAYROLL REPORT FROM ${data.startDate} - ${data.endDate}`],
        [],
        ['STATUS', 'Count', 'Amount'],
        ...flattenedData,
    ]);

    //styling

    if (!ws['!merges']) ws['!merges'] = []; // Create the '!merges' property if it doesn't exist
    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });

    // create !cols array if it does not exist
    if (!ws["!cols"]) ws["!cols"] = [];

    //  create column metadata object if it does not exist 
    if (!ws["!cols"][0]) ws["!cols"][0] = { wch: 8 };
    if (!ws["!cols"][1]) ws["!cols"][1] = { wch: 8 };
    if (!ws["!cols"][2]) ws["!cols"][2] = { wch: 8 };

    // set column width
    ws["!cols"][0].wpx = 150;
    ws["!cols"][1].wpx = 80;
    ws["!cols"][2].wpx = 150;

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, `${data.tradingName.toUpperCase()} PAYROLL REPORT FROM ${data.startDate} - ${data.endDate}.xlsx`);
};
export const splitPaymentReportDL = (data) => {
    const currencyOrder = ["NGN", "USD", "GBP", "EUR"];

    const filteredAndMappedData = data.data.flatMap((x) => {
        return {
            data: x
        };
    }).map(x => x.data).sort((a, b) =>
        currencyOrder.indexOf(a.currencyCode) - currencyOrder.indexOf(b.currencyCode)
    );

    const flattenedTotalAmount = filteredAndMappedData.reduce((result, { totalAmount }) => {
        result.push(`${amountFormatterWithoutCurrency(totalAmount)}`);
        return result;
    }, []);
    const flattenedSplitAmount = filteredAndMappedData.reduce((result, { splitAmount }) => {
        result.push(`${amountFormatterWithoutCurrency(splitAmount)}`);
        return result;
    }, []);
    const flattenedTotalProfit = filteredAndMappedData.reduce((result, { totalProfit }) => {
        result.push(`${amountFormatterWithoutCurrency(totalProfit)}`);
        return result;
    }, []);
    const flattenedTotalVoid = filteredAndMappedData.reduce((result, { totalVoid }) => {
        result.push(`${amountFormatterWithoutCurrency(totalVoid)}`);
        return result;
    }, []);
    const flattenedTotalCount = filteredAndMappedData.reduce((result, { totalCount }) => {
        result.push(`${amountFormatterWithoutCurrency(totalCount)}`);
        return result;
    }, []);

    const totalAmountArray = ["Total Amount", ...flattenedTotalAmount]
    const splitAmountArray = ["Split Amount", ...flattenedSplitAmount]
    const totalProfit = ["Total Profit", ...flattenedTotalProfit]
    const totalVoidArray = ["Total Void", ...flattenedTotalVoid]
    const totalCountArray = ["Total Count", ...flattenedTotalCount]

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([
        [],
        [`${data.tradingName.toUpperCase()} SPLIT PAYMENT REPORT FROM ${data.startDate} - ${data.endDate}`],
        [],
        ['Split Analysis', 'NGN', 'USD', 'GBP', 'EUR'],
        totalAmountArray,
        splitAmountArray,
        totalProfit,
        totalVoidArray,
        totalCountArray,
    ]);

    //styling

    if (!ws['!merges']) ws['!merges'] = []; // Create the '!merges' property if it doesn't exist
    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });

    // create !cols array if it does not exist
    if (!ws["!cols"]) ws["!cols"] = [];

    //  create column metadata object if it does not exist 
    if (!ws["!cols"][0]) ws["!cols"][0] = { wch: 8 };
    if (!ws["!cols"][1]) ws["!cols"][1] = { wch: 8 };
    if (!ws["!cols"][2]) ws["!cols"][2] = { wch: 8 };
    if (!ws["!cols"][3]) ws["!cols"][3] = { wch: 8 };
    if (!ws["!cols"][4]) ws["!cols"][4] = { wch: 8 };

    // set column width
    ws["!cols"][0].wpx = 150;
    ws["!cols"][1].wpx = 80;
    ws["!cols"][2].wpx = 80;
    ws["!cols"][3].wpx = 80;
    ws["!cols"][4].wpx = 80;

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, `${data.tradingName.toUpperCase()} SPLIT PAYMENT REPORT FROM ${data.startDate} - ${data.endDate}.xlsx`);
};

export const billsPaymentReportDL = (data) => {

    const flattenedData = data.result.map(bills => {
        return [
            bills._id,
            bills.count,
            `${amountFormatterWithoutCurrency(bills.totalAmount)}`,
        ]
    });
    const sortedArray = flattenedData.sort((a, b) => {
        const categoryA = a[0];
        const categoryB = b[0];

        // sorting alphabetically
        return categoryA.localeCompare(categoryB);
    });
    const [array1, array2, array3, array4] = sortedArray

    const arrayOfTotal = [data.totalCount, amountFormatterWithoutCurrency(data.totalAmount)];

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([
        [],
        [`${data.tradingName.toUpperCase()} BILL PAYMENT REPORT FROM ${data.startDate} - ${data.endDate}`],
        [],
        ['Category', 'Count', 'Amount'],
        array1,
        array2,
        array3,
        array4,
        ['TOTAL', ...arrayOfTotal],
    ]);

    //styling

    if (!ws['!merges']) ws['!merges'] = []; // Create the '!merges' property if it doesn't exist
    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });

    // create !cols array if it does not exist
    if (!ws["!cols"]) ws["!cols"] = [];

    //  create column metadata object if it does not exist
    if (!ws["!cols"][0]) ws["!cols"][0] = { wch: 8 };
    if (!ws["!cols"][1]) ws["!cols"][1] = { wch: 8 };
    if (!ws["!cols"][2]) ws["!cols"][2] = { wch: 8 };

    // set column width
    ws["!cols"][0].wpx = 150;
    ws["!cols"][1].wpx = 80;
    ws["!cols"][2].wpx = 150;

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, `${data.tradingName.toUpperCase()} BILL PAYMENT REPORT FROM ${data.startDate} - ${data.endDate}.xlsx`);
};
export const inventoryReportDL = (data) => {

    const top5MostSoldProducts = data.data.top5MostSoldProducts.map(product => [
        product.name,
        product.totalQuantity,
        product.orderCount,
        `${amountFormatterWithoutCurrency(product.totalSum)}`,
    ]);
    const top5MostSoldStocks = data.data.top5MostSoldStocks.map(stock => [
        stock.name,
        stock.totalQuantity,
        stock.orderCount,
        `${amountFormatterWithoutCurrency(stock.totalSum)}`,
    ]);
    const least5MostSoldStocks = data.data.least5SoldStocks.map(stock => [
        stock.name,
        '-',
        stock.orderCount,
        '-',
    ]);

    const analysis = data.data.newStockAnalysis.analysis;

    const sortedTotalStock = analysis.sort((a, b) => {
        const currencyOrder = { 'NGN': 1, 'USD': 2, 'GBP': 3, 'EUR': 4 };
        const currencyA = a._id;
        const currencyB = b._id;

        return currencyOrder[currencyA] - currencyOrder[currencyB] || a._id.localeCompare(b._id);
    }).map(obj => `${amountFormatterWithoutCurrency(obj.stockCount)}`);
    const sortedTotalCostPrices = analysis.sort((a, b) => {
        const currencyOrder = { 'NGN': 1, 'USD': 2, 'GBP': 3, 'EUR': 4 };
        const currencyA = a._id;
        const currencyB = b._id;

        return currencyOrder[currencyA] - currencyOrder[currencyB] || a._id.localeCompare(b._id);
    }).map(obj => `${amountFormatterWithoutCurrency(obj.totalCostPrice)}`);

    const sortedTotalSellingPrices = analysis.sort((a, b) => {
        const currencyOrder = { 'NGN': 1, 'USD': 2, 'GBP': 3, 'EUR': 4 };
        const currencyA = a._id;
        const currencyB = b._id;

        return currencyOrder[currencyA] - currencyOrder[currencyB] || a._id.localeCompare(b._id);
    }).map(obj => `${amountFormatterWithoutCurrency(obj.totalSellingPrice)}`);

    const sortedExpectedProfit = analysis.sort((a, b) => {
        const currencyOrder = { 'NGN': 1, 'USD': 2, 'GBP': 3, 'EUR': 4 };
        const currencyA = a._id;
        const currencyB = b._id;

        return currencyOrder[currencyA] - currencyOrder[currencyB] || a._id.localeCompare(b._id);
    }).map(obj => `${amountFormatterWithoutCurrency(obj.expectedProfit)}`);

    const currencyOrder = { 'NGN': 1, 'USD': 2, 'GBP': 3, 'EUR': 4 };
    const availableCurrency = analysis.sort((a, b) => currencyOrder[a._id] - currencyOrder[b._id]);
    const arrayOfSortedCurrency = [].concat(...availableCurrency.map(obj => obj._id));


    // Total product count

    const totalProductCount = [`${amountFormatterWithoutCurrency(data.data.newStockAnalysis.totalProduct)}`];

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([
        [],
        [`${data.tradingName.toUpperCase()} STOCK REPORT FROM ${data.startDate} - ${data.endDate}`],
        [],
        ['Top 5 Most Sold Product Type'],
        ['Name', 'Sold Quantites', 'Order Count', 'Amount'],
        ...top5MostSoldProducts,
        [],
        [],
        ['Top 5 Most Sold Stock (Variant)'],
        ['Name', 'Sold Quantites', 'Order Count', 'Amount'],
        ...top5MostSoldStocks,
        [],
        [],
        ["Least 5 Sold Stock (Variant)"],
        ["Name", "Sold Quantity", "Amount"],
        ...least5MostSoldStocks,
        [],
        [],
        ["Stock And Product Analysis"],
        ["Total Product Count", ...totalProductCount],
        [],
        ["", ...arrayOfSortedCurrency],
        ["Total Stock Count", ...sortedTotalStock],
        [],
        [],
        ['Financial Analysis'],
        ["", ...arrayOfSortedCurrency],
        ["Total Cost Price", ...sortedTotalCostPrices],
        ["Total Selling Price", ...sortedTotalSellingPrices],
        ["Total Expected Profit", ...sortedExpectedProfit],
        [],
    ]);

    //styling

    if (!ws['!merges']) ws['!merges'] = []; // Create the '!merges' property if it doesn't exist
    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });

    // create !cols array if it does not exist
    if (!ws["!cols"]) ws["!cols"] = [];

    //  create column metadata object if it does not exist
    if (!ws["!cols"][0]) ws["!cols"][0] = { wch: 8 };
    if (!ws["!cols"][1]) ws["!cols"][1] = { wch: 8 };
    if (!ws["!cols"][2]) ws["!cols"][2] = { wch: 8 };
    if (!ws["!cols"][3]) ws["!cols"][3] = { wch: 8 };
    if (!ws["!cols"][4]) ws["!cols"][4] = { wch: 8 };

    // set column width
    ws["!cols"][0].wpx = 200;
    ws["!cols"][1].wpx = 100;
    ws["!cols"][2].wpx = 100;
    ws["!cols"][3].wpx = 100;
    ws["!cols"][4].wpx = 100;

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, `${data.tradingName.toUpperCase()} STOCK REPORT FROM ${data.startDate} - ${data.endDate}.xlsx`);
};

export const transactionReportDL = (data) => {

    const targetCurrencyOrder = ["NGN", "USD", "GBP", "EUR"];

    const sortedAndSummedData = targetCurrencyOrder.map(targetCurrencyCode => {
        const currencyRecords = data.data.summaryAnalysis.find(data => data.currencyCode === targetCurrencyCode)?.records;
        if (currencyRecords) {
            const summedValues = currencyRecords.reduce((accumulator, record) => {
                const propertiesToSum = ["totalIncome", "totalExpense", "totalExpectedBalance", "totalAmountOwed", "totalVAT", "totalDiscount"];
                propertiesToSum.forEach(property => {
                    if (!accumulator[property]) {
                        accumulator[property] = 0;
                    }
                    accumulator[property] += record[property];
                });
                return accumulator;
            }, {});
            return Object.values(summedValues);
        }
        return [];
    });

    const filteredAndMappedData = targetCurrencyOrder.map(targetCurrencyCode => {
        const currencyData = data.data.expenseAnalysis.find(data => data.currencyCode === targetCurrencyCode);
        return currencyData ? currencyData.value : [];
    });

    const mappedArray = filteredAndMappedData.map(innerArray => {
        return innerArray.map(array => [array.name, `${amountFormatterWithoutCurrency(array.value)}`, array.percentage]);
    });

    const [ngnArray, usdArray, gbpArray, eurArray] = mappedArray;

    const elementIndex1 = 0;
    const elementIndex2 = 1;
    const elementIndex3 = 2;
    const elementIndex4 = 3;
    const elementIndex5 = 4;
    const elementIndex6 = 5;

    const totalIncomeArray = sortedAndSummedData.map(innerArray => innerArray[elementIndex1])
        .map(amount => amount.toLocaleString('en-US'));
    const totalExpenseArray = sortedAndSummedData.map(innerArray => innerArray[elementIndex2])
        .map(amount => amount.toLocaleString('en-US'));
    const totalExpectedBalArray = sortedAndSummedData.map(innerArray => innerArray[elementIndex3])
        .map(amount => amount.toLocaleString('en-US'));
    const totalAmountOwedArray = sortedAndSummedData.map(innerArray => innerArray[elementIndex4])
        .map(amount => amount.toLocaleString('en-US'));
    const totalVATArray = sortedAndSummedData.map(innerArray => innerArray[elementIndex5])
        .map(amount => amount.toLocaleString('en-US'));
    const totalDiscountArray = sortedAndSummedData.map(innerArray => innerArray[elementIndex6])
        .map(amount => amount.toLocaleString('en-US'));

    // MONEY IN AND OUT START

    const walletRecords = data.data.summaryAnalysis.flatMap(({ currencyCode, records }) =>
        records
            .filter(record => record.category === "WALLET")
            .map(record => [
                currencyCode,
                `${amountFormatterWithoutCurrency(record.totalIncome)}`,
                `${amountFormatterWithoutCurrency(record.totalExpense)}`,
            ])
    );

    const currencyOrder = ["NGN", "USD", "GBP", "EUR"];
    const sortedRecords = currencyOrder.map(currency =>
        walletRecords.find(record => record[0] === currency) || [currency, 0, 0]
    );

    // Transpose the arrays
    const transposedRecords = Array.from({ length: sortedRecords[0].length }, (_, i) =>
        sortedRecords.map(record => record[i])
    );
    const [, moneyInArray, moneyOutArray] = transposedRecords;

    // OPERATIONAL SUMARRY

    const filteredMappedData = targetCurrencyOrder.map(targetCurrencyCode => {
        const currencyData = data.data.inflowTypeAnalysis.find(data => data.currencyCode === targetCurrencyCode);
        return currencyData ? currencyData.data : [];
    });

    const resultArray = filteredMappedData.map(innerArray => {
        return innerArray.map(array => [
            array._id.type,
            `${amountFormatterWithoutCurrency(array.totalPaid)}`,
            array.count.toLocaleString('en-US')
        ]);
    });

    const [ngnResult, usdResult, gbpResult, eurResult] = resultArray;

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([
        [],
        [`${data.tradingName.toUpperCase()} FINANCIAL TRANSACTION REPORT FROM ${data.startDate} - ${data.endDate}`],
        [],
        [" Wallet Transactions", "Multicurrency"],
        ["", "NGN", "USD", "GBP", "EUR"],
        // ["Total Inflow", ...totalIncomeArray],
        // ["Total Outflow", ...totalExpenseArray],
        // ["Total Expected Balance", ...totalExpectedBalArray],
        // ["Total Debt", ...totalAmountOwedArray],
        // ["Total VAT", ...totalVATArray],
        // ["Total Discount", ...totalDiscountArray],
        ["Wallet-Money-In", ...moneyInArray],
        ["Wallet-Money-Out", ...moneyOutArray],
        [],
        [],
        ["Operating Expenses (NGN)"],
        [],
        ["Expense Category", "Amount", "Percentage"],
        ...ngnArray,
        [],
        [],
        ["Operating Expenses (USD)"],
        [],
        ["Expense Category", "Amount", "Percentage"],
        ...usdArray,
        [],
        [],
        ["Operating Expenses (GBP)"],
        [],
        ["Expense Category", "Amount", "Percentage"],
        ...gbpArray,
        [],
        [],
        ["Operating Expenses (EUR)"],
        [],
        ["Expense Category", "Amount", "Percentage"],
        ...eurArray,
        [],
        [],
        ["OPERATIONAL SUMMARY FOR NGN"],
        [],
        ["Activity", "Amount", "Count"],
        ...ngnResult,
        [],
        [],
        ["OPERATIONAL SUMMARY FOR USD"],
        [],
        ["Activity", "Amount", "Count"],
        ...usdResult,
        [],
        [],
        ["OPERATIONAL SUMMARY FOR GBP"],
        [],
        ["Activity", "Amount", "Count"],
        ...gbpResult,
        [],
        [],
        ["OPERATIONAL SUMMARY FOR EUR"],
        [],
        ["Activity", "Amount", "Count"],
        ...eurResult,
    ]);

    //styling

    if (!ws['!merges']) ws['!merges'] = []; // Create the '!merges' property if it doesn't exist
    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });

    // create !cols array if it does not exist
    if (!ws["!cols"]) ws["!cols"] = [];

    //  create column metadata object if it does not exist
    if (!ws["!cols"][0]) ws["!cols"][0] = { wch: 8 };
    if (!ws["!cols"][1]) ws["!cols"][1] = { wch: 8 };
    if (!ws["!cols"][2]) ws["!cols"][2] = { wch: 8 };
    if (!ws["!cols"][3]) ws["!cols"][3] = { wch: 8 };
    if (!ws["!cols"][4]) ws["!cols"][4] = { wch: 8 };

    // set column width
    ws["!cols"][0].wpx = 200;
    ws["!cols"][1].wpx = 100;
    ws["!cols"][2].wpx = 100;
    ws["!cols"][3].wpx = 100;
    ws["!cols"][4].wpx = 100;

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, `${data.tradingName.toUpperCase()} FINANCIAL TRANSACTION REPORT FROM ${data.startDate} - ${data.endDate}.xlsx`);
};

export const walletReportDL = (data) => {

    const walletData = data.data.walletBalanceAnalysis[0].pettyCash;;

    const flattenedData = walletData.map(item => {
        const balance = parseFloat(item.balance.replace("NGN", "").replace(",", ""));
        const limit = parseFloat(item.limit.replace("NGN", "").replace(",", ""));
        const amountSpent = limit - balance;

        return {
            ...item,
            amountSpent: `NGN ${amountSpent}`,
        };
    });

    const mappedflattenedData = flattenedData.map(item => {
        return [
            item.name,
            item.balance,
            item.limit,
            item.amountSpent,
            item.limitPeriod,
        ];
    })

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([
        [],
        [`${data.tradingName.toUpperCase()} PETTY CASH REPORT FROM ${data.startDate} - ${data.endDate}`],
        [],
        [],
        ["PETTY CASH REPORT"],
        [],
        ["Wallet Name", "Wallet Balance", "Limit", "Amount Spent", "Limit period"],
        ...mappedflattenedData,
    ]);

    //styling

    if (!ws['!merges']) ws['!merges'] = []; // Create the '!merges' property if it doesn't exist
    ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });

    // create !cols array if it does not exist
    if (!ws["!cols"]) ws["!cols"] = [];

    //  create column metadata object if it does not exist
    if (!ws["!cols"][0]) ws["!cols"][0] = { wch: 8 };
    if (!ws["!cols"][1]) ws["!cols"][1] = { wch: 8 };
    if (!ws["!cols"][2]) ws["!cols"][2] = { wch: 8 };
    if (!ws["!cols"][3]) ws["!cols"][3] = { wch: 8 };
    if (!ws["!cols"][4]) ws["!cols"][4] = { wch: 8 };
    if (!ws["!cols"][5]) ws["!cols"][5] = { wch: 8 };

    // set column width
    ws["!cols"][0].wpx = 200;
    ws["!cols"][1].wpx = 100;
    ws["!cols"][2].wpx = 100;
    ws["!cols"][3].wpx = 100;
    ws["!cols"][4].wpx = 100;
    ws["!cols"][5].wpx = 100;

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, `${data.tradingName.toUpperCase()} PETTY CASH REPORT FROM ${data.startDate} - ${data.endDate}.xlsx`);
};