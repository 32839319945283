import { MimaButton, MimaDropdown, MimaInput } from "../../../components";
import styles from "../../../assets/styles/Login/Login.module.scss";
import { useStores } from "../../../stores";
import React, { useState } from "react";
import { Formik } from "formik";

const SavedBeneficiary = ({
  onSubmitSavedBeneficiary,
  validationSchemaSavedBeneficiary,
  beneficiaryList,
  selectedState,
}) => {
  const {
    appStore: { expenseCategories },
  } = useStores();
  const [beneficiaryMessage, setBeneficiaryMessage] = useState("");
  return (
    <Formik
      initialValues={{
        transactionAmount: 0,
        beneficiary: selectedState.data,
        narration: "",
        expenseCategory: "",
      }}
      validationSchema={(data) => {
        if (selectedState.value === "-Select-")
          setBeneficiaryMessage("Beneficiary is Required");
        return validationSchemaSavedBeneficiary(data);
      }}
      onSubmit={onSubmitSavedBeneficiary}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <div>
          <MimaButton
            variant="outlined"
            id="beneficiary"
            name="beneficiary"
            title={selectedState.value}
            labelTitle="Choose Beneficiary"
            width={38}
            height={5}
            mb={4}
            onClick={beneficiaryList}
          />

          {selectedState.value === "-Select-" ? (
            <div className={styles.error}>{beneficiaryMessage}</div>
          ) : null}
          <MimaInput
            type="number"
            labelTitle="Amount"
            placeholder="Enter Amount"
            name="transactionAmount"
            mt={2}
            mb={2}
            id="transactionAmount"
            value={values.transactionAmount}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.transactionAmount}
            touched={touched.transactionAmount}
          />
          <MimaDropdown
            labelTitle="Expense Categories"
            placeholder="-Select-"
            data={expenseCategories}
            id="expenseCategory"
            onChange={(data) => {
              setFieldValue("expenseCategory", data.key);
            }}
            name="expenseCategory"
            onBlur={handleBlur}
            error={errors.expenseCategory}
            touched={touched.expenseCategory}
          />
          <MimaInput
            labelTitle="Description"
            placeholder="Describe this expense"
            name="narration"
            mt={1}
            mb={3}
            id="narration"
            value={values.narration}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.narration}
            touched={touched.narration}
          />
          <MimaButton
            title="Continue"
            onClick={handleSubmit}
            type="submit"
            variant="form"
          />
        </div>
      )}
    </Formik>
  );
};

export default SavedBeneficiary;
