import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
export default class MeasurementStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "MeasurementStore",
      properties: ["selectedMeasurement"],
    });
  }

  selectedMeasurement = {};

  reset = () => {
    this.selectedMeasurement = {};
  };

  setSelectedMeasurement = async (state) => {
    runInAction(async () => {
      this.selectedMeasurement = state;
    });
  };
}
