import { useNavigate } from "react-router-dom";
import React from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import styles from "../../assets/styles/AppHome/QuickLinksCard.module.scss";
import { QuickLinksComponent as QLC } from "../../components/Home";
import constant from "../../utils/constant";
import { businessStore, userStore } from "../../stores";
import { MimaText, MimaToastUtil } from "../../components";

const QuickLinksCard = ({ goToMeasurement }) => {
  const navigate = useNavigate();
  const isOnboardingDone = (path) => {
    if (userStore.isOnBoardingDone === "false") {
      MimaToastUtil.error({
        message: constant.OnboardingRequired,
      });
      localStorage.setItem(constant.RoutePath, constant.Routes.Invoice);
      return navigate(constant.Routes.OnboardingStageOne);
    }
    return navigate(path);
  };

  const websiteLink = businessStore.business.storeFrontUrl;
  const publicCatalogueUrl = businessStore.business.storeFrontUrl;

  return (
    <div className={styles.ql}>
      {websiteLink && (
        <div className={styles.reference}>
        <div className={styles.reference__link}>
          <MimaText mb={0.5} color="var(--color-white)">
            Website Link:
          </MimaText>
          <div
            onClick={() => {
              navigator.clipboard.writeText(websiteLink);
              MimaToastUtil.success({
                message: "Copied website link",
              });
              window.open(websiteLink, '_blank'); // Open the link in a new tab
            }}
            className={styles.reference__copy}
          >
            <span>{websiteLink}</span>
            <MdOutlineContentCopy className={styles.reference__copy__icon} />
          </div>
        </div>
      </div>
      
      )}
      <h4 className={styles.ql__title}>Quick Links</h4>
      <nav className={styles.ql__content}>
        <QLC
          title="Upload Style"
          onClick={() => navigate(constant.Routes.Styles)}
        />
        <QLC
          title="Add Customer"
          onClick={() => navigate(constant.Routes.Customer)}
        />
        <QLC
          title="Configure Website"
          onClick={() => navigate(constant.Routes.WebsiteConfig)}
        />

        <QLC title="Orders" onClick={() => navigate(constant.Routes.Orders)} />

        <QLC title="Measurement" onClick={goToMeasurement} />
        <QLC
          title="Payment Link"
          onClick={() => navigate(constant.Routes.PaymentLink)}
        />
        <QLC
          title="Transactions"
          onClick={() => navigate(constant.Routes.Transactions)}
        />
      </nav>
    </div>
  );
};

export default QuickLinksCard;
