import { apiRequest } from "../utils/useAPIRequest";
import constant from "../utils/constant";
import { businessStore } from "../stores";
import moment from "moment";
import {
  billsPaymentReportDL,
  customerReportDL2,
  inventoryReportDL,
  invoiceReportDL,
  payrollReportDL,
  transactionReportDL,
  walletReportDL,
} from "../utils/createReportUtil";
import { splitPaymentReportDL } from "../utils/createReportUtil";

const getBillsReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage, data } = await apiRequest({
    url: `/bills/bill-report-by-date?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    const reportData = {
      tradingName: businessStore.business.tradingName,
      startDate: moment(payload.startDate).format("DD MMM YYYY"),
      endDate: moment(payload.endDate).format("DD MMM YYYY"),
      ...data.analysis,
    };
    billsPaymentReportDL(reportData);
  }
  return { status, errorMessage };
};

const getBillsReportByMonth = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;
  const { status, errorMessage, data } = await apiRequest({
    url: `/bills/bill-report-by-month?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
  }
  return { status, errorMessage };
};

const getCustomerReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage, data } = await apiRequest({
    url: `/customers/customer-report-by-date?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    const reportData = {
      tradingName: businessStore.business.tradingName,
      startDate: moment(payload.startDate).format("DD MMM YYYY"),
      endDate: moment(payload.endDate).format("DD MMM YYYY"),
      ...data,
    };
    customerReportDL2(reportData);
  }
  return { status, errorMessage };
};

const getCustomerReportByMonth = async (query) => {
  if (!query) {
    query = "";
  }
  const { status, errorMessage, data } = await apiRequest({
    url: `/customers/customer-report-by-month?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
  }
  return { status, errorMessage };
};

const getInvoiceReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage, data } = await apiRequest({
    url: `/invoices/invoice-report-by-date?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    const reportData = {
      tradingName: businessStore.business.tradingName,
      startDate: moment(payload.startDate).format("DD MMM YYYY"),
      endDate: moment(payload.endDate).format("DD MMM YYYY"),
      ...data.analysis,
    };

    invoiceReportDL(reportData);
  }
  return { status, errorMessage };
};

const getInvoiceReportByMonth = async (query) => {
  if (!query) {
    query = "";
  }
  const { status, errorMessage, data } = await apiRequest({
    url: `/invoices/invoice-report-by-month?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
  }
  return { status, errorMessage };
};

const getPayrollReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage, data } = await apiRequest({
    url: `/payrolls/payroll-report-by-date?${query}`,
    method: "get",
  });

  if (status === constant.Success) {
    const reportData = {
      tradingName: businessStore.business.tradingName,
      startDate: moment(payload.startDate).format("DD MMM YYYY"),
      endDate: moment(payload.endDate).format("DD MMM YYYY"),
      data: data,
    };
    payrollReportDL(reportData);
  }

  return { status, errorMessage };
};

const getPayrollReportByMonth = async (query) => {
  if (!query) {
    query = "";
  }
  const { status, errorMessage, data } = await apiRequest({
    url: `/payrolls/payroll-report-by-month?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
  }
  return { status, errorMessage };
};

const getSplitPaymentReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage, data } = await apiRequest({
    url: `/transactions/split-report-by-date?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    const reportData = {
      tradingName: businessStore.business.tradingName,
      startDate: moment(payload.startDate).format("DD MMM YYYY"),
      endDate: moment(payload.endDate).format("DD MMM YYYY"),
      data: data.splitAnalysis,
    };

    splitPaymentReportDL(reportData);
  }
  return { status, errorMessage };
};

const getSplitPaymentReportByMonth = async (query) => {
  if (!query) {
    query = "";
  }
  const { status, errorMessage, data } = await apiRequest({
    url: `/transactions/split-report-by-month?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
  }
  return { status, errorMessage };
};

const getTransactionReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage, data } = await apiRequest({
    url: `/transactions/transaction-report-by-date?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    const reportData = {
      tradingName: businessStore.business.tradingName,
      startDate: moment(payload.startDate).format("DD MMM YYYY"),
      endDate: moment(payload.endDate).format("DD MMM YYYY"),
      data: data,
    };

    transactionReportDL(reportData);
  }
  return { status, errorMessage };
};

const getTransactionReportByMonth = async (query) => {
  if (!query) {
    query = "";
  }
  const { status, errorMessage, data } = await apiRequest({
    url: `/transactions/transaction-report-by-month?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
  }
  return { status, errorMessage };
};

const getInventoryReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage, data } = await apiRequest({
    url: `/products/inventory-report-by-date?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    const reportData = {
      tradingName: businessStore.business.tradingName,
      startDate: moment(payload.startDate).format("DD MMM YYYY"),
      endDate: moment(payload.endDate).format("DD MMM YYYY"),
      data: data,
    };

    inventoryReportDL(reportData);
  }
  return { status, errorMessage };
};

const getWalletReportByDate = async (payload) => {
  const query = `startDate=${payload.startDate}&endDate=${payload.endDate}`;

  const { status, errorMessage, data } = await apiRequest({
    url: `/wallets/wallet-report-by-date?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    const reportData = {
      tradingName: businessStore.business.tradingName,
      startDate: moment(payload.startDate).format("DD MMM YYYY"),
      endDate: moment(payload.endDate).format("DD MMM YYYY"),
      data: data,
    };
    walletReportDL(reportData);
  }
  return { status, errorMessage };
};

const ReportsController = {
  getBillsReportByDate,
  getBillsReportByMonth,
  getCustomerReportByDate,
  getCustomerReportByMonth,
  getInvoiceReportByDate,
  getInvoiceReportByMonth,
  getPayrollReportByDate,
  getPayrollReportByMonth,
  getSplitPaymentReportByDate,
  getSplitPaymentReportByMonth,
  getTransactionReportByDate,
  getTransactionReportByMonth,
  getInventoryReportByDate,
  getWalletReportByDate,
};

export default ReportsController;
