import React from "react";
import styles from "../../assets/styles/Auth/AuthOnboarding.module.scss";
import useUtitlityRoutes from "../../hooks/useUtitlityRoutes";
import { FashWhiteLogo } from "../../assets/img/ImgList";
import { MimaText } from "../../components";

const AuthOnboardingShell = ({ children }) => {
  const { switchToLanding } = useUtitlityRoutes();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={FashWhiteLogo}
          className={styles.fashIcon}
          alt="Fash Logo"
          onClick={switchToLanding}
        />
        <div className={styles.welcome}>
          <MimaText variant="h3" align="center">
            Unleash your creativity, Sell with ease.
          </MimaText>
        </div>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default AuthOnboardingShell;
