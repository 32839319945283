import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class BookingStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'BookingStore',
			properties: [
				'allBookings',
				'selectedBooking',
				'allBookingLinks',
				'selectedBookingLink',
				'bookingLinkDetails',
				'bookingLinkUnavailableDates'
			],
		});
	}

	allBookings = [];
	allBookingLinks = [];
	selectedBooking = {};
	selectedBookingLink = {};
	bookingLinkDetails = {};
	bookingLinkUnavailableDates = {};

	reset = () => {
		this.allBookings = [];
		this.allBookingLinks = [];
		this.selectedBooking = {};
		this.selectedBookingLink = {};
		this.bookingLinkDetails = {};
		this.bookingLinkUnavailableDates = {};
	};


	setAllBookings = async state => {
		runInAction(async () => {
			this.allBookings = state;
		});
	};
	setAllBookingLinks = async state => {
		runInAction(async () => {
			this.allBookingLinks = state;
		});
	};

	setSelectedBooking = async state => {
		runInAction(async () => {
			this.selectedBooking = state;
		});
	};

	setSelectedBookingLink = async state => {
		runInAction(async () => {
			this.selectedBookingLink = state;
		});
	};

	setBookingLinkUnavailableDates = async state => {
		runInAction(async () => {
			this.bookingLinkUnavailableDates = state;
		});
	};

	setBookingLinkDetails = async state => {
		runInAction(async () => {
			this.bookingLinkDetails = state;
		});
	};
}
