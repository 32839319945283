import React, { useState } from "react";
import { InvoiceController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { invoiceStore } from "../../../stores";

const useInvoiceSummaryLogic = ({ closeModal }) => {
  const [editInvoice, setEditInvoice] = useState(false);
  const [updateInvoice, setUpdateInvoice] = useState(false);

  const goToEditInvoice = () => {
    setEditInvoice(true);
  };
  const goToUpdateInvoice = () => {
    setUpdateInvoice(true);
  };

  const invoice = invoiceStore.selectedInvoice;
  const invoiceItem = invoice.status === constant.TRANSACTION_STATUS.UNPAID;

  const sendInvoice = async (id) => {
    const { status, errorMessage } = await InvoiceController.sendInvoice(id);
    if (status === constant.Success) {
      return MimaToastUtil.success({
        message: constant.Sent,
      });
    }
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };
  const onDownload = async (invoiceId) => {
    const { errorMessage } = await InvoiceController.download(invoiceId);
    if (errorMessage) {
      MimaToastUtil.error({
        message: errorMessage,
      });
    } else {
      MimaToastUtil.success({
        message: constant.Success,
      });
    }
  };
  const updatedCloseModal = () => {
    closeModal();
    setEditInvoice(false);
    setUpdateInvoice(false);
  };

  return {
    editInvoice,
    goToEditInvoice,
    sendInvoice,
    onDownload,
    closeModal,
    updateInvoice,
    goToUpdateInvoice,
    invoiceItem,
    updatedCloseModal,
  };
};

export default useInvoiceSummaryLogic;
