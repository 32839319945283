import {
  MimaDateFilter,
  MimaDropdownFilter,
  MimaFilter,
  MimaText,
  PageLoader,
  MimaTable,
  AppShell,
  MimaOptionMenu,
} from "../../components";
import UpdateOrders from "./UpdateOrders";
import ViewOrders from "./ViewOrders";
import { BusinessInsightComponent as BIC } from "../../components/Home";
import styles from "../../assets/styles/Orders/Orders.module.scss";
import flutter_wave from "../../assets/img/flucter_wave.svg";
import instagram from "../../assets/img/instagram.svg";
import whatsapp from "../../assets/img/whatsapp.svg";
import facebook from "../../assets/img/facebook.svg";
import paystack from "../../assets/img/paystack.svg";
import snapchat from "../../assets/img/snapchat.svg";
import twitter from "../../assets/img/twitter.svg";
import { BsFillPeopleFill } from "react-icons/bs";
import { AnimatePresence } from "framer-motion";
import jumia from "../../assets/img/jumia.svg";
import konga from "../../assets/img/konga.svg";
import jiji from "../../assets/img/jiji.svg";
import React, { useMemo } from "react";
import { Formik } from "formik";
import moment from "moment";
import constant from "../../utils/constant";
import { useOrdersLogic } from ".";
import { ordersStore } from "../../stores";

const Orders = () => {
  const {
    loading,
    validationSchema,
    onSubmit,
    setOnReset,
    resetFilter,
    onReset,
    ordersAnalysisList,
    filterPeriod,
    updateModal,
    setUpdateModal,
    closeModal,
    viewOrdersModal,
    setViewOrdersModal,
    getDate,
    filterPeriods,
    isLoading,
    orderData,
    isFetching,
    limit,
    onLimitChange,
    currentPage,
    setCurrentPage,
    setSearchQuery,
  } = useOrdersLogic();

  const imgStyle = { width: "3rem" };

  const tableData = useMemo(() => {
    if (orderData?.status === constant.Success) {
      return orderData?.data?.groups || [];
    }
    return [];
  }, [orderData]);

  const tableColumns = [
    {
      Header: "Order ID",
      accessor: "number",
      Cell: ({ cell }) => {
        const number = cell.row.original?.invoice?.number;
        return <span>{`ORD-${number}`}</span>;
      },
    },
    {
      Header: "Order Date",
      accessor: "createdAt",
      Cell: ({ cell }) => {
        const orderDate = cell.row.original.createdAt;
        const formattedDate = moment(orderDate).format("DD/MM/YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      Header: "Customer Name",
      accessor: "fullname",
      Cell: ({ cell }) => {
        const fullname = cell.row.original.invoice?.customer?.fullname;
        return <span>{fullname}</span>;
      },
    },
    {
      Header: "Items",
      Cell: ({ cell }) => {
        const items = cell.row.original.invoice?.orders?.length;
        return <span>{items}</span>;
      },
    },
    {
      Header: "Sales Channel",
      Cell: ({ cell }) => {
        const salesChannel = cell.row.original.invoice?.salesChannel;
        return (
          <span>
            {salesChannel === "twitter" ? (
              <img src={twitter} alt="Twitter" style={imgStyle} />
            ) : salesChannel === "Instagram" ? (
              <img src={instagram} alt="Instagram" style={imgStyle} />
            ) : salesChannel === "WhatsApp" ? (
              <img src={whatsapp} alt="Whatsapp" style={imgStyle} />
            ) : salesChannel === "Facebook" ? (
              <img src={facebook} alt="Facebook" style={imgStyle} />
            ) : salesChannel === "Jiji" ? (
              <img src={jiji} alt="Jiji" style={imgStyle} />
            ) : salesChannel === "Jumia" ? (
              <img src={jumia} alt="Jumia" style={imgStyle} />
            ) : salesChannel === "Paystack" ? (
              <img src={paystack} alt="Paystack" style={imgStyle} />
            ) : salesChannel === "Flutterwave" ? (
              <img src={flutter_wave} alt="Flutterwave" style={imgStyle} />
            ) : salesChannel === "Konga" ? (
              <img src={konga} alt="Konga" style={imgStyle} />
            ) : salesChannel === "Snapchat" ? (
              <img src={snapchat} alt="Snapchat" style={imgStyle} />
            ) : salesChannel === "Physical sale" ? (
              <BsFillPeopleFill style={{ fontSize: "2.5rem" }} />
            ) : (
              salesChannel
            )}
          </span>
        );
      },
    },
    {
      Header: "Status",
      Cell: ({ cell }) => {
        const trackingStatus = cell.row.original.trackingStatus;
        return (
          <span
            className={
              trackingStatus === "DELIVERED"
                ? styles.delivered
                : trackingStatus === "PROCESSING"
                  ? styles.processing
                  : trackingStatus === "REFUNDED"
                    ? styles.refunded
                    : trackingStatus === "SHIPPED"
                      ? styles.shipped
                      : styles.void
            }
          >
            {trackingStatus}
          </span>
        );
      },
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        const trackingStatus = cell.row.original.trackingStatus;
        const item = cell.row.original;
        return trackingStatus !== "VOID" ? (
          <MimaOptionMenu
            options={[
              {
                value: "View more",
                label: "View more",
              },
              {
                value: "Update Order status",
                label: "Update Order status",
              },
            ]}
            onOptionSelected={(option) => {
              ordersStore.setSingleItem(item);
              if (option.value === "View more") {
                setViewOrdersModal(true);
              } else if (option.value === "Update Order status") {
                setUpdateModal(true);
              }
            }}
          />
        ) : null;
      },
    },
  ];

  return (
    <AppShell pageTitle="Orders">
      <MimaText variant="subtitleBold" align="center" mb={2} mt={2}>
        You are Viewing Orders Analysis For {filterPeriod}
      </MimaText>

      {loading ? (
        <PageLoader title="Refreshing record..." loading={isLoading} />
      ) : (
        <>
          <Formik
            initialValues={{
              startDate: "",
              endDate: "",
              trackingStatus: "",
              period: "",
              periodText: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <MimaFilter
                filterClick={() => {
                  setOnReset(false);
                  handleSubmit();
                }}
                loading={loading}
                resetClick={() => {
                  setOnReset(true);
                  resetFilter();
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <MimaDropdownFilter
                    labelTitle="Tracking Status"
                    name="trackingStatus"
                    value={values.trackingStatus}
                    id="trackingStatus"
                    data={[
                      { key: "DELIVERED", value: "DELIVERED" },
                      { key: "PROCESSING", value: "PROCESSING" },
                      { key: "REFUNDED", value: "REFUNDED" },
                      { key: "SHIPPED", value: "SHIPPED" },
                      { key: "VOID", value: "VOID" },
                    ]}
                    onChange={(data) => {
                      setFieldValue("trackingStatus", data.key);
                    }}
                    placeholder="Select Status"
                    currentValue={values.trackingStatus}
                    touched={touched.trackingStatus}
                    error={errors.trackingStatus}
                  />

                  <MimaDropdownFilter
                    labelTitle="Filter period"
                    placeholder="Select Period"
                    variant="medium"
                    value={values.period}
                    name="period"
                    data={filterPeriods}
                    onChange={(data) => {
                      const dates = getDate(data.key);
                      setFieldValue("period", data.key);
                      setFieldValue("startDate", dates.startDate);
                      setFieldValue("endDate", dates.endDate);
                      setFieldValue("periodText", data.value);
                    }}
                    error={errors.period}
                    touched={touched.period}
                  />
                </div>

                {values.period === "custom" && (
                  <>
                    <MimaDateFilter
                      labelTitle="start date"
                      name="startDate"
                      value={!onReset ? values.startDate : ""}
                      onChange={(text) => {
                        setOnReset(false);
                        setFieldValue("startDate", text);
                      }}
                      onBlur={handleBlur}
                      width={18}
                      touched={touched.startDate}
                      error={errors.startDate}
                    />
                    <MimaDateFilter
                      labelTitle="End date"
                      name="endDate"
                      value={!onReset ? values.endDate : ""}
                      onChange={(text) => {
                        setOnReset(false);
                        setFieldValue("endDate", text);
                      }}
                      onBlur={handleBlur}
                      width={18}
                      touched={touched.endDate}
                      error={errors.endDate}
                    />
                  </>
                )}
              </MimaFilter>
            )}
          </Formik>
          <div className={styles.upper_container}>
            <MimaText variant="subtitleBold" mb={2}>
              Order Analysis
            </MimaText>
            <div className={styles.upper__metrics}>
              {ordersAnalysisList.length > 0 ? (
                ordersAnalysisList?.map((x, i) => {
                  return (
                    <div key={i}>
                      <BIC
                        title={`TOTAL ${x?.trackingStatus}`}
                        amount={x?.total}
                        styleClass={
                          x?.trackingStatus === "PROCESSING"
                            ? styles.upper__metrics_processing
                            : x?.trackingStatus === "DELIVERED"
                              ? styles.upper__metrics_completed
                              : x?.trackingStatus === "SHIPPED"
                                ? styles.upper__metrics_shipped
                                : x?.trackingStatus === "REFUNDED"
                                  ? styles.upper__metrics_refund
                                  : styles.upper__metrics_void
                        }
                      />
                    </div>
                  );
                })
              ) : (
                <MimaText> You have no orders yet</MimaText>
              )}
            </div>
          </div>

          <MimaTable
            tableData={tableData}
            tableColumns={tableColumns}
            searchPlaceholder="Search Orders"
            searchVariant="wide"
            totalItems={orderData?.data?.totalCounts}
            onLimitChange={onLimitChange}
            isLoading={isLoading}
            isFetching={isFetching}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setSearchQuery={setSearchQuery}
          />

          {(updateModal || viewOrdersModal) && (
            <div className="modal">
              <AnimatePresence>
                {updateModal && <UpdateOrders closeModal={closeModal} />}
                {viewOrdersModal && <ViewOrders closeModal={closeModal} />}
              </AnimatePresence>
            </div>
          )}
        </>
      )}
    </AppShell>
  );
};
export default Orders;
