import { apiRequest } from "../utils/useAPIRequest";
import constant from "../utils/constant";
import { billStore, businessStore } from "../stores";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

export const GET_BILL_LIST = "get-bill-list";
export const GET_AIRTIME_DATA_VENDOR = "get-airtime-data-vendor";
export const GET_DATA_PLANS = "get-data-plans";
export const GET_CABLE_TV_VENDOR = "get-cable-tv-vendor";
export const GET_CABLE_PLANS = "get-cable-plans";
export const GET_POWER_VENDOR = "get-power-vendor";

export const useGetAirtimeDataVendors = () => {
  const response = useQuery({
    queryKey: [businessStore.bId, GET_AIRTIME_DATA_VENDOR],
    queryFn: () => {
      return apiRequest({
        url: "/bills/club-konnect/network",
        method: "get",
      });
    },
  });
  const networkVendors = useMemo(() => {
    if (response.data?.status === constant.Success) {
      const { data = [] } = response.data || {};
      return data;
    }
    return [];
  }, [response.data]);
  return { ...response, networkVendors };
};

export const useGetCableTvVendors = () => {
  const response = useQuery({
    queryKey: [businessStore.bId, GET_CABLE_TV_VENDOR],
    queryFn: () => {
      return apiRequest({
        url: "/bills/tv-vendors",
        method: "get",
      });
    },
  });

  const tvVendors = useMemo(() => {
    if (response.data?.status === constant.Success) {
      const { data = [] } = response.data || {};
      return data;
    }
    return [];
  }, [response.data]);
  return { ...response, tvVendors };
};

export const useGetPowerVendors = () => {
  const response = useQuery({
    queryKey: [businessStore.bId, GET_POWER_VENDOR],
    queryFn: () => {
      return apiRequest({
        url: "/bills/power-vendors",
        method: "get",
      });
    },
  });

  const powerVendors = useMemo(() => {
    if (response.data?.status === constant.Success) {
      const { data = [] } = response.data || {};
      return data;
    }
    return [];
  }, [response.data]);
  return { ...response, powerVendors };
};

const buyAirtime = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/bills/pay-or-boro/buy-airtime",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    const process = () =>
      new Promise((resolve) =>
        setTimeout(async () => {
          await getBills();
          resolve({ status });
        }, 4000)
      );
    return await process();
  }
  return { status, errorMessage };
};

const getNetworks = async () => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/bills/club-konnect/network",
    method: "get",
  });
  if (status === constant.Success) {
    const vendors = data.map((network) => {
      return { id: network.id, value: network.carrier };
    });
    billStore.setAirtimeVendors(vendors);
    for (let index = 0; index < data.length; index++) {
      await getDataPlanByNetworkId(data[index].id);
    }
  }
  return { status, errorMessage };
};

const getBills = async (query) => {
  if (!query) {
    query = "limit=50";
  }
  const { status, errorMessage, data } = await apiRequest({
    url: `/bills/business?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    billStore.setBills(data.bills);
  }
  return { status, errorMessage };
};

const getBillById = async (id) =>
  await apiRequest({ url: `/bills/user/bills/bill/${id}`, method: "get" });

const getDataPlanByNetworkId = async (productId) => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/bills/pay-or-boro/get-data-plans/${productId}`,
    method: "get",
  });
  if (status === constant.Success) {
    const plans = [];
    data.forEach((obj) => plans.push({ id: obj._id, value: obj.plan }));
    localStorage.setItem(`data-${productId}-plans`, JSON.stringify(plans));
  }
  return { status, errorMessage };
};

const verifyMeterNumber = async (meterNumber, categoryId) =>
  await apiRequest({
    url: `/bills/verify-meter-number/${meterNumber}/${categoryId}`,
    method: "get",
  });

const getTvPackage = async (cableTVCode, smartCardNo) => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/bills/pay-or-boro/get-cable-plans/${cableTVCode}/${smartCardNo}`,
    method: "get",
  });
  if (status === constant.Success) {
    const packages = [];
    data.forEach((obj) => packages.push({ id: obj._id, value: obj.name }));
    localStorage.setItem(`${cableTVCode}-packages`, JSON.stringify(packages));
  }
  return { status, errorMessage };
};

const verifySmartCardNumber = async (cableTVCode, smartCardNo) =>
  await apiRequest({
    url: `/bills/verify-tv-smartcard-customer/${cableTVCode}/${smartCardNo}`,
    method: "get",
  });

const getMeterToken = async (bill) =>
  await apiRequest({
    url: `/bills/get-meter-token/bill/${bill}`,
    method: "get",
  });

const buyData = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/bills/pay-or-boro/buy-data",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    const process = () =>
      new Promise((resolve) =>
        setTimeout(async () => {
          await getBills();
          resolve({ status });
        }, 3000)
      );
    return await process();
  }

  return { status, errorMessage };
};

const buyPower = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/bills/pay-or-boro/buy-power",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    const process = () =>
      new Promise((resolve) =>
        setTimeout(async () => {
          await getBills();
          resolve({ status });
        }, 4000)
      );
    return await process();
  }
  return { status, errorMessage };
};

const subscribeTv = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/bills/pay-or-boro/buy-cable",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    const process = () =>
      new Promise((resolve) =>
        setTimeout(async () => {
          await getBills();
          resolve({ status });
        }, 4000)
      );
    return await process();
  }
  return { status, errorMessage };
};

const refreshBills = async (billId) => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/bills/bill-requery/${billId}`,
    method: "get",
  });

  if (status === constant.Success) {
    await getBills();
    return { status: constant.Success };
  }

  return { status, errorMessage };
};

export const useGetDataPlanByNetworkId = (networkId) => {
  const response = useQuery({
    queryKey: [businessStore.bId, GET_DATA_PLANS, networkId],
    queryFn: () => {
      return apiRequest({
        url: `/bills/pay-or-boro/get-data-plans/${networkId}`,
        method: "get",
      });
    },
    enabled: !!networkId,
  });

  const dataPlans = useMemo(() => {
    if (response.data?.status === constant.Success) {
      const { data = [] } = response.data || {};
      return data;
    }

    return [];
  }, [response.data]);

  return { ...response, dataPlans };
};

export const useGetCableTvPlans = (cableTVCode, smartCardNo) => {
  const response = useQuery({
    queryKey: [businessStore.bId, GET_CABLE_PLANS, cableTVCode, smartCardNo],
    queryFn: () => {
      return apiRequest({
        url: `/bills/pay-or-boro/get-cable-plans/${cableTVCode}/${smartCardNo}`,
        method: "get",
      });
    },
    enabled: !!(cableTVCode && smartCardNo),
  });

  const cableTvPlans = useMemo(() => {
    if (response.data?.status === constant.Success) {
      const { data = [] } = response.data || {};
      return data;
    }

    return [];
  }, [response.data]);

  return { ...response, cableTvPlans };
};

export default {
  subscribeTv,
  getBillById,
  buyData,
  buyPower,
  getNetworks,
  verifyMeterNumber,
  getDataPlanByNetworkId,
  getMeterToken,
  buyAirtime,
  getTvPackage,
  getBills,
  verifySmartCardNumber,
  refreshBills,
};
