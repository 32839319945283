import React, { useMemo } from "react";
import { AppShell, MimaTable } from "../../components";
import moment from "moment";
import constant from "../../utils/constant";
import useNewsLetterLogic from "./useLogic/useNewsLetterLogic";

const NewsLetter = () => {
  const {
    isLoading,
    data,
    isFetching,
    onLimitChange,
    limit,
    currentPage,
    setCurrentPage,
    setSearchQuery,
  } = useNewsLetterLogic();

  const tableData = useMemo(() => {
    if (data?.status === constant.Success) {
      return data?.data?.requests || [];
    }
    return [];
  }, [data]);

  const tableColumns = [
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ cell }) => {
        const createdAt = cell.row.original?.createdAt;
        return <span>{moment(createdAt).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      Header: "Customer Name",
      Cell: ({ cell }) => {
        const name = cell.row.original?.name;
        return <span>{name}</span>;
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: ({ cell }) => {
        const email = cell.row.original?.email;
        return <span>{email}</span>;
      },
    },
  ];
  return (
    <AppShell pageTitle="Newsletter Subscriptions">
      <MimaTable
        tableData={tableData}
        tableColumns={tableColumns}
        searchPlaceholder="Search news letter"
        searchVariant="wide"
        totalItems={data?.data?.totalCounts}
        onLimitChange={onLimitChange}
        isLoading={isLoading}
        isFetching={isFetching}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchQuery={setSearchQuery}
      />
    </AppShell>
  );
};

export default NewsLetter;
