import React, { useState } from "react";
import { useStores, walletStore } from "../../../stores";
import * as yup from "yup";
import constant from "../../../utils/constant";
import { WalletController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";

const useTransferFundOptionLogic = ({
  closeTransferModal,
  transferSwitchState,
  bankListContent,
  setBankListContent,
  beneficiaryContent,
  setBeneficiaryContent,
  stage2,
  setStage2,
}) => {
  const [savedActive, setSavedActive] = useState(true);
  const [newActive, setNewActive] = useState(false);

  const [newBeneficiarySelectedState, setNewBeneficiarySelectedState] =
    useState({
      key: "",
      value: "-Select-",
    });

  const [savedBeneficiarySelectedState, setSavedBeneficiarySelectedState] =
    useState({
      key: "",
      data: {},
      value: "-Select-",
    });

  const {
    appStore: { beneficiaries, banks },
  } = useStores();

  const goToSaved = () => {
    setSavedActive(true);
    setNewActive(false);
  };

  const goToBankList = () => {
    setBankListContent(true);
  };

  const goToNew = () => {
    setSavedActive(false);
    setNewActive(true);
  };

  const beneficiaryList = () => {
    setBeneficiaryContent(true);
  };

  const onSelectBeneficiary = (selectedBeneficiary) => {
    const { accountName, accountNumber, bankName } = selectedBeneficiary.value;
    selectedBeneficiary = {
      key: selectedBeneficiary.key,
      data: selectedBeneficiary.value,
      value: `${accountName}-${accountNumber}\n${bankName}`,
    };
    setSavedBeneficiarySelectedState(selectedBeneficiary);
    setSavedActive(true);
    setNewActive(false);
    setBeneficiaryContent(false);
  };

  const onSelectBank = (selectedBank) => {
    setNewBeneficiarySelectedState(selectedBank);
    setBankListContent(false);
    setNewActive(true);
    setSavedActive(false);
  };

  const closeModal = () => {
    walletStore.setSelectedSubWallet({});
    closeTransferModal();
    setBankListContent(false);
  };

  const key = "transferFundOptionModal";

  // new beneficiary logic

  const { walletStore } = useStores();
  const [loading, setLoading] = useState(false);

  const validationSchemaNewBeneficiary = () => {
    return yup.object({
      transactionAmount: yup
        .number()
        .min(100, "Amount Should be greater than 100.00"),
      beneficiaryBank: yup.string().required("Beneficiary Bank is required"),
      beneficiaryAccountNumber: yup
        .string()
        .required("Account Number is required"),
      narration: yup.string().required("Description is required"),
      expenseCategory: yup.string().required("Expense Category is required"),
    });
  };

  const onSubmitNewBeneficiary = (payload) => {
    //to be gotten from the ui
    // payload.saveBeneficiary = true;

    if (payload.nickName === "") {
      delete payload.nickName;
    }

    walletStore.setTransferPayload(payload);
    return transferSwitchState.goToConfirmPin();
  };

  const verifyAccountNumber = async (payload) => {
    if (
      payload.beneficiaryBank &&
      payload.accountNumber &&
      payload.accountNumber.length === 10
    ) {
      const { status, data, errorMessage } =
        await WalletController.verifyAccountNumber(payload);
      if (status === constant.Success) {
        return data.accountName;
      } else {
        MimaToastUtil.error({
          message: errorMessage,
        });
      }
    }
    return "";
  };

  // saved beneficiary logic

  const validationSchemaSavedBeneficiary = () => {
    return yup.object({
      transactionAmount: yup
        .number()
        .min(100, "Amount Should be greater than 100.00"),
      beneficiary: yup.object().optional(),
      expenseCategory: yup.string().required("Expense Category is required"),
      narration: yup.string().required("Description is required"),
    });
  };

  const onSubmitSavedBeneficiary = (payload) => {
    if (savedBeneficiarySelectedState.value !== "-Select-") {
      payload.currencyCode = "NGN";
      payload.saveBeneficiary = false;
      payload.beneficiaryAccountNumber = payload.beneficiary.accountNumber;
      payload.accountName = payload.beneficiary.accountName;
      payload.beneficiaryBank = payload.beneficiary.bankCode;
      delete payload.beneficiary;
      walletStore.setTransferPayload(payload);
      return transferSwitchState.goToConfirmPin();
    }
  };

  return {
    key,
    goToSaved,
    goToNew,
    onSelectBank,
    goToBankList,
    beneficiaryList,
    onSelectBeneficiary,
    savedActive,
    newActive,
    beneficiaries,
    banks,
    savedBeneficiarySelectedState,
    newBeneficiarySelectedState,
    closeModal,
    // new beneficiary logic
    validationSchemaNewBeneficiary,
    onSubmitNewBeneficiary,
    verifyAccountNumber,
    loading,
    setLoading,
    // saved beneficiary logic
    validationSchemaSavedBeneficiary,
    onSubmitSavedBeneficiary,
  };
};
export default useTransferFundOptionLogic;
