import React from "react";
import styles from "../../assets/styles/website/website.module.scss";
import { MimaText, MimaButton, AppShell, MimaInput } from "../../components";
import { Formik } from "formik";
import useCreateWebsiteLogic from "./useLogic/useCreateWebsiteLogic";
import { observer } from "mobx-react";

const CreateWebsite = () => {
  const {
    validationSchema,
    loading,
    onSubmit,
    setDomain,
    isFetching,
    errorMessage,
  } = useCreateWebsiteLogic();
  return (
    <AppShell pageTitle="Create website">
      <div className={styles.container}>
        <div className={styles.container_form}>
          <Formik
            initialValues={{
              subDomain: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <>
                <MimaInput
                  type="text"
                  labelTitle="Subdomain"
                  placeholder="Enter Subdomain name"
                  name="subDomain"
                  onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue("subDomain", value);
                    setDomain(value);
                  }}
                  variant="form"
                  value={values.subDomain}
                  onBlur={handleBlur}
                  touched={touched.subDomain}
                  error={errors.subDomain}
                  mb={2}
                />

                {errorMessage ===
                  "special character and number not allowed" && (
                  <span className={styles.error}>{errorMessage}</span>
                )}

                {values.subDomain !== "" ? (
                  <MimaText
                    variant="bodyBold"
                    labelTitle="Your Web Address will be"
                  >
                    {values.subDomain}.fash.style
                  </MimaText>
                ) : (
                  ""
                )}
                <MimaButton
                  title="Save"
                  variant="regular"
                  onClick={handleSubmit}
                  type="submit"
                  loading={loading}
                  isFetching={isFetching}
                  mt={2}
                  width={10}
                />
              </>
            )}
          </Formik>
        </div>
      </div>
    </AppShell>
  );
};

export default observer(CreateWebsite);
