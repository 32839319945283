import { BusinessController } from "../../../controllers";
import { useStores } from "../../../stores";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { useState } from "react";
import { string, object } from "yup";
import { handleImageUpload } from "../../../utils/utils";

const useBusinessInfoLogic = ({ closeModal, updateBusinessModal }) => {
  const [loading, setLoading] = useState(false);
  let { businessStore } = useStores();
  const [editState, setEditState] = useState(
    updateBusinessModal ? true : false
  );

  const goBack = () => {
    if (editState) {
      setEditState(false);
    } else {
      closeModal();
    }
  };

  const goToEditState = () => {
    setEditState(!editState);
  };

  const validationSchema = () =>
    object({
      address: string().optional(),
      webAddress: string().optional(),
      instagram: string().optional(),
      twitter: string().optional(),
    });

  const onSubmit = async (values) => {
    if (values.logoUrl?.length < 1) {
      return MimaToastUtil.error({
        message: "Kindly Add atleast 1 image ",
      });
    }

    setLoading(true);
    let urls = [];
    const file = values.logoUrl?.[0];

    if (values.logoUrl.length > 0) {
      urls = [file.preview];
      if (file.name !== file.preview) {
        urls = await handleImageUpload(values.logoUrl);
      }
    }

    const payload = {
      logoUrl: urls[0],
    };

    if (values.instagram) payload.instagramHandle = values.instagram;
    if (values.twitter) payload.twitterHandle = values.twitter;
    if (values.address) payload.businessAddress = values.address;
    values.bvn = businessStore.business.bvn;

    const { status, errorMessage } = await BusinessController.update(payload);
    setLoading(false);
    if (status === constant.Success) {
      MimaToastUtil.success({
        message: constant.Success,
      });
      return setEditState(false);
    }

    MimaToastUtil.error({
      message: errorMessage,
    });
  };
  const business = businessStore.business;
  return {
    loading,
    onSubmit,
    validationSchema,
    business,
    closeModal,
    editState,
    goToEditState,
    goBack,
  };
};

export default useBusinessInfoLogic;
