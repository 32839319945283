import { Formik } from "formik";
import {
  MimaButton,
  MimaDropdown,
  MimaText,
  Modal,
  PageLoader,
} from "../../components";
import React from "react";
import { businessStore } from "../../stores";
import useRenewSubLogic from "./useLogic/useRenewSubLogic";

const RenewSub = ({ closeModal }) => {
  const { business } = businessStore;

  const {
    monthData,
    plansData,
    onSubmit,
    validationSchema,
    loading,
    // plan,
    setPlan,
  } = useRenewSubLogic({ closeModal });

  return (
    <Modal closeModal={closeModal}>
      <MimaText variant="subtitle" mb={4}>
        Renew Your Subscription
      </MimaText>

      {loading ? (
        <PageLoader loading={loading} />
      ) : (
        <>
          <MimaText width={38} mb={3} align="center">
            Hello {business?.tradingName}, Renew your subscription to the{" "}
            <b>{business?.plan?.name} plan</b> or Select a new plan
          </MimaText>

          <Formik
            initialValues={{
              months: "",
              plan: business.plan._id,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <>
                <MimaDropdown
                  labelTitle="Select Plan"
                  placeholder={business?.plan?.name}
                  id="plan"
                  name="plan"
                  value={values.plan}
                  onChange={(data) => {
                    setFieldValue("plan", data.key);
                    setPlan(data);
                  }}
                  onBlur={handleBlur}
                  error={errors.plan}
                  touched={touched.plan}
                  data={plansData}
                />

                <MimaDropdown
                  labelTitle="Select Months"
                  placeholder="Select months"
                  id="months"
                  name="months"
                  value={values.months}
                  onChange={(data) => {
                    setFieldValue("months", data.key);
                  }}
                  onBlur={handleBlur}
                  error={errors.months}
                  touched={touched.months}
                  data={monthData}
                />

                <MimaButton
                  title="Renew Subscription"
                  onClick={handleSubmit}
                  type="submit"
                  loading={loading}
                  width={38}
                  mt={1}
                  variant="form"
                />
              </>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};

export default RenewSub;
