import {
  ExchangeController,
  TransactionsController,
  WalletController,
  BusinessController,
  CustomerController,
  SettingsController,
} from "../../../controllers";
import React, { useEffect, useState } from "react";
import { MimaToastUtil } from "../../../components";
import { useNavigate } from "react-router-dom";
import constant from "../../../utils/constant";
import { exchangeStore, useStores, virtualCardStore } from "../../../stores";
import { observer } from "mobx-react";
import { Wallet } from "../..";
import { object, string } from "yup";
import { monthName, theYear } from "../../../utils/utils";
import { getDate } from "../../../utils/utils";
import { filterPeriods } from "../../../utils/utils";
import moment from "moment";

const useWalletLogic = () => {
  const {
    // transactions,
    transactionSummary,
    expenseAnalysis,
    getWalletTransactions,
  } = TransactionsController;

  const { walletDetails, beneficiaries } = WalletController;
  const { businessDetails } = BusinessController;

  const { userStore, transactionStore, businessStore, walletStore } =
    useStores();
  const [loading, setLoading] = useState(false);
  const [filterPeriod, setFilterPeriod] = useState(`${monthName} ${theYear}`);
  const [tier, setTier] = useState("Tier 0");

  const navigate = useNavigate();
  // const isOnboardingDone = () => {
  // 	if (
  // 		userStore.isOnBoardingDone === 'false' &&
  // 		userStore.user.accountType === 'Business'
  // 	) {
  // 		MimaToastUtil.error({
  // 			message: constant.OnboardingRequired,
  // 		});
  // 		localStorage.setItem(constant.RoutePath, constant.Routes.Wallet);
  // 		return navigate(constant.Routes.OnboardingStageOne);
  // 	}
  // };
  const setPin = () => {
    if (!userStore.user.hasPin) {
      MimaToastUtil.error({
        message: "Kindly set your PIN on settings page",
      });
      return navigate(constant.Routes.Settings);
    }
  };
  const refreshWallet = async () => {
    setLoading(true);
    await Promise.all([
      walletDetails(),
      // transactions(),
      getWalletTransactions(),
      businessDetails(),
      transactionSummary(),
      expenseAnalysis(),
    ]);
    setLoading(false);
  };
  const setWithdrawalAccount = () => {
    const bankAccount =
      userStore.user.accountType === "Business"
        ? businessStore.business["withdrawalBankAccount"]
        : userStore.user["withdrawalBankAccount"];
    if (!bankAccount) {
      MimaToastUtil.error({
        message: "Kindly set your Withdrawal Account on settings page",
      });
      return navigate(constant.Routes.Settings);
    }
  };
  const hasBusinessAccount =
    businessStore?.business?.bankAccount ||
    businessStore?.business?.bankAccountTwo;

  useEffect(() => {
    const setTransactions = async () => {
      let query = "tagged=NO-TAG";
      setLoading(true);
      await Promise.all([
        // transactions(
        // 	transactionStore.tagQuery === true ? query : ""
        // ),
        getWalletTransactions(transactionStore.tagQuery === true ? query : ""),
        // banks(),
        beneficiaries(),
        CustomerController.getCustomers(),
        transactionSummary(),
        expenseAnalysis(),
        walletDetails(),
      ]);
      setLoading(false);
    };
    setTransactions();
  }, [hasBusinessAccount, transactionStore.tagQuery]);
  const validationSchema = () => {
    return object({
      startDate: string().required("Start Date is required"),
      endDate: string().required("End Date is required"),
    });
  };

  const onSubmit = async (payload) => {
    let query;

    if (payload && Object.keys(payload).length > 0) {
      query = `startDate=${payload.startDate}&endDate=${payload.endDate}&dateField=createdAt`;
      if (payload.period === "custom") {
        setFilterPeriod(
          `${moment(payload?.startDate).format("DD-MM-YYYY")} - ${moment(payload.endDate).format("DD-MM-YYYY")}`
        );
      } else {
        setFilterPeriod(`${payload?.periodText}`);
      }
    }
    setLoading(true);
    await Promise.all([
      TransactionsController.transactionSummary(query),
      TransactionsController.expenseAnalysis(query),
      // TransactionsController.transactions(query),
      TransactionsController.getWalletTransactions(query),
    ]);
    setLoading(false);
  };

  const [fundModal, setFundModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [walletExchangeModal, setWalletExchangeModal] = useState(false);
  const [pinContent, setPinContent] = useState(false);
  const [confirmTransferContent, setConfirmTransferContent] = useState(false);
  const [transferSuccessContent, setTransferSuccessContent] = useState(false);
  const [transferFailedContent, setTransferFailedContent] = useState(false);
  const [virtualCardModal, setVirtualCardModal] = useState(false);
  const [beneficiaryModal, setBeneficiaryModal] = useState(false);
  const [statementModal, setStatementModal] = useState(false);
  const [kycModal, setKycModal] = useState(false);
  const [bankAccModal, setBankAccModal] = useState(false);

  const transactions = transactionStore.walletTransactions;

  useEffect(() => {
    const setTransactions = async () =>
      await Promise.all([
        SettingsController.dashboardData(),
        // InventoryController.getConfig(),
        // TransactionsController.transactions(),
        // InvoiceController.getAnalysis(),
      ]);
    setTransactions();
  }, []);

  const sharedState = {
    pinContent,
    confirmTransferContent,
    transferSuccessContent,
    transferFailedContent,
  };

  const transferSwitchState = {
    goToTransferSuccess: () => {
      setTransferSuccessContent(true);
      setConfirmTransferContent(false);
      setPinContent(false);
    },

    goToTransferFailed: () => {
      setTransferFailedContent(true);
      setTransferSuccessContent(false);
      setConfirmTransferContent(false);
      setPinContent(false);
    },

    goToConfirmTransfer: () => {
      setConfirmTransferContent(true);
      setPinContent(false);
    },

    goToConfirmPin: () => {
      setPinContent(true);
      setConfirmTransferContent(false);
    },
  };

  const logout = () => {
    userStore.setLoggedIn(false);
  };

  const closeModal = () => {
    walletStore.setSelectedSubWallet({});
    walletStore.setSelectedWallet({});
    setFundModal(false);
    setTransferModal(false);
    setWithdrawModal(false);
    setTransferSuccessContent(false);
    setTransferFailedContent(false);
    setConfirmTransferContent(false);
    setPinContent(false);
    setWalletExchangeModal(false);
    setVirtualCardModal(false);
    virtualCardStore.setCardDetails();
    setBeneficiaryModal(false);
    setStatementModal(false);
    setKycModal(false);
    setBankAccModal(false);
  };

  const fundWallet = (wallet) => {
    // isOnboardingDone();
    walletStore.setSelectedWallet(wallet);
    setFundModal(true);
  };
  const transfer = () => {
    // isOnboardingDone();
    setPin();
    setTransferModal(true);
  };
  const withdraw = () => {
    // isOnboardingDone();
    setWithdrawalAccount();
    setPin();
    setWithdrawModal(true);
  };
  const virtualCard = () => {
    setVirtualCardModal(true);
  };

  const viewBeneficiaries = () => {
    setBeneficiaryModal(true);
  };

  const resetFilter = () => {
    onSubmit();
    setFilterPeriod(`${monthName} ${theYear}`);
  };

  const viewStatement = () => {
    setStatementModal(true);
  };

  const exchangeFunds = async (wallet) => {
    // isOnboardingDone();
    walletStore.setSelectedWallet(wallet);
    const exchangeCurrencies = {
      fromCurrency: wallet.currencyCode === "NGN" ? "USD" : wallet.currencyCode,
      toCurrency: "NGN",
    };
    exchangeStore.setExchangeCurrencies(exchangeCurrencies);
    let query;
    query = `fromCurrency=${exchangeCurrencies.fromCurrency}&toCurrency=${exchangeCurrencies.toCurrency}`;
    await ExchangeController.getCurrentExchangeRate(query);
    setWalletExchangeModal(true);
  };

  const isActivated = userStore?.isActivated;
  // const hasPin = userStore?.user?.hasPin;
  const isOnboarded = userStore?.isOnBoardingDone !== "false";
  // const hasWithdrawal = businessStore?.business?.withdrawalBankAccount;
  const hasBizReg = false;

  useEffect(() => {
    const getTier = () => {
      if (isActivated && isOnboarded && hasBizReg) {
        setTier("Tier 3");
      } else if (isActivated && isOnboarded) {
        setTier("Tier 2");
      } else if (isActivated) {
        setTier("Tier 1");
      }
    };

    getTier();
    TransactionsController.transactionSummary();
  }, []);

  const createBankAccount = async () => {
    if (tier === "Tier 1" || tier === "Tier 0") {
      MimaToastUtil.error({
        message: "Please update your KYC information and Upgrade to Tier 2",
      });
      setKycModal(true);
    } else {
      setBankAccModal(true);
      // setLoading(true)
      // const { status, errorMessage } = await BusinessController.generateBankAccount();

      // if (status === constant.Success) {
      // 	MimaToastUtil.success({
      // 		message: constant.Success,
      // 	});
      // 	setLoading(false);
      // } else {
      // 	setLoading(false);
      // 	MimaToastUtil.error({ message: errorMessage });
      // }
    }
  };

  const bankAccounts =
    userStore.user.accountType === "Business"
      ? [
          // businessStore?.business?.bankAccountTwo,
          businessStore?.business?.bankAccount,
        ]
      : [userStore?.user?.bankAccount];
  const summary = transactionStore.transactionsSummary;
  const closeWalletModal = closeModal;
  return {
    summary,
    transactions,
    closeWalletModal,
    fundModal,
    transferModal,
    withdrawModal,
    virtualCardModal,
    fundWallet,
    onSubmit,
    validationSchema,
    loading,
    setLoading,
    transfer,
    withdraw,
    virtualCard,
    bankAccounts,
    refreshWallet,
    transferSwitchState,
    sharedState,
    filterPeriod,
    resetFilter,
    pinContent,
    setPinContent,
    exchangeFunds,
    walletExchangeModal,
    createBankAccount,
    hasBusinessAccount,
    beneficiaryModal,
    viewBeneficiaries,
    viewStatement,
    statementModal,
    filterPeriods,
    getDate,
    kycModal,
    tier,
    bankAccModal,
  };
};
export default useWalletLogic;
