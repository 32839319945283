import { useState, useEffect } from "react";
import * as yup from "yup";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
import { SettingsController } from "../../../controllers";
import { appStore } from "../../../stores";

const useGeneralSettingsLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const setGeneralSettingsPage = async () => {
      setLoading(true);
      await Promise.all([SettingsController.getGeneralConfig()]);
      setLoading(false);
    };
    setGeneralSettingsPage();
  }, []);

  const validationSchema = () =>
    yup.object({
      defaultCurrency: yup.string().required("Select currency code"),
    });

  const onSubmit = async (payload) => {
    setLoading(true);
    const { status, errorMessage } =
      await SettingsController.updateGeneralConfig(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
    } else {
      MimaToastUtil.error({
        message: errorMessage,
      });
      setLoading(false);
    }
    closeModal();
  };

  const currencyCode = [
    {
      key: "NGN",
      value: "NGN",
    },
    {
      key: "USD",
      value: "USD",
    },
    {
      key: "GBP",
      value: "GBP",
    },
    {
      key: "EUR",
      value: "EUR",
    },
  ];

  const settings = appStore.generalSettings;

  return {
    loading,
    onSubmit,
    currencyCode,
    closeModal,
    validationSchema,
    settings,
  };
};
export default useGeneralSettingsLogic;
