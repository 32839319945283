import { apiRequest } from "../utils/useAPIRequest";
import constant from "../utils/constant";
import { businessStore, ordersStore } from "../stores";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../App";

const useGetOrders = (ordersTableQuery, filterQuery, searchQuery) => {
  const id = businessStore.bId;

  const query = useMemo(() => {
    if (searchQuery && filterQuery) {
      return `${ordersTableQuery}&${filterQuery}&search=${searchQuery}`;
    } else if (searchQuery) {
      return `${ordersTableQuery}&search=${searchQuery}`;
    } else if (filterQuery) {
      return `${ordersTableQuery}&${filterQuery}`;
    } else {
      return ordersTableQuery;
    }
  }, [filterQuery, ordersTableQuery, searchQuery]);

  return useQuery({
    queryKey: [id, "getOrders", query],
    queryFn: () => {
      return apiRequest({
        url: `/orders?${query}`,
        method: "get",
      });
    },
    keepPreviousData: true,
  });
};
const useGetDesigns = (designsTableQuery, filterQuery) => {
  const query = useMemo(() => {
    if (filterQuery) {
      return `${designsTableQuery}&${filterQuery}`;
    } else {
      return designsTableQuery;
    }
  }, [filterQuery, designsTableQuery]);

  return useQuery({
    queryKey: ["getDesigns", query],
    queryFn: () => {
      return apiRequest({
        url: `/fashion/store-styles?${query}`,
        method: "get",
      });
    },
    keepPreviousData: true,
  });
};

const getOrdersAnalysis = async (query) => {
  if (!query) {
    query = "";
  }
  const { status, errorMessage, data } = await apiRequest({
    url: `/orders/analysis?${query}`,
    method: "get",
  });
  if (status === constant.Success) {
    ordersStore.setOrdersAnalysis(data);
    return { status };
  }
  return { status, errorMessage };
};

const updateOrderStatus = async (payload, query) => {
  const trackingStatus = payload.trackingStatus;
  const id = payload.id;
  const bId = businessStore.bId;
  const { status, errorMessage } = await apiRequest({
    url: `/orders/order/${id}/${trackingStatus}?${query}&bId=${bId}`,
    method: "put",
    payload,
  });
  if (status === constant.Success) {
    await getOrdersAnalysis();
    queryClient.invalidateQueries({ queryKey: [bId, "getOrders"] });
    return { status };
  }
  return { status, errorMessage };
};

const requestDesign = async (payload) => {
  const { status, errorMessage } = await apiRequest({
    url: "/fashion/style/request",
    method: "post",
    payload,
  });
  if (status === constant.Success) {
    queryClient.invalidateQueries({ queryKey: ["getDesigns"] });

    return { status };
  }
  return { status, errorMessage };
};

const getOrderSummary = async () => {
  const { status, data, errorMessage } = await apiRequest({
    url: `/orders/tracking-analysis`,
    method: "get",
  });

  if (status === constant.Success) {
    ordersStore.setOrderSummary(data);
    return { status: constant.Success };
  }
  return { errorMessage, status };
};

const OrderController = {
  useGetOrders,
  useGetDesigns,
  getOrdersAnalysis,
  updateOrderStatus,
  requestDesign,
  getOrderSummary,
};

export default OrderController;
