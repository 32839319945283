import {
  MimaButton,
  MimaDropdown,
  MimaDropZone,
  MimaInput,
  MimaPaginatedDropdown,
  MimaText,
  MimaTextArea,
  Modal,
  ReceiptCheckBox as RCB,
} from "../../components";
import styles from "../../assets/styles/Customer/Customer.module.scss";
import { businessStore, customerStore } from "../../stores";
import React from "react";
import { Formik, Field, FieldArray } from "formik";
import { FaHandPointRight } from "react-icons/fa";
import useTakeMeasurementLogic from "./useLogic/useTakeMeasurementLogic";
import { RiDeleteBin5Line } from "react-icons/ri";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};

const TakeMeasurement = ({
  closeModal,
  goBack,
  newCustomer,
  createNewCustomer,
  onSubmitCustomer,
  customerDetailsValidation,
  reFetch,
}) => {
  const { loading, bId, onSubmit, validationSchema } = useTakeMeasurementLogic({
    closeModal,
    reFetch,
  });

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {!newCustomer && (
        <>
          <MimaText
            variant="subtitle"
            color="var(--color-fash-primary-3)"
            align="center"
            mb={5}
          >
            Take Measurement
          </MimaText>
          <Formik
            initialValues={{
              parts: [
                {
                  part: "",
                  value: "",
                  temp: "",
                },
              ],
              measurementFor: "Customer",
              style: "",
              note: "",
              standardSize: {
                country: "",
                value: "",
              },
              imageUrls: [
                {
                  type: "",
                  value: "",
                },
              ],

              customer: "",
              unitOfMeasurement: "",
              customerImage: [],
              measurementImage: [],
              measurementPic: false,
              customerPic: false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div>
                <MimaText variant="small" ml={2}>
                  Measurement For:
                </MimaText>
                <div className={styles.checks}>
                  <RCB
                    title="Customer"
                    name="measurementFor"
                    onChange={() => {
                      setFieldValue("measurementFor", "Customer");
                    }}
                    checked={values.measurementFor === "Customer"}
                    value="Customer"
                  />
                  <RCB
                    title="Design"
                    onChange={() => {
                      setFieldValue("measurementFor", "Design");
                    }}
                    checked={values.measurementFor === "Design"}
                    name="measurementFor"
                    value="Design"
                  />
                </div>

                {values.measurementFor === "Customer" && (
                  <>
                    <MimaPaginatedDropdown
                      mb={2}
                      labelTitle="Customer"
                      placeholder="Select Customer"
                      name="customer"
                      value={values.customer}
                      id="customer"
                      optionLabel="fullname"
                      touched={touched.customer}
                      error={errors.customer}
                      url="/customers/business"
                      queryKey={[businessStore.bId, "customers"]}
                      getPageValue={(page) => page?.data?.customers || []}
                      getPageTotalCount={(lastPage) =>
                        lastPage?.data?.totalCounts || 0
                      }
                      onChange={(data) => {
                        setFieldValue("customer", data._id);
                        setFieldValue("customerName", data.fullname);
                      }}
                    />

                    <MimaText
                      variant="smallBold"
                      color="var(--color-fash-primary-3)"
                      align="center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      mb={2}
                      onClick={createNewCustomer}
                    >
                      <FaHandPointRight
                        style={{ fontSize: "3rem", marginRight: "1rem" }}
                      />{" "}
                      Click here if Measurement is for a new Customer
                    </MimaText>
                  </>
                )}

                {values.measurementFor === "Design" && (
                  <>
                    <MimaPaginatedDropdown
                      mb={2}
                      labelTitle="Select style"
                      placeholder="Select style"
                      name="style"
                      value={values.style}
                      id="style"
                      optionLabel="name"
                      touched={touched.style}
                      error={errors.style}
                      url="/fashion/styles"
                      queryKey={[bId, "styles"]}
                      getPageValue={(page) => page?.data?.styles || []}
                      getPageTotalCount={(lastPage) =>
                        lastPage?.data?.totalCounts || 0
                      }
                      onChange={(data) => {
                        setFieldValue("style", data._id);
                        setFieldValue("styleName", data.name);
                      }}
                    />
                  </>
                )}

                <MimaDropdown
                  labelTitle="Unit Of Measurement"
                  placeholder="Select"
                  name="unitOfMeasurement"
                  value={values.unitOfMeasurement}
                  id="unitOfMeasurement"
                  onChange={(data) => {
                    setFieldValue("unitOfMeasurement", data.key);
                  }}
                  data={[
                    { key: "Inches", value: "Inches" },
                    { key: "Centimeter", value: "Centimeter" },
                    { key: "Meters", value: "Meters" },
                    { key: "Feet", value: "Feet" },
                  ]}
                  touched={touched.unitOfMeasurement}
                  error={errors.unitOfMeasurement}
                />
                <MimaText variant="small" mb={1} mt={2} align="center">
                  Manually Enter Measurement
                </MimaText>
                <FieldArray name="parts">
                  {({ push, remove }) => (
                    <React.Fragment>
                      {values.parts.map((_, i) => (
                        <div key={i} className={styles.measurement}>
                          <Field
                            name={`parts.${i}.part`}
                            id={`parts.${i}.part`}
                            component={FieldInput}
                            type="text"
                            placeholder="Body part"
                            labelTitle="Part"
                            variant="form"
                            width={16}
                          />
                          <Field
                            name={`parts.${i}.temp`}
                            id={`parts.${i}.temp`}
                            component={FieldInput}
                            type="text"
                            placeholder="e.g 10"
                            labelTitle="Temporary"
                            variant="form"
                            width={8}
                          />
                          <Field
                            name={`parts.${i}.value`}
                            id={`parts.${i}.value`}
                            component={FieldInput}
                            type="text"
                            placeholder="e.g 10"
                            labelTitle="Actual"
                            variant="form"
                            width={8}
                          />

                          {i !== 0 ? (
                            <div className={styles.measurement_delete}>
                              <RiDeleteBin5Line
                                onClick={() => remove(i)}
                                color="var(--color-error)"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                      <MimaButton
                        title="Add Another Part"
                        variant="outlined"
                        type="button"
                        height={3}
                        mt={1}
                        onClick={() =>
                          push({
                            part: "",
                            value: "",
                            temp: "",
                          })
                        }
                      />
                    </React.Fragment>
                  )}
                </FieldArray>
                {values.measurementFor === "Customer" && (
                  <>
                    <MimaText variant="small" mb={2} mt={2} align="center">
                      Enter standard Size
                    </MimaText>
                    <div className="modal__beside">
                      <MimaDropdown
                        labelTitle="Country"
                        placeholder="Select Country"
                        name="country"
                        value={values.standardSize.country}
                        id={values.standardSize.country}
                        onChange={(data) => {
                          setFieldValue(`standardSize.country`, data.key);
                        }}
                        data={[
                          { key: "US", value: "US" },
                          { key: "UK", value: "UK" },
                          { key: "EUROPE", value: "EUROPE" },
                          { key: "CHINA", value: "CHINA" },
                        ]}
                        touched={touched?.standardSize?.country}
                        width={18}
                        mb={2}
                        variant="filterForm"
                      />

                      <MimaInput
                        type="text"
                        labelTitle="Size"
                        name="standardSize.value"
                        id="standardSize.value"
                        placeholder="Enter size"
                        value={values.standardSize.value}
                        onChange={handleChange}
                        error={errors?.standardSize?.value}
                        touched={touched?.standardSize?.value}
                        onBlur={handleBlur}
                        width={18}
                        variant="form"
                      />
                    </div>
                  </>
                )}

                <MimaText variant="small" mb={2} mt={2} align="center">
                  Upload measurement page picture
                </MimaText>
                <>
                  <div className={styles.checks}>
                    <RCB
                      title="Yes"
                      name="measurementPic"
                      onChange={() => {
                        setFieldValue("measurementPic", true);
                      }}
                      checked={values.measurementPic}
                      value="true"
                    />
                    <RCB
                      title="No"
                      onChange={() => {
                        setFieldValue("measurementPic", false);
                      }}
                      checked={!values.measurementPic}
                      name="measurementPic"
                      value="false"
                    />
                  </div>
                  {errors.measurementImage ? (
                    <div className={styles.error}>
                      {""}
                      {touched.measurementImage && errors.measurementImage}
                      {""}
                    </div>
                  ) : null}
                </>
                {values.measurementPic === true && (
                  <div className={styles.dropZone}>
                    <MimaDropZone
                      title="Upload measurement page picture"
                      info="maximum image Size 1MB"
                      maxNumOfImages={1}
                      files={values.measurementImage}
                      setFiles={(newFiles) => {
                        setFieldValue("measurementImage", newFiles);
                      }}
                      labelTitle="Upload Image"
                      name="value"
                    />
                  </div>
                )}

                {values.measurementFor === "Customer" && (
                  <>
                    <MimaText variant="small" mb={2} mt={2} align="center">
                      Upload Customer's picture
                    </MimaText>
                    <>
                      <div className={styles.checks}>
                        <RCB
                          title="Yes"
                          name="customerPic"
                          onChange={() => {
                            setFieldValue("customerPic", true);
                          }}
                          checked={values.customerPic}
                          value="true"
                        />
                        <RCB
                          title="No"
                          onChange={() => {
                            setFieldValue("customerPic", false);
                          }}
                          checked={!values.customerPic}
                          name="customerPic"
                          value="false"
                        />
                      </div>
                      {errors.customerImage ? (
                        <div className={styles.error}>
                          {""}
                          {touched.customerImage && errors.customerImage}
                          {""}
                        </div>
                      ) : null}
                    </>
                    {values.customerPic === true && (
                      <div className={styles.dropZone}>
                        <MimaDropZone
                          title="Upload customer's picture"
                          info="maximum image Size 1MB"
                          maxNumOfImages={1}
                          files={values.customerImage}
                          setFiles={(newFiles) => {
                            setFieldValue("customerImage", newFiles);
                          }}
                          labelTitle="Upload Image"
                          name="value"
                        />
                      </div>
                    )}
                  </>
                )}

                <MimaTextArea
                  type="textarea"
                  labelTitle="Note"
                  placeholder="Note"
                  name="note"
                  mb={2}
                  variant="message"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <MimaButton
                  title="Save"
                  loading={loading}
                  variant="regular"
                  type="submit"
                  onClick={handleSubmit}
                  width={38}
                />
              </div>
            )}
          </Formik>
        </>
      )}

      {newCustomer && (
        <>
          <MimaText
            variant="subtitle"
            color="var(--color-fash-primary-3)"
            align="center"
          >
            Add Customer
          </MimaText>
          <Formik
            initialValues={{
              fullname: "",
              mobile: "",
              email: "",
              customerGroup: "",
              measurementFor: "",
              address: "",
            }}
            validationSchema={customerDetailsValidation()}
            onSubmit={onSubmitCustomer}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div>
                <MimaInput
                  type="text"
                  labelTitle="Name"
                  placeholder="Enter Customer Name"
                  name="fullname"
                  mb={2}
                  value={values.fullname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.fullname}
                  touched={touched.fullname}
                />

                <MimaInput
                  type="text"
                  labelTitle="Phone Number"
                  placeholder="Customer Phone number"
                  name="mobile"
                  mb={2}
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.mobile}
                  touched={touched.mobile}
                />
                <MimaInput
                  type="email"
                  labelTitle="Email Address"
                  placeholder=" Customer Email"
                  name="email"
                  mb={2}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <MimaText variant="small" ml={2}>
                  Gender/Entity
                </MimaText>
                <div className={styles.checks}>
                  <RCB
                    title="Male"
                    id="MALE"
                    name="gender"
                    onChange={() => {
                      setFieldValue("gender", "MALE");
                    }}
                    checked={values.gender === "MALE"}
                    value="Male"
                  />
                  <RCB
                    title="Female"
                    onChange={() => {
                      setFieldValue("gender", "FEMALE");
                    }}
                    checked={values.gender === "FEMALE"}
                    id="FEMALE"
                    name="gender"
                    value="Female"
                  />
                  <RCB
                    title="Organization"
                    id="BUSINESS"
                    onChange={() => {
                      setFieldValue("gender", "BUSINESS");
                    }}
                    checked={values.gender === "BUSINESS"}
                    name="gender"
                    value="Business"
                  />
                </div>
                <MimaDropdown
                  labelTitle="Group"
                  placeholder="Select"
                  name="customerGroup"
                  value={values.customerGroup}
                  id="customerGroup"
                  onChange={(data) => {
                    setFieldValue("customerGroup", data.key);
                  }}
                  data={customerStore.mappedGroups}
                  touched={touched.customerGroup}
                />

                <MimaTextArea
                  type="textarea"
                  labelTitle="Customer Address"
                  placeholder="Customer's Address"
                  name="address"
                  mb={2}
                  variant="message"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <MimaInput
                  type="text"
                  labelTitle="Secondary Phone Number"
                  name="secondaryMobile"
                  mb={2}
                  value={values.secondaryMobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <MimaButton
                  title="Save"
                  loading={loading}
                  type="submit"
                  onClick={handleSubmit}
                  variant="form"
                />
              </div>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};

export default TakeMeasurement;
