import React, { useState } from "react";
import {
  MimaButton,
  MimaInput,
  MimaText,
  Modal,
  PageLoader,
} from "../../components";
import { walletStore, exchangeStore } from "../../stores";
import styles from "../../assets/styles/Wallet/Wallet.module.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { Formik } from "formik";
import { amountFormatter } from "../../utils/utils";
import OtpInput from "react-otp-input";
import useWalletExchangeLogic from "./useLogics/useWalletExchangeLogic";

const WalletExchange = ({ closeWalletModal }) => {
  const {
    closeModal,
    onChangeToCurrency,
    validationSchema,
    onSubmit,
    getExchangeAmount,
    goBack,
    fee,
    loading,
    pinStage,
    onSubmitPin,
  } = useWalletExchangeLogic({ closeWalletModal });

  const [showCurr, setShowCurr] = useState(false);
  const [showCurr2, setShowCurr2] = useState(false);
  const { fromCurrency, toCurrency } = exchangeStore.exchangeCurrencies;
  const ngnRate = exchangeStore.currentExchangeRate;
  const [pin, setPin] = useState("");

  return (
    <Modal closeModal={closeModal}>
      {!pinStage && (
        <>
          <MimaText variant="subtitleBold" width={38} align="center">
            {" "}
            Change your {fromCurrency} to {toCurrency}{" "}
          </MimaText>
          {/* { fromCurrency === "NGN" ?
                        (<MimaText variant = "h3"> Buy {toCurrency} with {fromCurrency} </MimaText>) :
                        (<MimaText variant="h3"> Sell {fromCurrency} to {toCurrency} </MimaText> )
                    } */}

          {/* {fromCurrency === "NGN" ? (
                        <MimaText variant="smallBold" color="Var(--color-fash-primary-3)"> 1 {toCurrency} = {ngnRate} {fromCurrency} </MimaText>
                    ) : (
                        <MimaText variant="smallBold" color="Var(--color-fash-primary-3)" > 1 {fromCurrency} = {ngnRate} {toCurrency} </MimaText>
                    )} */}
          <MimaText variant="smallBold" color="Var(--color-fash-primary-3)">
            {" "}
            1 {fromCurrency} = {ngnRate} {toCurrency}{" "}
          </MimaText>

          <Formik
            initialValues={{
              fromCurrency: fromCurrency,
              toCurrency: toCurrency,
              amount: 0,
              exchangeAmount: 0,
              validationControl: fromCurrency === "NGN" ? true : false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <>
                {/* from */}
                <div className={styles.exchange}>
                  <div className={styles.exchange__side}>
                    <div>
                      <MimaText variant="small">From</MimaText>
                      <MimaText
                        variant="subtitleBold"
                        color="var(--color-fash-primary-3)"
                      >
                        {values.fromCurrency}
                      </MimaText>
                    </div>
                    {/* {(fromCurrency === "NGN" && walletStore?.wallets?.length > 1 ) ? (
                                                <div className={styles.selectCurrency}>
                                                    <div className={!showCurr ? styles.selectCurrency__icon : styles.selectCurrency__icon_active}
                                                    onClick={()=>{
                                                        setShowCurr(!showCurr)
                                                    }}><MdKeyboardArrowDown /></div>

                                                    <AnimatePresence>
                                                        {showCurr &&
                                                            <motion.div
                                                                initial={{
                                                                    opacity: 0,
                                                                    scaleY: 0,
                                                                }}
                                                                animate={{
                                                                    scaleY: 1,
                                                                    opacity: 1,
                                                                    transition: {
                                                                        duration: .2,
                                                                    },
                                                                }}
                                                                exit={{
                                                                    scaleY: 0,
                                                                    opacity: 0,
                                                                    transition: {
                                                                        duration: 0.2,
                                                                    },
                                                                }}
                                                                style={{originY: 0}}
                                                                className={styles.selectCurrency__list}>
                                                                {walletStore?.wallets?.map(item => (
                                                                    <li key={item.currencyCode} className={fromCurrency === item.currencyCode ? styles.selectCurrency__list_active : ""} onClick={()=>{
                                                                        setShowCurr(false);
                                                                        setFieldValue("fromCurrency", item.currencyCode);
                                                                        if (fromCurrency !== "NGN") {
                                                                            exchangeStore.setExchangeCurrencies({
                                                                                fromCurrency: item.currencyCode,
                                                                                toCurrency: "NGN"
                                                                            })
                                                                        }
                                                                    }}>{item.currencyCode}</li>
                                                                ))}
                                                            </motion.div>
                                                        }
                                                    </AnimatePresence>

                                                </div>) : ""} */}
                  </div>
                  <div>
                    <MimaInput
                      placeholder="Enter amount"
                      type="number"
                      variant="exchange"
                      ml={1}
                      name="amount"
                      min={ngnRate}
                      mb={1}
                      value={values.amount}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value !== "") {
                          getExchangeAmount(e.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                      touched={touched.amount}
                      error={errors.amount}
                    />
                  </div>
                </div>

                {/* to */}
                <div className={styles.exchange}>
                  <div className={styles.exchange__side}>
                    <div>
                      <MimaText variant="small">To</MimaText>
                      <MimaText
                        variant="subtitleBold"
                        color="var(--color-fash-primary-3)"
                      >
                        {toCurrency}
                      </MimaText>
                    </div>
                    {fromCurrency === "NGN" &&
                    walletStore?.wallets?.length > 1 ? (
                      <div className={styles.selectCurrency}>
                        <div
                          className={
                            !showCurr2
                              ? styles.selectCurrency__icon
                              : styles.selectCurrency__icon_active
                          }
                          onClick={() => {
                            setShowCurr2(!showCurr2);
                          }}
                        >
                          <MdKeyboardArrowDown />
                        </div>

                        <AnimatePresence>
                          {showCurr2 && (
                            <motion.div
                              initial={{
                                opacity: 0,
                                scaleY: 0,
                              }}
                              animate={{
                                scaleY: 1,
                                opacity: 1,
                                transition: {
                                  duration: 0.2,
                                },
                              }}
                              exit={{
                                scaleY: 0,
                                opacity: 0,
                                transition: {
                                  duration: 0.2,
                                },
                              }}
                              style={{ originY: 0 }}
                              className={styles.selectCurrency__list}
                            >
                              {walletStore?.wallets
                                ?.filter((x) => {
                                  return x.currencyCode !== fromCurrency;
                                })
                                ?.map((item) => (
                                  <li
                                    key={item.currencyCode}
                                    className={
                                      toCurrency === item.currencyCode
                                        ? styles.selectCurrency__list_active
                                        : ""
                                    }
                                    onClick={() => {
                                      exchangeStore.setExchangeCurrencies({
                                        fromCurrency: fromCurrency,
                                        toCurrency: item.currencyCode,
                                      });
                                      setShowCurr2(false);
                                      setFieldValue(
                                        "toCurrency",
                                        item.currencyCode
                                      );
                                      onChangeToCurrency();
                                    }}
                                  >
                                    {item.currencyCode}
                                  </li>
                                ))}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <MimaInput
                      placeholder="Enter amount"
                      type="number"
                      variant="exchange"
                      ml={1}
                      disabled
                      name="exchangeAmount"
                      value={exchangeStore.exchangeRateValue}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(
                          "exchangeAmount",
                          exchangeStore.exchangeRateValue
                        );
                      }}
                    />
                  </div>
                </div>
                <MimaText variant="small" mt={1} mb={1}>
                  Fee: {amountFormatter(fromCurrency).format(fee)}
                </MimaText>
                <MimaButton
                  title="Continue"
                  variant="form"
                  width={40}
                  loading={loading}
                  type="submit"
                  onClick={handleSubmit}
                />
              </>
            )}
          </Formik>
        </>
      )}

      {pinStage && (
        <Formik
          initialValues={{
            pin: "",
          }}
        >
          {({ handleChange, handleBlur, values, errors, touched }) => (
            <form onSubmit={onSubmit}>
              {loading ? (
                <PageLoader
                  title="Exchange in Progress...."
                  loading={loading}
                />
              ) : (
                <>
                  <MimaText align="center" width={40} variant="subtitleBold">
                    Enter your Pin to complete your fund Exchange from{" "}
                    <span style={{ color: "var(--color-fash-primary-3)" }}>
                      {amountFormatter(fromCurrency).format(
                        exchangeStore.exchangePayload.amount
                      )}{" "}
                      to{" "}
                      {amountFormatter(toCurrency).format(
                        exchangeStore.exchangeRateValue
                      )}
                    </span>
                  </MimaText>

                  <div className={styles.pin__group}>
                    <MimaText>Enter Pin</MimaText>
                    <div className={styles.pin__item}>
                      <OtpInput
                        value={pin}
                        onChange={(pin) => {
                          setPin(pin);
                          if (pin >= 1000) {
                            onSubmitPin(pin);
                          }
                        }}
                        numInputs={4}
                        isInputSecure={true}
                        inputStyle={{
                          fontSize: "2.4rem",
                          fontFamily: "inherit",
                          width: "6rem",
                          height: "6rem",
                          margin: ".4rem",
                          borderTop: "0px",
                          border: "1px solid #4A4A68",
                          borderRadius: "1rem",
                          display: "block",
                          outline: "none",
                          textAlign: "center",
                          marginRight: "2rem",
                        }}
                        isInputNum
                      />
                    </div>
                  </div>
                </>
              )}
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default WalletExchange;
