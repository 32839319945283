import {
  MimaButton,
  MimaDropZone,
  MimaInput,
  MimaText,
  MimaTextArea,
  Modal,
} from "../../components";
import styles from "../../assets/styles/Settings/Settings.module.scss";
import { MdModeEditOutline } from "react-icons/md";
import { BsSave } from "react-icons/bs";
import { Formik } from "formik";
import React from "react";
import useBusinessInfoLogic from "./useLogic/useBusinessInfoLogic";

const BusinessInfo = ({ closeModal, updateBusinessModal }) => {
  const {
    loading,
    onSubmit,
    validationSchema,
    business,

    editState,
    goToEditState,
    goBack,
  } = useBusinessInfoLogic({ closeModal, updateBusinessModal });

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <div className={styles.bus}>
        <div className={styles.bus__head}>
          <MimaText variant="subtitleBold">Business Information</MimaText>
          {!editState && (
            <div className={styles.bus__edit} onClick={goToEditState}>
              <MdModeEditOutline />
              <MimaText variant="small" color="var(--color-fash-primary-3)">
                Edit
              </MimaText>
            </div>
          )}
          {editState && (
            <div className={styles.bus__edit} onClick={goToEditState}>
              <BsSave />
              <MimaText variant="small" color="var(--color-fash-primary-3)">
                Save
              </MimaText>
            </div>
          )}
        </div>

        <div className={styles.bus__details}>
          <div className={styles.bus__img}>
            <img
              src={business?.logoUrl}
              alt="logo"
              className={styles.bus__logo}
            />
          </div>

          <MimaText labelTitle="Business Owner">
            {" "}
            {`${business?.users?.[0].firstname || ""} ${business?.users?.[0].lastname || ""}`}
          </MimaText>
          <MimaText labelTitle="Phone Number">{business?.mobile}</MimaText>
          <MimaText labelTitle="Email"> {business?.email}</MimaText>
          {!editState && (
            <div className={styles.bus__details}>
              <MimaText labelTitle="Business Name">
                {" "}
                {business?.tradingName}
              </MimaText>
              <MimaText labelTitle="Address">
                {business?.businessAddress}
              </MimaText>
              <MimaText labelTitle="Business Registration Number">
                {business?.businessRegNum}
              </MimaText>
              <MimaText labelTitle="Website Address">
                {" "}
                {business?.website}
              </MimaText>
              <MimaText labelTitle="Instagram Handle">
                {business?.instagramHandle}
              </MimaText>
              <MimaText labelTitle="Twitter Handle">
                {business?.twitterHandle}
              </MimaText>
            </div>
          )}
          {editState && (
            <div className={styles.bus__details}>
              <Formik
                initialValues={{
                  businessName: business?.tradingName,
                  address: business?.businessAddress
                    ? business?.businessAddress
                    : "",
                  regNumber: business?.businessRegNum
                    ? business?.businessRegNum
                    : "",
                  webAddress: business?.website ? business?.website : "",
                  instagram: business?.instagramHandle
                    ? business?.instagramHandle
                    : "",
                  twitter: business?.twitterHandle
                    ? business?.twitterHandle
                    : "",
                  logoUrl:
                    [
                      {
                        name: business?.logoUrl,
                        preview: business?.logoUrl,
                      },
                    ] || [],
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  values,
                }) => (
                  <div>
                    <MimaDropZone
                      title="Click to upload business logo"
                      // info="Min of 1 image and Max of 4 images"
                      maxNumOfImages={1}
                      files={values.logoUrl}
                      setFiles={(newFiles) => {
                        setFieldValue("logoUrl", newFiles);
                      }}
                      labelTitle="Upload Business logo"
                      name="value"
                    />

                    <MimaTextArea
                      type="text"
                      labelTitle="Address"
                      name="address"
                      variant="form"
                      textAreaVariant="type3"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // width={38}
                      height={10}
                    />
                    <MimaInput
                      type="text"
                      labelTitle="Instagram Handle"
                      name="instagram"
                      value={values.instagram}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="form"
                    />
                    <MimaInput
                      type="text"
                      labelTitle="Twitter Handle"
                      name="twitter"
                      value={values.twitter}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="form"
                    />

                    <MimaButton
                      title="Continue"
                      loading={loading}
                      type="submit"
                      onClick={handleSubmit}
                      variant="form"
                      mt={2}
                    />
                  </div>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BusinessInfo;
