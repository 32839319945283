import { useState } from "react";
// import * as yup from "yup";
import constant from "../../../utils/constant";
import { WebsiteController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import { handleImageUpload, removeEmptyKeys } from "../../../utils/utils";

const useWebsiteConfig = () => {
  const [loading, setLoading] = useState(false);
  const [menuBar, setMenuBar] = useState(true);
  const [homePageBanner, setHomePageBanner] = useState(false);
  const [aboutPage, setAboutPage] = useState(false);
  const [localShipping, setLocalShipping] = useState(false);
  const [internationalShipping, setInternationalShipping] = useState(false);
  const [refundPolicy, setRefundPolicy] = useState(false);
  const [terms, setTerms] = useState(false);
  const [faqs, setFaqs] = useState(false);
  const [sizeGuide, setSizeGuide] = useState(false);
  const [liveChat, setLiveChat] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [editMenuStyle, setEditMenuStyle] = useState(false);
  const [editHomePage, setEditHomePage] = useState(false);
  const [editAboutPage, setEditAboutPage] = useState(false);
  const [editLocalShipping, setEditLocalShipping] = useState(false);
  const [editInternationalShipping, setEditInternationalShipping] =
    useState(false);
  const [editRefundPolicy, setEditRefundPolicy] = useState(false);
  const [editTerms, setEditTerms] = useState(false);
  const [editFaq, setEditFaq] = useState(false);
  const [editSizeGuide, setEditSizeGuide] = useState(false);
  const [editMaintenance, setEditMaintenance] = useState(false);
  const [editLiveChat, setEditLiveChat] = useState(false);

  const closeEditState = () => {
    setEditMenuStyle(false);
    setEditHomePage(false);
    setEditAboutPage(false);
    setEditLocalShipping(false);
    setEditInternationalShipping(false);
    setEditRefundPolicy(false);
    setEditTerms(false);
    setEditFaq(false);
    setEditSizeGuide(false);
    setEditMaintenance(false);
    setEditLiveChat(false);
  };

  const goToMenuBar = () => {
    setMenuBar(true);
    closeEditState();
    setMaintenanceMode(false);
    setHomePageBanner(false);
    setAboutPage(false);
    setLocalShipping(false);
    setInternationalShipping(false);
    setRefundPolicy(false);
    setTerms(false);
    setFaqs(false);
    setSizeGuide(false);
    setLiveChat(false);
  };
  const goToHomePageBanner = () => {
    setHomePageBanner(true);
    setMaintenanceMode(false);
    setMenuBar(false);
    closeEditState();
    setAboutPage(false);
    setLocalShipping(false);
    setInternationalShipping(false);
    setRefundPolicy(false);
    setTerms(false);
    setFaqs(false);
    setSizeGuide(false);
    setLiveChat(false);
  };
  const goToAboutPage = () => {
    setAboutPage(true);
    setMaintenanceMode(false);
    setHomePageBanner(false);
    setMenuBar(false);
    closeEditState();
    setLocalShipping(false);
    setInternationalShipping(false);
    setRefundPolicy(false);
    setTerms(false);
    setFaqs(false);
    setSizeGuide(false);
    setLiveChat(false);
  };
  const goToLocalShipping = () => {
    setLocalShipping(true);
    setMaintenanceMode(false);
    setAboutPage(false);
    setHomePageBanner(false);
    setMenuBar(false);
    closeEditState();
    setInternationalShipping(false);
    setRefundPolicy(false);
    setTerms(false);
    setFaqs(false);
    setSizeGuide(false);
    setLiveChat(false);
  };
  const goToInternationalShipping = () => {
    setInternationalShipping(true);
    setMaintenanceMode(false);
    setLocalShipping(false);
    setAboutPage(false);
    setHomePageBanner(false);
    setMenuBar(false);
    closeEditState();
    setRefundPolicy(false);
    setTerms(false);
    setFaqs(false);
    setSizeGuide(false);
    setLiveChat(false);
  };
  const goToRefundPolicy = () => {
    setRefundPolicy(true);
    setMaintenanceMode(false);
    setInternationalShipping(false);
    setLocalShipping(false);
    setAboutPage(false);
    setHomePageBanner(false);
    setMenuBar(false);
    closeEditState();
    setTerms(false);
    setFaqs(false);
    setSizeGuide(false);
    setLiveChat(false);
  };
  const goToTerms = () => {
    setTerms(true);
    setMaintenanceMode(false);
    setRefundPolicy(false);
    setInternationalShipping(false);
    setLocalShipping(false);
    setAboutPage(false);
    setHomePageBanner(false);
    setMenuBar(false);
    closeEditState();
    setFaqs(false);
    setSizeGuide(false);
    setLiveChat(false);
  };
  const goToFaqs = () => {
    setFaqs(true);
    setMaintenanceMode(false);
    setTerms(false);
    setRefundPolicy(false);
    setInternationalShipping(false);
    setLocalShipping(false);
    setAboutPage(false);
    setHomePageBanner(false);
    setMenuBar(false);
    closeEditState();
    setSizeGuide(false);
    setLiveChat(false);
  };
  const goToSizeGuide = () => {
    setSizeGuide(true);
    setFaqs(false);
    setTerms(false);
    setRefundPolicy(false);
    setInternationalShipping(false);
    setLocalShipping(false);
    setAboutPage(false);
    setHomePageBanner(false);
    setMenuBar(false);
    closeEditState();
    setMaintenanceMode(false);
    setLiveChat(false);
  };
  const goToMaintenanceMode = () => {
    setMaintenanceMode(true);
    setSizeGuide(false);
    setFaqs(false);
    setTerms(false);
    setRefundPolicy(false);
    setInternationalShipping(false);
    setLocalShipping(false);
    setAboutPage(false);
    setHomePageBanner(false);
    setMenuBar(false);
    closeEditState();
    setLiveChat(false);
  };
  const goToLiveChat = () => {
    setLiveChat(true);
    setSizeGuide(false);
    setFaqs(false);
    setTerms(false);
    setRefundPolicy(false);
    setInternationalShipping(false);
    setLocalShipping(false);
    setAboutPage(false);
    setHomePageBanner(false);
    setMenuBar(false);
    setMaintenanceMode(false);
    closeEditState();
  };

  const navData = [
    {
      title: "Menu bar style",
      link: goToMenuBar,
    },
    {
      title: "Home Page Banner",
      link: goToHomePageBanner,
    },
    {
      title: "About Us Page",
      link: goToAboutPage,
    },
    {
      title: "Local Shipping",
      link: goToLocalShipping,
    },
    {
      title: "International Shipping",
      link: goToInternationalShipping,
    },
    {
      title: "Refund Policy",
      link: goToRefundPolicy,
    },
    {
      title: "Standard Terms of Services",
      link: goToTerms,
    },
    {
      title: "FAQs Page",
      link: goToFaqs,
    },
    {
      title: "Size Guide",
      link: goToSizeGuide,
    },
    {
      title: "Maintenance Mode",
      link: goToMaintenanceMode,
    },
    {
      title: "Live Chat",
      link: goToLiveChat,
    },
  ];

  const onSubmit = async (values) => {
    setLoading(true);
    let homeBannerUrl = [];
    const file = values.homeBannerImageUrl?.[0];
    if (file) {
      homeBannerUrl = [file.preview];
      if (file.name !== file.preview) {
        homeBannerUrl = await handleImageUpload(values?.homeBannerImageUrl);
      }
    }
    let aboutUsUrl = [];
    const file2 = values.aboutUsImageUrl?.[0];
    if (file2) {
      aboutUsUrl = [file2.preview];
      if (file2.name !== file2.preview) {
        aboutUsUrl = await handleImageUpload(values?.aboutUsImageUrl);
      }
    }

    const payload = {
      tawk: values.tawk,
      website: {
        homeBanner: {
          imageUrl: homeBannerUrl.length > 0 ? homeBannerUrl[0] : "",
          title: values.website.homeBanner.title,
        },
        collectionsMenuStyle: values.website.collectionsMenuStyle,
        aboutUs: {
          imageUrl: aboutUsUrl.length > 0 ? aboutUsUrl[0] : "",
          text: values.website.aboutUs.text,
        },
        faqs: values.website.faqs.map((faq) => ({
          question: faq.question,
          text: faq.text,
        })),
        sizeGuides: values.website.sizeGuides,

        shipping: {
          local: values.website.shipping.local,
          international: values.website.shipping.international,
        },
        maintenanceMode: values.website.maintenanceMode,
        refundPolicy: values.website.refundPolicy,
        refundPolicyDays: values.website.refundPolicyDays,
        useDefaultRefundPolicy: values.website.useDefaultRefundPolicy,
        termsOfService: values.website.termsOfService,
        useDefaulTermsOfService: values.website.useDefaulTermsOfService,
        privacyPolicy: values.website.privacyPolicy,
      },
    };

    removeEmptyKeys(payload);

    const { status, errorMessage } =
      await WebsiteController.configureWebsite(payload);

    setLoading(false);
    if (status === constant.Success) {
      closeEditState();
      MimaToastUtil.success({
        message: constant.Success,
      });
      return;
    }

    MimaToastUtil.error({
      message: errorMessage,
    });
  };

  return {
    navData,
    menuBar,
    homePageBanner,
    aboutPage,
    localShipping,
    internationalShipping,
    refundPolicy,
    terms,
    faqs,
    sizeGuide,
    maintenanceMode,
    goToMenuBar,
    loading,
    setLoading,
    onSubmit,
    setEditMenuStyle,
    editMenuStyle,
    setEditHomePage,
    editHomePage,
    setEditAboutPage,
    editAboutPage,
    setEditLocalShipping,
    setEditInternationalShipping,
    editInternationalShipping,
    setEditRefundPolicy,
    editRefundPolicy,
    setEditTerms,
    editTerms,
    setEditFaq,
    editFaq,
    setEditSizeGuide,
    editSizeGuide,
    setEditMaintenance,
    editMaintenance,
    editLocalShipping,
    liveChat,
    editLiveChat,
    setEditLiveChat,
  };
};

export default useWebsiteConfig;
