import React, { useState } from "react";
import * as yup from "yup";
import constant from "../../../utils/constant";
import { WebsiteController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import { useNavigate } from "react-router-dom";

const useCreateWebsiteLogic = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [domain, setDomain] = useState("");
  const { isValid, isFetching, data } =
    WebsiteController.useGetValidateDomain(domain);

  const oneWordSchema = yup.string().test(
    "is-one-word", // Test name
    "Spaces are not allowed", // Error message
    (value) => !value || value.trim().split(/\s+/).length === 1
  );

  const errorMessage = data?.errorMessage;

  const validationSchema = yup.object({
    subDomain: oneWordSchema
      .min(4, "Minimum of 4 characters")
      .max(20, "Maximum of 20 characters")
      .required("Subdomain name is required")
      .test("no-dots", "Dot is not allowed!", function (value) {
        const { path, createError } = this;
        return (
          !value ||
          !value.includes(".") ||
          createError({ path, message: "Dot is not allowed!" })
        );
      }),
  });

  const onSubmit = async (payload) => {
    setLoading(true);
    for (const key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }

    if (isValid) {
      const { status, errorMessage } =
        await WebsiteController.createWebsite(payload);
      if (status === constant.Success) {
        setLoading(false);
        MimaToastUtil.success({
          message: constant.Success,
        });
        navigate(constant.Routes.Styles);
        return;
      }
      setLoading(false);
      return MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };
  return {
    validationSchema,
    onSubmit,
    loading,
    setDomain,
    isFetching,
    errorMessage,
  };
};

export default useCreateWebsiteLogic;
