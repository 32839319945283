import React, { memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MimaText from '../MimaText';

const Root = styled(MenuItem)(({ theme, ...props }) => ({
  height: 40,
  width: '100%',
  paddingLeft: props.itempadding,
  cursor: 'pointer',
  textDecoration: 'none!important',
  '&:hover': {
    color: theme.palette.text.primary,
  },
  '&.active': {
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, .05)!important'
        : 'rgba(255, 255, 255, .1)!important',
    pointerEvents: 'none',
    transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
    '& > .menu-item-text-primary': {
      color: 'inherit',
    },
    '& > .menu-item-icon': {
      color: 'inherit',
    },
  },
  '& >.menu-item-icon': {
    color: 'inherit',
  },
  '& > .menu-item-text': {
    color: 'inherit',
  },
}));

const MimaMenuItem = (props) => {
  const { id, icon, item, onItemClick } = props;

  return useMemo(
    () => (
      <Root
        button
        onClick={event => onItemClick && onItemClick(event)}
        role="button"
      >
        <>
          {icon && (
            <ListItemIcon className="menu-item-icon">{icon}</ListItemIcon>
          )}
          <MimaText
            {...item.props}
            className={clsx('menu-item-text', item.className)}
          >
            {item.title}
          </MimaText>
        </>
      </Root>
    ),
    [id, item, onItemClick]
  );
}

MimaMenuItem.propTypes = {};

MimaMenuItem.defaultProps = {};

export default memo(MimaMenuItem);
