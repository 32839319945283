import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";

export default class OrdersStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "OrdersStore",
      properties: [
        "orders",
        "ordersAnalysis",
        "design",
        "designList",
        "singleItem",
        "orderSummary",
      ],
    });
  }

  orders = [];
  ordersAnalysis = [];
  design = {};
  designList = [];
  orderSummary = {};
  singleItem = {};

  reset = () => {
    this.orders = [];
    this.ordersAnalysis = [];
    this.designList = [];
    this.orderSummary = {};
    this.singleItem = {};
  };

  setOrders = async (state) => {
    runInAction(async () => {
      this.orders = state;
    });
  };

  setOrdersAnalysis = async (state) => {
    runInAction(async () => {
      this.ordersAnalysis = state;
    });
  };

  setDesign = async (state) => {
    runInAction(async () => {
      this.design = state;
    });
  };
  setDesignList = async (state) => {
    runInAction(async () => {
      this.designList = state;
    });
  };
  setSingleItem = async (state) => {
    runInAction(async () => {
      this.singleItem = state;
    });
  };
  setOrderSummary = async (state) => {
    runInAction(async () => {
      this.orderSummary = state;
    });
  };
}
