import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from 'mobx-persist-store';
import { number } from "prop-types";

export default class VirtualCardStore {
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'VirtualCardStore',
            properties: [
                'cardDetails',
                'card',
                'cardCharge',
            ]
        });
    }
    card = "";
    cardDetails = {};
    cardCharge = "";

    setCardDetails = async (cardDetails) => {
        runInAction(async () => {
            this.cardDetails = cardDetails;
        });
    }
    setCard = async (card) => {
        runInAction(async () => {
            this.card = card;
        });
    }
    setCardCharge = async (cardCharge) => {
        runInAction(async () => {
            this.cardCharge = cardCharge;
        });
    }

}
