/* eslint-disable no-unused-vars */
import {
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  ReceiptCheckBox as RCB,
  Modal,
  MimaDateInput,
} from "../../components";

import styles from "../../assets/styles/Invoice/Invoice.module.scss";
import { amountFormatter } from "../../utils/utils";
import constant from "../../utils/constant";
import { Formik } from "formik";
import React, { useMemo } from "react";
import { transactionStore } from "../../stores";
import moment from "moment";
import useUpdateInvoiceLogic from "./useLogic/useUpdateInvoiceLogic";

const UpdateInvoice = ({ closeModal }) => {
  const { balanceAmount, loading, invoice, onSubmit, validationSchema } =
    useUpdateInvoiceLogic({ closeModal });
  const transactions = transactionStore.transactions;

  const untaggedTransactions = useMemo(() => {
    const untaggedTransactions = transactions.filter((item) => {
      return item.inflowType === "Untagged" && item.category !== "OFFLINE";
    });
    return untaggedTransactions;
  }, [transactions]);

  const untaggedList = untaggedTransactions.map((y) => {
    return {
      key: y._id,
      value: `${moment(y.createdAt).format("DD/MM/YYYY h:mm a")}-${y.narration ? y?.narration : ""}-${amountFormatter(y.currencyCode).format(y.transactionAmount)}`,
      ...y,
    };
  });

  const statuses =
    invoice.status === constant.TRANSACTION_STATUS.UNPAID
      ? [
          {
            key: constant.TRANSACTION_STATUS.VOID,
            value: constant.TRANSACTION_STATUS.VOID,
          },
          {
            key: constant.TRANSACTION_STATUS.FULLY_PAID,
            value: constant.TRANSACTION_STATUS.FULLY_PAID,
          },
          {
            key: constant.TRANSACTION_STATUS.PARTIALLY_PAID,
            value: constant.TRANSACTION_STATUS.PARTIALLY_PAID,
          },
        ]
      : [
          {
            key: constant.TRANSACTION_STATUS.VOID,
            value: constant.TRANSACTION_STATUS.VOID,
          },
        ];

  return (
    <div>
      <MimaText
        variant="subtitle"
        color="var(--color-fash-primary-3)"
        align="center"
      >
        {`Update ${invoice.status === constant.TRANSACTION_STATUS.UNPAID ? "Invoice" : "Receipt"} Status`}
      </MimaText>
      <MimaText variant="small" align="center" mb={4}>
        Manually update the status of your invoice
      </MimaText>
      <Formik
        initialValues={{
          paidDate: "",
          paymentMethod: "",
          status: "",
          amountPaid: 0,
          walletInflow: false,
          untagged: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div>
            <div className={styles.summary__to}>
              <MimaText>Invoice To</MimaText>
              <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
                {Array.isArray(invoice.customer)
                  ? invoice.customer[0].fullname
                  : invoice.customer.fullname}
              </MimaText>
              <div className={styles.summary__line}></div>
            </div>
            <div className={styles.summary__total}>
              <MimaText>Invoice Total Amount</MimaText>
              <MimaText variant="bodyBold" color="var(--color-fash-primary-3)">
                {amountFormatter(invoice.currencyCode).format(invoice.deposit)}
              </MimaText>
            </div>
            <MimaDropdown
              labelTitle="Invoice Status"
              placeholder="Select Status"
              name="status"
              currentValue={values.status}
              id="status"
              onChange={(data) => {
                setFieldValue("status", data.key);
              }}
              data={statuses}
            />
            {errors.status ? (
              <div className={styles.error}>
                {""}
                {touched.status && errors.status}
                {""}
              </div>
            ) : null}

            {values.status !== constant.TRANSACTION_STATUS.VOID &&
              invoice.status === constant.TRANSACTION_STATUS.UNPAID && (
                <>
                  <div className={styles.invoice__checks_group}>
                    <MimaText variant="small">
                      Did you receive this payment into Mima Account?
                    </MimaText>
                    <div className={styles.invoice__checks}>
                      <RCB
                        title="Yes"
                        onChange={() => {
                          setFieldValue("walletInflow", true);
                        }}
                        checked={values.walletInflow}
                        value="true"
                        name="walletInflow"
                      />
                      <RCB
                        title="No"
                        checked={!values.walletInflow}
                        onChange={() => setFieldValue("walletInflow", false)}
                        value="false"
                        name="walletInflow"
                      />
                    </div>
                    {errors.walletInflow ? (
                      <div className={styles.error}>
                        {""}
                        {touched.walletInflow && errors.walletInflow}
                        {""}
                      </div>
                    ) : null}
                  </div>

                  {values.walletInflow && (
                    <MimaDropdown
                      labelTitle="Select Untagged Transaction"
                      name="untagged"
                      value={values.untagged}
                      placeholder={
                        untaggedList.length === 0
                          ? "No Untagged Transaction"
                          : "Select Untagged Transaction"
                      }
                      id="untagged"
                      currentValue={values.untagged}
                      onChange={(data) => {
                        setFieldValue("untagged", data.key);
                        const selectedObject = untaggedList.find(
                          (item) => item.key === data.key
                        );

                        transactionStore.setUntagged(selectedObject);

                        if (selectedObject) {
                          setFieldValue(
                            "amountPaid",
                            selectedObject.transactionAmount.toFixed(2)
                          );

                          setFieldValue(
                            "paymentMethod",
                            selectedObject.paymentMethod
                          );
                          setFieldValue(
                            "paidDate",
                            new Date(selectedObject.createdAt)
                          );
                        }
                      }}
                      data={untaggedList}
                      touched={touched.untagged}
                      error={errors.untagged}
                    />
                  )}

                  <div className={styles.invoice__beside}>
                    <div>
                      <MimaInput
                        type="number"
                        labelTitle="Amount Received"
                        placeholder="0.00"
                        name="amountPaid"
                        width={38}
                        disabled={
                          values.status ===
                          constant.TRANSACTION_STATUS.FULLY_PAID
                            ? true
                            : false
                        }
                        value={
                          values.status ===
                            constant.TRANSACTION_STATUS.FULLY_PAID &&
                          values.walletInflow === false
                            ? invoice.deposit.toFixed(2)
                            : values.amountPaid
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        mr={2}
                      />

                      {errors.amountPaid ? (
                        <div className={styles.error}>
                          {""}
                          {touched.amountPaid && errors.amountPaid}
                          {""}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <MimaDateInput
                        labelTitle="Paid date"
                        type="date"
                        name="paidDate"
                        value={values.paidDate}
                        onChange={(text) => {
                          setFieldValue("paidDate", text);
                        }}
                        onBlur={handleBlur}
                        width={38}
                        touched={touched.paidDate}
                        error={errors.paidDate}
                        mr={2}
                      />
                    </div>
                  </div>
                  <MimaButton
                    title={`Expected Balance Amount:
														${balanceAmount(values)}`}
                    variant="text"
                    type="button"
                    width={38}
                  />

                  <MimaText variant="small" ml={2}>
                    Payment Method
                  </MimaText>
                  <div className={styles.invoice__checks}>
                    <RCB
                      title="Cash"
                      id="CASH"
                      name="paymentMethod"
                      onChange={() => {
                        setFieldValue("paymentMethod", "CASH");
                      }}
                      checked={values.paymentMethod === "CASH"}
                      value="cash"
                    />
                    <RCB
                      title="Transfer"
                      onChange={() => {
                        setFieldValue("paymentMethod", "TRANSFER");
                      }}
                      checked={values.paymentMethod === "TRANSFER"}
                      id="transfer"
                      name="paymentMethod"
                      value="transfer"
                    />
                    <RCB
                      title="P.O.S"
                      id="pos"
                      onChange={() => {
                        setFieldValue("paymentMethod", "POS");
                      }}
                      checked={values.paymentMethod === "POS"}
                      name="paymentMethod"
                      value="pos"
                    />
                  </div>
                  {errors.paymentMethod ? (
                    <div className={styles.error}>
                      {touched.paymentMethod && errors.paymentMethod}
                    </div>
                  ) : null}
                </>
              )}

            {values.status === constant.TRANSACTION_STATUS.FULLY_PAID ? (
              <>
                {values.walletInflow === true &&
                values.amountPaid !== invoice.deposit.toFixed(2) ? (
                  <MimaText
                    variant="small"
                    width={40}
                    color="var(--color-error)"
                    mb={2}
                  >
                    <strong>Note:</strong> To update to Fully Paid, Untagged
                    transaction amount must be same as invoice amount
                  </MimaText>
                ) : (
                  <>
                    <MimaText variant="small" width={40} mb={1} mt={2}>
                      The amount paid is added to your income and the invoice is
                      updated into a receipt.
                    </MimaText>
                    <MimaText
                      variant="small"
                      width={40}
                      color="var(--color-error)"
                      mb={2}
                    >
                      <strong>Note:</strong> Issuing another receipt for this
                      transaction would result in duplicate income.
                    </MimaText>
                  </>
                )}
              </>
            ) : values.status === constant.TRANSACTION_STATUS.PARTIALLY_PAID ? (
              <>
                <MimaText variant="small" width={40} mb={1} mt={2}>
                  The partially paid amount is added to your income, and the
                  invoice is updated into a partially paid receipt.
                </MimaText>
                <MimaText
                  variant="small"
                  width={40}
                  color="var(--color-error)"
                  mb={2}
                >
                  <strong>Note:</strong> To avoid duplicate income, do not
                  create a receipt for this transaction. Additionally, an
                  automated invoice is created for the outstanding balance.
                </MimaText>
              </>
            ) : values.status === constant.TRANSACTION_STATUS.VOID ? (
              <>
                <MimaText variant="small" width={40} mt={2} mb={2}>
                  The invoice is cancelled.
                </MimaText>
              </>
            ) : null}
            <MimaButton
              title="Update"
              onClick={handleSubmit}
              type="submit"
              loading={loading}
              variant="form"
              mt={2}
              disabled={
                values.status === constant.TRANSACTION_STATUS.FULLY_PAID &&
                values.walletInflow === true &&
                values.amountPaid !== invoice.deposit.toFixed(2)
                  ? true
                  : false
              }
            />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default UpdateInvoice;
