import {
  MimaButton,
  MimaDateInput,
  MimaDropdown,
  MimaInput,
  MimaText,
  MimaTextArea,
  ReceiptCheckBox as RCB,
  MimaPaginatedDropdown,
} from "../../components";
import styles from "../../assets/styles/Invoice/Invoice.module.scss";
import React from "react";
import { currencies } from "../../utils/utils";
import { Formik, Field, FieldArray } from "formik";
import { businessStore } from "../../stores";
import useEditQuotationLogic from "./useLogic/useEditQuotationLogic";

const FieldInput = ({ field, ...props }) => {
  return <MimaInput {...field} {...props} />;
};

const FieldTextArea = ({ field, ...props }) => {
  return <MimaTextArea {...field} {...props} />;
};

const FieldPaginatedDropdown = ({ field, ...props }) => {
  return <MimaPaginatedDropdown {...field} {...props} />;
};

const DuplicateQuote = ({ closeModal, quote }) => {
  const { validationSchemaDuplicateQuote, onSubmitDuplicateQuote, loading } =
    useEditQuotationLogic(quote, closeModal);
  return (
    <div>
      <MimaText
        variant="subtitle"
        color="var(--color-fash-primary-3)"
        align="center"
        mb={4}
      >
        Duplicate Quotation
      </MimaText>
      <Formik
        initialValues={{
          customer: "",
          customerName: "",
          orders: quote.orders,
          selectFromStock: quote.orders?.[0] === undefined ? false : true,
          vat: !!(quote.vat && quote.vat.amount),
          wht: !!(quote.wht && quote.wht.amount),
          dueDate: "",
          currencyCode: quote.currencyCode,
          discountAmount: quote.discountAmount || 0,
          paymentMethod: quote.paymentMethod || "",
          note: quote.note,
          deposit: quote.deposit,
          discountPer: quote.discountPer || 0,
          depositPer: 100,
          shipping: quote.shipping || 0,
        }}
        validationSchema={validationSchemaDuplicateQuote}
        onSubmit={onSubmitDuplicateQuote}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => {
          return (
            <div>
              <MimaPaginatedDropdown
                mb={2}
                labelTitle="Bill To"
                placeholder="Select Customer"
                name="customer"
                value={values.customerName}
                id="customer"
                optionLabel="fullname"
                touched={touched.customer}
                error={errors.customer}
                url="/customers/business"
                queryKey={[businessStore.bId, "customers"]}
                getPageValue={(page) => page?.data?.customers || []}
                getPageTotalCount={(lastPage) =>
                  lastPage?.data?.totalCounts || 0
                }
                onChange={(data) => {
                  setFieldValue("customer", data._id);
                  setFieldValue("customerName", data.fullname);
                }}
              />

              <MimaDropdown
                labelTitle="Select Currency"
                name="currencyCode"
                value={values.currencyCode}
                placeholder={values.currencyCode}
                id="currencyCode"
                currentValue={values.currencyCode}
                onChange={(data) => {
                  setFieldValue("currencyCode", data.key);
                }}
                data={currencies}
                touched={touched.currencyCode}
                error={errors.currencyCode}
                mb={3}
              />
              <RCB
                title="Select Product from Stock"
                // onChange={e => {
                // 	setFieldValue(
                // 		'selectFromStock',
                // 		e.target.checked
                // 	);
                // 	setFieldValue(
                // 		'orders', [
                // 		{
                // 			quantity: 1,
                // 			unitPrice: 0,
                // 			item: '',
                // 			stock: '',
                // 		},
                // 	]
                // 	)
                // }}
                checked={values.selectFromStock}
                value={String(values.selectFromStock)}
                name="selectFromStock"
              />
              <FieldArray name="orders">
                {({ push, remove }) => (
                  <React.Fragment>
                    {values?.orders?.map((_, i) => (
                      <div key={i}>
                        {!values.selectFromStock && (
                          <Field
                            name={`orders.${i}.item`}
                            id={`orders.${i}.item`}
                            component={FieldTextArea}
                            type="text"
                            placeholder="Product/Service"
                            labelTitle="Product/Service"
                            mt={1}
                            touched={touched?.orders?.[i]?.item}
                            error={errors?.orders?.[i]?.item}
                          />
                        )}

                        {values.selectFromStock ? (
                          <Field
                            type="text"
                            mt={2}
                            name={`orders.${i}.item`}
                            id={`orders.${i}.item`}
                            value={values.orders[i].item}
                            queryKey={[businessStore.bId, "stock"]}
                            optionLabel="name"
                            component={FieldPaginatedDropdown}
                            labelTitle="Product/Service"
                            placeholder={
                              values.orders[i].item || "Select from Stock"
                            }
                            touched={touched?.orders?.[i]?.item}
                            error={errors?.orders?.[i]?.item}
                            url="/products/stocks/business"
                            getPageValue={(page) => page?.data?.value || []}
                            getPageTotalCount={(lastPage) =>
                              lastPage?.data?.totalCounts || 0
                            }
                            onChange={(data) => {
                              setFieldValue(`orders.${i}.stock`, data._id);
                              setFieldValue(`orders.${i}.item`, data.name);
                              setFieldValue(
                                `orders.${i}.unitPrice`,
                                data.sellingPrice
                              );
                            }}
                          />
                        ) : null}

                        <div className={styles.invoice__beside}>
                          <Field
                            name={`orders.${i}.unitPrice`}
                            component={FieldInput}
                            type="number"
                            min="0"
                            labelTitle="Price"
                            width={18}
                            // disabled={values.selectFromStock}
                            touched={touched?.orders?.[i]?.unitPrice}
                            error={errors?.orders?.[i]?.unitPrice}
                          />
                          <Field
                            name={`orders.${i}.quantity`}
                            component={FieldInput}
                            type="number"
                            min="0"
                            labelTitle="Quantity"
                            width={18}
                            touched={touched?.orders?.[i]?.quantity}
                            error={errors?.orders?.[i]?.quantity}
                          />
                        </div>
                        {i !== 0 ? (
                          <MimaButton
                            title="Remove"
                            variant="filter"
                            type="button"
                            buttonColor="var(--color-error)"
                            width={10}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    {values.orders?.length > 0 && (
                      <MimaButton
                        title="Add Another Item"
                        variant="text"
                        type="button"
                      />
                    )}
                  </React.Fragment>
                )}
              </FieldArray>
              <>
                <MimaInput
                  type="number"
                  labelTitle="Shipping Fee"
                  placeholder="0.00"
                  name="shipping"
                  mb={1}
                  min={0}
                  value={values.shipping}
                  onBlur={handleBlur}
                />
                <div className={styles.invoice__beside}>
                  <MimaInput
                    type="number"
                    labelTitle="Deposit %"
                    placeholder="0%"
                    name="depositPer"
                    mb={1}
                    min={0}
                    max={100}
                    value={values.depositPer}
                    onBlur={handleBlur}
                    width={18}
                    touched={touched.depositPer}
                    error={errors.depositPer}
                  />
                  <MimaInput
                    type="number"
                    labelTitle="Deposit Amount"
                    placeholder="0"
                    name="deposit"
                    mb={1}
                    min={0}
                    max={100}
                    value={values.deposit}
                    onBlur={handleBlur}
                    width={18}
                    touched={touched.deposit}
                    error={errors.deposit}
                  />
                </div>
                <MimaDateInput
                  labelTitle="Valid date"
                  name="dueDate"
                  value={values.dueDate}
                  onChange={(text) => {
                    setFieldValue("dueDate", text);
                  }}
                  onBlur={handleBlur}
                  touched={touched.dueDate}
                  error={errors.dueDate}
                />
                <div className={styles.invoice__checks_group}>
                  <MimaText variant="small">
                    Do you want to charge VAT?
                  </MimaText>
                  <div className={styles.invoice__checks}>
                    <RCB
                      title="Yes"
                      checked={values.vat}
                      value="true"
                      name="vat"
                    />
                    <RCB
                      title="No"
                      checked={!values.vat}
                      value="false"
                      name="vat"
                    />
                  </div>
                </div>
                <div className={styles.invoice__checks_group}>
                  <MimaText variant="small">
                    Do you want to deduct WHT?
                  </MimaText>
                  <div className={styles.invoice__checks}>
                    <RCB
                      title="Yes"
                      checked={values.wht}
                      value="true"
                      name="wht"
                    />
                    <RCB
                      title="No"
                      checked={!values.wht}
                      value="false"
                      name="wht"
                    />
                  </div>
                </div>
              </>

              <MimaTextArea
                type="text"
                labelTitle="Note or Terms and Conditions"
                name="note"
                variant="wide"
                value={values.note}
                onBlur={handleBlur}
                height={10}
              />

              <MimaButton
                title="Continue"
                onClick={handleSubmit}
                type="submit"
                loading={loading}
                variant="regular"
                width={38}
                mt={2}
              />
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default DuplicateQuote;
