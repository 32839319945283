import styles from "../../assets/styles/MimaInput.module.scss";
import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import * as inputStyles from "./styles";
import { MdClose } from "react-icons/md";
import MimaText from "../MimaText";

const propTypes = {
  labelTitle: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.any,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.any,
  fontSize: PropTypes.number,
  suggestionData: PropTypes.array,
  handleFilter: PropTypes.func,
  note: PropTypes.string,
};

export const MimaTagInput = ({
  labelTitle,
  placeholder,
  onBlur,
  onChange: origOnChange,
  value,
  disabled = false,
  width,
  height,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  touched,
  error,
  fontSize,
  suggestionData,
  handleFilter,
  tags,
  setTags,
  ...props
}) => {
  //for tags
  // const [tags, setTags] = useState([]);

  //Add tags
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      const multiTags = inputValue.split(",");

      if (inputValue.includes(",")) {
        setTags(
          tags.concat(multiTags.filter((item) => tags.indexOf(item) < 0))
        );
      } else if (!tags.includes(inputValue)) {
        setTags([...tags, inputValue]);
      }

      // setTags([...tags, event.target.value]);
      // event.target.value = "";
      setInputValue("");
    } else if (
      event.key === "Backspace" &&
      tags.length &&
      event.target.value == 0
    ) {
      const tagsCopy = [...tags];
      tagsCopy.pop();
      event.preventDefault();
      setTags(tagsCopy);
    }
  };

  //Remove tags
  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  //Making tags come from a particular data selection
  const [inputValue, setInputValue] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);
  const [clickedSuggestion, setClickedSuggestion] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const filterData = (text) => {
    if (suggestionData?.length > 0) {
      return suggestionData.filter((item) =>
        handleFilter(item, text?.toLowerCase())
      );
    }
  };

  const onChangeHandler = (e) => {
    const text = e.target.value;

    setInputValue(text);
    if (text && text.length > 0 && suggestionData?.length > 0) {
      setFilteredData(filterData(text));
    } else {
      setFilteredData([]);
    }
    setMenuVisible(true);

    if (inputValue === "") {
      setMenuVisible(false);
    }
  };

  const onBlurHandler = (event) => {
    if (event.target.value !== "" && !clickedSuggestion) {
      const multiTags = inputValue.split(",");

      if (inputValue.includes(",")) {
        setTags(
          tags.concat(multiTags.filter((item) => tags.indexOf(item) < 0))
        );
      } else if (!tags.includes(inputValue)) {
        setTags([...tags, inputValue]);
      }

      // setTags([...tags, event.target.value]);
      // event.target.value = "";
      setInputValue("");
    }
  };

  const suggestionClickHandler = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setClickedSuggestion(true);
    if (!tags.includes(item.a)) {
      // arr.push(str);
      setTags([...tags, item.a]);
    }
    setInputValue("");
    setMenuVisible(false);
    setTimeout(() => {
      setClickedSuggestion(false);
    }, 10);
  };
  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
      <label className={styles.form__label}>{labelTitle}</label>
      <InnerDivSkeleton>
        <div className={styles.tags}>
          {tags.map((tag, index) => (
            <div className={styles.singleTag} key={index}>
              <span>{tag}</span>
              <i onClick={() => removeTags(index)}>
                <MdClose />
              </i>
            </div>
          ))}
        </div>
        <TagInputSkeleton
          placeholder={placeholder}
          onBlur={(e) => {
            onBlur(e);
            onBlurHandler(e);
          }}
          disabled={disabled}
          onChange={onChangeHandler}
          width={width}
          value={inputValue}
          id={id}
          name={name}
          height={height}
          fontSize={fontSize}
          onKeyDown={handleKeyDown}
          onFocus={() => setClickedSuggestion(false)}
        />

        {menuVisible && filteredData && suggestionData?.length > 0 ? (
          <div>
            <MimaText variant="xsmall" mb={0.5}>
              Suggestions
            </MimaText>
            <div className={styles.suggestions__all}>
              {filteredData.map((item, i) => (
                <div
                  key={i}
                  onMouseDown={(e) => suggestionClickHandler(e, item)}
                  className={styles.suggestions}
                >
                  {item.a}
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </InnerDivSkeleton>

      {error ? <div className={styles.error}>{touched && error}</div> : null}
    </DivSkeleton>
  );
};

const TagInputSkeleton = styled.input`
  ${inputStyles.tagInput};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(props) => (props.height ? `height: ${props.height}rem` : "")};
  ${(touched) => (touched.id || touched.name ? `color: var(--color-dark)` : "")}
`;

const DivSkeleton = styled.div`
  ${inputStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const InnerDivSkeleton = styled.div`
  ${inputStyles.innerDivBase};
`;

MimaTagInput.propTypes = propTypes;

export default MimaTagInput;
