import {
	IconButton,
	Sidebar,
	MimaButton,
	MimaText,
	SelectBank,
} from '../../../components';
import styles from '../../../assets/styles/Wallet/TransferFunds.module.scss';
import TransferFailedImage from '../../../assets/img/MimaActionFailed.png';
import constant from '../../../utils/constant';
import React from 'react';

const BankList = ({ onSelectBank, banks }) => {
	return (
		<div>
			<MimaText align="center" variant="subtitle" mb={3}>
				Select Bank
			</MimaText>

			<SelectBank
				onSelectBank={onSelectBank}
				data={banks}
			/>
		</div>
	);
};

export default BankList;
