import {
  BusinessController,
  SettingsController,
  WalletController,
} from "../../../controllers";
import { appStore, businessStore, walletStore } from "../../../stores";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { amountFormatter, useGeoLocation } from "../../../utils/utils";
import * as yup from "yup";

const useSubscriptionsLogic = ({ closeModal }) => {
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(
    constant.SUBSCRIPTION_TYPE.BASIC
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showSummary, setShowSummary] = useState(false);
  const [changePlan, setChangePlan] = useState(false);
  const [cancelPlan, setCancelPlan] = useState(false);
  const [planChangeCost, setPlanChangeCost] = useState({});
  const [subscriptionSummary, setSubscriptionSummary] = useState({});
  const [planName, setPlanName] = useState("");

  const { country } = useGeoLocation();

  const planQuery = useMemo(() => {
    return `countryCode=${country}&app=FASH`;
  }, [country]);

  const getSubscriptions = async () => {
    setLoading(true);
    await Promise.all([
      BusinessController.getAllSubscriptions(planQuery),
      WalletController.walletDetails(),
      SettingsController.getGeneralConfig(),
    ]);
    setLoading(false);
  };

  useEffect(() => {
    if (country) {
      getSubscriptions();
    }
  }, [country]);

  const { wallets } = walletStore;
  const { subscriptionPlans, business } = businessStore;
  const { subscription: currentSubscription } = business || {};
  const { generalSettings } = appStore;

  const goBack = () => {
    if (changePlan) {
      setChangePlan(false);
    } else if (cancelPlan) {
      setCancelPlan(false);
    } else if (showSummary) {
      setShowSummary(false);
    } else {
      closeModal();
    }
  };

  const calculatePrice = (months, amount, discount) => {
    return (amount * months * (100 - discount)) / 100;
  };

  const isInfluencer = business.isInfluencer;

  const basicPricing = useMemo(() => {
    const basicPlan = subscriptionPlans.find(
      (x) => x.name === constant.SUBSCRIPTION_TYPE.BASIC
    );

    return [
      {
        index: 0,
        _id: basicPlan?._id,
        plan: basicPlan?.name,
        discount: 0,
        influencerDiscount: 0,
        price: basicPlan?.amount,
        period: "Monthly",
        duration: "1",
        coverage: basicPlan?.coverage,
        noCoverage: basicPlan?.noCoverage,
        description:
          "For small and medium sized businesses. Billed every month. Cancel anytime.",
      },
      {
        index: 1,
        _id: basicPlan?._id,
        plan: basicPlan?.name,
        discount: isInfluencer
          ? basicPlan?.influencerThreeMonthsDiscountPer
          : basicPlan?.threeMonthsDiscountPer,
        price: calculatePrice(
          3,
          basicPlan?.amount,
          isInfluencer
            ? basicPlan?.influencerThreeMonthsDiscountPer
            : basicPlan?.threeMonthsDiscountPer
        ),
        period: "Quarterly",
        duration: "3",
        coverage: basicPlan?.coverage,
        noCoverage: basicPlan?.noCoverage,
        description:
          "For small and medium sized businesses. Billed every quarter. Cancel anytime.",
      },
      {
        index: 2,
        _id: basicPlan?._id,
        plan: basicPlan?.name,
        discount: isInfluencer
          ? basicPlan?.influencerSixMonthsDiscountPer
          : basicPlan?.sixMonthsDiscountPer,
        price: calculatePrice(
          6,
          basicPlan?.amount,
          isInfluencer
            ? basicPlan?.influencerSixMonthsDiscountPer
            : basicPlan?.sixMonthsDiscountPer
        ),
        period: "Biannually",
        duration: "6",
        coverage: basicPlan?.coverage,
        noCoverage: basicPlan?.noCoverage,
        description:
          "For small and medium sized businesses. Billed every six months. Cancel anytime.",
      },
      {
        index: 3,
        _id: basicPlan?._id,
        plan: basicPlan?.name,
        discount: isInfluencer
          ? basicPlan?.influencerOneYearDiscountPer
          : basicPlan?.oneYearDiscountPer,
        price: calculatePrice(
          12,
          basicPlan?.amount,
          isInfluencer
            ? basicPlan?.influencerOneYearDiscountPer
            : basicPlan?.oneYearDiscountPer
        ),
        period: "Yearly",

        duration: "12",
        coverage: basicPlan?.coverage,
        noCoverage: basicPlan?.noCoverage,
        description:
          "For small and medium sized businesses. Billed every year. Cancel anytime.",
      },
    ];
  }, [isInfluencer, subscriptionPlans]);

  const premiumPricing = useMemo(() => {
    const premiumPlan = subscriptionPlans.find(
      (x) => x.name === constant.SUBSCRIPTION_TYPE.PREMIUM
    );

    return [
      {
        index: 4,
        _id: premiumPlan?._id,
        plan: premiumPlan?.name,
        discount: 0,
        influencerDiscount: 0,
        price: premiumPlan?.amount,
        influencerPrice: premiumPlan?.amount,
        period: "Monthly",
        duration: "1",
        coverage: premiumPlan?.coverage,
        noCoverage: premiumPlan?.noCoverage,
        description:
          "For small and medium sized businesses. Billed every month. Cancel anytime.",
      },
      {
        index: 5,
        _id: premiumPlan?._id,
        plan: premiumPlan?.name,
        discount: isInfluencer
          ? premiumPlan?.influencerThreeMonthsDiscountPer
          : premiumPlan?.threeMonthsDiscountPer,
        price: calculatePrice(
          3,
          premiumPlan?.amount,
          isInfluencer
            ? premiumPlan?.influencerThreeMonthsDiscountPer
            : premiumPlan?.threeMonthsDiscountPer
        ),
        period: "Quarterly",
        duration: "3",
        coverage: premiumPlan?.coverage,
        noCoverage: premiumPlan?.noCoverage,
        description:
          "For small and medium sized businesses. Billed every quarter. Cancel anytime.",
      },
      {
        index: 6,
        _id: premiumPlan?._id,
        plan: premiumPlan?.name,
        discount: isInfluencer
          ? premiumPlan?.influencerSixMonthsDiscountPer
          : premiumPlan?.sixMonthsDiscountPer,
        price: calculatePrice(
          6,
          premiumPlan?.amount,
          isInfluencer
            ? premiumPlan?.influencerSixMonthsDiscountPer
            : premiumPlan?.sixMonthsDiscountPer
        ),
        period: "Biannually",
        duration: "6",
        coverage: premiumPlan?.coverage,
        noCoverage: premiumPlan?.noCoverage,
        description:
          "For small and medium sized businesses. Billed every six months. Cancel anytime.",
      },
      {
        index: 7,
        _id: premiumPlan?._id,
        plan: premiumPlan?.name,
        discount: isInfluencer
          ? premiumPlan?.influencerOneYearDiscountPer
          : premiumPlan?.oneYearDiscountPer,
        price: calculatePrice(
          12,
          premiumPlan?.amount,
          isInfluencer
            ? premiumPlan?.influencerOneYearDiscountPer
            : premiumPlan?.oneYearDiscountPer
        ),
        period: "Yearly",
        duration: "12",
        coverage: premiumPlan?.coverage,
        noCoverage: premiumPlan?.noCoverage,
        description:
          "For small and medium sized businesses. Billed every year. Cancel anytime.",
      },
    ];
  }, [subscriptionPlans, isInfluencer]);

  const enterprisePricing = useMemo(() => {
    const enterprisePlan = subscriptionPlans.find(
      (x) => x.name === constant.SUBSCRIPTION_TYPE.ENTERPRISE
    );

    return [
      {
        index: 8,
        _id: enterprisePlan?._id,
        plan: enterprisePlan?.name,
        discount: 0,
        influencerDiscount: 0,
        price: enterprisePlan?.amount,
        influencerPrice: enterprisePlan?.amount,
        period: "Monthly",
        duration: "1",
        coverage: enterprisePlan?.coverage,
        noCoverage: enterprisePlan?.noCoverage,
        description:
          "For medium and large scale businesses. Billed every month. Cancel anytime.",
      },
      {
        index: 9,
        _id: enterprisePlan?._id,
        plan: enterprisePlan?.name,
        discount: isInfluencer
          ? enterprisePlan?.influencerThreeMonthsDiscountPer
          : enterprisePlan?.threeMonthsDiscountPer,
        price: calculatePrice(
          3,
          enterprisePlan?.amount,
          isInfluencer
            ? enterprisePlan?.influencerThreeMonthsDiscountPer
            : enterprisePlan?.threeMonthsDiscountPer
        ),
        period: "Quarterly",
        duration: "3",
        coverage: enterprisePlan?.coverage,
        noCoverage: enterprisePlan?.noCoverage,
        description:
          "For medium and large scale businesses. Billed every quarter. Cancel anytime.",
      },
      {
        index: 10,
        _id: enterprisePlan?._id,
        plan: enterprisePlan?.name,
        discount: isInfluencer
          ? enterprisePlan?.influencerSixMonthsDiscountPer
          : enterprisePlan?.sixMonthsDiscountPer,
        price: calculatePrice(
          6,
          enterprisePlan?.amount,
          isInfluencer
            ? enterprisePlan?.influencerSixMonthsDiscountPer
            : enterprisePlan?.sixMonthsDiscountPer
        ),
        period: "Biannually",
        duration: "6",
        coverage: enterprisePlan?.coverage,
        noCoverage: enterprisePlan?.noCoverage,
        description:
          "For medium and large scale businesses. Billed every six months. Cancel anytime.",
      },
      {
        index: 11,
        _id: enterprisePlan?._id,
        plan: enterprisePlan?.name,
        discount: isInfluencer
          ? enterprisePlan?.influencerOneYearDiscountPer
          : enterprisePlan?.oneYearDiscountPer,
        price: calculatePrice(
          12,
          enterprisePlan?.amount,
          isInfluencer
            ? enterprisePlan?.influencerOneYearDiscountPer
            : enterprisePlan?.oneYearDiscountPer
        ),
        period: "Yearly",
        duration: "12",
        coverage: enterprisePlan?.coverage,
        noCoverage: enterprisePlan?.noCoverage,
        description:
          "For medium and large scale businesses. Billed every year. Cancel anytime.",
      },
    ];
  }, [subscriptionPlans, isInfluencer]);

  const plansPricing = {
    basicPricing: basicPricing,
    premiumPricing: premiumPricing,
    enterprisePricing: enterprisePricing,
  };

  const onContinueClick = () => {
    if (subscriptionSummary?._id) {
      setShowSummary(true);
    } else {
      MimaToastUtil.error({
        message: "Please select a plan",
      });
    }
  };

  const onSelectPlan = (plan) => {
    setSubscriptionSummary(plan);
    setSelectedIndex(plan.index);
  };

  const subscribeAction = async () => {
    setLoading(true);

    const payload = {
      plan: subscriptionSummary._id,
      months: subscriptionSummary.duration,
    };

    const { status, errorMessage, data } =
      await BusinessController.subscribe(payload);

    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    } else {
      setLoading(false);
      if (data?.url) {
        MimaToastUtil.info({
          message: "redirecting to Paystack",
        });
        return window.open(data?.url, "_self");
      }
      return MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  const upgradePlans = useMemo(() => {
    const planFilter = subscriptionPlans
      .filter((x) => x.name !== business.plan.name)
      .map((y) => ({
        planId: y._id,
        planName: y.name,
        coverage: y.coverage,
        noCoverage: y?.noCoverage,
      }));

    return planFilter;
  }, [subscriptionPlans, business]);

  const getCost = async (plans) => {
    const payload = {
      plan: plans.planId,
      months:
        currentSubscription.months !== 1200 ? currentSubscription.months : 12,
    };

    const { status, data } = await BusinessController.planChangeCost(payload);

    if (status === constant.Success) {
      setPlanChangeCost(data);
    }
  };

  const upgradeDowngradeHandler = async (values) => {
    if (!subscriptionSummary.planId) {
      return MimaToastUtil.error({
        message: "Select a plan to upgrade or downgrade to.",
      });
    }

    const ngnBalance =
      wallets.find((x) => x.currencyCode === "NGN")?.balance || 0;

    if (ngnBalance >= planChangeCost.totalAmount || !!business.card) {
      setLoading(true);

      const payload = {
        newPlan: subscriptionSummary.planId,
        ...(values.months ? { months: values.months } : null),
      };

      const { status, errorMessage } =
        await BusinessController.changeSubscription(payload);

      if (status === constant.Success) {
        setLoading(false);
        MimaToastUtil.success({
          message: "Subscription Plan successfully changed",
        });

        return closeModal();
      } else {
        setLoading(false);
        return MimaToastUtil.error({
          message: errorMessage,
        });
      }
    }

    // if (!business.card) {
    // 	return navigation.navigate(NavigationType.SecureNavigation, {
    // 		screen: navConstant.BusinessAccountSettingsScreen,
    // 		params: {
    // 			action: 'open_debit_card_modal',
    // 		},
    // 	});
    // }

    return MimaToastUtil.info({
      message: "Please Fund your wallet",
    });
  };

  const subCancelHandler = async () => {
    setLoading(true);

    const payload = {
      activateTransactionsSMS: generalSettings.activateTransactionsSMS,
      defaultCurrency: generalSettings.defaultCurrency,
      activateTransactionsEmail: generalSettings.activateTransactionsEmail,
      activateInvoiceReminder: generalSettings.activateInvoiceReminder,
      cancelAutoRenew: !generalSettings.cancelAutoRenew,
      invoiceTimer: generalSettings.invoiceTimer,
      invoiceReminderDate: generalSettings.invoiceReminderDate,
      invoiceBankAccount: generalSettings.invoiceBankAccount,
    };

    const { status, errorMessage } =
      await SettingsController.updateGeneralConfig(payload);

    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: "Successful",
      });
      return closeModal();
    }

    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const monthData = [
    {
      key: "1",
      value:
        planName === "Basic"
          ? `1 Month - ${amountFormatter("NGN").format(basicPricing[0].price)}`
          : planName === "Premium"
            ? `1 Month - ${amountFormatter("NGN").format(premiumPricing[0].price)}`
            : `1 Month - ${amountFormatter("NGN").format(enterprisePricing[0].price)}`,
    },
    {
      key: "3",
      value:
        planName === "Basic"
          ? `3 Month - ${amountFormatter("NGN").format(basicPricing[1].price)}`
          : planName === "Premium"
            ? `3 Month - ${amountFormatter("NGN").format(premiumPricing[1].price)}`
            : `3 Month - ${amountFormatter("NGN").format(enterprisePricing[1].price)}`,
    },
    {
      key: "6",
      value:
        planName === "Basic"
          ? `6 Month - ${amountFormatter("NGN").format(basicPricing[2].price)}`
          : planName === "Premium"
            ? `6 Month - ${amountFormatter("NGN").format(premiumPricing[2].price)}`
            : `6 Month - ${amountFormatter("NGN").format(enterprisePricing[2].price)}`,
    },
    {
      key: "12",
      value:
        planName === "Basic"
          ? `12 Month - ${amountFormatter("NGN").format(basicPricing[3].price)}`
          : planName === "Premium"
            ? `12 Month - ${amountFormatter("NGN").format(premiumPricing[3].price)}`
            : `12 Month - ${amountFormatter("NGN").format(enterprisePricing[3].price)}`,
    },
  ];

  const validationSchema = () =>
    yup.object({
      months: yup.string().required("Number of months is required"),
    });

  return {
    closeModal,
    loading,
    goBack,
    plansPricing,
    selectedTab,
    setSelectedTab,
    selectedIndex,
    setSelectedIndex,
    onContinueClick,
    showSummary,
    onSelectPlan,
    subscriptionSummary,
    subscribeAction,
    currentSubscription,
    business,
    changePlan,
    setChangePlan,
    upgradePlans,
    setSubscriptionSummary,
    getCost,
    planChangeCost,
    upgradeDowngradeHandler,
    generalSettings,
    cancelPlan,
    setCancelPlan,
    subCancelHandler,
    monthData,
    validationSchema,
    setPlanName,
  };
};

export default useSubscriptionsLogic;
