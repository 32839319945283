import {
	IconButton,
	Sidebar,
	MimaButton,
	MimaDropdown,
	MimaInput,
	MimaText,
	Modal,
	Beneficiaries,
} from '../../../components';
import constant from '../../../utils/constant';
import styles from '../../../assets/styles/Wallet/TransferFunds.module.scss';
import TransferFailedImage from '../../../assets/img/MimaActionFailed.png';
import React from 'react';

const BeneficiaryList = ({ onSelectBeneficiary, beneficiaries }) => {
	return (
		<div>
			<MimaText align="center" variant="subtitleBold" mb={3} >
				Your Beneficiaries
			</MimaText>

			<Beneficiaries 	
				onSelectBeneficiary={onSelectBeneficiary}
			/>
		</div>
	);
};

export default BeneficiaryList;
