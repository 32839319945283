import styles from "../../assets/styles/Modal/Modal.module.scss";
import { motion } from "framer-motion";
import MimaButton from "../Button/MimaButton";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { RemoveScroll } from "react-remove-scroll";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

const Modal = ({ children, closeModal, goBack, ...props }) => {
  const isBelow500 = useMediaQuery({
    query: "(max-width: 500px)",
  });

  return (
    <RemoveScroll>
      {!isBelow500 && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          className={styles.background}
        >
          <div onClick={closeModal}></div>

          <motion.div
            initial={{
              x: 100,
            }}
            animate={{
              x: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              x: 100,
              transition: {
                duration: 0.2,
              },
            }}
            className={styles.container}
          >
            <div className={styles.container__top}>
              <div className={styles.container__top__inner}>
                {goBack && (
                  <BsFillArrowLeftCircleFill
                    onClick={goBack}
                    style={{ position: "fixed" }}
                  />
                )}
                <div className={styles.close}>
                  <MimaButton title="X" variant="close" onClick={closeModal} />
                </div>
              </div>
            </div>
            <div className={styles.content}>{children}</div>
          </motion.div>
        </motion.div>
      )}

      {isBelow500 && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          className={styles.background}
        >
          <div onClick={closeModal}></div>

          <motion.div
            initial={{
              y: 100,
            }}
            animate={{
              y: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              y: 100,
              transition: {
                duration: 0.2,
              },
            }}
            className={styles.container}
          >
            <div className={styles.container__top}>
              <div className={styles.container__top__inner}>
                {goBack && (
                  <BsFillArrowLeftCircleFill
                    onClick={goBack}
                    style={{ position: "fixed" }}
                  />
                )}
                <div className={styles.close}>
                  <MimaButton title="X" variant="close" onClick={closeModal} />
                </div>
              </div>
            </div>

            {/* <div className={styles.bws}>a</div> */}
            <div className={styles.content}>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </RemoveScroll>
  );
};

export default Modal;
