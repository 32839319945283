import {
  MimaInput,
  MimaText,
  AppPage,
  PageLoader,
  MimaFilter,
  MimaDateFilter,
  MimaDropdownFilter,
  Searchbar,
  MimaButton,
  MimaDropdown,
  AppShell,
} from "../../components";
// import { CableContainer, UtilitiesContainer } from "../../containers";
import styles from "../../assets/styles/PayBills/PayBills.module.scss";
import Meter from "../../assets/img/electric-meter.png";
import Phone from "../../assets/img/smartphone.png";
// import { AirtimeContainer } from "../../containers";
import Tv from "../../assets/img/television.png";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence } from "framer-motion";
import { Formik } from "formik";
import React, { useState } from "react";
import moment from "moment";
import { amountFormatter } from "../../utils/utils";
import { billStore, userStore } from "../../stores";
import useBillLogic from "./useLogic/useBillLogic";
import Utilities from "./Utilities";
import AirtimeOne from "./AirtimeData";

function PayBills() {
  const {
    closeModal,
    setBillType,
    billType,
    logout,
    loading,
    airtimeModal,
    cabletvModal,
    utilitiesModal,
    goToAirtime,
    goToUtilities,
    goToCabletv,
    bills,
    resetFilter,
    onSubmit,
    validationSchema,
    onReset,
    setOnReset,
    billList,
    filteredBills,
    setFilteredBills,
    refresh,
    refreshBill,
    filterPeriods,
    getDate,
  } = useBillLogic();
  const isBelow600 = useMediaQuery({
    query: "(max-width: 600px)",
  });

  const textWidth = !isBelow600 ? 5 : "";
  const textAlign = isBelow600 ? "center" : "";

  return (
    <AppShell pageTitle="Pay Bills" refresh={refresh}>
      <div className={styles.main}>
        <div className={styles.pb}>
          <div
            className={styles.pb__item}
            onClick={() => {
              billStore.setCopiedBill({});
              goToAirtime();
            }}
          >
            <img src={Phone} className={styles.pb__image} alt="Airtime" />
            <MimaText>Airtime </MimaText>
            {/* <img src={Phone} className={styles.pb__image} alt="Airtime/Data" /> */}
            {/* //<MimaText>Airtime/Data </MimaText> */}
          </div>
          {/* <div className={styles.pb__item} onClick={goToCabletv}>
						<img src={Tv} className={styles.pb__image} alt="Cable TV" />
						<MimaText>Cable TV </MimaText>
					</div> */}
          <div
            className={styles.pb__item}
            onClick={() => {
              billStore.setCopiedBill({});
              goToUtilities();
            }}
          >
            <img src={Meter} className={styles.pb__image} alt="Electricity" />
            <MimaText>Electricity</MimaText>
          </div>
        </div>
        <div>
          <Formik
            initialValues={{
              startDate: "",
              endDate: "",
              billType: "",
              period: "",
              periodText: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <MimaFilter
                filterClick={() => {
                  setOnReset(false);
                  handleSubmit();
                }}
                loading={loading}
                resetClick={() => {
                  setOnReset(true);
                  resetFilter();
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <MimaDropdownFilter
                      labelTitle="Bill Type"
                      name="billType"
                      value={values.billType}
                      id="billType"
                      onChange={(data) => {
                        setFieldValue("billType", data.key);
                      }}
                      placeholder="Select bill type"
                      currentValue={values.billType}
                      data={[
                        { key: "AIRTIME", value: "AIRTIME" },
                        { key: "DATA", value: "DATA" },
                        { key: "TV-SUBSCRIPTION", value: "CABLE TV" },
                        { key: "ELECTRICITY", value: "ELECTRICITY" },
                      ]}
                      touched={touched.billType}
                      error={errors.billType}
                    />
                    <MimaDropdownFilter
                      labelTitle="Filter period"
                      placeholder="Select Period"
                      variant="medium"
                      value={values.period}
                      name="period"
                      data={filterPeriods}
                      onChange={(data) => {
                        const dates = getDate(data.key);
                        setFieldValue("period", data.key);
                        setFieldValue("startDate", dates.startDate);
                        setFieldValue("endDate", dates.endDate);
                        setFieldValue("periodText", data.value);
                      }}
                      error={errors.period}
                      touched={touched.period}
                    />
                  </div>
                  {values.period === "custom" && (
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <MimaDateFilter
                        labelTitle="start date"
                        name="startDate"
                        value={!onReset ? values.startDate : ""}
                        onChange={(text) => {
                          setOnReset(false);
                          setFieldValue("startDate", text);
                        }}
                        onBlur={handleBlur}
                        width={18}
                        touched={touched.startDate}
                        error={errors.startDate}
                      />
                      <MimaDateFilter
                        labelTitle="End date"
                        name="endDate"
                        value={!onReset ? values.endDate : ""}
                        onChange={(text) => {
                          setOnReset(false);
                          setFieldValue("endDate", text);
                        }}
                        onBlur={handleBlur}
                        width={18}
                        touched={touched.endDate}
                        error={errors.endDate}
                      />
                    </div>
                  )}
                </div>

                {/* <MimaDateFilter
									labelTitle="Start Date"
									name="startDate"
									value={!onReset ? values.startDate : ''}
									onChange={text => {
										setOnReset(false);
										setFieldValue('startDate', text);
									}}
									// onBlur={handleBlur}
									width={18}
									// touched={touched.startDate}
									error={errors.startDate}
								/> */}
                {/* <MimaDateFilter
									labelTitle="End Date"
									name="endDate"
									value={!onReset ? values.endDate : ''}
									onChange={text => {
										setOnReset(false);
										setFieldValue('endDate', text);
									}}
									// onBlur={handleBlur}
									width={18}
									// touched={touched.endDate}
									error={errors.endDate}
								/> */}
              </MimaFilter>
            )}
          </Formik>
          <div className={styles.history}>
            <div className={styles.history__top}>
              <MimaText
                variant="subtitle"
                // width={textWidth}
                // align={textAlign}
                mb={2}
              >
                Payment History
              </MimaText>
              <Searchbar
                placeholder="Search Bills"
                variant="regular"
                input={bills}
                loading={false}
                output={setFilteredBills}
                handleFilter={(item, searchQuery) => {
                  return (
                    item?.phone?.toLowerCase()?.includes(searchQuery) ||
                    item?.type?.toLowerCase()?.includes(searchQuery) ||
                    String(item?.amount).toLowerCase()?.includes(searchQuery)
                  );
                }}
              />
            </div>
            {loading ? (
              <PageLoader title="In progess ..." loading={loading} />
            ) : (
              <div className={styles.history__content}>
                <table className={styles.table}>
                  <tbody>
                    {filteredBills.map((bill, i) => (
                      <tr key={i}>
                        <td>
                          {moment(bill.createdAt).format("DD-MM-YYYY hh:mm")}
                        </td>
                        <td>{bill.type}</td>
                        <td>{bill.details}</td>
                        <td className={styles.table__amount}>
                          {amountFormatter().format(bill.amount)}{" "}
                        </td>
                        <td>
                          {bill.type === "AIRTIME" || bill.type === "DATA"
                            ? "Phone No:"
                            : bill.type === "ELECTRICITY"
                              ? "Meter Token:"
                              : ""}{" "}
                          {bill.type === "AIRTIME" || bill.type === "DATA"
                            ? bill.phone
                            : bill.type === "ELECTRICITY"
                              ? bill.meterToken
                              : ""}
                        </td>
                        <td>
                          {bill.status !== "DONE" ? (
                            <MimaButton
                              variant="filter"
                              title="Refresh"
                              loading={loading}
                              onClick={() => {
                                refreshBill(bill._id);
                              }}
                              width={10}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {bill.type === "AIRTIME" ||
                          bill.type === "DATA" ||
                          bill.type === "ELECTRICITY" ? (
                            <MimaButton
                              variant="filter"
                              title="Duplicate"
                              loading={loading}
                              onClick={() => {
                                billStore.setCopiedBill(bill);
                                if (bill.type === "AIRTIME") {
                                  goToAirtime();
                                } else if (bill.type === "DATA") {
                                  goToAirtime();
                                } else if (bill.type === "ELECTRICITY") {
                                  goToUtilities();
                                }
                              }}
                              width={10}
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {(airtimeModal || cabletvModal || utilitiesModal) && (
        <div className="modal">
          <AnimatePresence>
            {airtimeModal && <AirtimeOne closeModal={closeModal} />}
            {/* {cabletvModal && <CableContainer closeModal={closeModal} />} */}
            {utilitiesModal && <Utilities closeModal={closeModal} />}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
}

export default PayBills;
