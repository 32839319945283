import React, { useMemo, useState } from "react";
import { WebsiteController } from "../../../controllers";
import { websiteStore } from "../../../stores";

const useStylesLogic = () => {
  const [limit, setLimit] = useState(50);
  const [filterQuery, setFilterQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [uploadStyleModal, setUploadStyleModal] = useState(false);
  const [editStyleModal, setEditStyleModal] = useState(false);
  const [deleteStyleModal, setDeleteStyleModal] = useState(false);
  const [viewMoreStyleModal, setViewMoreStyleModal] = useState(false);

  const stylesTableQuery = useMemo(() => {
    return `offset=${currentPage + 1}&limit=${limit}`;
  }, [currentPage, limit]);

  const { isLoading, data, isFetching, refetch } =
    WebsiteController.useGetStyles(stylesTableQuery, filterQuery, searchQuery);

  const onLimitChange = (limit) => {
    setLimit(limit);
    setCurrentPage(0);
  };

  const goToUploadStyle = () => {
    setUploadStyleModal(true);
  };
  const closeModal = () => {
    setUploadStyleModal(false);
    setDeleteStyleModal(false);
    setEditStyleModal(false);
    setViewMoreStyleModal(false);
  };
  const handleOptionSelect = (selectedStyle, option) => {
    websiteStore.setSelectedStyle(selectedStyle);
    if (option.value === "View More") {
      setViewMoreStyleModal(true);
    } else if (option.value === "Edit Style") {
      setEditStyleModal(true);
    } else if (option.value === "Delete Style") {
      setDeleteStyleModal(true);
    }
  };

  return {
    limit,
    currentPage,
    setCurrentPage,
    onLimitChange,
    searchQuery,
    setSearchQuery,
    handleOptionSelect,
    isLoading,
    data,
    isFetching,
    uploadStyleModal,
    goToUploadStyle,
    closeModal,
    refetch,
    editStyleModal,
    deleteStyleModal,
    viewMoreStyleModal,
  };
};

export default useStylesLogic;
