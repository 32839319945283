import TransactionsController from './TransactionsController';
import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { invoiceStore, paymentLinkStore } from '../stores';
import moment from 'moment';

const createPaymentLink = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/banking/create-checkout-payment-link',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await getAllPaymentLinks();
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const getAllPaymentLinks = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/banking/payment-checkout-links',
		method: 'get',
	});

	if (status === constant.Success) {
		paymentLinkStore.setAllPaymentLinks(data);				
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const editPaymentLink = async (id, payload) => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/banking/edit-checkout-payment-link/${id}`,
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		await getAllPaymentLinks();
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const deletePaymentLink = async id => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/banking/payment-link?id=${id}`,
		method: 'delete',
	});
	if (status === constant.Success) {
		await getAllPaymentLinks();
		paymentLinkStore.setSelectedPaymentLink();
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

const getPaymentCheckoutDetails = async id => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/banking/payment-checkout-details/${id}`,
		method: 'get',
	});

	if (status === constant.Success) {
		paymentLinkStore.setPaymentCheckoutDetails(data);
		paymentLinkStore.setCharges();				
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

const createPaymentLinkInvoice = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/invoices/accept-payment-invoice',
		method: 'post',
		payload,
	});	
	if (status === constant.Success) {
		paymentLinkStore.setCharges();
		
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

const getCharges = async payload => {

	let query;

	query = `quantity=${payload.quantity}&name=${payload.name}&amount=${payload.amount}`;

	const { status, data, errorMessage } = await apiRequest({
		url: `/charges/calculate-charge?${query}`,
		method: 'get',
	});
	

	if (status === constant.Success) {
		let chargeData = {
			...data,
		}
	
		if (payload.chargeSplit === "EQUAL SPLIT") {
			chargeData.chargeAmount = data.amount/2
		}
	
		if (payload.chargeSplit === "MY BUSINESS") {
			chargeData.chargeAmount = 0
		}
	
		if (payload.chargeSplit === "CUSTOMER") {
			chargeData.chargeAmount = data.amount
		}

		paymentLinkStore.setCharges(chargeData);	
		return { status: constant.Success };
	}
	return { errorMessage, status };
};

export default {
	createPaymentLink,
	getAllPaymentLinks,
	editPaymentLink,
	deletePaymentLink,
	getPaymentCheckoutDetails,
	createPaymentLinkInvoice,
	getCharges,
};
