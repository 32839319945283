import React from "react";
import styles from "../../assets/styles/Orders/Orders.module.scss";
import {
  MimaButton,
  MimaDateInput,
  MimaDropdown,
  MimaInput,
  MimaText,
  Modal,
} from "../../components";
import { Formik } from "formik";
import { ordersStore } from "../../stores";
import useUpdateOrdersLogic from "./useUpdateOrdersLogic";

const UpdateOrders = ({ closeModal }) => {
  const data = ordersStore.singleItem;

  const { validationSchema, onSubmit, status, loading } =
    useUpdateOrdersLogic(closeModal);
  return (
    <Modal closeModal={closeModal}>
      <div className={styles.view_content}>
        <MimaText
          variant="subtitleBold"
          align="center"
          color="var(--color-fash-primary-3)"
          mb={5}
        >
          Update Order Status
        </MimaText>

        <MimaText
          labelTitle="Order ID"
          mb={3}
        >{`ORD-${data.invoice.number}`}</MimaText>

        <MimaText labelTitle="Customer Name" mb={3}>
          {data.invoice.customer.fullname}
        </MimaText>
      </div>
      <Formik
        initialValues={{
          id: data?._id || "",
          trackingStatus: data.trackingStatus ? data.trackingStatus : "",
          trackingNumber: "",
          shippingVendor: "",
          shippingTrackingUrl: "",
          expectedDeliveryDate: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div style={{ width: "38rem" }}>
            <MimaDropdown
              labelTitle="Order Status"
              placeholder={
                data.trackingStatus ? data.trackingStatus : "Select Status"
              }
              name="trackingStatus"
              data={status}
              onChange={(data) => {
                setFieldValue("trackingStatus", data.value);
              }}
              value={values.trackingStatus}
              error={errors.trackingStatus}
              touched={touched.trackingStatus}
            />

            {values?.trackingStatus === "SHIPPED" ? (
              <>
                <MimaInput
                  labelTitle="Tracking Number"
                  placeholder="Enter Tracking Number"
                  name="trackingNumber"
                  mb={2}
                  value={values.trackingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.trackingNumber}
                  touched={touched.trackingNumber}
                />
                <MimaInput
                  labelTitle="Shipping Vendor/Company"
                  placeholder="Enter Shipping Vendor"
                  name="shippingVendor"
                  mb={2}
                  value={values.shippingVendor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.shippingVendor}
                  touched={touched.shippingVendor}
                />
                <MimaInput
                  labelTitle="Shipping tracking web Link "
                  placeholder="Enter Shipping tracking web link"
                  name="shippingTrackingUrl"
                  mb={2}
                  value={values.shippingTrackingUrl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.shippingTrackingUrl}
                  touched={touched.shippingTrackingUrl}
                />

                <MimaDateInput
                  labelTitle="Expected Delivery date"
                  name="expectedDeliveryDate"
                  value={values.expectedDeliveryDate}
                  onChange={(text) => {
                    setFieldValue("expectedDeliveryDate", text);
                  }}
                  onBlur={handleBlur}
                  touched={touched.expectedDeliveryDate}
                  error={errors.expectedDeliveryDate}
                  mb={2}
                />
              </>
            ) : (
              ""
            )}

            <MimaButton
              title="Save"
              variant="regular"
              onClick={handleSubmit}
              loading={loading}
              width={38}
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
};
export default UpdateOrders;
