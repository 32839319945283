import React from "react";
import AuthOnboardingShell from "../AuthOnboardingShell";
import { userStore } from "../../../stores";
import constant from "../../../utils/constant";
import { MimaButton, MimaOtpInput, MimaText } from "../../../components";
import { Formik } from "formik";
import useOtpLogic from "./useOtpLogic";
import styles from "../../../assets/styles/Auth/AuthOnboarding.module.scss";

const Otp = () => {
  const {
    loading,
    validationSchema,
    onSubmit,
    isResendEnabled,
    resendCountdown,
    handleResendClick,
  } = useOtpLogic();
  return (
    <AuthOnboardingShell>
      {userStore.otpType === constant.OtpType.ONBOARDING && (
        <MimaText variant="bodyBold" align="center" mb={0.5}>
          To Activate your account , we’ve sent you a 5 digit code to your email
          address
        </MimaText>
      )}
      <MimaText align="center" mb={2}>
        Please enter the code sent to your email address below
      </MimaText>
      <Formik
        initialValues={{
          otpValue: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div style={{ marginTop: "2rem" }} className={styles.form}>
            <MimaOtpInput
              title="Enter OTP"
              errorMessage={errors.otpValue}
              value={values.otpValue}
              onChange={(otpValue) => {
                setFieldValue("otpValue", otpValue);
              }}
            />
            <MimaButton
              mt={2}
              type="submit"
              title="Continue"
              loading={loading}
              onClick={handleSubmit}
              variant="form"
            />
          </div>
        )}
      </Formik>

      {isResendEnabled ? (
        <MimaText variant="small" align="center" mt={2}>
          Didn’t get any code?{" "}
          <span className={styles.clickable} onClick={handleResendClick}>
            Resend Code
          </span>
        </MimaText>
      ) : (
        <MimaText variant="small" align="center" mt={2}>
          Resend in <span>{resendCountdown}</span> seconds
        </MimaText>
      )}
    </AuthOnboardingShell>
  );
};

export default Otp;
