import styles from "../../assets/styles/MimaInput.module.scss";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as inputStyles from "./styles";
import { BsInfoCircleFill } from "react-icons/bs";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const propTypes = {
  labelTitle: PropTypes.string,
  type: PropTypes.oneOf(["text", "number", "tel"]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["medium", "form", "error", "formError"]),
  width: PropTypes.any,
  height: PropTypes.any,
  icon: PropTypes.element,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  error: PropTypes.any,
  touched: PropTypes.bool,
  passwordIcon: PropTypes.bool,
  fontSize: PropTypes.number,
  handleFilter: PropTypes.func,
  note: PropTypes.string,
  required: PropTypes.bool,
  defaultCountry: PropTypes.string,
};

const MimaPhoneInput = ({
  labelTitle,
  placeholder = "Enter phone number",
  disabled = false,
  type = "tel",
  variant = "medium",
  width,
  height,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  touched,
  error,
  fontSize,
  note,
  required = false,
  value,
  onChange,
  onBlur,
  defaultValue,
  defaultCountry = "NG",
  onCountryChange,
  ...props
}) => {
  return (
    <DivSkeleton
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
      width={width}
      variant={variant}
    >
      {labelTitle && (
        <label htmlFor={id || name} className={styles.form__label}>
          {labelTitle}
        </label>
      )}

      <PhoneInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        // variant={error ? errorVariant : variant}
        name={name}
        id={id || name}
        defaultCountry={defaultCountry}
        onCountryChange={onCountryChange}
      />

      {error ? <div className={styles.error}>{touched && error}</div> : null}

      {note ? (
        <div className={styles.note}>
          <BsInfoCircleFill style={{ fontSize: "2rem", marginRight: "1rem" }} />{" "}
          {note}
        </div>
      ) : null}
    </DivSkeleton>
  );
};

const DivSkeleton = styled.div`
  ${inputStyles.divBase};
  ${(props) => inputStyles[props.variant]};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

MimaPhoneInput.propTypes = propTypes;

export default MimaPhoneInput;
