import {
  IconButton,
  Sidebar,
  MimaButton,
  MimaDropdown,
  MimaInput,
  MimaText,
  Modal,
  PageLoader,
} from "../../components";
import styles from "../../assets/styles/Wallet/TransferFunds.module.scss";
import { amountFormatter } from "../../utils/utils";
import OtpInput from "react-otp-input";
import { Formik } from "formik";

import React, { useState } from "react";

const TransferPinConfirm = ({
  onSubmitTransferPinConfirm,
  payload,
  loading,
}) => {
  const [otp, setOtp] = useState("");

  return (
    <Formik
      initialValues={{
        otp: "",
      }}
      onSubmit={onSubmitTransferPinConfirm}
    >
      {({ handleChange, handleBlur, values, errors, touched }) => (
        <form onSubmit={onSubmitTransferPinConfirm}>
          {loading ? (
            <PageLoader title="Sending Money...." loading={loading} />
          ) : (
            <div className={styles.centerContent}>
              <MimaText align="center">
                Enter your Transfer Pin to complete transfer of
              </MimaText>
              <MimaText mb={1.5} align="center" variant="bodyBold">
                {amountFormatter().format(payload.amount)}
              </MimaText>

              <div className={styles.pin__group}>
                <MimaText align="center">Enter Pin</MimaText>
                <div className={styles.otp__group}>
                  <OtpInput
                    value={otp}
                    onChange={(otp) => {
                      setOtp(otp);
                      if (otp >= 1000) {
                        onSubmitTransferPinConfirm(otp);
                      }
                    }}
                    numInputs={4}
                    isInputSecure={true}
                    inputStyle={{
                      fontSize: "2.4rem",
                      fontFamily: "inherit",
                      width: "6rem",
                      height: "6rem",
                      margin: ".4rem",
                      borderTop: "0px",
                      border: "1px solid #4A4A68",
                      borderRadius: "1rem",
                      display: "block",
                      outline: "none",
                      textAlign: "center",
                      marginRight: "2rem",
                    }}
                    isInputNum
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default TransferPinConfirm;
