import {
  AppPage,
  MimaInput,
  MimaText,
  MimaTextArea,
  ReceiptCheckBox as RCB,
  MimaButton,
  PageLoader,
  MimaDropdown,
} from "../../components";
import { chargeSplitValues } from "../../utils/utils";
import styles from "../../assets/styles/Booking/Booking.module.scss";
import { Formik } from "formik";
import React from "react";

const EditBooking = ({
  loading,
  editLink,
  cancelEdit,
  selectedLink,
  onSubmitEditBooking,
  validationSchemaEditBooking,
}) => {
  return (
    <div className={styles.main}>
      <div className={styles.main__content}>
        {editLink && (
          <div>
            <MimaText
              variant="subtitleBold"
              mb={2}
              align="center"
              color="var(--color-dark)"
            >
              Edit Booking Link for {selectedLink.title}
            </MimaText>
            <MimaButton
              title="Cancel Edit"
              onClick={cancelEdit}
              loading={loading}
              variant="regular"
              mb={2}
              height={3}
            />
            <Formik
              initialValues={{
                amount: selectedLink.amount || "",
                minDeposit: selectedLink.minDeposit || "",
                currencyCode: selectedLink.currencyCode || "NGN",
                title: selectedLink.title || "",
                description: selectedLink.description || "",
                chargeSplit: selectedLink.chargeSplit || "MY BUSINESS",
                videoConferencingLink: selectedLink.videoConferencingLink,
                acceptPhoneNumber: selectedLink.acceptPhoneNumber || false,
                availableWeekends: selectedLink.availableWeekends,
                acceptPartPayment: selectedLink.acceptPartPayment || false,
                durationInMinutes: selectedLink.durationInMinutes,
                previewAmount: "",
                previewFirstName: "",
                previewLastName: "",
                previewEmail: "",
                previewMobile: "",
              }}
              validationSchema={validationSchemaEditBooking}
              onSubmit={onSubmitEditBooking}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              }) => {
                return (
                  <div className={styles.createLinks}>
                    <div className={styles.createLinks_1}>
                      {loading ? (
                        <PageLoader title="While your link is being generated" />
                      ) : (
                        <div>
                          <MimaInput
                            labelTitle="Title"
                            variant="form2"
                            placeholder="Link Title"
                            name="title"
                            id="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched.title}
                            error={errors.title}
                            mb={2}
                          />

                          <MimaTextArea
                            labelTitle="Description"
                            textAreaVariant="type3"
                            placeholder="Description"
                            name="description"
                            id="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched.description}
                            error={errors.description}
                            mb={3}
                          />

                          {selectedLink.amount !== 0 ? (
                            <div className={styles.beside}>
                              <MimaText variant="small">
                                Accept Part payment?
                              </MimaText>
                              <div className={styles.beside}>
                                <RCB
                                  title="Yes"
                                  onChange={() => {
                                    setFieldValue("acceptPartPayment", true);
                                  }}
                                  checked={values.acceptPartPayment}
                                  value="true"
                                  name="acceptPartPayment"
                                />
                                <RCB
                                  title="No"
                                  checked={!values.acceptPartPayment}
                                  onChange={() =>
                                    setFieldValue("acceptPartPayment", false)
                                  }
                                  value="false"
                                  name="acceptPartPayment"
                                />
                              </div>
                              {errors.acceptPartPayment ? (
                                <div className={styles.error}>
                                  {""}
                                  {touched.acceptPartPayment &&
                                    errors.acceptPartPayment}
                                  {""}
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            ""
                          )}

                          <div
                            className={
                              values.acceptPartPayment ? styles.beside : ""
                            }
                          >
                            {values.acceptPartPayment ? (
                              <MimaInput
                                labelTitle="Minimum Part Payment (Optional)"
                                variant="form2"
                                type="number"
                                placeholder="Minimum Part Payment"
                                name="minDeposit"
                                id="minDeposit"
                                value={values.minDeposit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                mb={-1}
                                touched={touched.minDeposit}
                                error={errors.minDeposit}
                              />
                            ) : (
                              ""
                            )}

                            <MimaDropdown
                              labelTitle="Who bears the payment charge"
                              name="chargeSplit"
                              value={values.chargeSplit}
                              placeholder={values.chargeSplit}
                              id="chargeSplit"
                              currentValue={values.chargeSplit}
                              onChange={(data) => {
                                setFieldValue("chargeSplit", data.key);
                              }}
                              data={chargeSplitValues}
                              touched={touched.chargeSplit}
                              error={errors.chargeSplit}
                              variant="filterForm"
                              bodyVariant="bodyBase2"
                              mb={2}
                            />
                          </div>
                          <div className={styles.beside}>
                            <MimaInput
                              labelTitle="Video Conferencing Url"
                              variant="form2"
                              placeholder="Zoom link or google meet"
                              name="videoConferencingLink"
                              id="videoConferencingLink"
                              value={values.videoConferencingLink}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              touched={touched.videoConferencingLink}
                            />
                            <MimaInput
                              labelTitle="How many minutes is a session?"
                              variant="form2"
                              type="number"
                              placeholder="30"
                              name="durationInMinutes"
                              id="durationInMinutes"
                              value={values.durationInMinutes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              mb={1}
                              touched={touched.durationInMinutes}
                              error={errors.durationInMinutes}
                            />
                          </div>

                          <div className={styles.beside}>
                            <MimaText variant="small">
                              Collect Phone Number?
                            </MimaText>
                            <div className={styles.beside}>
                              <RCB
                                title="Yes"
                                onChange={() => {
                                  setFieldValue("acceptPhoneNumber", true);
                                }}
                                checked={values.acceptPhoneNumber}
                                value="true"
                                name="acceptPhoneNumber"
                              />
                              <RCB
                                title="No"
                                checked={!values.acceptPhoneNumber}
                                onChange={() =>
                                  setFieldValue("acceptPhoneNumber", false)
                                }
                                value="false"
                                name="acceptPhoneNumber"
                              />
                            </div>
                            {errors.acceptPhoneNumber ? (
                              <div className={styles.error}>
                                {""}
                                {touched.acceptPhoneNumber &&
                                  errors.acceptPhoneNumber}
                                {""}
                              </div>
                            ) : null}
                          </div>

                          <div className={styles.beside}>
                            <MimaText variant="small">
                              Do you accept weekends booking sessions?
                            </MimaText>
                            <div className={styles.beside}>
                              <RCB
                                title="Yes"
                                onChange={() => {
                                  setFieldValue("availableWeekends", true);
                                }}
                                checked={values.availableWeekends}
                                value="true"
                                name="availableWeekends"
                              />
                              <RCB
                                title="No"
                                checked={!values.availableWeekends}
                                onChange={() =>
                                  setFieldValue("availableWeekends", false)
                                }
                                value="false"
                                name="availableWeekends"
                              />
                            </div>
                            {errors.availableWeekends ? (
                              <div className={styles.error}>
                                {""}
                                {touched.availableWeekends &&
                                  errors.availableWeekends}
                                {""}
                              </div>
                            ) : null}
                          </div>

                          <MimaButton
                            title="Update Booking Link"
                            onClick={handleSubmit}
                            type="submit"
                            loading={loading}
                            variant="form"
                            mt={2}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditBooking;
