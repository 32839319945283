import React, { useMemo, useState } from "react";
import { WebsiteController } from "../../../controllers";

const useStyleRequestLogic = () => {
  const [limit, setLimit] = useState(50);
  const [filterQuery, setFilterQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewNoteModal, setViewNoteModal] = useState(false);

  const styleRequestsTableQuery = useMemo(() => {
    return `offset=${currentPage + 1}&limit=${limit}`;
  }, [currentPage, limit]);

  const { isLoading, data, isFetching } = WebsiteController.useGetStyleRequests(
    styleRequestsTableQuery,
    filterQuery,
    searchQuery
  );

  const onLimitChange = (limit) => {
    setLimit(limit);
    setCurrentPage(0);
  };

  const closeModal = () => {
    setViewNoteModal(false);
  };

  return {
    isLoading,
    data,
    isFetching,
    onLimitChange,
    limit,
    currentPage,
    setCurrentPage,
    searchQuery,
    setSearchQuery,
    closeModal,
    setViewNoteModal,
    viewNoteModal,
  };
};

export default useStyleRequestLogic;
