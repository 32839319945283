import React, { useMemo, useState } from "react";
import { AppShell, MimaTable, MimaText } from "../../components";
import moment from "moment";
import constant from "../../utils/constant";
import useContactUsLogic from "./useLogic/useContactUsLogic";
import { AnimatePresence } from "framer-motion";
import ViewMessage from "./ViewMessage";

const ContactUs = () => {
  const [item, setItem] = useState({});

  const {
    isLoading,
    data,
    isFetching,
    onLimitChange,
    limit,
    currentPage,
    setCurrentPage,
    setSearchQuery,
    closeModal,
    viewMessageModal,
    setViewMessageModal,
  } = useContactUsLogic();

  const tableData = useMemo(() => {
    if (data?.status === constant.Success) {
      return data?.data?.requests || [];
    }
    return [];
  }, [data]);

  const tableColumns = [
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ cell }) => {
        const createdAt = cell.row.original?.createdAt;
        return <span>{moment(createdAt).format("DD/MM/YYYY")}</span>;
      },
    },

    {
      Header: "Customer Name",
      Cell: ({ cell }) => {
        const fullname = cell.row.original?.fullname;
        return <span>{fullname}</span>;
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: ({ cell }) => {
        const email = cell.row.original?.email;
        return <span>{email}</span>;
      },
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        const item = cell.row.original;

        return (
          <span
            className="action__text"
            onClick={() => {
              setItem(item);
              setViewMessageModal(true);
            }}
          >
            View Message
          </span>
        );
      },
    },
  ];
  return (
    <AppShell pageTitle="Contact Us Requests">
      <MimaTable
        tableData={tableData}
        tableColumns={tableColumns}
        searchPlaceholder="Search Contacts"
        searchVariant="wide"
        totalItems={data?.data?.totalCounts}
        onLimitChange={onLimitChange}
        isLoading={isLoading}
        isFetching={isFetching}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchQuery={setSearchQuery}
      />
      {viewMessageModal && (
        <div className="modal">
          <AnimatePresence>
            {viewMessageModal && (
              <ViewMessage closeModal={closeModal} item={item} />
            )}
          </AnimatePresence>
        </div>
      )}
    </AppShell>
  );
};

export default ContactUs;
