import React, { useMemo, useState } from "react";
import { Searchbar2 } from "../Searchbar";
import styles from "../../assets/styles/MimaDropdown.module.scss";
import styled from "styled-components";
import * as dropdownStyles from "./styles";
import { toJS } from "mobx";
import PropTypes from "prop-types";
import PageLoader from "../PageLoader";

const propTypes = {
  labelTitle: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    "medium",
    "wide",
    "small",
    "filterForm",
    "form",
    "error",
    "formError",
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.element,
  data: PropTypes.array.isRequired,
  styleClass: PropTypes.any,
  pt: PropTypes.any,
  pb: PropTypes.any,
  error: PropTypes.any,
  touched: PropTypes.any,
  mb: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  fontSize: PropTypes.number,
  bodyVariant: PropTypes.oneOf(["bodyBase", "bodyBase2"]),
  loading: PropTypes.bool,
};

const MimaDropdown = ({
  labelTitle,
  placeholder,
  data = [],
  canSelectAll = false,
  onBlur,
  onChange,
  value,
  currentValue,
  variant = "medium",
  width,
  id,
  name,
  touched,
  error,
  mb,
  mt,
  mr,
  ml,
  height,
  bodyVariant = "bodyBase",
  loading = false,
  disabled = false,
  ...props
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selected, setSelected] = useState({
    key: currentValue || "",
    value: currentValue || placeholder,
  });

  const openDropdownHandler = (e) => {
    setOpenDropdown(!openDropdown);
  };

  const dropDownSetHandler = (dropDownOption) => {
    setSelected(dropDownOption);
    onChange(dropDownOption);
    setOpenDropdown(false);
  };

  const dropDownOptions = canSelectAll
    ? [{ value: "ALL", key: "" }, ...data]
    : data;
  const [filteredDropdown, setFilteredDropdown] = useState([]);

  const dropDownList = filteredDropdown.map((item) => (
    <div
      key={item.key}
      onClick={() => dropDownSetHandler(item)}
      className={styles.select__options}
    >
      {item.value}
    </div>
  ));

  const errorVariant = useMemo(() => {
    if (variant === "form") {
      return "formError";
    }
    return "error";
  }, [variant]);

  return (
    <>
      <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
        <label
          className={`${
            variant !== "wide"
              ? styles.label
              : `${styles.label} ${styles.label__wide}`
          }`}
        >
          {labelTitle}
        </label>
        <DropdownSkeleton
          width={width}
          height={height}
          placeholder={placeholder}
          onClick={openDropdownHandler}
          variant={error ? errorVariant : variant}
        >
          {selected?.value}
        </DropdownSkeleton>
        {openDropdown && (
          <DropdownBody
            width={width}
            bodyVariant={bodyVariant}
            id="scrollableDiv"
          >
            <div className="searchInMiddle">
              <Searchbar2
                placeholder="Search list"
                input={toJS(dropDownOptions)}
                loading={false}
                output={setFilteredDropdown}
                handleFilter={(item, searchQuery) => {
                  return item?.value?.toLowerCase()?.includes(searchQuery);
                }}
                variant="dropdown"
                showIcon={false}
                mb={1}
              />
            </div>
            {loading ? (
              <PageLoader loading={loading} type="bar" />
            ) : (
              dropDownList
            )}
          </DropdownBody>
        )}

        {error ? <div className={styles.error}>{error}</div> : null}
      </DivSkeleton>
    </>
  );
};

const DivSkeleton = styled.div`
  ${dropdownStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : "")};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : "")};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : "")};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const DropdownBody = styled.div`
  ${dropdownStyles.bodyBase};
  ${(props) => dropdownStyles[props.bodyVariant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
`;

const DropdownSkeleton = styled.div`
  ${dropdownStyles.base};
  ${(props) => dropdownStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : "")};
  ${(touched) => (touched.id || touched.name ? `color: var(--color-dark)` : "")}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : "")};
`;

MimaDropdown.propTypes = propTypes;

export default MimaDropdown;
