import {
  IoMdSettings,
  IoMdLogOut,
  IoMdEye,
  IoMdEyeOff,
  IoIosCloudDownload,
  IoMdRemoveCircleOutline,
  IoMdAddCircleOutline,
} from "react-icons/io";
import styles from "../../assets/styles/Components/IconButton.module.scss";
import PropTypes from "prop-types";
import React from "react";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";

const propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    "settings",
    "logout",
    "eyeOpen",
    "eyeClose",
    "download",
    "add",
    "remove",
    "back",
  ]),
};

const IconButton = ({
  type = "button",
  disabled = false,
  onClick,
  loading = false,
  variant = "settings",
  ...props
}) => {
  return (
    <div className={styles.icon} onClick={onClick}>
      {variant === "settings" && <IoMdSettings />}
      {variant === "logout" && <IoMdLogOut />}
      {variant === "eyeOpen" && <IoMdEye />}
      {variant === "eyeClose" && <IoMdEyeOff />}
      {variant === "remove" && <IoMdRemoveCircleOutline />}
      {variant === "add" && <IoMdAddCircleOutline />}
      {variant === "download" && <IoIosCloudDownload />}
      {variant === "back" && <TbSquareRoundedArrowLeftFilled />}
    </div>
  );
};

IconButton.propTypes = propTypes;

export default IconButton;
