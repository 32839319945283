import {
	MimaDateFilter,
	MimaDropdownFilter,
	MimaFilter,
	MimaText,
	PageLoader,
	RecentActivitiesList as RAL,
} from '../../components';
import styles from '../../assets/styles/Wallet/RecentActivities.module.scss';
import { amountFormatter } from '../../utils/utils';
import constant from '../../utils/constant';
import React, { useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';

const RecentActivities = ({
	transactions,
	validationSchema,
	onSubmit,
	loading,
	filterPeriod,
	resetFilter,
	hide,
	filterPeriods,
	getDate,
}) => {
	const [onReset, setOnReset] = useState(false);

	return (
		<section className={styles.ra} id="tagSection">
			<MimaText mb={2} align="center">
				You are viewing Wallet Activities for {filterPeriod}
			</MimaText>
			<Formik
				initialValues={{
					startDate: '',
					endDate: '',
					period: '',
					periodText: '',
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					values,
					errors,
					touched,
				}) => (
					<MimaFilter
						filterClick={() => {
							setOnReset(false);
							handleSubmit();
						}}
						loading={loading}
						resetClick={() => {
							setOnReset(true);
							resetFilter();
						}}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<MimaDropdownFilter
								labelTitle="Filter period"
								placeholder="Select Period"
								variant="medium"
								name="period"
								data={filterPeriods}
								onChange={data => {
									const dates = getDate(data.key)
									setFieldValue("period", data.key)
									setFieldValue("startDate", dates.startDate)
									setFieldValue("endDate", dates.endDate)
									setFieldValue("periodText", data.value)
								}}
								error={errors.period}
								touched={touched.period}
							/>
							{values.period === "custom" && (
								<div style={{ display: 'flex', gap: "2rem" }}>
									<MimaDateFilter
										labelTitle="start date"
										name="startDate"
										value={!onReset ? values.startDate : ''}
										onChange={text => {
											setOnReset(false);
											setFieldValue('startDate', text);
										}}
										onBlur={handleBlur}
										width={18}
										touched={touched.startDate}
										error={errors.startDate}
									/>
									<MimaDateFilter
										labelTitle="End date"
										name="endDate"
										value={!onReset ? values.endDate : ''}
										onChange={text => {
											setOnReset(false);
											setFieldValue('endDate', text);
										}}
										onBlur={handleBlur}
										width={18}
										touched={touched.endDate}
										error={errors.endDate}
									/>
								</div>
							)}
						</div>
					</MimaFilter>
				)}
			</Formik>

			{loading ? (
				<PageLoader title="Recovering your transactions..." loading={loading} />
			) : (
				<>
					<div className={styles.ra__main}>
						<div className={styles.ra__content}>
							{transactions && transactions.length > 0 ? (
								transactions.map(transaction => (

									<RAL
										transaction={transaction}
										variant={(transaction.type === "INFLOW" && transaction.inflowType === constant.FLOW_TYPE.Untagged)
											? 'noTag' :
											(transaction.type === 'INFLOW') ? 'credit' : 'debit'}
										description={
											transaction.narration
												? transaction.narration
												: transaction.expenseCategory
													? transaction.expenseCategory.name
													: ''
										}
										accountNumber={
											transaction.type === constant.TRANSACTION_TYPE.INCOME
												? transaction.sourceAccountNumber
												: transaction.receiverAccountNumber
										}
										accountName={
											transaction.type === constant.TRANSACTION_TYPE.INCOME
												? transaction.sourceAccountName || ''
												: transaction.receiverAccountName || ''
										}
										bankName={transaction.bankName || ''}
										date={moment(transaction.createdAt).format(
											'dddd, MMMM Do YYYY, HH:mm:ss a'
										)}
										amount={amountFormatter(transaction.currencyCode).format(transaction.amountPaid)}
										balance={amountFormatter(transaction.currencyCode).format(transaction.walletBalance)}
										paymentStatus={transaction.status}
										transferReceipt={transaction.transfer}
										hide={hide}
									/>
								))
							) : (
								<MimaText variant="smallBold" mb={1}>
									No Transaction
								</MimaText>
							)}
						</div >
						<br />
						{/* <MimaButton title="View More" variant="text" /> */}
					</div>
				</>
			)}
		</section>
	);
};

export default RecentActivities;
