import { apiRequest, UseRequest } from "../utils/useAPIRequest";
import constant from "../utils/constant";
import { capitalizeFirstLetter } from "../utils/utils";
import { businessStore, userStore } from "../stores";

const login = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/users/user/login",
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    const { token, user } = data || {};
    userStore.setUser(user);
    businessStore.setBId(user.business._id);
    userStore.setToken(token);
    userStore.setActivated(true);
    userStore.setLoggedIn(true);
    userStore.setUserId(user._id);
    businessStore.setExpiredSubscription(
      user.business.subscription?.status === constant.PLAN_STATUS.EXPIRED
    );
    businessStore.setBusiness(user.business);
    return { status };
  }

  return { status, data, errorMessage };
};

const getPermissions = async () => {
  try {
    let { data } = await UseRequest({
      url: "/permissions/business",
      method: "get",
    });
    data = data.map((perm) => {
      perm.permissions = perm.permissions.map((value) => {
        value.name = capitalizeFirstLetter(value.name);
        return value;
      });
      return perm;
    });
    userStore.setPermissions(data);
    return { status: constant.Success };
  } catch (error) {
    const { data } = error.response || {};
    return { status: constant.Failed, errorMessage: data.message };
  }
};
const signup = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/businesses/business",
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    const { userId } = data || {};
    userStore.setUserId(userId);
    return { status };
  }

  return { status, errorMessage };
};

const activateUser = async (token) => {
  const { status, errorMessage, data } = await apiRequest({
    url: `/users/user/activate/${userStore.userId}/${token}/user`,
    method: "get",
  });
  if (status === constant.Success) {
    const { token, user } = data || {};

    userStore.setUser(user);
    userStore.setToken(token);
    userStore.setLoggedIn(true);
    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};

const resendCode = async (userId) => {
  const { status, errorMessage, data } = await apiRequest({
    url: `/users/user/resend/${userId}/user`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};
const forgotPassword = async (emailMobile) => {
  const { status, errorMessage, data } = await apiRequest({
    url: `/users/user/forget-password/${emailMobile}`,
    method: "get",
  });

  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};

const forgotPin = async (payload) =>
  await apiRequest({
    url: "/users/user/forget-pin",
    method: "post",
    payload,
  });

const changePassword = async (payload) =>
  await apiRequest({
    url: "/users/user/change-password",
    method: "patch",
    payload,
  });

const changePin = async (payload) =>
  await apiRequest({
    url: "/users/user/change-pin",
    method: "post",
    payload,
  });
const validateForgetPasswordToken = async (email, token) => {
  const { status, errorMessage, data } = await apiRequest({
    url: `/users/user/validate/${email}/${token}/user`,
    method: "get",
  });

  if (status === constant.Success) {
    const { token, user } = data || {};
    userStore.setUser(user);
    userStore.setToken(token);
    userStore.setLoggedIn(true);

    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};
const getUser = async (id) => {
  try {
    const { data, status } = await UseRequest({
      url: `/users/user/${id}`,
      method: "get",
    });
    if (status === constant.Success) {
      userStore.setUser(data);
      return { status: constant.Success };
    }
  } catch (error) {
    const { data } = error.response || {};
    return { status: constant.Failed, errorMessage: data.message };
  }
};
const resetForgotPassword = async (payload) => {
  const { status, errorMessage, data } = await apiRequest({
    url: "/users/user/reset-password",
    method: "post",
    payload,
  });

  if (status === constant.Success) {
    return { status: constant.Success, data };
  }
  return { status, errorMessage };
};

const setPin = async (payload) => {
  try {
    const { status } = await apiRequest({
      url: "/users/user/set-pin",
      method: "patch",
      payload,
    });
    if (status === constant.Success) {
      await getUser(userStore.user._id);
      return { status: constant.Success };
    }
  } catch (error) {
    const { data } = error.response || {};
    return { status: constant.Failed, errorMessage: data.message };
  }
};

const UserController = {
  setPin,
  login,
  signup,
  activateUser,
  resendCode,
  forgotPassword,
  validateForgetPasswordToken,
  resetForgotPassword,
  getUser,
  forgotPin,
  changePassword,
  changePin,
  getPermissions,
};

export default UserController;
