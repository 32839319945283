import React, { useEffect, useState } from "react";
import styles from "../../assets/styles/Components/Sidebar.module.scss";
import { useNavigate } from "react-router-dom";
import {
  SidebarData as MainSidebarData,
  ExpiredSidebarData,
} from "./SidebarData";
import { businessStore, userStore } from "../../stores";
import { FaBars } from "react-icons/fa";
import { observer } from "mobx-react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import AppDLBanner from "../AppDLBanner";
import { FashWhiteLogo } from "../../assets/img/ImgList";
import constant from "../../utils/constant";
import { CgWebsite } from "react-icons/cg";
import { FcDataConfiguration } from "react-icons/fc";
import { LuGitPullRequestDraft } from "react-icons/lu";
import { RiPaintBrushFill } from "react-icons/ri";

const Sidebar = () => {
  const [expandedNav, setExpandedNav] = useState(-1);

  const { user } = userStore;

  useEffect(() => {
    const updateSideBar = (SidebarData) => {
      const hasWebsite = user?.business?.todo?.createdWebsite;

      if (!hasWebsite) {
        const createWebsiteExists = SidebarData.some(
          (item) => item.title === "Create Website"
        );

        if (!createWebsiteExists) {
          SidebarData.push({
            title: "Create Website",
            module: "STYLE MANAGEMENT",
            type: "GENERAL",
            icon: <CgWebsite />,
            link: constant.Routes.CreateWebsite,
          });
        }
      }

      if (hasWebsite) {
        const websiteItems = [
          {
            title: "Styles",
            module: "STYLE MANAGEMENT",
            type: "GENERAL",
            icon: <CgWebsite />,
            link: constant.Routes.Styles,
          },
          {
            title: "Style Enquiries",
            module: "STYLE MANAGEMENT",
            type: "GENERAL",
            icon: <LuGitPullRequestDraft />,
            link: constant.Routes.StyleRequest,
          },
          {
            title: "Website Config",
            type: "GENERAL",
            module: "STYLE MANAGEMENT",
            icon: <FcDataConfiguration />,
            link: constant.Routes.WebsiteConfig,
            subNav: [
              {
                title: "General Website Config",
                type: "GENERAL",
                module: "STYLE MANAGEMENT",
                icon: <FcDataConfiguration />,
                link: constant.Routes.WebsiteConfig,
              },
              {
                title: "Themes Config",
                type: "GENERAL",
                module: "STYLE MANAGEMENT",
                icon: <RiPaintBrushFill />,
                link: constant.Routes.ThemeConfig,
              },
            ],
          },
        ];

        // Remove "Create Website" if it exists
        const filteredSidebarData = SidebarData.filter(
          (item) => item.title !== "Create Website"
        );

        // Insert new items in the desired order
        filteredSidebarData.splice(1, 0, websiteItems[0]);
        filteredSidebarData.splice(2, 0, websiteItems[1]);
        filteredSidebarData.splice(3, 0, websiteItems[2]);

        // Ensure uniqueness
        const uniqueTitles = new Set();
        const uniqueSidebarData = [];

        filteredSidebarData.forEach((item) => {
          if (!uniqueTitles.has(item.title)) {
            uniqueTitles.add(item.title);
            uniqueSidebarData.push(item);
          }
        });

        // Update SidebarData with the new order
        SidebarData.length = 0;
        SidebarData.push(...uniqueSidebarData);
      }
    };

    updateSideBar(SidebarData);
  }, [user?.business?.todo?.createdWebsite]);

  const userPermissions = new Set(
    user?.permissions?.map((perm) => perm.module) || []
  );
  const permissionArray = Array.from(userPermissions);

  const navigate = useNavigate();

  const SidebarData = businessStore.expiredSubscription
    ? ExpiredSidebarData
    : MainSidebarData;

  const filteredSidebarData = SidebarData.filter((item) => {
    if (permissionArray.includes(item.module)) {
      return true;
    }

    if (item.subNav) {
      const matchingSubNavItem = item.subNav.find((subNavItem) =>
        permissionArray.includes(subNavItem.module)
      );
      if (matchingSubNavItem) {
        return true;
      }
    }

    return !item.module && !item.subNav;
  });

  //for App dl banner
  const [appLink, setAppLink] = useState(true);
  const hasMobileApp = userStore?.hasMobileApp;

  return (
    <div className={styles.sb}>
      <div className={styles.sb_title}>
        <FaBars
          className={styles.sb_icon}
          // onClick={menuClicked}
        />
        <img src={FashWhiteLogo} className={styles.sb_logo} alt="Fash logo" />
      </div>
      <nav
        className={
          (appLink && !hasMobileApp) || !hasMobileApp
            ? styles.sb_nav_2
            : styles.sb_nav
        }
      >
        <ul className={styles.sb_nav_ul}>
          {filteredSidebarData.map((item, index) => {
            return (
              <li
                key={index}
                className={`${styles.sb_nav_item} ${
                  window.location.pathname === item.link
                    ? styles.sb_nav_item_active
                    : ""
                }`}
                onClick={() => {
                  if (item.subNav !== undefined) {
                    if (expandedNav === index) {
                      setExpandedNav(-1);
                    } else {
                      setExpandedNav(index);
                    }
                  } else {
                    // menuClicked();
                    navigate(item.link);
                  }
                }}
              >
                {!item.module ||
                userPermissions.has(item.module) ||
                item.type === "GENERAL" ? (
                  <>
                    <div className={styles.sb_nav_link_group}>
                      <div className={styles.sb_nav_link} key={item.title}>
                        <span className={styles.sb_nav_link_icon}>
                          {item.icon}
                        </span>
                        <span>{item.title}</span>
                      </div>

                      {item.subNav !== undefined && (
                        <MdOutlineKeyboardArrowDown
                          className={
                            expandedNav === index
                              ? styles.sb_nav_link_arr
                              : styles.sb_nav_link_arr_1
                          }
                        />
                      )}
                    </div>
                    {expandedNav === index && item.subNav !== undefined && (
                      <ul className={styles.subNav_ul}>
                        {item.subNav.map((x, i) => (
                          <li
                            className={`${styles.subNav_li} ${
                              window.location.pathname === x.link
                                ? styles.subNav_li_active
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              setExpandedNav(-1);
                              // menuClicked();
                              navigate(x.link);
                            }}
                          >
                            <div
                              className={styles.sb_nav_link}
                              key={item.title}
                            >
                              <span className={styles.sb_nav_link_icon}>
                                {x.icon}
                              </span>
                              <span>{x.title}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  ""
                )}
              </li>
            );
          })}
        </ul>
      </nav>
      <AppDLBanner
        appLink={appLink}
        setAppLink={setAppLink}
        hasMobileApp={hasMobileApp}
      />
    </div>
  );
};

export default observer(Sidebar);
