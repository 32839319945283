import {
  FlexButton,
  MimaButton,
  MimaInput,
  MimaText,
  MimaTextArea,
  Modal,
} from "../../components";
import styles from "../../assets/styles/Settings/Settings.module.scss";
import Support from "../../assets/img/MimaSupport.png";
import Sent from "../../assets/img/Mima msg-sent.png";
import { IoIosArrowForward } from "react-icons/io";
import { supportStore } from "../../stores";
import { IoMdCall } from "react-icons/io";
import { Formik } from "formik";
import React from "react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";
import useContactSupportLogic from "./useLogic/useContactSupportLogic";

const ContactSupport = ({ closeModal }) => {
  const {
    faq,
    loading,
    showFaq,
    onSubmit,
    validationSchema,
    faqData,
    sendMessage,
    showSendMessage,
    speakRep,
    showSpeakRep,
    msgSent,
    showMsgSent,
    supportMainMenu,
    category,
    showCategory,
    faqData2,
    goBack,
    file,
    selectClick,
  } = useContactSupportLogic({ closeModal });
  const callIcon = (
    <IoMdCall
      style={{
        color: "var(--color-primary)",
      }}
    />
  );
  const { primary, seconday, email, address } = supportStore.contactDetails;

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {!sendMessage && !speakRep && (
        <div className={styles.cs}>
          <MimaText variant="subtitleBold" mb={2}>
            Customer Support
          </MimaText>
          <img src={Support} className={styles.cs__img} alt="" />
          <div className={styles.cs__menu}>
            <div
              className={`${styles.cs__menu_main} ${styles.cs__menu_main__send}`}
              onClick={showSendMessage}
            >
              <div>
                <MimaText variant="subtitle">Send a message</MimaText>
                <MimaText variant="small">
                  To explain your problem in a message.
                </MimaText>
              </div>
              <IoIosArrowForward />
            </div>
            <div
              className={`${styles.cs__menu_main} ${styles.cs__menu_main__speak}`}
              onClick={showSpeakRep}
            >
              <div>
                <MimaText variant="subtitle">Speak with our rep</MimaText>
                <MimaText variant="small">
                  Speak to a Customer care representative.
                </MimaText>
              </div>
              <IoIosArrowForward />
            </div>

            <MimaText width={42} align="center" mt={2}>
              Reach out to us on any of our social media platforms by clicking
              below
            </MimaText>

            <div className={styles.cs__socials}>
              <a
                href="https://web.facebook.com/mimabusinessinc"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare />
              </a>
              <a
                href="https://www.instagram.com/mimabusiness_hq/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://twitter.com/MimaBusiness_Hq"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitterSquare />
              </a>
              <a
                href="https://www.linkedin.com/company/trymima/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      )}
      {sendMessage && (
        <div>
          {!msgSent && (
            <div className={styles.cs}>
              <MimaText variant="subtitle" mb={4}>
                Send a message
              </MimaText>
              <img src={Support} className={styles.cs__img} />
              <Formik
                initialValues={{
                  title: "",
                  message: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <>
                    <div>
                      <MimaText
                        mb={3}
                        color="var(--color-primary)"
                        align="center"
                      >
                        Send Message
                      </MimaText>

                      <div className={styles.bus__details}>
                        <MimaInput
                          type="text"
                          labelTitle="Title"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.title}
                          touched={touched.title}
                        />

                        <MimaTextArea
                          labelTitle="Message"
                          name="message"
                          value={values.message}
                          mt={3}
                          mb={3}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.message}
                          touched={touched.message}
                        />

                        <MimaButton
                          title="Add Image/File"
                          variant="outlined"
                          width={38}
                          onClick={selectClick}
                          mt={2}
                        />

                        {file !== null ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "2rem",
                            }}
                          >
                            <embed
                              src={file?.source || ""}
                              type="application/pdf"
                              className={styles.imageUpload}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        <MimaButton
                          disabled={loading}
                          loading={loading}
                          title="Send"
                          type="submit"
                          variant="form"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          )}
          {msgSent && (
            <div className={styles.cs}>
              <MimaText variant="subtitle" mb={4}>
                Send a message
              </MimaText>
              <img src={Sent} className={styles.cs__img} alt="" />
              <MimaText
                variant="subtitle"
                mt={3}
                mb={3}
                color="var(--color-primary)"
              >
                Message Sent
              </MimaText>

              <MimaButton
                title="Continue"
                buttonColor="var(--color-dark-3)"
                onClick={supportMainMenu}
                variant="form"
              />
            </div>
          )}
        </div>
      )}

      {speakRep && (
        <div className={styles.cs}>
          <img src={Support} className={styles.cs__img} alt="" />
          <MimaText variant="subtitle" mb={3} mt={3} align="center">
            To speak with <br /> our Representative
          </MimaText>
          <div className={styles.cs__dials}>
            {callIcon} <MimaText>Dial</MimaText>
            <MimaText variant="bodyBold">{primary}</MimaText>
          </div>
          <div className={styles.cs__dials}>
            {callIcon} <MimaText>Dial</MimaText>
            <MimaText variant="bodyBold">{seconday}</MimaText>
          </div>
          <div className={styles.cs__dials}>
            <MimaText>Email</MimaText>
            <MimaText variant="bodyBold">hello@fash.style.com</MimaText>
          </div>
          <MimaButton
            title="Continue"
            onClick={supportMainMenu}
            variant="form"
            width={38}
          />
        </div>
      )}
    </Modal>
  );
};

export default ContactSupport;
