import { css } from "styled-components";

export const base = css`
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 400;
  color: var(--color-dark);
  word-wrap: break-word;
`;

export const h1 = css`
  font-size: 6.4rem;
  line-height: 7.8rem;
  font-weight: 700;

  @media only screen and (max-width: 30em) {
    font-size: 4rem;
    line-height: 4.8rem;
  }
`;

export const h2 = css`
  font-size: 4rem;
  line-height: 4.9rem;
  font-weight: 700;
`;

export const h3 = css`
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-weight: 700;
`;

export const subtitle = css`
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 400;
`;

export const subtitleBold = css`
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 700;
`;

export const body = css`
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 400;

  @media only screen and (max-width: 30em) {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
`;

export const bodyBold = css`
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 700;
`;

export const small = css`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
`;

export const smallBold = css`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 700;
`;

export const xsmall = css`
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
`;

export const footer = css`
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 400;
`;

export const label = css`
  font-size: var(--default-font-small);
  font-family: inherit;
  margin-bottom: 2rem;
  color: var(--color-fash-wine);
`;
