import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class EmployeeStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'EmployeeStore',
			properties: [
				'activeEmployees',
				'suspendedEmployees',
				'terminatedEmployees',
				'selectedEmployee',
				'mappedEmployees',
				'businessAdmins',
				'departments',
				'mapDepartments',
				'units',
				'mapUnits',
				'groups',
				'mapGroups',
				'employees',
				'selectedEmployeeGroup',
				'employeesTotalCount',
				'selectedDepartment',
				'tags',
				'commissions',
				'commissionsTotalCount'
			],
		});
	}
	departments = [];
	units = [];
	commissions = [];
	employees = [];
	selectedEmployee = {};
	trackCreate = {};
	mappedEmployees = [];
	mapDepartments = [];
	mapUnits = [];
	loading = false;
	businessAdmins = [];
	groups = [];
	tags = [];
	mapGroups = [];
	selectedEmployeeGroup = {};
	employeesTotalCount = 0;
	commissionsTotalCount = 0;
	selectedDepartment={};

	reset = () => {
		this.mappedEmployees = [];
		this.tags = [];
		this.commissions = [];
		this.selectedEmployee = {};
		this.businessAdmins = [];
		this.mapDepartments = [];
		this.departments = [];
		this.mapUnits = [];
		this.units = [];
		this.groups = [];
		this.mapGroups = [];
		this.selectedEmployeeGroup = {};
		this.employeesTotalCount = 0;
		this.commissionsTotalCount = 0;
		this.selectedDepartment = {};
	};

	setLoading = async isLoading => {
		runInAction(() => {
			this.loading = isLoading;
		});
	};
	setEmployeesTotalCount = async state => {
		runInAction(() => {
			this.employeesTotalCount = state;
		});
	};
	setCommissionsTotalCount= async state => {
		runInAction(() => {
			this.commissionsTotalCount = state;
		});
	};
	setTrackCreateEmployee = async state => {
		runInAction(() => {
			this.trackCreate = state;
		});
	};
	setMapTags = async state => {
		runInAction(() => {
			this.tags = state;
		});
	};

	setBusinessAdmins = async state => {
		runInAction(() => {
			this.businessAdmins = state;
		});
	};
	setDepartments = async state => {
		runInAction(() => {
			this.departments = state;
		});
	};
	setMapDepartments = async state => {
		runInAction(() => {
			this.mapDepartments = state;
		});
	};
	setUnits = async state => {
		runInAction(() => {
			this.units = state;
		});
	};
	setMapUnits = async state => {
		runInAction(() => {
			this.mapUnits = state;
		});
	};
	setGroups = async state => {
		runInAction(() => {
			this.groups = state;
		});
	};
	setMapGroups = async state => {
		runInAction(() => {
			this.mapGroups = state;
		});
	};
	setEmployees = async employees => {
		runInAction(async () => {
			this.employees = employees;
		});
	};
	setCommissions = async commissions => {
		runInAction(async () => {
			this.commissions = commissions;
		});
	};
	setSelectedEmployeeGroup = async group => {
		runInAction(async () => {
			this.selectedEmployeeGroup = group;
		});
	};
	setMapEmployees = async state => {
		runInAction(async () => {
			this.mappedEmployees = state;
		});
	};

	setSelectedEmployee = async state => {
		runInAction(async () => {
			this.selectedEmployee = state;
		});
	};

	setSelectedDepartment = async state => {
		runInAction(async () => {
			this.selectedDepartment = state;
		});
	};
}
