import React from "react";
import { AppShell, MimaButton, MimaText } from "../../components";
import useThemeConfig from "./useLogic/useThemeConfig";
import styles from "../../assets/styles/ThemeConfig/ThemeConfig.module.scss";

const ThemeConfig = () => {
  const {
    themes,
    activeTheme,
    changeTheme,
    customWebHandler,
    currentTheme,
    setThemeHandler,
    loading,
  } = useThemeConfig();

  return (
    <AppShell pageTitle="Theme Configuration">
      <div>
        <MimaText mb={2} variant="subtitle" align="center">
          Choose your preferred Store Theme
        </MimaText>
        <div className={styles.themes}>
          {themes.map((theme, i) => (
            <div
              key={i}
              className={`${styles.theme} ${theme?.key === currentTheme?.key ? styles.theme__active : theme?.key === activeTheme?.key ? styles.theme__set : ""}`}
              onClick={() => {
                changeTheme(theme);
              }}
            >
              <div>
                <MimaText>{theme.name}</MimaText>
                {theme?.key === currentTheme?.key ? (
                  <MimaText
                    variant="smallBold"
                    color="var(--color-fash-green-2)"
                  >
                    ACTIVE
                  </MimaText>
                ) : (
                  ""
                )}
              </div>
              <img src={theme?.image} alt={theme?.name} />
              {theme?.key === activeTheme?.key &&
              theme?.key !== currentTheme?.key ? (
                <MimaButton
                  title="Set as theme"
                  mt={1}
                  onClick={() => {
                    setThemeHandler(theme?.key);
                  }}
                  loading={loading}
                />
              ) : (
                ""
              )}
              {theme?.key === currentTheme?.key &&
              currentTheme?.key !== "DEFAULT" ? (
                <MimaButton
                  title="Customise Your Website"
                  buttonColor="var(--color-dark)"
                  titleColor="var(--color-white)"
                  mt={1}
                  onClick={customWebHandler}
                />
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </AppShell>
  );
};

export default ThemeConfig;
