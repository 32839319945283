import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";

export default class QuotationStore {
    constructor () {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'QuotationStore',
            properties: [
                'quotations',
            ]
        })
    }

    quotations= [];

    reset = () => {
        this.quotations= [];
    }

    setQuotations = async state => {
        runInAction(async () => {
			this.quotations = state;
		});
    }

    
}