import * as yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { BusinessController, CustomerController } from "../../controllers";
import { customerStore, useStores } from "../../stores";
import constant from "../../utils/constant";
import { MimaToastUtil } from "../../components";
import { phoneRegExp, removeEmptyKeys } from "../../utils/utils";

const useCustomersLogic = () => {
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [viewCustomerModal, setViewCustomerModal] = useState(false);
  const [deleteCustomerModal, setDeleteCustomerModal] = useState(false);
  const [customerMenu, setCustomerMenu] = useState(false);

  //for customer table and pagination
  const [limit, setLimit] = useState(50);
  const [filterQuery, setFilterQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    userStore,
    customerStore: { setLoading, loading, customers, setSelectedCustomer },
  } = useStores();

  const logout = () => {
    userStore.setLoggedIn(false);
  };

  useEffect(() => {
    const setCustomers = async () => {
      setLoading(true);
      await Promise.all([
        CustomerController.getCustomers(),
        BusinessController.businessDetails(),
      ]);
      setLoading(false);
    };
    setCustomers();
  }, [setLoading]);

  const goToAddCustomer = () => {
    setAddCustomerModal(true);
    setEditCustomerModal(false);
    setViewCustomerModal(false);
    setDeleteCustomerModal(false);
  };

  const goToEditCustomer = () => {
    setEditCustomerModal(true);
    setViewCustomerModal(false);
    setAddCustomerModal(false);
    setDeleteCustomerModal(false);
  };

  const goToDeleteCustomer = () => {
    setDeleteCustomerModal(true);
    setEditCustomerModal(false);
    setViewCustomerModal(false);
    setAddCustomerModal(false);
  };

  const handleOptionSelect = (_id, customerDetail, option) => {
    customerStore.setSelectedCustomer(customerDetail);
    if (option.value === "delete customer") {
      goToDeleteCustomer();
    } else if (option.value === "edit customer") {
      goToEditCustomer();
    } else if (option.value === "view more") {
      goToViewCustomer();
    }
  };

  const goToViewCustomer = () => {
    setViewCustomerModal(true);
    setEditCustomerModal(false);
  };

  const closeModal = () => {
    setAddCustomerModal(false);
    setEditCustomerModal(false);
    setViewCustomerModal(false);
    setDeleteCustomerModal(false);
  };

  const showCustomerMenu = () => {
    setCustomerMenu(!customerMenu);
  };

  const goBack = () => {
    if (editCustomerModal) {
      setEditCustomerModal(false);
      setViewCustomerModal(true);
    } else {
      closeModal();
    }
  };

  const customerTableQuery = useMemo(() => {
    return `offset=${currentPage + 1}&limit=${limit}`;
  }, [currentPage, limit]);

  const { isLoading, data, isFetching } = CustomerController.useGetCustomers(
    customerTableQuery,
    filterQuery,
    searchQuery
  );

  const onLimitChange = (limit) => {
    setLimit(limit);
    setCurrentPage(0);
  };

  const customerDetailsValidation = () => {
    return yup.object({
      fullname: yup.string().required("Customer name is required"),
      mobile: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Customer phone number is required"),
    });
  };

  const onSubmitCreateCustomer = async (payload) => {
    setLoading(true);

    removeEmptyKeys(payload);

    const { status, errorMessage } =
      await CustomerController.createCustomer(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      closeModal();

      return;
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const onSubmitEditCustomer = async (payload) => {
    setLoading(true);
    for (const key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }

    const { status, errorMessage } = await CustomerController.updateCustomer(
      payload,
      customerStore.selectedCustomer._id
    );
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      closeModal();

      return;
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const onDelete = async (id) => {
    setLoading(true);
    const { status, errorMessage } =
      await CustomerController.deleteCustomer(id);

    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      return closeModal();
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  return {
    closeModal,
    loading,
    editCustomerModal,
    addCustomerModal,
    viewCustomerModal,
    deleteCustomerModal,
    customers,
    setSelectedCustomer,
    handleOptionSelect,
    goToDeleteCustomer,
    goToViewCustomer,
    showCustomerMenu,
    isLoading,
    isFetching,
    data,
    onLimitChange,
    goBack,
    goToAddCustomer,
    logout,
    setSearchQuery,
    customerDetailsValidation,
    onSubmitCreateCustomer,
    onSubmitEditCustomer,
    onDelete,
  };
};

export default useCustomersLogic;
