import { Modal, MimaText, FlexButton } from "../../components";
// import { ElectricBillContainer } from "../../containers";
import React from "react";
import useUtilitiesLogic from "./useLogic/useUtilitiesLogic";
import Electricity from "./Electricity";

function Utilities({ closeModal }) {
  const {
    selectElectricBill,
    mainContents,
    payElectricBill,
    goBack,
    pinContent,
    setPinContent,
  } = useUtilitiesLogic({ closeModal });
  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      {mainContents && (
        <>
          <MimaText variant="subtitle" mb={4}>
            Pay Electricity Bill
          </MimaText>

          <FlexButton title="Electricity" mt={4} onClick={payElectricBill} />
          {/* <FlexButton title="Toll Fees" mt={4} /> */}
        </>
      )}

      {selectElectricBill && (
        <Electricity
          closeModal={closeModal}
          pinContent={pinContent}
          setPinContent={setPinContent}
        />
      )}
    </Modal>
  );
}

export default Utilities;
