import { MimaText, Modal } from "../../components";
import { amountFormatter } from "../../utils/utils";
import React from "react";
import { transactionStore } from "../../stores";
import moment from "moment";

const IncomeViewMore = ({ closeModal }) => {
  const incomeList = transactionStore.selectedIncome;

  return (
    <Modal closeModal={closeModal} goBack={closeModal}>
      <MimaText
        variant="subtitleBold"
        mb={2}
        color="var(--color-fash-primary-3)"
      >
        Income Breakdown
      </MimaText>
      <div>
        <MimaText mb={1} labelTitle="Date and time">
          {moment(incomeList.createdAt).format("DD/MM/YYYY HH:mm a")}
        </MimaText>
        <MimaText mb={1} labelTitle="Customer Name">
          {incomeList?.customer?.fullname}
        </MimaText>
        <MimaText mb={1} labelTitle="Amount Charged">
          {amountFormatter(incomeList.currencyCode).format(
            incomeList.transactionAmount || 0
          )}
        </MimaText>
        <MimaText mb={1} labelTitle="Amount Received">
          {amountFormatter(incomeList.currencyCode).format(
            incomeList.amountPaid || 0
          )}
        </MimaText>
        <MimaText mb={1} labelTitle="Paid Date">
          {moment(incomeList.paidDate).format("DD/MM/YYYY HH:mm a")}
        </MimaText>
        <MimaText mb={1} labelTitle="Narration">
          {incomeList.narration ? incomeList.narration : ""}
        </MimaText>
        <MimaText mb={1} labelTitle="Balance">
          {amountFormatter(incomeList.currencyCode).format(
            incomeList.balanceAmount || 0
          )}
        </MimaText>
        <MimaText mb={1} labelTitle="Payment Status">
          {incomeList.status}
        </MimaText>
        <MimaText mb={1} labelTitle="Tag Type">
          {incomeList.inflowType}
        </MimaText>
        <MimaText mb={1} labelTitle="Payment Method">
          {incomeList.paymentMethod}
        </MimaText>
        <MimaText labelTitle="Shipping Fee">
          {amountFormatter(incomeList.currencyCode).format(incomeList.shipping)}
        </MimaText>
        <MimaText labelTitle="Discount Amount">
          {amountFormatter(incomeList.currencyCode).format(
            incomeList.discountAmount
          )}
        </MimaText>
        <MimaText mt={2} align="center">
          Order Analysis
        </MimaText>
        <MimaText mt={2} mb={2}>
          {incomeList.orders.map((x) => (
            <ul
              key={x._id}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "1rem",
              }}
            >
              <MimaText labelTitle="Product/Service">
                {x.item ? x.item : ""}
              </MimaText>
              <MimaText labelTitle="Quantity">{x.quantity}</MimaText>
              <MimaText labelTitle="Unit Price">
                {amountFormatter(incomeList.currencyCode).format(x.unitPrice)}
              </MimaText>
            </ul>
          ))}
        </MimaText>
      </div>
    </Modal>
  );
};

export default IncomeViewMore;
