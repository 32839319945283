import styles from "../../assets/styles/Settings/Settings.module.scss";
import {
  AppShell,
  IconButton,
  MimaText,
  MimaToastUtil,
} from "../../components";
import React, { useState } from "react";
import { businessStore } from "../../stores";
import { MdOutlineContentCopy } from "react-icons/md";

const ApiKeys = () => {
  const { business } = businessStore;

  const [hide, setHide] = useState(true);

  let eye;
  !hide ? (eye = "eyeOpen") : (eye = "eyeClose");

  const keyToggle = () => {
    setHide(!hide);
  };
  return (
    <AppShell pageTitle="API Keys">
      <div className={styles.ai}>
        <div className={styles.ai__card}>
          <div className={styles.ai__card__align}>
            <MimaText labelTitle="Live Secret Key">
              {!hide ? business.secretKey : "*******"}
            </MimaText>
            <IconButton onClick={() => keyToggle()} variant={eye} />
          </div>
          <div
            onClick={() => {
              navigator.clipboard.writeText(business ? business.secretKey : "");
              MimaToastUtil.success({
                message: "Copied!",
              });
            }}
            className={styles.ai__copy}
          >
            <MdOutlineContentCopy />
            <MimaText variant="smallBold" color="var(--color-white)">
              Click to copy Key
            </MimaText>
          </div>
        </div>
        <div className={styles.ai__card}>
          <MimaText labelTitle="Live Public Key">{business.publicKey}</MimaText>
          <div
            onClick={() => {
              navigator.clipboard.writeText(business ? business.publicKey : "");
              MimaToastUtil.success({
                message: "Copied!",
              });
            }}
            className={styles.ai__copy}
          >
            <MdOutlineContentCopy />
            <MimaText variant="smallBold" color="var(--color-white)">
              Click to copy Key
            </MimaText>
          </div>
        </div>
      </div>
    </AppShell>
  );
};

export default ApiKeys;
