import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { passwordRegex, removeEmptyKeys } from "../../../utils/utils";
import { UserController } from "../../../controllers";
import { userStore } from "../../../stores";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";

const useForgotPasswordResetLogic = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .test(
        "no-spaces",
        "Password cannot contain spaces",
        (value) => value && !/\s/.test(value) // Check for spaces before regex
      )
      .matches(
        passwordRegex,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .required("Password is required"),

    newPassword: yup
      .string()
      .test(
        "no-spaces",
        "New password cannot contain spaces",
        (value) => value && !/\s/.test(value) 
      )
      .matches(
        passwordRegex,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .test(function () {
        const { newPassword, password } = this.parent;
        return newPassword !== password
          ? this.createError({
              message:
                "New password and confirmed new password should be the same",
              path: "newPassword",
            })
          : true;
      })
      .required("Password is required"),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const payload = { newPassword: values.newPassword };
    removeEmptyKeys(payload);

    const { status, errorMessage } =
      await UserController.resetForgotPassword(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: "Password successfully changed, Log in again to continue",
      });
      userStore.setLoggedIn(false);
      return navigate(constant.Routes.Login);
    }

    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  return {
    loading,
    validationSchema,
    onSubmit,
  };
};

export default useForgotPasswordResetLogic;
