import {
  BusinessController,
  EmployeeController,
  SettingsController,
  SupportController,
} from "../../../controllers";
import React, { useEffect, useState } from "react";
import { MimaToastUtil } from "../../../components";
import { Settings } from "../../../pages/Settings";
import { useNavigate } from "react-router-dom";
import constant from "../../../utils/constant";
import { useStores } from "../../../stores";

const { faqCategories, contactDetails } = SupportController;

const useSettingsLogic = () => {
  const [businessInfo, setBusinessInfo] = useState(false);
  const [addBusinessModal, setAddBusiness] = useState(false);
  const [securityModal, setSecurityModal] = useState(false);
  const [accountManagerModal, setAccountManagerModal] = useState(false);
  const [inviteFriendsModal, setInviteFriendsModal] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [faqModal, setFaqModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [withdrawalAccountModal, setWithdrawalAccountModal] = useState(false);
  const [managePayroll, setManagePayroll] = useState(false);
  const [productSalesConfigModal, setProductSalesConfig] = useState(false);
  const [inventoryConfigModal, setInventoryConfigModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [kycModal, setKycModal] = useState(false);
  const [tier, setTier] = useState("Tier 0");
  const [cardInfoModal, setCardInfoModal] = useState(false);
  const [invoiceReminderModal, setInvoiceReminderModal] = useState(false);
  const [generalSettingsModal, setGeneralSettingsModal] = useState(false);
  const [subscriptionsModal, setSubscriptionsModal] = useState(false);

  const { userStore, businessStore, employeeStore } = useStores();

  const navigate = useNavigate();
  useEffect(() => {
    const setEmployees = async () => {
      setLoading(true);
      await Promise.all([
        EmployeeController.getBusinessAdmins(),
        faqCategories(),
        contactDetails(),
        EmployeeController.getEmployees(),
        SettingsController.securityQuestions(),
        // WalletController.banks(),
        // PayrollController.getConfiguredPayroll(),
        BusinessController.businessDetails(),
      ]);
      setLoading(false);
    };
    setEmployees();
  }, []);

  const goToBusinessInfo = () => {
    setBusinessInfo(true);
  };
  const goToAddBusiness = () => {
    setAddBusiness(true);
  };
  const refresh = async () => {
    setLoading(true);
    await Promise.all([
      EmployeeController.getBusinessAdmins(),
      //   PayrollController.getConfiguredPayroll(),
      BusinessController.businessDetails(),
    ]);
    setLoading(false);
  };

  const goToProductSalesConfig = () => {
    setProductSalesConfig(true);
  };
  const goToSecurity = () => {
    setSecurityModal(true);
  };

  const goToWithdrawalAccount = () => {
    // isOnboardingDone();
    setWithdrawalAccountModal(true);
  };

  const goToCardInfo = () => {
    setCardInfoModal(true);
  };

  const goToAccountManager = () => {
    setAccountManagerModal(true);
  };
  const goToInvite = () => {
    setInviteFriendsModal(true);
  };
  const goToSupport = () => {
    setSupportModal(true);
  };
  const goTologout = () => {
    setLogoutModal(true);
  };
  const goToFaq = () => {
    setFaqModal(true);
  };

  const goToInvoiceReminderModal = () => {
    setInvoiceReminderModal(true);
  };

  const goToManagePayroll = () => {
    // isOnboardingDone();
    setManagePayroll(true);
  };

  const goToBusinessAdmin = () => {
    navigate(constant.Routes.BusinessAdmin);
  };

  const goToInventoryConfig = () => {
    setInventoryConfigModal(true);
  };

  const goToGeneralSettings = () => {
    setGeneralSettingsModal(true);
  };

  const goToSubscriptions = () => {
    setSubscriptionsModal(true);
  };
  const goToApiKeys = () => {
    navigate(constant.Routes.ApiKeys);
  };

  const isActivated = userStore?.isActivated;
  const hasPin = userStore?.user?.hasPin;
  const isOnboarded = userStore?.isOnBoardingDone !== "false";
  const hasWithdrawal = businessStore?.business?.withdrawalBankAccount;
  const hasBizReg = false;

  let [a, b, c, d, e] = [0, 0, 0, 0, 0];

  isActivated ? (a = 25) : (a = 0);
  isOnboarded ? (b = 25) : (b = 0);
  hasPin ? (c = 25) : (c = 0);
  hasWithdrawal ? (d = 25) : (d = 0);
  hasBizReg ? (e = 25) : (e = 0);

  const goToKyc = () => {
    if (isActivated && isOnboarded && hasBizReg) {
      setTier("Tier 3");
    } else if (isActivated && isOnboarded) {
      setTier("Tier 2");
    } else if (isActivated) {
      setTier("Tier 1");
    }
    // setTier("Tier 1")
    setKycModal(true);
  };

  const closeModal = () => {
    // await BusinessController.businessDetails();
    setBusinessInfo(false);
    setAddBusiness(false);
    setSecurityModal(false);
    setAccountManagerModal(false);
    setInviteFriendsModal(false);
    setSupportModal(false);
    setLogoutModal(false);
    setWithdrawalAccountModal(false);
    setManagePayroll(false);
    setInventoryConfigModal(false);
    setProductSalesConfig(false);
    setKycModal(false);
    setFaqModal(false);
    setGeneralSettingsModal(false);
    setCardInfoModal(false);
    setInvoiceReminderModal(false);
    setSubscriptionsModal(false);
  };

  return {
    goToInvoiceReminderModal,
    invoiceReminderModal,
    generalSettingsModal,
    goToGeneralSettings,
    goToFaq,
    faqModal,
    businessInfo,
    goToBusinessInfo,
    goToAddBusiness,
    addBusinessModal,
    securityModal,
    withdrawalAccountModal,
    accountManagerModal,
    inviteFriendsModal,
    supportModal,
    logoutModal,
    loading,
    goToSecurity,
    goToWithdrawalAccount,
    goToAccountManager,
    goToBusinessAdmin,
    goToInvite,
    goToSupport,
    goTologout,
    goToProductSalesConfig,
    closeModal,
    managePayroll,
    productSalesConfigModal,
    goToManagePayroll,
    inventoryConfigModal,
    goToInventoryConfig,
    goToKyc,
    kycModal,
    tier,
    refresh,
    cardInfoModal,
    goToCardInfo,
    goToSubscriptions,
    subscriptionsModal,
    goToApiKeys,
  };
};

export default useSettingsLogic;
