import styles from "../../assets/styles/Settings/Settings.module.scss";
import { MimaButton, MimaText, Modal } from "../../components";
import React from "react";
import useLogoutLogic from "./useLogic/useLogoutLogic";

const Logout = ({ closeModal }) => {
  const { logout } = useLogoutLogic({ closeModal });
  return (
    <Modal closeModal={closeModal}>
      <MimaText variant="subtitle" mb={4} color="var(--color-primary)">
        Are you sure you <br /> want to log out?
      </MimaText>

      <div style={{ display: "flex", gap: "2rem" }}>
        <MimaButton
          title="Yes"
          onClick={logout}
          width={18}
          titleColor="var(--color-white)"
          variant="regular"
        />
        <MimaButton
          title="No"
          onClick={closeModal}
          buttonColor="var(--color-error)"
          titleColor="var(--color-white)"
          width={18}
          variant="regular"
        />
      </div>
    </Modal>
  );
};

export default Logout;
