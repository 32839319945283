import {
  MimaButton,
  MimaText,
  Modal,
  PageLoader,
  ReceiptCheckBox as RCB,
} from "../../components";
import styles from "../../assets/styles/Settings/Settings.module.scss";
import React from "react";
import { userStore } from "../../stores";
import VisaLogo from "../../assets/img/VisaCardLogo.png";
import MastercardLogo from "../../assets/img/masterCardLogo.png";
import VerveLogo from "../../assets/img/verve-logo.png";
import { PaystackButton } from "react-paystack";
import { MdDeleteForever } from "react-icons/md";
import useCardInfoLogic from "./useLogic/useCardInfoLogic";

const CardInfo = ({ closeModal }) => {
  const {
    stage,
    onSubmit,
    setLoading,
    validationSchema,
    loading,
    chargeCard,
    setStage,
    deleteCardHandler,
    goBack,
    confirmDelete,
  } = useCardInfoLogic({ closeModal });
  const availableCards = userStore?.availableCards;

  return (
    <Modal closeModal={closeModal} goBack={goBack}>
      <MimaText variant="subtitle" mb={3}>
        Card Information
      </MimaText>

      {stage === "" && (
        <>
          {loading ? (
            <PageLoader loading={loading} />
          ) : (
            <div>
              <MimaText color="var(--color-dark)" align="center">
                Add your Debit/Credit Card Info
              </MimaText>
              <MimaText mb={5} variant="small" width={38} align="center">
                Your Card Info is Securely Protected by Paystack
              </MimaText>
              <MimaText mb={1} variant="bodyBold" width={38} align="center">
                To confirm that your card is working, a charge of 50 naira will
                be applied.
              </MimaText>
              <div className={styles.paystackButton_container}>
                <PaystackButton
                  {...chargeCard(50 * 100)}
                  className={styles.paystackButton}
                />
              </div>
            </div>
          )}
        </>
      )}

      {stage === "stage2" && (
        <>
          <MimaText
            color="var(--color-primary)"
            variant="bodyBold"
            align="center"
            mb={3}
          >
            Added Card(s)
          </MimaText>

          <>
            {loading ? (
              <PageLoader loading={loading} />
            ) : (
              <div>
                {availableCards.map((cards) => (
                  <div className={styles.cards} key={cards._id}>
                    <div className={styles.cards__beside}>
                      <img
                        src={
                          cards.card_type.trim() === "verve"
                            ? VerveLogo
                            : cards.card_type.trim() === "visa"
                              ? VisaLogo
                              : MastercardLogo
                        }
                        alt="card Logo"
                      />
                      <MimaText>**** {cards.last4}</MimaText>
                    </div>
                    <div
                      className={styles.cards__delete}
                      onClick={() => {
                        confirmDelete(cards._id);
                      }}
                    >
                      <MdDeleteForever />
                      <p> Delete </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>

          <MimaButton
            title="Add Another Card"
            onClick={() => {
              setStage("");
            }}
            mt={4}
            variant="colorable"
            buttonColor="var(--color-dark)"
          />
        </>
      )}

      {stage === "stage3" && (
        <>
          {loading ? (
            <PageLoader loading={loading} />
          ) : (
            <>
              <MimaText
                width={38}
                align="center"
                mb={2}
                color="var(--color-error)"
              >
                Are you sure you want to Delete this Card
              </MimaText>
              <div className="modal__beside">
                <MimaButton
                  title="Yes"
                  width={18}
                  onClick={deleteCardHandler}
                  variant="form"
                />
                <MimaButton
                  title="No"
                  width={18}
                  buttonColor="var(--color-dark)"
                  variant="form"
                  onClick={() => {
                    setStage("stage2");
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default CardInfo;
