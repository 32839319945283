import React from "react";
import AuthOnboardingShell from "../AuthOnboardingShell";
import { Formik } from "formik";
import { MimaButton, MimaInput } from "../../../components";
import useForgotPasswordLogic from "./useForgotPasswordLogic";
import styles from "../../../assets/styles/Auth/AuthOnboarding.module.scss";

const ForgotPassword = () => {
  const { loading, validationSchema, onSubmit } = useForgotPasswordLogic();
  return (
    <AuthOnboardingShell>
      <Formik
        initialValues={{
          emailMobile: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className={styles.form}>
            <MimaInput
              labelTitle="Email address"
              type="text"
              placeholder="Enter your email address"
              id="emailMobile"
              name="emailMobile"
              value={values.emailMobile}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.emailMobile}
              touched={touched.emailMobile}
              mb={2}
              variant="form"
            />
            <MimaButton
              title="Continue"
              onClick={handleSubmit}
              type="submit"
              loading={loading}
              variant="form"
            />
          </div>
        )}
      </Formik>
    </AuthOnboardingShell>
  );
};

export default ForgotPassword;
