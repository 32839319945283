import styles from '../../assets/styles/Button.module.scss';
import ChevronButton from '../../assets/img/forward.png';
import PulseLoader from 'react-spinners/PulseLoader';
import * as buttonStyles from './FlexButtonStyles';
import { IoIosArrowForward } from 'react-icons/io';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	title: PropTypes.string,
	loading: PropTypes.bool,
	type: PropTypes.oneOf(['button', 'submit', 'reset']),
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf(['main', 'other']),
	width: PropTypes.number,
	icon: PropTypes.any,
	buttonColor: PropTypes.string,
	mt: PropTypes.number,
	mr: PropTypes.number,
	mb: PropTypes.number,
	ml: PropTypes.number,
	labelTitle: PropTypes.string,
};

const defaultProps = {
	title: '',
	onClick: () => {},
	variant: 'main',
	icon: <IoIosArrowForward />,
	type: 'button',
};

function FlexButton({ title, loading, disabled, icon, ...props }) {
	return (
		<ButtonSkeleton {...props} disabled={loading || disabled} icon={icon}>
			{loading ? (
				<PulseLoader color={'white'} loading={loading} size={15} />
			) : (
				<>
					{title} {icon}
				</>
			)}
		</ButtonSkeleton>
	);
}

const ButtonSkeleton = styled.button`
	${buttonStyles.base};
	${props => buttonStyles[props.variant]};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.mt ? `margin-top: ${props.mt}rem` : '')};
	${props => (props.mr ? `margin-right: ${props.mr}rem` : '')};
	${props => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
	${props => (props.ml ? `margin-left: ${props.ml}rem` : '')};
	${props =>
		props.buttonColor ? `background-color: ${props.buttonColor}` : ''};
`;

FlexButton.propTypes = propTypes;

FlexButton.defaultProps = defaultProps;

export default FlexButton;
