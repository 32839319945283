import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../assets/styles/AppHome/ReminderCard.module.scss';

const propTypes = {
	title: PropTypes.string,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	time: PropTypes.string,
	styleClass: PropTypes.any,
};

const defaultProps = {
	title: '',
	loading: false,
	onClick: () => {},
	disabled: false,
	time: '',
	styleClass: '',
};

const ReminderComponent = ({ title, time, styleClass, ...props }) => {
	return (
		<div className={styles.reminder__list}>
			<input type="checkbox" className={styles.reminder__list__check} />
			<div className={styles.reminder__list__details}>{title}</div>
			<div className={styles.reminder__list__time}>{time}</div>
		</div>
	);
};

export default ReminderComponent;
