import React from "react";
import { useState } from "react";
import { useFileUpload } from "use-file-upload";
import * as yup from "yup";
import { BusinessController, SettingsController } from "../../../controllers";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import { phoneRegExp } from "../../../utils/utils";
import { userStore } from "../../../stores";

const useKycLogic = ({ closeModal, tier }) => {
  const [addCac, setAddCac] = useState(false);
  const [addBvn, setAddBvn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, selectFile] = useFileUpload();
  const [otpView, setOtpView] = useState(false);

  const selectClick = () => {
    selectFile({ accept: "image/*" });
  };

  const goToAddCac = () => {
    setAddCac(true);
  };

  const goToAddBvn = () => {
    setAddBvn(true);
  };

  const Tier2ValidationSchema = () => {
    yup.object({
      address: yup.string().required("Please enter your address"),
      bizIndustry: yup.string().required("Please select a category"),
      mobile: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Business Phone number is required"),
    });
  };

  const onSubmitTier2 = async (values) => {
    setLoading(true);
    let fileToSend;
    if (file && typeof file.source === "string") {
      fileToSend = await fetch(file.source)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new File([blob], "name", {
              type: blob.type,
              lastModified: new Date(),
            })
        );
      values.logo = fileToSend;
    }

    const fdata = new FormData();
    fdata.append("businessAddress", values.address);
    fdata.append("businessCategory", values.bizIndustry);
    fdata.append("businessMobile", values.businessMobile);
    fdata.append("mobile", values.mobile);
    const names = values.fullname.split(" ");
    fdata.append("firstname", names[0]);
    fdata.append("lastname", names.length > 2 ? names[2] : names[1]);
    fdata.append("middlename", names.length > 2 ? names[1] : "");
    values.logo && fdata.append("logo", values.logo);
    values.regNo && fdata.append("businessRegNum", values.regNo);
    fdata.append("bvn", values.bvn);

    const { status, errorMessage } = await BusinessController.update(fdata);

    if (status === constant.Success) {
      setLoading(false);
      setOtpView(true);
    } else {
      setLoading(false);
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  const onSubmitOTP = async (token) => {
    setLoading(true);
    const { status, errorMessage } =
      await BusinessController.activateBvn(token);
    if (status === constant.Success) {
      await SettingsController.dashboardData();
      setLoading(false);
      MimaToastUtil.success({
        message: "Tier 2 Successfully activated, Login again to continue",
      });
      userStore.setLoggedIn(false);
    } else {
      setLoading(false);
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  const goBack = () => {
    if (addBvn && otpView) {
      setOtpView(false);
    } else if (addCac) {
      setAddCac(false);
    } else if (addBvn) {
      setAddBvn(false);
    } else {
      closeModal();
    }
  };

  return {
    tier,
    addCac,
    addBvn,
    goToAddCac,
    goToAddBvn,
    goBack,
    loading,
    Tier2ValidationSchema,
    onSubmitTier2,
    selectClick,
    file,
    otpView,
    onSubmitOTP,
  };
};

export default useKycLogic;
