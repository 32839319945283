import { MimaButton, MimaText, Modal } from "..";
import React from "react";
import { userStore } from "../../stores";

const LogoutModal = ({ closeModal }) => {
  const logout = () => {
    userStore.setLoggedIn(false);
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <MimaText variant="subtitle" mb={2}>
        Are you sure you <br /> want to log out?
      </MimaText>

      <div style={{ display: "flex", gap: "2rem" }}>
        <MimaButton
          title="Yes"
          onClick={logout}
          width={18}
          titleColor="var(--color-white)"
          variant="regular"
        />
        <MimaButton
          title="No"
          onClick={closeModal}
          buttonColor="var(--color-error)"
          titleColor="var(--color-white)"
          width={18}
          variant="regular"
        />
      </div>
    </Modal>
  );
};

export default LogoutModal;
