import { apiRequest } from '../utils/useAPIRequest';
import { businessStore, customerStore } from '../stores';
import constant from '../utils/constant';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../App';

const getCustomers = async () => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/customers/business',
		method: 'get',
	});
	if (status === constant.Success) {
		customerStore.setCustomers(data.customers);
		customerStore.setMapCustomers(
			data.customers.map(customer => {
				return { key: customer._id, value: customer.fullname };
			})
		);
		return { status };
	}
	return { status, errorMessage };
};
const getCustomerById = async id =>
	await apiRequest({ url: `/customers/customer/${id}`, method: 'get' });

const deleteCustomer = async id => {
	const { status, errorMessage } = await apiRequest({
		url: `/customers/customer/${id}`,
		method: 'delete',
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'getCustomers'] });
		const updatedCustomers = customerStore.customers.filter(
			customer => customer._id !== id
		);
		customerStore.setCustomers(updatedCustomers);
		return { status };
	}
	return { status, errorMessage };
};

const updateCustomer = async (payload, id) => {
	const { status, errorMessage } = await apiRequest({
		url: `/customers/customer/${id}`,
		method: 'patch',
		payload,
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'getCustomers'] });
		await getCustomers();
		return { status };
	}
	return { status, errorMessage };
};

const createCustomer = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/customers/customer',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'getCustomers'] });
		await getCustomers();
		return { status };
	}
	return { status, errorMessage };
};

const createGroup = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/customer-groups',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		const id = businessStore.bId;
		queryClient.invalidateQueries({ queryKey: [id, 'getCustomers'] });
		await getCustomersGroups();
		return { status };
	}
	return { status, errorMessage };
};

const getCustomersGroups = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/customer-groups/customer-group',
		method: 'get',
		payload,
	});
	if (status === constant.Success) {
		customerStore.setMapGroups(
			data.groups.map(group => {
				return {
					key: group._id,
					value: group.name,
				};
			})
		);
		return { status };
	}
	return { status, errorMessage };
};

const createBulkCustomer = async payload =>
	await apiRequest({ url: '/customers/bulk', method: 'post', payload });

const uploadCustomerImage = async customerId =>
	await apiRequest({
		url: '/customers/bulk',
		method: 'put',
		payload: { customerId },
		hasImageUpload: true,
	});

const useGetCustomers = (customerTableQuery, filterQuery, searchQuery) => {
	const id = businessStore.bId;

	const query = useMemo(() => {
		if (searchQuery) {
			return `${customerTableQuery}&${filterQuery}&search=${searchQuery}`;
		} else if (filterQuery) {
			return `${customerTableQuery}&${filterQuery}`;
		} else {
			return customerTableQuery;
		}
	}, [filterQuery, customerTableQuery, searchQuery]);

	return useQuery({
		queryKey: [id, 'getCustomers', query],
		queryFn: () => {
			return apiRequest({
				url: `/customers/business?${query}`,
				method: 'get',
			});
		},
		keepPreviousData: true,
	});
};

export default {
	createBulkCustomer,
	createGroup,
	getCustomersGroups,
	createCustomer,
	updateCustomer,
	deleteCustomer,
	getCustomerById,
	getCustomers,
	uploadCustomerImage,
	useGetCustomers,
};
