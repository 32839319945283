import React, { useMemo } from "react";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";
import {
  invoiceStore,
  transactionStore,
  userStore,
  useStores,
} from "../../../stores";
import {
  InvoiceController,
  TransactionsController,
} from "../../../controllers";
import { amountFormatter } from "../../../utils/utils";
import { object, string, number } from "yup";

const useUpdateInvoiceLogic = ({ closeModal }) => {
  const {
    appStore: { loading, setLoading },
  } = useStores();

  const setTransactions = useMemo(
    () => async () => {
      setLoading(true);
      await TransactionsController.transactions();
      setLoading(false);
      return setTransactions;
    },
    [setLoading]
  );

  const invoice = invoiceStore.selectedInvoice;
  const validationSchema = () => {
    return object({
      paidDate: string(),
      amountPaid: number().min(0),
      paymentMethod: string().test(function () {
        const { status, paymentMethod } = this.parent;
        return (status === constant.TRANSACTION_STATUS.PAID ||
          status === constant.TRANSACTION_STATUS.PARTIALLY_PAID) &&
          !paymentMethod
          ? this.createError({
              message: `Payment method is required for a ${status} status`,
              path: "paymentMethod",
            })
          : true;
      }),
      status: string().required(" Status is required"),
    });
  };

  const balanceAmount = (values) =>
    amountFormatter(invoice.currencyCode).format(
      values.status === constant.TRANSACTION_STATUS.PAID
        ? 0
        : invoice.deposit - values.amountPaid
    );

  const onCloseModal = () => {
    transactionStore.setUntagged({});
    closeModal();

    return;
  };

  const onTagSubmit = async () => {
    const payload = {
      // inflowType: transactionStore.untagged.inflowType,
      inflowType: "Income",
      invoiceId: transactionStore.untagged._id,
    };

    const { status, errorMessage } = await TransactionsController.tagIncome(
      payload,
      transactionStore.untagged._id
    );
    setLoading(false);
    if (status === constant.Success) {
      return onCloseModal();
    }
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };

  const onSubmit = async (payload) => {
    for (const key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }

    setLoading(true);
    payload.invoiceId = invoice._id;
    if (
      payload.status === constant.TRANSACTION_STATUS.PAID &&
      payload.walletInflow === false
    )
      payload.amountPaid = invoice.deposit;
    delete payload.walletInflow;
    delete payload.untagged;
    if (transactionStore.untagged._id) {
      const { status, errorMessage } =
        await InvoiceController.updateInvoiceStatus(payload);
      setLoading(false);
      if (status === constant.Success) {
        onTagSubmit();
        MimaToastUtil.success({ message: constant.Success });
      } else {
        MimaToastUtil.error({ message: errorMessage });
      }
      closeModal();

      return;
    } else {
      const { status, errorMessage } =
        await InvoiceController.updateInvoiceStatus(payload);
      setLoading(false);
      if (status === constant.Success) {
        MimaToastUtil.success({ message: constant.Success });
      } else {
        MimaToastUtil.error({ message: errorMessage });
      }
      closeModal();

      return;
    }
  };

  return { balanceAmount, loading, invoice, onSubmit, validationSchema };
};

export default useUpdateInvoiceLogic;
