import React from "react";
import AuthOnboardingShell from "../AuthOnboardingShell";
import useUtitlityRoutes from "../../../hooks/useUtitlityRoutes";
import styles from "../../../assets/styles/Auth/AuthOnboarding.module.scss";
import { MimaButton, MimaInput, MimaText } from "../../../components";
import { Formik } from "formik";
import useLoginLogic from "./useLoginLogic";
import { observer } from "mobx-react";

const Login = () => {
  const { switchToSignup, switchToForgotPassword } = useUtitlityRoutes();
  const { loading, validationSchema, onSubmit } = useLoginLogic();

  return (
    <AuthOnboardingShell>
      <MimaText variant="subtitleBold" align="center" mb={4}>
        Log In
      </MimaText>
      <MimaText variant="small" align="center" mb={2}>
        Don’t have an account?{" "}
        <span className={styles.clickable} onClick={switchToSignup}>
          Sign Up{" "}
        </span>
        for free
        <br /> it will take less than a minute
      </MimaText>

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className={styles.form}>
            <MimaInput
              labelTitle="Email address"
              type="text"
              placeholder="Enter your email address"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email}
              touched={touched.email}
              mb={2}
              variant="form"
            />
            <MimaInput
              labelTitle="Password"
              type="text"
              placeholder="Enter your password"
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              passwordIcon
              error={errors.password}
              touched={touched.password}
              variant="form"
              mb={2}
            />
            <MimaButton
              title="Login"
              onClick={handleSubmit}
              type="submit"
              loading={loading}
              variant="form"
            />
          </div>
        )}
      </Formik>
      <MimaText align="center" variant="small" mt={2}>
        Forgot your password?
        <span className={styles.clickable} onClick={switchToForgotPassword}>
          {" "}
          Click Here
        </span>
      </MimaText>
    </AuthOnboardingShell>
  );
};

export default observer(Login);
