import { ExchangeController } from "../../../controllers";
import { useStores, exchangeStore } from "../../../stores";
import React, { useState } from "react";
import { object, number } from "yup";
import { MimaToastUtil } from "../../../components";
import constant from "../../../utils/constant";

const useWalletExchangeLogic = ({ closeWalletModal }) => {
  const [loading, setLoading] = useState(false);
  const [pinStage, setPinStage] = useState(false);
  const [fee, setFee] = useState(0);
  const { fromCurrency, toCurrency } = exchangeStore.exchangeCurrencies;
  const ngnRate = exchangeStore.currentExchangeRate;

  const getExchangeAmount = async (amount) => {
    setLoading(true);
    let query;
    query = `fromCurrency=${fromCurrency}&toCurrency=${toCurrency}&amount=${amount}`;

    const { status, errorMessage, data } =
      await ExchangeController.getExchangeRateAmount(query);
    setFee(data.charge);
    if (status === constant.Success) {
      setLoading(false);
    } else {
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  const {
    userStore,
    exchangeStore: { currentExchangeRate },
  } = useStores();

  const validationSchema = () => {
    return object({
      amount: number().when("validationControl", {
        is: true,
        then: (schema) =>
          schema
            .min(ngnRate, `Amount to change must be greater than ${ngnRate}`)
            .required("Amount is required"),
        otherwise: (schema) =>
          schema
            .min(1, `Amount to change must be greater than 1`)
            .required("Amount is required"),
      }),
    });
  };

  const onChangeToCurrency = async () => {
    setLoading(true);
    const value = exchangeStore.exchangeCurrencies;
    let query;
    query = `fromCurrency=${value.fromCurrency}&toCurrency=${value.toCurrency}`;
    await ExchangeController.getCurrentExchangeRate(query);
    setLoading(false);
  };

  const onCloseModal = () => {
    closeWalletModal();
    exchangeStore.setCurrentExchangeRate("");
    exchangeStore.setExchangeCurrencies({});
    exchangeStore.setExchangeRateValue(0);
  };

  const onSubmit = (values) => {
    delete values.exchangeAmount;
    delete values.validationControl;

    setLoading(true);
    exchangeStore.setExchangePayload(values);
    setPinStage(true);
    setLoading(false);
  };

  const onSubmitPin = async (pin) => {
    setLoading(true);
    const payload = {
      ...exchangeStore.exchangePayload,
      pin: pin,
    };

    const { status, errorMessage } =
      await ExchangeController.changeCurrency(payload);
    if (status === constant.Success) {
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      onCloseModal();

      return;
    } else {
      setLoading(false);
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  const goBack = () => {
    if (pinStage) {
      setPinStage(false);
    } else {
      onCloseModal();
    }
  };
  const closeModal = closeWalletModal;

  return {
    closeModal,
    onChangeToCurrency,
    validationSchema,
    onSubmit,
    getExchangeAmount,
    goBack,
    fee,
    loading,
    pinStage,
    onSubmitPin,
  };
};

export default useWalletExchangeLogic;
