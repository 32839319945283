import React from "react";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
import { InvoiceController, QuotationController } from "../../../controllers";
import { useStores } from "../../../stores";
import { object, string, number } from "yup";

const useUpdateQuotationLogic = ({ closeModal, quote }) => {
  const {
    appStore: { loading, setLoading },
  } = useStores();
  const validationSchema = () => {
    return object({
      paidDate: string().test(function () {
        const { status, paidDate } = this.parent;
        return (status === constant.TRANSACTION_STATUS.PAID ||
          status === constant.TRANSACTION_STATUS.PARTIALLY_PAID) &&
          !paidDate
          ? this.createError({
              message: `Payment date is required for a ${status} status`,
              path: "paidDate",
            })
          : true;
      }),
      amountPaid: number()
        .min(0)
        .test(function () {
          const { status, amountPaid } = this.parent;
          return status === constant.TRANSACTION_STATUS.PARTIALLY_PAID &&
            amountPaid === 0
            ? this.createError({
                message: `Amount Paid is required for ${status} status`,
                path: "amountPaid",
              })
            : true;
        }),
      paymentMethod: string().test(function () {
        const { status, paymentMethod } = this.parent;
        return (status === constant.TRANSACTION_STATUS.PAID ||
          status === constant.TRANSACTION_STATUS.PARTIALLY_PAID) &&
          !paymentMethod
          ? this.createError({
              message: `Payment method is required for a ${status} status`,
              path: "paymentMethod",
            })
          : true;
      }),
      status: string().required(" Status is required"),
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    quote.orders.forEach((item) => {
      // if (item.type === 'INVOICE') {
      //     delete item.status;
      // }
      delete item.itemPrice;
      delete item._id;
    });

    const payload = {
      customer: quote.customer?.[0]?._id,
      currencyCode: quote.currencyCode,
      note: quote.note,
      shipping: quote.shipping,
      orders: quote.orders,
      discountAmount: quote.discountAmount,
      vat: !!(quote.vat && quote.vat.amount),
      wht: !!(quote.wht && quote.wht.amount),
      dueDate: quote.dueDate,
      status: values.status,
    };

    const invoicePayload = {
      customer: quote.customer?.[0]?._id,
      currencyCode: quote.currencyCode,
      note: quote.note,
      shipping: quote.shipping,
      orders: quote.orders,
      discountAmount: quote.discountAmount,
      vat: !!(quote.vat && quote.vat.amount),
      wht: !!(quote.wht && quote.wht.amount),
      dueDate: quote.dueDate,
      type: "INVOICE",
      deposit: quote.transactionAmount,
    };

    if (values.status === "VOID") {
      const { status, errorMessage } =
        await QuotationController.updateQuotation(payload, quote._id);

      if (status === constant.Success) {
        setLoading(false);
        MimaToastUtil.success({ message: constant.Success });
        closeModal();
      } else {
        setLoading(false);
        MimaToastUtil.error({ message: errorMessage });
      }
    } else {
      const [quoteRes, invoiceRes] = await Promise.all([
        QuotationController.updateQuotation(payload, quote._id),
        InvoiceController.createInvoice(invoicePayload),
      ]);

      if (
        quoteRes.status === constant.Success &&
        invoiceRes.status === constant.Success
      ) {
        setLoading(false);
        MimaToastUtil.success({ message: constant.Success });
        closeModal();
      } else {
        setLoading(false);
        MimaToastUtil.error({
          message: `${quoteRes.errorMessage} {" "} ${invoiceRes.errorMessage}`,
        });
      }
    }
  };

  return {
    loading,
    onSubmit,
    validationSchema,
  };
};

export default useUpdateQuotationLogic;
