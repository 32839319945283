import React, { useState } from "react";
import constant from "../../../utils/constant";
import { MimaToastUtil } from "../../../components";
import { MeasurementController } from "../../../controllers";
import { measurementStore, userStore } from "../../../stores";
import { handleImageUpload, removeEmptyKeys } from "../../../utils/utils";
import * as yup from "yup";

const useEditMeasurementLogic = ({ closeModal, reFetch }) => {
  const [loading, setLoading] = useState(false);

  const bId = userStore.userId;
  const selectedMeasurement = measurementStore.selectedMeasurement;

  const validationSchema = yup.object().shape({
    customer: yup.string().when("measurementFor", {
      is: "Customer",
      then: yup.string().required("Customer is required"),
      otherwise: yup.string(),
    }),
    style: yup.string().when("measurementFor", {
      is: "Design",
      then: yup.string().required("Style is required"),
      otherwise: yup.string(),
    }),
    unitOfMeasurement: yup.string().required("Select Unit of Measurement"),
    measurementImage: yup
      .array()
      .test(
        "measurementImage",
        "Delete attached measurement image",
        function (value) {
          const { measurementPic } = this.parent;
          return !(value.length > 0 && measurementPic === false);
        }
      ),
    measurementPic: yup.boolean(),
    customerImage: yup
      .array()
      .test(
        "customerImage",
        "Delete attached customer image",
        function (value) {
          const { customerPic } = this.parent;
          return !(value.length > 0 && customerPic === false);
        }
      ),
    customerPic: yup.boolean(),
  });

  const onSubmit = async (values) => {
    setLoading(true);

    let measurementImageUrls = [];
    const file = values.measurementImage?.[0];

    if (values.measurementImage.length > 0) {
      measurementImageUrls = [file.preview];
      if (file.name !== file.preview) {
        measurementImageUrls = await handleImageUpload(values.measurementImage);
      }
    }

    let customerImageUrls = [];
    const file2 = values.customerImage?.[0];

    if (values.customerImage.length > 0) {
      customerImageUrls = [file2.preview];
      if (file2.name !== file2?.preview) {
        customerImageUrls = await handleImageUpload(values.customerImage);
      }
    }

    let payload = {
      ...values,
      imageUrls: [],
    };

    if (measurementImageUrls.length > 0) {
      payload.imageUrls.push({
        type: "Measurement NoteBook",
        value: measurementImageUrls[0],
      });
    }

    if (customerImageUrls.length > 0) {
      payload.imageUrls.push({
        type: "Customer Picture",
        value: customerImageUrls[0],
      });
    }

    delete payload.customerImage;
    delete payload.measurementImage;
    delete payload.measurementPic;
    delete payload.customerPic;
    delete payload.value;
    delete payload.customerName;
    delete payload.styleName;

    removeEmptyKeys(payload);

    const { status, errorMessage } =
      await MeasurementController.editMeasurement(
        payload,
        measurementStore.selectedMeasurement._id,
        measurementStore.selectedMeasurement.business._id
      );
    if (status === constant.Success) {
      reFetch();
      setLoading(false);
      MimaToastUtil.success({
        message: constant.Success,
      });
      closeModal();

      return;
    }
    setLoading(false);
    return MimaToastUtil.error({
      message: errorMessage,
    });
  };
  return { onSubmit, loading, bId, selectedMeasurement, validationSchema };
};

export default useEditMeasurementLogic;
